import React, {Component} from 'react';
import ScrollerLabel from "../../svg/ScrollerLabel";
import { TweenLite, Power1} from "gsap/TweenLite";
import { TimelineLite } from "gsap";

class Loading extends Component {
    constructor(props){
        super(props);
        // reference to the DOM node
        this.myElement = null;
        // reference to the animation
        this.myTween = null;
    }
    componentDidMount(){

        const bounce = new TimelineLite({
            paused:true,
            yoyo:false,
            repeat:-1
        });﻿﻿

        TweenLite.set(this.myElement, {
            top: 220
        });﻿﻿

        bounce
            .to(this.myElement, .5, {
                top:200,
                ease:Power1.easeOut
    })
            .to(this.myElement, .5, {
                top:220,
                ease:Power1.easeIn
            })
            .play();﻿
    }
    render() {
        return (
            <div className="overlay-loader">
                <p>Loading..</p>
                <div className="el"  ref={div => this.myElement = div}>
                    <ScrollerLabel />
                </div>
            </div>
        );
    }
}

export default Loading;