import React, {Component, Fragment} from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router';

//actions
import {
    toggleHomeMenu,
    setHoveredMenuItemIndex,
    setPost,
    setSearchFocus
} from "../../actions/pageActions";

// svg
import SearchIcon from "../../svg/SearchIcon";
import MenuClose from "../../svg/MenuClose";

//tools
import classnames from 'classnames';
import { TweenLite, Power1} from "gsap/TweenLite";
import { TimelineLite } from "gsap";

//config
import { paths } from '../../config/paths';
import {Link} from "react-router-dom";


class Search extends Component {
    constructor(props) {
        super(props);
        this.state={
            focused:false,
            phrase: null,
            url: null,
            filteredResults: []
        };

        this.searchContainerEl = null;
        this.inputEl = React.createRef();
        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMouseEnterContainer = this.handleMouseEnterContainer.bind(this);
        this.handleItemSelection = this.handleItemSelection.bind(this);
        this.getFilteredResults = this.getFilteredResults.bind(this);
    }

    getFilteredResults = (data, val) => {
        const filteredResults = data.filter(item => {
            const itemName = item.acf.nazwa.toLowerCase();
            const enteredPhrase = val.toLowerCase();
            return itemName.startsWith(enteredPhrase);
        })
            .map( (item,index) => {return item} );
        this.setState({
            filteredResults
        })
    };
    handleMouseEnterContainer = () =>{
        this.props.setHoveredMenuItem(null)
    };
    handleInputFocus = () => {
        
        //animate
        const {searchMinWidth,searchMaxWidth,placeholderTxt} = this.props;
        const stretch = new TimelineLite({
            paused:true,
            yoyo:false,
            repeat:false
        });﻿﻿
        TweenLite.set(this.searchContainerEl, {
            maxWidth: searchMinWidth
        });﻿﻿
        stretch
            .to(this.searchContainerEl, .5, {
                maxWidth: searchMaxWidth,
                ease:Power1.easeOut
            })
            .play();﻿

        //focus
        this.props.setSearchFocus(true);
        this.setState({
            focused:true
        });
        this.inputEl.focus();
        this.inputEl.setAttribute("placeholder", placeholderTxt);

    };
    handleInputBlur = () => {

        //animate
        const {searchMinWidth,searchMaxWidth} = this.props;
        const shrink = new TimelineLite({
            paused:true,
            yoyo:false,
            repeat:false
        });﻿﻿
        TweenLite.set(this.searchContainerEl, {
            maxWidth: searchMaxWidth
        });﻿﻿
        shrink
            .to(this.searchContainerEl, .2, {
                maxWidth: searchMinWidth,
                ease:Power1.easeOut
            })
            .play();﻿

        //blur
        this.props.setSearchFocus(false);
        this.setState({
            phrase: null,
            focused:false
        });
        this.inputEl.value='';
        this.inputEl.removeAttribute("placeholder");
    };
    handleInputChange = (event,pageDataPosts) => {

        if (event.target.value.length > 0) {
            this.setState(
                {
                phrase: event.target.value
                }
            );
            this.getFilteredResults( pageDataPosts, event.target.value)

        } else {
            this.setState({
                phrase: null
            })
        }
    };
    handleItemSelection = url => {
        this.setState({ url });
        this.props.setPost(url)
    };

    render() {

        const {
            searchMinWidth,
            height,
            align,
            resultsAlignment,
            iconWidth,
            iconHeight,
            fontSize,
            id,
            pageOptions,
            pageDataPosts,
            setSelectedPost
        } = this.props;


        return (
            <Fragment>
                { this.state.url &&
                    <Redirect push to={ [ paths.sklepy, this.state.url ].join("/") }/>
                }
                <div className={
                    classnames(
                        'search-container',
                        'align-'+align,
                        {
                            'focused':this.state.focused
                        }
                    )
                }
                     ref={div => this.searchContainerEl = div}
                     style={{
                         maxWidth: searchMinWidth
                     }}
                     onMouseEnter={ () => this.handleMouseEnterContainer() }
                >
                    <form style={{
                        marginTop:`${height/2 - fontSize/2 - 2}px`,
                        width: `calc(100% - ${iconWidth}px)`
                    }}>
                        <input type="text"
                               className="search-shops"
                               onFocus={ ev=>this.handleInputFocus(ev,pageDataPosts) }
                               onChange={ ev=>this.handleInputChange(ev,pageDataPosts)}
                               style={{
                                   fontSize: `${fontSize}px`
                               }}
                               ref={el => (this.inputEl = el)}
                               id={id}
                               autoComplete="off"

                        />
                    </form>
                    <figure style={{
                        width:`${iconWidth}px`,
                        height:`${iconWidth}px`,
                        paddingTop:`${height/2 - iconHeight/2}px`
                    }}>
                        {
                            this.state.focused ?
                                <div className="close-search"
                                     onClick={ ()=>this.handleInputBlur() }>
                                    <MenuClose mainColor={"#000000"}
                                               size={fontSize}
                                    />
                                </div>
                                :
                                <div onClick={ ()=>this.handleInputFocus() }>
                                    <SearchIcon mainColor={"#000000"}
                                                size={fontSize}/>
                                </div>

                        }

                    </figure>
                    {
                        this.state.phrase &&

                        <ul
                            style={{top:height}}
                            className={classnames(
                                "results",
                                resultsAlignment
                            )}>
                            { this.state.filteredResults
                                .map((item,index) =>
                                    <li key={index}
                                        onMouseEnter={ () => setSelectedPost( item.acf.url ) }
                                        onMouseLeave={ () => setSelectedPost( null ) }
                                        onClick={ ()=> this.handleItemSelection(item.acf.url) }
                                    >
                                        <strong> {item.acf.nazwa} </strong>
                                    </li>
                                )
                            }
                            {
                                !this.state.filteredResults.length &&
                                <li>
                                    {pageOptions.teksty_brak_wynikow}&nbsp;
                                    <Link to={paths.sklepy}>
                                        {pageOptions.teksty_przejrzyj_pelna_liste} >
                                    </Link>
                                </li>
                            }
                        </ul>
                    }
                </div>

            </Fragment>


        );
    }
}

const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions,
    searchFocused: state.page.searchFocused,
    pageDataPosts: state.page.pageDataPosts,
});



const mapDispatchToProps = dispatch => ({
    toggleMenu: () => dispatch( toggleHomeMenu() ),
    setHoveredMenuItem: value => dispatch( setHoveredMenuItemIndex (value)),
    setPost: value => dispatch(setPost(value)),
    setSearchFocus: value => dispatch(setSearchFocus(value)),
    setSelectedPost: url => dispatch( setPost(url) )
});


export default connect(mapStateToProps,mapDispatchToProps)(Search);