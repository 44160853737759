import React from 'react';

const Dbaj = ({mainColor}) => {
    return (
        <svg
            version="1.1" xmlns="http://www.w3.org/2000/svg"
            id="svg_dbaj"
            x="0px" y="0px"
            width="82.87px" height="80.2px" viewBox="853.36 650.47 82.87 80.2">
            <path fill={mainColor} d="M861.44,695.75c-0.89-0.01-1.63,0.71-1.63,1.61c-0.01,0.89,0.71,1.63,1.61,1.63s1.63-0.71,1.63-1.61c0,0,0-0.01,0-0.01
	C863.04,696.48,862.33,695.75,861.44,695.75z"/>
            <path fill={mainColor} d="M927.74,701.34l-15.26,5.25c0.07-0.38,0.1-0.76,0.1-1.15c0-3.58-2.9-6.47-6.47-6.47h-10.48c-0.28,0-0.55-0.07-0.79-0.21
	l-8.55-4.79c-1.69-0.96-3.61-1.46-5.55-1.46h-11.48c-0.69-1.94-2.52-3.24-4.58-3.23h-9.7c-0.89-0.01-1.62,0.71-1.63,1.6
	c0,0.01,0,0.01,0,0.02v29.13c0,0.89,0.72,1.61,1.62,1.61c0,0,0,0,0,0h9.7c2.01,0,3.81-1.24,4.53-3.11c1.83,0.23,3.95,0.77,5.29,1.57
	l8.46,5.08c5.82,3.49,12.98,3.9,19.16,1.09l30.22-13.29c3.22-1.33,4.74-5.02,3.41-8.24c-0.09-0.23-0.2-0.45-0.33-0.67
	C934,701.46,930.7,700.3,927.74,701.34z M866.29,716.8c-0.01,0.89-0.73,1.61-1.62,1.61h-8.09v-25.9h8.09c0.89,0,1.62,0.73,1.62,1.62
	V716.8z M931.09,709.97h-0.05l-30.26,13.35c-5.21,2.37-11.25,2.03-16.16-0.92l-8.48-5.05c-1.81-1.09-4.4-1.75-6.63-2.01v-19.58
	h11.21c1.39,0,2.76,0.36,3.97,1.04l8.55,4.81c0.73,0.41,1.55,0.63,2.38,0.63h10.48c1.79-0.07,3.29,1.32,3.36,3.11
	s-1.32,3.29-3.11,3.36c-0.08,0-0.17,0-0.25,0h-16.45c-0.89-0.05-1.66,0.64-1.71,1.53c-0.05,0.89,0.64,1.66,1.53,1.71
	c0.06,0,0.12,0,0.18,0h16.45c1.06,0,2.1-0.26,3.04-0.76l19.68-6.79c1.46-0.52,3.09,0,3.74,1.19c0.87,1.44,0.41,3.31-1.03,4.18
	C931.38,709.84,931.24,709.91,931.09,709.97z"/>
            <path fill={mainColor} d="M900.48,692.61c0.61,0.52,1.51,0.52,2.12,0c13.21-11.45,21.37-17.24,21.37-26.24c0-7.12-5.04-13.02-12.13-13.02
	c-4.65-0.02-8.21,2.66-10.5,6.68c-2.3-4.01-5.85-6.7-10.52-6.7c-5.42,0-9.95,3.51-11.54,8.95c-0.3,0.84,0.15,1.77,0.99,2.07
	c0.84,0.3,1.77-0.14,2.07-0.99c0.02-0.05,0.04-0.11,0.05-0.17c1.18-4.04,4.49-6.63,8.44-6.63c4.62,0,7.86,4.1,8.95,7.92
	c0.23,0.86,1.12,1.37,1.98,1.14c0.56-0.15,0.99-0.58,1.14-1.14c0-0.08,2.27-7.92,8.95-7.92c5.06,0,8.88,4.21,8.88,9.79
	c0.01,7.2-7.04,12.39-19.2,22.87c-7.41-6.35-13.28-10.83-16.64-15.21c-0.61-0.66-1.63-0.7-2.29-0.1c-0.57,0.52-0.69,1.38-0.29,2.04
	C886.1,680.9,892.46,685.64,900.48,692.61z"/>
            <path fill={mainColor} d="M880.7,667.58c-0.9,0-1.62,0.73-1.62,1.62c0,0.9,0.73,1.62,1.62,1.62c0.89,0,1.62-0.73,1.62-1.62l0,0
	C882.31,668.31,881.59,667.59,880.7,667.58z"/>
        </svg>
    );
};

export default Dbaj;