import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router";

//actions
import {setPost} from "../../../../../actions/pageActions";

//tools
import classnames from "classnames";

//config
import {paths} from "../../../../../config/paths";

//svg
import Smiley from "../../../../../svg/Smiley";
import Akceptuj from "../../../../../svg/Csr/Akceptuj";
import Dbaj from "../../../../../svg/Csr/Dbaj";
import Doceniaj from "../../../../../svg/Csr/Doceniaj";
import Odpuszczaj from "../../../../../svg/Csr/Odpuszczaj";
import Ograniczaj from "../../../../../svg/Csr/Ograniczaj";

const Icons = {
    Smiley,
    Akceptuj,
    Dbaj,
    Doceniaj,
    Odpuszczaj,
    Ograniczaj
};

class Rules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToRulesArticle: null,
            itemHovered:null
        };
        this.handleRulesArticleLink = this.handleRulesArticleLink.bind(this);
    }

    handleRulesArticleLink = value => {
        this.props.setSelectedPost(value);
        this.setState({ redirectToRulesArticle: value })
    };

    render() {
        const {
            zasady,
            colors
        } = this.props;
        return (
            <div className="row rules">
                { this.state.redirectToRulesArticle &&
                <Redirect push to={paths.csr+ '/' + this.state.redirectToRulesArticle}/>
                }
                {
                    zasady && zasady.map(
                        (item,index) => {
                            const Icon = Icons[item.ikona];
                            return (
                                <div
                                    className={classnames(
                                        "col col-xs-12 col-sm-6 col-md-4",{
                                            "first" : item.is_first,
                                            "link" : item.url_csr_zasady,
                                            "hovered": index === this.state.itemHovered
                                        }
                                    )}
                                    key={index}
                                    onClick={() => this.handleRulesArticleLink(item.url_csr_zasady)}
                                    onMouseOver={() => this.setState({itemHovered:index>0 ? index : null})}
                                    style={ index === this.state.itemHovered ? colors.greenGradient : {}}

                                >
                                    <div className="row row-rule-item top-xs ">
                                        <div className="col col-xs-7 txt">
                                            {
                                                item.is_first ?
                                                    <h3>{item.tytul}</h3>
                                                    :
                                                    <>
                                                        <h4
                                                            dangerouslySetInnerHTML={{__html: item.tytul }}
                                                            style={ index === this.state.itemHovered ? { color:'#f6b938' } : {}} />
                                                        <p
                                                            dangerouslySetInnerHTML={{__html: item.podtytul }}
                                                            style={ index === this.state.itemHovered ? { color:'#f6b938' } : {}}/>
                                                    </>
                                            }
                                        </div>
                                        <div
                                            className="col col-xs-5 center-xs middle-xs  icon"
                                            style={colors.csrGradient}>
                                            <Icon mainColor={ index === this.state.itemHovered ? colors.green : "#FFFFFF"} />
                                        </div>
                                    </div>


                                </div>
                            )
                        }
                    )
                }

            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    setSelectedPost: value => dispatch( setPost(value))
});


export default connect(null,mapDispatchToProps)(Rules);