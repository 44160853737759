import React from 'react';

const ToiletIcon= ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_search" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="31.16px" height="31.45px"
             viewBox="0 0 31.16 31.45">

            <g>
                <rect fill={mainColor} x="14.61" y="3.07" width="2.42" height="26.3"/>
                <g>
                    <path fill={mainColor} d="M24.7,3.02c1.21,0.01,2.15,0.97,2.14,2.2c-0.01,1.2-0.99,2.17-2.19,2.16c-1.19-0.01-2.17-0.99-2.17-2.19
				C22.47,3.96,23.44,3.01,24.7,3.02z"/>
                    <path fill={mainColor} d="M29.81,10.46c-0.04-1.47-1.28-2.54-2.76-2.55c-0.8,0-1.6-0.01-2.39-0.01l0,0l0,0c0,0,0,0,0,0l0,0c-0.8,0-1.6,0-2.39,0.01
				c-1.48,0.01-2.72,1.08-2.76,2.55c-0.07,2.33-0.03,4.67-0.02,7.01c0,0.54,0.42,0.92,0.93,0.93c0.5,0.01,0.91-0.37,0.97-0.9
				c0.02-0.15,0.01-0.31,0.01-0.47c0-1.7,0-3.39,0-5.09c0-0.19,0-0.37,0-0.55c0.56-0.11,0.43,0.27,0.43,0.5
				c0.01,5.23,0,10.45,0,15.68c0,0.17-0.01,0.35,0,0.52c0.06,0.79,0.6,1.33,1.33,1.32c0.72-0.01,1.24-0.57,1.26-1.37
				c0-0.16,0-0.31,0-0.47c0-2.89,0-5.78,0-8.67c0-0.24-0.01-0.47,0.24-0.48c0.25,0.01,0.24,0.24,0.24,0.48c0,2.89,0,5.78,0,8.67
				c0,0.16,0,0.31,0,0.47c0.03,0.8,0.54,1.35,1.26,1.37c0.73,0.01,1.27-0.52,1.33-1.32c0.01-0.17,0-0.35,0-0.52
				c0-5.23,0-10.45,0-15.68c0-0.23-0.14-0.61,0.43-0.5c0,0.18,0,0.37,0,0.55c0,1.7,0,3.39,0,5.09c0,0.16-0.01,0.31,0.01,0.47
				c0.06,0.53,0.47,0.9,0.97,0.9c0.5-0.01,0.92-0.38,0.93-0.93C29.84,15.13,29.88,12.79,29.81,10.46z"/>
                </g>
                <g>
                    <g>
                        <path fill={mainColor} d="M4.54,5.22c-0.01-1.23,0.92-2.19,2.13-2.2C7.92,3.01,8.9,3.97,8.9,5.19c0,1.19-0.98,2.18-2.17,2.19
					C5.54,7.39,4.55,6.42,4.54,5.22z"/>
                    </g>
                    <path fill={mainColor} d="M12.72,16.25c-0.29-0.99-1.51-5.17-1.85-6.26c-0.4-1.3-1.47-2.08-2.83-2.08c-0.45,0-0.91,0-1.36,0c-0.45,0-0.91,0-1.36,0
				c-1.36,0-2.43,0.78-2.83,2.08c-0.33,1.09-1.56,5.27-1.85,6.26c-0.12,0.42-0.05,0.8,0.34,1.05c0.34,0.22,0.72,0.21,1.01-0.07
				c0.17-0.17,0.29-0.43,0.36-0.67c0.53-1.75,1.04-3.51,1.56-5.27c0.08-0.29,0.16-0.55,0.59-0.33c-0.96,3.27-2.91,9.9-2.91,9.9H4.4
				c0,0,0,4.94,0,7.06c0,0.24,0.01,0.49,0.05,0.72c0.09,0.45,0.51,0.77,0.98,0.77c0.47,0,0.89-0.3,1-0.75
				c0.05-0.2,0.05-0.41,0.05-0.62c0-2.25,0-4.5,0-6.75c0-0.23,0.01-0.44,0.2-0.45c0.2,0.01,0.2,0.22,0.2,0.45c0,2.25,0,4.5,0,6.75
				c0,0.21,0,0.42,0.05,0.62c0.1,0.45,0.52,0.75,1,0.75c0.47,0,0.89-0.32,0.98-0.77c0.05-0.24,0.05-0.48,0.05-0.72
				c0-2.13,0-7.06,0-7.06h2.81c0,0-1.94-6.63-2.91-9.9c0.43-0.21,0.51,0.05,0.59,0.33c0.51,1.76,1.03,3.51,1.56,5.27
				c0.07,0.24,0.19,0.5,0.36,0.67c0.29,0.28,0.66,0.29,1.01,0.07C12.77,17.05,12.84,16.67,12.72,16.25z"/>
                </g>
            </g>
        </svg>
    );
};

export default ToiletIcon;