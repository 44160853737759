import React,{Component,Fragment} from 'react';
import {connect} from "react-redux";
import { Redirect } from 'react-router';

//actions
import {setPost,setHoveredPost} from "../../actions/pageActions";

//tools
import classnames from 'classnames';
import {isIE} from 'react-device-detect';

//data
import { layers, colors } from '../../components/Views/ViewPlanCentrum/Plan/dataList';

//config
import { paths } from "../../config/paths";




class PlanVectors extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            redirect: false,
            redirectLink: null
        };
        this.handleBlock = this.handleBlock.bind(this);
        this.handlePostSelection = this.handlePostSelection.bind(this);
        this.handleColors = this.handleColors.bind(this);
    }

    handleBlock = (url) => {
        this.props.setHoveredPost(url);
        this.props.setSelectedPost(url);
    };

    handlePostSelection = value =>{
        this.props.setSelectedPost(value);
        this.setState(
            {
                redirect: true ,
                redirectLink: value
            });
        if (this.props.selectedPost) {
            this.setState( { showPins: false });
        } else {
            this.setState( { showPins: true });
        }
    };

    handleColors = (cat,side,id,active) => {
        const blockState = active ? 'active' : 'passive';
        switch(cat) {
            case cat:
                return side ? colors[cat][blockState][side] : '#FFFFFF     ';
            default:
                return '#888888';

        }
    };


    render() {
        const {
            selectedPost,
            hoveredPost
        } = this.props;

        const {
            redirect,
            redirectLink
        } = this.state;


        const sortedShops = [];
        
        this.props.shopsData.forEach(function (sklepy) {
            if (sklepy.acf.numer_miejsca_na_planie) {
                sortedShops[sklepy.acf.numer_miejsca_na_planie] = sklepy.acf
                if (!sortedShops[sklepy.acf.numer_miejsca_na_planie].kategoria_sklepu) {
                    sortedShops[sklepy.acf.numer_miejsca_na_planie].kategoria_sklepu = {value: sklepy.acf.katogoria_uslug.value}
                }
            }
        });


        return (
            <Fragment>
                { redirect &&
                    <Redirect push to={paths.sklepy + '/' + redirectLink}/>
                }
                <svg
                    version="1.1"
                    id="r1_col1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px"
                    width="1820px" height="1473.441px"
                    viewBox="0 -40 1820 1473.441"
                    preserveAspectRatio={isIE ? "xMidYMid slice" : "none"}
                >
                    <g id="r1">
                        

                        {
                            layers.row1.map(
                                (item,index) => {
                                    let itemID = "inactive"
                                    let itemCAT = "inactive"
                                    

                                    if (item.dataid in sortedShops) {
                                        // console.log(item.dataid)
                                        // console.log(sortedShops[item.dataid].url)
                                        //console.log(sortedShops[itemID].kategoria_sklepu.value)
                                        

                                        // if (sortedShops[itemID]) {
                                        //     itemID = sortedShops[itemID].nazwa
                                        //     itemCAT = sortedShops[itemID].kategoria_sklepu.value
                                        // } else {
                                        //     itemID = "inactive"
                                        //     itemCAT = "inactive"
                                        // }
                                        itemID = sortedShops[item.dataid].url
                                        itemCAT = sortedShops[item.dataid].kategoria_sklepu.value

                                    } else {
                                        itemID = "inactive"
                                        itemCAT = "inactive"
                                    }
                                    

                                    return (
                                        
                                        <Fragment key={index}>
                                            {
                                                item.isPath &&
                                                <path
                                                    key={index}
                                                    onMouseOver={() => this.handleBlock(itemID)}
                                                    onClick={() => this.handlePostSelection(itemID)}
                                                    className={classnames(
                                                        item.className,
                                                        {
                                                            "surface": item.side === "top",
                                                            "expanded": selectedPost === itemID,
                                                            "hovered": hoveredPost === itemID
                                                        }
                                                    )}
                                                    dataid={itemID}
                                                    fill={
                                                        selectedPost ?
                                                            this.handleColors(
                                                                itemCAT,
                                                                item.side,
                                                                itemID,
                                                                selectedPost=== itemID
                                                            ) :
                                                            this.handleColors(
                                                                itemCAT,
                                                                item.side,
                                                                itemID,
                                                                true
                                                            )
                                                    }
                                                    d={
                                                        selectedPost === itemID && itemID !== 'inactive'
                                                            ?
                                                            item.pointsOut :
                                                            item.pointsIn}
                                                />
                                            }
                                            {
                                                !item.isPath &&
                                                <polygon
                                                    key={index}
                                                    onMouseOver={() => this.handleBlock(itemID)}
                                                    onClick={() => this.handlePostSelection(itemID)}
                                                    className={classnames(
                                                        item.className,
                                                        {
                                                            "surface": item.side === "top",
                                                            "expanded": selectedPost === itemID,
                                                            "hovered": hoveredPost === itemID
                                                        }
                                                    )}
                                                    dataid={itemID}
                                                    fill={
                                                        selectedPost ?
                                                        this.handleColors(
                                                            itemCAT,
                                                            item.side,
                                                            itemID,
                                                            selectedPost=== itemID
                                                        ) :
                                                        this.handleColors(
                                                            itemCAT,
                                                            item.side,
                                                            itemID,
                                                            true
                                                        )
                                                    }
                                                    points={
                                                        selectedPost === itemID && itemID !== 'inactive'
                                                            ?
                                                            item.pointsOut :
                                                            item.pointsIn}
                                                />
                                            }

                                        </Fragment>

                                    )
                                }
                            )
                        }
                    </g>
                </svg>
            </Fragment>

        );
    }
}


const mapStateToProps = (state) => ({
        pageOptions: state.page.pageOptions,
        selectedPost: state.page.selectedPost,
        hoveredPost: state.page.hoveredPost
});

const mapDispatchToProps = dispatch => ({
        setSelectedPost: (url) => dispatch( setPost(url) ),
        setHoveredPost: url => dispatch( setHoveredPost(url) )
});


export default connect(mapStateToProps,mapDispatchToProps)(PlanVectors);