import React, {Component} from 'react';
import {connect} from 'react-redux';

//actions
import {togglePlayer} from "../../actions/playerActions";

//svg
import Play from '../../svg/player/Play';
import Pause from '../../svg/player/Pause';

//tools
import Vimeo from '@u-wave/react-vimeo';
import MediaQuery from 'react-responsive';

class Player extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobilePaused: true,
            singlePaused: false,
        };

        this.handlePlayerPause = this.handlePlayerPause.bind(this);
        this.handlePlayerPlay = this.handlePlayerPlay.bind(this);

    }

    handlePlayerPlay() {
        this.setState({
            mobilePaused: false,
            singlePaused:false
        });
    }

    handlePlayerPause() {
        this.setState({
            mobilePaused: true,
            singlePaused: true
        });
    }

    render() {

        const vimeoId = this.props.selectedVideo.vimeoId;

        const {
            mobilePaused,
            singlePaused
        } = this.state;

        const {
            playerBg,
            playerBgTransparentImg
        } = this.props;

        return (
            <div
                className="player-container"
                style={{backgroundColor:playerBg}}
            >

                <nav className="video-close">
                    <button onClick={()=>this.props.closePlayer()}>x</button>
                </nav>



                <MediaQuery maxWidth={768}>
                    <div className="stage mobile">
                        <nav className="video-controls">
                            <ul>
                                <li className="play"
                                    onClick={this.handlePlayerPlay}>
                                    <Play mainColor="#FFFFFF" />
                                </li>
                                <li className="pause"
                                    onClick={this.handlePlayerPause} >
                                    <Pause mainColor="#FFFFFF" />
                                </li>
                            </ul>

                        </nav>
                        <Vimeo
                            video={vimeoId}
                            paused={mobilePaused}
                            showByline={false}
                            showTitle={false}
                            className="vid"
                            autoplay={false}
                            autopause={true}
                        />
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={769}>
                    <div className="stage">
                        <nav className="video-controls">
                            <ul>
                                <li className="play"
                                    onClick={this.handlePlayerPlay}>
                                    <Play mainColor="#FFFFFF" />
                                </li>
                                <li className="pause"
                                    onClick={this.handlePlayerPause} >
                                    <Pause mainColor="#FFFFFF" />
                                </li>
                            </ul>

                        </nav>
                        <Vimeo
                            video={vimeoId}
                            paused={singlePaused}
                            showByline={false}
                            showTitle={false}
                            className="vid"
                            autoplay={true}
                            autopause={false}
                        />
                        <img
                            className="container-pic"
                            src={playerBgTransparentImg}
                            alt=""
                            aria-hidden="true" />
                    </div>
                </MediaQuery>

            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    selectedVideo: state.player.selectedVideo,
    playerBg: state.page.pageOptions.player_bg,
    playerBgTransparentImg: state.page.pageOptions.zaslepka_player
});

const mapDispatchToProps = dispatch => ({
    closePlayer: () => dispatch(togglePlayer()),
});


export default connect(mapStateToProps,mapDispatchToProps)(Player);