import React from 'react';

const Play = ({mainColor}) => {
    return (
        <svg
            version="1.1"
            id="player_play"
            xmlns="http://www.w3.org/2000/svg"
            width="33px"
            height="36px"
            viewBox="-11.552 -10.904 33 36">
            <polygon fill={mainColor} points="-11.552,-10.904 -11.552,25.096 21.448,7.04 "/>
</svg>
    );
};

export default Play;