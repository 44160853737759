import React from 'react';

const Facebook = ({mainColor}) => {
    return (
        <svg
            version="1.1"
            id="soc_fb"
            xmlns="http://www.w3.org/2000/svg"
            width="100px"
            height="100px"
            viewBox="0 0 100 100">
            <path fill={mainColor} d="M58.062,99.35c-0.416,0.067-0.834,0.13-1.254,0.188C57.228,99.479,57.646,99.417,58.062,99.35z"/>
            <path fill={mainColor} d="M59.063,99.179c-0.199,0.036-0.398,0.069-0.598,0.104C58.664,99.248,58.863,99.215,59.063,99.179z"/>
            <path fill={mainColor} d="M55.69,99.676c-0.487,0.055-0.978,0.104-1.47,0.145C54.713,99.779,55.203,99.73,55.69,99.676z"/>
            <path fill={mainColor} d="M56.651,99.559c-0.234,0.031-0.47,0.06-0.705,0.088C56.182,99.618,56.417,99.59,56.651,99.559z"/>
            <path fill={mainColor} d="M60.423,98.908c-0.175,0.037-0.352,0.075-0.527,0.11C60.071,98.983,60.248,98.945,60.423,98.908z"/>
            <path fill={mainColor} d="M63.802,98.069c-0.142,0.04-0.284,0.078-0.426,0.118C63.518,98.147,63.66,98.109,63.802,98.069z"/>
            <path fill={mainColor} d="M62.78,98.349c-0.154,0.04-0.31,0.081-0.464,0.12C62.471,98.43,62.626,98.39,62.78,98.349z"/>
            <path fill={mainColor} d="M61.443,98.683c-0.163,0.038-0.328,0.074-0.493,0.11C61.115,98.757,61.28,98.721,61.443,98.683z"/>
            <path fill={mainColor} d="M54.205,99.822c-0.264,0.022-0.527,0.042-0.791,0.06C53.678,99.864,53.941,99.845,54.205,99.822z"/>
            <path fill={mainColor} d="M100,50c0-27.61-22.39-50-50-50C22.39,0,0,22.39,0,50c0,27.61,22.39,50,50,50c0.293,0,1-0.012,1-0.012V61
			H40V48h11v-9.212c0-10.684,6.603-16.5,16.251-16.5c4.62,0,8.592,0.34,9.749,0.492V34h-6.695C65.022,34,64,36.464,64,40.08V48
			h12.199l-1.616,13H64v37.068C84.701,92.077,100,72.822,100,50z"/>
            <path fill={mainColor} d="M53.304,99.889c-0.521,0.033-1.043,0.061-1.567,0.078C52.261,99.949,52.783,99.922,53.304,99.889z"/>
            <path fill={mainColor} d="M51.71,99.968c-0.276,0.01-0.554,0.017-0.83,0.021C51.156,99.984,51.434,99.978,51.71,99.968z"/>
        </svg>
    );
};

export default Facebook;