import React from 'react';

//tools
import MediaQuery from "react-responsive";

const Franczyza = ({pageData,pageOptions}) => {

    const phone1 = pageData.franczyza_telefon_1;
    const phone2 = pageData.franczyza_telefon_2;
    return (
        <div className={"col col-xs-12 col-sm-6 col-md-4"}>
            <section className="lower">
                <h3 dangerouslySetInnerHTML={{__html: pageOptions.teksty_kontakt_franczyza}}/>
                <p>
                    <strong>{pageData.franczyza_nazwa}</strong>
                    <a href={`mailto:${pageData.franczyza_email}`} title="Email - Franchise">
                        {pageData.franczyza_email}
                    </a>
                </p>
                <p>
                    <MediaQuery minWidth={769}>
                        <span style={{display:"block"}}>
                            {pageData.franczyza_telefon_1}
                        </span>
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                        <a href={`tel:${phone1 && phone1.replace(/[() ]/g,'')}`} title="Tel 1">
                            {pageData.franczyza_telefon_1}
                        </a>
                    </MediaQuery>

                    <MediaQuery minWidth={769}>
                        <span style={{display:"block"}}>
                            {pageData.franczyza_telefon_2}
                        </span>
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                        <a href={`tel:${phone2 && phone2.replace(/[() ]/g,'')}`} title="Tel 2">
                            {pageData.franczyza_telefon_2}
                        </a>
                    </MediaQuery>
                </p>
            </section>
        </div>
    );
};

export default Franczyza;