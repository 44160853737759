import React from 'react';

const SearchIcon= ({mainColor,size}) => {
    return (
        <svg version="1.1" id="svg_search" xmlns="http://www.w3.org/2000/svg" width={`${size}px`} height={`${size}px`} viewBox="103.301 5.801 258.398 258.398">
            <g>
                <path fill={mainColor} d="M358.535,245.848l-62.121-62.121c15.398-18.773,24.68-42.82,24.68-69.029c0-60.117-48.779-108.896-108.896-108.896
                    c-60.17,0-108.896,48.779-108.896,108.896s48.727,108.896,108.896,108.896c26.209,0,50.203-9.229,68.977-24.627l62.121,62.068
                    c4.219,4.219,11.021,4.219,15.24,0C362.754,256.869,362.754,250.014,358.535,245.848z M212.197,201.92
                    c-48.146,0-87.275-39.129-87.275-87.223s39.129-87.275,87.275-87.275c48.094,0,87.275,39.182,87.275,87.275
                    S260.291,201.92,212.197,201.92z"/>
            </g>
        </svg>
    );
};

export default SearchIcon;