import React from 'react';

const CounterDistance = ({mainColor}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" width="108.743px" height="82px"
             viewBox="837.838 611.101 108.743 82" >
            <g id="Layer_2">
                <g id="Layer_2-2">
                    <path fill={mainColor} d="M878.987,624.139c0.001-3.632-2.943-6.578-6.575-6.579s-6.578,2.943-6.579,6.576s2.943,6.578,6.575,6.579
                        c0.001,0,0.001,0,0.001,0C876.041,630.71,878.983,627.769,878.987,624.139z M869.038,624.139
                        c-0.001-1.863,1.509-3.375,3.372-3.376c1.864,0,3.375,1.509,3.376,3.373c0.001,1.863-1.509,3.375-3.373,3.376
                        c-0.001,0-0.002,0-0.003,0C870.549,627.509,869.041,626,869.038,624.139z"/>
                    <path fill={mainColor} d="M916.607,653.066c-4.126,0-7.634,1.028-9.754,2.663l-5.743-1.281c-0.865-0.177-1.711,0.383-1.888,1.25
                        c-0.172,0.845,0.356,1.673,1.193,1.875l4.401,0.982c-0.083,0.321-0.125,0.651-0.125,0.983c0,2.176,1.78,4.035,4.632,5.196
                        l-2.562,2.638c-0.623,0.627-0.619,1.642,0.009,2.265s1.643,0.619,2.265-0.009c0.008-0.008,0.016-0.016,0.022-0.024l3.788-3.888
                        c1.242,0.207,2.501,0.31,3.761,0.306c6.795,0,11.919-2.782,11.919-6.474C928.526,655.856,923.402,653.066,916.607,653.066z
                         M916.607,662.811c-5.238,0-8.713-1.968-8.713-3.271c0-0.272,0.16-0.573,0.442-0.88c0.049-0.043,0.096-0.089,0.14-0.139
                        c1.195-1.121,4.153-2.253,8.131-2.253c5.238,0,8.716,1.97,8.716,3.271S921.846,662.811,916.607,662.811z"/>
                    <path fill={mainColor} d="M897.565,681.431l2.562-2.639c0.61-0.641,0.586-1.654-0.055-2.264c-0.631-0.603-1.627-0.588-2.24,0.03l-3.789,3.898
                        c-1.242-0.206-2.498-0.309-3.756-0.306c-6.793,0-11.917,2.783-11.917,6.474c0,3.692,5.12,6.476,11.917,6.476
                        s11.916-2.783,11.916-6.476C902.199,684.454,900.418,682.592,897.565,681.431z M890.287,689.898c-5.238,0-8.715-1.97-8.715-3.272
                        c0-1.301,3.477-3.271,8.715-3.271s8.714,1.971,8.714,3.271C899.001,687.928,895.521,689.898,890.287,689.898z"/>
                    <path fill={mainColor} d="M887.907,654.791c0.115,0.025,0.233,0.039,0.351,0.039c0.884,0.003,1.603-0.712,1.605-1.597
                        c0.002-0.754-0.522-1.408-1.26-1.569l-4.402-0.979c0.084-0.322,0.126-0.653,0.126-0.985c0-3.001-3.339-5.027-7.162-5.939
                        l7.146-14.312c0.012-0.022,0.022-0.047,0.032-0.071c2.895-6.59-0.101-14.278-6.691-17.173c-6.59-2.895-14.279,0.102-17.173,6.692
                        c-0.726,1.652-1.101,3.438-1.1,5.243c-0.004,1.804,0.37,3.588,1.097,5.238c0.01,0.024,0.021,0.048,0.034,0.071l7.152,14.329
                        c-3.832,0.931-7.162,2.958-7.162,5.925c0,3.683,5.125,6.475,11.916,6.475c4.127,0,7.636-1.028,9.756-2.661L887.907,654.791z
                         M862.583,624.139c0.004-5.428,4.408-9.824,9.835-9.82s9.825,4.408,9.82,9.835c-0.001,1.342-0.277,2.669-0.811,3.9l-9.018,18.061
                        l-9.016-18.061C862.856,626.819,862.579,625.486,862.583,624.139z M863.704,649.702c0-0.841,2.082-2.303,5.445-2.971l1.841,3.684
                        c0.396,0.791,1.357,1.112,2.148,0.717c0.311-0.156,0.562-0.407,0.717-0.717l1.845-3.698c3.394,0.641,5.437,2.103,5.437,2.982
                        c0,0.274-0.16,0.577-0.445,0.884c-0.05,0.042-0.096,0.088-0.14,0.138c-1.194,1.121-4.154,2.249-8.13,2.249
                        C867.172,652.97,863.704,651.002,863.704,649.702z"/>
                    <path fill={mainColor} d="M903.444,671.482c-0.885,0-1.602,0.717-1.602,1.601c0,0.885,0.717,1.602,1.602,1.602c0.884,0,1.602-0.717,1.602-1.602
                        C905.046,672.199,904.328,671.482,903.444,671.482z"/>
                    <path fill={mainColor} d="M894.51,656.222c0.885,0,1.602-0.718,1.602-1.602c0-0.885-0.717-1.603-1.602-1.603s-1.602,0.718-1.602,1.603
                        C892.908,655.504,893.625,656.222,894.51,656.222z"/>
                </g>
            </g>
        </svg>

    );
};

export default CounterDistance;