import React,{Fragment} from 'react';

import classnames from "classnames";

const Info = (
    {
        tytul,
        opis,
        rowerzysta,
        hashtagi,
        gradient,
        theme,
        flat
    }) => {

    const background = {
        background: `linear-gradient( 
                -45deg, 
                ${gradient.color1} 53%, 
                ${gradient.color2} 85% 
            )`};

    return (
        <Fragment>
            {
                theme === "strona_akcji" &&
                <div className="row section-info middle-md">
                    <div
                        className="col col-xs-12 col-md-9 txt"
                        style={{
                            marginTop: flat ? "80px" : "-34px"
                        }}
                    >
                        <div
                            className="mask"
                            style={background}
                        />
                        <div className="body">
                            <h3 style={{textTransform:"uppercase"}} >{tytul}</h3>
                            <p dangerouslySetInnerHTML={{__html:opis}}/>
                            { hashtagi && <p className="hashtags" dangerouslySetInnerHTML={{__html:hashtagi}}/> }

                        </div>

                    </div>
                    <div className="col col-xs-12 col-md-3 img">
                        {
                            rowerzysta &&
                            <img
                                src={rowerzysta}
                                alt="rowerzysta"
                                style={{
                                    width: "60%",
                                    height: "auto"
                                }}
                            />
                        }

                    </div>
                </div>
            }
            {
                theme === "strona_newsa" &&
                <div className="row center-xs start-sm">
                    <div className={"col col-xs-12 col-md-4"}>
                        <div className="panel" style={background}/>
                    </div>
                    <div className={'col col-xs-12 col-md-8 news-txt-content'}>
                        <article>
                            <h3 style={{textTransform:"uppercase"}}>{tytul}</h3>
                            <p dangerouslySetInnerHTML={{__html: opis}} />
                            { hashtagi && <p className="hashtags" dangerouslySetInnerHTML={{__html:hashtagi}}/> }
                        </article>
                    </div>
                </div>
            }
        </Fragment>

    );
};

export default Info;