import { config } from "./";

export const paths = {
    lojalnosclogin: config.domainRelPath + "/klub_turawa_login",
    lojalnosc: config.domainRelPath + "/klub_turawa",
    karta: config.domainRelPath + "/karta-podarunkowa",
    fotoblog: config.domainRelPath + "/fotoblog",
    godziny: config.domainRelPath + "/turawa-park-ch/godziny-otwarcia",
    home: config.domainRelPathExt,
    konkursy: config.domainRelPath + "/aktualnosci/konkursy",
    kontakt: config.domainRelPath + "/kontakt",
    lokalizacja: config.domainRelPath + "/turawa-park-ch/lokalizacja",

    //shops
    sklepy: config.domainRelPath + "/sklepy-i-uslugi",
    sklep: config.domainRelPath + "/sklepy-i-uslugi/:shopId",

    //news
    archiwa: config.domainRelPath + "/aktualnosci/archiwa",
    archiwum: config.domainRelPath + "/aktualnosci/archiwa/:newsId",
    wydarzenia: config.domainRelPath + "/aktualnosci/nadchodzace-wydarzenia",
    news: config.domainRelPath + "/aktualnosci/nadchodzace-wydarzenia/:newsId",

    //promo
    promocje: config.domainRelPath + "/promocje",
    promocja: config.domainRelPath + "/promocje/:promoId",

    //csr rules
    csr: config.domainRelPath + "/zachowajzdrowydystans",
    csrZasady: config.domainRelPath + "/zachowajzdrowydystans/:ruleId",

    //plan
    planCentrum: config.domainRelPath + "/plan-centrum",
    planCentrumSklep: config.domainRelPath + "/plan-centrum/:shopId",

    oNas: config.domainRelPath + "/turawa-park-ch/o-nas",
    udogodnienia: config.domainRelPath + "/turawa-park-ch/udogodnienia",
    event: config.domainRelPath + "/rajd-charytatywny",
    eventBts: config.domainRelPath + "/events/powrot-do-szkoly",
    eventBtsKonkurs: config.domainRelPath + "/events/powrot-do-szkoly-konkurs",
    eventJesienneRadosci: config.domainRelPath + "/events/jesienne-radosci-2020",
    eventKonkursSwiateczny2020: config.domainRelPath + "/events/konkurs-swiateczny-2020",
    eventMagicznaGwiazdka2020: config.domainRelPath + "/events/magiczna-gwiazdka-2020",
    eventRajd: config.domainRelPath + "/events/rajd-charytatywny",
    eventRajd2021: config.domainRelPath + "/events/rajd-charytatywny-2021",
    eventRajd2022: config.domainRelPath + "/events/rajd-charytatywny-2022",
    eventWosp2021: config.domainRelPath + "/events/wosp-2021",
    eventWalentynki2021: config.domainRelPath + "/events/walentynki-2021",
    eventDzienKobiet2021: config.domainRelPath + "/events/dzien-kobiet-2021",
    eventWielkanoc2021: config.domainRelPath + "/events/konkurs-wielkanoc-2021",
    eventPrzedszkolaki2021: config.domainRelPath + "/events/konkurs-dla-przedszkolakow-2021",
    eventDzienMatki2021: config.domainRelPath + "/events/dni-pelne-nagrod-dla-mamy-i-dziecka",
    event10LatRazem: config.domainRelPath + "/events/10-lat-razem",
    eventBialaSobota: config.domainRelPath + "/events/biala-sobota",
    eventMikolajki2021: config.domainRelPath + "/events/mikolajki-2021",
    eventSwieta2021: config.domainRelPath + "/events/swieta-2021",
    evenDzienBabci2022: config.domainRelPath + "/events/dzien-babci-i-dziadka-2021",
};