import React,{Component,Fragment} from 'react';
import {connect} from "react-redux";

import Arrow from "../../svg/lightbox/Arrow";
import MenuClose from "../../svg/MenuClose";

//tools
import classnames from "classnames";

//actions
import {togglePlayer,selectedVideo} from "../../actions/playerActions";

class ExternalButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: false
        };

        this.handleVideoPlayer = this.handleVideoPlayer.bind(this);
    }

    handleVideoPlayer = video => {
        this.props.openPlayer();
        this.props.selectedVideo(video);
    };
    render(){
        const {
            txt,
            linkType,
            vimeoId,
            url,
            gradient,
            color,
            isModal,
            modalContent,
            featObraz,
            featTytul,
            odleglosc
        } = this.props;

        const featTitlePadding = odleglosc && odleglosc.replace("-","");

        const setGradient =  gradient ? gradient : {};
        const setColor = color ? {borderColor:color} : {};

        return (
            <Fragment>
                {
                    txt &&
                    <div className={classnames(
                        "section-external-link",{
                            "extended": featObraz || featTytul
                        })}>

                        {
                            (featObraz || featTytul) &&
                            <div
                                className="row feat bottom-md middle-xs center-xs start-md"
                                style={{
                                    marginBottom: `${odleglosc ? odleglosc : 0}px`
                                }}
                            >
                                <div className="col col-xs-12 col-md-6 obraz">
                                    { featObraz && <img src={featObraz} alt={`Pic: ${featTytul}`} /> }

                                </div>
                                <div className="col col-xs-12 col-md-6 tytul">
                                    {
                                        featTytul &&
                                        <p style={{ paddingBottom: `${featTitlePadding ? featTitlePadding : 0}px`}}>
                                            {featTytul}
                                        </p>
                                    }
                                </div>
                            </div>
                        }

                        <nav
                            className="external-button"
                            style={Object.assign(setColor,setGradient)}
                        >
                            {
                                (
                                    (!isModal && linkType === "link") ||
                                    (!isModal && !linkType)
                                )  &&
                                <a
                                    href={url}
                                    title={`Link: ${txt}`}
                                    target="_blank"
                                    rel="noopener nofollow"
                                    style={{ color: color ? color : "#000000" }}
                                    dangerouslySetInnerHTML={{__html:txt}}
                                />
                            }
                            {
                                !isModal &&
                                linkType === "video"  &&
                                <button
                                    style={{ color: color ? color : "#000000" }}
                                    onClick={ ()=>this.handleVideoPlayer({ vimeoId:vimeoId} ) }>
                                    <span dangerouslySetInnerHTML={{__html:txt}}/>
                                </button>
                            }
                            {
                                isModal   &&
                                <span
                                    onClick={()=>this.setState({opened:true})}
                                    title={`Modal: ${txt}`}
                                    style={{ color: color ? color : "#000000" }}
                                    dangerouslySetInnerHTML={{__html:txt}}
                                />
                            }
                            <Arrow mainColor={color ? color : "#000000"}/>
                        </nav>
                        {
                            modalContent &&
                            this.state.opened &&
                            <div className="link-modal" >
                                <nav
                                    className="link-modal-close"
                                    onClick={()=>this.setState({opened:false})}
                                >
                                    <MenuClose mainColor="#000000" size={11}/>
                                </nav>
                                <div className="panel" style={gradient}/>
                                <div
                                    className="link-modal-content"
                                    dangerouslySetInnerHTML={{__html: modalContent}}
                                />
                            </div>
                        }
                    </div>
                }

            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch( togglePlayer() ),
    selectedVideo: video => dispatch( selectedVideo(video) ),
});


export default connect(null,mapDispatchToProps)(ExternalButton);