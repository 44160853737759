import React from 'react';
import {connect} from "react-redux";

// components
import Page from '../../../Page';

//config
import { config }  from "../../../../config";

const Konkursy = ({pageData}) => {
    return (
        <Page pageIndex={config.pageIndex.aktualnosci.konkursy}>
        <article className="page konkursy content">
            <div className={"boxed"}>
               <h2>{pageData.tytul}</h2>
            </div>
        </article>
        </Page>
    );
};

const mapStateToProps = (state) => ({
    pageData: state.page.pageData
});


export default connect(mapStateToProps,null)(Konkursy);