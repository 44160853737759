import React, {Fragment} from 'react';

const Partners = ({partnersData,csrGradient}) => {
    return (
        <Fragment>
            {
                partnersData.img &&
                <div className="row partners">
                    <div
                        className="col col-xs-12 col-sm-9 list"
                        style={csrGradient}
                    >
                        <h3>{ partnersData.tytulListy}</h3>
                        <img src={partnersData.img} alt="Lista partnerów" />
                    </div>
                </div>
            }
        </Fragment>

    );
};

export default Partners;