import React, {Component} from 'react';
import {connect} from "react-redux";

//actions
import {setPost} from "../../../actions/pageActions";

// components
import Page from '../../Page';
import Player from '../../Player';

//tools
//import classnames from "classnames";

//components
import Cover from "./Sections/Cover";
import Info from "./Sections/Info";
import Rules from "./Sections/Rules";
import Participants from "./Sections/Participants";
import Articles from "./Sections/Articles";
import Partners from "./Sections/Partners";

//config
import { config }  from "../../../config";

class ViewCsr extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            pageData,
            pageOptions,
            playerOpened
        } = this.props;

        const {
            opis,
            zasady,
            uczestnicy,
            slogan_side,
            tytul_partnerzy,
            opis_partnerzy,
            tytul_listy,
            lista_obraz,
            artykuly,
            artykuly_tytul,
            artykuly_podtytul
        } = pageData;

        const csrGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_csr}deg, 
                ${pageOptions.kolor_1_csr} 53%, 
                ${pageOptions.kolor_2_csr} 85% 
            )`};

        const csrGradientTransp = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_csr_transp}deg, 
                ${pageOptions.kolor_1_csr_transp} 53%, 
                ${pageOptions.kolor_2_csr_transp} 85% 
            )`};
        const greenGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_news}deg, 
                ${pageOptions.kolor_1_news} 53%, 
                ${pageOptions.kolor_2_news} 85% 
            )`};
        return (
            <Page pageIndex={config.pageIndex.csr}>

                { playerOpened && <Player /> }

                <article className="page csr content">
                    <div className="boxed">
                        <Cover pageData={pageData}/>
                        <Info
                            opis={opis}
                            csrGradientTransp={csrGradientTransp}/>
                        <Rules
                            zasady={zasady}
                            colors={{
                                csrGradient: csrGradient,
                                green: pageOptions.zielen,
                                greenGradient: greenGradient

                            }} />
                        <Participants
                            uczestnicy={uczestnicy}
                            uczestnicyData={{
                                tytul: tytul_partnerzy,
                                opis: opis_partnerzy,
                            }}
                            slogan={slogan_side}
                            green={pageOptions.kolor_1_news}
                            greenGradient={greenGradient}/>
                        <Articles
                            artykuly={artykuly}
                            artykulyData={{
                                tytul: artykuly_tytul,
                                podtytul: artykuly_podtytul
                            } }
                            slogan={slogan_side}
                            green={pageOptions.kolor_1_news}
                            greenGradient={greenGradient}
                        />
                        <Partners
                            csrGradient={csrGradient}
                            partnersData={{
                            tytulListy: tytul_listy,
                            img: lista_obraz
                        } }/>

                    </div>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions:state.page.pageOptions,
    playerOpened:state.player.playerOpened
});
const mapDispatchToProps = dispatch => ({
    setSelectedPost: value => dispatch( setPost(value))
});


export default connect(mapStateToProps,mapDispatchToProps)(ViewCsr);