import React from 'react';
import {connect} from "react-redux";

// components
import Page from '../../Page';
import PageHeader from "../../PageHeader";
import HoursLabels from "../../HoursLabels";

//tools
import classnames from "classnames";

//config
import { config }  from "../../../config";

const ViewGodziny = (
    {
        pageData,
        pageOptions
    }) => {

    const greenGradient = {
        background: `linear-gradient( 
                ${pageOptions.rotacja_news}deg, 
                ${pageOptions.kolor_1_news} 53%, 
                ${pageOptions.kolor_2_news} 85% 
            )`
    };

    return (
        <Page pageIndex={config.pageIndex.godziny}>
            <article className="page godziny content">

                <PageHeader
                    title={pageData.tytul}
                    subtitle={pageData.podtytul}
                    text={pageData.tekst_naglowka}
                />

                <div className="row hours-row-container center-xs start-md">
                    <HoursLabels />


                {
                    pageData.lista_punktow
                        ?
                        pageData.lista_punktow
                            .map((item,index) =>
                                <div
                                    className={
                                        classnames(
                                            "col col-xs-12 col-sm-6 col-md-2",{
                                                "odd": !(index % 2)
                                            })
                                    }
                                    style={ !(index % 2) ? greenGradient : {}}
                                    key={index}>
                                    <h3>
                                        <img
                                            src={item.ikona}
                                            alt={item.tytul}
                                            width={205}
                                            height={89} />
                                    </h3>
                                    <ul>
                                        {
                                            item.godziny.map((item,index)=>
                                                <li key={index}>
                                                    <header>{item.label}</header>
                                                    <span>{item.hours}
                                                        {item.note &&
                                                        <>
                                                            <br/><span className="small">{item.note}</span>
                                                        </>
                                                        }
                                                </span>

                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>

                            )
                        :
                        []
                }
                </div>

            </article>
        </Page>
    );
};

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(ViewGodziny);