import React from 'react';

const Pharmacy = ({mainColor}) => {
    return (
        <svg version="1.1"
             id="i_pharma"
             xmlns="http://www.w3.org/2000/svg"
             width="143px"
             height="129px"
             viewBox="0 0 143 129" >
            <g>
                <path display="none" fill={mainColor} d="M131.889,45.975C115.785,29.869,94.336,21,71.5,21c-22.837,0-44.282,8.867-60.389,24.975
		c-0.869,0.869-0.869,2.274,0,3.144c0.869,0.869,2.274,0.869,3.145,0C29.521,33.851,49.851,25.444,71.5,25.444
		c21.648,0,41.979,8.407,57.245,23.673c0.434,0.433,1.002,0.651,1.571,0.651s1.139-0.218,1.572-0.651
		C132.758,48.249,132.758,46.842,131.889,45.975"/>
                <path display="none" fill={mainColor} d="M24.18,59.042c-0.869,0.87-0.869,2.274,0,3.144c0.435,0.434,1.003,0.651,1.572,0.651
		s1.138-0.217,1.572-0.651c24.356-24.359,63.99-24.359,88.35,0c0.869,0.869,2.274,0.869,3.144,0c0.869-0.87,0.869-2.274,0-3.144
		C92.729,32.953,50.273,32.949,24.18,59.042"/>
                <path display="none" fill={mainColor} d="M71.5,57.948c-12.951,0-25.113,5.031-34.249,14.166c-0.869,0.869-0.869,2.274,0,3.144
		c0.87,0.869,2.274,0.869,3.144,0c8.295-8.293,19.341-12.862,31.105-12.862c11.764,0,22.81,4.569,31.104,12.862
		c0.435,0.434,1.004,0.651,1.573,0.651s1.139-0.218,1.572-0.651c0.869-0.869,0.869-2.274,0-3.144
		C96.615,62.979,84.451,57.948,71.5,57.948"/>
                <path display="none" fill={mainColor} d="M71.5,78.865c-8.031,0-14.565,6.534-14.565,14.567S63.469,108,71.5,108
		c8.033,0,14.567-6.534,14.567-14.567S79.533,78.865,71.5,78.865 M71.5,103.551c-5.581,0-10.119-4.54-10.119-10.12
		c0-5.581,4.54-10.121,10.119-10.121c5.578,0,10.121,4.54,10.121,10.121C81.621,99.011,77.081,103.551,71.5,103.551"/>
                <path fill={mainColor} d="M71.5,111.923c-12.667,0-24.576-4.933-33.533-13.89S24.077,77.167,24.077,64.5s4.933-24.576,13.89-33.533
		s20.866-13.89,33.533-13.89c11.506,0,22.604,4.173,31.246,11.749c0.577,0.506,0.635,1.383,0.129,1.96
		c-0.506,0.577-1.383,0.635-1.96,0.129C92.778,23.783,82.333,19.856,71.5,19.856c-11.925,0-23.137,4.643-31.568,13.076
		C31.499,41.364,26.854,52.575,26.854,64.5c0,11.925,4.645,23.137,13.077,31.569c8.432,8.432,19.644,13.075,31.568,13.075
		s23.137-4.644,31.568-13.075c8.433-8.433,13.076-19.645,13.076-31.569c0-10.926-3.988-21.441-11.229-29.607
		c-0.51-0.574-0.457-1.452,0.118-1.961c0.573-0.509,1.452-0.456,1.961,0.118c7.693,8.675,11.928,19.844,11.928,31.45
		c0,12.667-4.933,24.576-13.89,33.533S84.167,111.923,71.5,111.923"/>
                <path fill={mainColor} d="M71.5,96.488c-4.517,0-8.189-3.674-8.189-8.189c0,0-0.313-2.863,1.39-2.863
		c1.701,0,1.389,2.863,1.389,2.863c0,2.983,2.428,5.411,5.411,5.411s5.41-2.428,5.41-5.411V74.632c0-2.604,2.118-4.722,4.722-4.722
		h13.667c2.983,0,5.411-2.427,5.411-5.41c0-2.984-2.428-5.411-5.411-5.411H81.632c-2.604,0-4.722-2.118-4.722-4.722V40.7
		c0-2.983-2.427-5.41-5.41-5.41s-5.411,2.427-5.411,5.41v13.667c0,2.604-2.117,4.722-4.721,4.722H47.701
		c-2.983,0-5.41,2.427-5.41,5.411c0,2.982,2.427,5.41,5.41,5.41h13.667c2.604,0,4.721,2.118,4.721,4.722v6.583
		c0,0.767-0.621,1.39-1.389,1.39s-1.39-0.623-1.39-1.39v-6.583c0-1.071-0.872-1.942-1.942-1.942H47.701
		c-4.516,0-8.189-3.674-8.189-8.189c0-4.516,3.674-8.189,8.189-8.189h13.667c1.07,0,1.942-0.872,1.942-1.944V40.7
		c0-4.515,3.673-8.189,8.189-8.189c4.516,0,8.189,3.674,8.189,8.189v13.667c0,1.072,0.871,1.944,1.942,1.944h13.667
		c4.516,0,8.189,3.673,8.189,8.189c0,4.515-3.674,8.189-8.189,8.189H81.632c-1.071,0-1.942,0.872-1.942,1.942v13.667
		C79.689,92.814,76.016,96.488,71.5,96.488"/>
            </g>
        </svg>
    );
};

export default Pharmacy;