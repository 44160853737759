import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../../Page';
import Gallery from '../../../Gallery';
import Back from "../../../Back";

//contexts
import ResponsivenessContext from '../../../../contexts/Responsiveness/ResponsivenessContext';

//config
import { config }  from "../../../../config";
import { paths }  from "../../../../config/paths";

class Rule extends Component {
    render() {
        const {
            pageData,
            pageOptions,
            selectedPost,
            match } = this.props;

        const seasonGradientOpacity = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_naglowki}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`
        };

        return (
            <ResponsivenessContext.Consumer>
                {
                    isMobile => (
                        <Page pageIndex={config.pageIndex.csrZasady}
                              restName="zasady"
                              postUrl={ match ? match.params.ruleId : selectedPost }
                        >
                            <Back
                                bg={pageOptions.zielen}
                                link={paths.csr}
                            />
                            <article className="page news rule content">
                                { !isMobile &&
                                <div className="row cover-row-container">
                                    <div className={"col col-xs-12"}>
                                        <figure>
                                            {
                                                pageData.okladka_full ?
                                                    <img src={pageData.okladka_full} alt={'Zasada'}/>
                                                    :
                                                    <img src={pageOptions.zaslepka_news} alt={'Zasada'}/>
                                            }

                                        </figure>
                                    </div>
                                </div>
                                }
                                <div className="row center-xs start-sm">
                                    <div className={"col col-xs-12 col-md-4"}>
                                        <div className="panel" style={seasonGradientOpacity}/>
                                    </div>
                                    <div className={'col col-xs-12 col-md-5'}>
                                        <article>
                                            <h2>{pageData.tytul}</h2>
                                            <p dangerouslySetInnerHTML={{__html: pageData.tekst}} />
                                        </article>
                                    </div>
                                </div>

                                {
                                    pageData.posiada_galerie &&
                                    <Gallery
                                        pageData={pageData}
                                        pageOptions={pageOptions}
                                        initialAmount={6}
                                    />
                                }
                            </article>

                        </Page>
                    )
                }

            </ResponsivenessContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions,
    selectedPost: state.page.selectedPost,
    selectedPostCategory: state.page.selectedPostCategory
});


export default connect(mapStateToProps,null)(Rule);