import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../Page';
import PageHeader from "../../PageHeader";
import ChTurawa from "./ChTurawa";
import Pasaz from "./Pasaz";
import Zarzadca from "./Zarzadca";
import Franczyza from "./Franczyza";

//config
import { config }  from "../../../config";

class ViewKontakt extends Component {
    render() {
        const {
            pageData,
            pageOptions
        } = this.props;

        const seasonTextColor = pageOptions.kolor_sezonowy_tekst;

        const seasonGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_naglowki}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`
        };

        const greenGradient = {
            background: `linear-gradient( 
                            ${pageOptions.rotacja_news}deg, 
                            ${pageOptions.kolor_1_news} 53%, 
                            ${pageOptions.kolor_2_news} 85% 
                        )`};

        return (
            <Page pageIndex={config.pageIndex.kontakt}>
                <article className="page kontakt content">
                    <PageHeader
                        title={pageData.tytul}
                        subtitle={pageData.podtytul}
                    />
                    <div className="row content-row-container middle-xs center-xs start-md">

                        <div className={"col col-xs-12 col-sm-6 col-md-4 empty"}>
                            <figure style={greenGradient}/>
                        </div>

                        <ChTurawa
                            pageData={pageData}
                            title={pageOptions.teksty_kontakt_z_zarzadca_label}
                            textColor={seasonTextColor}
                            textSubmit={pageOptions.teksty_wyslij_wiadomosc}
                            seasonGradient={seasonGradient}
                        />

                        <Zarzadca
                            pageData={pageData}
                            title={pageOptions.teksty_obiekt_zarzadzany_przez}
                        />

                        <div className={"col col-xs-12 col-sm-6 col-md-4 empty"}/>

                        <Franczyza pageData={pageData} pageOptions={pageOptions}/>

                        <Pasaz
                            pageData={pageData}
                            title={pageOptions.teksty_kontakt_pasaz}
                            textColor={seasonTextColor}
                            seasonGradient={seasonGradient}
                        />
                    </div>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions:state.page.pageOptions
});


export default connect(mapStateToProps,null)(ViewKontakt);