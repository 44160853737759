export const TOGGLE_LIGHTBOX = 'lightboxToggle';
export const SET_SELECTED_GALLERY = 'setLightboxData';

export const toggleLightbox = () => ({
    type: TOGGLE_LIGHTBOX
});

export const selectedGallery = gallery => ({
    type: SET_SELECTED_GALLERY,
    gallery
});