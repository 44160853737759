import {
    TOGGLE_OVERLAY,
    TOGGLE_HOME_MENU,
    SET_SEARCH_FOCUS,
    SET_PAGE_DATA,
    SET_POSTS_DATA,
    SET_PAGE_OPTIONS,
    SET_HOVERED_MENU_ITEM,
    SET_POST,
    SET_HOVERED_POST,
    SET_FILTER_YEAR,
    SELECT_POSTS_PAGE,
    SET_NEWSLETTER_VISIBILITY,
    SET_MC_NEWSLETTER_VISIBILITY,
    SET_CONTACTFORM_VISIBILITY
} from "../actions/pageActions";

const currentYr =  new Date().getFullYear();
const yr = currentYr.toString();

const defaultState = {
    displayOverlay: false,
    menuOpened: false,
    searchFocused: false,
    pageData: {},
    pageOptions: {},
    pageDataSlidesList:[],
    pageDataLinksList:[],
    pageDataHoursList:[],
    pageDataSocialList:[],
    pageDataBusesList:[],
    pageDataFeaturesList:[],
    pageDataPosts:[],
    selectedPost:null,
    selectedPostModalPosX:'0',
    hoveredPost:'',
    selectedPostCategory:'news',
    focusedMenuItem: null,
    popupData: {},
    paginationYear: yr,
    pageDataPostsYearList:[yr],
    paginationTotalPosts: 12,
    paginationPagesAmount: null,
    paginationPagesPerPage: 6,
    paginationCurrentPage: 1,
    newsletterVisible:false,
    mcNewsletterVisible:false,
    contactFormVisible:false
};


const page = (state = defaultState, action = undefined) => {
    const {type} = action;

    function toggleMenu (state) {
        const newState = Object.assign({}, state);
        newState.menuOpened = !newState.menuOpened;
        return newState;
    }

    switch (type) {
        case TOGGLE_HOME_MENU: {
            return toggleMenu(state)
        }
        case TOGGLE_OVERLAY: {
            return {
                ...state,
                displayOverlay: action.value
            }
        }
        case SET_SEARCH_FOCUS:{
            return {
                ...state,
                searchFocused: action.value
            }
        }
        case SET_PAGE_DATA: {
            return {
                ...state,
                pageData: action.data,
                pageDataSlidesList: action.data.lista,
                pageDataLinksList: action.data.lista_kafli,
                pageDataHoursList: action.data.lista_punkty,
                pageDataSocialList: action.data.social_media,
                pageDataBusesList: action.data.rozklady_jazdy,
                pageDataFeaturesList: action.data.lista_udogodnien,
            }
        }
        case SET_POSTS_DATA: {
            return {
                ...state,
                pageDataPosts: action.data

            }
        }
        case SET_PAGE_OPTIONS: {
            return {
                ...state,
                pageOptions: action.data
            }
        }
        case SET_HOVERED_MENU_ITEM: {
            return {
                ...state,
                focusedMenuItem: action.value
            }
        }
        case SET_POST: {
            return {
                ...state,
                selectedPost: action.url,
                selectedPostCategory: action.cat
            }
        }
        case SET_HOVERED_POST: {
            return {
                ...state,
                hoveredPost: action.url
            }
        }
        case SET_FILTER_YEAR: {
            return {
                ...state,
                paginationYear: action.value
            }
        }
        case SELECT_POSTS_PAGE: {
            return {
                ...state,
                paginationCurrentPage: action.value
            }
        }
        case SET_NEWSLETTER_VISIBILITY:{
            return {
                ...state,
                newsletterVisible: action.value
            }
        }
        case SET_MC_NEWSLETTER_VISIBILITY:{
            return {
                ...state,
                mcNewsletterVisible: action.value
            }
        }
        case SET_CONTACTFORM_VISIBILITY:{
            return {
                ...state,
                contactFormVisible: action.value
            }
        }
        default:
            return state;
    }
};

export default page;