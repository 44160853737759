import React from 'react';

const CounterMembers = ({mainColor}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" width="81.318px" height="82px"
             viewBox="215.342 215 81.318 82" >
            <g>
                <g>
                    <path fill={mainColor} d="M291.204,281.366c0.556-1.063,0.872-2.271,0.872-3.552v-3.057c0-3.324-2.118-6.162-5.074-7.238
                        c-0.596-3.279-2.486-6.146-5.256-7.998c0.556-1.063,0.871-2.271,0.871-3.551v-3.056c0-4.246-3.454-7.7-7.7-7.7s-7.7,3.454-7.7,7.7
                        v3.056c0,1.28,0.315,2.488,0.871,3.552c-0.999,0.668-1.883,1.469-2.634,2.373c-0.756-0.912-1.639-1.709-2.625-2.371
                        c0.556-1.063,0.872-2.272,0.872-3.554v-3.056c0-4.246-3.454-7.7-7.7-7.7s-7.701,3.454-7.701,7.7v3.056
                        c0,1.281,0.316,2.49,0.873,3.554c-0.992,0.666-1.879,1.468-2.638,2.388c-0.752-0.911-1.634-1.719-2.623-2.387
                        c0.557-1.064,0.873-2.272,0.873-3.555v-3.056c0-4.246-3.455-7.7-7.701-7.7c-4.246,0-7.7,3.454-7.7,7.7v3.056
                        c0,1.28,0.316,2.488,0.871,3.552c-2.767,1.85-4.659,4.719-5.255,7.997c-2.957,1.076-5.074,3.914-5.074,7.238v3.057
                        c0,1.281,0.316,2.488,0.872,3.552c-3.4,2.271-5.456,6.053-5.456,10.201v3.845c0,0.877,0.711,1.588,1.588,1.588h18.917h18.917
                        h21.393h18.917c0.877,0,1.588-0.711,1.588-1.588v-3.845C296.66,287.419,294.604,283.638,291.204,281.366L291.204,281.366z
                         M288.899,274.758v3.057c0,2.494-2.029,4.523-4.523,4.523c-2.495,0-4.524-2.029-4.524-4.523v-3.057
                        c0-2.494,2.029-4.523,4.524-4.523C286.87,270.234,288.899,272.264,288.899,274.758z M283.632,267.094
                        c-3.898,0.376-6.957,3.669-6.957,7.664v3.057c0,1.281,0.316,2.49,0.873,3.554c-0.985,0.661-1.866,1.458-2.622,2.37
                        c-0.75-0.903-1.637-1.702-2.638-2.372c0.556-1.063,0.871-2.271,0.871-3.552v-3.057c0-3.995-3.058-7.288-6.957-7.664
                        c0.645-2.092,2.045-3.928,3.922-5.103c1.316,1.05,2.981,1.68,4.793,1.68s3.477-0.63,4.794-1.68
                        C281.588,263.166,282.988,265.001,283.632,267.094z M260.935,277.814v-3.057c0-2.494,2.029-4.523,4.524-4.523
                        c2.494,0,4.523,2.029,4.523,4.523v3.057c0,2.494-2.029,4.523-4.523,4.523C262.964,282.338,260.935,280.309,260.935,277.814z
                         M270.394,252.915c0-2.495,2.029-4.524,4.523-4.524c2.495,0,4.524,2.029,4.524,4.524v3.056c0,2.494-2.029,4.524-4.524,4.524
                        c-2.494,0-4.523-2.03-4.523-4.524V252.915z M251.477,252.915c0-2.495,2.03-4.524,4.524-4.524c2.495,0,4.524,2.029,4.524,4.524
                        v3.056c0,2.494-2.029,4.524-4.524,4.524s-4.524-2.03-4.524-4.524V252.915L251.477,252.915z M251.208,261.991
                        c1.316,1.05,2.982,1.68,4.793,1.68c1.811,0,3.477-0.63,4.793-1.68c1.151,0.717,2.126,1.675,2.863,2.819
                        c-0.375,0.859-0.653,1.768-0.825,2.709c-2.956,1.076-5.073,3.914-5.073,7.238v3.057c0,1.281,0.315,2.488,0.871,3.552
                        c-1.001,0.669-1.887,1.468-2.637,2.37c-0.755-0.912-1.637-1.707-2.623-2.368c0.556-1.063,0.873-2.272,0.873-3.554v-3.057
                        c0-3.325-2.118-6.164-5.076-7.239c-0.172-0.931-0.454-1.834-0.833-2.692C249.073,263.675,250.051,262.711,251.208,261.991z
                         M242.019,277.814v-3.057c0-2.494,2.029-4.523,4.524-4.523s4.524,2.029,4.524,4.523v3.057c0,2.494-2.03,4.523-4.524,4.523
                        S242.019,280.309,242.019,277.814z M232.561,252.915c0-2.495,2.029-4.524,4.524-4.524s4.524,2.029,4.524,4.524v3.056
                        c0,2.494-2.029,4.524-4.524,4.524s-4.524-2.03-4.524-4.524V252.915z M232.291,261.991c1.316,1.05,2.982,1.68,4.793,1.68
                        s3.477-0.63,4.793-1.68c1.878,1.175,3.278,3.01,3.921,5.103c-3.899,0.376-6.958,3.669-6.958,7.664v3.057
                        c0,1.281,0.316,2.488,0.872,3.552c-1.001,0.669-1.887,1.468-2.637,2.369c-0.755-0.911-1.637-1.706-2.623-2.367
                        c0.556-1.063,0.873-2.272,0.873-3.554v-3.057c0-3.995-3.059-7.288-6.957-7.664C229.014,265.002,230.415,263.166,232.291,261.991z
                         M223.102,274.758c0-2.494,2.03-4.523,4.524-4.523s4.524,2.029,4.524,4.523v3.057c0,2.494-2.029,4.523-4.524,4.523
                        s-4.524-2.029-4.524-4.523V274.758z M218.518,291.567c0-3.181,1.633-6.07,4.314-7.733c1.316,1.051,2.983,1.681,4.794,1.681
                        c1.811,0,3.477-0.63,4.793-1.68c1.151,0.716,2.124,1.672,2.861,2.814c-0.664,1.521-1.022,3.187-1.022,4.918v2.256h-15.74V291.567z
                         M237.435,291.567c0-3.181,1.633-6.07,4.314-7.733c1.316,1.051,2.982,1.681,4.794,1.681c1.811,0,3.477-0.63,4.793-1.68
                        c1.15,0.716,2.123,1.673,2.86,2.815c-0.664,1.521-1.022,3.187-1.022,4.917v2.257h-15.74L237.435,291.567L237.435,291.567z
                         M256.351,291.567c0-3.181,1.634-6.07,4.314-7.733c1.316,1.051,2.982,1.681,4.794,1.681s3.478-0.63,4.794-1.681
                        c2.681,1.663,4.314,4.555,4.314,7.733v2.256h-18.217V291.567z M293.483,293.823h-15.739v-2.257c0-1.729-0.359-3.396-1.022-4.916
                        c0.737-1.143,1.712-2.101,2.86-2.815c1.316,1.05,2.982,1.68,4.793,1.68c1.812,0,3.478-0.63,4.794-1.681
                        c2.682,1.662,4.314,4.553,4.314,7.732V293.823L293.483,293.823z"/>
                    <path fill={mainColor} d="M243.175,228.502c0.825-0.298,1.252-1.208,0.954-2.033c-0.999-2.765-0.638-4.953,1.103-6.688
                        c2.147-2.141,5.642-2.141,7.79,0c1.671,1.665,4.288,1.665,5.959,0c2.147-2.141,5.642-2.141,7.789,0
                        c1.56,1.554,2.009,3.463,1.373,5.837c-1.591,5.949-8.973,12.023-12.141,13.222c-0.906-0.346-2.171-1.101-3.49-2.093
                        c-0.701-0.527-1.697-0.386-2.224,0.315c-0.527,0.701-0.386,1.697,0.315,2.224c1.896,1.425,3.667,2.404,4.988,2.757
                        c0.134,0.036,0.272,0.054,0.41,0.054s0.275-0.018,0.41-0.054c2.113-0.564,5.332-2.728,8.199-5.512
                        c1.969-1.912,5.444-5.765,6.602-10.091c0.928-3.469,0.167-6.549-2.199-8.908c-3.385-3.374-8.892-3.374-12.275,0
                        c-0.483,0.482-1.107,0.365-1.473,0c-3.384-3.374-8.891-3.373-12.274,0c-2.629,2.621-3.269,6.085-1.848,10.018
                        C241.439,228.373,242.35,228.8,243.175,228.502z"/>
                    <path fill={mainColor} d="M246.362,231.519c-0.877,0-1.587,0.711-1.587,1.588c0,0.877,0.711,1.588,1.588,1.588c0.877,0,1.588-0.711,1.588-1.588
                        C247.952,232.23,247.241,231.519,246.362,231.519L246.362,231.519z"/>
                </g>
            </g>
            </svg>
    );
};

export default CounterMembers;