import React from 'react';
import classnames from 'classnames';
import MediaQuery from "react-responsive";

const PageHeader = (
    {
        id,
        title,
        subtitle,
        text,
        seasonGradient,
        seasonBlockWidth,
        seasonBlockHeight
    }) => {
    return (
        <>
            <header className={
                classnames(
                    "page-header",
                    id
                )}>
                <h2 dangerouslySetInnerHTML={{__html:title}} />
                { subtitle && <p className="strong" dangerouslySetInnerHTML={{__html:subtitle}} /> }
                { text && <p dangerouslySetInnerHTML={{__html:text}} /> }
            </header>

            {
                seasonGradient &&
                <MediaQuery minWidth={768}>
                    <figure
                        aria-hidden="true"
                        className="season-block"
                        style={{
                            ...seasonGradient,
                            ...{
                                width: seasonBlockWidth,
                                height: seasonBlockHeight
                            }}} />
                </MediaQuery>
            }

        </>
    );
};

export default PageHeader;