import React from 'react';

const TableContent = (
    {
        hours,
        kolor,
        pageOptions
    }
    ) => {
    return (
        <main>
            <div className="row hd" style={{ color:`rgb(${kolor})` }}>
                <div className="col col-xs-4">
                    <span>{pageOptions.teksty_dni_robocze}</span>
                </div>
                <div className="col col-xs-4">
                    <span>{pageOptions.teksty_wakacje_dni_robocze}</span>
                </div>
                <div className="col col-xs-4">
                    <span>{pageOptions.teksty_soboty}</span>
                </div>
                {/* <div className="col col-xs-2">
                    <span>{pageOptions.teksty_niedziela_handlowa}</span>
                </div>
                <div className="col col-xs-2">
                    <span>{pageOptions.teksty_niedziela_niehandlowa}</span>
                </div> */}
            </div>

            {
                hours.map( (item,index) =>
                    <div
                        className="row row-hour"
                        key={index}
                        style={{ backgroundColor: `rgba(${kolor},.2)` }}
                    >
                        <div className="col col-xs-4">
                            <strong>{item.godzina}</strong>
                            <span>{item.dni_robocze}</span>
                        </div>
                        <div className="col col-xs-4">
                            <strong>{item.godzina}</strong>
                            <span>{item.wakacje_dni_robocze}</span>
                        </div>
                        <div className="col col-xs-4">
                            <strong>{item.godzina}</strong>
                            <span>{item.soboty}</span>
                        </div>
                        {/* <div className="col col-xs-2">
                            <strong>{item.godzina}</strong>
                            <span>{item.niedziela_handlowa}</span>
                        </div>
                        <div className="col col-xs-2">
                            <strong>{item.godzina}</strong>
                            <span>{item.niedziela_niehandlowa}</span>
                        </div> */}
                    </div>

                )
            }

        </main>
    );
};

export default TableContent;