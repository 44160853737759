import React from 'react';

const CounterMoney = ({mainColor}) => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px" width="83.672px" height="82px"
             viewBox="852.959 646.719 83.672 82" >
            <g>
                <g>
                    <path fill={mainColor} d="M922.005,690.107c-0.672,0-1.218,0.545-1.218,1.218c0,0.853-0.693,1.546-1.546,1.546s-1.546-0.693-1.546-1.546
                        c0-0.673-0.545-1.218-1.218-1.218s-1.218,0.545-1.218,1.218c0,2.195,1.786,3.981,3.981,3.981s3.981-1.786,3.981-3.981
                        C923.223,690.652,922.678,690.107,922.005,690.107z"/>
                </g>
            </g>
                        <g>
                <g>
                    <path fill={mainColor} d="M933.436,690.306l-3.428-1.098c-0.439-0.141-0.785-0.491-0.926-0.938c-0.722-2.297-1.812-4.453-3.239-6.409
                        c-0.65-0.892-1.374-1.743-2.165-2.553c0.506-2.218,0.781-4.729,0.745-6.897c-0.051-3.103-0.671-4.922-1.896-5.564
                        c-0.659-0.346-2.666-1.396-13.528,3.576c-0.257,0.118-0.497,0.263-0.72,0.429c-0.732-0.197-1.48-0.379-2.233-0.542
                        c-0.091-0.02-0.185-0.038-0.275-0.057c0.88-1.923,1.394-4.011,1.49-6.167c0.03-0.672-0.49-1.241-1.162-1.271
                        c-0.67-0.033-1.241,0.49-1.271,1.162c-0.159,3.545-1.629,6.88-4.141,9.392c-1.5,1.5-3.257,2.59-5.129,3.276
                        c-3.248-0.456-6.555-0.456-9.802,0c-1.873-0.686-3.629-1.776-5.129-3.276c-2.679-2.679-4.155-6.241-4.155-10.03
                        s1.476-7.351,4.155-10.03s6.241-4.155,10.03-4.155c3.789,0,7.352,1.476,10.03,4.155c1.077,1.077,1.967,2.306,2.645,3.651
                        c0.303,0.601,1.035,0.843,1.635,0.54c0.602-0.302,0.843-1.035,0.54-1.635c-0.794-1.579-1.837-3.018-3.097-4.278
                        c-3.14-3.139-7.313-4.868-11.752-4.868s-8.613,1.729-11.752,4.868s-4.868,7.313-4.868,11.752s1.729,8.613,4.868,11.752
                        c0.905,0.905,1.891,1.684,2.933,2.336c0,0,0,0,0.001,0c-0.757,0.197-1.509,0.417-2.251,0.666
                        c-0.638,0.213-0.982,0.903-0.769,1.541c0.213,0.638,0.903,0.982,1.541,0.769c6.641-2.22,13.956-2.22,20.597,0
                        c0.128,0.042,0.258,0.063,0.386,0.063c0.51,0,0.984-0.322,1.155-0.832c0.213-0.638-0.131-1.328-0.77-1.541
                        c-0.743-0.249-1.494-0.47-2.251-0.666c0,0,0,0,0.001,0c1.042-0.652,2.026-1.431,2.933-2.336c0.801-0.801,1.508-1.676,2.122-2.606
                        c0.336,0.065,0.669,0.134,0.997,0.205c0.438,0.095,0.873,0.197,1.304,0.304c-0.013,0.053-0.027,0.106-0.038,0.16
                        c-0.594,2.818-0.556,5.311,0.114,7.408c0.603,1.885,1.688,3.413,3.229,4.54c1.815,1.328,4.008,1.902,5.993,1.902
                        c1.495,0,2.872-0.325,3.885-0.898c1.145-0.648,2.104-2.014,2.862-4.065c0.351,0.41,0.684,0.829,0.997,1.258
                        c1.271,1.741,2.241,3.66,2.884,5.703c0.377,1.2,1.313,2.145,2.506,2.526l3.427,1.098c0.899,0.288,1.504,1.116,1.504,2.061v5.683
                        c0,0.944-0.604,1.772-1.504,2.061l-5.521,1.769c-0.884,0.283-1.632,0.89-2.105,1.71c-2.169,3.749-5.58,6.852-10.138,9.221
                        c-0.527,0.274-0.892,0.765-1.002,1.346l-1.769,9.364c-0.049,0.258-0.274,0.445-0.537,0.445h-5.75
                        c-0.263,0-0.489-0.188-0.538-0.445l-0.979-5.176c-0.192-1.018-1.147-1.694-2.176-1.538c-2.477,0.374-5.029,0.563-7.585,0.563
                        c-1.902,0-3.78-0.105-5.581-0.315c-0.996-0.114-1.924,0.564-2.11,1.548l-0.931,4.918c-0.049,0.258-0.275,0.445-0.538,0.445h-5.75
                        c-0.263,0-0.489-0.188-0.538-0.449l-1.703-8.845c-0.109-0.57-0.467-1.055-0.982-1.329c-7.77-4.148-11.876-11.078-11.876-20.04
                        c0-4.528,0.991-8.532,2.944-11.901c1.823-3.144,4.486-5.743,7.915-7.727c0.582-0.337,0.781-1.082,0.444-1.664
                        c-0.337-0.582-1.082-0.781-1.664-0.444c-6.648,3.846-10.657,9.779-11.761,17.314v-0.001c-0.117-0.011-0.234-0.021-0.351-0.032
                        c0.021-0.813-0.126-1.634-0.446-2.43c-0.707-1.756-2.158-3.024-3.697-3.229c-1.213-0.163-2.368,0.353-3.169,1.411
                        c-1.162,1.535-0.973,2.75-0.609,3.499c0.613,1.264,2.19,2.158,4.806,2.72c-0.072,0.107-0.151,0.216-0.238,0.325
                        c-1.546,1.938-3.367,2.367-4.549,2.407c-0.692,0.023-1.247,0.579-1.247,1.271v0.006c0,0.684,0.539,1.241,1.222,1.272
                        c0.087,0.004,0.179,0.006,0.274,0.006c1.496,0,3.898-0.552,6.205-3.444c0.364-0.457,0.661-0.938,0.889-1.437
                        c0.229,0.026,0.45,0.048,0.662,0.067c0,0,0,0,0-0.001c-0.042,0.66-0.064,1.329-0.064,2.011c0,5.07,1.209,9.592,3.593,13.438
                        c2.198,3.546,5.345,6.449,9.356,8.634l1.655,8.598c0.267,1.407,1.499,2.428,2.931,2.428h5.75c1.432,0,2.664-1.021,2.931-2.428
                        l0.841-4.446c1.748,0.186,3.558,0.279,5.387,0.279c2.511,0,5.018-0.175,7.461-0.521l0.888,4.688
                        c0.266,1.406,1.498,2.428,2.93,2.428h5.751c1.432,0,2.664-1.021,2.93-2.429l1.723-9.117c4.864-2.572,8.531-5.952,10.9-10.047
                        c0.171-0.295,0.434-0.512,0.74-0.609l5.522-1.769c1.911-0.613,3.195-2.373,3.195-4.38v-5.684
                        C936.631,692.678,935.347,690.918,933.436,690.306z M921.805,675.834c-0.469,4.008-1.726,7.437-2.989,8.152
                        c-1.436,0.813-4.75,0.971-7.239-0.85c-2.492-1.823-3.321-5.101-2.398-9.479l0,0c0.092-0.44,0.404-0.82,0.835-1.018
                        c6.097-2.791,9.611-3.703,10.917-3.703c0.197,0,0.344,0.021,0.441,0.059C921.764,669.413,922.278,671.789,921.805,675.834z
                         M856.378,689.353c-0.126-0.26,0.171-0.716,0.36-0.966c0.299-0.395,0.574-0.476,0.782-0.476c0.044,0,0.085,0.004,0.123,0.009
                        c0.56,0.075,1.345,0.69,1.76,1.724c0.098,0.243,0.225,0.646,0.254,1.148C857.972,690.444,856.662,689.94,856.378,689.353z"/>
                </g>
            </g>
                        <g>
                <g>
                    <path fill={mainColor} d="M891.84,661.944v-4.357c1.503,0.107,2.061,0.794,2.576,0.794c0.644,0,0.944-0.815,0.944-1.224
                        c0-1.052-2.061-1.502-3.521-1.545v-0.58c0-0.257-0.322-0.494-0.644-0.494c-0.365,0-0.623,0.236-0.623,0.494v0.623
                        c-2.039,0.215-4.079,1.288-4.079,3.993c0,2.748,2.146,3.521,4.079,4.207v5.045c-2.189-0.172-2.769-1.674-3.478-1.674
                        c-0.537,0-0.987,0.708-0.987,1.224c0,1.052,1.803,2.49,4.465,2.533l0,0v0.666c0,0.258,0.258,0.494,0.623,0.494
                        c0.322,0,0.644-0.236,0.644-0.494v-0.729c2.318-0.322,3.907-1.782,3.907-4.422C895.747,663.575,893.708,662.63,891.84,661.944z
                         M890.702,661.536c-1.138-0.43-2.061-0.88-2.061-2.104c0-1.116,0.859-1.653,2.061-1.803V661.536z M891.711,668.856v-4.529
                        c1.052,0.451,1.889,1.052,1.889,2.404C893.6,667.955,892.87,668.641,891.711,668.856z"/>
                </g>
            </g>
            </svg>
    );
};

export default CounterMoney;