import React from 'react';

const Instagram = ({mainColor}) => {
    return (
        <svg
            version="1.1"
            id="soc_insta"
            xmlns="http://www.w3.org/2000/svg"
            width="100px"
            height="100px"
            viewBox="0 0 100 100">
            <path fill={mainColor} d="M59.57,50c0,5.285-4.285,9.57-9.57,9.57c-5.286,0-9.57-4.285-9.57-9.57c0-5.286,4.285-9.57,9.57-9.57
			C55.285,40.43,59.57,44.714,59.57,50z"/>
            <path fill={mainColor} d="M72.382,33.067c-0.46-1.247-1.194-2.375-2.147-3.301c-0.927-0.954-2.055-1.688-3.302-2.148
			c-1.012-0.393-2.531-0.86-5.331-0.988c-3.027-0.138-3.936-0.168-11.602-0.168c-7.667,0-8.575,0.029-11.602,0.167
			c-2.799,0.128-4.32,0.596-5.331,0.989c-1.248,0.46-2.376,1.194-3.301,2.148c-0.954,0.926-1.688,2.054-2.148,3.301
			c-0.393,1.012-0.861,2.532-0.988,5.332c-0.138,3.027-0.167,3.935-0.167,11.602c0,7.666,0.03,8.573,0.167,11.602
			c0.127,2.8,0.595,4.319,0.988,5.331c0.461,1.247,1.194,2.375,2.147,3.301c0.926,0.954,2.054,1.688,3.301,2.148
			c1.012,0.394,2.532,0.861,5.332,0.988c3.027,0.138,3.935,0.167,11.601,0.167c7.668,0,8.575-0.029,11.603-0.167
			c2.799-0.127,4.319-0.595,5.331-0.988c2.504-0.967,4.483-2.945,5.449-5.449c0.393-1.012,0.86-2.531,0.988-5.331
			c0.139-3.028,0.167-3.936,0.167-11.602c0-7.667-0.028-8.575-0.167-11.602C73.243,35.6,72.775,34.079,72.382,33.067z M50,64.743
			c-8.143,0-14.744-6.601-14.744-14.743c0-8.143,6.601-14.743,14.744-14.743c8.143,0,14.743,6.6,14.743,14.743
			C64.743,58.143,58.143,64.743,50,64.743z M65.326,38.12c-1.903,0-3.445-1.543-3.445-3.445c0-1.903,1.542-3.446,3.445-3.446
			c1.902,0,3.445,1.543,3.445,3.446C68.771,36.577,67.229,38.12,65.326,38.12z"/>
            <path fill={mainColor} d="M50,0C22.39,0,0,22.39,0,50c0,27.61,22.39,50,50,50c27.61,0,50-22.39,50-50C100,22.39,77.61,0,50,0z
			 M78.538,61.837c-0.14,3.057-0.625,5.144-1.335,6.97c-1.491,3.856-4.54,6.905-8.396,8.396c-1.826,0.71-3.913,1.195-6.969,1.335
			c-3.062,0.14-4.04,0.173-11.837,0.173c-7.798,0-8.775-0.033-11.838-0.173c-3.056-0.14-5.143-0.625-6.969-1.335
			c-1.917-0.721-3.651-1.851-5.086-3.312c-1.46-1.434-2.59-3.169-3.311-5.085c-0.709-1.826-1.195-3.913-1.334-6.969
			c-0.141-3.063-0.174-4.041-0.174-11.838c0-7.797,0.033-8.775,0.173-11.837c0.139-3.056,0.624-5.143,1.333-6.97
			c0.721-1.916,1.852-3.651,3.313-5.085c1.434-1.461,3.169-2.591,5.085-3.312c1.827-0.709,3.914-1.194,6.97-1.334
			c3.062-0.14,4.04-0.173,11.837-0.173c7.797,0,8.775,0.034,11.837,0.174c3.057,0.139,5.144,0.624,6.97,1.333
			c1.916,0.721,3.651,1.852,5.086,3.313c1.46,1.435,2.591,3.169,3.311,5.085c0.711,1.827,1.195,3.914,1.335,6.97
			c0.14,3.062,0.173,4.04,0.173,11.837C78.711,57.797,78.678,58.775,78.538,61.837z"/>
        </svg>
    );
};

export default Instagram;