import React,{ Component } from 'react';
import { connect } from "react-redux";

//actions
import { setContactFormVisibility } from "../../actions/pageActions";

//svg
import MenuClose from "../../svg/MenuClose";

//tools
import classnames from "classnames";

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {            
            inputName: {
                focused:false,
                text:null,
                valid: true
            },
            inputEmail: {
                focused:false,
                text:null,
                valid: true,
                errorType:null
            },
            textarea: {
                focused:false,
                text:null,
                valid: true
            },
            inputChecked:false,
            checkboxValid: true,
            formValid: true,
            submitMessage: false
        };
        this.validateInputs = this.validateInputs.bind(this);
    }

    validateInputs  = () => {

        const regEmail = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

        this.setState({submitMessage: true});

        this.setState({
            inputName: {
                ...this.state.inputName,
                valid:this.state.inputName.text,
                errorType: this.state.inputName.text ? null : 'required'
            },
            inputEmail: {
                ...this.state.inputEmail,
                valid: this.state.inputEmail.text && regEmail.test(this.state.inputEmail.text),
                errorType: (this.state.inputEmail.text && !regEmail.test(this.state.inputEmail.text)) ? 'pattern' : 'required'
            },
            textarea: {
                ...this.state.textarea,
                valid:this.state.textarea.text,
                errorType: this.state.textarea.text ? null : 'required'
            }
        });

        // field - disclaimer

        this.setState({
            checkboxValid: this.state.inputChecked,
            submitMessage: this.state.inputChecked
        });


    };



    render() {

        const { pageOptions } = this.props;
        const formValid =
            this.state.inputName.valid &&
            this.state.inputEmail.valid &&
            this.state.textarea.valid &&
            this.state.checkboxValid;

        return (
            <div className={classnames(
                "form-container contact",{
                    "sent": formValid && this.state.submitMessage
                }
            )}>

                <nav onClick={ ()=> this.props.setContactFormVisibility(false) }>
                    <MenuClose mainColor="#000000" size={14}/>
                </nav>


                    <div className="row head center-xs">
                        <div className="col col-xs-12">
                            <h2>{pageOptions.teksty_napisz_do_nas}</h2>
                        </div>
                    </div>

                {
                    (this.state.formValid && !this.state.submitMessage) ?
                    <div className="row text center-xs">
                        <div className={classnames(
                            "col text-input col-xs-12 ",{
                                "error": !this.state.inputName.valid
                            }
                        )}>
                            <input
                                type="text"
                                id="contact-text-input"
                                placeholder={pageOptions.teksty_form_label_imie}
                                maxLength={25}
                                onFocus={
                                    ()=>this.setState({
                                        inputName: {
                                            ...this.state.inputName,
                                            focused: true,
                                            valid:true
                                        }
                                    })
                                }
                                onBlur={
                                    ()=>this.setState({
                                        inputName: {
                                            ...this.state.inputName,
                                            focused: false
                                        }
                                    })
                                }
                                onChange={
                                    event => this.setState({
                                        inputName: {
                                            ...this.state.inputName,
                                            text: event.target.value
                                        }
                                    })
                                }
                            />
                            <figcaption>
                                { this.state.inputName.errorType === "required" &&
                                <>{pageOptions.teksty_form__error_required}</> }
                                { this.state.inputName.errorType === "pattern" &&
                                <>{pageOptions.teksty_form__error_invalid}</> }
                            </figcaption>
                        </div>
                        <div className={classnames(
                            "col text-input col-xs-12",{
                                "error": !this.state.inputEmail.valid
                            }
                        )}>
                            <input
                                type="text"
                                id="contact-email-input"
                                placeholder={pageOptions.teksty_form_label_email}
                                maxLength={30}
                                onFocus={
                                    ()=>this.setState({
                                        inputEmail: {
                                            ...this.state.inputEmail,
                                            focused: true,
                                            valid:true
                                        }
                                    })
                                }
                                onBlur={
                                    ()=>this.setState({
                                        inputEmail: {
                                            ...this.state.inputEmail,
                                            focused: false
                                        }
                                    })
                                }
                                onChange={
                                    event => this.setState({
                                        inputEmail: {
                                            ...this.state.inputEmail,
                                            text: event.target.value
                                        }
                                    })
                                }
                            />
                            <figcaption>
                                { this.state.inputEmail.errorType === "required" &&
                                <>{pageOptions.teksty_form__error_required}</> }
                                { this.state.inputEmail.errorType === "pattern" &&
                                <>{pageOptions.teksty_form__error_invalid}</> }
                            </figcaption>
                        </div>
                        <div className={classnames(
                            "col textarea col-xs-12",{
                                "error": !this.state.inputEmail.valid
                            }
                        )}>
                            <label htmlFor="contact-email-message">{pageOptions.teksty_form_label_wiadomosc}</label>
                            <textarea
                                id="contact-email-message"
                                maxLength={500}
                                rows={7}
                                onFocus={
                                    ()=>this.setState({
                                        textarea: {
                                            ...this.state.textarea,
                                            focused: true,
                                            valid:true
                                        }
                                    })
                                }
                                onBlur={
                                    ()=>this.setState({
                                        textarea: {
                                            ...this.state.textarea,
                                            focused: false
                                        }
                                    })
                                }
                                onChange={
                                    event => this.setState({
                                        textarea: {
                                            ...this.state.textarea,
                                            text: event.target.value
                                        }
                                    })
                                }
                            />
                            <figcaption>
                                { this.state.textarea.errorType === "required" &&
                                <>{pageOptions.teksty_form__error_required}</> }
                                { this.state.textarea.errorType === "pattern" &&
                                <>{pageOptions.teksty_form__error_invalid}</> }
                            </figcaption>
                        </div>
                        <div className="col submit col-xs-4 col-xs-offset-4">
                            <button
                                className="btn form-btn"
                                type="submit"
                                onClick={event=>this.validateInputs(event)} >
                                {pageOptions.teksty_wyslij}
                            </button>
                        </div>
                    </div> :
                    <div className="row text middle-xs success">
                        <div className="col col-xs-12">
                            Formularz otrzymaliśmy. Skontaktujemy się z Państwem w ciągu maksymalnie 72 godz.
                        </div>
                    </div>
                }

                    <div
                        className="row"
                        style={
                            ( this.state.formValid && this.state.submitMessage ) ?
                                { visibility: 'hidden' } :
                                {} } >
                        <div className="col col-xs-12">
                            <div className={classnames(
                              "row checkbox",{
                                  "error": !this.state.checkboxValid
                              }
                            )}>
                                <div className="col col-xs-2">
                                    <input
                                      type="checkbox"
                                      id="newsletter-disclaimer-input"
                                      onChange={
                                          e => e.target.checked
                                            ? this.setState({
                                                inputChecked:true,
                                                checkboxValid:true
                                            })
                                            : this.setState({inputChecked:false}) }/>
                                </div>
                                <div className="col col-xs-10">
                                <span>
                                    <label htmlFor="newsletter-disclaimer-input"
                                           dangerouslySetInnerHTML={
                                               {__html: pageOptions.teksty_zgoda_potwierdzenie_regulaminu}
                                           }/>
                                    <a href="/#" title="Link: Regulamin" target="_blank" rel="noopener noreferrer">
                                        {pageOptions.teksty_czytaj_dalej}
                                    </a>
                                </span>

                                </div>
                            </div>
                            <div className="row checkbox">
                                <div className="col col-xs-2">
                                    <input
                                      type="checkbox"
                                      id="newsletter-disclaimer2-input"/>
                                </div>
                                <div className="col col-xs-10">
                                <span>
                                    <label htmlFor="newsletter-disclaimer2-input"
                                           dangerouslySetInnerHTML={
                                               {__html: pageOptions.teksty_zgoda_przetwarzanie}
                                           }/>
                                </span>

                                </div>
                            </div>
                            <div className="row checkbox">
                                <div className="col col-xs-2">
                                    <input
                                      type="checkbox"
                                      id="newsletter-disclaimer3-input"/>
                                </div>
                                <div className="col col-xs-10">
                                <span>
                                    <label htmlFor="newsletter-disclaimer3-input"
                                           dangerouslySetInnerHTML={
                                               {__html: pageOptions.teksty_zgoda_telemarketing}
                                           }/>
                                </span>

                                </div>
                            </div>
                            <div className="row checkbox">
                                <div className="col col-xs-2">
                                    <input
                                      type="checkbox"
                                      id="newsletter-disclaimer4-input"/>
                                </div>
                                <div className="col col-xs-10">
                                <span>
                                    <label htmlFor="newsletter-disclaimer4-input"
                                           dangerouslySetInnerHTML={
                                               {__html: pageOptions.teksty_zgoda_informacje_handlowe}
                                           }/>
                                </span>

                                </div>
                            </div>
                        </div>
                    </div>



            </div>
        );
    }

};

const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions
});

const mapDispatchToProps = dispatch => ({
    setContactFormVisibility: value => dispatch( setContactFormVisibility(value))
});


export default connect(mapStateToProps,mapDispatchToProps)(ContactForm);