import React, {Component} from 'react';
import {connect} from 'react-redux';

//actions
import { toggleLightbox, selectedGallery } from "../../../../actions/lightboxActions";

//tools
import classnames from "classnames";

class PhotoSessionGroup extends Component {

    constructor(props) {
        super(props);
        this.handleLightbox = this.handleLightbox.bind(this);
    }
    handleLightbox = (
        tytul,
        opis,
        zdjecie_s_full,
        zdjecie_xl_full,
        dodatkowe_zdjecia
    ) => {
        const list = [zdjecie_s_full,zdjecie_xl_full];
        this.props.openLightbox();
        this.props.selectedGallery({
            title:tytul,
            text:opis,
            imgList: dodatkowe_zdjecia ? list.concat(dodatkowe_zdjecia) : list
        });
    };




    render() {
        const {
            item,
            index,
        } = this.props;

        return (
            <div
                key={index}
                className="col col-xs-12 col-md-6" >
                <div className={classnames(
                    "row group",{
                        "revert": item.revert
                    })}>

                {
                    item.sesje &&
                    item.sesje
                        .map( (item,index) =>
                        <>
                            {
                                item.zdjecie_s_miniatura &&
                                <div
                                    key={index}
                                    className="col col-xs-12"
                                    onClick={
                                        ()=>this.handleLightbox(
                                            item.tytul,
                                            item.opis,
                                            item.zdjecie_s_full,
                                            item.zdjecie_xl_full,
                                            item.dodatkowe_zdjecia.length>0 ? item.dodatkowe_zdjecia.map((item)=>item.zdjecie) : null
                                        )
                                    }>

                                    <div className={classnames( "row session",{ "title-on-right": item.tytul_po_stronie === "right" } )}>

                                        <div className="col col-xs-4">
                                            <figure>
                                                <img
                                                    src={item.zdjecie_s_miniatura}
                                                    width={253}
                                                    height={ item.orientacja === "h" ? 170 : 303 }
                                                    alt="" />
                                            </figure>
                                            <figcaption>
                                                <h3>{item.tytul}</h3>
                                            </figcaption>
                                        </div>

                                        <div className="col col-xs-8">

                                            <img
                                                src={item.zdjecie_xl_miniatura}
                                                width={484}
                                                height={ item.orientacja === "h" ? 304 : 606 } alt="" />
                                        </div>

                                    </div>

                                </div>
                            }
                        </>


                    )
                }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openLightbox: () => dispatch( toggleLightbox() ),
    selectedGallery: gallery => dispatch( selectedGallery(gallery) ),
});


export default connect(null,mapDispatchToProps)(PhotoSessionGroup);