import React from 'react';

const Akceptuj = ({mainColor}) => {
    return (
        <svg
            version="1.1" xmlns="http://www.w3.org/2000/svg"
            id="svg_akceptuj"
            x="0px" y="0px"
            width="82.87px" height="80.02px" viewBox="0 1285.89 82.87 80.02">
            <path fill={mainColor} d="M65.91,1320.72l-29.87,29.87c-2.66,2.66-6.97,2.66-9.63,0l-22.01-22.01l9.62-9.63l3.27,3.29c0.71,0.72,1.87,0.73,2.6,0.02
	s0.73-1.87,0.02-2.6l-4.61-4.58c-0.71-0.71-1.86-0.71-2.57,0l-12.21,12.21c-0.71,0.7-0.71,1.85-0.01,2.56c0,0,0.01,0.01,0.01,0.01
	l23.29,23.3c4.09,4.06,10.68,4.06,14.77,0l29.87-29.87c0.71-0.71,0.71-1.86,0-2.57c-0.71-0.71-1.86-0.71-2.57,0l0,0L65.91,1320.72z"
            />
            <path fill={mainColor} d="M82.33,1306.87l-12.2-12.21c-0.71-0.71-1.86-0.71-2.57,0l-35.87,35.88c-0.26,0.25-0.67,0.25-0.93,0l-7.2-7.2
	c-0.71-0.71-1.87-0.72-2.58-0.01c-0.72,0.71-0.72,1.87-0.01,2.58l0,0l7.22,7.2c1.68,1.68,4.4,1.68,6.07,0l34.59-34.59l9.63,9.63
	l-8.91,8.91c-0.34,0.34-0.53,0.8-0.52,1.28c0,1,0.81,1.82,1.82,1.82c0.48,0,0.94-0.19,1.28-0.53l10.19-10.2
	C83.05,1308.74,83.05,1307.59,82.33,1306.87C82.34,1306.88,82.34,1306.88,82.33,1306.87z"/>
        </svg>
    );
};

export default Akceptuj;