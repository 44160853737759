import React from 'react';

//tools
import MediaQuery from "react-responsive";

const Zarzadca = ({pageData,title}) => {

    const phone = pageData.zarzadca_telefon;

    return (
        <div className={"col col-xs-12 col-sm-6 col-md-4"}>
            <section>
                <h3 dangerouslySetInnerHTML={{__html: title}}/>
                <p>
                    <strong>{pageData.zarzadca_nazwa}</strong>
                    <span dangerouslySetInnerHTML={{__html: pageData.zarzadca_adres}}/>
                </p>

                <p>

                    <MediaQuery minWidth={769}>
                        <span>
                            {pageData.zarzadca_telefon}
                        </span>
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                        <a href={`tel:${phone && phone.replace(/[() ]/g,'')}`} title="Tel">
                            {pageData.zarzadca_telefon}
                        </a>
                    </MediaQuery>

                    <a href={pageData.zarzadca_www} title="www">
                        {pageData.zarzadca_www}
                    </a>
                </p>

                <figure>
                    <img src={pageData.zarzadca_logo} alt="Logo" />
                </figure>
            </section>
        </div>
    );
};

export default Zarzadca;