import React from 'react';

const Info = ({opis,csrGradientTransp}) => {
    return (
        <div className="row info">
            <div className={"col col-xs-12 col-sm-8"}>
                <figure style={csrGradientTransp}/>
                <p dangerouslySetInnerHTML={{__html: opis}}/>
            </div>
        </div>
    );
};

export default Info;