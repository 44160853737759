import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../../Page';
import Arrow from "../../../../svg/lightbox/Arrow";
import Back from "../../../Back";

//tools
import Slider from "react-slick";
import classnames from 'classnames';
import MediaQuery from "react-responsive";
import Moment from 'react-moment';

//config
import { config }  from "../../../../config";
import { paths }  from "../../../../config/paths";


class Promocja extends Component {
    render() {
        const {
            pageData,
            pageOptions,
            selectedPost,
            match
        } = this.props;

        const seasonGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_naglowki}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`
        };
        const greenGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_news}deg, 
                ${pageOptions.kolor_1_news} 53%, 
                ${pageOptions.kolor_2_news} 85% 
            )`
        };
        const sliderSettings = {
            autoplay:false,
            cssEase:'ease-in-out',
            dots: false,
            arrows: true,
            infinite: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide:0,
            nextArrow: <div className="arrow next"><Arrow mainColor="#000000" /></div>,
            prevArrow: <div className="arrow prev"><Arrow mainColor="#000000" /></div>
        };

        return (

            <Page pageIndex={config.pageIndex.promocje}
                  restName="promocje"
                  postUrl={ match ? match.params.promoId : selectedPost }
            >
                <Back
                    bg={pageOptions.zielen}
                    link={paths.promocje}
                />
                <article className={classnames(
                    "page promocja content",{
                        "gazetka" : pageData.gazetka
                    }
                )}>
                    <div className="row header-promo ">
                        <div className="col col-xs-12 col-md-6">
                            <div className="row center-xs start-sm">
                                <div className="col col-xs-12 col-sm-6 logo">
                                    {pageData.logo &&
                                    <img
                                        src={pageData.logo}
                                        alt={`Logo: ${pageData.podtytul}`}
                                        width={325}
                                    />
                                    }
                                </div>
                                <div className="col col-xs-12 col-sm-6">
                                    <h2 dangerouslySetInnerHTML={{__html: pageData.tytul}} />
                                    <p className="daty">
                                        {
                                            pageData.data_publikacji ?
                                                <span> <Moment format="DD/MM/YYYY">{pageData.data_publikacji}</Moment></span> :
                                                <span>&nbsp;</span>
                                        }
                                        {
                                            pageData.data_zakonczenia &&
                                            <span> - <Moment format="DD/MM/YYYY">{pageData.data_zakonczenia}</Moment></span> }
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <MediaQuery minWidth={769}>
                        <div className="row img-row-container center-xs start-sm">
                            <div className={classnames(
                                "col col-xs-12",{
                                    "col-sm-7" : pageData.gazetka,
                                    "col-sm-4" : !pageData.gazetka,
                                }
                            )} >
                                <div className={classnames(
                                    "mask",{
                                        "fit" : pageData.gazetka
                                    }

                                )}
                                     style={greenGradient} />
                                { !pageData.gazetka &&
                                    <img src={ pageData.zdjecie_do_naglowka ? pageData.zdjecie_do_naglowka : pageData.miniatura_promocja } alt=""/>
                                }

                            </div>
                            <MediaQuery minWidth={769}>
                                <div className={classnames(
                                    "col col-xs-12",{
                                        "col-sm-5" : pageData.gazetka,
                                        "col-sm-6" : !pageData.gazetka,
                                    }
                                )}>
                                    <div className="mask fit"
                                         style={seasonGradient} />
                                </div>
                            </MediaQuery>
                        </div>
                    </MediaQuery>

                    {!pageData.gazetka ?

                        <div className="row text-row-container top-xs">
                            <MediaQuery minWidth={769}>
                                <div className="col col-xs-12 col-sm-2">
                                    <div
                                        className="sidepic"
                                        style={{
                                            backgroundImage: `url(${pageData.zdjecie_pionowe_boczne ? pageData.zdjecie_pionowe_boczne : pageOptions.zaslepka_promocja_bok})`
                                        }}
                                    />
                                </div>
                            </MediaQuery>

                            <div className="col col-xs-12 col-sm-8 col-md-5 center-xs start-sm">
                                <article>
                                    <p dangerouslySetInnerHTML={{__html: pageData.tekst}}/>
                                </article>
                            </div>

                        </div>
                        :
                        <div className="row slider-row-container top-xs">
                            <Slider {...sliderSettings}>
                                { pageData.strony &&
                                    pageData.strony.map((item,index) =>
                                        <div key={index}>
                                            <img src={item.strona} alt="" className="page"/>
                                        </div>
                                    )
                                }
                            </Slider>
                        </div>
                    }
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions,
    selectedPost: state.page.selectedPost
});


export default connect(mapStateToProps,null)(Promocja);