import React,{Fragment} from 'react';

const Mapy = ({ tytul, lista, gradient, color }) => {
    return (
        <div className="section-mapy">
            <h3 style={{color:color}} dangerouslySetInnerHTML={{__html:tytul}}/>
            <div className="row end-xs lista-map">
                {
                    lista &&
                    lista.map((item,index)=>{
                        return (
                            <div className="col col-xs-12 col-sm-6 mapa" key={index}>
                                <a href={item.url_linka} target="_blank" rel="noopener nofollow">
                                    <img src={item.obraz} alt={`Pic: ${item.tytul}`}/>
                                </a>
                                <nav>
                                    <a href={item.url_linka} target="_blank" rel="noopener nofollow">
                                        {item.tekst_linka}
                                    </a>
                                </nav>
                            </div>
                        )
                    })
                }
            </div>
            <div
                className="mask"
                style={gradient}
            />
        </div>
    );
};

export default Mapy;