import React from 'react';
import {isIE} from 'react-device-detect';

const Map = () => {
    return (
        <svg
            version="1.1"
            id="map"
            xmlns="http://www.w3.org/1999/xlink"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1392px"
            height="1036px"
            viewBox="0 0 1392 1036"
            preserveAspectRatio={isIE ? "xMidYMid slice" : "none"}
        >
            <g>
                <defs>
                    <rect id="SVGID_1_" width="1511" height="1080"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_"  overflow="visible"/>
                </clipPath>
                <g id="streets" clipPath="url(#SVGID_2_)">
                    <path fill="#AADAFF" d="M805.69,1054.96c-0.93-1.5-35.34-58.83-53.5-95.17c-15.81-31.63-14.15-61.96-12.05-100.37
			c0.52-9.54,1.06-19.41,1.4-30.16c1.47-46.18-22.1-55.07-55.54-62.6c-37.05-8.34-59.21-24.13-79.02-56.32
			c-15.92-25.87-13.16-51.95,8.45-79.73c10.41-13.38,7.09-41.47-9.09-77.07c-11.11-24.44-23.78-40.56-38.46-59.23
			c-9.35-11.9-19.02-24.19-29.45-40.01c-26.57-40.28-27.69-77.69-29.1-125.06c-0.11-3.62-0.22-7.31-0.34-11.08
			c-1.59-48.5-13.17-63.6-32.36-88.61l-0.86-1.13c-15.18-19.8-20.12-42.62-25.34-66.77c-2.19-10.13-4.46-20.6-7.55-30.93
			c-9.41-31.37-36.68-58.2-36.95-58.47l15.06-15.5c1.28,1.25,31.54,30.91,42.59,67.76c3.34,11.14,5.7,22.04,7.98,32.57
			c4.9,22.66,9.13,42.23,21.37,58.19l0.86,1.12c19.55,25.49,34.99,45.62,36.81,101.06c0.12,3.79,0.23,7.5,0.34,11.14
			c1.36,45.66,2.35,78.65,25.54,113.81c9.93,15.06,19.32,27,28.4,38.55c14.89,18.95,28.96,36.84,41.15,63.64
			c14.08,30.98,26.64,73.36,6.47,99.29c-16.06,20.65-18.18,37.13-7.11,55.13c16.8,27.3,33.9,39.48,65.36,46.56
			c16.86,3.79,36.06,8.91,50.42,21.71c15.69,13.98,22.88,34.47,21.98,62.66c-0.35,10.99-0.9,20.99-1.42,30.66
			c-2,36.59-3.45,63.02,9.8,89.52c17.71,35.43,52.33,93.1,52.55,93.45L805.69,1054.96z"/>
                    <path fill="#AADAFF" d="M777.93,999.11c-2.9,0-6.78-1.99-12.5-4.91c-6.82-3.49-15.73-8.49-25.08-14.08
			c-14.47-8.64-43.07-26.53-72.04-49.17c-25.29-19.77-91.56-175.05-96.04-191.16c-2.05-7.38-7.31-35.91-13.39-68.95
			c-6.62-35.92-14.12-76.64-16.84-85.7c-1.95-6.49-7.07-17.99-12.5-30.17c-9.12-20.46-18.55-41.62-19.68-52.93
			c-1.75-17.53,4.06-101.9,4.31-105.48l10.35,0.72c-0.06,0.87-6,87.07-4.34,103.73c0.96,9.65,10.87,31.88,18.83,49.74
			c5.81,13.03,10.83,24.29,12.96,31.41c2.88,9.6,10.11,48.85,17.1,86.8c5.79,31.43,11.26,61.11,13.19,68.05
			c4.71,16.95,70.3,168.47,92.43,185.77c47.04,36.78,97.87,64.52,103.75,65.99c-1.26-0.31-3.71,0.25-4.97,2.45
			c-1.16,2.01-0.63,4.18,0.16,5.25l6.71-7.85c4.8,4.1,2.21,7.78,1.64,8.48C780.84,998.5,779.54,999.11,777.93,999.11"/>
                    <path fill="#686868" d="M1313.74,784.95c-2.44-138.86-4.34-219.25-5.5-232.47c-0.13-1.49-0.27-3.14-0.42-4.93
			c-2.83-34.21-9.46-114.31-66.34-161.58c-39.89-33.15-70.02-42.51-94.23-50.03c-12.84-3.99-23.93-7.43-34.06-13.57
			c-7-4.24-14.67-8.71-22.79-13.44c-24.56-14.31-52.39-30.52-70.28-45.75c-10.15-8.65-18.88-17.65-26.59-25.59
			c-10.3-10.62-19.2-19.79-28.6-25.43c-20.79-12.47-149.27-74.68-201.08-76.33c-12.5-0.4-26.12-2.15-40.54-4
			c-50.38-6.48-113.09-14.54-199.71,21.82c-110.85,46.54-390.34,159.26-415.91,163.67c-3.33,0.57-7.85,1.35-13.16,2.26l-1.46-8.52
			c5.31-0.91,9.82-1.68,13.15-2.25c23.69-4.08,288.75-110.52,414.03-163.12c39.41-16.54,77.55-25.58,116.6-27.61
			c33.68-1.75,62.3,1.93,87.56,5.18c14.21,1.83,27.63,3.55,39.72,3.94c56.09,1.78,188.12,67.28,205.25,77.56
			c10.37,6.22,19.63,15.77,30.35,26.83c7.94,8.18,16.15,16.65,25.99,25.03c17.3,14.73,44.78,30.74,69.02,44.87
			c8.15,4.75,15.86,9.24,22.92,13.52c9.23,5.59,19.85,8.89,32.14,12.71c24.94,7.75,55.98,17.39,97.19,51.63
			c25.64,21.31,44.53,51.58,56.14,89.98c9.47,31.35,11.86,60.26,13.29,77.53c0.15,1.77,0.28,3.4,0.41,4.88
			c1.5,16.95,3.84,136.11,5.54,233.08L1313.74,784.95z"/>
                    <path fill="#686868" d="M943.13,1060.9c-0.42-0.72-42.5-72.01-51.94-89.16c-9.04-16.43-27.03-32.87-27.21-33.03l-0.53-0.48
			l-0.35-0.62c-3.92-7.05-3.64-15.11-3.29-18.52c-18.56-32.97-64.7-114.84-66.58-117.81c-0.86-1.27-8.25-9.77-26.38-29.71
			c-6.78-7.45-12.86-10.86-17.31-13.35c-0.91-0.51-1.73-0.97-2.46-1.41c-5.46-3.27-8.42-10.85-8.74-11.7l-0.66-1.76l5.17-10.33
			c2-4,5.59-32.96,5.59-40.43c0-4.71-7.37-41.73-12.01-63.96l-0.88-4.21l4.2-0.9c12.33-2.64,52.45-4.42,82.17-4.42
			c15.58,0,55.4-3.43,97.56-7.07c34.58-2.98,70.35-6.07,96.71-7.62c58.11-3.42,138.97-15.37,146.17-17.17
			c3.77-0.94,20.15-12.64,33.82-23.41l0.69-0.54l0.85-0.23c17.6-4.77,51.95-14.15,55.96-15.49c5.32-1.78,39.64,2.22,59.66,4.77
			c0.99,0.1,18.98,2,41.45,3.67c10.29,0.76,21.3,1.63,47.91,3.71l24.3,1.91l-0.68,8.62l-24.3-1.91c-26.59-2.09-37.6-2.95-47.87-3.71
			c-23.14-1.71-41.59-3.68-41.77-3.7l-0.09-0.01c-24.45-3.11-51.99-5.86-55.98-5.11c-5.42,1.77-48.61,13.5-55.47,15.37
			c-6.18,4.85-29.24,22.67-36.38,24.46c-7.33,1.83-89.2,13.97-147.76,17.41c-26.24,1.54-61.94,4.62-96.47,7.6
			c-42.35,3.65-82.35,7.1-98.3,7.1c-25.84,0-60.93,1.41-76.12,3.51c2.96,14.3,11.28,55.09,11.28,61.33c0,6.61-3.31,37.9-6.51,44.29
			l-3.42,6.84c1.03,2.1,2.68,4.66,4.37,5.68c0.66,0.4,1.42,0.82,2.24,1.28c4.68,2.62,11.76,6.58,19.48,15.08
			c25.07,27.58,26.67,29.98,27.2,30.77c1.77,2.65,60.87,107.62,67.6,119.58l0.77,1.36l-0.28,1.53c-0.01,0.07-1.29,7.65,1.81,13.84
			c3.29,3.06,19.5,18.54,28.41,34.74c9.37,17.04,51.38,88.21,51.81,88.93L943.13,1060.9z M1256.42,555.77
			c-0.01,0-0.02,0.01-0.03,0.01C1256.4,555.77,1256.41,555.77,1256.42,555.77"/>
                    <path fill="#686868" d="M702.03,597.33l-2.67-3.06c-0.72-0.83-17.66-20.35-23.01-30.16c-5.21-9.55-11.97-24.74-12.26-25.39
			l7.9-3.51c0.07,0.16,6.91,15.51,11.95,24.76c3.72,6.82,14.33,19.66,19.31,25.54c12.04-9.23,53.89-41.3,64.14-49.35
			c9.65-7.59,28.55-38.31,36.62-51.42c1.64-2.66,2.97-4.83,3.9-6.29c2.23-3.5,10.71-16.15,21.44-32.16
			c19.09-28.47,45.24-67.47,58.97-88.89c17.15-26.76,24.91-34.47,33.9-43.4c2.52-2.5,5.12-5.08,8.09-8.24
			c13.99-14.86,23.6-27.32,49.66-67.68c23.89-36.99,27.68-39.92,37.13-44.36c0.81-0.38,1.67-0.78,2.61-1.25
			c3.58-1.79,17.71-8.66,37.26-18.16c35.81-17.4,89.92-43.7,114.89-56.19c36.52-18.26,50.3-34.99,50.44-35.15l6.74,5.41
			c-0.59,0.74-14.87,18.25-53.31,37.48c-25.01,12.51-79.15,38.82-114.98,56.23c-18.8,9.13-33.64,16.35-37.18,18.12
			c-1.01,0.51-1.93,0.94-2.8,1.35c-7.23,3.4-9.93,4.67-33.54,41.22c-26.42,40.92-36.24,53.63-50.63,68.91
			c-3.07,3.26-5.72,5.9-8.29,8.45c-8.98,8.92-16.08,15.97-32.72,41.93c-13.78,21.49-39.95,60.53-59.06,89.04
			c-10.7,15.96-19.15,28.57-21.33,31.99c-0.91,1.43-2.22,3.56-3.83,6.17c-9.71,15.78-27.78,45.15-38.65,53.69
			c-11.99,9.42-66.92,51.48-67.47,51.9L702.03,597.33z"/>
                    <path fill="#686868" d="M1317.73,976.58c-27.1,0-71.65-27.98-114.73-55.04c-28.24-17.74-54.91-34.49-73.96-41.63
			c-43.13-16.17-112.12-48.02-141.58-61.61c-3.72-1.72-6.81-3.14-9.13-4.21c-8.79-4.03-21.06-9.1-32.93-14.01
			c-21.35-8.83-30.99-12.93-33.67-15.07c-0.63-0.5-2.51-1.89-4.89-3.64c-34.26-25.18-48.33-37.63-50.18-44.42
			c-2.66-9.76-6.24-45.78-5.33-58.5c0.86-12.01,0.86-39.27,0-53.02c-0.7-11.16-3.16-26.58-3.99-31.6
			c-6.97-5.41-33.66-26.43-39.43-35.51c-2.69-4.23-15.2-8.49-25.26-11.91c-8.92-3.04-18.15-6.18-24.39-10.08
			c-12.2-7.63-47.22-12.28-63.71-6.5c-18.73,6.56-92.22,42.71-97.42,48.7c-5.24,6.99-73.9,80.08-93.49,93.44
			c-4.14,2.83-9.23,6.56-15.11,10.89c-21.85,16.07-49.04,36.08-66.32,38.24c-12.81,1.6-41.38-1.67-66.59-4.56
			c-15.83-1.81-30.78-3.54-35.67-3.2c-8.86,0.59-17.38,1.19-21.84,1.5c-1.6,6.29-5.95,19.26-15.42,23.99
			c-6.88,3.44-25.5,26.48-35.63,40.2l-2.16,2.92l-3.25-1.63c-24.56-12.28-34.73-9.99-45.51-7.57c-6.89,1.55-14.02,3.15-24.33,1.02
			l1.75-8.47c8.49,1.76,14.14,0.49,20.68-0.99c10.77-2.42,22.92-5.16,48.01,6.69c6.93-9.2,27.02-35.14,36.57-39.91
			c6.89-3.45,10.71-16.73,11.51-21.11l0.6-3.3l3.35-0.24c0.12-0.01,12.26-0.88,25.09-1.73c5.67-0.37,19.6,1.22,37.23,3.24
			c23.5,2.69,52.76,6.04,64.54,4.57c15.04-1.88,42.34-21.96,62.27-36.62c5.95-4.38,11.09-8.16,15.37-11.07
			c18.08-12.33,85.88-84.06,91.48-91.53c6.51-8.68,85.95-46.21,101.45-51.63c17.8-6.23,55.7-2.32,71.15,7.33
			c5.4,3.37,14.14,6.35,22.6,9.23c13.08,4.46,25.44,8.67,29.76,15.45c4.63,7.27,29.55,27.25,38.76,34.34l1.33,1.03l0.29,1.66
			c0.14,0.82,3.51,20.28,4.38,34.19c0.88,14.03,0.88,41.88,0,54.18c-0.84,11.83,2.58,46.59,5.05,55.61
			c0.19,0.65,2.03,4.63,18.83,18.27c10.11,8.21,21.39,16.5,28.13,21.46c2.65,1.95,4.41,3.24,5.17,3.85
			c2.23,1.69,18.5,8.42,31.57,13.83c11.95,4.94,24.3,10.05,33.23,14.15c2.33,1.07,5.43,2.5,9.16,4.22
			c29.37,13.56,98.16,45.31,140.99,61.37c19.86,7.45,46.9,24.43,75.52,42.4c40.09,25.18,85.52,53.71,110.13,53.71V976.58z"/>
                    <path fill="#686868" d="M868.57,918.83l-8.56-1.22l2.59-18.15c0.89-6.2,1.41-11.7-1.2-16.26c-2.01-3.51-8.89-7.67-12.76-9.46
			l-1.84-0.85l-0.52-1.96c-2.9-10.86-2.14-17.48,2.24-19.68c4.1-2.05,13.21-4.71,21.34-5.62c1.17-0.13,2.46-0.28,3.87-0.44
			c7.87-0.91,19.75-2.3,35.31-3.04c31.52-1.5,39.08-3.66,40.81-4.42l0.03-0.07c1.25-2.44,1.87-3.66,2.6-13.14
			c0.88-11.43,2.66-17.52,7.06-24.14c2.9-4.47,11.08-46.4,11.08-56.79c0-6.42-2.68-9.74-11.98-20.28c-2.26-2.57-4.83-5.47-7.74-8.9
			c-10.73-12.63-11-19.42-11.33-28.03c-0.15-3.96-0.33-8.45-1.69-14.44c-4.09-17.99-27.58-52.26-27.81-52.61l7.12-4.9
			c1.01,1.46,24.67,35.99,29.12,55.59c1.54,6.77,1.74,11.9,1.9,16.02c0.3,7.75,0.48,12.42,9.28,22.77c2.87,3.37,5.4,6.25,7.64,8.79
			c9.41,10.67,14.14,16.04,14.14,26c0,9.73-7.76,54.42-12.52,61.58c-3.14,4.73-4.79,8.93-5.64,20.02
			c-0.83,10.73-1.72,12.89-3.52,16.41l-0.38,0.75c-1.41,2.81-3.16,6.31-47.75,8.44c-15.26,0.73-26.97,2.09-34.72,2.99
			c-1.42,0.17-2.73,0.32-3.91,0.45c-6.72,0.75-14.11,2.89-17.54,4.35c-0.2,1.56,0.18,4.95,0.9,8.26
			c3.72,1.93,11.53,6.48,14.72,12.07c4.29,7.51,3.11,15.76,2.25,21.78L868.57,918.83z M949.49,838.45
			c-0.01,0.01-0.01,0.02-0.02,0.03L949.49,838.45z"/>
                    <path fill="#686868" d="M1239.11,937.42l-7.76-3.81c0,0,2.85-5.8,4-8.12c0.09-2.55-1.09-11.76-1.81-17.36
			c-0.37-2.85-0.71-5.53-0.94-7.58c-0.86-7.76-8.65-87.3-11.25-117.68c-2.61-30.47-0.06-45.45,0.05-46.08l0.02-0.09
			c0.13-0.66,12.96-65.7,13.81-70.76c0.29-1.89-2.39-8.79-5.85-15.16l-90.71-51.91l4.29-7.5l92.79,53.1l0.59,1.04
			c2,3.56,8.45,15.64,7.41,21.85c-0.86,5.16-13.17,67.56-13.84,70.96c-0.12,0.75-2.39,15.28,0.05,43.8
			c2.6,30.33,10.37,109.73,11.23,117.47c0.22,1.98,0.56,4.63,0.92,7.44c2.1,16.42,2.29,19.68,1.24,21.77
			C1242.5,930.52,1239.11,937.42,1239.11,937.42"/>
                    <path fill="#686868" d="M974.99,762.83l-3.43-7.93c1.13-0.49,113.17-48.99,119.91-52.35c6.5-3.25,22.55-15.06,22.71-15.18
			l2.33-1.71l2.47,1.5c27.98,16.93,70.56,42.59,73.18,43.92c1.78,0.89,3.73,2.54,6.19,4.62c3.36,2.84,7.54,6.38,11.78,8.26
			c6.47,2.88,13.79,1.83,13.86,1.82l1.31,8.55c-0.4,0.06-9.84,1.46-18.68-2.47c-5.37-2.39-10.07-6.36-13.85-9.56
			c-1.7-1.44-3.62-3.06-4.48-3.49c-3.25-1.63-57.61-34.48-71.33-42.77c-4.86,3.52-16.01,11.43-21.63,14.24
			C1088.38,713.75,979.61,760.83,974.99,762.83"/>
                    <path fill="#686868" d="M1138.86,595.45l-15.2-197.34c-0.69-11.05,2.71-15.15,7.01-20.34c1.96-2.36,4.17-5.02,6.56-9.01
			c7.52-12.54,17.01-35.28,17.11-35.51l7.98,3.32c-0.4,0.96-9.83,23.55-17.68,36.63c-2.73,4.55-5.27,7.62-7.32,10.08
			c-3.9,4.71-5.52,6.66-5.04,14.23l15.19,197.27L1138.86,595.45z"/>
                    <path fill="#686868" d="M1045.84,606.87h-8.65v-11.98c-0.44-4.56-16.29-29.5-20.75-35.44c-2.83-3.77-2.07-10.43-1.35-16.87
			c0.25-2.19,0.48-4.26,0.48-5.61c-0.13-2.22-4.89-10.18-8.37-15.99c-2.99-4.99-6.07-10.15-8.46-14.92
			c-5.98-11.96-87.69-173.6-88.52-175.23l7.71-3.9c0.83,1.63,82.55,163.29,88.53,175.27c2.24,4.48,5.25,9.5,8.15,14.35
			c6.13,10.24,9.6,16.24,9.6,20.43c0,1.84-0.26,4.14-0.54,6.58c-0.34,3-1.04,9.22-0.28,10.78c1.17,1.66,22.43,32.23,22.43,40.56
			V606.87z M1023.37,554.26c0,0.01,0.02,0.02,0.04,0.05C1023.39,554.29,1023.38,554.28,1023.37,554.26"/>

                    <rect x="1073.72" y="470.88" transform="matrix(0.1275 0.9918 -0.9918 0.1275 1465.7212 -607.2771)" fill="#686868" width="8.64" height="117.23"/>
                    <path fill="#686868" d="M926.98,569.87c-0.93,0-1.74-0.02-2.42-0.06c-13.32-0.78-41.59-19.37-64.74-35.47l-2.31-1.6
			c-14.06-9.73-8.43-51.1-6.83-55.93c1.77-5.32,58.91-91.32,76.38-117.56l7.2,4.79c-28.96,43.48-72.8,109.95-75.41,115.6
			c-0.67,2.21-2.12,12.31-2.02,23.02c0.12,12.03,2.21,20.62,5.59,22.96l2.32,1.61c11.8,8.21,47.71,33.19,60.31,33.94
			c14.15,0.83,93.53-8.53,94.33-8.62l1.01,8.59C1017.27,561.52,946.4,569.87,926.98,569.87"/>
                    <path fill="#686868" d="M925.94,615.87c-1.83-18.25-4.76-46.5-5.37-49.52l8.48-1.7c0.88,4.4,5.02,45.67,5.49,50.36L925.94,615.87z
			"/>
                    <path fill="#686868" d="M697.42,526.16l-8.6-0.86c0.78-7.79,1.08-17.52,0.29-19.58c-1.78-4.44,0.09-20.47,0.48-23.64
			c2.5-20.19,5.68-22.74,6.88-23.7c5.55-4.44,42.53-14.85,64.93-10.37c20.21,4.04,45.98,23.89,51.76,28.5l40.91,1.63l-0.34,8.64
			l-43.8-1.75l-1.12-0.92c-0.29-0.24-28.82-23.57-49.11-27.63c-20.4-4.08-53.28,5.78-57.66,8.53c-2.78,4.39-6.06,32.73-4.87,37.61
			C699.08,507.65,697.85,521.89,697.42,526.16 M697.13,502.51c0,0.01,0.01,0.03,0.02,0.04
			C697.15,502.54,697.14,502.53,697.13,502.51"/>
                    <path fill="#686868" d="M710.38,458.62c-0.25-0.4-6.19-9.88-4.25-23.41c0.55-3.85,2.54-15.93,5.07-31.22
			c5.31-32.18,13.34-80.81,13.92-89.49c1.77-26.61,6.71-90.3,14.2-106.96c7.84-17.43,44.94-72.35,46.51-74.68l0.51-0.76l0.78-0.48
			c0.21-0.13,21.06-13.17,28.48-19.76c7.72-6.87,92.01-78.7,95.6-81.76l5.61,6.58c-0.88,0.75-87.8,74.83-95.46,81.64
			c-7.19,6.39-24.86,17.63-28.86,20.14c-4.56,6.77-38.22,56.94-45.28,72.63c-5.65,12.56-10.66,61.95-13.46,103.98
			c-0.61,9.1-8.36,56.04-14.01,90.32c-2.52,15.24-4.5,27.27-5.04,31.03c-1.46,10.23,2.93,17.46,2.98,17.53L710.38,458.62z"/>
                    <path fill="#686868" d="M588.53,971.3c-0.03-0.33-2.68-33.25-11.19-81.72c-7.59-43.26-18.8-55.83-28.69-66.93
			c-1.09-1.22-2.15-2.42-3.18-3.62l-1.51-1.76c-9.09-10.6-11.25-13.11-15.62-23.59c-1.58-3.78-9.75-10.58-39.76-21.46l-0.96-0.35
			c-12.41-4.51-14.08-16.82-14.08-28.27c0-10.64-6.01-43.01-9.19-49.47c-2.67-5.42-19.19-30.07-26.81-41.3h-15.15l-1.17-2.48
			c-0.61-1.29-14.91-31.63-17.58-42.29c-2.67-10.67,5.4-55.34,6.76-62.69c-0.32-4.81-2.68-40.44-4.27-56.37
			c-1.67-16.65-15.38-60.47-20.46-73.17c-3.52-8.81-19.1-49.9-30.48-79.91c-5.39-14.22-9.65-25.44-11.04-29.07
			c-3.01-7.81-29.1-42.96-45.91-64.68l6.84-5.29c4.36,5.63,42.72,55.37,47.14,66.87c1.4,3.65,5.66,14.89,11.06,29.11
			c11.36,29.98,26.92,71.03,30.42,79.76c5.36,13.4,19.27,57.86,21.04,75.52c1.72,17.17,4.31,56.81,4.33,57.21l0.03,0.54l-0.1,0.53
			c-3.61,19.36-8.7,52.65-6.97,59.54c2.05,8.19,12.36,30.71,15.85,38.22h14.23l1.29,1.89c1.02,1.5,25.04,36.77,28.71,44.25
			c4.05,8.25,10.08,42,10.08,53.28c0,12.3,2.35,17.95,8.39,20.14l0.95,0.35c19.71,7.15,40.46,15.86,44.79,26.26
			c3.84,9.21,5.22,10.82,14.2,21.29l1.52,1.77c1,1.16,2.02,2.31,3.08,3.49c10.13,11.37,22.74,25.51,30.75,71.19
			c8.58,48.92,11.26,82.21,11.29,82.54L588.53,971.3z"/>
                    <path fill="#686868" d="M617.99,615.7c-0.06-0.04-5.97-4.25-10.09-6.73c-2.66-1.6-8.35-9.67-29.65-40.82
			c-14.81-21.66-31.59-46.21-43.3-61.39c-21.66-28.08-70.44-22.65-86.47-20.87l-0.4,0.04c-3.84,0.43-9.84,1.29-16.78,2.29
			c-34.63,5-52.15,6.91-58.07,4.28c-3.72-1.65-32.23-12.8-59.8-23.59c-29.98-11.73-60.98-23.85-67.23-26.53
			c-7.84-3.36-64.52-1.59-98.38-0.53c-15.07,0.47-28.09,0.88-35.65,0.88c-10.33,0-18.45-0.13-25.61-0.24
			c-11.05-0.17-19.03-0.29-26.84,0.23c-3.2,0.21-8.62,0.34-16.12,0.38l-0.05-8.64c7.33-0.04,12.57-0.16,15.6-0.36
			c8.16-0.54,16.29-0.42,27.55-0.25c7.13,0.11,15.22,0.24,25.48,0.24c7.42,0,20.38-0.41,35.38-0.87
			c52.39-1.64,93.16-2.59,102.06,1.22c6.12,2.62,37.06,14.72,66.97,26.43c28.94,11.32,56.27,22.01,60.16,23.74
			c4.95,2.04,37.62-2.67,53.33-4.93c7.02-1.01,13.08-1.89,17.06-2.33l0.4-0.04c17.23-1.92,69.64-7.75,94.27,24.18
			c11.87,15.38,28.72,40.04,43.6,61.8c11.5,16.83,24.52,35.87,27.19,38.42c4.4,2.66,10.19,6.79,10.44,6.97L617.99,615.7z
			 M612.35,601.56c0.02,0.01,0.03,0.02,0.05,0.03C612.38,601.58,612.37,601.57,612.35,601.56"/>
                    <path fill="#686868" d="M546.27,641.96c-6.28,0-11.82-0.37-12.17-0.4l0.59-8.63c0.12,0.01,12.36,0.83,20.54,0.01
			c7.47-0.75,25.79-5.75,29.03-6.64c4.36-2.44,35.65-19.94,43.13-23.27c6.43-2.86,12.92-1.9,18.63-1.05
			c2.35,0.35,4.56,0.68,6.46,0.68c2.77,0,10.29-4.62,16.75-9.6c-4.88-7.71-16.74-26.53-18.89-30.83
			c-1.93-3.86-10.58-26.04-18.54-46.53c-1.64-4.22-2.83-7.29-3.22-8.26c-2.2-5.5-11.76-90.66-12.4-98.96
			c-0.83-10.85-45.37-172.97-49.87-184.65c-2.85-7.42-28.84-30.96-44.55-44.05l-1.25-1.05l-0.25-1.62
			c-0.02-0.11-1.7-10.87-4.93-18.94c-2.67-6.68-31.59-62.61-46.5-91.25l7.67-3.99c1.77,3.4,43.37,83.29,46.86,92.03
			c2.84,7.1,4.54,15.66,5.17,19.2c7.85,6.6,41.56,35.38,45.86,46.56c4.85,12.62,49.51,175.24,50.42,187.09
			c0.99,12.93,10.18,90.8,11.84,96.5c0.43,1.07,1.61,4.12,3.22,8.26c5.29,13.62,16.3,41.96,18.21,45.79
			c2.05,4.09,15.51,25.45,20.53,33.33l2.04,3.21l-2.92,2.43c-3.93,3.28-17.37,13.97-25.24,13.97c-2.53,0-5.18-0.39-7.73-0.77
			c-5-0.74-9.71-1.44-13.85,0.4c-7.51,3.34-42.51,22.96-42.87,23.16l-0.45,0.25l-0.5,0.14c-0.9,0.25-22.08,6.16-30.99,7.06
			C553.04,641.86,549.55,641.96,546.27,641.96"/>
                    <path fill="#686868" d="M693.12,698.63c-3.73,0-14.37-2.76-17.54-3.6l-4.56-1.22l8.02-22.06c-1.6-4.91-7.06-21.46-10.6-29.25
			c-3.36-7.4-18.83-27.44-24.51-34.53l6.75-5.4c0.86,1.08,21.14,26.47,25.63,36.36c4.38,9.63,11.13,30.7,11.42,31.59l0.45,1.41
			l-5.79,15.92c4.76,1.16,9.39,2.14,10.73,2.14c0.88-0.06,4.56-1.03,7.82-1.89c7.99-2.11,20.05-5.3,29.97-5.88
			c11.96-0.7,49.92-3.67,70.31-5.27c4.45-0.35,7.95-0.62,10.01-0.78c11-0.85,43.74-5.06,44.07-5.1l1.1,8.57
			c-1.35,0.17-33.29,4.28-44.51,5.15c-2.06,0.16-5.56,0.43-10,0.78c-20.42,1.6-58.43,4.57-70.48,5.28
			c-9.05,0.53-20.61,3.59-28.27,5.61C697.43,697.97,694.84,698.63,693.12,698.63"/>
                    <path fill="#686868" d="M858.59,737.44c-17.37-7.39-43.62-18.51-47.25-19.9c-3.25-0.16-20.38,1.64-35.56,3.54l-1.21,0.15
			l-61.99-28.1l3.57-7.87l59.77,27.09c34.4-4.27,36.91-3.44,37.87-3.12c2.63,0.88,40.6,17.02,48.19,20.25L858.59,737.44z"/>
                    <path fill="#686868" d="M723.58,687.67l-12.29-61.13l20.33-2.18c-6.52-7.49-7.08-10.29-7.29-11.34c-0.12-0.58-0.26-1.39-0.44-2.4
			c-1.08-6.1-3.6-20.38-8.91-29.3l7.43-4.42c6.12,10.28,8.83,25.65,9.99,32.22c0.16,0.92,0.3,1.67,0.4,2.21
			c0.32,1.14,3.25,5.43,9.98,12.39c1.34,1.39,1.77,3.38,1.11,5.19c-0.66,1.81-2.26,3.07-4.18,3.27l-18.08,1.94l10.42,51.84
			L723.58,687.67z"/>
                    <path fill="#686868" d="M618.95,401.27l-0.33-0.16c-0.58-0.29-14.29-7.2-17.04-13.62c-3.03-7.07-12.93-28.27-14.42-29.85
			c-0.76-0.76-1.7-3.81-5.08-15.51c-4.21-14.57-10.58-36.59-14.93-42.57c-6.91-9.5-18.65-34.6-19.15-35.66l-0.6-1.27l31.58-11.73
			l0.9,2.43l-28.94,10.75c2.52,5.32,12.4,25.85,18.29,33.96c4.62,6.35,10.81,27.75,15.32,43.37c1.92,6.62,3.9,13.47,4.49,14.46
			c2.24,2.5,13.01,26.17,14.91,30.6c1.92,4.47,11.53,10.13,15.49,12.15l98.19-8.61l0.23,2.58L618.95,401.27z"/>
                    <path fill="#686868" d="M733.75,264.21h-23.34c-2.63,0-21.06-8.44-27.09-11.24h-44.54l-56.07,11.21l-0.51-2.54l56.32-11.27h45.37
			l0.26,0.12c9.81,4.55,24.44,11.02,26.27,11.12l23.33,0V264.21z"/>

                    <rect x="580.03" y="284.52" transform="matrix(0.3716 0.9284 -0.9284 0.3716 640.9736 -353.1152)" fill="#686868" width="2.59" height="24.79"/>

                    <rect x="550.33" y="276.47" transform="matrix(0.381 0.9246 -0.9246 0.381 610.6825 -356.6418)" fill="#686868" width="42.73" height="2.59"/>

                    <rect x="576.76" y="318.62" transform="matrix(1 6.144639e-003 -6.144639e-003 1 1.9768 -3.5953)" fill="#686868" width="18.67" height="2.59"/>
                    <path fill="#686868" d="M591.46,363.23l-0.76-2.48c0.99-0.31,24.44-7.48,32.25-8.35c6.69-0.74,21.21-2.14,25.03-2.51l2.38-10.33
			l2.53,0.58l-2.8,12.15l-0.93,0.09c-0.18,0.02-18.23,1.74-25.92,2.59C615.67,355.82,591.71,363.16,591.46,363.23"/>

                    <rect x="622.7" y="347.86" transform="matrix(0.0646 0.9979 -0.9979 0.0646 934.7323 -298.9177)" fill="#686868" width="8.23" height="2.59"/>
                    <path fill="#686868" d="M1006.94,457.87h-9.98v-2.59h9.98c4.11,0,26.14-2.51,43.9-5.17c17.12-2.57,79.82-9.45,80.45-9.52
			l0.14-0.02l0.14,0.02l36.68,4.16l-0.29,2.58l-36.54-4.14c-3.38,0.37-63.54,6.99-80.21,9.49
			C1034.69,455.14,1011.52,457.87,1006.94,457.87"/>
                    <path fill="#686868" d="M1006.27,514.73l-0.19-1.36c-0.05-0.38-5.21-38.11-7.76-49.16c-2.32-10.04-14.2-27.72-19.91-36.22
			c-0.56-0.83-1.07-1.59-1.51-2.26c-2.43-3.64-4.27-8.65-6.22-13.94c-2.34-6.36-4.76-12.94-8.45-18
			c-6.26-8.6-17.33-33.81-22.06-44.58c-0.57-1.29-1.04-2.38-1.41-3.21c-3.31-7.46-19.43-22.77-19.59-22.93l-0.16-0.19
			c-0.88-1.24-88.21-123.65-93.3-129.59c-2.54-2.96-11.21-1.49-18.19-0.32c-6.07,1.02-11.3,1.91-14.19,0.67
			c-6.25-2.68-26.4-25.3-27.26-26.27l1.94-1.72c5.72,6.43,21.6,23.57,26.34,25.6c2.19,0.94,7.55,0.03,12.74-0.84
			c8.4-1.42,17.1-2.88,20.59,1.19c5.13,5.99,88.84,123.32,93.37,129.67c1.47,1.4,16.66,15.96,20.08,23.67
			c0.37,0.83,0.85,1.92,1.42,3.22c4.7,10.69,15.69,35.71,21.79,44.09c3.9,5.36,6.38,12.1,8.78,18.63c1.9,5.15,3.69,10.01,5.94,13.4
			c0.44,0.67,0.95,1.42,1.51,2.25c6.24,9.29,17.86,26.57,20.29,37.08c2.33,10.1,6.65,41.04,7.61,48.03
			c5.2-1.04,23.95-4.94,28.95-7.8l1.44-0.83c5.65-3.26,10.98-6.35,22.36-7.97c11.99-1.71,73.78-7.73,74.41-7.79l0.25,2.58
			c-0.62,0.06-62.35,6.07-74.29,7.77c-10.88,1.56-16.01,4.52-21.43,7.65l-1.45,0.83c-6.17,3.52-30.07,8.17-31.09,8.36
			L1006.27,514.73z"/>
                    <path fill="#686868" d="M1057.36,471.02l-2.28-1.23l4.91-9.12c0.91-1.69,2.59-2.85,4.5-3.1l17.5-2.28l49.31-5.19l0.27,2.58
			l-49.28,5.19l-17.47,2.28c-1.08,0.14-2.03,0.8-2.55,1.76L1057.36,471.02z"/>
                    <polygon fill="#686868" points="1081.72,601.81 1079.13,601.81 1079.13,583.97 1070.1,583.15 1069.77,582.53 1030.09,508.18 
			1032.37,506.96 1071.73,580.7 1081.72,581.6 		"/>
                    <path fill="#686868" d="M1040.67,526.28l-0.28-2.58c0.25-0.03,25.25-2.74,36.29-5.28c11.17-2.58,56.97-8.42,58.92-8.67l0.33,2.57
			c-0.48,0.06-47.61,6.07-58.66,8.62C1066.07,523.52,1041.7,526.17,1040.67,526.28"/>

                    <rect x="1048.64" y="539.99" transform="matrix(0.0977 0.9952 -0.9952 0.0977 1528.7625 -603.6322)" fill="#686868" width="97.29" height="2.59"/>
                    <path fill="#686868" d="M924.38,567.23h-2.59v-11.24c0-3.4,3.57-5.88,5.19-6.82v-22.06l1.21-0.08c0.54-0.04,13.19-0.86,17.63,0.02
			c2.39,0.48,8.34,3.85,17.15,8.93c5.11,2.95,10.91,6.3,12.3,6.64c2.66,0.67,10.58,0.2,13.41-0.03l1.4-0.12v17.83h-2.59v-15.03
			c-3.01,0.21-9.98,0.58-12.85-0.14c-1.66-0.42-6.01-2.9-12.97-6.92c-6.33-3.65-14.21-8.2-16.36-8.63
			c-3.35-0.67-12.45-0.24-15.74-0.05v21.19l-0.72,0.36c-1.16,0.58-4.47,2.73-4.47,4.89V567.23z"/>
                    <polygon fill="#686868" points="967.6,562.9 965.01,562.9 965.01,552.91 927.35,551.23 927.46,548.64 967.6,550.43 		"/>
                    <path fill="#686868" d="M974.52,543.18h-2.59v-22.1l1.19-0.1c4.78-0.4,10.46-1.39,11.29-2.22c0.76-0.98,0.87-7.42,0.49-12.82
			l-0.1-1.39h18.68v2.59h-15.91c0.25,4.29,0.4,11.72-1.33,13.45c-1.68,1.68-8.38,2.53-11.72,2.86V543.18z"/>
                    <path fill="#686868" d="M1083.47,597.79c-0.93,0-1.94-0.05-3.12-0.12c-4.34-0.25-32.64-4.76-33.85-4.95l-0.7-0.11l-0.28-0.65
			c-0.06-0.14-6.1-13.94-8.67-20.81c-2.74-7.32-0.09-13.67,0.02-13.93l0.31-0.73l20.03-1.35l0.17,2.59l-18.39,1.24
			c-0.54,1.72-1.6,6.29,0.27,11.28c2.23,5.95,7.11,17.22,8.34,20.03c4.49,0.71,29.04,4.59,32.89,4.81c4.2,0.24,5.68,0.24,8.85-1.35
			c0.86-0.43,1.79-0.98,2.78-1.57c2.97-1.76,6.35-3.76,9.9-3.76c4.68,0,13.19-0.83,14.69-1.42c0.16-0.26,0.51-0.99,0.8-1.59
			c1.43-2.95,3.58-7.41,6.67-8.18c3.53-0.88,16.95-1.73,17.52-1.77l0.16,2.59c-0.14,0.01-13.75,0.87-17.06,1.69
			c-1.91,0.48-3.9,4.59-4.96,6.8c-0.66,1.37-0.97,1.99-1.35,2.37c-1.72,1.72-16.33,2.11-16.48,2.11c-2.84,0-5.89,1.8-8.58,3.4
			c-1.03,0.61-2,1.18-2.94,1.65C1087.76,597.43,1085.92,597.79,1083.47,597.79"/>
                    <polygon fill="#686868" points="1117.17,565.63 1113.84,533.97 1096.5,534.34 1096.44,531.75 1116.17,531.32 1119.75,565.36 		"/>
                    <path fill="#686868" d="M1097.3,544.47l-0.03-2.59c0.14,0,14.4-0.16,25.52-0.16c10.83,0,15.97-1.65,16.02-1.66l0.82,2.46
			c-0.22,0.07-5.52,1.8-16.84,1.8C1111.68,544.32,1097.44,544.47,1097.3,544.47"/>

                    <rect x="1119" y="543.55" transform="matrix(0.0636 0.998 -0.998 0.0636 1612.1138 -589.6987)" fill="#686868" width="2.59" height="41.36"/>

                    <rect x="1121.87" y="569.35" transform="matrix(0.4122 0.9111 -0.9111 0.4122 1183.7062 -693.5071)" fill="#686868" width="14.94" height="2.59"/>

                    <rect x="1070.85" y="504.12" transform="matrix(0.1029 0.9947 -0.9947 0.1029 1474.6852 -624.2936)" fill="#686868" width="25.2" height="2.59"/>

                    <rect x="1065.78" y="500.86" transform="matrix(0.078 0.997 -0.997 0.078 1489.9374 -606.6694)" fill="#686868" width="14.33" height="2.59"/>

                    <rect x="1090.06" y="502.31" transform="matrix(0.4135 0.9105 -0.9105 0.4135 1105.0344 -708.3531)" fill="#686868" width="24.63" height="2.59"/>
                    <rect x="1093.44" y="500.87" fill="#686868" width="8.28" height="2.59"/>
                    <path fill="#686868" d="M1038.93,652.77c-4.31,0-7.72-0.37-7.93-0.39l0.29-2.58c0.08,0.01,7.72,0.84,14.39,0
			c6.84-0.86,102.79-11.99,103.75-12.11l0.3,2.58c-0.97,0.11-96.9,11.25-103.73,12.1C1043.62,652.67,1041.16,652.77,1038.93,652.77"
                    />
                    <path fill="#686868" d="M1110.59,644.13l-2.42-0.94c0.02-0.05,2.07-5.37,2.07-9.39c0-4.18-4.27-33.23-4.31-33.53l2.57-0.38
			c0.18,1.2,4.34,29.54,4.34,33.9C1112.84,638.31,1110.68,643.89,1110.59,644.13"/>
                    <path fill="#686868" d="M1031.05,708.91c-2.21,0-4.27-0.38-5.82-1.41c-5.25-3.5-31.94-31.84-33.08-33.04l-0.35-0.38v-0.51
			c0-8.19-0.63-22.03-2.33-24.29c-2.45-3.27-12.61-10.9-12.71-10.98l-0.07-0.06l-26.8-23.34l1.7-1.96l26.76,23.31
			c0.8,0.6,10.57,7.98,13.19,11.47c2.61,3.47,2.84,20.7,2.85,25.33c9.64,10.24,28.32,29.65,32.27,32.29
			c3.78,2.52,13.52-0.48,16.96-1.85l0.96,2.41C1043.59,706.29,1036.83,708.91,1031.05,708.91"/>

                    <rect x="919.53" y="587.98" transform="matrix(0.0874 0.9962 -0.9962 0.0874 1448.7926 -402.8569)" fill="#686868" width="49.47" height="2.59"/>
                    <path fill="#686868" d="M1152.96,666.65l-0.32-1.45c-0.21-0.96-5.22-23.64-4.35-31.55c0.83-7.52-3.41-34.83-3.45-35.1l2.56-0.4
			c0.18,1.14,4.34,27.93,3.47,35.79c-0.71,6.38,2.88,24.04,3.99,29.24l27.58-10.25l-8.81-20.83l-6.86-3.92l8.74-13.6l0.38-0.16
			l36.92-15.11l-10.87-33.43c-1.42-4.76-34.62-116.01-35.49-121.22c-0.64-3.83-2.62-27.93-3.75-41.95
			c-0.13-1.61-0.89-3.05-2.13-4.07c-1.25-1.02-2.82-1.47-4.43-1.28l-28.03,3.42l-133.35,16.52l-0.32-2.57l133.35-16.52l28.03-3.42
			c2.31-0.28,4.58,0.37,6.38,1.84c1.8,1.47,2.89,3.55,3.08,5.87c0.9,11.26,3.07,37.86,3.72,41.73
			c0.67,4.04,23.46,80.83,35.41,120.88l11.6,35.69l-38.78,15.86l-6.82,10.61l5.25,3l10.21,24.13L1152.96,666.65z"/>
                    <path fill="#686868" d="M1166.36,394.07c-1.33,0-1.78-0.21-2.13-0.56l-23.34-23.34l1.83-1.83l9.17,9.17
			c2.71-4.62,4.84-6.96,6.34-6.96c1.75,0,17.34,7.12,26.48,11.36l0.51,0.24l0.18,0.53c0.64,1.93,2,6.64,0.61,8.55
			c-0.43,0.6-1.09,0.93-1.84,0.93c-0.88,0-3.67,0.36-6.63,0.75C1171.24,393.73,1168.07,394.07,1166.36,394.07 M1165.85,391.46
			c1.47,0.16,7.61-0.64,11.35-1.13c3.44-0.45,5.68-0.73,6.77-0.77c0.23-0.78-0.14-3.22-0.86-5.55c-8.77-4.06-22.56-10.28-24.8-10.85
			c-0.8,0.51-2.77,3.23-4.51,6.24L1165.85,391.46z"/>
                    <polygon fill="#686868" points="1207.99,558.07 1201.36,534.86 1193.87,535.27 1139.34,540.85 1139.08,538.27 1193.67,532.68 
			1203.28,532.16 1210.48,557.36 		"/>

                    <rect x="1183.97" y="543.53" transform="matrix(0.0606 0.9982 -0.9982 0.0606 1671.7413 -657.6423)" fill="#686868" width="2.59" height="31.66"/>
                    <polygon fill="#686868" points="1141.91,568.52 1141.7,565.93 1173.58,563.42 1174.25,560.06 1176.79,560.57 1175.73,565.85 		"/>

                    <rect x="1169.39" y="535.93" transform="matrix(0.9881 0.1536 -0.1536 0.9881 98.4558 -173.2666)" fill="#686868" width="2.59" height="29.37"/>

                    <rect x="1161.62" y="536.8" transform="matrix(0.9882 0.1532 -0.1532 0.9882 98.2078 -171.6678)" fill="#686868" width="2.59" height="29.11"/>

                    <rect x="1154.08" y="537.47" transform="matrix(0.9863 0.1653 -0.1653 0.9863 107.0361 -183.347)" fill="#686868" width="2.59" height="28.22"/>

                    <rect x="1147.06" y="538.31" transform="matrix(0.9878 0.1555 -0.1555 0.9878 99.965 -171.8842)" fill="#686868" width="2.59" height="29.1"/>
                    <path fill="#686868" d="M1272.75,692.87l-2.53-0.58c2.51-10.98,6.2-27.33,6.81-30.46c-1.31-1.14-6.16-4.35-9.15-6.33
			c-3.46-2.29-4.71-3.13-5.09-3.5c-0.55-0.54-1.95-3.78-16.8-44.04c-1.58-4.3-2.82-7.64-3.38-9.15c-2.65-7.08-5.27-26.41-5.27-29.85
			c0-3.4,3.52-12.15,4.31-14.06l-1.65-35.55l-49.64,3.93l-0.2-2.58l52.31-4.14l1.8,38.8l-0.11,0.27
			c-1.17,2.82-4.22,10.71-4.22,13.33c0,3.24,2.59,22.25,5.1,28.94c0.57,1.52,1.8,4.86,3.39,9.17c4.9,13.27,15,40.66,16.28,43.18
			c0.53,0.42,2.68,1.84,4.6,3.1c10.39,6.87,10.39,7.14,10.39,8.12C1279.69,662.34,1275.34,681.52,1272.75,692.87"/>
                    <polygon fill="#686868" points="1280.57,552.68 1277,522.38 1307.21,507.28 1308.37,509.6 1279.79,523.89 1283.14,552.38 		"/>
                    <polygon fill="#686868" points="1184.6,622.05 1183.73,619.61 1206.73,611.39 1198.95,588.94 1209.44,584.96 1210.36,587.38 
			1202.23,590.47 1210.02,612.97 		"/>
                    <polygon fill="#686868" points="1169.11,611.65 1168.1,609.26 1191.75,599.25 1195.5,607.78 1193.13,608.83 1190.41,602.64 		"/>
                    <path fill="#686868" d="M1212.21,592.59l-1.19-2.3c5.9-3.04,10.22-11.24,10.73-12.71c-0.32-1.22-2.47-5.98-3.42-7.28
			c-0.66,0.07-2.24,0.24-6.03,0.72c-3.58,0.45-7.27,0.93-7.27,0.93l-0.33-2.57c0,0,3.69-0.48,7.28-0.93
			c6.19-0.78,6.58-0.78,6.78-0.78c2.32,0,5.62,9.39,5.62,9.94C1224.37,579.05,1219.33,588.91,1212.21,592.59"/>
                    <polygon fill="#686868" points="1231.36,647.2 1216.24,604.5 1271.88,583.31 1272.81,585.73 1219.53,606.03 1233.8,646.33 		"/>

                    <rect x="1241.14" y="620.24" transform="matrix(0.3766 0.9264 -0.9264 0.3766 1361.7572 -755.8168)" fill="#686868" width="2.59" height="27.24"/>
                    <path fill="#686868" d="M1268.53,608.19l-1.02-2.38l5.44-2.33c0.17-1.03,0.43-3.64-0.17-8.42c-0.88-7.05-3.49-13.64-4.98-15.87
			c-1.82-2.73-8.28-9.81-9.78-10.74c-1.74-0.09-10.71,2.88-18.61,5.87l-0.92-2.43c12.18-4.6,19.1-6.56,20.58-5.82
			c2.06,1.03,9.16,9.1,10.87,11.68c1.98,2.97,4.54,10.13,5.39,16.98c0.88,7.01,0.04,9.79-0.06,10.08l-0.18,0.55L1268.53,608.19z"/>

                    <rect x="1253.74" y="568.51" transform="matrix(0.3181 0.948 -0.948 0.3181 1408.5708 -792.2317)" fill="#686868" width="2.59" height="29.21"/>

                    <rect x="1257.77" y="580.65" transform="matrix(0.3374 0.9414 -0.9414 0.3374 1395.168 -790.4407)" fill="#686868" width="2.59" height="30.35"/>

                    <rect x="1241.94" y="575.93" transform="matrix(0.3222 0.9467 -0.9467 0.3222 1393.2156 -791.4723)" fill="#686868" width="14.79" height="2.59"/>

                    <rect x="1248.42" y="573.82" transform="matrix(0.319 0.9478 -0.9478 0.319 1400.313 -798.5078)" fill="#686868" width="14.71" height="2.59"/>

                    <rect x="1246.58" y="611.52" transform="matrix(0.3513 0.9363 -0.9363 0.3513 1393.9236 -786.2484)" fill="#686868" width="35.57" height="2.59"/>
                    <path fill="#686868" d="M1259.72,696.46c-2.82,0-5.02-0.61-5.94-0.92c-2.77-0.92-10.01-7.32-10.83-8.04l-0.47-0.42l0.9-14.46
			l2.59,0.16l-0.82,13.2c3,2.63,7.83,6.56,9.45,7.1c1.46,0.49,6.65,1.87,11.98-0.79c4.42-2.21,12.57-1.55,15.82-1.17l2.46-31.23
			l-21.73-65.55l2.46-0.82l21.89,66.05l-0.02,0.26l-2.7,34.24l-1.38-0.21c-0.11-0.02-10.89-1.63-15.65,0.74
			C1264.9,696.03,1262.09,696.46,1259.72,696.46"/>
                    <polygon fill="#686868" points="1257.15,635.08 1256.28,632.63 1282.11,623.44 1280,612.19 1271.92,615.13 1271.04,612.69 
			1281.98,608.71 1285.06,625.14 		"/>
                    <path fill="#686868" d="M1213.56,683.5c-10.09,0-11.47-0.56-12.16-1.24c-0.92-0.92-3.28-6.62-4.61-9.95l-0.58-1.45l5.85-1.17
			l0.51,2.54l-2.79,0.56c1.36,3.33,2.87,6.75,3.4,7.56c0.54,0.2,2.61,0.56,10.37,0.56c1.57-0.05,13.72-4.82,24.57-9.41l0.24-0.1
			h11.98c2.6-1.72,8.07-5.19,9.9-5.19c1.82,0,8.98,1.58,9.79,1.76l-0.56,2.53c-2.96-0.66-8.07-1.7-9.23-1.7
			c-0.96,0.09-5.31,2.65-8.79,4.97l-0.33,0.22h-12.23C1234.59,675.81,1216.22,683.5,1213.56,683.5"/>
                    <path fill="#686868" d="M1264.43,695.17l-2.5-0.68l9.75-35.64c0-0.01,0.01-0.05-0.01-0.09c-0.03-0.04-0.06-0.04-0.08-0.05
			c-7.02-0.79-19.12-2.22-21.26-2.94c-2.63-0.88-19.87-10.26-21.82-11.33l1.24-2.28c7.61,4.15,19.58,10.54,21.4,11.15
			c1.35,0.45,9.3,1.53,20.73,2.82c0.78,0.09,1.49,0.51,1.93,1.17c0.44,0.65,0.58,1.46,0.37,2.23L1264.43,695.17z"/>

                    <rect x="1244.42" y="651.86" transform="matrix(0.6301 0.7765 -0.7765 0.6301 973.1956 -723.2278)" fill="#686868" width="2.59" height="16.02"/>

                    <rect x="1249.87" y="672.69" transform="matrix(0.999 0.0437 -0.0437 0.999 31.0015 -53.9822)" fill="#686868" width="2.59" height="19.9"/>

                    <rect x="1280.99" y="656.61" transform="matrix(0.2166 0.9763 -0.9763 0.2166 1649.4982 -734.3262)" fill="#686868" width="2.59" height="7.97"/>

                    <rect x="1206.75" y="576.26" transform="matrix(0.3981 0.9174 -0.9174 0.3981 1261.3055 -767.1073)" fill="#686868" width="16.87" height="2.59"/>
                    <polygon fill="#686868" points="1221.73,616.16 1220.88,613.71 1232.64,609.66 1229.45,600.83 1231.89,599.95 1235.97,611.25 		
			"/>

                    <rect x="1225.41" y="616.1" transform="matrix(0.3178 0.9482 -0.9482 0.3178 1425.0535 -745.8533)" fill="#686868" width="10.88" height="2.59"/>
                    <path fill="#686868" d="M1033.48,732.99c-3.9-6.83-2.63-13.34-0.82-16.06c1.65-2.47,8.15-3.51,11.62-3.89
			c-1.79-8.54-11.11-52.87-12.66-59.05c-1.74-6.98-2.6-45.34-2.63-46.97l2.59-0.06c0.01,0.4,0.88,39.7,2.55,46.4
			c1.72,6.86,12.52,58.38,12.98,60.57l0.3,1.44l-1.46,0.12c-4.69,0.39-10.28,1.61-11.13,2.88c-1.4,2.09-2.38,7.57,0.91,13.33
			L1033.48,732.99z"/>
                    <path fill="#686868" d="M974.12,634.51l-1.7-1.96c0.07-0.06,1.83-1.58,3.68-2.51c1.71-0.86,50.88-3.7,80.04-5.32l0.14,2.59
			c-30.25,1.68-76.04,4.41-79.12,5.1C975.65,633.19,974.13,634.5,974.12,634.51"/>
                    <path fill="#686868" d="M795.13,534.81c-1.53,0-3.58-1.07-6.79-2.81c-2.49-1.35-5.3-2.87-7.3-3.27
			c-4.79-0.96-55.12-28.88-56.86-30.61c-1.8-1.8-13.05-14.78-14.86-17.49c-1.81-2.71-5.79-19.29-6.23-21.17l2.52-0.6
			c1.65,6.93,4.7,18.58,5.87,20.33c1.69,2.54,12.82,15.38,14.54,17.09c2.02,1.75,51.54,29.1,55.53,29.9
			c2.38,0.48,5.25,2.03,8.03,3.53c2.16,1.17,4.6,2.49,5.55,2.49c23.56,0,49.75-0.23,52.93-0.72c1.96-1.88,13.12-12.29,13.62-12.76
			l0.72-0.67l16.4,10.02l-1.35,2.21l-14.72-9c-4.63,4.32-12.44,11.63-13.08,12.26C849.15,534.06,848.4,534.81,795.13,534.81"/>
                    <path fill="#686868" d="M791.08,502.68c-10.04-5.19-25.65-13.14-27.48-13.75c-2.23-0.74-20.56-2.42-22.81-2.52
			c-0.95,0.33-5.11,2.69-8.79,4.89l-5.92,6.76l-1.95-1.71l6.19-7.07l0.17-0.1c8.95-5.37,9.81-5.37,10.18-5.37
			c1.01,0,21.01,1.75,23.75,2.66c2.61,0.87,25.28,12.58,27.85,13.91L791.08,502.68z"/>

                    <rect x="789.39" y="469.03" transform="matrix(0.8515 0.5244 -0.5244 0.8515 371.3069 -342.7314)" fill="#686868" width="2.59" height="30.16"/>
                    <path fill="#686868" d="M730.15,491.1c-0.43-0.53-10.48-13.11-12.25-16.66c-1.77-3.54-5.14-16.99-5.29-17.56l2.52-0.63
			c0.03,0.14,3.44,13.73,5.09,17.02c1.32,2.64,8.4,11.75,11.96,16.2L730.15,491.1z"/>
                    <path fill="#686868" d="M740.81,506.22l-2.37-1.04c1.7-3.86,4.39-10.61,4.39-12.3c0-2.02-1.7-27.97-2.59-41.41l2.59-0.17
			c0.11,1.59,2.6,38.98,2.6,41.58C745.42,495.61,741.58,504.47,740.81,506.22"/>
                    <path fill="#686868" d="M726.6,484.76l-1.29-2.25c2.95-1.69,10.03-5.62,11.9-5.62c1.56,0,20.02,1.31,24.89,1.65l1.5-7.52
			l2.54,0.51l-1.95,9.77l-1.14-0.08c-6.72-0.48-24.46-1.73-25.84-1.73C736.2,479.57,730.91,482.29,726.6,484.76"/>
                    <path fill="#686868" d="M785.86,482.77c-4.29-2.38-16-8.71-18.7-9.38c-2.66-0.67-17.24-2.17-24.57-2.87l0.25-2.58
			c0.87,0.08,21.44,2.05,24.96,2.93c3.52,0.88,17.73,8.74,19.33,9.63L785.86,482.77z"/>
                    <path fill="#686868" d="M791.01,465.96l-2.13-1.49l23.34-33.35c-1.06-0.42-2.78-0.92-4.99-0.92c-3.86,0-48.75,4.31-56.63,5.07
			v5.64l-7.78,4.32v6.15h-2.59v-7.68l7.78-4.32v-6.46l1.17-0.11c2.19-0.21,53.71-5.19,58.05-5.19c4.66,0,7.52,1.87,7.63,1.95
			l1.1,0.74L791.01,465.96z"/>
                    <rect x="754.93" y="433.43" fill="#686868" width="2.59" height="18.82"/>
                    <rect x="760.12" y="432.93" fill="#686868" width="2.59" height="19.31"/>
                    <path fill="#686868" d="M899.74,438.85h-7.78c-0.55,0-1.3,0-36.22-26.19l1.56-2.08c12.88,9.66,32.44,24.19,34.96,25.68h7.49
			V438.85z"/>
                    <polygon fill="#686868" points="904.59,482.07 894.98,482.07 894.98,478.61 889.37,478.61 889.37,476.02 897.58,476.02 
			897.58,479.48 901.8,479.48 897.62,420.92 893.74,417.81 895.36,415.79 900.13,419.6 		"/>
                    <path fill="#686868" d="M870.13,508.22c-8.99,0-17.88-0.21-17.97-0.22l0.06-2.59c13.44,0.33,33.08,0.37,36.15-0.53
			c0.74-2.97-0.01-21.05-1.13-37.33l-7.85-7.07l-13.94,0.87l-0.42-0.5c-3.57-4.28-7.7-8.98-8.85-9.91h-23.87v-2.59h24.21
			c0.65,0,1.45,0,10.08,10.34l13.72-0.86l9.44,8.5l0.04,0.52c0.01,0.09,0.65,9.42,1.08,18.83c0.89,19.59,0.03,20.45-0.59,21.07
			C889.7,507.35,888.82,508.22,870.13,508.22"/>
                    <path fill="#686868" d="M860.4,506.71h-2.59c0-3.79,0.04-22.82,0.93-25.48c0.89-2.66,11.85-20.32,13.1-22.32l2.2,1.37
			c-4.83,7.77-12.24,19.97-12.84,21.77C860.62,483.79,860.4,498.1,860.4,506.71"/>
                    <path fill="#686868" d="M830.81,481.47l-2.18-1.4c5.54-8.67,14.02-22,14.63-23.22c0.43-0.87,0.48-4.36,0.28-7.1l2.59-0.19
			c0.08,1.07,0.43,6.51-0.55,8.46C844.7,459.75,832.22,479.26,830.81,481.47"/>

                    <rect x="826.86" y="459.13" transform="matrix(0.845 0.5348 -0.5348 0.845 375.5555 -374.7014)" fill="#686868" width="14.44" height="2.59"/>

                    <rect x="961.33" y="408.75" transform="matrix(0.3934 0.9194 -0.9194 0.3934 970.722 -629.7916)" fill="#686868" width="2.59" height="23.97"/>
                    <path fill="#686868" d="M968.9,437.98h-16.96l0.11-1.4c0.38-5.02-0.04-10.16-0.9-10.8c-1.66-0.55-10.63-0.24-15.88,0.09
			l-1.38,0.08v-22.49l17.65-0.93l0.14,2.59l-15.2,0.8v17.28c3.93-0.22,13.2-0.64,15.54,0.15c2.8,0.93,2.96,7.42,2.7,12.04h14.18
			V437.98z"/>
                    <path fill="#686868" d="M954.2,412.05h-7.76l-10.37-23.34h-1.75c-0.5,0-1.42,0-4.94-4.32h-13.21v-2.59h14.46l0.39,0.49
			c1.25,1.56,2.91,3.4,3.54,3.83h3.2l10.37,23.34h6.07V412.05z"/>
                    <path fill="#686868" d="M815.45,679.7l-0.95-14.27l27.66-1.73l-1.55-21.65c-32.58,1.21-92.94,3.59-96.23,4.25l-43.23,8.64
			l-0.51-2.54l43.23-8.65c4.37-0.88,94.08-4.21,97.9-4.35l1.25-0.05l1.91,26.76l-27.67,1.73l0.78,11.67L815.45,679.7z"/>
                    <path fill="#686868" d="M773.08,714.27l-2.59-0.14c0.03-0.47,2.59-46.7,2.59-50.93c0-4.22-2.56-37.6-2.59-37.94l2.59-0.2
			c0.11,1.38,2.6,33.82,2.6,38.14C775.68,667.5,773.19,712.36,773.08,714.27"/>
                    <path fill="#686868" d="M800.75,714.25c-0.14-3.64-3.46-89.11-3.46-92.55c0-3.69-4.33-37.79-8.54-47.91
			c-4.27-10.25-9.45-22.34-9.5-22.47l-0.25-0.58l4.63-8.34l2.27,1.26l-4.01,7.22c1.09,2.55,5.53,12.93,9.26,21.91
			c4.31,10.35,8.75,45.15,8.75,48.91c0,3.39,3.42,91.56,3.46,92.45L800.75,714.25z"/>
                    <path fill="#686868" d="M816.11,715.47l-0.45-2.55c5.95-1.05,12.55-2.39,14.54-3.03c-0.22-1.2-1.13-3.92-1.82-5.98
			c-1.25-3.77-2.55-7.66-2.55-9.6v-16.43h2.59v16.43c0,1.52,1.34,5.54,2.42,8.77c2.11,6.33,2.44,7.65,1.52,8.57
			C832.04,711.97,831.21,712.81,816.11,715.47"/>

                    <rect x="824.94" y="714.63" transform="matrix(0.0995 0.995 -0.995 0.0995 1459.1656 -180.4568)" fill="#686868" width="8.69" height="2.59"/>

                    <rect x="807.24" y="652.68" transform="matrix(0.0353 0.9994 -0.9994 0.0353 1444.3032 -188.2394)" fill="#686868" width="24.83" height="2.59"/>

                    <rect x="819.74" y="652.06" transform="matrix(0.0379 0.9993 -0.9993 0.0379 1453.356 -202.814)" fill="#686868" width="24.53" height="2.59"/>
                    <rect x="782.6" y="625.15" fill="#686868" width="2.59" height="17.84"/>
                    <polygon fill="#686868" points="710.9,688.63 705.3,669.97 747.58,661.89 774.38,661.89 774.38,664.49 747.71,664.49 
			708.61,671.97 713.38,687.89 		"/>
                    <path fill="#686868" d="M694.11,681.04l-0.42-0.84c-0.14-0.29-3.53-7.08-4.42-10.64c-0.87-3.46-4.2-21.78-4.34-22.56l-0.17-0.96
			l6.06-3.03c3.54-1.77,12.86-7.76,15.22-10.13c2.89-2.89,9.66-2.97,10.43-2.97v2.59c-1.78,0-6.78,0.4-8.59,2.21
			c-2.81,2.81-12.51,8.92-15.9,10.62l-4.32,2.16c0.71,3.9,3.4,18.5,4.13,21.44c0.65,2.59,2.88,7.34,3.81,9.26l13.12-1.99l0.39,2.56
			L694.11,681.04z"/>
                    <path fill="#686868" d="M722.51,584.95l-0.02-2.59c1.11-0.03,45.87-2.58,69.39-3.93l0.15,2.59
			C785.01,581.42,723.39,584.95,722.51,584.95"/>

                    <rect x="758.8" y="562.12" transform="matrix(0.0582 0.9983 -0.9983 0.0582 1312.0278 -196.3831)" fill="#686868" width="2.59" height="70.09"/>
                    <path fill="#686868" d="M751.25,597.71c-0.38-4.7-2.17-27.24-2.35-30.19c-0.62-0.89-3.77-3.85-6.49-6.24l1.71-1.95
			c7.36,6.44,7.36,7.31,7.36,7.89c0.02,0.84,1.42,18.57,2.36,30.28L751.25,597.71z M748.88,567.23c0,0.02,0,0.03,0,0.05
			C748.88,567.26,748.88,567.24,748.88,567.23"/>
                    <polygon fill="#686868" points="796.59,608.81 791.35,607.11 790.54,600.58 777.05,601.37 776.9,598.78 792.81,597.85 
			793.72,605.15 797.39,606.34 		"/>
                    <path fill="#686868" d="M716.33,580.16l-29.03-34.83c-0.56-0.67-0.77-1.53-0.59-2.38c0.18-0.85,0.72-1.56,1.5-1.94l8.66-4.33
			l1.16,2.32l-8.65,4.33c-0.08,0.04-0.11,0.11-0.12,0.16s-0.01,0.12,0.05,0.19l29.03,34.83L716.33,580.16z"/>

                    <rect x="692.54" y="556.45" transform="matrix(0.5929 0.8053 -0.8053 0.5929 738.2313 -328.3407)" fill="#686868" width="2.59" height="18.96"/>
                    <path fill="#686868" d="M676.66,555.26l-1.66-1.99c2.83-2.36,10.47-9,11.8-11.32c1.32-2.31,2.83-11.37,3.31-14.67l2.57,0.37
			c-0.18,1.26-1.82,12.44-3.62,15.59C687.25,546.38,677.74,554.36,676.66,555.26"/>
                    <path fill="#686868" d="M742.25,559.39c-2.34-2.92-14.05-17.69-14.94-21.25c-0.91-3.62-0.07-13.82-0.03-14.25l2.58,0.21
			c-0.23,2.83-0.7,10.75-0.03,13.41c0.58,2.32,9.17,13.65,14.45,20.25L742.25,559.39z"/>
                    <path fill="#686868" d="M741.38,542.97c-0.15-0.18-3.61-4.54-4.54-7.32c-0.93-2.79-0.15-9.34-0.06-10.08l2.57,0.32
			c-0.23,1.84-0.69,7.04-0.06,8.94c0.65,1.94,3.18,5.36,4.1,6.52L741.38,542.97z"/>
                    <path fill="#686868" d="M727.56,553.35c-0.81-1-7.99-9.84-8.9-13.47c-0.89-3.55-0.13-15.48-0.04-16.83l2.59,0.17
			c-0.24,3.55-0.7,13.35-0.04,16.02c0.65,2.6,6.28,9.88,8.39,12.46L727.56,553.35z"/>
                    <path fill="#686868" d="M720.67,557.7c-1.17-1.35-11.49-13.36-12.4-17.88c-0.89-4.44-0.06-17.09-0.02-17.63l2.59,0.17
			c-0.01,0.13-0.84,12.85-0.02,16.95c0.58,2.93,7.38,11.59,11.81,16.7L720.67,557.7z"/>
                    <path fill="#686868" d="M712.85,562.84c-2.65-3.38-15.84-20.47-15.84-25.01v-12.1h2.59v12.1c0,2.67,8.94,15.3,15.29,23.41
			L712.85,562.84z"/>

                    <rect x="730.29" y="517.77" transform="matrix(0.58 0.8146 -0.8146 0.58 754.562 -365.3553)" fill="#686868" width="2.59" height="62.61"/>

                    <rect x="662" y="542.18" transform="matrix(0.4625 0.8866 -0.8866 0.4625 847.1637 -290.6413)" fill="#686868" width="2.59" height="22.43"/>
                    <path fill="#686868" d="M653.85,606.54l-7.23-21.7l15.95-10.32l24.81,3.82l-0.9,1.63c-0.18,0.33-4.5,8.04-9.81,12.47l-1.66-1.99
			c3.54-2.95,6.71-7.66,8.24-10.12l-20.09-3.09l-13.44,8.7l6.6,19.8L653.85,606.54z"/>
                    <polygon fill="#686868" points="674.66,614.93 665.24,600.8 667.4,599.36 675.27,611.17 680.93,606.76 673.07,596.55 
			675.13,594.96 684.56,607.23 		"/>
                    <polygon fill="#686868" points="698.29,639.94 695.83,635.18 688.21,636.03 679.72,624.98 665.06,630.68 664.12,628.26 
			680.59,621.86 689.38,633.29 697.32,632.41 700.59,638.75 		"/>
                    <polygon fill="#686868" points="681.3,738.7 666.94,731.51 645.64,646.33 660.92,646.33 673.69,642.07 674.51,644.54 
			661.34,648.92 648.96,648.92 669.16,729.73 682.46,736.38 		"/>
                    <path fill="#686868" d="M635.41,701.84c-1.2,0-1.53-0.22-1.8-0.4c-3.16-2.11-7.64-11.69-9.16-16.23
			c-1.44-4.34-3.32-15.82,0.82-17.2c2.56-0.85,33.93-7.14,37.11-7.78l14.51-8.54l1.31,2.24l-14.89,8.76l-0.21,0.04
			c-9.6,1.92-34.95,7.05-37.02,7.74c-1.11,0.66-1.13,8.07,0.82,13.92c1.77,5.29,5.87,13.24,8.05,14.83
			c2.76,0.6,24.64-4.49,43.99-9.62l0.67,2.51c-0.11,0.03-10.73,2.84-21.54,5.41C643.34,701.02,637.74,701.84,635.41,701.84"/>

                    <rect x="626.32" y="681.21" transform="matrix(0.2571 0.9664 -0.9664 0.2571 1137.2324 -114.3739)" fill="#686868" width="33.38" height="2.59"/>

                    <rect x="642.9" y="669" transform="matrix(0.2145 0.9767 -0.9767 0.2145 1177.9244 -124.0899)" fill="#686868" width="46.43" height="2.59"/>
                    <path fill="#686868" d="M572.53,853.56c-0.17-1.2-16.47-120.49-19.89-147.85c-2.79-22.33-2.22-36.67-1.89-45.24
			c0.08-2.08,0.15-3.73,0.15-5.06c0-6.56-5.02-17.5-5.07-17.61l2.35-1.09c0.22,0.47,5.31,11.58,5.31,18.7
			c0,1.39-0.07,3.05-0.15,5.16c-0.34,8.49-0.9,22.7,1.87,44.82c3.42,27.34,19.72,146.62,19.88,147.82L572.53,853.56z"/>
                    <path fill="#686868" d="M520.65,780.76h-2.59v-20.75c0-1.99,0.06-5.33,0.13-9.55c0.21-12.48,0.52-31.33-0.12-42.24
			c-0.85-14.4-9.38-46.1-9.47-46.42l2.5-0.68c0.35,1.31,8.69,32.29,9.55,46.95c0.65,11.01,0.33,29.92,0.13,42.44
			c-0.07,4.21-0.13,7.54-0.13,9.51V780.76z"/>
                    <path fill="#686868" d="M472.77,711.17l-0.2-2.58c0.11-0.01,11.32-0.87,19.85-1.73c8.53-0.85,26.3-3.17,26.48-3.2l0.34,2.57
			c-0.18,0.02-18,2.35-26.56,3.21C484.12,710.29,472.88,711.16,472.77,711.17"/>

                    <rect x="498.27" y="672.97" transform="matrix(0.1254 0.9921 -0.9921 0.1254 1121.1238 107.5408)" fill="#686868" width="2.59" height="33.32"/>

                    <rect x="469.54" y="694.25" transform="matrix(0.1241 0.9923 -0.9923 0.1241 1113.2068 129.9754)" fill="#686868" width="26.88" height="2.59"/>
                    <path fill="#686868" d="M519.33,744.83c-0.29-0.1-28.91-9.51-32.28-10.36c-3.18-0.8-9.83,0.02-9.9,0.03l-0.32-2.57
			c0.29-0.04,7.2-0.88,10.85,0.03c3.46,0.87,31.28,10.02,32.46,10.41L519.33,744.83z"/>
                    <path fill="#686868" d="M519.12,766.15c-0.25-0.14-25.48-13.65-28.88-15.35c-2.66-1.33-9.93-2.23-12.51-2.46l0.24-2.58
			c0.4,0.04,9.79,0.91,13.43,2.72c3.44,1.72,27.91,14.83,28.95,15.38L519.12,766.15z"/>
                    <path fill="#686868" d="M296.7,711.04l-2.52-0.6c3.58-14.9,10.34-44.31,10.34-48.98c-0.08-3.59-18.52-60.74-33.34-106.67
			c-11.27-34.94-21.01-65.12-22.81-71.96c-2.16-8.22-8.76-33.93-15.13-58.8c-6.37-24.86-12.97-50.57-15.13-58.78
			c-4.25-16.14-32.5-79.56-32.78-80.2l2.37-1.06c1.17,2.62,28.62,64.26,32.92,80.6c2.16,8.22,8.75,33.93,15.13,58.79
			c6.37,24.86,12.97,50.57,15.13,58.78c1.78,6.77,11.98,38.37,22.77,71.82c19.6,60.73,33.47,103.99,33.47,107.46
			C307.11,667.59,297.13,709.27,296.7,711.04"/>
                    <path fill="#686868" d="M251.48,624.55c-2.23,0-4.43-0.69-6.3-2.01c-2.68-1.9-4.35-4.87-4.58-8.15
			c-1.87-26.85-6.28-89.8-6.96-94.52c-0.85-5.94-17.11-83-17.28-83.77l2.54-0.54c0.67,3.18,16.44,77.92,17.31,83.94
			c0.69,4.8,5.11,67.83,6.98,94.71c0.17,2.5,1.45,4.76,3.49,6.21c2.04,1.45,4.6,1.9,7.02,1.24l155.52-42.71l0.69,2.5l-155.52,42.71
			C253.42,624.42,252.45,624.55,251.48,624.55"/>
                    <path fill="#686868" d="M264.13,526.6l-1.91-1.75c1.1-1.2,6.7-7.2,9.47-8.13c0.62-0.21,88.47-20.81,92.05-20.81
			c2.89,0,19.02,5.98,21.21,7c1.91,0.22,15.53-2.47,27.78-5.24l0.57,2.53c-18.08,4.08-27.97,5.79-29.4,5.08
			c-1.76-0.88-17.95-6.78-20.17-6.78c-2.66,0.17-87.09,19.31-91.23,20.68C270.85,519.73,266.49,524.02,264.13,526.6"/>

                    <rect x="367.92" y="576.28" transform="matrix(0.2605 0.9655 -0.9655 0.2605 837.6415 61.563)" fill="#686868" width="21.43" height="2.59"/>
                    <polygon fill="#686868" points="337.37,579.3 337.37,568.85 393.85,552.34 397.95,540.02 400.41,540.84 395.88,554.45 
			395.23,554.64 339.96,570.79 339.96,575.9 413.54,555.6 414.23,558.1 		"/>
                    <polygon fill="#686868" points="350.49,566.31 346.61,550.74 392.99,538.26 415.66,539.13 415.56,541.72 393.28,540.87 
			349.74,552.59 353,565.69 		"/>
                    <path fill="#686868" d="M437.68,709.26l-8.65-43.23c-0.82-4.12-6.76-16.87-6.82-17l2.35-1.1c0.25,0.54,6.13,13.16,7.01,17.58
			l8.65,43.23L437.68,709.26z"/>
                    <path fill="#686868" d="M374.58,714.48c-0.71-3.12-6.95-30.66-6.07-35.95c0.1-0.58,0.18-1.24,0.27-1.96
			c0.74-5.74,2.1-16.41,13.97-16.41h46.18v2.59h-46.18c-9.59,0-10.68,8.51-11.4,14.15c-0.1,0.76-0.19,1.45-0.29,2.06
			c-0.67,4.05,4.13,26.6,6.04,34.94L374.58,714.48z"/>
                    <path fill="#686868" d="M1091.45,495.09c-0.01-0.11-0.66-11.1-2.31-16.07c-1.7-5.09-11.92-30.37-12.02-30.63l2.4-0.97
			c0.42,1.04,10.36,25.61,12.08,30.78c1.76,5.29,2.41,16.27,2.44,16.74L1091.45,495.09z"/>
                    <path fill="#686868" d="M1061.34,408.5c-16.06-40.64-43.35-109.78-45.67-116.18c-3.52-9.69-0.21-36.64-0.07-37.78l2.57,0.32
			c-0.03,0.27-3.38,27.46-0.07,36.57c2.32,6.37,29.59,75.48,45.65,116.11L1061.34,408.5z"/>
                    <path fill="#686868" d="M1011.34,456.97c-0.04-0.12-3.92-12.11-5.61-16.34c-1.64-4.09-45.87-88.38-49.67-93.59
			c-0.56-0.08-1.99,0.16-2.97,0.33c-2.56,0.43-5.47,0.92-7.39-0.37c-2.64-1.76-18.01-22.17-21.07-26.24l2.08-1.56
			c6.97,9.29,18.67,24.46,20.43,25.64c1.07,0.71,3.54,0.3,5.53-0.03c2.01-0.34,3.6-0.6,4.71-0.05c0.58,0.29,1.55,0.77,25.64,46.37
			c11.13,21.06,24.18,46.18,25.13,48.54c1.73,4.31,5.51,16.01,5.67,16.51L1011.34,456.97z"/>
                    <path fill="#686868" d="M979.27,417.23c-0.42,0-1.31,0-8.39-5.46l1.58-2.05c2.7,2.08,6,4.45,6.93,4.9
			c1.18-0.21,7.64-1.87,13.37-3.39l0.67,2.5C987.45,415.33,980.13,417.23,979.27,417.23"/>
                    <path fill="#686868" d="M984.15,435.51l-0.08-2.59c0.79-0.03,1.67-0.12,2.26-0.24c-1.12-2.48-4.81-9.69-8.2-16.13l2.29-1.21
			c8.79,16.71,8.79,17.51,8.79,17.89C989.22,434.21,988.64,435.37,984.15,435.51 M986.65,433.46L986.65,433.46z"/>

                    <rect x="940.59" y="310.47" transform="matrix(0.1702 0.9854 -0.9854 0.1702 1099.8097 -660.1384)" fill="#686868" width="2.59" height="25.01"/>

                    <rect x="991.97" y="400.89" transform="matrix(0.0915 0.9958 -0.9958 0.0915 1305.7727 -621.0778)" fill="#686868" width="2.59" height="8.37"/>

                    <rect x="1198.81" y="355.76" transform="matrix(0.5849 0.8111 -0.8111 0.5849 813.0934 -812.2765)" fill="#686868" width="2.7" height="65.02"/>
                    <path fill="#686868" d="M1206.23,451.81c-0.03-0.1-3.44-10.28-5.93-14.43c-1.53-2.55-6.42-6.42-10.35-9.52
			c-2.45-1.94-4.57-3.61-5.87-4.92c-2.11-2.11-3.85-4.73-5.68-7.51c-1.24-1.88-2.53-3.82-3.93-5.57
			c-3.29-4.11-14.6-11.63-14.72-11.71l-1.41-0.93l5.88-5.6l1.86,1.95l-3.44,3.28c3.23,2.21,11.09,7.76,13.94,11.33
			c1.48,1.85,2.8,3.84,4.07,5.77c1.76,2.66,3.42,5.17,5.34,7.09c1.19,1.19,3.35,2.9,5.64,4.71c4.31,3.41,9.21,7.27,10.99,10.25
			c2.64,4.4,6.03,14.53,6.17,14.96L1206.23,451.81z"/>
                    <path fill="#686868" d="M1245.18,483.12c-2.05,0-3.71-0.55-5.25-1.06c-2.51-0.84-20.28-18.35-30.7-28.77
			c-2.25-1.1-22.85-3.86-41.27-5.91l0.3-2.68c18.58,2.07,41.09,4.9,42.79,6.6c15.85,15.85,28.1,27.46,29.77,28.22
			c2.49,0.83,4.68,1.56,8.54,0.01c4.25-1.7,45.37-19.69,45.78-19.87l1.08,2.47c-1.7,0.74-41.57,18.18-45.86,19.9
			C1248.36,482.83,1246.67,483.12,1245.18,483.12"/>

                    <rect x="1226.49" y="378.48" transform="matrix(0.4416 0.8972 -0.8972 0.4416 1057.6689 -870.1288)" fill="#686868" width="2.7" height="72.24"/>

                    <rect x="1183.26" y="408.56" transform="matrix(0.6392 0.7691 -0.7691 0.6392 748.3203 -775.0948)" fill="#686868" width="33.79" height="2.7"/>
                    <polygon fill="#686868" points="1167.11,439.14 1166.89,436.45 1189.73,434.58 1189.73,426.31 1192.43,426.31 1192.43,437.06 		
			"/>
                    <polygon fill="#686868" points="1185.98,422.94 1184.08,421.04 1187.55,417.57 1177.93,405.54 1180.03,403.86 1191.16,417.77 		
			"/>
                    <polygon fill="#686868" points="1204.76,434.98 1191.75,420.11 1196.93,415.79 1191.82,410.04 1186.77,414.37 1185.02,412.32 
			1192.07,406.27 1200.79,416.08 1195.6,420.41 1206.79,433.2 		"/>
                    <path fill="#686868" d="M1134.26,465.99l-2.21-1.55c0.06-0.09,6.01-8.6,7.68-11.93c1.56-3.13,1.59-7.99,1.59-8.04l2.7,0
			c0,0.23-0.02,5.55-1.87,9.25C1140.39,457.22,1134.51,465.63,1134.26,465.99"/>
                    <path fill="#686868" d="M1186.29,474.91l-2.26-15.85c-0.04-0.3-0.23-0.47-0.34-0.55c-0.11-0.07-0.33-0.19-0.63-0.12
			c-9.28,2.11-9.86,2.11-10.13,2.11v-1.35l-0.04-1.35c0.43-0.03,3.17-0.59,9.58-2.04c0.95-0.21,1.95-0.02,2.75,0.53
			c0.8,0.55,1.34,1.42,1.48,2.38l2.26,15.85L1186.29,474.91z"/>
                    <polygon fill="#686868" points="1239.88,518.01 1238.95,495.85 1255.92,495.85 1255.92,498.55 1241.76,498.55 1242.57,517.89 		
			"/>
                    <path fill="#686868" d="M1236.42,518.02c-0.04-0.67-0.87-16.5-0.87-19.96c0-2.03-3.16-3.54-4.27-3.91l-0.92-0.31v-10.36l7.55-4.72
			l1.43,2.29l-6.28,3.93v6.99c1.71,0.76,5.19,2.73,5.19,6.1c0,3.38,0.85,19.65,0.86,19.81L1236.42,518.02z"/>

                    <rect x="1202.09" y="546.76" transform="matrix(0.0983 0.9952 -0.9952 0.0983 1632.9048 -701.2192)" fill="#686868" width="2.59" height="7.36"/>
                    <path fill="#686868" d="M1160.44,502.73l-2.25-8.26c-0.37-1.37,0.43-2.81,1.79-3.2l19.5-5.69l0.73,2.49l-19.5,5.69l2.23,8.29
			L1160.44,502.73z"/>
                    <polygon fill="#686868" points="1214.37,377.09 1211.09,373.8 1197.48,367.85 1198.52,365.47 1212.57,371.62 1216.21,375.25 		"/>

                    <rect x="1189.49" y="383.93" transform="matrix(0.0463 0.9989 -0.9989 0.0463 1525.7798 -817.0378)" fill="#686868" width="2.59" height="13.26"/>
                    <path fill="#016760" d="M1141.12,469.92l-0.12-1.61l1.44-0.73l-0.21-2.75l-1.54-0.5l-0.12-1.63l8,2.66l0.06,0.75L1141.12,469.92z
			 M1143.66,466.96l2-1.02l-2.14-0.73L1143.66,466.96z"/>
                    <polygon fill="#016760" points="1141.5,474.94 1141.18,470.74 1148.94,470.16 1149.06,471.71 1142.74,472.19 1142.94,474.84 		"/>
                    <polygon fill="#016760" points="1141.9,480.25 1141.57,475.88 1149.33,475.29 1149.66,479.67 1148.22,479.77 1148.01,476.95 
			1146.22,477.08 1146.41,479.58 1145.1,479.68 1144.91,477.18 1143.12,477.32 1143.34,480.14 		"/>
                    <path fill="#016760" d="M1143.65,483.63c-0.5,0.04-0.9-0.05-1.2-0.25c-0.3-0.21-0.46-0.49-0.49-0.85
			c-0.02-0.28-0.03-0.55-0.04-0.82c-0.01-0.27,0.02-0.58,0.07-0.94l1.59-0.12c-0.05,0.16-0.09,0.3-0.11,0.42
			c-0.03,0.12-0.04,0.22-0.05,0.31c-0.01,0.09-0.01,0.16-0.01,0.23c0,0.07,0,0.13,0.01,0.19c0.01,0.1,0.05,0.17,0.12,0.21
			c0.07,0.04,0.12,0.06,0.15,0.06l6.1-0.46l0.12,1.55L1143.65,483.63z"/>
                    <path fill="#016760" d="M1142.74,491.38l-0.12-1.61l1.44-0.73l-0.21-2.75l-1.54-0.5l-0.12-1.63l8,2.66l0.06,0.75L1142.74,491.38z
			 M1145.28,488.42l2-1.02l-2.14-0.73L1145.28,488.42z"/>
                    <polygon fill="#016760" points="1151.53,504.43 1143.48,501.21 1143.42,500.4 1145.48,499.18 1143.26,498.29 1143.2,497.49 
			1150.67,493.07 1150.8,494.83 1145.88,497.74 1147.1,498.22 1150.89,495.99 1151.02,497.64 1148.84,498.92 1151.19,499.88 
			1151.31,501.52 1147.22,499.88 1146.08,500.55 1151.4,502.68 		"/>

                    <rect x="1146.96" y="501.92" transform="matrix(0.0754 0.9972 -0.9972 0.0754 1565.6201 -676.7744)" fill="#016760" width="1.56" height="7.78"/>
                    <polygon fill="#016760" points="1150.75,513.25 1150.58,511.01 1144.26,511.49 1144.14,509.94 1150.46,509.46 1150.29,507.23 
			1151.73,507.12 1152.19,513.14 		"/>
                    <path fill="#016760" d="M1148.94,521.76c-0.55,0.04-1.08-0.02-1.58-0.2c-0.5-0.17-0.94-0.43-1.33-0.77s-0.71-0.74-0.95-1.21
			c-0.24-0.47-0.39-0.98-0.43-1.52c-0.04-0.55,0.02-1.08,0.19-1.58c0.17-0.5,0.42-0.94,0.76-1.34c0.33-0.39,0.74-0.71,1.2-0.96
			c0.47-0.25,0.98-0.39,1.53-0.43c0.54-0.04,1.07,0.02,1.57,0.2c0.5,0.17,0.95,0.43,1.33,0.77c0.39,0.34,0.7,0.74,0.95,1.21
			c0.25,0.47,0.39,0.97,0.43,1.53c0.04,0.54-0.03,1.07-0.2,1.57c-0.17,0.5-0.43,0.95-0.76,1.34c-0.33,0.39-0.73,0.71-1.2,0.96
			C1149.99,521.57,1149.48,521.72,1148.94,521.76 M1148.82,520.21c0.33-0.02,0.64-0.11,0.93-0.27c0.29-0.15,0.53-0.35,0.74-0.59
			c0.2-0.24,0.36-0.51,0.46-0.82c0.1-0.31,0.15-0.63,0.12-0.96c-0.03-0.34-0.11-0.65-0.26-0.94c-0.15-0.29-0.34-0.53-0.58-0.74
			c-0.24-0.21-0.51-0.37-0.82-0.47c-0.3-0.11-0.62-0.15-0.95-0.12c-0.33,0.02-0.64,0.11-0.93,0.27c-0.29,0.15-0.53,0.35-0.74,0.59
			c-0.2,0.24-0.36,0.51-0.46,0.82c-0.11,0.31-0.15,0.63-0.12,0.96c0.03,0.33,0.11,0.64,0.26,0.93c0.15,0.29,0.34,0.54,0.58,0.74
			c0.24,0.2,0.51,0.36,0.82,0.47S1148.49,520.23,1148.82,520.21"/>
                    <path fill="#016760" d="M1147.78,527.56c-0.35,0.03-0.68-0.02-0.98-0.13c-0.3-0.11-0.57-0.28-0.8-0.49
			c-0.23-0.21-0.42-0.47-0.56-0.76c-0.14-0.29-0.22-0.61-0.25-0.95c-0.02-0.31,0-0.6,0.08-0.87c0.08-0.27,0.19-0.51,0.35-0.73
			c0.16-0.22,0.36-0.42,0.59-0.58c0.23-0.17,0.49-0.3,0.78-0.4l0.75,1.34c-0.26,0.09-0.49,0.23-0.68,0.42s-0.27,0.43-0.25,0.74
			c0.01,0.1,0.04,0.21,0.08,0.33c0.05,0.12,0.11,0.22,0.18,0.3s0.16,0.16,0.26,0.21c0.1,0.06,0.21,0.08,0.33,0.07
			c0.17-0.01,0.3-0.1,0.4-0.25c0.1-0.16,0.2-0.35,0.28-0.58c0.09-0.23,0.18-0.48,0.28-0.75c0.1-0.27,0.23-0.52,0.39-0.76
			c0.16-0.24,0.37-0.44,0.63-0.61c0.25-0.17,0.58-0.27,0.98-0.3c0.31-0.02,0.61,0.01,0.91,0.1c0.3,0.09,0.56,0.23,0.8,0.41
			c0.24,0.18,0.43,0.41,0.58,0.67c0.15,0.27,0.24,0.56,0.26,0.89c0.04,0.54-0.07,1.01-0.34,1.42c-0.27,0.41-0.65,0.72-1.13,0.94
			l-0.77-1.37c0.18-0.07,0.34-0.18,0.46-0.32c0.13-0.15,0.18-0.32,0.17-0.52c-0.02-0.22-0.11-0.4-0.27-0.54
			c-0.16-0.14-0.35-0.2-0.56-0.18c-0.17,0.01-0.3,0.1-0.39,0.25c-0.1,0.16-0.19,0.35-0.27,0.58c-0.08,0.23-0.17,0.48-0.27,0.75
			s-0.23,0.52-0.4,0.76c-0.17,0.23-0.38,0.44-0.63,0.6C1148.51,527.43,1148.18,527.53,1147.78,527.56"/>
                    <path fill="#016760" d="M1146.06,535.27l-0.12-1.61l1.44-0.73l-0.21-2.75l-1.54-0.5l-0.12-1.63l8,2.66l0.06,0.75L1146.06,535.27z
			 M1148.6,532.31l2-1.02l-2.14-0.73L1148.6,532.31z"/>
                    <path fill="#016760" d="M1189.63,485.22l-1.43-0.67c0.24-0.33,0.37-0.66,0.4-1c0.03-0.34-0.02-0.69-0.13-1.07
			c-0.09-0.3-0.24-0.57-0.45-0.81c-0.21-0.23-0.44-0.42-0.71-0.56c-0.27-0.14-0.56-0.22-0.87-0.25c-0.31-0.03-0.62,0-0.92,0.09
			c-0.3,0.09-0.58,0.24-0.82,0.44c-0.24,0.2-0.44,0.43-0.58,0.69c-0.15,0.26-0.24,0.55-0.28,0.86c-0.04,0.31-0.01,0.62,0.09,0.93
			c0.08,0.27,0.2,0.54,0.36,0.8c0.16,0.26,0.35,0.48,0.57,0.66l1.22-0.38l-0.57-1.83l1.25-0.38l1.03,3.34l-4.34,1.34l-0.42-1.37
			l0.38-0.21c-0.28-0.21-0.51-0.46-0.67-0.76c-0.17-0.3-0.3-0.61-0.4-0.93c-0.16-0.52-0.2-1.02-0.12-1.52
			c0.08-0.5,0.24-0.96,0.5-1.38c0.26-0.42,0.59-0.79,0.99-1.11c0.4-0.32,0.86-0.55,1.35-0.7c0.52-0.16,1.04-0.21,1.56-0.16
			c0.52,0.05,1.01,0.19,1.46,0.42c0.45,0.23,0.85,0.54,1.19,0.93c0.34,0.39,0.6,0.85,0.76,1.37c0.17,0.56,0.23,1.11,0.17,1.65
			S1189.93,484.71,1189.63,485.22"/>
                    <path fill="#016760" d="M1189.85,495.05c-0.53,0.16-1.06,0.22-1.58,0.16c-0.52-0.06-1.01-0.21-1.47-0.45
			c-0.46-0.24-0.85-0.57-1.2-0.97c-0.34-0.4-0.59-0.87-0.75-1.39c-0.16-0.53-0.22-1.06-0.16-1.58c0.06-0.52,0.2-1.02,0.44-1.47
			c0.24-0.46,0.56-0.86,0.96-1.2c0.4-0.35,0.87-0.6,1.39-0.76c0.52-0.16,1.05-0.21,1.58-0.15c0.53,0.06,1.02,0.21,1.47,0.45
			c0.45,0.24,0.85,0.57,1.19,0.97s0.6,0.86,0.76,1.39c0.16,0.52,0.21,1.05,0.15,1.58c-0.06,0.53-0.21,1.02-0.44,1.48
			c-0.24,0.46-0.56,0.86-0.96,1.2C1190.83,494.63,1190.37,494.89,1189.85,495.05 M1189.39,493.56c0.32-0.1,0.6-0.25,0.84-0.47
			c0.25-0.21,0.44-0.46,0.59-0.74c0.15-0.28,0.24-0.58,0.27-0.9c0.03-0.32,0-0.65-0.1-0.96c-0.1-0.32-0.25-0.61-0.46-0.86
			s-0.45-0.45-0.73-0.59c-0.28-0.15-0.58-0.24-0.9-0.28c-0.32-0.04-0.64,0-0.96,0.09c-0.32,0.1-0.6,0.25-0.84,0.47
			c-0.25,0.21-0.44,0.46-0.59,0.73c-0.15,0.28-0.24,0.58-0.27,0.9c-0.03,0.33,0,0.65,0.1,0.96c0.1,0.32,0.25,0.6,0.46,0.85
			c0.21,0.25,0.46,0.45,0.73,0.6c0.28,0.15,0.58,0.24,0.9,0.28C1188.75,493.69,1189.07,493.66,1189.39,493.56 M1195.33,491.14
			l-2.13-0.68l-0.32-1.05l2,0.24L1195.33,491.14z"/>
                    <path fill="#016760" d="M1188.13,502.68l-0.5-1.62l2.47-2.21l-0.31-0.99l-2.88,0.89l-0.45-1.46l7.44-2.29l0.86,2.79
			c0.09,0.29,0.11,0.58,0.07,0.87c-0.04,0.29-0.13,0.57-0.28,0.82c-0.15,0.25-0.35,0.48-0.59,0.68c-0.24,0.2-0.52,0.34-0.84,0.44
			c-0.4,0.12-0.79,0.15-1.18,0.08c-0.39-0.07-0.74-0.24-1.05-0.49L1188.13,502.68z M1192.68,499.16c0.1-0.03,0.19-0.08,0.28-0.15
			c0.09-0.07,0.16-0.14,0.22-0.23c0.06-0.08,0.1-0.17,0.13-0.27c0.02-0.1,0.02-0.19,0-0.27l-0.41-1.34l-1.64,0.5l0.41,1.34
			c0.03,0.09,0.08,0.16,0.15,0.23c0.08,0.07,0.16,0.12,0.25,0.16c0.1,0.03,0.2,0.06,0.3,0.06
			C1192.49,499.2,1192.59,499.19,1192.68,499.16"/>
                    <polygon fill="#016760" points="1190.05,508.92 1189.73,507.86 1193.01,503.68 1188.83,504.97 1188.34,503.37 1195.78,501.08 
			1196.1,502.11 1192.73,506.35 1197,505.04 1197.49,506.63 		"/>
                    <path fill="#016760" d="M1192.43,516.65l-0.48-1.55l1.24-1.03l-0.81-2.64l-1.61-0.15l-0.48-1.57l8.39,0.82l0.22,0.71
			L1192.43,516.65z M1194.26,513.2l1.73-1.43l-2.25-0.24L1194.26,513.2z"/>
                    <polygon fill="#016760" points="969.2,376.76 960.54,376.85 960.17,376.13 961.61,374.21 959.22,374.24 958.85,373.52 
			964.07,366.59 964.87,368.16 961.42,372.73 962.73,372.71 965.39,369.2 966.14,370.68 964.61,372.7 967.15,372.68 967.89,374.16 
			963.48,374.19 962.68,375.25 968.41,375.19 		"/>

                    <rect x="965.44" y="375.59" transform="matrix(0.4506 0.8927 -0.8927 0.4506 869.6151 -654.0792)" fill="#016760" width="1.56" height="7.78"/>
                    <polygon fill="#016760" points="965.72,387.14 963.75,383.23 970.69,379.72 972.67,383.64 971.38,384.29 970.11,381.76 
			968.5,382.57 969.63,384.8 968.46,385.39 967.34,383.16 965.74,383.97 967.01,386.5 		"/>
                    <path fill="#016760" d="M968.63,389.59c-0.44,0.22-0.85,0.3-1.2,0.22c-0.36-0.08-0.61-0.28-0.78-0.6
			c-0.13-0.25-0.24-0.5-0.35-0.74c-0.11-0.25-0.21-0.55-0.29-0.9l1.43-0.72c0.02,0.17,0.03,0.31,0.05,0.43
			c0.02,0.12,0.04,0.22,0.07,0.3c0.03,0.08,0.05,0.16,0.08,0.22c0.03,0.06,0.05,0.12,0.08,0.17c0.05,0.09,0.11,0.14,0.19,0.15
			c0.08,0.01,0.13,0.01,0.17-0.01l5.46-2.76l0.7,1.39L968.63,389.59z"/>
                    <path fill="#016760" d="M972.05,394.6c-0.31,0.16-0.63,0.24-0.95,0.25c-0.32,0.01-0.63-0.04-0.92-0.15
			c-0.3-0.11-0.56-0.28-0.81-0.5c-0.24-0.22-0.44-0.48-0.59-0.78c-0.14-0.28-0.22-0.56-0.26-0.83c-0.03-0.28-0.02-0.55,0.05-0.81
			c0.06-0.26,0.17-0.52,0.33-0.76c0.15-0.24,0.34-0.46,0.57-0.66l1.2,0.95c-0.21,0.18-0.37,0.4-0.47,0.64s-0.09,0.5,0.05,0.78
			c0.05,0.09,0.11,0.18,0.2,0.27c0.09,0.09,0.18,0.16,0.28,0.21c0.1,0.06,0.21,0.09,0.32,0.1c0.11,0.01,0.22-0.01,0.33-0.06
			c0.15-0.08,0.24-0.21,0.28-0.39c0.03-0.18,0.05-0.4,0.04-0.64c-0.01-0.25-0.02-0.51-0.03-0.8c-0.01-0.29,0.01-0.57,0.07-0.85
			c0.06-0.28,0.18-0.55,0.35-0.8c0.17-0.25,0.44-0.47,0.79-0.65c0.28-0.14,0.57-0.23,0.88-0.25c0.31-0.03,0.61,0,0.9,0.08
			c0.29,0.08,0.55,0.21,0.79,0.4c0.24,0.19,0.44,0.43,0.58,0.72c0.24,0.48,0.32,0.96,0.22,1.44c-0.09,0.48-0.32,0.91-0.69,1.3
			l-1.23-0.97c0.14-0.13,0.24-0.29,0.31-0.47c0.06-0.18,0.05-0.36-0.04-0.54c-0.1-0.2-0.25-0.33-0.45-0.39
			c-0.2-0.06-0.4-0.05-0.59,0.05c-0.15,0.07-0.24,0.2-0.26,0.38c-0.03,0.18-0.04,0.39-0.03,0.64c0.01,0.24,0.02,0.51,0.04,0.79
			c0.01,0.29-0.01,0.57-0.08,0.85c-0.06,0.28-0.18,0.55-0.36,0.8C972.68,394.19,972.41,394.42,972.05,394.6"/>
                    <polygon fill="#016760" points="973.22,402.01 972.32,400.22 973.91,396.68 971.21,398.04 970.51,396.65 977.46,393.15 
			978.16,394.54 975.46,395.9 979.26,396.72 980.16,398.51 975.22,397.33 		"/>
                    <path fill="#016760" d="M976.46,408.44l-0.73-1.45l1.05-1.23l-1.24-2.46l-1.61,0.13l-0.74-1.46l8.41-0.59l0.34,0.67L976.46,408.44
			z M977.68,404.74l1.47-1.7l-2.26,0.14L977.68,404.74z"/>
                    <path fill="#016760" d="M842.32,413.06c0.46,0.3,0.84,0.67,1.14,1.11c0.29,0.44,0.49,0.91,0.6,1.42c0.11,0.51,0.12,1.02,0.03,1.54
			c-0.09,0.52-0.28,1.01-0.58,1.47c-0.3,0.47-0.67,0.85-1.1,1.14c-0.44,0.3-0.91,0.5-1.41,0.61c-0.5,0.11-1.02,0.13-1.54,0.04
			c-0.52-0.08-1.01-0.28-1.48-0.58c-0.46-0.3-0.84-0.66-1.13-1.11c-0.29-0.44-0.5-0.91-0.6-1.42c-0.11-0.5-0.11-1.01-0.03-1.54
			c0.08-0.52,0.28-1.01,0.58-1.48c0.29-0.46,0.66-0.84,1.11-1.13c0.44-0.29,0.91-0.5,1.41-0.61c0.5-0.11,1.01-0.13,1.54-0.04
			S841.86,412.77,842.32,413.06 M841.48,414.37c-0.28-0.18-0.58-0.29-0.9-0.35c-0.32-0.05-0.63-0.04-0.94,0.02
			c-0.31,0.07-0.59,0.19-0.87,0.37c-0.27,0.18-0.49,0.42-0.67,0.69c-0.18,0.28-0.3,0.59-0.36,0.91c-0.05,0.32-0.05,0.63,0.02,0.94
			c0.07,0.31,0.19,0.6,0.37,0.87c0.18,0.27,0.41,0.49,0.69,0.67c0.28,0.18,0.58,0.3,0.9,0.35c0.32,0.05,0.63,0.04,0.94-0.02
			c0.3-0.07,0.59-0.19,0.86-0.38c0.27-0.18,0.5-0.42,0.68-0.69c0.18-0.28,0.3-0.58,0.35-0.9c0.06-0.32,0.05-0.64-0.02-0.94
			c-0.07-0.31-0.19-0.59-0.37-0.87C841.99,414.78,841.76,414.55,841.48,414.37"/>
                    <polygon fill="#016760" points="848.49,410.67 846.21,414.21 839.67,410 840.51,408.69 845.84,412.12 847.28,409.89 		"/>
                    <polygon fill="#016760" points="851.37,406.19 849,409.88 842.45,405.67 844.83,401.98 846.04,402.76 844.51,405.14 
			846.02,406.11 847.37,404.01 848.47,404.72 847.12,406.82 848.62,407.79 850.16,405.41 		"/>
                    <path fill="#016760" d="M852.47,400.25c0.3,0.19,0.53,0.42,0.7,0.69c0.17,0.27,0.28,0.56,0.34,0.87c0.05,0.31,0.05,0.63-0.02,0.95
			c-0.07,0.32-0.19,0.62-0.38,0.91c-0.17,0.26-0.36,0.48-0.59,0.65c-0.22,0.17-0.47,0.29-0.73,0.37c-0.26,0.08-0.54,0.11-0.82,0.11
			s-0.57-0.06-0.86-0.15l0.21-1.52c0.26,0.09,0.53,0.11,0.79,0.08c0.26-0.03,0.48-0.18,0.64-0.44c0.06-0.09,0.1-0.19,0.13-0.31
			c0.03-0.12,0.04-0.24,0.04-0.35c0-0.11-0.03-0.22-0.08-0.33s-0.12-0.19-0.22-0.25c-0.14-0.09-0.3-0.1-0.47-0.04
			c-0.18,0.06-0.37,0.16-0.58,0.29c-0.21,0.13-0.43,0.27-0.67,0.43c-0.24,0.16-0.5,0.28-0.77,0.37c-0.27,0.09-0.56,0.12-0.86,0.1
			c-0.31-0.02-0.63-0.14-0.96-0.35c-0.26-0.17-0.48-0.38-0.67-0.63s-0.31-0.52-0.39-0.81c-0.08-0.29-0.1-0.58-0.06-0.89
			c0.04-0.3,0.15-0.59,0.33-0.87c0.29-0.45,0.67-0.76,1.13-0.92c0.46-0.16,0.95-0.19,1.47-0.07l-0.21,1.55
			c-0.19-0.05-0.38-0.06-0.57-0.02c-0.19,0.04-0.34,0.14-0.45,0.31c-0.12,0.19-0.16,0.38-0.11,0.59s0.16,0.37,0.34,0.48
			c0.14,0.09,0.29,0.1,0.47,0.03c0.17-0.07,0.36-0.17,0.56-0.3c0.21-0.13,0.43-0.28,0.67-0.43c0.24-0.16,0.5-0.28,0.77-0.37
			c0.27-0.09,0.56-0.12,0.87-0.1C851.8,399.91,852.13,400.03,852.47,400.25"/>
                    <polygon fill="#016760" points="858.26,395.48 857.18,397.17 853.32,397.59 855.86,399.22 855.01,400.53 848.47,396.32 
			849.31,395.01 851.85,396.65 850.64,392.95 851.72,391.27 853.22,396.13 		"/>
                    <path fill="#016760" d="M862.16,389.43l-0.88,1.36l-1.59-0.29l-1.49,2.32l0.93,1.32l-0.89,1.38l-4.78-6.95l0.41-0.63
			L862.16,389.43z M858.35,390.25l-2.21-0.4l1.26,1.87L858.35,390.25z"/>
                    <path fill="#016760" d="M596.6,273.33c-0.29,0.07-0.59,0.09-0.87,0.05c-0.29-0.04-0.56-0.13-0.8-0.28
			c-0.25-0.15-0.46-0.34-0.64-0.58c-0.18-0.24-0.31-0.53-0.4-0.87l-0.75-3.04l7.55-1.87l0.71,2.85c0.08,0.33,0.1,0.65,0.05,0.95
			c-0.05,0.3-0.14,0.58-0.29,0.82c-0.15,0.24-0.34,0.45-0.58,0.62c-0.23,0.17-0.5,0.29-0.8,0.37c-0.25,0.06-0.53,0.08-0.83,0.06
			c-0.3-0.02-0.57-0.1-0.81-0.23c-0.16,0.29-0.38,0.53-0.66,0.73C597.19,273.12,596.9,273.25,596.6,273.33 M596.32,271.76
			c0.11-0.03,0.21-0.07,0.31-0.14c0.1-0.07,0.18-0.15,0.25-0.25c0.07-0.1,0.12-0.21,0.15-0.33c0.03-0.12,0.03-0.24,0-0.36
			l-0.34-1.35l-1.82,0.45l0.33,1.34c0.03,0.12,0.09,0.23,0.17,0.32c0.08,0.1,0.17,0.17,0.27,0.23c0.1,0.06,0.22,0.09,0.33,0.11
			C596.09,271.8,596.2,271.79,596.32,271.76 M599.33,270.94c0.11-0.03,0.21-0.08,0.3-0.17c0.09-0.08,0.16-0.18,0.22-0.29
			c0.05-0.11,0.09-0.23,0.11-0.36c0.02-0.13,0.01-0.25-0.02-0.37l-0.29-1.16l-1.72,0.43l0.33,1.35c0.03,0.12,0.08,0.22,0.16,0.31
			c0.08,0.08,0.16,0.15,0.26,0.2c0.1,0.04,0.21,0.07,0.32,0.08C599.13,270.97,599.23,270.96,599.33,270.94"/>
                    <path fill="#016760" d="M598.59,280.1c-0.32,0.08-0.66,0.08-1-0.01c-0.35-0.08-0.67-0.23-0.98-0.45
			c-0.31-0.21-0.58-0.48-0.82-0.82c-0.24-0.33-0.41-0.7-0.51-1.11c-0.1-0.4-0.12-0.81-0.06-1.21c0.06-0.4,0.17-0.77,0.34-1.11
			c0.17-0.34,0.39-0.63,0.65-0.86c0.26-0.24,0.56-0.4,0.88-0.48l5.09-1.26l0.38,1.54l-5.09,1.27c-0.12,0.03-0.24,0.09-0.35,0.19
			c-0.11,0.1-0.2,0.22-0.28,0.38c-0.07,0.15-0.12,0.33-0.14,0.53c-0.02,0.2,0,0.41,0.05,0.65s0.14,0.44,0.26,0.6
			c0.11,0.17,0.24,0.3,0.37,0.4c0.14,0.1,0.28,0.17,0.42,0.2c0.14,0.03,0.28,0.04,0.4,0.01l5.09-1.26l0.38,1.54L598.59,280.1z"/>
                    <path fill="#016760" d="M601.64,286.67c-0.57,0.14-1.11,0.18-1.62,0.12c-0.51-0.06-0.97-0.21-1.39-0.46
			c-0.42-0.25-0.77-0.58-1.07-1c-0.3-0.42-0.52-0.92-0.66-1.5l-0.45-1.8l7.55-1.88l0.45,1.8c0.14,0.58,0.19,1.12,0.13,1.63
			c-0.06,0.51-0.21,0.97-0.46,1.38c-0.25,0.41-0.58,0.76-1,1.05C602.71,286.31,602.21,286.52,601.64,286.67 M601.27,285.16
			c0.79-0.2,1.35-0.55,1.67-1.05c0.32-0.51,0.39-1.12,0.21-1.85l-0.07-0.27l-4.94,1.23l0.07,0.27c0.18,0.73,0.54,1.24,1.07,1.53
			C599.81,285.3,600.47,285.35,601.27,285.16"/>
                    <path fill="#016760" d="M603.78,295.37c-0.54,0.13-1.07,0.16-1.59,0.07c-0.52-0.09-1-0.27-1.44-0.54
			c-0.44-0.27-0.82-0.61-1.14-1.04c-0.32-0.42-0.54-0.9-0.68-1.43c-0.13-0.54-0.16-1.07-0.07-1.59c0.08-0.52,0.26-1,0.52-1.45
			c0.27-0.44,0.61-0.82,1.02-1.15c0.42-0.32,0.9-0.55,1.43-0.68c0.53-0.13,1.06-0.15,1.58-0.07c0.52,0.09,1,0.27,1.44,0.53
			s0.82,0.61,1.14,1.03c0.32,0.42,0.55,0.9,0.68,1.43c0.13,0.53,0.15,1.06,0.07,1.58c-0.09,0.52-0.26,1.01-0.52,1.45
			c-0.26,0.44-0.6,0.82-1.03,1.15C604.79,295.01,604.31,295.24,603.78,295.37 M603.41,293.86c0.32-0.08,0.61-0.22,0.87-0.42
			c0.26-0.2,0.47-0.43,0.63-0.7c0.16-0.27,0.27-0.56,0.32-0.89c0.05-0.32,0.04-0.65-0.04-0.97c-0.08-0.33-0.22-0.62-0.42-0.88
			c-0.2-0.26-0.43-0.47-0.7-0.63c-0.27-0.17-0.56-0.27-0.88-0.33c-0.32-0.05-0.64-0.04-0.96,0.04s-0.61,0.22-0.87,0.42
			c-0.26,0.2-0.47,0.43-0.63,0.7c-0.16,0.27-0.27,0.56-0.32,0.89c-0.05,0.32-0.04,0.65,0.04,0.97c0.08,0.32,0.22,0.61,0.42,0.87
			c0.2,0.26,0.43,0.47,0.7,0.63c0.27,0.16,0.56,0.27,0.88,0.33C602.76,293.95,603.08,293.94,603.41,293.86"/>
                    <polygon fill="#016760" points="610.25,305.29 601.78,303.46 601.58,302.67 603.41,301.12 601.08,300.62 600.88,299.83 
			607.51,294.23 607.93,295.94 603.56,299.64 604.84,299.91 608.21,297.07 608.61,298.68 606.67,300.31 609.15,300.86 
			609.55,302.46 605.24,301.52 604.23,302.37 609.83,303.58 		"/>
                    <polygon fill="#016760" points="603.79,311.55 602.77,307.46 610.33,305.59 610.7,307.1 604.55,308.63 605.19,311.2 		"/>
                    <path fill="#016760" d="M605.61,318.89l-0.39-1.57l1.3-0.96l-0.67-2.68l-1.6-0.23l-0.4-1.59l8.34,1.29l0.18,0.73L605.61,318.89z
			 M607.63,315.55l1.81-1.33l-2.23-0.37L607.63,315.55z"/>
                    <polygon fill="#016760" points="607.21,325.34 606.94,324.26 610.45,320.27 606.21,321.32 605.81,319.7 613.36,317.82 
			613.62,318.87 610.03,322.91 614.36,321.84 614.76,323.46 		"/>
                    <polygon fill="#016760" points="616.52,330.54 611.89,328.99 608.34,329.88 607.96,328.37 611.52,327.48 614.89,323.96 
			615.34,325.78 613.37,327.83 616.07,328.73 		"/>
                    <path fill="#016760" d="M612.07,338.92c-0.42-0.18-0.76-0.38-1.02-0.59c-0.27-0.21-0.48-0.43-0.65-0.66
			c-0.17-0.23-0.29-0.47-0.38-0.71c-0.08-0.25-0.16-0.49-0.22-0.73c-0.13-0.52-0.15-1.04-0.06-1.55c0.09-0.51,0.27-0.98,0.53-1.41
			c0.27-0.43,0.61-0.8,1.03-1.12c0.42-0.32,0.9-0.54,1.44-0.68c0.53-0.13,1.06-0.16,1.58-0.07c0.52,0.08,1,0.25,1.44,0.5
			c0.43,0.25,0.81,0.59,1.13,1c0.32,0.41,0.54,0.88,0.67,1.4c0.06,0.24,0.1,0.5,0.13,0.78c0.03,0.28,0.02,0.58-0.02,0.88
			c-0.04,0.3-0.13,0.6-0.26,0.9c-0.13,0.3-0.33,0.59-0.59,0.86l-1.3-0.82c0.18-0.18,0.31-0.37,0.39-0.58
			c0.08-0.21,0.14-0.41,0.16-0.61c0.03-0.2,0.03-0.39,0.01-0.57c-0.02-0.18-0.05-0.33-0.08-0.46c-0.08-0.31-0.21-0.6-0.41-0.84
			c-0.19-0.24-0.42-0.44-0.68-0.59c-0.26-0.15-0.55-0.25-0.86-0.31c-0.31-0.05-0.63-0.04-0.94,0.04c-0.32,0.08-0.6,0.21-0.85,0.4
			c-0.25,0.19-0.46,0.41-0.62,0.67c-0.16,0.26-0.27,0.54-0.33,0.84c-0.06,0.31-0.04,0.62,0.03,0.93c0.03,0.13,0.07,0.27,0.12,0.42
			c0.05,0.16,0.13,0.31,0.23,0.47c0.1,0.16,0.24,0.31,0.42,0.45c0.18,0.15,0.4,0.27,0.69,0.38L612.07,338.92z"/>
                    <polygon fill="#016760" points="612.23,345.55 611.86,344.04 614.99,343.26 614.41,340.92 611.27,341.7 610.9,340.19 
			618.45,338.31 618.83,339.82 615.67,340.6 616.26,342.95 619.41,342.17 619.78,343.68 		"/>
                    <path fill="#016760" d="M389.16,380.1c-0.55,0.2-1.09,0.3-1.6,0.29c-0.52-0.01-0.99-0.11-1.43-0.31c-0.44-0.2-0.83-0.49-1.17-0.88
			c-0.34-0.39-0.61-0.86-0.82-1.42l-0.64-1.75l7.31-2.67l0.64,1.75c0.2,0.56,0.3,1.09,0.3,1.61c0,0.51-0.11,0.99-0.31,1.42
			c-0.2,0.44-0.5,0.82-0.88,1.16C390.18,379.63,389.71,379.9,389.16,380.1 M388.63,378.64c0.77-0.28,1.28-0.69,1.54-1.23
			c0.27-0.54,0.27-1.16,0.01-1.86l-0.1-0.26l-4.77,1.74l0.1,0.26c0.26,0.71,0.67,1.18,1.23,1.41
			C387.2,378.94,387.86,378.92,388.63,378.64"/>
                    <path fill="#016760" d="M392.22,388.53c-0.52,0.19-1.04,0.27-1.57,0.24c-0.53-0.03-1.02-0.16-1.49-0.38
			c-0.47-0.22-0.88-0.52-1.24-0.91c-0.36-0.39-0.64-0.84-0.83-1.35c-0.19-0.52-0.27-1.04-0.24-1.57c0.03-0.53,0.15-1.03,0.36-1.49
			c0.22-0.47,0.52-0.88,0.9-1.25c0.38-0.36,0.83-0.64,1.35-0.83c0.51-0.19,1.03-0.27,1.57-0.23c0.53,0.03,1.03,0.16,1.49,0.38
			s0.88,0.52,1.24,0.9c0.36,0.38,0.64,0.83,0.83,1.35c0.19,0.51,0.27,1.03,0.23,1.56c-0.03,0.53-0.15,1.03-0.36,1.5
			c-0.21,0.47-0.51,0.89-0.9,1.25C393.18,388.07,392.73,388.34,392.22,388.53 M391.68,387.07c0.31-0.11,0.58-0.28,0.82-0.51
			c0.24-0.23,0.42-0.48,0.55-0.76c0.13-0.28,0.21-0.59,0.22-0.92c0.02-0.33-0.03-0.65-0.15-0.96c-0.12-0.32-0.28-0.59-0.51-0.83
			c-0.22-0.23-0.48-0.42-0.76-0.56c-0.29-0.13-0.59-0.21-0.91-0.23c-0.32-0.02-0.64,0.03-0.95,0.14c-0.31,0.11-0.58,0.28-0.82,0.51
			c-0.23,0.23-0.42,0.48-0.55,0.76c-0.13,0.28-0.21,0.59-0.22,0.92c-0.02,0.33,0.03,0.65,0.15,0.96c0.11,0.31,0.28,0.59,0.5,0.83
			c0.22,0.24,0.48,0.42,0.76,0.56c0.28,0.13,0.59,0.21,0.91,0.23C391.05,387.23,391.37,387.18,391.68,387.07"/>
                    <polygon fill="#016760" points="391.38,397.57 390.81,396.01 395,394.48 391.68,393.64 393.68,390.87 389.49,392.4 388.92,390.84 
			396.23,388.17 396.6,389.18 394,392.79 398.32,393.89 398.69,394.9 		"/>
                    <path fill="#016760" d="M394.15,405.16l-0.56-1.52l1.19-1.1l-0.95-2.59l-1.61-0.06l-0.56-1.54l8.42,0.39l0.26,0.7L394.15,405.16z
			 M395.79,401.63l1.65-1.52l-2.25-0.13L395.79,401.63z"/>
                    <path fill="#016760" d="M396.42,411.4l-0.38-1.04l3.06-4.34l-4.1,1.5l-0.57-1.57l7.31-2.67l0.37,1.01l-3.14,4.4l4.19-1.53
			l0.57,1.57L396.42,411.4z M405.67,406.75l-2.16-0.57l-0.38-1.04l2.01,0.15L405.67,406.75z"/>
                    <path fill="#016760" d="M400.6,416.16c-0.33,0.12-0.66,0.17-0.98,0.14c-0.32-0.03-0.62-0.11-0.9-0.26
			c-0.28-0.15-0.53-0.34-0.74-0.58c-0.21-0.25-0.38-0.53-0.5-0.85c-0.11-0.29-0.16-0.58-0.16-0.86c0-0.28,0.05-0.55,0.14-0.8
			c0.09-0.26,0.23-0.5,0.41-0.72c0.18-0.22,0.39-0.42,0.64-0.59l1.08,1.09c-0.23,0.16-0.41,0.35-0.54,0.58
			c-0.13,0.23-0.15,0.49-0.04,0.78c0.04,0.1,0.09,0.19,0.17,0.29c0.08,0.1,0.16,0.18,0.25,0.24c0.09,0.07,0.2,0.11,0.31,0.14
			s0.22,0.02,0.33-0.02c0.16-0.06,0.26-0.18,0.32-0.35c0.06-0.18,0.1-0.39,0.12-0.63c0.02-0.24,0.04-0.51,0.06-0.8
			c0.02-0.29,0.08-0.56,0.17-0.83c0.09-0.27,0.24-0.52,0.44-0.75c0.2-0.23,0.49-0.42,0.86-0.55c0.29-0.11,0.59-0.16,0.91-0.15
			c0.31,0.01,0.61,0.07,0.88,0.18c0.28,0.11,0.52,0.28,0.74,0.49c0.22,0.21,0.38,0.47,0.5,0.78c0.19,0.51,0.2,0.99,0.05,1.46
			c-0.15,0.47-0.43,0.87-0.83,1.21l-1.11-1.11c0.16-0.12,0.28-0.26,0.36-0.44c0.08-0.17,0.09-0.36,0.02-0.54
			c-0.08-0.21-0.21-0.35-0.4-0.44c-0.19-0.09-0.39-0.09-0.59-0.02c-0.16,0.06-0.26,0.17-0.31,0.35c-0.05,0.18-0.08,0.38-0.1,0.63
			c-0.02,0.24-0.04,0.5-0.06,0.79c-0.02,0.29-0.08,0.57-0.17,0.84c-0.1,0.27-0.25,0.52-0.45,0.75
			C401.28,415.83,400.99,416.02,400.6,416.16"/>
                    <polygon fill="#016760" points="400.9,423.66 400.21,421.78 402.21,418.44 399.37,419.48 398.84,418.02 406.15,415.35 
			406.68,416.81 403.85,417.85 407.52,419.11 408.21,420.99 403.43,419.25 		"/>

                    <rect x="404.31" y="419.9" transform="matrix(0.3432 0.9393 -0.9393 0.3432 664.1069 -102.1404)" fill="#016760" width="1.56" height="7.78"/>
                    <polygon fill="#016760" points="403.7,431.34 402.2,427.22 409.51,424.55 411.01,428.67 409.66,429.17 408.69,426.5 407,427.12 
			407.86,429.47 406.63,429.92 405.77,427.57 404.09,428.18 405.06,430.84 		"/>
                    <path fill="#016760" d="M412.48,436.22l-1.46-0.59c0.22-0.34,0.34-0.68,0.35-1.02c0.01-0.34-0.05-0.69-0.19-1.06
			c-0.11-0.3-0.27-0.56-0.49-0.78c-0.22-0.22-0.46-0.4-0.74-0.52c-0.28-0.12-0.57-0.19-0.88-0.21c-0.31-0.02-0.62,0.03-0.92,0.14
			c-0.3,0.11-0.56,0.27-0.79,0.48c-0.23,0.21-0.41,0.46-0.55,0.72c-0.13,0.27-0.21,0.56-0.24,0.87c-0.02,0.31,0.02,0.62,0.13,0.92
			c0.1,0.27,0.23,0.53,0.4,0.78c0.17,0.25,0.37,0.47,0.6,0.63l1.2-0.44l-0.66-1.8l1.23-0.45l1.2,3.29l-4.27,1.56l-0.49-1.34
			l0.37-0.22c-0.29-0.2-0.53-0.44-0.71-0.73c-0.18-0.29-0.33-0.59-0.45-0.91c-0.19-0.51-0.25-1.01-0.2-1.51
			c0.05-0.5,0.2-0.97,0.43-1.41s0.55-0.82,0.94-1.16c0.39-0.34,0.83-0.6,1.31-0.77c0.51-0.19,1.02-0.27,1.55-0.24
			c0.52,0.02,1.02,0.14,1.48,0.35c0.46,0.21,0.88,0.5,1.24,0.87c0.36,0.37,0.64,0.81,0.83,1.33c0.2,0.55,0.29,1.1,0.25,1.64
			C412.91,435.17,412.76,435.7,412.48,436.22"/>
                    <path fill="#016760" d="M413.2,446.02c-0.52,0.19-1.04,0.27-1.57,0.24c-0.53-0.03-1.02-0.16-1.49-0.38
			c-0.47-0.22-0.88-0.52-1.24-0.91c-0.36-0.39-0.64-0.84-0.82-1.35c-0.19-0.52-0.27-1.04-0.24-1.57c0.03-0.53,0.15-1.03,0.37-1.49
			c0.22-0.47,0.51-0.88,0.9-1.25c0.38-0.36,0.83-0.64,1.35-0.83c0.51-0.19,1.04-0.27,1.57-0.23c0.53,0.03,1.03,0.16,1.49,0.38
			s0.88,0.52,1.24,0.9c0.36,0.38,0.64,0.83,0.83,1.35c0.19,0.51,0.26,1.03,0.23,1.56c-0.03,0.53-0.15,1.03-0.37,1.5
			c-0.21,0.47-0.51,0.89-0.9,1.25C414.17,445.56,413.72,445.84,413.2,446.02 M412.67,444.56c0.31-0.11,0.58-0.28,0.82-0.51
			c0.24-0.23,0.42-0.48,0.55-0.76c0.13-0.28,0.21-0.59,0.22-0.92c0.02-0.32-0.03-0.65-0.15-0.96c-0.11-0.32-0.28-0.59-0.51-0.83
			c-0.22-0.23-0.48-0.42-0.76-0.56c-0.29-0.13-0.59-0.21-0.91-0.23c-0.32-0.02-0.64,0.03-0.95,0.14c-0.31,0.11-0.58,0.28-0.82,0.51
			c-0.24,0.23-0.42,0.48-0.55,0.76c-0.13,0.28-0.21,0.59-0.22,0.92c-0.02,0.33,0.03,0.65,0.15,0.96c0.11,0.31,0.28,0.59,0.5,0.83
			c0.22,0.24,0.48,0.42,0.76,0.56c0.28,0.13,0.59,0.21,0.91,0.23C412.04,444.72,412.36,444.68,412.67,444.56"/>
                    <polygon fill="#016760" points="560.94,509.4 552.37,510.66 551.91,509.99 553.08,507.9 550.71,508.25 550.26,507.58 
			554.5,500.01 555.49,501.47 552.69,506.46 553.99,506.26 556.15,502.43 557.09,503.79 555.85,505.99 558.36,505.64 559.29,507 
			554.93,507.63 554.28,508.78 559.95,507.96 		"/>
                    <path fill="#016760" d="M557.9,518.72l-0.96-1.39l1.69-2.85l-0.59-0.85l-2.48,1.7l-0.86-1.26l6.41-4.4l1.66,2.41
			c0.17,0.25,0.28,0.52,0.33,0.81c0.05,0.29,0.04,0.58-0.03,0.87s-0.19,0.56-0.36,0.82c-0.17,0.26-0.4,0.48-0.67,0.67
			c-0.34,0.23-0.71,0.38-1.1,0.42c-0.39,0.05-0.77,0-1.14-0.15L557.9,518.72z M561.2,514c0.08-0.06,0.16-0.13,0.22-0.22
			c0.06-0.09,0.11-0.18,0.15-0.28c0.03-0.1,0.05-0.2,0.04-0.3c-0.01-0.1-0.04-0.19-0.09-0.26l-0.79-1.15l-1.41,0.97l0.79,1.15
			c0.05,0.08,0.12,0.13,0.21,0.18c0.09,0.04,0.19,0.07,0.29,0.07c0.1,0.01,0.2,0,0.31-0.03C561.02,514.1,561.12,514.06,561.2,514"/>
                    <path fill="#016760" d="M565.83,523.39c-0.46,0.31-0.94,0.52-1.46,0.62c-0.52,0.1-1.03,0.1-1.54,0.01
			c-0.51-0.1-0.98-0.29-1.43-0.57c-0.45-0.28-0.83-0.65-1.14-1.1c-0.31-0.46-0.52-0.94-0.62-1.46c-0.1-0.52-0.11-1.03-0.02-1.54
			c0.09-0.51,0.28-0.99,0.56-1.43c0.28-0.45,0.65-0.83,1.1-1.14c0.45-0.31,0.94-0.52,1.46-0.62c0.52-0.1,1.04-0.1,1.54,0
			c0.5,0.1,0.98,0.29,1.43,0.57c0.45,0.28,0.83,0.65,1.14,1.1c0.31,0.45,0.52,0.94,0.62,1.46s0.11,1.04,0.02,1.54
			c-0.09,0.51-0.27,0.98-0.56,1.43C566.65,522.7,566.28,523.08,565.83,523.39 M564.95,522.11c0.27-0.19,0.5-0.42,0.67-0.7
			c0.17-0.28,0.28-0.57,0.34-0.88c0.06-0.31,0.05-0.62-0.01-0.94c-0.06-0.32-0.19-0.62-0.38-0.89c-0.19-0.28-0.43-0.51-0.7-0.68
			c-0.27-0.17-0.56-0.29-0.88-0.35c-0.31-0.06-0.62-0.06-0.94,0c-0.32,0.06-0.61,0.19-0.89,0.38c-0.27,0.19-0.5,0.42-0.67,0.7
			c-0.17,0.28-0.28,0.57-0.34,0.88c-0.06,0.31-0.05,0.62,0.01,0.94c0.06,0.32,0.19,0.62,0.38,0.89c0.19,0.27,0.42,0.5,0.69,0.67
			c0.28,0.18,0.57,0.29,0.88,0.35c0.31,0.06,0.62,0.06,0.94,0C564.38,522.42,564.68,522.29,564.95,522.11"/>
                    <path fill="#016760" d="M568.28,531.28c-0.45-0.02-0.84-0.09-1.17-0.19c-0.32-0.1-0.6-0.23-0.84-0.39
			c-0.24-0.16-0.44-0.34-0.6-0.54c-0.17-0.2-0.32-0.4-0.46-0.61c-0.31-0.44-0.51-0.92-0.6-1.43c-0.09-0.51-0.09-1.01,0-1.5
			c0.1-0.49,0.29-0.96,0.57-1.41c0.28-0.44,0.65-0.82,1.11-1.13c0.45-0.31,0.93-0.52,1.45-0.62c0.52-0.1,1.03-0.12,1.52-0.03
			c0.49,0.08,0.96,0.26,1.41,0.53s0.81,0.63,1.12,1.07c0.14,0.2,0.27,0.43,0.4,0.69c0.13,0.26,0.22,0.53,0.29,0.83
			c0.07,0.29,0.09,0.6,0.08,0.94c-0.02,0.33-0.1,0.67-0.25,1.01l-1.51-0.31c0.1-0.23,0.16-0.46,0.16-0.68
			c0.01-0.22-0.01-0.43-0.06-0.63c-0.04-0.19-0.11-0.37-0.19-0.53c-0.08-0.16-0.16-0.3-0.23-0.41c-0.18-0.27-0.41-0.48-0.68-0.64
			c-0.27-0.16-0.55-0.27-0.85-0.32c-0.3-0.05-0.6-0.05-0.91,0.02c-0.31,0.06-0.6,0.19-0.87,0.37c-0.27,0.18-0.49,0.41-0.66,0.68
			c-0.17,0.27-0.28,0.55-0.34,0.85c-0.06,0.3-0.06,0.6-0.01,0.9c0.06,0.31,0.17,0.6,0.36,0.86c0.08,0.11,0.16,0.23,0.26,0.35
			c0.1,0.13,0.23,0.25,0.38,0.36c0.15,0.11,0.33,0.2,0.55,0.28c0.21,0.07,0.47,0.11,0.78,0.11L568.28,531.28z"/>
                    <path fill="#016760" d="M570.01,536.35l-2.39-3.47l6.41-4.41l0.88,1.28l-5.22,3.59l1.5,2.19L570.01,536.35z M573.16,532.32
			l-3.79-1.39l0.22-0.68l3.79,1.4L573.16,532.32z"/>
                    <path fill="#016760" d="M574.3,542.58l-0.92-1.33l0.88-1.36l-1.56-2.27l-1.58,0.34l-0.93-1.35l8.26-1.72l0.42,0.62L574.3,542.58z
			 M575.01,538.75l1.22-1.88l-2.22,0.44L575.01,538.75z"/>
                    <polygon fill="#016760" points="586.39,546.46 577.82,547.71 577.36,547.05 578.53,544.95 576.16,545.3 575.71,544.64 
			579.95,537.07 580.94,538.52 578.14,543.52 579.44,543.32 581.6,539.48 582.54,540.85 581.3,543.05 583.81,542.69 584.74,544.05 
			580.38,544.68 579.73,545.84 585.4,545.01 		"/>
                    <path fill="#016760" d="M584.57,553.49c-0.29,0.2-0.59,0.33-0.91,0.38c-0.32,0.05-0.63,0.05-0.94-0.02
			c-0.31-0.07-0.6-0.2-0.87-0.38c-0.27-0.18-0.5-0.42-0.69-0.69c-0.18-0.26-0.3-0.52-0.37-0.79c-0.07-0.27-0.09-0.54-0.06-0.81
			c0.03-0.27,0.1-0.54,0.22-0.8c0.12-0.26,0.28-0.51,0.47-0.73l1.32,0.78c-0.18,0.21-0.31,0.44-0.38,0.7s-0.02,0.51,0.15,0.76
			c0.06,0.09,0.14,0.17,0.24,0.24c0.1,0.08,0.2,0.13,0.31,0.17c0.11,0.04,0.22,0.06,0.33,0.06c0.12,0,0.22-0.04,0.32-0.11
			c0.14-0.09,0.21-0.24,0.22-0.42c0.01-0.19,0-0.4-0.04-0.64c-0.04-0.24-0.09-0.5-0.14-0.79c-0.05-0.28-0.07-0.57-0.04-0.85
			c0.02-0.28,0.1-0.56,0.24-0.84c0.14-0.27,0.37-0.52,0.7-0.75c0.26-0.18,0.54-0.3,0.84-0.37c0.3-0.07,0.6-0.09,0.9-0.05
			c0.3,0.04,0.58,0.14,0.84,0.29c0.26,0.15,0.49,0.36,0.67,0.63c0.3,0.44,0.44,0.91,0.42,1.4c-0.03,0.49-0.2,0.95-0.51,1.38
			l-1.35-0.8c0.12-0.15,0.2-0.32,0.24-0.51s0-0.37-0.11-0.53c-0.13-0.18-0.29-0.29-0.5-0.33c-0.21-0.04-0.4,0.01-0.58,0.13
			c-0.14,0.09-0.21,0.23-0.21,0.42c0,0.18,0.01,0.39,0.06,0.63c0.04,0.24,0.09,0.5,0.14,0.78c0.05,0.28,0.06,0.57,0.04,0.85
			c-0.03,0.29-0.11,0.57-0.25,0.84C585.14,553,584.9,553.26,584.57,553.49"/>
                    <polygon fill="#016760" points="586.72,560.68 585.59,559.03 586.69,555.3 584.2,557.01 583.32,555.73 589.74,551.32 
			590.62,552.6 588.13,554.31 592,554.62 593.14,556.27 588.07,555.77 		"/>
                    <path fill="#016760" d="M590.8,566.61l-0.92-1.33l0.88-1.36l-1.56-2.28l-1.58,0.34l-0.93-1.35l8.26-1.72l0.42,0.62L590.8,566.61z
			 M591.51,562.78l1.22-1.89l-2.22,0.44L591.51,562.78z"/>
                    <polygon fill="#016760" points="514.28,648.38 513.49,649.16 508.27,648.17 511.33,651.28 510.14,652.46 504.68,646.91 
			505.45,646.16 510.76,647.19 507.63,644.01 508.82,642.84 		"/>

                    <rect x="512.36" y="640.15" transform="matrix(0.7126 -0.7016 0.7016 0.7126 -304.3679 545.1305)" fill="#016760" width="1.56" height="7.78"/>
                    <polygon fill="#016760" points="520.59,642.17 517.46,645.25 512,639.71 515.13,636.63 516.14,637.65 514.12,639.64 
			515.38,640.92 517.16,639.17 518.08,640.1 516.3,641.85 517.56,643.13 519.57,641.14 		"/>
                    <polygon fill="#016760" points="526.51,636.34 525.32,637.5 522.19,634.33 522.81,637.7 519.45,637.03 522.58,640.21 
			521.4,641.37 515.94,635.83 516.71,635.07 521.07,635.93 520.28,631.55 521.05,630.8 		"/>
                    <path fill="#016760" d="M530.24,627.21c0.39,0.39,0.68,0.84,0.87,1.33s0.28,1,0.27,1.51c-0.01,0.52-0.11,1.02-0.31,1.51
			c-0.2,0.49-0.5,0.93-0.89,1.31c-0.39,0.39-0.84,0.68-1.33,0.87c-0.49,0.19-1,0.29-1.51,0.29c-0.51,0-1.02-0.1-1.51-0.3
			c-0.49-0.2-0.93-0.49-1.32-0.89c-0.38-0.39-0.67-0.83-0.86-1.33c-0.19-0.5-0.28-1-0.27-1.51c0.01-0.51,0.11-1.01,0.31-1.5
			c0.2-0.49,0.49-0.93,0.89-1.32c0.39-0.38,0.83-0.67,1.33-0.86c0.5-0.19,1-0.29,1.51-0.29c0.51,0,1.02,0.1,1.51,0.3
			C529.42,626.52,529.85,626.82,530.24,627.21 M529.13,628.3c-0.23-0.24-0.5-0.41-0.8-0.53c-0.3-0.12-0.61-0.18-0.92-0.18
			c-0.31,0-0.62,0.06-0.92,0.18c-0.3,0.12-0.57,0.3-0.81,0.53c-0.24,0.24-0.42,0.51-0.55,0.81c-0.12,0.3-0.18,0.61-0.19,0.92
			c0,0.32,0.05,0.62,0.17,0.93c0.12,0.3,0.29,0.57,0.53,0.81c0.23,0.24,0.5,0.42,0.8,0.53c0.3,0.12,0.61,0.18,0.92,0.18
			c0.31,0,0.62-0.06,0.93-0.18c0.3-0.12,0.57-0.3,0.81-0.53c0.24-0.23,0.42-0.5,0.54-0.8c0.13-0.3,0.19-0.61,0.19-0.92
			c0-0.31-0.05-0.62-0.17-0.93S529.36,628.53,529.13,628.3"/>
                    <path fill="#016760" d="M535.14,622.35c0.41,0.42,0.72,0.86,0.93,1.33c0.21,0.47,0.31,0.95,0.31,1.43c0,0.48-0.11,0.96-0.32,1.43
			c-0.21,0.47-0.53,0.91-0.95,1.33l-1.32,1.3l-5.46-5.54l1.32-1.3c0.42-0.42,0.87-0.73,1.34-0.94c0.47-0.21,0.94-0.31,1.42-0.31
			c0.48,0.01,0.95,0.11,1.42,0.32C534.29,621.62,534.73,621.93,535.14,622.35 M534.03,623.44c-0.57-0.58-1.16-0.88-1.76-0.9
			c-0.6-0.02-1.16,0.24-1.7,0.76l-0.2,0.2l3.57,3.62l0.2-0.2c0.54-0.53,0.79-1.09,0.77-1.7C534.9,624.62,534.6,624.02,534.03,623.44
			"/>
                    <polygon fill="#016760" points="541.7,621.38 538.7,624.34 533.24,618.79 534.34,617.7 538.79,622.22 540.68,620.35 		"/>

                    <rect x="539.41" y="613.52" transform="matrix(0.7123 -0.7019 0.7019 0.7123 -277.9349 556.8102)" fill="#016760" width="1.56" height="7.78"/>
                    <path fill="#016760" d="M548.64,614.54l-0.79,0.78l-5.22-0.99l3.06,3.11l-1.19,1.17l-5.46-5.54l0.77-0.76l5.31,1.03l-3.13-3.18
			l1.19-1.17L548.64,614.54z M540.58,608.05l0.38,2.21l-0.79,0.78l-0.7-1.89L540.58,608.05z"/>
                    <path fill="#016760" d="M551.24,608.76c0.25,0.25,0.42,0.53,0.53,0.83c0.11,0.3,0.16,0.61,0.14,0.92s-0.09,0.62-0.22,0.92
			c-0.13,0.3-0.32,0.56-0.56,0.8c-0.22,0.22-0.46,0.39-0.72,0.5c-0.25,0.11-0.52,0.18-0.79,0.2c-0.27,0.02-0.55,0-0.82-0.08
			c-0.28-0.07-0.55-0.18-0.8-0.34l0.54-1.43c0.24,0.14,0.49,0.23,0.76,0.25c0.27,0.02,0.51-0.07,0.73-0.28
			c0.07-0.07,0.14-0.17,0.2-0.27c0.06-0.11,0.1-0.22,0.12-0.33c0.02-0.11,0.02-0.23,0-0.34c-0.02-0.11-0.08-0.21-0.16-0.29
			c-0.12-0.12-0.27-0.17-0.45-0.14c-0.19,0.02-0.39,0.08-0.62,0.16c-0.23,0.08-0.48,0.17-0.75,0.27c-0.27,0.1-0.55,0.16-0.83,0.19
			c-0.29,0.03-0.57,0-0.87-0.09c-0.29-0.09-0.58-0.27-0.86-0.56c-0.22-0.22-0.39-0.48-0.51-0.76c-0.12-0.29-0.19-0.58-0.2-0.88
			c-0.01-0.3,0.03-0.59,0.14-0.88s0.27-0.55,0.5-0.77c0.39-0.38,0.82-0.6,1.31-0.65c0.49-0.06,0.97,0.03,1.45,0.26l-0.55,1.47
			c-0.17-0.09-0.35-0.14-0.55-0.15c-0.19,0-0.36,0.07-0.5,0.21c-0.16,0.16-0.24,0.34-0.23,0.55c0,0.21,0.08,0.39,0.23,0.55
			c0.12,0.12,0.27,0.16,0.45,0.14c0.18-0.03,0.39-0.08,0.61-0.17c0.23-0.08,0.48-0.18,0.75-0.28c0.27-0.1,0.55-0.16,0.83-0.19
			c0.29-0.02,0.58,0.01,0.87,0.09C550.66,608.29,550.96,608.47,551.24,608.76"/>
                    <polygon fill="#016760" points="557.94,605.38 556.52,606.79 552.65,606.35 554.77,608.51 553.66,609.6 548.2,604.05 
			549.31,602.96 551.43,605.11 551.06,601.24 552.48,599.84 552.88,604.91 		"/>
                    <path fill="#016760" d="M563.07,600.33l-1.15,1.14l-1.49-0.63l-1.97,1.94l0.61,1.49l-1.17,1.15l-3.14-7.83l0.53-0.52
			L563.07,600.33z M559.17,600.3l-2.07-0.87l0.82,2.1L559.17,600.3z"/>
                    <polygon fill="#016760" points="339.65,725.67 338.55,725.54 336.03,720.87 335.54,725.21 333.88,725.02 334.75,717.29 
			335.82,717.41 338.36,722.18 338.86,717.75 340.52,717.94 		"/>

                    <rect x="341.53" y="718.16" transform="matrix(0.9937 0.1117 -0.1117 0.9937 82.7734 -33.7092)" fill="#016760" width="1.56" height="7.78"/>
                    <polygon fill="#016760" points="348.44,726.66 344.08,726.17 344.96,718.44 349.32,718.93 349.16,720.36 346.34,720.05 
			346.14,721.83 348.62,722.11 348.48,723.41 345.99,723.13 345.79,724.91 348.61,725.23 		"/>
                    <polygon fill="#016760" points="356.7,727.59 355.05,727.41 355.55,722.97 353.32,725.57 351.73,722.54 351.23,726.98 
			349.58,726.79 350.45,719.06 351.52,719.18 353.6,723.11 356.5,719.74 357.57,719.86 		"/>
                    <path fill="#016760" d="M366.15,724.74c-0.06,0.55-0.23,1.05-0.49,1.51c-0.26,0.46-0.6,0.85-1,1.17
			c-0.41,0.32-0.86,0.55-1.37,0.71c-0.51,0.15-1.03,0.2-1.58,0.14c-0.55-0.06-1.05-0.22-1.51-0.48c-0.46-0.26-0.85-0.59-1.17-0.99
			c-0.32-0.4-0.56-0.86-0.72-1.36c-0.16-0.5-0.2-1.03-0.14-1.58c0.06-0.54,0.22-1.05,0.49-1.51c0.26-0.46,0.6-0.85,1-1.17
			c0.4-0.32,0.86-0.55,1.36-0.71c0.5-0.15,1.03-0.2,1.58-0.14c0.54,0.06,1.04,0.22,1.51,0.49c0.46,0.26,0.85,0.6,1.18,0.99
			c0.32,0.4,0.56,0.85,0.72,1.36C366.16,723.67,366.21,724.2,366.15,724.74 M364.6,724.57c0.04-0.33,0.01-0.65-0.09-0.96
			c-0.1-0.31-0.24-0.59-0.44-0.83c-0.2-0.25-0.44-0.45-0.72-0.61c-0.28-0.16-0.59-0.26-0.92-0.3c-0.34-0.04-0.66-0.01-0.97,0.08
			c-0.31,0.09-0.59,0.24-0.84,0.43c-0.25,0.19-0.45,0.43-0.61,0.71c-0.16,0.28-0.26,0.59-0.3,0.92c-0.04,0.33-0.01,0.65,0.09,0.96
			c0.1,0.31,0.24,0.59,0.44,0.83c0.19,0.24,0.43,0.45,0.72,0.61c0.29,0.16,0.59,0.26,0.92,0.29c0.33,0.04,0.65,0.01,0.96-0.08
			c0.31-0.09,0.59-0.24,0.84-0.43c0.25-0.2,0.45-0.43,0.61-0.71C364.46,725.21,364.56,724.9,364.6,724.57"/>
                    <path fill="#016760" d="M373,725.5c-0.07,0.58-0.22,1.1-0.45,1.56c-0.24,0.46-0.54,0.84-0.92,1.14c-0.38,0.3-0.81,0.52-1.31,0.65
			c-0.5,0.13-1.04,0.17-1.63,0.1l-1.85-0.21l0.87-7.73l1.85,0.21c0.59,0.07,1.12,0.22,1.57,0.45c0.46,0.23,0.84,0.54,1.14,0.91
			c0.3,0.37,0.51,0.81,0.64,1.3C373.04,724.37,373.07,724.91,373,725.5 M371.46,725.32c0.09-0.81-0.04-1.46-0.41-1.93
			c-0.36-0.48-0.92-0.76-1.66-0.84l-0.28-0.03l-0.57,5.05l0.28,0.03c0.75,0.08,1.35-0.07,1.81-0.47
			C371.09,726.74,371.37,726.13,371.46,725.32"/>
                    <polygon fill="#016760" points="377.89,729.98 373.7,729.51 374.57,721.78 376.12,721.95 375.41,728.25 378.05,728.55 		"/>

                    <rect x="379.24" y="722.42" transform="matrix(0.9937 0.1117 -0.1117 0.9937 83.5381 -37.9153)" fill="#016760" width="1.56" height="7.78"/>
                    <path fill="#016760" d="M387.57,731.08l-1.1-0.12l-2.52-4.68l-0.49,4.34l-1.66-0.19l0.87-7.73l1.07,0.12l2.54,4.78l0.5-4.43
			l1.66,0.19L387.57,731.08z M387.54,720.73l-1.47,1.68l-1.1-0.12l1.03-1.73L387.54,720.73z"/>
                    <path fill="#016760" d="M393.7,729.46c-0.04,0.35-0.14,0.66-0.31,0.94s-0.38,0.5-0.63,0.69c-0.25,0.19-0.54,0.32-0.85,0.4
			c-0.31,0.08-0.64,0.11-0.98,0.07c-0.31-0.04-0.59-0.12-0.84-0.24c-0.25-0.13-0.47-0.29-0.66-0.49c-0.19-0.2-0.34-0.43-0.46-0.69
			c-0.12-0.26-0.2-0.54-0.24-0.84l1.45-0.48c0.04,0.28,0.13,0.52,0.28,0.75c0.15,0.22,0.38,0.35,0.68,0.38
			c0.1,0.01,0.22,0.01,0.34-0.02c0.12-0.02,0.23-0.06,0.33-0.12c0.1-0.05,0.19-0.13,0.26-0.21c0.07-0.09,0.12-0.19,0.13-0.31
			c0.02-0.17-0.04-0.31-0.18-0.44c-0.13-0.13-0.31-0.26-0.51-0.39s-0.44-0.26-0.68-0.41c-0.25-0.15-0.47-0.32-0.67-0.52
			c-0.2-0.2-0.36-0.45-0.48-0.73c-0.12-0.28-0.15-0.62-0.11-1.02c0.04-0.31,0.13-0.6,0.27-0.88c0.15-0.28,0.33-0.51,0.55-0.71
			c0.23-0.2,0.48-0.34,0.77-0.44c0.29-0.1,0.6-0.13,0.92-0.09c0.54,0.06,0.98,0.26,1.33,0.6c0.35,0.34,0.59,0.77,0.71,1.29
			l-1.49,0.5c-0.04-0.19-0.11-0.36-0.23-0.52c-0.12-0.15-0.28-0.24-0.48-0.26c-0.22-0.02-0.41,0.03-0.58,0.17
			c-0.16,0.13-0.26,0.31-0.28,0.52c-0.02,0.17,0.04,0.31,0.17,0.43c0.14,0.12,0.31,0.25,0.52,0.37c0.21,0.13,0.44,0.26,0.68,0.41
			c0.25,0.15,0.47,0.33,0.67,0.53c0.2,0.21,0.36,0.45,0.48,0.74C393.71,728.71,393.74,729.06,393.7,729.46"/>
                    <polygon fill="#016760" points="400.54,732.54 398.55,732.32 396.46,729.04 396.12,732.04 394.58,731.87 395.45,724.14 
			396.99,724.31 396.65,727.31 399.43,724.58 401.42,724.81 397.72,728.31 		"/>
                    <path fill="#016760" d="M407.7,733.35l-1.61-0.18l-0.45-1.55l-2.74-0.31l-0.77,1.42l-1.63-0.18l4.11-7.37l0.74,0.08L407.7,733.35z
			 M405.27,730.3l-0.62-2.16l-1.12,1.96L405.27,730.3z"/>
                    <polygon fill="#016760" points="1027.74,442.18 1024.94,450.38 1024.14,450.49 1022.8,448.49 1022.04,450.75 1021.23,450.86 
			1016.44,443.62 1018.19,443.4 1021.35,448.17 1021.77,446.93 1019.34,443.25 1020.99,443.04 1022.38,445.16 1023.21,442.76 
			1024.85,442.55 1023.42,446.72 1024.14,447.83 1026,442.4 		"/>
                    <polygon fill="#016760" points="1033.84,441.4 1031.77,445.82 1032.24,449.45 1030.69,449.65 1030.23,446.02 1027.12,442.26 
			1028.98,442.03 1030.78,444.22 1031.99,441.64 		"/>
                    <path fill="#016760" d="M1040.81,441.73l-0.9,1.3c-0.28-0.29-0.59-0.48-0.91-0.56c-0.33-0.08-0.69-0.1-1.08-0.05
			c-0.32,0.04-0.61,0.14-0.87,0.3c-0.26,0.16-0.49,0.37-0.67,0.61c-0.18,0.24-0.31,0.51-0.4,0.81c-0.08,0.3-0.1,0.61-0.06,0.92
			c0.04,0.32,0.14,0.61,0.29,0.88c0.16,0.27,0.35,0.5,0.58,0.7c0.23,0.19,0.5,0.33,0.8,0.42c0.3,0.09,0.61,0.12,0.93,0.07
			c0.28-0.04,0.57-0.11,0.85-0.22c0.29-0.11,0.54-0.26,0.75-0.44l-0.16-1.27l-1.9,0.24l-0.17-1.3l3.47-0.44l0.58,4.51l-1.42,0.18
			l-0.14-0.41c-0.25,0.24-0.54,0.42-0.87,0.53c-0.32,0.11-0.65,0.19-0.99,0.24c-0.53,0.07-1.04,0.02-1.52-0.14
			c-0.48-0.16-0.9-0.41-1.27-0.73c-0.37-0.33-0.68-0.72-0.92-1.17c-0.24-0.45-0.4-0.94-0.46-1.45c-0.07-0.54-0.03-1.06,0.11-1.56
			c0.14-0.51,0.36-0.96,0.67-1.37c0.3-0.4,0.68-0.74,1.12-1.02c0.44-0.27,0.94-0.44,1.48-0.51c0.58-0.07,1.13-0.04,1.65,0.12
			C1039.88,441.08,1040.36,441.35,1040.81,441.73"/>
                    <path fill="#016760" d="M1050.53,443.2c0.07,0.55,0.03,1.08-0.12,1.58c-0.15,0.51-0.38,0.96-0.7,1.37
			c-0.32,0.41-0.71,0.74-1.17,1.01c-0.46,0.27-0.96,0.44-1.5,0.5c-0.55,0.07-1.08,0.03-1.58-0.11c-0.51-0.15-0.96-0.37-1.38-0.69
			c-0.41-0.31-0.75-0.7-1.02-1.15c-0.27-0.45-0.44-0.95-0.51-1.5c-0.07-0.54-0.03-1.07,0.12-1.58c0.15-0.51,0.38-0.97,0.7-1.37
			c0.32-0.4,0.7-0.74,1.16-1.01c0.45-0.27,0.96-0.44,1.5-0.51c0.54-0.07,1.07-0.03,1.58,0.12c0.51,0.15,0.97,0.38,1.38,0.69
			c0.41,0.31,0.75,0.69,1.02,1.15C1050.29,442.16,1050.46,442.66,1050.53,443.2 M1048.99,443.4c-0.04-0.33-0.15-0.63-0.31-0.91
			c-0.17-0.28-0.38-0.51-0.62-0.7c-0.25-0.19-0.53-0.33-0.84-0.42c-0.31-0.09-0.64-0.11-0.97-0.07c-0.34,0.04-0.64,0.15-0.92,0.31
			c-0.28,0.17-0.52,0.37-0.71,0.62c-0.2,0.25-0.34,0.53-0.43,0.84c-0.09,0.31-0.11,0.63-0.07,0.96c0.04,0.33,0.15,0.63,0.32,0.91
			c0.17,0.28,0.38,0.51,0.62,0.71c0.25,0.19,0.53,0.33,0.84,0.42c0.32,0.09,0.64,0.11,0.97,0.07c0.33-0.04,0.64-0.15,0.92-0.31
			c0.28-0.17,0.52-0.37,0.71-0.62c0.19-0.25,0.33-0.53,0.43-0.84C1049.01,444.04,1049.03,443.73,1048.99,443.4"/>
                    <polygon fill="#016760" points="1057.92,446.18 1056.82,446.32 1053.26,442.37 1053.81,446.7 1052.16,446.91 1051.17,439.19 
			1052.24,439.06 1055.84,443.1 1055.28,438.67 1056.94,438.46 		"/>
                    <path fill="#016760" d="M1066.53,441.16c0.07,0.55,0.03,1.08-0.12,1.58c-0.15,0.51-0.38,0.96-0.7,1.37
			c-0.32,0.41-0.71,0.74-1.16,1.01c-0.46,0.27-0.96,0.44-1.5,0.5c-0.55,0.07-1.08,0.03-1.58-0.11c-0.51-0.14-0.96-0.37-1.38-0.69
			c-0.41-0.31-0.75-0.7-1.02-1.15c-0.27-0.45-0.44-0.96-0.51-1.5c-0.07-0.54-0.03-1.07,0.12-1.58c0.15-0.51,0.38-0.97,0.7-1.37
			c0.32-0.4,0.7-0.74,1.16-1.01c0.45-0.27,0.95-0.44,1.5-0.51c0.54-0.07,1.07-0.03,1.58,0.12c0.51,0.15,0.97,0.38,1.38,0.69
			c0.41,0.31,0.75,0.69,1.02,1.15C1066.29,440.12,1066.46,440.62,1066.53,441.16 M1064.98,441.35c-0.04-0.33-0.15-0.63-0.32-0.91
			c-0.17-0.28-0.38-0.51-0.62-0.7c-0.25-0.19-0.53-0.33-0.84-0.42c-0.32-0.09-0.64-0.11-0.97-0.07c-0.34,0.04-0.64,0.15-0.92,0.31
			c-0.28,0.17-0.52,0.37-0.71,0.62c-0.2,0.25-0.34,0.53-0.43,0.84c-0.09,0.31-0.11,0.63-0.07,0.96c0.04,0.33,0.15,0.63,0.32,0.91
			c0.17,0.28,0.38,0.51,0.62,0.7c0.25,0.19,0.53,0.33,0.84,0.42c0.32,0.09,0.64,0.11,0.97,0.07c0.33-0.04,0.63-0.14,0.92-0.31
			c0.28-0.17,0.52-0.37,0.71-0.62c0.19-0.25,0.33-0.53,0.43-0.84C1065,442,1065.02,441.69,1064.98,441.35"/>
                    <polygon fill="#016760" points="1077.13,435.88 1074.32,444.09 1073.52,444.19 1072.19,442.19 1071.42,444.46 1070.62,444.56 
			1065.83,437.33 1067.58,437.1 1070.74,441.88 1071.15,440.63 1068.73,436.96 1070.38,436.75 1071.76,438.86 1072.6,436.46 
			1074.23,436.25 1072.8,440.42 1073.53,441.53 1075.39,436.11 		"/>
                    <path fill="#016760" d="M1083.97,442.86l-1.61,0.21l-0.81-1.41l-2.74,0.35l-0.42,1.56l-1.63,0.21l2.25-8.13l0.74-0.09
			L1083.97,442.86z M1080.88,440.47l-1.12-1.95l-0.62,2.17L1080.88,440.47z"/>
                    <path fill="#016760" d="M973.76,600.23c0.05,0.55-0.02,1.08-0.19,1.58c-0.17,0.5-0.42,0.94-0.76,1.34
			c-0.34,0.39-0.74,0.71-1.21,0.96s-0.98,0.39-1.52,0.43c-0.55,0.04-1.08-0.02-1.58-0.18c-0.5-0.17-0.95-0.42-1.34-0.75
			c-0.39-0.33-0.72-0.73-0.96-1.2c-0.25-0.46-0.4-0.97-0.44-1.53c-0.04-0.54,0.02-1.07,0.19-1.57c0.17-0.5,0.42-0.95,0.76-1.34
			s0.74-0.71,1.2-0.96c0.46-0.25,0.97-0.4,1.53-0.44c0.54-0.04,1.07,0.02,1.57,0.19s0.95,0.42,1.35,0.75s0.72,0.73,0.97,1.2
			C973.57,599.18,973.71,599.69,973.76,600.23 M972.21,600.36c-0.03-0.33-0.12-0.64-0.27-0.92c-0.16-0.29-0.35-0.53-0.59-0.73
			c-0.24-0.2-0.51-0.36-0.82-0.46c-0.31-0.1-0.63-0.14-0.96-0.12c-0.34,0.03-0.65,0.12-0.94,0.27c-0.29,0.15-0.53,0.35-0.74,0.58
			c-0.21,0.24-0.36,0.51-0.46,0.82c-0.1,0.31-0.14,0.63-0.12,0.96c0.03,0.33,0.12,0.64,0.27,0.92c0.15,0.29,0.35,0.53,0.59,0.73
			c0.24,0.2,0.51,0.36,0.82,0.46c0.31,0.1,0.63,0.14,0.96,0.12c0.33-0.03,0.64-0.12,0.93-0.27c0.29-0.15,0.54-0.35,0.74-0.58
			c0.2-0.24,0.36-0.51,0.46-0.82C972.19,601.01,972.23,600.69,972.21,600.36"/>
                    <polygon fill="#016760" points="980.59,603.58 974.07,604.11 977.33,597.51 973.9,597.78 973.79,596.33 979.94,595.83 
			976.67,602.45 980.47,602.15 		"/>

                    <rect x="981.3" y="595.67" transform="matrix(0.9968 -0.0803 0.0803 0.9968 -44.97 80.7908)" fill="#016760" width="1.56" height="7.78"/>
                    <polygon fill="#016760" points="991.76,602.68 990.11,602.81 989.75,598.37 988.05,601.35 985.91,598.68 986.27,603.12 
			984.62,603.26 983.99,595.5 985.07,595.42 987.86,598.88 990.06,595.01 991.14,594.92 		"/>
                    <path fill="#016760" d="M997.46,599.92c0.03,0.35-0.01,0.68-0.13,0.98c-0.11,0.3-0.27,0.57-0.49,0.8
			c-0.21,0.23-0.47,0.42-0.76,0.56c-0.29,0.14-0.61,0.23-0.95,0.25c-0.31,0.03-0.6,0-0.87-0.08c-0.27-0.08-0.51-0.19-0.74-0.35
			c-0.22-0.16-0.42-0.36-0.58-0.59c-0.17-0.23-0.3-0.49-0.4-0.77l1.33-0.75c0.09,0.26,0.23,0.49,0.42,0.68s0.43,0.27,0.74,0.25
			c0.1-0.01,0.21-0.04,0.33-0.08s0.21-0.11,0.3-0.18s0.16-0.16,0.21-0.26c0.06-0.1,0.08-0.21,0.07-0.33c-0.01-0.17-0.1-0.3-0.26-0.4
			c-0.16-0.1-0.35-0.2-0.58-0.28c-0.23-0.09-0.48-0.18-0.75-0.27c-0.27-0.1-0.53-0.22-0.76-0.39c-0.24-0.16-0.44-0.37-0.61-0.62
			s-0.27-0.58-0.3-0.98c-0.02-0.31,0.01-0.61,0.1-0.91c0.09-0.3,0.23-0.57,0.41-0.8c0.18-0.24,0.41-0.43,0.67-0.58
			s0.56-0.24,0.88-0.27c0.54-0.04,1.01,0.07,1.42,0.34c0.41,0.27,0.73,0.64,0.95,1.13l-1.36,0.78c-0.07-0.18-0.18-0.34-0.32-0.46
			c-0.15-0.13-0.32-0.18-0.52-0.17c-0.22,0.02-0.4,0.11-0.54,0.27c-0.14,0.16-0.19,0.35-0.18,0.56c0.01,0.17,0.1,0.3,0.25,0.39
			c0.16,0.1,0.35,0.18,0.58,0.27c0.23,0.08,0.48,0.17,0.75,0.27c0.27,0.1,0.53,0.23,0.76,0.39c0.24,0.17,0.44,0.38,0.61,0.63
			C997.33,599.18,997.43,599.51,997.46,599.92"/>
                    <polygon fill="#016760" points="1004.77,601.63 1002.78,601.79 1000.09,598.98 1000.34,601.99 998.79,602.11 998.16,594.36 
			999.71,594.23 999.95,597.24 1002.15,594.04 1004.15,593.88 1001.19,598.02 		"/>
                    <path fill="#016760" d="M1011.95,601.05l-1.61,0.13l-0.74-1.44l-2.75,0.22l-0.49,1.54l-1.63,0.13l2.62-8.02l0.75-0.06
			L1011.95,601.05z M1008.97,598.52l-1.03-2l-0.72,2.14L1008.97,598.52z"/>
                    <polygon fill="#016760" points="878.7,736.24 876.48,734.48 875.36,735.9 877.38,737.5 876.57,738.52 874.55,736.92 
			872.55,739.45 871.32,738.49 876.15,732.38 879.59,735.11 		"/>
                    <path fill="#016760" d="M880.37,745.64l-1.27-1l0.44-1.56l-2.17-1.71l-1.41,0.79l-1.29-1.02l7.38-4.08l0.59,0.46L880.37,745.64z
			 M879.92,741.77l0.61-2.16l-1.99,1.07L879.92,741.77z"/>
                    <path fill="#016760" d="M886.66,747.77c-0.19,0.24-0.41,0.43-0.66,0.58c-0.25,0.14-0.52,0.24-0.8,0.27
			c-0.28,0.04-0.57,0.01-0.86-0.07s-0.57-0.23-0.85-0.44l-2.46-1.94l4.83-6.1l2.3,1.82c0.27,0.21,0.47,0.45,0.62,0.72
			c0.15,0.27,0.24,0.54,0.27,0.83s0.01,0.57-0.07,0.85c-0.08,0.28-0.21,0.54-0.4,0.78c-0.16,0.21-0.37,0.39-0.62,0.56
			c-0.25,0.17-0.51,0.27-0.78,0.31c0.05,0.32,0.03,0.65-0.07,0.98C887,747.23,886.85,747.52,886.66,747.77 M885.48,746.7
			c0.07-0.09,0.12-0.19,0.16-0.3c0.04-0.11,0.05-0.23,0.05-0.35c-0.01-0.12-0.04-0.24-0.08-0.35c-0.05-0.11-0.12-0.21-0.22-0.29
			l-1.09-0.87l-1.17,1.47l1.08,0.85c0.1,0.08,0.21,0.13,0.33,0.15c0.12,0.02,0.24,0.03,0.36,0.01c0.12-0.02,0.23-0.06,0.33-0.12
			S885.4,746.79,885.48,746.7 M887.36,744.2c0.07-0.09,0.11-0.19,0.13-0.31c0.02-0.12,0.02-0.24-0.01-0.36
			c-0.02-0.12-0.07-0.24-0.13-0.35c-0.06-0.11-0.14-0.21-0.24-0.28l-0.94-0.74l-1.1,1.39l1.09,0.86c0.1,0.08,0.2,0.13,0.32,0.15
			s0.22,0.02,0.33,0c0.11-0.03,0.21-0.07,0.31-0.13C887.21,744.36,887.29,744.28,887.36,744.2"/>
                    <path fill="#016760" d="M890.6,753.73l-1.33-1.05l0.78-3.22l-0.81-0.64l-1.87,2.36l-1.2-0.95l4.83-6.1l2.29,1.82
			c0.23,0.18,0.42,0.41,0.55,0.67c0.13,0.26,0.21,0.54,0.23,0.84c0.02,0.29-0.01,0.59-0.1,0.89c-0.09,0.3-0.24,0.58-0.44,0.84
			c-0.26,0.33-0.57,0.57-0.93,0.73c-0.36,0.16-0.74,0.22-1.14,0.19L890.6,753.73z M892.35,748.25c0.06-0.08,0.11-0.17,0.15-0.28
			c0.04-0.1,0.05-0.21,0.06-0.31c0-0.1-0.01-0.2-0.05-0.3c-0.04-0.1-0.09-0.17-0.16-0.23l-1.1-0.87l-1.06,1.34l1.1,0.87
			c0.07,0.05,0.16,0.09,0.26,0.1c0.1,0.01,0.2,0.01,0.3-0.02c0.1-0.02,0.19-0.06,0.28-0.12
			C892.21,748.39,892.29,748.32,892.35,748.25"/>
                    <polygon fill="#016760" points="900.41,751.57 895.8,753.17 893.53,756.05 892.31,755.08 894.58,752.21 895.09,747.36 
			896.56,748.52 896.25,751.35 898.94,750.41 		"/>
                    <path fill="#016760" d="M902.02,760.92c-0.44,0.11-0.83,0.16-1.17,0.16c-0.34,0-0.64-0.05-0.92-0.13
			c-0.27-0.08-0.52-0.19-0.73-0.34c-0.22-0.14-0.43-0.29-0.62-0.44c-0.42-0.34-0.75-0.73-1-1.19c-0.24-0.46-0.39-0.94-0.44-1.44
			s-0.01-1,0.13-1.51c0.14-0.51,0.38-0.98,0.72-1.41c0.34-0.43,0.74-0.77,1.2-1.02c0.46-0.26,0.94-0.42,1.44-0.48
			c0.5-0.07,1-0.03,1.5,0.1c0.5,0.13,0.97,0.36,1.39,0.69c0.19,0.15,0.38,0.33,0.58,0.54c0.2,0.21,0.37,0.44,0.52,0.7
			c0.15,0.26,0.27,0.55,0.35,0.87c0.08,0.32,0.1,0.67,0.06,1.04l-1.53,0.15c0.03-0.25,0.02-0.48-0.04-0.7
			c-0.06-0.21-0.14-0.41-0.24-0.58s-0.21-0.32-0.34-0.46c-0.13-0.13-0.24-0.24-0.34-0.32c-0.25-0.2-0.53-0.34-0.83-0.42
			c-0.3-0.08-0.6-0.09-0.9-0.05c-0.3,0.04-0.59,0.13-0.87,0.29c-0.28,0.15-0.52,0.35-0.72,0.61c-0.2,0.25-0.34,0.54-0.43,0.84
			s-0.11,0.61-0.08,0.91c0.03,0.3,0.12,0.59,0.26,0.87c0.14,0.28,0.34,0.51,0.6,0.72c0.1,0.08,0.22,0.17,0.36,0.26
			c0.13,0.09,0.29,0.17,0.47,0.23c0.18,0.06,0.38,0.09,0.61,0.1c0.23,0.01,0.49-0.03,0.78-0.12L902.02,760.92z"/>
                    <polygon fill="#016760" points="906.42,766.24 901.3,762.19 908.18,759.57 905.48,757.44 906.39,756.29 911.23,760.12 
			904.32,762.74 907.32,765.11 		"/>
                    <polygon fill="#016760" points="911.79,770.49 910.92,769.8 911.26,764.49 908.55,767.92 907.24,766.88 912.06,760.78 
			912.91,761.45 912.54,766.85 915.31,763.35 916.62,764.38 		"/>
                    <path fill="#016760" d="M918.13,775.5l-1.27-1l0.44-1.56l-2.17-1.71l-1.41,0.79l-1.29-1.02l7.38-4.08l0.58,0.46L918.13,775.5z
			 M917.68,771.62l0.61-2.16l-1.99,1.07L917.68,771.62z"/>
                    <path fill="#016760" d="M811.96,804.59c-0.31,0.17-0.62,0.27-0.94,0.29c-0.32,0.02-0.63-0.02-0.93-0.11
			c-0.3-0.1-0.57-0.25-0.82-0.46c-0.25-0.21-0.46-0.46-0.62-0.76c-0.15-0.27-0.25-0.55-0.29-0.82c-0.04-0.28-0.04-0.55,0.02-0.82
			c0.05-0.27,0.15-0.52,0.3-0.77c0.14-0.25,0.32-0.48,0.54-0.68l1.24,0.91c-0.2,0.19-0.35,0.41-0.45,0.66
			c-0.09,0.25-0.07,0.51,0.08,0.77c0.05,0.09,0.12,0.18,0.21,0.26c0.09,0.08,0.19,0.15,0.29,0.2c0.1,0.05,0.21,0.08,0.33,0.09
			c0.12,0.01,0.22-0.02,0.33-0.07c0.15-0.08,0.23-0.21,0.26-0.4c0.03-0.19,0.03-0.4,0.02-0.64c-0.01-0.24-0.04-0.51-0.06-0.8
			c-0.02-0.29-0.01-0.57,0.04-0.85c0.05-0.28,0.16-0.55,0.32-0.81c0.16-0.26,0.42-0.48,0.77-0.68c0.27-0.15,0.56-0.25,0.87-0.29
			c0.31-0.04,0.61-0.03,0.9,0.04c0.29,0.07,0.56,0.19,0.81,0.37c0.25,0.18,0.45,0.41,0.61,0.7c0.26,0.47,0.35,0.95,0.28,1.43
			c-0.08,0.49-0.29,0.93-0.64,1.32l-1.27-0.92c0.14-0.14,0.23-0.3,0.29-0.49c0.06-0.19,0.04-0.37-0.06-0.54
			c-0.11-0.19-0.26-0.32-0.47-0.38c-0.2-0.06-0.4-0.03-0.59,0.07c-0.15,0.08-0.23,0.21-0.25,0.39c-0.02,0.18-0.02,0.39,0,0.64
			s0.04,0.51,0.06,0.79c0.02,0.29,0.01,0.57-0.04,0.85c-0.05,0.28-0.16,0.55-0.33,0.81C812.57,804.16,812.31,804.39,811.96,804.59"
                    />
                    <polygon fill="#016760" points="818.56,808.2 817.48,806.23 811.93,809.29 811.18,807.93 816.73,804.87 815.65,802.91 
			816.91,802.21 819.83,807.5 		"/>
                    <path fill="#016760" d="M816.14,816.93l-0.82-1.48l1.96-2.67l-0.5-0.91l-2.63,1.45l-0.74-1.33l6.81-3.76l1.41,2.56
			c0.14,0.26,0.23,0.54,0.25,0.83c0.02,0.3-0.02,0.58-0.11,0.86c-0.09,0.28-0.24,0.54-0.44,0.78c-0.2,0.24-0.44,0.44-0.73,0.6
			c-0.36,0.2-0.74,0.3-1.14,0.31c-0.4,0.01-0.77-0.08-1.12-0.26L816.14,816.93z M819.88,812.56c0.09-0.05,0.17-0.12,0.25-0.2
			c0.07-0.08,0.13-0.17,0.17-0.27c0.04-0.09,0.07-0.19,0.07-0.29c0-0.1-0.02-0.19-0.06-0.27l-0.68-1.23l-1.5,0.83l0.68,1.23
			c0.04,0.08,0.11,0.14,0.2,0.19c0.09,0.05,0.18,0.08,0.28,0.1c0.1,0.02,0.2,0.02,0.31,0C819.7,812.64,819.8,812.61,819.88,812.56"
                    />
                    <path fill="#016760" d="M821.62,821.62c-0.29,0.16-0.61,0.24-0.97,0.25c-0.36,0.01-0.71-0.05-1.06-0.18
			c-0.35-0.13-0.68-0.32-1-0.58c-0.32-0.26-0.58-0.57-0.78-0.94c-0.2-0.36-0.32-0.75-0.37-1.15c-0.05-0.4-0.04-0.79,0.04-1.16
			c0.08-0.37,0.21-0.7,0.41-1c0.2-0.3,0.44-0.53,0.73-0.69l4.6-2.54l0.77,1.39l-4.6,2.54c-0.11,0.06-0.21,0.15-0.29,0.28
			c-0.08,0.12-0.14,0.27-0.17,0.44c-0.03,0.17-0.03,0.35,0,0.55c0.03,0.2,0.1,0.4,0.22,0.61c0.12,0.21,0.25,0.38,0.4,0.52
			c0.15,0.13,0.31,0.23,0.46,0.29c0.16,0.06,0.31,0.09,0.46,0.08s0.28-0.03,0.39-0.09l4.6-2.53l0.76,1.39L821.62,821.62z"/>
                    <path fill="#016760" d="M828.97,826.59l-1.54-0.36c0.17-0.37,0.23-0.72,0.19-1.06c-0.04-0.34-0.16-0.68-0.35-1.02
			c-0.15-0.28-0.35-0.51-0.6-0.7c-0.25-0.19-0.52-0.32-0.81-0.4c-0.29-0.08-0.59-0.1-0.9-0.07c-0.31,0.03-0.6,0.13-0.88,0.28
			c-0.28,0.15-0.52,0.35-0.71,0.6s-0.34,0.51-0.43,0.8c-0.09,0.29-0.12,0.59-0.1,0.9c0.02,0.31,0.12,0.61,0.27,0.89
			c0.14,0.25,0.31,0.49,0.52,0.71c0.21,0.23,0.44,0.4,0.69,0.53l1.12-0.62l-0.93-1.68l1.14-0.63l1.69,3.06l-3.98,2.19l-0.69-1.25
			l0.33-0.28c-0.32-0.15-0.59-0.35-0.82-0.61c-0.23-0.26-0.42-0.54-0.58-0.83c-0.26-0.47-0.4-0.96-0.43-1.46
			c-0.03-0.5,0.04-0.99,0.21-1.46c0.17-0.47,0.41-0.9,0.75-1.29c0.33-0.39,0.73-0.71,1.18-0.96c0.47-0.26,0.97-0.42,1.49-0.47
			c0.52-0.05,1.03-0.02,1.52,0.12c0.49,0.13,0.94,0.36,1.36,0.67c0.42,0.31,0.76,0.71,1.02,1.18c0.28,0.51,0.45,1.04,0.5,1.58
			C829.24,825.48,829.17,826.03,828.97,826.59"/>
                    <path fill="#016760" d="M827.16,836.91l-0.78-1.42l1.01-1.27l-1.33-2.42l-1.6,0.18l-0.79-1.43l8.39-0.9l0.36,0.65L827.16,836.91z
			 M828.24,833.16l1.4-1.75l-2.25,0.22L828.24,833.16z"/>

                    <rect x="1081.4" y="477.51" transform="matrix(0.119 0.9929 -0.9929 0.119 1449.7654 -676.3452)" fill="#686868" width="49.24" height="2.59"/>

                    <rect x="1090.9" y="476.57" transform="matrix(0.1167 0.9932 -0.9932 0.1167 1460.0222 -685.8492)" fill="#686868" width="49.37" height="2.59"/>

                    <rect x="1120.42" y="494.23" transform="matrix(0.1095 0.994 -0.994 0.1095 1495.7053 -678.3885)" fill="#686868" width="12.06" height="2.59"/>
                    <path fill="#686868" d="M1098.84,485.27c-0.8,0-1.57-0.27-2.2-0.77c-0.75-0.6-1.22-1.45-1.32-2.4l-1.46-13.83l-20.41,2.38
			c-0.61,0.07-1.16,0.4-1.51,0.89l-3.43,4.8l-2.11-1.51l3.43-4.8c0.78-1.09,1.99-1.81,3.32-1.96l48.31-5.64
			c0.89-0.1,1.77,0.15,2.46,0.71c0.7,0.56,1.13,1.36,1.22,2.25l1.36,13.57c0.16,1.62-1.02,3.1-2.64,3.3l-24.59,2.98
			C1099.13,485.26,1098.98,485.27,1098.84,485.27 M1096.44,467.96l1.46,13.86c0.03,0.26,0.15,0.48,0.35,0.64
			c0.2,0.16,0.45,0.23,0.71,0.2l24.59-2.98c0.23-0.03,0.39-0.23,0.37-0.46l-1.36-13.57c-0.02-0.19-0.11-0.37-0.27-0.49
			c-0.15-0.12-0.34-0.18-0.54-0.16L1096.44,467.96z"/>
                    <path fill="#686868" d="M1079.31,482.69l-2.09-1.7l2.89-3.56c0.64-0.86,1.67-1.43,2.79-1.54l6.63-0.62l0.25,2.68l-6.63,0.62
			c-0.36,0.03-0.7,0.22-0.92,0.51L1079.31,482.69z"/>
                    <path fill="#016760" d="M1139.99,246.46c0.06-0.02,0.12-0.03,0.18-0.03c0.21,0,0.4,0.13,0.48,0.33c0,0,0.06,0.16,0.2,0.48
			c0.14,0.32,0.37,0.78,0.69,1.37c0.64,1.16,3.18,4.6,3.18,4.6s8,7.92,15.91,11l-0.93-1.23c-2.62-3.64-1.77-6.48-4.26-13.49
			c-3.78-10.63-17.83-7.54-18.86-8.32c0,0-6.03,18.17,9.73,21.07c4.75,0.87,8.05,1.14,10.04,1.2
			c-13.06-6.51-16.65-16.25-16.67-16.32C1139.59,246.84,1139.73,246.55,1139.99,246.46 M1112.45,249.48
			c-2.49,7-1.64,9.84-4.26,13.49l-0.93,1.23c7.91-3.08,12.89-7.42,15.91-11c1.53-1.81,2.55-3.43,3.19-4.6
			c0.32-0.58,0.54-1.05,0.69-1.37c0.14-0.32,0.2-0.48,0.2-0.48c0.08-0.21,0.27-0.33,0.48-0.33c0.06,0,0.12,0.01,0.18,0.03
			c0.26,0.1,0.4,0.39,0.3,0.65c-0.02,0.07-3.6,9.81-16.67,16.32c2-0.06,5.3-0.32,10.04-1.2c15.77-2.89,9.74-21.07,9.74-21.07
			C1130.28,241.93,1116.23,238.85,1112.45,249.48 M1155.5,229.65c2.49-7,1.64-9.84,4.26-13.48l0.93-1.24
			c-7.91,3.08-12.89,7.42-15.91,11c-1.53,1.81-2.55,3.43-3.19,4.6c-0.32,0.58-0.54,1.05-0.69,1.36c-0.14,0.32-0.2,0.48-0.2,0.48
			c-0.08,0.21-0.27,0.33-0.48,0.33c-0.06,0-0.12-0.01-0.17-0.03c-0.27-0.1-0.4-0.39-0.3-0.65c0.02-0.07,3.6-9.81,16.67-16.32
			c-2,0.06-5.3,0.33-10.04,1.2c-15.77,2.89-9.73,21.06-9.73,21.06C1137.67,237.2,1151.72,240.28,1155.5,229.65 M1121.64,216.91
			c-4.74-0.88-8.05-1.14-10.04-1.2c13.06,6.51,16.65,16.25,16.67,16.32c0.1,0.26-0.04,0.56-0.31,0.65
			c-0.06,0.02-0.12,0.03-0.17,0.03c-0.21,0-0.4-0.13-0.48-0.33c0,0-0.06-0.16-0.2-0.48c-0.14-0.32-0.37-0.79-0.68-1.36
			c-0.64-1.16-1.66-2.78-3.19-4.6c-3.01-3.58-8-7.92-15.91-11l0.93,1.23c2.62,3.64,1.77,6.48,4.26,13.48
			c3.78,10.63,17.83,7.54,18.86,8.32C1131.37,237.97,1137.41,219.8,1121.64,216.91"/>
                    <path fill="#016760" d="M1217.47,254.7c0.25-0.18,0.67-0.48,1.25-0.88c0.58-0.4,1.19-0.83,1.84-1.3c0.65-0.47,1.28-0.93,1.9-1.4
			c0.62-0.47,1.11-0.86,1.47-1.19c-0.08-0.17-0.29-0.37-0.61-0.62c-0.32-0.25-0.71-0.37-1.17-0.37c-0.49,0-0.89,0.07-1.2,0.22
			c-0.3,0.15-0.65,0.35-1.02,0.63c-0.48,0.34-0.98,0.71-1.5,1.1c-0.53,0.39-1.04,0.76-1.53,1.12c-0.49,0.36-0.94,0.69-1.34,0.98
			c-0.4,0.3-0.71,0.52-0.93,0.67v-10.42c-0.08-0.03-0.25-0.09-0.51-0.17c-0.25-0.08-0.54-0.12-0.84-0.12
			c-0.49,0-0.88,0.11-1.15,0.33c-0.27,0.22-0.41,0.56-0.41,1.02v17.44h2.91v-5.76c0.53,0.39,1.08,0.82,1.65,1.29
			c0.57,0.47,1.13,0.95,1.68,1.45c0.55,0.5,1.07,1.01,1.56,1.53c0.48,0.52,0.92,1.02,1.31,1.49h3.62c-0.82-1.12-1.82-2.31-3.01-3.56
			C1220.23,256.93,1218.92,255.77,1217.47,254.7 M1209.15,249.03c-0.67-0.3-1.54-0.45-2.61-0.45c-1.7,0-3.03,0.43-3.99,1.29
			c-0.96,0.86-1.44,2.07-1.44,3.63v8.23h2.91v-7.39c0-2.1,0.99-3.16,2.98-3.16c0.54,0,1.03,0.06,1.45,0.18
			c0.43,0.12,0.78,0.25,1.07,0.38c0.1-0.07,0.23-0.24,0.4-0.51c0.16-0.27,0.24-0.55,0.24-0.84
			C1210.16,249.78,1209.82,249.32,1209.15,249.03 M1195.25,259.74c-0.15,0.04-0.46,0.08-0.92,0.14c-0.46,0.06-1.08,0.09-1.86,0.09
			c-0.7,0-1.28-0.05-1.75-0.14c-0.47-0.09-0.84-0.23-1.11-0.41c-0.27-0.18-0.46-0.4-0.57-0.65c-0.11-0.25-0.17-0.53-0.17-0.84
			c0-0.75,0.26-1.29,0.79-1.65c0.53-0.35,1.37-0.52,2.52-0.52c0.93,0,1.63,0.03,2.1,0.1c0.47,0.07,0.79,0.12,0.96,0.15
			L1195.25,259.74L1195.25,259.74z M1196.24,249.8c-0.59-0.42-1.3-0.73-2.13-0.93c-0.82-0.19-1.74-0.29-2.74-0.29
			c-0.83,0-1.52,0.05-2.06,0.15c-0.54,0.1-0.98,0.24-1.3,0.42c-0.32,0.18-0.55,0.39-0.67,0.62c-0.13,0.23-0.19,0.46-0.19,0.7
			c0,0.34,0.09,0.63,0.27,0.87c0.18,0.24,0.32,0.4,0.42,0.48c0.19-0.15,0.57-0.34,1.16-0.56c0.59-0.22,1.37-0.33,2.33-0.33
			c0.58,0,1.11,0.04,1.58,0.13c0.47,0.09,0.88,0.24,1.22,0.46c0.34,0.22,0.61,0.53,0.8,0.92c0.2,0.39,0.29,0.88,0.29,1.48
			c-0.29-0.03-0.72-0.07-1.3-0.11c-0.58-0.04-1.19-0.06-1.83-0.06c-1.12,0-2.08,0.09-2.86,0.27c-0.78,0.18-1.42,0.45-1.9,0.8
			c-0.48,0.35-0.83,0.8-1.05,1.33c-0.21,0.53-0.32,1.14-0.32,1.83c0,0.53,0.09,1.04,0.27,1.54c0.18,0.5,0.51,0.94,0.99,1.33
			c0.48,0.38,1.15,0.69,1.99,0.92c0.84,0.23,1.92,0.34,3.25,0.34c0.67,0,1.3-0.02,1.91-0.05c0.61-0.04,1.16-0.07,1.66-0.12
			c0.5-0.04,0.92-0.08,1.29-0.13c0.37-0.04,0.63-0.08,0.8-0.11v-7.67c0-0.99-0.16-1.83-0.5-2.52
			C1197.3,250.79,1196.83,250.23,1196.24,249.8 M1181.41,251.17c-0.15,0.4-0.41,0.75-0.78,1.06c-0.37,0.3-0.86,0.54-1.49,0.71
			c-0.63,0.17-1.41,0.25-2.35,0.25c-0.85,0-1.55-0.04-2.09-0.11c-0.54-0.08-0.88-0.12-1-0.14v-6.22c0.14-0.03,0.45-0.07,0.94-0.1
			c0.49-0.03,1.15-0.05,1.96-0.05c1.02,0,1.86,0.09,2.51,0.28c0.65,0.19,1.17,0.43,1.54,0.74c0.37,0.31,0.63,0.66,0.76,1.06
			c0.13,0.4,0.2,0.81,0.2,1.23C1181.63,250.34,1181.56,250.77,1181.41,251.17 M1182.76,245.6c-1.33-1.07-3.35-1.6-6.07-1.6
			c-1.33,0-2.34,0.03-3.06,0.09c-0.71,0.06-1.21,0.11-1.48,0.16c-0.39,0.09-0.72,0.25-0.99,0.49c-0.27,0.24-0.41,0.63-0.41,1.17
			v15.83h2.96v-6.22c0.05,0,0.19,0.02,0.41,0.05c0.22,0.03,0.49,0.06,0.79,0.09c0.31,0.02,0.64,0.05,1.01,0.08
			c0.37,0.02,0.73,0.04,1.08,0.04c1.41,0,2.61-0.15,3.59-0.45c0.99-0.3,1.78-0.71,2.39-1.23c0.61-0.53,1.06-1.15,1.34-1.88
			c0.28-0.72,0.42-1.5,0.42-2.33C1184.75,248.1,1184.08,246.67,1182.76,245.6 M1253.71,235.06c-0.15,0.03-0.46,0.08-0.92,0.14
			c-0.46,0.06-1.08,0.09-1.86,0.09c-0.7,0-1.28-0.05-1.74-0.14c-0.47-0.09-0.84-0.23-1.11-0.41c-0.27-0.18-0.46-0.4-0.57-0.65
			c-0.11-0.25-0.17-0.53-0.17-0.84c0-0.75,0.26-1.29,0.79-1.64c0.53-0.35,1.37-0.52,2.52-0.52c0.94,0,1.64,0.03,2.1,0.1
			c0.47,0.07,0.78,0.12,0.96,0.15L1253.71,235.06L1253.71,235.06z M1256.09,226.8c-0.33-0.7-0.8-1.26-1.39-1.68
			c-0.59-0.43-1.31-0.74-2.13-0.93c-0.82-0.19-1.74-0.29-2.74-0.29c-0.83,0-1.52,0.05-2.06,0.15c-0.54,0.1-0.98,0.24-1.3,0.42
			c-0.32,0.18-0.55,0.38-0.68,0.61c-0.13,0.23-0.19,0.46-0.19,0.7c0,0.34,0.09,0.63,0.27,0.87c0.18,0.24,0.32,0.4,0.42,0.48
			c0.18-0.15,0.57-0.34,1.16-0.56c0.59-0.22,1.36-0.33,2.33-0.33c0.58,0,1.1,0.04,1.58,0.13s0.88,0.24,1.22,0.46
			c0.34,0.22,0.61,0.53,0.8,0.92c0.19,0.39,0.29,0.88,0.29,1.48c-0.29-0.03-0.72-0.07-1.3-0.11c-0.58-0.04-1.19-0.06-1.84-0.06
			c-1.12,0-2.07,0.09-2.85,0.27c-0.78,0.18-1.42,0.45-1.9,0.8c-0.48,0.36-0.83,0.8-1.04,1.33c-0.21,0.52-0.32,1.14-0.32,1.83
			c0,0.53,0.09,1.04,0.27,1.54c0.18,0.5,0.51,0.94,0.99,1.33c0.48,0.38,1.15,0.69,1.99,0.92c0.84,0.23,1.92,0.35,3.25,0.35
			c0.66,0,1.3-0.02,1.91-0.05c0.61-0.03,1.16-0.07,1.66-0.12c0.49-0.04,0.92-0.09,1.29-0.13c0.36-0.04,0.63-0.08,0.8-0.11v-7.67
			C1256.59,228.34,1256.42,227.5,1256.09,226.8 M1241.78,224.25c-0.51,0-0.89,0.12-1.13,0.37c-0.25,0.25-0.45,0.63-0.6,1.16
			c-0.39,1.39-0.82,2.79-1.29,4.18c-0.47,1.4-0.92,2.68-1.36,3.85c-0.21-0.58-0.43-1.24-0.68-1.99c-0.25-0.75-0.5-1.53-0.75-2.34
			c-0.25-0.82-0.51-1.64-0.76-2.49c-0.25-0.84-0.49-1.64-0.71-2.41c-0.07-0.03-0.24-0.1-0.51-0.19c-0.27-0.09-0.58-0.14-0.92-0.14
			c-0.54,0-0.94,0.11-1.19,0.34c-0.25,0.23-0.44,0.56-0.57,1c-0.19,0.66-0.39,1.36-0.61,2.1c-0.22,0.74-0.45,1.47-0.68,2.19
			c-0.23,0.73-0.45,1.43-0.66,2.1c-0.21,0.68-0.41,1.28-0.6,1.81c-0.21-0.6-0.44-1.27-0.7-2.03c-0.26-0.76-0.53-1.55-0.8-2.38
			c-0.27-0.83-0.54-1.67-0.79-2.51c-0.25-0.84-0.48-1.63-0.69-2.38c-0.07-0.03-0.21-0.08-0.42-0.15c-0.21-0.07-0.51-0.1-0.88-0.1
			c-0.5,0-0.86,0.13-1.09,0.41c-0.24,0.27-0.36,0.59-0.36,0.94c0,0.21,0.06,0.55,0.19,1.04c0.13,0.48,0.35,1.17,0.66,2.06
			c0.32,0.89,0.74,2.02,1.27,3.38c0.54,1.36,1.22,3.02,2.05,4.97h3.01c0.14-0.36,0.31-0.88,0.54-1.57c0.22-0.69,0.46-1.44,0.73-2.26
			c0.26-0.81,0.53-1.65,0.79-2.51c0.26-0.86,0.5-1.64,0.73-2.36c0.22,0.71,0.46,1.5,0.73,2.36c0.26,0.86,0.53,1.7,0.8,2.51
			c0.27,0.82,0.52,1.57,0.75,2.26c0.23,0.69,0.41,1.21,0.55,1.57h3.01c0.42-0.97,0.87-2.05,1.35-3.25c0.48-1.2,0.93-2.37,1.35-3.52
			c0.42-1.15,0.8-2.21,1.15-3.2c0.34-0.98,0.58-1.76,0.71-2.32c-0.03-0.04-0.2-0.13-0.48-0.28
			C1242.61,224.33,1242.24,224.25,1241.78,224.25 M1212.03,233.25c0-0.75,0.26-1.29,0.79-1.65c0.53-0.35,1.37-0.52,2.52-0.52
			c0.94,0,1.64,0.03,2.1,0.1c0.47,0.07,0.79,0.12,0.96,0.15v3.72c-0.15,0.03-0.46,0.08-0.92,0.14c-0.46,0.06-1.08,0.09-1.86,0.09
			c-0.7,0-1.28-0.05-1.75-0.14c-0.47-0.09-0.84-0.23-1.11-0.41c-0.27-0.18-0.46-0.4-0.57-0.65
			C1212.09,233.83,1212.03,233.55,1212.03,233.25 M1215.63,237.4c0.66,0,1.3-0.02,1.91-0.05c0.61-0.03,1.17-0.07,1.66-0.12
			c0.49-0.04,0.92-0.09,1.29-0.13c0.37-0.04,0.63-0.08,0.8-0.11v-7.67c0-0.98-0.17-1.83-0.5-2.52c-0.33-0.7-0.79-1.26-1.39-1.68
			c-0.6-0.43-1.31-0.74-2.13-0.93c-0.83-0.19-1.74-0.29-2.74-0.29c-0.83,0-1.52,0.05-2.06,0.15c-0.54,0.1-0.98,0.24-1.3,0.42
			c-0.32,0.18-0.55,0.38-0.68,0.61c-0.13,0.23-0.19,0.46-0.19,0.7c0,0.34,0.09,0.63,0.27,0.87c0.18,0.24,0.32,0.4,0.42,0.48
			c0.19-0.15,0.58-0.34,1.16-0.56c0.58-0.22,1.36-0.33,2.33-0.33c0.58,0,1.1,0.04,1.58,0.13c0.47,0.08,0.88,0.24,1.22,0.46
			c0.34,0.22,0.61,0.53,0.8,0.92c0.19,0.39,0.29,0.88,0.29,1.48c-0.29-0.03-0.72-0.07-1.3-0.11c-0.58-0.04-1.19-0.06-1.84-0.06
			c-1.12,0-2.07,0.09-2.85,0.27c-0.78,0.18-1.42,0.45-1.9,0.8c-0.48,0.36-0.83,0.8-1.05,1.33c-0.21,0.52-0.32,1.14-0.32,1.83
			c0,0.53,0.09,1.04,0.27,1.54c0.18,0.5,0.51,0.94,1,1.33c0.48,0.38,1.15,0.69,1.99,0.92C1213.22,237.29,1214.3,237.4,1215.63,237.4
			 M1202.32,229.65c0-2.1,0.99-3.16,2.98-3.16c0.54,0,1.03,0.06,1.45,0.18c0.42,0.12,0.78,0.25,1.07,0.38
			c0.1-0.07,0.23-0.24,0.4-0.51c0.16-0.27,0.24-0.55,0.24-0.84c0-0.61-0.33-1.07-1.01-1.36c-0.67-0.3-1.54-0.45-2.61-0.45
			c-1.7,0-3.03,0.43-3.99,1.29c-0.96,0.86-1.44,2.07-1.44,3.63v8.23h2.91V229.65z M1190.22,237.4c0.92,0,1.76-0.13,2.52-0.37
			c0.77-0.25,1.42-0.61,1.98-1.08c0.55-0.47,0.98-1.06,1.27-1.74c0.3-0.69,0.45-1.47,0.45-2.33v-7.34
			c-0.09-0.03-0.25-0.09-0.51-0.16c-0.25-0.08-0.55-0.11-0.87-0.11c-0.48,0-0.85,0.1-1.12,0.32c-0.27,0.21-0.41,0.56-0.41,1.03v5.64
			c0,1.15-0.27,2.04-0.82,2.64c-0.55,0.6-1.38,0.9-2.5,0.9s-1.95-0.3-2.5-0.9c-0.54-0.6-0.82-1.48-0.82-2.64v-6.71
			c-0.08-0.03-0.25-0.09-0.51-0.16c-0.26-0.08-0.54-0.11-0.84-0.11c-0.49,0-0.88,0.1-1.15,0.32c-0.27,0.21-0.41,0.56-0.41,1.03v6.27
			c0,0.87,0.15,1.65,0.46,2.33c0.3,0.69,0.73,1.27,1.27,1.74c0.54,0.48,1.2,0.84,1.96,1.08
			C1188.46,237.28,1189.3,237.4,1190.22,237.4 M1174.59,237.05h3.03v-14.71h6.42c0.07-0.08,0.15-0.26,0.24-0.52
			c0.09-0.27,0.14-0.56,0.14-0.88c0-0.92-0.5-1.38-1.5-1.38h-14.56c-0.05,0.08-0.13,0.26-0.23,0.52c-0.1,0.26-0.15,0.56-0.15,0.88
			c0,0.92,0.5,1.38,1.5,1.38h5.1L1174.59,237.05L1174.59,237.05z"/>
                    <path fill="#016760" d="M1107.32,271.17c0-0.4,0.24-0.64,0.6-0.64c0.35,0,0.58,0.24,0.58,0.64v4.3l3.85-4.69
			c0.12-0.15,0.29-0.25,0.49-0.25c0.28,0,0.53,0.21,0.53,0.52c0,0.15-0.07,0.28-0.16,0.39l-2.31,2.73l2.64,4.6
			c0.08,0.13,0.12,0.25,0.12,0.37c0,0.31-0.22,0.56-0.56,0.56c-0.24,0-0.43-0.12-0.56-0.34l-2.43-4.26l-1.62,1.96v2.01
			c0,0.4-0.24,0.64-0.58,0.64c-0.36,0-0.6-0.24-0.6-0.64L1107.32,271.17L1107.32,271.17z"/>
                    <path fill="#016760" d="M1114.72,278.89l2.78-7.69c0.15-0.42,0.37-0.66,0.77-0.66s0.65,0.24,0.8,0.66l2.73,7.69
			c0.03,0.08,0.05,0.17,0.05,0.27c0,0.33-0.25,0.54-0.56,0.54c-0.26,0-0.48-0.15-0.57-0.42l-0.61-1.74h-3.71l-0.61,1.74
			c-0.09,0.28-0.3,0.42-0.57,0.42c-0.31,0-0.56-0.21-0.56-0.54C1114.67,279.06,1114.69,278.97,1114.72,278.89 M1119.73,276.44
			l-1.44-4.1h-0.04l-1.46,4.1H1119.73z"/>
                    <path fill="#016760" d="M1123.52,279.63c-0.39,0-0.63-0.23-0.63-0.56c0-0.25,0.09-0.45,0.35-0.84l4.22-6.52v-0.01h-3.81
			c-0.36,0-0.58-0.24-0.58-0.54c0-0.32,0.23-0.56,0.58-0.56h4.65c0.39,0,0.62,0.24,0.62,0.57c0,0.23-0.07,0.4-0.32,0.8l-4.25,6.55
			v0.01h3.98c0.36,0,0.58,0.24,0.58,0.54c0,0.32-0.22,0.56-0.58,0.56H1123.52z M1125.16,268.73c0-0.4,0.32-0.73,0.73-0.73
			c0.4,0,0.73,0.33,0.73,0.73c0,0.41-0.33,0.73-0.73,0.73C1125.48,269.46,1125.16,269.14,1125.16,268.73"/>
                    <path fill="#016760" d="M1130.26,271.13c0-0.32,0.2-0.53,0.53-0.53h2.3c1.75,0,2.83,0.69,3.27,2.06c0.2,0.58,0.27,1.16,0.27,2.46
			c0,1.3-0.07,1.87-0.27,2.46c-0.44,1.37-1.51,2.06-3.27,2.06h-2.3c-0.33,0-0.53-0.21-0.53-0.53V271.13z M1131.52,278.53h1.49
			c1.2,0,1.88-0.37,2.2-1.35c0.15-0.46,0.21-0.98,0.21-2.06c0-1.07-0.07-1.59-0.21-2.06c-0.32-0.98-1.01-1.35-2.2-1.35h-1.49
			c-0.05,0-0.08,0.03-0.08,0.08v6.67C1131.44,278.5,1131.47,278.53,1131.52,278.53"/>
                    <path fill="#016760" d="M1141.01,275.96v3.09c0,0.4-0.24,0.64-0.58,0.64c-0.33,0-0.58-0.24-0.58-0.64v-3.09l-2.47-4.55
			c-0.05-0.11-0.09-0.24-0.09-0.34c0-0.29,0.24-0.53,0.56-0.53c0.24,0,0.4,0.12,0.52,0.35l2.07,3.85h0.04l2.04-3.85
			c0.12-0.23,0.28-0.35,0.52-0.35c0.32,0,0.55,0.24,0.55,0.53c0,0.11-0.04,0.24-0.09,0.34L1141.01,275.96z"/>
                    <path fill="#016760" d="M1149.63,271.13c0-0.32,0.2-0.53,0.53-0.53h2.29c1.75,0,2.83,0.69,3.27,2.06c0.2,0.58,0.27,1.16,0.27,2.46
			c0,1.3-0.07,1.87-0.27,2.46c-0.44,1.37-1.51,2.06-3.27,2.06h-2.29c-0.33,0-0.53-0.21-0.53-0.53V271.13z M1150.89,278.53h1.49
			c1.19,0,1.89-0.37,2.2-1.35c0.15-0.46,0.21-0.98,0.21-2.06c0-1.07-0.07-1.59-0.21-2.06c-0.32-0.98-1.01-1.35-2.2-1.35h-1.49
			c-0.05,0-0.08,0.03-0.08,0.08v6.67C1150.81,278.5,1150.83,278.53,1150.89,278.53"/>
                    <path fill="#016760" d="M1157.4,279.63c-0.39,0-0.62-0.23-0.62-0.56c0-0.25,0.09-0.45,0.34-0.84l4.22-6.52v-0.01h-3.81
			c-0.36,0-0.58-0.24-0.58-0.55c0-0.32,0.22-0.56,0.58-0.56h4.65c0.39,0,0.62,0.24,0.62,0.57c0,0.23-0.07,0.4-0.32,0.8l-4.25,6.55
			v0.01h3.98c0.36,0,0.58,0.24,0.58,0.54c0,0.32-0.22,0.56-0.58,0.56H1157.4z"/>
                    <path fill="#016760" d="M1164.15,271.17c0-0.4,0.24-0.64,0.6-0.64c0.34,0,0.58,0.24,0.58,0.64v7.89c0,0.4-0.24,0.64-0.58,0.64
			c-0.36,0-0.6-0.24-0.6-0.64V271.17z"/>
                    <path fill="#016760" d="M1167.15,271.13c0-0.32,0.2-0.53,0.53-0.53h4.65c0.36,0,0.58,0.24,0.58,0.54c0,0.32-0.23,0.56-0.58,0.56
			h-3.92c-0.05,0-0.08,0.03-0.08,0.08v2.66c0,0.05,0.03,0.08,0.08,0.08h3.27c0.36,0,0.58,0.24,0.58,0.55c0,0.32-0.23,0.56-0.58,0.56
			h-3.27c-0.05,0-0.08,0.03-0.08,0.08v2.75c0,0.05,0.03,0.08,0.08,0.08h3.92c0.36,0,0.58,0.24,0.58,0.54c0,0.32-0.23,0.56-0.58,0.56
			h-4.65c-0.33,0-0.53-0.21-0.53-0.53V271.13z"/>
                    <path fill="#016760" d="M1174.27,271.25c0-0.44,0.24-0.72,0.65-0.72c0.34,0,0.57,0.23,0.8,0.61l3.85,6.36h0.05v-6.37
			c0-0.37,0.23-0.6,0.54-0.6c0.33,0,0.56,0.23,0.56,0.6v7.85c0,0.44-0.24,0.72-0.65,0.72c-0.34,0-0.58-0.23-0.81-0.61l-3.85-6.33
			h-0.04v6.35c0,0.37-0.23,0.6-0.54,0.6c-0.33,0-0.56-0.23-0.56-0.6V271.25z M1178.03,267.81c0.09-0.11,0.2-0.17,0.36-0.17
			c0.25,0,0.45,0.19,0.45,0.44c0,0.13-0.05,0.25-0.13,0.34l-0.92,1.01c-0.12,0.13-0.24,0.2-0.4,0.2c-0.23,0-0.43-0.18-0.43-0.42
			c0-0.13,0.05-0.25,0.15-0.36L1178.03,267.81z"/>
                    <path fill="#016760" d="M1187.55,271.13c0-0.32,0.2-0.53,0.53-0.53h2.3c1.75,0,2.83,0.69,3.27,2.06c0.2,0.58,0.27,1.16,0.27,2.46
			c0,1.3-0.07,1.87-0.27,2.46c-0.44,1.37-1.51,2.06-3.27,2.06h-2.3c-0.33,0-0.53-0.21-0.53-0.53V271.13z M1188.81,278.53h1.49
			c1.2,0,1.89-0.37,2.2-1.35c0.15-0.46,0.21-0.98,0.21-2.06c0-1.07-0.07-1.59-0.21-2.06c-0.32-0.98-1.01-1.35-2.2-1.35h-1.49
			c-0.05,0-0.08,0.03-0.08,0.08v6.67C1188.73,278.5,1188.75,278.53,1188.81,278.53"/>
                    <path fill="#016760" d="M1195.47,277.64c-0.21-0.65-0.25-1.23-0.25-2.52c0-1.29,0.04-1.87,0.25-2.52c0.45-1.38,1.5-2.14,3.05-2.14
			c1.57,0,2.62,0.76,3.07,2.14c0.21,0.65,0.25,1.23,0.25,2.52s-0.04,1.87-0.25,2.52c-0.45,1.38-1.5,2.14-3.07,2.14
			C1196.97,279.78,1195.92,279.02,1195.47,277.64 M1200.43,277.28c0.15-0.44,0.21-0.97,0.21-2.16s-0.07-1.73-0.21-2.16
			c-0.27-0.84-0.86-1.39-1.91-1.39c-1.04,0-1.63,0.56-1.9,1.39c-0.15,0.44-0.21,0.97-0.21,2.16s0.07,1.73,0.21,2.16
			c0.27,0.84,0.86,1.39,1.9,1.39C1199.57,278.67,1200.17,278.12,1200.43,277.28"/>
                    <path fill="#016760" d="M1203.41,271.13c0-0.32,0.2-0.53,0.53-0.53h2.8c1.81,0,2.72,0.93,2.72,2.36c0,1.05-0.48,1.7-1.22,1.98
			v0.03c0.65,0.17,1.38,0.9,1.38,2.08c0,1.7-1.09,2.58-2.92,2.58h-2.76c-0.33,0-0.53-0.2-0.53-0.53L1203.41,271.13L1203.41,271.13z
			 M1206.71,274.5c1,0,1.58-0.57,1.58-1.41c0-0.86-0.58-1.42-1.58-1.42h-2.04c-0.05,0-0.08,0.03-0.08,0.08v2.67
			c0,0.05,0.03,0.08,0.08,0.08L1206.71,274.5L1206.71,274.5z M1204.67,278.55h2.11c1.08,0,1.66-0.58,1.66-1.49
			c0-0.89-0.58-1.49-1.66-1.49h-2.11c-0.05,0-0.08,0.03-0.08,0.08v2.82C1204.59,278.53,1204.61,278.55,1204.67,278.55"/>
                    <path fill="#016760" d="M1211.07,279.06v-7.93c0-0.32,0.2-0.53,0.53-0.53h2.83c1.75,0,2.86,1.02,2.86,2.6
			c0,1.21-0.64,2.08-1.71,2.43l1.62,3.19c0.05,0.11,0.08,0.2,0.08,0.32c0,0.29-0.21,0.56-0.57,0.56c-0.24,0-0.42-0.12-0.57-0.37
			l-1.78-3.52h-2.02c-0.05,0-0.08,0.03-0.08,0.08v3.17c0,0.4-0.24,0.64-0.58,0.64C1211.31,279.7,1211.07,279.46,1211.07,279.06
			 M1216.12,273.2c0-0.94-0.62-1.5-1.67-1.5h-2.11c-0.05,0-0.08,0.03-0.08,0.08v2.84c0,0.05,0.03,0.08,0.08,0.08h2.11
			C1215.5,274.7,1216.12,274.15,1216.12,273.2"/>
                    <path fill="#016760" d="M1221.87,275.96v3.09c0,0.4-0.24,0.64-0.58,0.64c-0.33,0-0.58-0.24-0.58-0.64v-3.09l-2.47-4.55
			c-0.05-0.11-0.09-0.24-0.09-0.34c0-0.29,0.24-0.53,0.56-0.53c0.24,0,0.4,0.12,0.52,0.35l2.07,3.85h0.04l2.04-3.85
			c0.12-0.23,0.28-0.35,0.52-0.35c0.32,0,0.54,0.24,0.54,0.53c0,0.11-0.04,0.24-0.09,0.34L1221.87,275.96z"/>
                    <path fill="#016760" d="M1179.84,305.71c-9.79,0-17.72,7.94-17.72,17.72s17.72,35.01,17.72,35.01s17.72-25.22,17.72-35.01
			S1189.63,305.71,1179.84,305.71 M1179.84,331.65c-4.54,0-8.21-3.68-8.21-8.21s3.68-8.21,8.21-8.21s8.21,3.68,8.21,8.21
			S1184.38,331.65,1179.84,331.65"/>
                    <path fill="#016760" d="M822.12,547.69c-0.23,0.24-0.49,0.42-0.78,0.54c-0.29,0.13-0.57,0.19-0.87,0.2
			c-0.29,0.01-0.57-0.04-0.85-0.14s-0.52-0.26-0.73-0.47l-1-0.99l-2.12,2.14l-1.08-1.08l5.48-5.52l2.08,2.07
			c0.21,0.21,0.37,0.45,0.46,0.73c0.1,0.28,0.14,0.56,0.13,0.86c-0.01,0.3-0.08,0.59-0.2,0.87
			C822.54,547.19,822.36,547.45,822.12,547.69 M821.05,546.62c0.07-0.07,0.13-0.15,0.17-0.25c0.05-0.1,0.08-0.2,0.1-0.3
			c0.02-0.1,0.02-0.2,0-0.29c-0.02-0.09-0.06-0.17-0.12-0.23l-1.02-1.02l-1.2,1.21l1.02,1.02c0.06,0.06,0.14,0.1,0.24,0.12
			c0.09,0.02,0.19,0.02,0.28,0c0.09-0.02,0.19-0.05,0.28-0.09C820.9,546.75,820.98,546.69,821.05,546.62"/>
                    <polygon fill="#016760" points="822.39,555.49 819.39,552.54 824.84,546.99 825.95,548.08 821.51,552.6 823.4,554.46 		"/>
                    <polygon fill="#016760" points="826.67,559.65 823.52,556.59 828.94,551.01 832.09,554.06 831.08,555.1 829.05,553.13 
			827.8,554.42 829.6,556.16 828.69,557.09 826.89,555.35 825.64,556.64 827.67,558.61 		"/>
                    <path fill="#016760" d="M833.42,562.98c-0.21,0.22-0.44,0.39-0.71,0.52c-0.26,0.12-0.54,0.19-0.82,0.2
			c-0.29,0.01-0.57-0.04-0.85-0.14c-0.29-0.11-0.55-0.28-0.8-0.52l-2.28-2.15l5.34-5.66l2.14,2.02c0.25,0.23,0.43,0.49,0.56,0.77
			c0.13,0.28,0.19,0.56,0.2,0.85c0.01,0.29-0.04,0.57-0.15,0.84s-0.26,0.52-0.47,0.74c-0.18,0.19-0.4,0.36-0.67,0.5
			c-0.27,0.14-0.53,0.23-0.81,0.24c0.02,0.33-0.03,0.65-0.16,0.97C833.81,562.48,833.64,562.75,833.42,562.98 M832.34,561.82
			c0.08-0.08,0.14-0.18,0.18-0.28c0.04-0.11,0.07-0.22,0.07-0.34c0-0.12-0.01-0.24-0.05-0.36s-0.1-0.22-0.19-0.3l-1.01-0.96
			l-1.29,1.37l1,0.94c0.09,0.08,0.2,0.15,0.31,0.18s0.24,0.05,0.35,0.04c0.12-0.01,0.23-0.04,0.34-0.09
			C832.16,561.97,832.26,561.9,832.34,561.82 M834.43,559.49c0.08-0.08,0.13-0.18,0.16-0.3c0.03-0.12,0.04-0.24,0.03-0.36
			c-0.01-0.12-0.05-0.24-0.1-0.36c-0.05-0.12-0.12-0.22-0.22-0.3l-0.87-0.82l-1.22,1.29l1.01,0.95c0.09,0.09,0.19,0.14,0.3,0.18
			c0.11,0.03,0.22,0.04,0.33,0.02c0.11-0.01,0.22-0.05,0.32-0.1C834.28,559.63,834.36,559.57,834.43,559.49"/>

                    <rect x="835.68" y="559.57" transform="matrix(0.7576 0.6528 -0.6528 0.7576 570.5811 -409.4038)" fill="#016760" width="1.56" height="7.78"/>
                    <path fill="#016760" d="M841.25,569.41c-0.22,0.27-0.48,0.48-0.76,0.62c-0.29,0.14-0.59,0.22-0.9,0.24
			c-0.31,0.02-0.63-0.02-0.94-0.12c-0.31-0.1-0.6-0.26-0.86-0.47c-0.24-0.2-0.43-0.42-0.58-0.66c-0.14-0.24-0.24-0.5-0.29-0.76
			c-0.05-0.27-0.06-0.54-0.02-0.83c0.04-0.28,0.12-0.56,0.25-0.84l1.48,0.38c-0.12,0.25-0.17,0.51-0.17,0.78
			c0.01,0.27,0.13,0.5,0.36,0.69c0.08,0.07,0.18,0.12,0.29,0.16c0.12,0.04,0.23,0.07,0.34,0.08c0.11,0.01,0.22-0.01,0.33-0.04
			c0.11-0.04,0.2-0.1,0.27-0.19c0.11-0.13,0.13-0.28,0.09-0.47c-0.04-0.18-0.12-0.38-0.23-0.6c-0.11-0.22-0.22-0.46-0.36-0.72
			c-0.13-0.26-0.22-0.52-0.28-0.8c-0.06-0.28-0.06-0.57-0.01-0.87c0.05-0.3,0.21-0.61,0.46-0.92c0.2-0.24,0.43-0.44,0.7-0.59
			c0.27-0.15,0.56-0.25,0.85-0.3c0.29-0.04,0.59-0.03,0.89,0.04c0.3,0.07,0.57,0.21,0.83,0.42c0.42,0.34,0.68,0.75,0.79,1.23
			c0.11,0.48,0.08,0.97-0.09,1.47l-1.52-0.38c0.08-0.18,0.1-0.37,0.09-0.56c-0.02-0.19-0.1-0.35-0.26-0.48
			c-0.17-0.14-0.36-0.2-0.58-0.17c-0.21,0.02-0.38,0.12-0.52,0.29c-0.11,0.13-0.13,0.28-0.08,0.46c0.05,0.18,0.13,0.37,0.23,0.59
			c0.11,0.22,0.23,0.45,0.36,0.71c0.13,0.26,0.22,0.53,0.28,0.81c0.06,0.28,0.06,0.57,0,0.88
			C841.66,568.79,841.51,569.1,841.25,569.41"/>
                    <path fill="#016760" d="M847.35,575.48c-0.44,0.1-0.83,0.15-1.17,0.14c-0.34-0.01-0.65-0.06-0.91-0.14
			c-0.27-0.08-0.51-0.2-0.73-0.35c-0.22-0.15-0.42-0.3-0.61-0.45c-0.42-0.34-0.74-0.74-0.98-1.21c-0.23-0.46-0.37-0.95-0.42-1.44
			c-0.04-0.5,0.01-1,0.15-1.51c0.15-0.51,0.39-0.97,0.74-1.4c0.34-0.42,0.75-0.76,1.22-1.01c0.47-0.25,0.95-0.4,1.45-0.46
			s1-0.02,1.5,0.12c0.5,0.14,0.96,0.37,1.38,0.71c0.19,0.15,0.38,0.33,0.57,0.55s0.36,0.45,0.51,0.71c0.15,0.26,0.26,0.55,0.34,0.88
			c0.08,0.32,0.09,0.67,0.05,1.04l-1.54,0.13c0.03-0.25,0.02-0.48-0.03-0.7c-0.06-0.22-0.13-0.41-0.23-0.58
			c-0.1-0.17-0.21-0.33-0.33-0.46c-0.12-0.13-0.24-0.24-0.34-0.32c-0.25-0.21-0.53-0.35-0.83-0.43c-0.3-0.08-0.6-0.1-0.9-0.07
			c-0.3,0.04-0.59,0.13-0.87,0.27s-0.52,0.35-0.73,0.6c-0.21,0.25-0.35,0.53-0.44,0.83s-0.12,0.61-0.09,0.91
			c0.02,0.3,0.11,0.59,0.25,0.87c0.14,0.28,0.33,0.52,0.59,0.73c0.1,0.08,0.22,0.17,0.35,0.27s0.29,0.17,0.46,0.24
			c0.18,0.06,0.38,0.1,0.61,0.11c0.23,0.01,0.49-0.02,0.78-0.11L847.35,575.48z"/>
                    <polygon fill="#016760" points="857.2,575.55 852.51,576.91 850.09,579.65 848.92,578.62 851.35,575.88 852.12,571.07 
			853.52,572.3 853.06,575.11 855.8,574.31 		"/>
                    <polygon fill="#016760" points="860.45,581.9 859.06,580.14 854.08,584.07 853.12,582.84 858.09,578.92 856.7,577.16 
			857.83,576.26 861.58,581 		"/>
                    <path fill="#016760" d="M862.57,591.22c-0.5,0.24-1.01,0.38-1.54,0.4c-0.53,0.02-1.03-0.05-1.52-0.22s-0.93-0.43-1.33-0.77
			c-0.4-0.35-0.72-0.77-0.96-1.26c-0.25-0.5-0.38-1.01-0.41-1.53c-0.03-0.53,0.04-1.03,0.21-1.52c0.16-0.49,0.42-0.94,0.76-1.34
			c0.34-0.4,0.76-0.73,1.26-0.97c0.49-0.24,1-0.38,1.53-0.4s1.04,0.05,1.52,0.22c0.49,0.17,0.93,0.42,1.33,0.77
			c0.4,0.34,0.73,0.76,0.97,1.26c0.24,0.49,0.37,1,0.4,1.53c0.03,0.53-0.04,1.04-0.2,1.53c-0.16,0.49-0.41,0.93-0.76,1.34
			C863.48,590.65,863.06,590.98,862.57,591.22 M861.88,589.82c0.3-0.15,0.55-0.34,0.76-0.59s0.36-0.52,0.46-0.82
			c0.1-0.3,0.14-0.61,0.12-0.93c-0.02-0.33-0.1-0.64-0.25-0.94c-0.15-0.3-0.35-0.56-0.59-0.77c-0.25-0.21-0.52-0.37-0.82-0.47
			c-0.3-0.1-0.61-0.15-0.93-0.13c-0.32,0.02-0.63,0.1-0.93,0.25s-0.55,0.34-0.76,0.59s-0.36,0.52-0.46,0.82
			c-0.1,0.3-0.14,0.61-0.12,0.93c0.02,0.33,0.1,0.64,0.25,0.94c0.15,0.3,0.34,0.55,0.59,0.77c0.25,0.21,0.52,0.37,0.82,0.47
			c0.3,0.1,0.61,0.14,0.93,0.13C861.27,590.05,861.58,589.97,861.88,589.82"/>
                    <polygon fill="#016760" points="868.83,601.97 860.47,599.67 860.32,598.88 862.23,597.43 859.92,596.8 859.77,596 866.7,590.78 
			867.03,592.51 862.46,595.96 863.73,596.3 867.25,593.65 867.56,595.28 865.53,596.8 867.98,597.48 868.28,599.1 864.04,597.93 
			862.97,598.72 868.5,600.25 		"/>
                    <path fill="#016760" d="M861.7,609.2l-0.21-1.6l1.4-0.81l-0.35-2.74l-1.56-0.42l-0.21-1.63l8.13,2.25l0.09,0.74L861.7,609.2z
			 M864.09,606.11l1.95-1.12l-2.17-0.62L864.09,606.11z"/>
                </g>
                <g id="regions_x5F_names" clipPath="url(#SVGID_2_)">
                    <rect x="681" y="574" fill="#FFFFFF" stroke="#262626" stroke-miterlimit="10" width="110" height="39"/>
                    <rect x="242" y="164" fill="#FFFFFF" stroke="#262626" stroke-miterlimit="10" width="88" height="39"/>
                    <path fill="#016760" d="M263.6,185.69c0,0.53-0.09,1.03-0.28,1.49c-0.19,0.47-0.45,0.88-0.8,1.23c-0.35,0.35-0.77,0.63-1.25,0.84
			c-0.49,0.2-1.03,0.31-1.63,0.31h-5.44v-13.5h5.09c0.59,0,1.13,0.1,1.62,0.31c0.49,0.21,0.91,0.48,1.26,0.84
			c0.35,0.35,0.62,0.77,0.81,1.23c0.19,0.47,0.29,0.97,0.29,1.49c0,0.46-0.08,0.93-0.24,1.43c-0.16,0.5-0.41,0.92-0.73,1.27
			c0.42,0.38,0.74,0.85,0.96,1.41C263.48,184.6,263.6,185.15,263.6,185.69 M260.72,180.09c0-0.19-0.05-0.38-0.15-0.57
			c-0.1-0.19-0.24-0.35-0.4-0.49c-0.16-0.14-0.35-0.25-0.55-0.33c-0.21-0.08-0.42-0.13-0.63-0.13h-2.08v3.08h2.4
			c0.22,0,0.41-0.04,0.59-0.14c0.18-0.09,0.32-0.21,0.44-0.36c0.12-0.15,0.21-0.32,0.28-0.51
			C260.68,180.45,260.72,180.27,260.72,180.09 M260.84,185.51c0-0.19-0.04-0.38-0.11-0.58c-0.07-0.19-0.18-0.37-0.32-0.52
			c-0.14-0.15-0.3-0.28-0.49-0.39c-0.19-0.1-0.39-0.15-0.61-0.15h-2.42v3.26h2.39c0.22,0,0.42-0.05,0.62-0.14
			c0.19-0.1,0.36-0.22,0.5-0.37c0.14-0.15,0.25-0.32,0.32-0.51C260.8,185.92,260.84,185.72,260.84,185.51"/>
                    <path fill="#016760" d="M275.37,189.56h-2.93l-2.41-5.22h-1.8v5.22h-2.65v-13.5h5.08c0.52,0,1.01,0.11,1.48,0.32
			c0.47,0.22,0.87,0.51,1.21,0.89c0.34,0.38,0.62,0.82,0.82,1.32c0.2,0.5,0.31,1.04,0.31,1.62c0,0.72-0.16,1.38-0.48,1.99
			c-0.32,0.61-0.76,1.1-1.34,1.48L275.37,189.56z M271.86,180.22c0-0.18-0.03-0.36-0.1-0.54c-0.07-0.18-0.15-0.34-0.26-0.49
			c-0.11-0.14-0.24-0.26-0.39-0.35s-0.3-0.13-0.46-0.13h-2.43v2.97h2.43c0.16,0,0.31-0.04,0.46-0.13c0.15-0.09,0.28-0.2,0.39-0.34
			c0.11-0.14,0.19-0.29,0.26-0.47C271.82,180.56,271.86,180.39,271.86,180.22"/>
                    <polygon fill="#016760" points="286.71,189.56 275.37,189.56 281.92,178.6 275.96,178.6 275.96,176.06 286.67,176.06 
			280.08,187.06 286.71,187.06 		"/>
                    <polygon fill="#016760" points="296.12,189.56 288.51,189.56 288.51,176.06 296.12,176.06 296.12,178.57 291.21,178.57 
			291.21,181.68 295.54,181.68 295.54,183.95 291.21,183.95 291.21,187.06 296.12,187.06 		"/>
                    <path fill="#016760" d="M309.3,178.15l-1.84,2.03c-0.42-0.56-0.9-0.95-1.45-1.17c-0.55-0.22-1.16-0.32-1.84-0.32
			c-0.55,0-1.07,0.11-1.56,0.33c-0.49,0.22-0.92,0.52-1.29,0.9c-0.37,0.38-0.66,0.82-0.87,1.31s-0.31,1.02-0.31,1.58
			s0.1,1.08,0.31,1.58s0.5,0.94,0.85,1.32c0.36,0.38,0.79,0.68,1.28,0.9c0.49,0.22,1.02,0.33,1.58,0.33c0.49,0,1-0.06,1.51-0.19
			c0.52-0.13,0.98-0.33,1.39-0.6v-2.21h-3.33v-2.27h6.07v7.88h-2.48l-0.15-0.74c-0.49,0.36-1.03,0.6-1.61,0.73
			c-0.58,0.13-1.17,0.19-1.75,0.19c-0.94,0-1.8-0.19-2.58-0.58c-0.79-0.38-1.47-0.9-2.03-1.54c-0.57-0.64-1.02-1.38-1.33-2.21
			c-0.32-0.83-0.48-1.7-0.48-2.6c0-0.94,0.18-1.82,0.53-2.66s0.84-1.58,1.45-2.21s1.33-1.13,2.15-1.5c0.82-0.37,1.71-0.56,2.66-0.56
			c1.02,0,1.96,0.19,2.82,0.57C307.84,176.83,308.61,177.4,309.3,178.15"/>
                    <path fill="#016760" d="M706.56,598.06c-0.48,0.62-0.95,1.12-1.42,1.48c-0.47,0.36-0.93,0.63-1.39,0.81
			c-0.46,0.18-0.91,0.29-1.36,0.33c-0.45,0.04-0.89,0.06-1.32,0.06c-0.94,0-1.82-0.18-2.64-0.55c-0.82-0.37-1.54-0.86-2.15-1.48
			c-0.61-0.62-1.09-1.36-1.45-2.2c-0.36-0.84-0.54-1.74-0.54-2.7c0-0.95,0.18-1.84,0.54-2.69c0.36-0.85,0.84-1.58,1.45-2.21
			c0.61-0.62,1.32-1.12,2.15-1.48c0.82-0.37,1.7-0.55,2.64-0.55c0.42,0,0.88,0.04,1.37,0.11c0.49,0.07,0.98,0.2,1.47,0.4
			c0.49,0.19,0.96,0.46,1.41,0.81c0.46,0.35,0.86,0.8,1.21,1.35l-1.93,1.85c-0.23-0.37-0.5-0.67-0.81-0.9
			c-0.31-0.23-0.63-0.41-0.96-0.53c-0.32-0.13-0.64-0.21-0.95-0.25c-0.31-0.04-0.58-0.06-0.81-0.06c-0.56,0-1.09,0.11-1.58,0.33
			c-0.49,0.22-0.92,0.52-1.29,0.9c-0.37,0.38-0.66,0.82-0.87,1.32c-0.22,0.5-0.33,1.04-0.33,1.6c0,0.56,0.11,1.1,0.33,1.6
			c0.21,0.5,0.51,0.95,0.87,1.32c0.37,0.38,0.8,0.68,1.29,0.9c0.49,0.22,1.02,0.33,1.58,0.33c0.23,0,0.48-0.01,0.76-0.03
			c0.28-0.02,0.58-0.08,0.88-0.19c0.31-0.11,0.62-0.28,0.94-0.51c0.32-0.23,0.63-0.57,0.93-1L706.56,598.06z"/>
                    <polygon fill="#016760" points="715.81,600.56 708.2,600.56 708.2,587.06 715.81,587.06 715.81,589.57 710.9,589.57 710.9,592.68 
			715.24,592.68 715.24,594.95 710.9,594.95 710.9,598.06 715.81,598.06 		"/>
                    <polygon fill="#016760" points="727.87,600.56 725.95,600.56 720.69,592.99 720.69,600.56 717.79,600.56 717.79,587.06 
			719.66,587.06 724.98,594.8 724.98,587.06 727.87,587.06 		"/>
                    <polygon fill="#016760" points="739.79,589.57 735.9,589.57 735.9,600.56 733.2,600.56 733.2,589.57 729.31,589.57 729.31,587.06 
			739.79,587.06 		"/>
                    <path fill="#016760" d="M751.04,600.56h-2.93l-2.41-5.22h-1.8v5.22h-2.65v-13.5h5.08c0.52,0,1.01,0.11,1.48,0.32
			c0.47,0.22,0.87,0.51,1.21,0.89c0.34,0.38,0.62,0.82,0.82,1.32c0.2,0.5,0.31,1.04,0.31,1.62c0,0.72-0.16,1.38-0.48,1.99
			c-0.32,0.61-0.76,1.1-1.34,1.48L751.04,600.56z M747.53,591.22c0-0.18-0.03-0.36-0.1-0.54s-0.15-0.34-0.26-0.49
			c-0.11-0.14-0.24-0.26-0.39-0.35s-0.3-0.13-0.46-0.13h-2.43v2.97h2.43c0.16,0,0.31-0.04,0.46-0.13c0.15-0.09,0.28-0.2,0.39-0.34
			c0.11-0.14,0.2-0.29,0.26-0.47C747.49,591.56,747.53,591.39,747.53,591.22"/>
                    <path fill="#016760" d="M762.74,596.17c0,0.58-0.14,1.14-0.43,1.68c-0.29,0.55-0.67,1.03-1.16,1.46s-1.06,0.77-1.72,1.04
			c-0.66,0.26-1.36,0.4-2.09,0.4c-0.72,0-1.41-0.13-2.06-0.4s-1.23-0.61-1.73-1.04c-0.5-0.43-0.89-0.91-1.18-1.46
			c-0.29-0.55-0.43-1.11-0.43-1.68v-9.11h2.75v9.11c0,0.22,0.06,0.44,0.18,0.67c0.12,0.23,0.29,0.43,0.52,0.62
			c0.23,0.19,0.5,0.34,0.83,0.46c0.32,0.12,0.7,0.18,1.12,0.18c0.42,0,0.79-0.06,1.13-0.18c0.33-0.12,0.61-0.27,0.83-0.46
			c0.22-0.19,0.39-0.39,0.51-0.62c0.12-0.23,0.18-0.45,0.18-0.67v-9.11h2.75V596.17z"/>
                    <polygon fill="#016760" points="777.16,600.56 774.28,600.56 774.28,592.82 770.93,597.74 767.6,592.82 767.6,600.56 
			764.72,600.56 764.72,587.06 766.59,587.06 770.93,593.44 775.28,587.06 777.16,587.06 		"/>
                </g>
                <g id="streets-yellow" clipPath="url(#SVGID_2_)">
                    <path fill="#F8AF40" d="M1097.27,313.44c-2.33-1.36-4.69-2.74-7.08-4.13c-24.57-14.31-52.43-30.54-70.34-45.8
			c-10.17-8.66-18.91-17.67-26.62-25.62c-10.28-10.6-19.16-19.75-28.51-25.36c-20.77-12.46-149.13-74.63-200.87-76.27
			c-12.52-0.4-26.15-2.15-40.59-4.01c-50.33-6.47-112.97-14.53-199.49,21.79c-27.58,11.58-65.28,27.13-106.17,43.79l-3.59-8.81
			c40.86-16.65,78.53-32.19,106.08-43.75c39.46-16.56,77.64-25.61,116.74-27.64c33.71-1.75,62.36,1.93,87.64,5.18
			c14.2,1.83,27.61,3.55,39.67,3.93c56.17,1.78,188.32,67.33,205.46,77.62c10.42,6.25,19.7,15.82,30.44,26.9
			c7.93,8.18,16.13,16.63,25.96,25c17.27,14.71,44.73,30.71,68.96,44.82c2.4,1.4,4.76,2.77,7.1,4.14L1097.27,313.44z"/>
                    <polygon fill="#F8AF40" points="1103.82,292.57 1120.3,321.38 1087.11,321.25 		"/>
                    <path fill="#F8AF40" d="M656.25,547.31l-4.1-8.58c17.81-8.51,32.9-15.21,39.4-17.49c17.89-6.26,55.99-2.33,71.52,7.37
			c1.93,1.21,4.45,2.46,7.65,3.79c10.18-10.92,25.43-35.72,32.92-47.89c1.64-2.66,2.97-4.83,3.9-6.29
			c2.23-3.51,10.71-16.16,21.45-32.17c19.09-28.47,45.24-67.47,58.96-88.88c17.18-26.8,24.96-34.52,33.96-43.47
			c2.51-2.5,5.11-5.08,8.08-8.23c13.97-14.84,23.57-27.29,49.61-67.62c2.86-4.43,5.33-8.21,7.56-11.57l7.93,5.25
			c-2.21,3.33-4.66,7.08-7.5,11.48c-26.45,40.95-36.26,53.67-50.67,68.98c-3.07,3.27-5.73,5.9-8.3,8.46
			c-8.96,8.9-16.04,15.94-32.66,41.86c-13.78,21.5-39.96,60.54-59.07,89.04c-10.7,15.96-19.15,28.56-21.32,31.98
			c-0.91,1.43-2.22,3.56-3.82,6.17c-8.6,13.98-24.61,40-35.53,50.92l-0.31,0.31l-3.66,2.51l-2.34-0.91
			c-5.12-1.98-8.89-3.78-11.88-5.64c-12.13-7.58-46.94-12.2-63.34-6.46C688.5,532.39,673.77,538.94,656.25,547.31"/>
                    <path fill="#F8AF40" d="M1313.31,784.96c-1.49-84.99-3.54-190.94-5.02-224.11c-23.21-2.88-47.96-5.3-51.87-4.62
			c-5.56,1.81-48.26,13.41-55.38,15.34c-7.68,6.03-29.39,22.71-36.47,24.48c-2.2,0.55-9.96,1.83-20.76,3.44l-5.27,0.78l-0.2-5.8
			l-13.31-175.1l9.48-0.72l12.97,170.67c8.49-1.3,13.48-2.17,14.77-2.49c3.66-0.91,20.32-12.82,33.66-23.33l0.76-0.6l0.94-0.25
			c17.6-4.77,51.93-14.15,55.93-15.48c5.41-1.8,39.84,2.21,59.89,4.75l3.93,0.5l0.21,3.96c1.5,27.86,3.67,139.59,5.23,228.42
			L1313.31,784.96z M1256.55,556.18C1256.54,556.18,1256.54,556.18,1256.55,556.18C1256.54,556.18,1256.54,556.18,1256.55,556.18"/>
                    <polygon fill="#F8AF40" points="1113.6,425.1 1127.97,395.19 1146.7,422.59 		"/>
                </g>
                <g id="cities_x5F_names" clipPath="url(#SVGID_2_)">
                    <rect x="631.76" y="271.22" fill="#FFFFFF" stroke="#262626" stroke-miterlimit="10" width="279.15" height="61.78"/>
                    <path fill="#016760" d="M699.62,302c0,2.99-0.57,5.8-1.71,8.41c-1.14,2.62-2.7,4.91-4.68,6.87s-4.29,3.5-6.93,4.63
			c-2.64,1.12-5.43,1.68-8.39,1.68c-2.99,0-5.8-0.56-8.41-1.68c-2.62-1.12-4.92-2.66-6.9-4.63c-1.98-1.96-3.54-4.25-4.68-6.87
			c-1.14-2.62-1.71-5.42-1.71-8.41c0-2.95,0.57-5.75,1.71-8.39c1.14-2.64,2.7-4.93,4.68-6.87c1.98-1.94,4.28-3.49,6.9-4.63
			c2.62-1.14,5.42-1.71,8.41-1.71c2.95,0,5.75,0.57,8.39,1.71c2.64,1.14,4.95,2.68,6.93,4.63c1.98,1.94,3.54,4.23,4.68,6.87
			C699.05,296.25,699.62,299.05,699.62,302 M691.21,302c0-1.79-0.36-3.5-1.07-5.1c-0.71-1.61-1.66-3.01-2.86-4.21
			c-1.2-1.2-2.61-2.14-4.23-2.83c-1.63-0.69-3.34-1.04-5.13-1.04c-1.83,0-3.55,0.35-5.16,1.04c-1.61,0.69-3.02,1.64-4.23,2.83
			c-1.22,1.2-2.17,2.6-2.86,4.21c-0.69,1.61-1.04,3.31-1.04,5.1c0,1.79,0.35,3.5,1.07,5.1c0.71,1.61,1.66,3.01,2.86,4.21
			c1.2,1.2,2.61,2.14,4.24,2.83c1.63,0.69,3.34,1.04,5.13,1.04c1.79,0,3.51-0.35,5.13-1.04c1.63-0.69,3.04-1.64,4.23-2.83
			c1.2-1.2,2.15-2.6,2.86-4.21C690.85,305.5,691.21,303.79,691.21,302"/>
                    <path fill="#016760" d="M744.72,293.98c0,1.79-0.31,3.48-0.93,5.05c-0.62,1.57-1.47,2.93-2.55,4.07
			c-1.08,1.14-2.35,2.04-3.79,2.69c-1.44,0.65-2.96,0.98-4.57,0.98h-7.63v16.27h-8.25v-42.07h15.87c1.61,0,3.13,0.35,4.57,1.04
			c1.44,0.69,2.7,1.63,3.79,2.8c1.08,1.18,1.94,2.55,2.55,4.12C744.41,290.5,744.72,292.18,744.72,293.98 M736.53,293.98
			c0-0.52-0.09-1.07-0.28-1.63c-0.19-0.56-0.44-1.06-0.76-1.51c-0.32-0.45-0.68-0.82-1.09-1.12c-0.41-0.3-0.86-0.45-1.35-0.45h-7.8
			v9.2h7.8c0.49,0,0.94-0.14,1.38-0.42c0.43-0.28,0.79-0.63,1.09-1.07c0.3-0.43,0.54-0.91,0.73-1.43
			C736.43,295.03,736.53,294.5,736.53,293.98"/>
                    <path fill="#016760" d="M802.15,302c0,2.99-0.57,5.8-1.71,8.41c-1.14,2.62-2.7,4.91-4.68,6.87c-1.98,1.96-4.29,3.5-6.93,4.63
			s-5.43,1.68-8.38,1.68c-2.99,0-5.8-0.56-8.41-1.68c-2.62-1.12-4.92-2.66-6.9-4.63c-1.98-1.96-3.54-4.25-4.68-6.87
			c-1.14-2.62-1.71-5.42-1.71-8.41c0-2.95,0.57-5.75,1.71-8.39c1.14-2.64,2.7-4.93,4.68-6.87c1.98-1.94,4.28-3.49,6.9-4.63
			c2.62-1.14,5.42-1.71,8.41-1.71c2.95,0,5.75,0.57,8.38,1.71c2.64,1.14,4.95,2.68,6.93,4.63c1.98,1.94,3.54,4.23,4.68,6.87
			C801.58,296.25,802.15,299.05,802.15,302 M793.74,302c0-1.79-0.36-3.5-1.07-5.1c-0.71-1.61-1.67-3.01-2.86-4.21
			c-1.2-1.2-2.61-2.14-4.24-2.83c-1.63-0.69-3.34-1.04-5.13-1.04c-1.83,0-3.55,0.35-5.16,1.04c-1.61,0.69-3.02,1.64-4.24,2.83
			c-1.21,1.2-2.17,2.6-2.86,4.21c-0.69,1.61-1.04,3.31-1.04,5.1c0,1.79,0.35,3.5,1.07,5.1c0.71,1.61,1.66,3.01,2.86,4.21
			c1.2,1.2,2.61,2.14,4.23,2.83c1.63,0.69,3.34,1.04,5.13,1.04s3.5-0.35,5.13-1.04s3.04-1.64,4.24-2.83c1.2-1.2,2.15-2.6,2.86-4.21
			C793.38,305.5,793.74,303.79,793.74,302"/>
                    <polygon fill="#016760" points="842.31,323.03 819.54,323.03 819.54,280.97 827.95,280.97 827.95,315.24 842.31,315.24 		"/>
                    <polygon fill="#016760" points="882.31,323.03 858.58,323.03 858.58,280.97 882.31,280.97 882.31,288.76 866.99,288.76 
			866.99,298.47 880.51,298.47 880.51,305.53 866.99,305.53 866.99,315.24 882.31,315.24 		"/>
                    <rect x="1261" y="780" fill="#FFFFFF" stroke="#262626" stroke-miterlimit="10" width="114" height="64"/>
                    <path fill="#016760" d="M1284.13,804.59c0,0.61-0.12,1.17-0.35,1.67c-0.23,0.5-0.55,0.94-0.95,1.31c-0.4,0.37-0.87,0.66-1.4,0.86
			c-0.53,0.2-1.09,0.31-1.67,0.31c-0.54,0-1.04-0.08-1.49-0.25s-0.86-0.4-1.22-0.71c-0.36-0.31-0.67-0.67-0.93-1.1
			c-0.26-0.43-0.45-0.89-0.58-1.4l2.41-1.12c0.12,0.47,0.33,0.88,0.63,1.23c0.3,0.35,0.71,0.53,1.24,0.53c0.18,0,0.37-0.03,0.58-0.1
			c0.2-0.07,0.39-0.16,0.55-0.27s0.3-0.25,0.41-0.42s0.16-0.35,0.16-0.56c0-0.29-0.13-0.53-0.39-0.73c-0.26-0.2-0.58-0.39-0.96-0.57
			c-0.38-0.18-0.8-0.37-1.26-0.58s-0.88-0.46-1.26-0.77c-0.38-0.31-0.71-0.7-0.96-1.16c-0.26-0.46-0.39-1.04-0.39-1.74
			c0-0.54,0.1-1.06,0.3-1.57c0.2-0.5,0.47-0.95,0.82-1.33c0.35-0.38,0.76-0.69,1.24-0.92s1-0.34,1.57-0.34
			c0.94,0,1.74,0.26,2.41,0.78c0.67,0.52,1.16,1.22,1.48,2.08l-2.47,1.15c-0.1-0.32-0.26-0.61-0.5-0.85s-0.53-0.36-0.87-0.36
			c-0.38,0-0.71,0.13-0.96,0.4c-0.26,0.26-0.39,0.58-0.39,0.95c0,0.29,0.13,0.53,0.39,0.71c0.26,0.19,0.58,0.37,0.96,0.54
			c0.38,0.17,0.8,0.36,1.26,0.57s0.88,0.46,1.26,0.78c0.39,0.32,0.71,0.71,0.96,1.18C1284,803.29,1284.13,803.88,1284.13,804.59"/>
                    <polygon fill="#016760" points="1295.15,797.57 1291.26,797.57 1291.26,808.56 1288.56,808.56 1288.56,797.57 1284.67,797.57 
			1284.67,795.06 1295.15,795.06 		"/>
                    <path fill="#016760" d="M1306.4,808.56h-2.93l-2.41-5.22h-1.8v5.22h-2.65v-13.5h5.08c0.52,0,1.01,0.11,1.48,0.32
			c0.47,0.22,0.87,0.51,1.22,0.89c0.34,0.38,0.61,0.82,0.82,1.32s0.31,1.04,0.31,1.62c0,0.72-0.16,1.38-0.48,1.99
			c-0.32,0.61-0.76,1.1-1.34,1.48L1306.4,808.56z M1302.89,799.22c0-0.18-0.03-0.36-0.1-0.54c-0.07-0.18-0.15-0.34-0.26-0.49
			c-0.11-0.14-0.24-0.26-0.39-0.35s-0.3-0.13-0.46-0.13h-2.43v2.97h2.43c0.16,0,0.31-0.04,0.46-0.13c0.15-0.09,0.28-0.2,0.39-0.34
			c0.11-0.14,0.2-0.29,0.26-0.47C1302.86,799.56,1302.89,799.39,1302.89,799.22"/>
                    <polygon fill="#016760" points="1317.74,808.56 1306.4,808.56 1312.95,797.6 1306.99,797.6 1306.99,795.06 1317.7,795.06 
			1311.11,806.06 1317.74,806.06 		"/>
                    <polygon fill="#016760" points="1327.15,808.56 1319.54,808.56 1319.54,795.06 1327.15,795.06 1327.15,797.57 1322.24,797.57 
			1322.24,800.68 1326.58,800.68 1326.58,802.95 1322.24,802.95 1322.24,806.06 1327.15,806.06 		"/>
                    <polygon fill="#016760" points="1336.44,808.56 1329.13,808.56 1329.13,795.06 1331.83,795.06 1331.83,806.06 1336.44,806.06 		
			"/>
                    <path fill="#016760" d="M1349.06,806.06c-0.48,0.62-0.95,1.12-1.42,1.48c-0.47,0.36-0.93,0.63-1.39,0.81
			c-0.46,0.18-0.91,0.29-1.36,0.33c-0.45,0.04-0.89,0.06-1.32,0.06c-0.94,0-1.82-0.18-2.64-0.55c-0.82-0.37-1.54-0.86-2.15-1.48
			s-1.09-1.36-1.45-2.2c-0.36-0.84-0.54-1.74-0.54-2.7c0-0.95,0.18-1.84,0.54-2.69c0.36-0.85,0.84-1.58,1.45-2.21
			c0.61-0.62,1.32-1.12,2.15-1.48c0.82-0.37,1.7-0.55,2.64-0.55c0.42,0,0.88,0.04,1.37,0.11c0.49,0.07,0.98,0.2,1.47,0.4
			c0.49,0.19,0.96,0.46,1.41,0.81c0.46,0.35,0.86,0.8,1.21,1.35l-1.93,1.85c-0.23-0.37-0.5-0.67-0.81-0.9
			c-0.31-0.23-0.63-0.41-0.96-0.53c-0.32-0.13-0.64-0.21-0.95-0.25s-0.58-0.06-0.81-0.06c-0.56,0-1.09,0.11-1.58,0.33
			c-0.49,0.22-0.92,0.52-1.29,0.9c-0.37,0.38-0.66,0.82-0.87,1.32c-0.22,0.5-0.33,1.04-0.33,1.6c0,0.56,0.11,1.1,0.33,1.6
			c0.22,0.5,0.51,0.95,0.87,1.32c0.37,0.38,0.79,0.68,1.29,0.9c0.49,0.22,1.02,0.33,1.58,0.33c0.23,0,0.48-0.01,0.76-0.03
			c0.28-0.02,0.58-0.08,0.88-0.19c0.31-0.11,0.62-0.28,0.94-0.51c0.32-0.23,0.63-0.57,0.93-1L1349.06,806.06z"/>
                    <polygon fill="#016760" points="1358.31,808.56 1350.69,808.56 1350.69,795.06 1358.31,795.06 1358.31,797.57 1353.39,797.57 
			1353.39,800.68 1357.73,800.68 1357.73,802.95 1353.39,802.95 1353.39,806.06 1358.31,806.06 		"/>
                    <path fill="#016760" d="M1288.42,823.41c0,0.96-0.18,1.86-0.55,2.7s-0.87,1.58-1.5,2.21c-0.63,0.63-1.38,1.13-2.22,1.48
			c-0.85,0.36-1.74,0.54-2.69,0.54c-0.96,0-1.86-0.18-2.7-0.54c-0.84-0.36-1.58-0.85-2.21-1.48c-0.64-0.63-1.14-1.37-1.5-2.21
			s-0.55-1.74-0.55-2.7c0-0.95,0.18-1.84,0.55-2.69c0.37-0.85,0.87-1.58,1.5-2.2c0.64-0.62,1.37-1.12,2.21-1.49
			c0.84-0.37,1.74-0.55,2.7-0.55c0.95,0,1.85,0.18,2.69,0.55c0.85,0.37,1.59,0.86,2.22,1.49c0.64,0.63,1.14,1.36,1.5,2.2
			C1288.23,821.57,1288.42,822.46,1288.42,823.41 M1285.72,823.41c0-0.58-0.11-1.12-0.34-1.64c-0.23-0.52-0.53-0.97-0.92-1.35
			c-0.38-0.38-0.84-0.69-1.36-0.91c-0.52-0.22-1.07-0.33-1.65-0.33c-0.59,0-1.14,0.11-1.65,0.33c-0.52,0.22-0.97,0.53-1.36,0.91
			c-0.39,0.38-0.7,0.83-0.92,1.35c-0.22,0.52-0.33,1.06-0.33,1.64s0.11,1.12,0.34,1.64c0.23,0.52,0.53,0.97,0.92,1.35
			s0.84,0.69,1.36,0.91c0.52,0.22,1.07,0.33,1.65,0.33c0.58,0,1.13-0.11,1.65-0.33c0.52-0.22,0.97-0.53,1.36-0.91
			c0.38-0.38,0.69-0.83,0.92-1.35C1285.6,824.54,1285.72,823.99,1285.72,823.41"/>
                    <path fill="#016760" d="M1299.29,820.84c0,0.58-0.1,1.12-0.3,1.62c-0.2,0.5-0.47,0.94-0.82,1.3c-0.35,0.37-0.75,0.65-1.21,0.86
			s-0.95,0.32-1.47,0.32h-2.45v5.22h-2.65v-13.5h5.09c0.52,0,1.01,0.11,1.47,0.33c0.46,0.22,0.87,0.52,1.21,0.9
			c0.35,0.38,0.62,0.82,0.82,1.32C1299.19,819.72,1299.29,820.26,1299.29,820.84 M1296.66,820.84c0-0.17-0.03-0.34-0.09-0.52
			c-0.06-0.18-0.14-0.34-0.24-0.49c-0.1-0.14-0.22-0.26-0.35-0.36c-0.13-0.1-0.28-0.14-0.43-0.14h-2.5v2.95h2.5
			c0.16,0,0.3-0.04,0.44-0.13s0.25-0.2,0.35-0.34c0.09-0.14,0.17-0.29,0.23-0.46C1296.63,821.17,1296.66,821.01,1296.66,820.84"/>
                    <path fill="#016760" d="M1314.12,823.41c0,0.96-0.18,1.86-0.55,2.7c-0.37,0.84-0.87,1.58-1.5,2.21c-0.64,0.63-1.38,1.13-2.22,1.48
			c-0.85,0.36-1.74,0.54-2.69,0.54c-0.96,0-1.86-0.18-2.7-0.54c-0.84-0.36-1.58-0.85-2.21-1.48c-0.64-0.63-1.14-1.37-1.5-2.21
			c-0.37-0.84-0.55-1.74-0.55-2.7c0-0.95,0.18-1.84,0.55-2.69c0.37-0.85,0.87-1.58,1.5-2.2c0.64-0.62,1.37-1.12,2.21-1.49
			c0.84-0.37,1.74-0.55,2.7-0.55c0.95,0,1.85,0.18,2.69,0.55s1.59,0.86,2.22,1.49c0.64,0.63,1.14,1.36,1.5,2.2
			C1313.94,821.57,1314.12,822.46,1314.12,823.41 M1311.42,823.41c0-0.58-0.11-1.12-0.34-1.64c-0.23-0.52-0.53-0.97-0.92-1.35
			c-0.39-0.38-0.84-0.69-1.36-0.91c-0.52-0.22-1.07-0.33-1.65-0.33c-0.59,0-1.14,0.11-1.66,0.33c-0.52,0.22-0.97,0.53-1.36,0.91
			c-0.39,0.38-0.7,0.83-0.92,1.35c-0.22,0.52-0.33,1.06-0.33,1.64s0.11,1.12,0.34,1.64c0.23,0.52,0.53,0.97,0.92,1.35
			s0.84,0.69,1.36,0.91c0.52,0.22,1.07,0.33,1.65,0.33c0.58,0,1.13-0.11,1.65-0.33c0.52-0.22,0.97-0.53,1.36-0.91
			c0.38-0.38,0.69-0.83,0.92-1.35C1311.31,824.54,1311.42,823.99,1311.42,823.41"/>
                    <polygon fill="#016760" points="1323.41,830.16 1316.1,830.16 1316.1,816.66 1318.8,816.66 1318.8,827.66 1323.41,827.66 		"/>
                    <path fill="#016760" d="M1332.93,826.19c0,0.61-0.12,1.17-0.35,1.67c-0.23,0.5-0.55,0.94-0.95,1.31c-0.4,0.37-0.87,0.66-1.4,0.86
			c-0.53,0.2-1.09,0.31-1.67,0.31c-0.54,0-1.04-0.08-1.49-0.25s-0.86-0.4-1.22-0.71c-0.36-0.31-0.67-0.67-0.93-1.1
			c-0.26-0.43-0.45-0.89-0.58-1.4l2.41-1.12c0.12,0.47,0.33,0.88,0.63,1.23c0.3,0.35,0.71,0.53,1.24,0.53c0.18,0,0.37-0.03,0.58-0.1
			c0.2-0.07,0.39-0.16,0.55-0.27c0.16-0.11,0.3-0.25,0.41-0.42s0.16-0.35,0.16-0.56c0-0.29-0.13-0.53-0.39-0.73
			c-0.26-0.2-0.58-0.39-0.96-0.57c-0.38-0.18-0.8-0.37-1.26-0.58s-0.88-0.46-1.26-0.77c-0.38-0.31-0.71-0.7-0.96-1.16
			c-0.26-0.46-0.39-1.04-0.39-1.74c0-0.54,0.1-1.06,0.3-1.57c0.2-0.5,0.47-0.95,0.82-1.33c0.35-0.38,0.76-0.69,1.24-0.92
			s1-0.34,1.57-0.34c0.94,0,1.74,0.26,2.41,0.78c0.67,0.52,1.16,1.22,1.48,2.08l-2.47,1.15c-0.1-0.32-0.26-0.61-0.5-0.85
			s-0.53-0.36-0.87-0.36c-0.38,0-0.71,0.13-0.96,0.4c-0.26,0.26-0.39,0.58-0.39,0.95c0,0.29,0.13,0.53,0.39,0.71
			c0.26,0.19,0.58,0.37,0.96,0.54c0.38,0.17,0.8,0.36,1.26,0.57s0.88,0.46,1.26,0.78c0.38,0.32,0.7,0.71,0.96,1.18
			C1332.8,824.89,1332.93,825.48,1332.93,826.19"/>
                    <polygon fill="#016760" points="1345.33,830.16 1341.86,830.16 1337.61,824.92 1337.61,830.16 1334.91,830.16 1334.91,816.66 
			1337.61,816.66 1337.61,821.9 1341.86,816.66 1345.33,816.66 1339.64,823.41 		"/>
                    <rect x="1346.68" y="816.66" fill="#016760" width="2.7" height="13.5"/>
                    <polygon fill="#016760" points="1359.52,830.16 1351.9,830.16 1351.9,816.66 1359.52,816.66 1359.52,819.17 1354.6,819.17 
			1354.6,822.28 1358.94,822.28 1358.94,824.55 1354.6,824.55 1354.6,827.66 1359.52,827.66 		"/>
                </g>
                <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M1149.04,153.61h-31.77c-1.49,0-2.7-1.21-2.7-2.7v-18.85
		c0-1.49,1.21-2.7,2.7-2.7h31.77c1.49,0,2.7,1.21,2.7,2.7v18.85C1151.75,152.4,1150.54,153.61,1149.04,153.61"/>
                <path clipPath="url(#SVGID_2_)" d="M1149.04,154.57h-31.77c-2.02,0-3.67-1.64-3.67-3.67v-18.85c0-2.02,1.64-3.67,3.67-3.67h31.77
		c2.02,0,3.67,1.64,3.67,3.67v18.85C1152.71,152.93,1151.07,154.57,1149.04,154.57 M1117.27,130.31c-0.96,0-1.74,0.78-1.74,1.74
		v18.85c0,0.96,0.78,1.74,1.74,1.74h31.77c0.96,0,1.74-0.78,1.74-1.74v-18.85c0-0.96-0.78-1.74-1.74-1.74H1117.27z"/>
                <path clipPath="url(#SVGID_2_)" d="M1132,145.43h-1.31v2.07h-2.04v-2.07h-5.32l6.24-9.6h1.12v7.47h1.31V145.43z M1128.66,143.29
		v-2.65l-1.73,2.65H1128.66z"/>
                <path clipPath="url(#SVGID_2_)" d="M1140.97,143.65c0,0.6-0.1,1.15-0.31,1.65c-0.21,0.5-0.49,0.92-0.84,1.28
		c-0.35,0.35-0.77,0.63-1.24,0.82s-0.98,0.29-1.52,0.29c-0.64,0-1.27-0.19-1.88-0.56c-0.61-0.38-1.2-1.01-1.78-1.91l1.65-1.26
		c0.39,0.6,0.75,1.02,1.07,1.24c0.32,0.22,0.64,0.33,0.97,0.33c0.24,0,0.47-0.05,0.69-0.14c0.22-0.09,0.42-0.22,0.58-0.39
		c0.17-0.17,0.3-0.36,0.4-0.6c0.1-0.23,0.15-0.49,0.15-0.77c0-0.27-0.05-0.52-0.15-0.75c-0.1-0.23-0.23-0.43-0.39-0.6
		c-0.16-0.17-0.35-0.31-0.58-0.4c-0.22-0.1-0.46-0.15-0.71-0.15c-0.14,0-0.29,0.02-0.47,0.05c-0.18,0.04-0.44,0.11-0.78,0.21
		l-1.06-0.92l1.21-5.24h4.76v2.16h-3.16l-0.47,2.04h0.03c0.64-0.09,1.2-0.06,1.68,0.1s0.87,0.41,1.18,0.75
		c0.31,0.34,0.54,0.75,0.7,1.23C1140.89,142.59,1140.97,143.1,1140.97,143.65"/>
                <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M359.74,311.81h-31.77c-1.49,0-2.7-1.21-2.7-2.7v-18.85
		c0-1.49,1.21-2.7,2.7-2.7h31.77c1.49,0,2.7,1.21,2.7,2.7v18.85C362.44,310.6,361.23,311.81,359.74,311.81"/>
                <path clipPath="url(#SVGID_2_)" d="M359.74,312.78h-31.77c-2.02,0-3.67-1.65-3.67-3.67v-18.85c0-2.02,1.65-3.67,3.67-3.67h31.77
		c2.02,0,3.67,1.64,3.67,3.67v18.85C363.41,311.13,361.76,312.78,359.74,312.78 M327.97,288.52c-0.96,0-1.74,0.78-1.74,1.74v18.85
		c0,0.96,0.78,1.74,1.74,1.74h31.77c0.96,0,1.74-0.78,1.74-1.74v-18.85c0-0.96-0.78-1.74-1.74-1.74H327.97z"/>
                <path clipPath="url(#SVGID_2_)" d="M342.7,303.63h-1.31v2.07h-2.04v-2.07h-5.32l6.24-9.6h1.12v7.47h1.31V303.63z M339.35,301.5
		v-2.65l-1.73,2.65H339.35z"/>
                <path clipPath="url(#SVGID_2_)" d="M351.66,301.86c0,0.6-0.1,1.15-0.31,1.65c-0.21,0.5-0.49,0.92-0.84,1.28
		c-0.35,0.35-0.77,0.63-1.24,0.82c-0.47,0.19-0.98,0.29-1.52,0.29c-0.64,0-1.27-0.19-1.88-0.56c-0.61-0.38-1.2-1.01-1.78-1.91
		l1.65-1.26c0.39,0.6,0.75,1.02,1.07,1.24c0.32,0.22,0.64,0.33,0.96,0.33c0.24,0,0.47-0.05,0.69-0.14c0.22-0.09,0.42-0.22,0.58-0.39
		c0.17-0.17,0.3-0.36,0.4-0.6c0.1-0.23,0.15-0.49,0.15-0.77c0-0.27-0.05-0.52-0.15-0.75c-0.1-0.23-0.23-0.43-0.39-0.6
		c-0.16-0.17-0.35-0.31-0.58-0.4c-0.22-0.1-0.46-0.15-0.71-0.15c-0.14,0-0.29,0.02-0.47,0.06c-0.18,0.04-0.44,0.11-0.78,0.21
		l-1.06-0.92l1.21-5.25h4.76v2.16h-3.16l-0.47,2.04h0.03c0.64-0.09,1.2-0.06,1.68,0.1s0.87,0.41,1.18,0.75
		c0.31,0.34,0.54,0.75,0.7,1.23C351.58,300.79,351.66,301.31,351.66,301.86"/>
                <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M1295.05,438.9h-31.57c-1.55,0-2.8-1.25-2.8-2.8v-18.65
		c0-1.55,1.25-2.8,2.8-2.8h31.57c1.55,0,2.8,1.25,2.8,2.8v18.65C1297.85,437.64,1296.59,438.9,1295.05,438.9"/>
                <path clipPath="url(#SVGID_2_)" d="M1295.05,439.86h-31.57c-2.08,0-3.77-1.69-3.77-3.77v-18.65c0-2.08,1.69-3.77,3.77-3.77h31.57
		c2.08,0,3.77,1.69,3.77,3.77v18.65C1298.81,438.17,1297.12,439.86,1295.05,439.86 M1263.48,415.6c-1.01,0-1.84,0.83-1.84,1.84
		v18.65c0,1.01,0.82,1.84,1.84,1.84h31.57c1.01,0,1.84-0.83,1.84-1.84v-18.65c0-1.01-0.82-1.84-1.84-1.84H1263.48z"/>
                <path clipPath="url(#SVGID_2_)" d="M1278.11,430.72h-1.31v2.07h-2.04v-2.07h-5.32l6.24-9.6h1.12v7.47h1.31V430.72z
		 M1274.76,428.58v-2.65l-1.73,2.65H1274.76z"/>
                <path clipPath="url(#SVGID_2_)" d="M1287.6,428.92c0,0.58-0.1,1.12-0.31,1.61c-0.21,0.49-0.49,0.92-0.85,1.29
		c-0.36,0.36-0.77,0.65-1.25,0.85c-0.47,0.2-0.97,0.3-1.49,0.3c-0.53,0-1.03-0.1-1.5-0.31c-0.47-0.21-0.88-0.49-1.24-0.85
		c-0.35-0.36-0.63-0.78-0.84-1.28c-0.21-0.49-0.31-1.03-0.31-1.61c0-0.51,0.09-0.99,0.28-1.45c0.19-0.46,0.41-0.88,0.67-1.28
		l3.35-5.09h2.47l-2.54,3.77c0.5,0.03,0.96,0.16,1.39,0.38c0.43,0.22,0.81,0.52,1.13,0.88c0.32,0.36,0.57,0.79,0.75,1.26
		S1287.6,428.39,1287.6,428.92 M1285.51,428.92c0-0.26-0.05-0.5-0.14-0.73c-0.09-0.23-0.22-0.43-0.38-0.6
		c-0.16-0.17-0.35-0.3-0.58-0.4c-0.22-0.1-0.46-0.15-0.71-0.15s-0.48,0.05-0.71,0.15c-0.22,0.1-0.42,0.23-0.58,0.4
		c-0.16,0.17-0.29,0.37-0.38,0.6c-0.09,0.23-0.14,0.47-0.14,0.73c0,0.26,0.05,0.5,0.14,0.74c0.09,0.23,0.22,0.43,0.38,0.61
		c0.16,0.17,0.35,0.3,0.58,0.4c0.22,0.1,0.46,0.15,0.71,0.15s0.49-0.05,0.71-0.15c0.22-0.1,0.42-0.23,0.58-0.4
		c0.16-0.17,0.29-0.37,0.38-0.61C1285.47,429.42,1285.51,429.18,1285.51,428.92"/>
                <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M260.22,272.91h-31.57c-1.55,0-2.8-1.25-2.8-2.8v-18.65
		c0-1.55,1.25-2.8,2.8-2.8h31.57c1.55,0,2.8,1.25,2.8,2.8v18.65C263.02,271.66,261.77,272.91,260.22,272.91"/>
                <path clipPath="url(#SVGID_2_)" d="M260.22,273.87h-31.57c-2.08,0-3.77-1.69-3.77-3.77v-18.65c0-2.08,1.69-3.77,3.77-3.77h31.57
		c2.08,0,3.77,1.69,3.77,3.77v18.65C263.99,272.18,262.3,273.87,260.22,273.87 M228.65,249.62c-1.01,0-1.84,0.83-1.84,1.84v18.65
		c0,1.01,0.82,1.84,1.84,1.84h31.57c1.02,0,1.84-0.83,1.84-1.84v-18.65c0-1.01-0.82-1.84-1.84-1.84H228.65z"/>
                <path clipPath="url(#SVGID_2_)" d="M243.28,264.73h-1.31v2.07h-2.04v-2.07h-5.32l6.24-9.6h1.12v7.47h1.31V264.73z M239.93,262.6
		v-2.65l-1.73,2.65H239.93z"/>
                <path clipPath="url(#SVGID_2_)" d="M252.77,262.93c0,0.58-0.1,1.12-0.31,1.61c-0.21,0.49-0.49,0.92-0.85,1.29
		c-0.36,0.36-0.77,0.65-1.24,0.85c-0.47,0.2-0.97,0.3-1.49,0.3c-0.53,0-1.03-0.1-1.5-0.31c-0.47-0.21-0.88-0.49-1.24-0.85
		c-0.35-0.36-0.63-0.78-0.84-1.28c-0.21-0.49-0.31-1.03-0.31-1.61c0-0.51,0.09-0.99,0.28-1.45c0.19-0.46,0.41-0.88,0.67-1.28
		l3.35-5.09h2.47l-2.54,3.77c0.5,0.03,0.96,0.16,1.39,0.38c0.43,0.22,0.81,0.52,1.13,0.88c0.32,0.36,0.57,0.79,0.75,1.26
		C252.68,261.9,252.77,262.4,252.77,262.93 M250.69,262.93c0-0.26-0.05-0.5-0.14-0.73c-0.09-0.23-0.22-0.43-0.38-0.6
		c-0.16-0.17-0.35-0.3-0.58-0.4c-0.22-0.1-0.46-0.15-0.71-0.15s-0.48,0.05-0.71,0.15c-0.22,0.1-0.42,0.23-0.57,0.4
		c-0.16,0.17-0.29,0.37-0.38,0.6c-0.09,0.23-0.14,0.47-0.14,0.73c0,0.26,0.05,0.5,0.14,0.74c0.09,0.23,0.22,0.44,0.38,0.61
		c0.16,0.17,0.35,0.3,0.57,0.4c0.22,0.1,0.46,0.15,0.71,0.15s0.49-0.05,0.71-0.15c0.22-0.1,0.42-0.23,0.58-0.4
		c0.16-0.17,0.29-0.37,0.38-0.61C250.64,263.44,250.69,263.19,250.69,262.93"/>
                <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M1334.27,747.53h-32.21c-1.37,0-2.48-1.11-2.48-2.48v-19.29
		c0-1.37,1.11-2.48,2.48-2.48h32.21c1.37,0,2.48,1.11,2.48,2.48v19.29C1336.75,746.42,1335.64,747.53,1334.27,747.53"/>
                <path clipPath="url(#SVGID_2_)" d="M1334.27,748.49h-32.21c-1.9,0-3.45-1.54-3.45-3.45v-19.29c0-1.9,1.55-3.45,3.45-3.45h32.21
		c1.9,0,3.45,1.55,3.45,3.45v19.29C1337.72,746.95,1336.17,748.49,1334.27,748.49 M1302.06,724.24c-0.84,0-1.52,0.68-1.52,1.52
		v19.29c0,0.84,0.68,1.52,1.52,1.52h32.21c0.84,0,1.52-0.68,1.52-1.52v-19.29c0-0.84-0.68-1.52-1.52-1.52H1302.06z"/>
                <path clipPath="url(#SVGID_2_)" d="M1317.06,733.79c0,0.51-0.09,0.99-0.28,1.45c-0.19,0.46-0.41,0.88-0.67,1.28l-3.35,5.09h-2.47
		l2.54-3.78c-0.5-0.03-0.96-0.16-1.39-0.38c-0.43-0.22-0.81-0.52-1.13-0.88c-0.32-0.36-0.57-0.78-0.75-1.26
		c-0.18-0.48-0.27-0.98-0.27-1.51c0-0.58,0.1-1.12,0.31-1.61s0.49-0.92,0.85-1.28c0.36-0.36,0.77-0.65,1.25-0.85s0.97-0.3,1.49-0.3
		c0.53,0,1.03,0.1,1.5,0.31c0.47,0.21,0.88,0.49,1.24,0.85c0.35,0.36,0.63,0.79,0.84,1.28
		C1316.95,732.69,1317.06,733.22,1317.06,733.79 M1314.97,733.79c0-0.26-0.05-0.5-0.14-0.73c-0.09-0.23-0.22-0.43-0.38-0.6
		c-0.16-0.17-0.35-0.3-0.58-0.4s-0.46-0.15-0.71-0.15s-0.48,0.05-0.71,0.15s-0.42,0.23-0.58,0.4c-0.16,0.17-0.29,0.37-0.38,0.6
		c-0.09,0.23-0.14,0.48-0.14,0.73c0,0.26,0.05,0.5,0.14,0.73s0.22,0.42,0.38,0.59c0.16,0.17,0.35,0.3,0.58,0.4s0.46,0.15,0.71,0.15
		s0.49-0.05,0.71-0.15s0.42-0.23,0.58-0.4c0.16-0.17,0.29-0.37,0.38-0.59S1314.97,734.05,1314.97,733.79"/>
                <path clipPath="url(#SVGID_2_)" d="M1326.66,739.35h-1.31v2.07h-2.04v-2.07h-5.32l6.24-9.6h1.12v7.47h1.31V739.35z
		 M1323.31,737.22v-2.65l-1.73,2.65H1323.31z"/>
                <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M725.65,141.5h-32.21c-1.37,0-2.48-1.11-2.48-2.48v-19.29
		c0-1.37,1.11-2.48,2.48-2.48h32.21c1.37,0,2.48,1.11,2.48,2.48v19.29C728.13,140.39,727.02,141.5,725.65,141.5"/>
                <path clipPath="url(#SVGID_2_)" d="M725.65,142.47h-32.21c-1.9,0-3.45-1.54-3.45-3.44v-19.29c0-1.9,1.55-3.45,3.45-3.45h32.21
		c1.9,0,3.45,1.55,3.45,3.45v19.29C729.1,140.92,727.55,142.47,725.65,142.47 M693.44,118.21c-0.84,0-1.52,0.68-1.52,1.52v19.29
		c0,0.84,0.68,1.52,1.52,1.52h32.21c0.84,0,1.52-0.68,1.52-1.52v-19.29c0-0.84-0.68-1.52-1.52-1.52H693.44z"/>
                <path clipPath="url(#SVGID_2_)" d="M708.44,127.77c0,0.51-0.09,0.99-0.28,1.45c-0.19,0.46-0.41,0.88-0.67,1.28l-3.35,5.09h-2.48
		l2.54-3.78c-0.5-0.03-0.96-0.16-1.39-0.38c-0.43-0.22-0.81-0.52-1.13-0.88c-0.32-0.36-0.57-0.78-0.75-1.26
		c-0.18-0.48-0.27-0.98-0.27-1.51c0-0.58,0.1-1.12,0.31-1.61c0.21-0.49,0.49-0.92,0.85-1.28c0.36-0.36,0.77-0.65,1.25-0.85
		c0.47-0.2,0.97-0.3,1.49-0.3c0.53,0,1.03,0.1,1.5,0.31c0.47,0.21,0.88,0.49,1.24,0.85c0.35,0.36,0.63,0.79,0.84,1.28
		S708.44,127.2,708.44,127.77 M706.35,127.77c0-0.26-0.05-0.5-0.14-0.73c-0.09-0.23-0.22-0.43-0.38-0.6
		c-0.16-0.17-0.35-0.3-0.58-0.4c-0.22-0.1-0.46-0.15-0.71-0.15s-0.48,0.05-0.71,0.15c-0.22,0.1-0.42,0.23-0.57,0.4
		c-0.16,0.17-0.29,0.37-0.38,0.6c-0.09,0.23-0.14,0.48-0.14,0.73c0,0.26,0.05,0.5,0.14,0.73c0.09,0.23,0.22,0.42,0.38,0.59
		c0.16,0.17,0.35,0.3,0.57,0.4c0.22,0.1,0.46,0.15,0.71,0.15s0.49-0.05,0.71-0.15c0.22-0.1,0.42-0.23,0.58-0.4
		c0.16-0.17,0.29-0.37,0.38-0.59C706.31,128.27,706.35,128.02,706.35,127.77"/>
                <path clipPath="url(#SVGID_2_)" d="M718.04,133.32h-1.31v2.07h-2.04v-2.07h-5.32l6.24-9.6h1.12v7.47h1.31V133.32z M714.69,131.19
		v-2.65l-1.73,2.65H714.69z"/>
            </g>
        </svg>
    );
};

export default Map;