import React from 'react';

const CounterCalories = ({mainColor}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" width="109.563px" height="84.208px"
             viewBox="839.065 644.887 109.563 84.208">
            <g id="Layer_2">
                <g id="Layer_2-2">
                    <path fill={mainColor} d="M914.844,672.577l-1.671-1.361l-0.781,2.019c-0.091,0.233-2.248,5.679-5.962,5.679c-0.398-0.001-0.796-0.062-1.178-0.178
                        c-1.01-0.276-1.841-0.994-2.261-1.953c-1.024-2.192-0.652-5.895,0.968-9.665c1.93-4.493,1.065-9.222-2.497-13.678
                        c-1.649-2.026-3.598-3.79-5.778-5.228l-2.458-1.533l0.057,2.896c0,0.087,0.079,8.784-6.432,12.01
                        c-3.457,1.711-5.928,4.532-6.959,7.943c-0.959,3.196-0.558,6.646,1.108,9.537c0.871,1.506,1.117,2.834,0.732,3.948
                        c-0.532,1.541-2.071,2.301-2.709,2.557c-0.617,0.254-1.277,0.388-1.944,0.393c-2.945,0-5.309-2.757-6.442-5.121l-1.235-2.656
                        l-1.521,2.5c-9.044,14.937-4.268,34.378,10.67,43.423c4.817,2.917,10.327,4.494,15.958,4.568h0.381
                        c17.426,0,31.603-14.175,31.603-31.6C926.506,687.572,922.226,678.567,914.844,672.577z M884.985,707.827l0.282-0.826l5.801,1.696
                        l0.04-2.055c0.101-5.302,3.047-8.569,5.502-10.378l1.115-0.826l0.311,1.35c0.504,2.285,1.38,4.473,2.593,6.474
                        c1.399,2.283,2.724,4.215,3.946,5.966c0.896,1.285,1.404,3.188,1.394,5.223c-0.001,6.118-4.961,11.076-11.079,11.075
                        c-6.116-0.002-11.073-4.959-11.074-11.075v-0.029C883.789,712.371,884.184,710.146,884.985,707.827z M908.988,721.797
                        l-2.535,1.452l1.269-2.633c0.923-1.916,1.398-4.016,1.393-6.142c0.012-2.707-0.684-5.206-1.961-7.035
                        c-1.381-1.981-2.563-3.722-3.84-5.808c-1.487-2.426-2.376-5.269-2.724-8.691l-0.207-2.033l-1.911,0.724
                        c-0.266,0.101-2.673,1.049-5.171,3.316c-2.643,2.396-4.325,5.304-5.003,8.646l-0.196,0.968l-4.615-1.348l-0.553,1.264
                        c-1.531,3.493-2.29,6.854-2.253,9.993c-0.004,2.131,0.475,4.234,1.402,6.152l1.273,2.643l-2.542-1.453
                        c-12.544-7.146-17.753-22.551-12.119-35.843l0.555-1.308l0.955,1.054c0.215,0.238,0.436,0.466,0.661,0.682
                        c2.764,2.662,6.079,3.406,9.334,2.098c2.192-0.881,3.835-2.501,4.508-4.447c0.478-1.382,0.695-3.642-0.981-6.544
                        c-1.249-2.133-1.548-4.692-0.826-7.056c0.776-2.572,2.676-4.716,5.345-6.039c4.84-2.397,6.813-6.949,7.616-10.345l0.372-1.569
                        l1.156,1.131c0.604,0.592,1.169,1.22,1.694,1.882c2.77,3.511,3.438,7.003,1.982,10.379c-1.992,4.637-2.339,9.21-0.929,12.235
                        c0.802,1.769,2.34,3.098,4.207,3.634c3.031,0.91,6.018-0.175,8.411-3.056c0.371-0.449,0.716-0.919,1.034-1.407l0.615-0.945
                        l0.792,0.805c5.245,5.31,8.178,12.479,8.156,19.943C923.324,707.305,917.848,716.723,908.988,721.797L908.988,721.797z"/>
                </g>
            </g>
        </svg>
    );
};

export default CounterCalories;