import React, {Component} from 'react';
import {useState} from 'react';
import {connect} from "react-redux";
import SignupForm from "./SignupForm";

// components
import Page from '../../Page';
// import PageHeader from "../../PageHeader";

//tools
// import MediaQuery from "react-responsive/src/Component";

//config
import { config }  from "../../../config";

class ViewLojalnosc extends Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     statistics:{}
        // };
        this.state = { showComponentOne: false };
        
    }
    
    
    async componentDidMount() {      
        // const tokenUrl = "https://poweredby.coniq.com/oauth/v2/token";
        // const userUrl = "https://api.coniq.com/app/customer/exists?email=alexandr.volkoff@gmail.com";
        //const userUrl = "https://api.coniq.com/app/customer/credentials/set-password";
        // const userUrl = "https://api.coniq.com/app/program/Klub_Turawa";
        // const userUrl = "https://api.coniq.com/offersByLocation/53018";
        // const userUrl = "https://api.coniq.com/app/program/Klub_Turawa/offers";
        // const clientId = "129_2k6ix5hqg884880sk480swgooc880okoksws4kkskokc0ck8so";
        // const clientSecret = "6an5es08l1oo80co80080ok0w0cs0s8ckso84c4gcoc8k0c4c0";
        
    
        // const tokenHeaders = new Headers();
        // tokenHeaders.append("x-api-version", "2.0");
        // tokenHeaders.append("Content-Type", "application/json");
    
        // const tokenData = {
        //     "client_id": clientId,
        //     "client_secret": clientSecret,
        //     "grant_type": "client_credentials"
        // };
    
        // const tokenOptions = {
        //     method: 'POST',
        //     headers: tokenHeaders,
        //     body: JSON.stringify(tokenData),
        //     redirect: 'follow'
        // };
        
        // try {
        //     const tokenResponse = await fetch(tokenUrl, tokenOptions);
        //     const tokenResult = await tokenResponse.json();
    
        //     console.log(tokenResult);
            
            // SET PASSWORD
            
            // const userHeaders = new Headers();
            // userHeaders.append("Access-Control-Allow-Origin", "*");
            // userHeaders.append("Access-Control-Allow-Methods", "POST, GET, OPTIONS, PUT, DELETE");
            // userHeaders.append("Access-Control-Allow-Headers", "Content-Type");
            // userHeaders.append("x-api-version", "3.0");
            // userHeaders.append("Authorization", `Bearer ${tokenResult.access_token}`);
            //userHeaders.append("Authorization", "ApiKey key=\"0f514cc5-317f-4118-ac90-309e22dbe1b6\"");
            // userHeaders.append("Content-Type", "application/json");
            //userHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            
            //console.log("Authorization", `Bearer ${tokenResult.access_token}`);
            
            //var raw = {"username": "alexandr.volkoff@gmail.com"};
            // var raw = {
            //     "username" : "alexandr.volkoff@gmail.com",
            //     "redirect_url" : "https://google.com"
            // }
            //var raw = "{\n	\"username\" : \"alexandr.volkoff@gmail.com\",\n    \"redirect_url\" : \"https://google.com\"\n}";
            
            //var raw = "{\n\t\"username\" : \"alexandr.volkoff@gmail.com\",\n    \"redirect_url\" : \"https://google.com\"\n}";


            // var raw = "{\n	\"username\" : \"alexandr.volkoff@gmail.com\"}";
            // var formdata = new FormData();

            // var userOptions = {
            //     method: 'GET',
            //     //method: 'POST',
            //     headers: userHeaders,
            //     //body: raw,
            //     redirect: 'follow'
            //   };
            
            // const userHeaders = new Headers();
            // userHeaders.append("x-api-version", "3.0");
            // userHeaders.append("Authorization", `Bearer ${tokenResult.access_token}`);
    
            // const userOptions = {
            //     method: 'GET',
            //     headers: userHeaders,
            //     redirect: 'follow'
            // };  

        //     const userResponse = await fetch(userUrl, userOptions);
        //     const userResult = await userResponse.json();
    
        //     console.log(userResponse);
        //     console.log(userResult);

            

        // } catch (error) {
        //     console.error(error);
        // }

    }
    
    
    

    render() {
        const {
            pageData,
            pageOptions
        } = this.props;

        // console.log(this.state.statistics);

        // const seasonGradient = {
        //     background: `linear-gradient( 
        //         ${pageOptions.rotacja_naglowki}deg, 
        //         ${pageOptions.kolor_1_naglowki} 53%, 
        //         ${pageOptions.kolor_2_naglowki} 85% 
        //     )`
        // };

        // const seasonGradientOpacity = {
        //     background: `linear-gradient( 
        //                     ${pageOptions.rotacja_opacity}deg, 
        //                     ${pageOptions.kolor_1_opacity} 53%, 
        //                     ${pageOptions.kolor_2_opacity} 85% 
        //                 )`
        // };

        const pStyle = {
            cursor: 'pointer'
        };

        return (
            <Page pageIndex={config.pageIndex.lojalnosc}>
                <article className="page o-nas content">
                    {/* <PageHeader
                        title={pageData.tytul}
                        subtitle={pageData.podtytul}
                        seasonGradient={seasonGradient}
                        seasonBlockWidth="35.4%"
                        seasonBlockHeight="241px"
                    /> */}

                    <div className="boxed">

                        {pageData.banner ? (
                            <div className="row cover-row-container">
                                <img src={pageData.banner} 
                                    alt="program lojalnościowy" 
                                    title="program lojalnościowy" 
                                    style={{maxWidth: "100%", height: "auto"}} 
                                />
                            </div>
                        ) : (
                            <div></div>
                        )}
                        

                        <div className="row content-row-container center-xs start-sm">
                            <div className={"col col-xs-12 col-md-offset-1 col-md-11"}>
                                <div className="row">
                                    <div className={"col col-md-8"}>
                                        <p dangerouslySetInnerHTML={{__html: pageData.opis}} />
                                    </div>

                                    {/* <MediaQuery minWidth={992}>
                                        <div className={"col col-md-4"}>
                                            <aside style={ seasonGradientOpacity }>
                                                <div className="full-color" style={ seasonGradient }/>
                                            </aside>
                                        </div>
                                    </MediaQuery> */}

                                    
                                    


                                    <div className={"col col-md-8"}>
                                        
                                        {/* {<p 
                                            style={pStyle}
                                            onClick={() => this.setState(prevState => ({showComponentOne: !prevState.showComponentOne}))}>
                                            {this.state.showComponentOne ? "+ Zarejestruj" : "!!!+ Zaloguj się do swojego profilu, jeśli już jesteś z nami"}
                                        </p>} */}
                                        
                                        <SignupForm />
                                        
                                    </div>

                                    <div className={"col col-md-8"}>
                                        &nbsp;
                                    </div>


                                    <div className={"col col-md-8"}>
                                        <p dangerouslySetInnerHTML={{__html: pageData.tekst_po_formularzu}} />
                                    </div>
                                    

                                </div>
                            </div>
                        </div>

                    </div>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(ViewLojalnosc);