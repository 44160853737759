import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {ConnectedRouter} from "connected-react-router";
import routes from './js/routes';
import ReactGA from 'react-ga';

//actions
import {toggleOverlay} from "./js/actions/pageActions";

// components
import Loading from "./js/components/Loading";
import LightboxGallery from "./js/components/LightboxGallery";
import Responsiveness from "./js/contexts/Responsiveness";

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-P5HL8GT'
};

TagManager.initialize(tagManagerArgs);

class App extends Component {

    constructor(props) {
        super(props);
        this.handleOverlay = this.handleOverlay.bind(this);
    }

    handleOverlay() {
        this.props.showOverlay(true);
    }



    render() {

        const {
            history,
            displayOverlay,
            lightboxOpened
        } = this.props;

        const trackingId = "UA-161709274-1";
        ReactGA.initialize(trackingId);

        history.listen(location => {
            this.handleOverlay();
            ReactGA.set({ page: location.pathname }); // Update the user's current page
            ReactGA.pageview(location.pathname); // Record a pageview for the given page
        });

        return (
            <div className="App">
                { displayOverlay && <Loading /> }
                { lightboxOpened && <LightboxGallery/> }

                <ConnectedRouter history={history}>
                    <Responsiveness>
                        { routes }
                    </Responsiveness>
                </ConnectedRouter>
            </div>
        );
    }
}

App.propTypes = {
    history: PropTypes.object,
};

const mapStateToProps = (state) => ({
    displayOverlay: state.page.displayOverlay,
    location: state.router.location,
    lightboxOpened: state.lightbox.lightboxOpened
});

const mapDispatchToProps = dispatch => ({
    showOverlay: value => dispatch(toggleOverlay(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(App);
