import React, {Component,Fragment} from 'react';
import { connect } from "react-redux";
import axios from "axios";

//actions
import {setHoveredMenuItemIndex} from "../../actions/pageActions";

// svg
import Logo from "../../svg/Logo";

// components
import MenuTop from '../Menu/MenuTop';
import Search from '../Search';

//tools
import MediaQuery from 'react-responsive';

//config
import { config }  from "../../config";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu:[]
        };
        this.handleMouseEnterLogo = this.handleMouseEnterLogo.bind(this);
    }

    handleMouseEnterLogo(){
        this.props.setHoveredMenuItem(null)
    }

    componentDidMount() {
        axios
            .get(
                config.domainRelPath+'/wp-json/api/menu/'
            )
            .then(res => {
                this.setState({
                    menu: res.data
                });
            })
            .catch(error => console.log(error));
    }
    render() {
        const {
            pageOptions
        } = this.props;
        return (
            <Fragment>
                <header className="main-header">
                    <div className={"boxed"}>
                        <figure onMouseEnter={ () => this.handleMouseEnterLogo()}>
                            <a href={config.domain}
                               title="Link: Home"
                               target="_top">
                                <Logo mainColor={pageOptions.zielen} />
                            </a>

                        </figure>

                        <MenuTop role="menu"
                                 menu={this.state.menu} />
                    </div>
                </header>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions
});

const mapDispatchToProps = dispatch => ({
    setHoveredMenuItem: value => dispatch( setHoveredMenuItemIndex (value))
});


export default connect(mapStateToProps,mapDispatchToProps)(Header);

