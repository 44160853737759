import React from 'react';
import ResponsivenessContext from './ResponsivenessContext';

//hooks
import { useMediaQuery } from 'react-responsive';

const Responsiveness = ( { children } ) => {
    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    });
    return (
        <ResponsivenessContext.Provider value={isMobile}>
            {children}
        </ResponsivenessContext.Provider>
    );
};

export default Responsiveness;