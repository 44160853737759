import React from 'react';
//tools
import MediaQuery from "react-responsive";

const Pasaz = ({pageData,title,textColor,seasonGradient}) => {

    const phone = pageData.pasaz_telefon;
    return (
        <div
            className={"col col-xs-12 col-sm-6 col-md-4"}
            style={seasonGradient} >
            <section
                className="lower"
                style={{color:textColor}}
            >
                <h3 dangerouslySetInnerHTML={{__html: title}}/>
                <p>
                    <strong>{pageData.pasaz_nazwa}</strong>
                    <a href={`mailto:${pageData.pasaz_email}`}
                       title="Email - Wynajem powierzchni na pasażu"
                       style={{color:textColor}}
                    >
                        {pageData.pasaz_email}
                    </a>
                </p>
                <p>
                    <MediaQuery minWidth={769}>
                        <span>
                            {pageData.pasaz_telefon}
                        </span>
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                        <a
                            href={`tel:${phone && phone.replace(/[() ]/g,'')}`}
                            title="Tel"
                            style={{color:textColor}}
                        >
                            {pageData.pasaz_telefon}
                        </a>
                    </MediaQuery>
                </p>
            </section>
        </div>
    );
};

export default Pasaz;