import React,{ Component } from 'react';
import { connect } from "react-redux";
import jsonp from "jsonp";

//actions
import { setNewsletterVisibility } from "../../actions/pageActions";

//svg
import MenuClose from "../../svg/MenuClose";

//tools
import classnames from "classnames";


const x = `
<div id="coniqSignup">
	<img id="coniqLoadingAnimation" src="https://platform-stage.coniq.com/img/coniqSignupImages/coniqSignupLoader.gif" style="display:none" />
	<script id="myScript" type="text/javascript">document.getElementById("coniqLoadingAnimation").style.display="inline-block"</script>
	<noscript>
		<a id="coniqSignupLink" href="https://poweredby.coniq.com/signup/0eff0g0" target="_blank">Click here to sign up</a>
	</noscript>
</div>`;


class Newsletter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputFocused: false,
            inputText: null,
            inputChecked:false,
            inputValue: null,
            inputValid: true,
            checkboxValid: true,
            inputTextErrorType:null,
            submitMessage: false,
            postResponse: null,
            submitStatus: null,
            submitStatusMsg: null
        };
        //this.validateForm = this.validateForm.bind(this);
        //this.submitForm = this.submitForm.bind(this)
    }


    componentDidMount() {

        const script = document.getElementById('myScript').innerHTML;
        window.eval(script);

        const lounchScript = document.createElement("script");
        lounchScript.src = process.env.PUBLIC_URL + "/submitform.js";
        lounchScript.async = true;
        document.getElementById("formdiv").appendChild(lounchScript);
    }

    

    

    render() {

        const { pageOptions } = this.props;

        return (
            <div className={classnames(
                "newsletter-container",{
                    "sent": this.state.submitMessage
                }
            )}>

                <nav onClick={ ()=> this.props.setNewsletterVisibility(false) }>
                    <MenuClose mainColor="#000000" size={14}/>
                </nav>

                    
                <div className="row ">
                    <div className="col ">
                        <div id="formdiv" dangerouslySetInnerHTML={{__html: x}} />                        
                    </div>
                </div>
                

            </div>
        );
    }

};

const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions
});

const mapDispatchToProps = dispatch => ({
    setNewsletterVisibility: value => dispatch( setNewsletterVisibility(value))
});


export default connect(mapStateToProps,mapDispatchToProps)(Newsletter);