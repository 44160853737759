import React from 'react';
import {connect} from "react-redux";
import Map from '../../../../svg/Map';

const Mapa = (
    {
      link,
      mapaTransparent
    }
) => {
    return (
        <div className="row mapa center-xs">
            <div className="col col-xs-12">
                <figure>
                    <a
                        href={link}
                        title="Sprawdź mapę dojazdu"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Map/>
                        <img
                            className="svg-size-provider"
                            src={mapaTransparent}
                            width={1392}
                            height={1036}
                            aria-hidden={true}
                        />

                    </a>
                </figure>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    mapaTransparent: state.page.pageOptions.zaslepka_mapa
});


export default connect(mapStateToProps,null)(Mapa);