import React, {Component} from 'react';
import {connect} from "react-redux";

//actions
import {
    setNewsletterVisibility
} from "../../../../actions/pageActions";

//tools
import classnames from'classnames';

//components
import Newsletter from '../../../Newsletter';


class FindUsSection extends Component {
    render() {

        const {
            pageData,
            pageOptions,
            pageDataSocialList} = this.props;

        const seasonGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_news}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`
        };

        return (
            <>
                { this.props.newsletterVisible && <Newsletter/> }

                <div className="row find-us-row-container center-xs start-md">
                    <div className="col col-xs-12 col-md-7 contact-us  center-xs">
                        <div className="row center-xs">
                            <div className="col col-xs-12 col-md-10">
                                <div
                                    className="newsletter"
                                    style={ seasonGradient }
                                    onClick={() => this.props.setNewsletterVisibility(true)}
                                >
                                    <h3 style={{color:pageOptions.kolor_sezonowy_tekst}}>
                                        {pageOptions.teksty_zapisz_sie_do_newslettera}
                                    </h3>
                                    <p style={{color:pageOptions.kolor_sezonowy_tekst}}>
                                        {pageOptions.teksty_jesli_chcesz_byc_na_biezaco}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row center-xs start-md">
                            <div className={
                                classnames(
                                    "col",
                                    "col-xs-12",
                                    "col-sm-12",
                                    "col-md-10 col-md-offset-2")}>
                                <div className="social-media" >
                                    <p>
                                        {pageOptions.teksty_znajdz_nas_na}
                                    </p>
                                    <ul>
                                        {
                                            pageDataSocialList
                                                .map((item,index) =>
                                                    <li key={index}>
                                                        <a title={`Link: ${item.nazwa}`}
                                                           href={item.link}>
                                                            <img src={item.ikona}
                                                                 alt={`Ikona: ${item.nazwa}`}
                                                                 width={50}
                                                                 height={50} />
                                                        </a>
                                                    </li>
                                                )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col col-xs-12 col-md-5 contact">

                        <div className="row">
                            <div className="col col-xs-12 col-sm-6">
                                <h3 dangerouslySetInnerHTML={{__html: pageOptions.teksty_kontakt_z_zarzadca_label}}/>
                                <p>
                                    {pageData.kontakt_nazwa}<br/>
                                    <span dangerouslySetInnerHTML={{__html: pageData.kontakt_adres}}/>
                                </p>
                                <a href={`tel:${pageData.kontakt_telefon}`} title="Tel">
                                    {pageData.kontakt_telefon}
                                </a><br/>
                                <a href={`mailto:${pageData.kontakt_email}`} title="Email">
                                    {pageData.kontakt_email}
                                </a>
                            </div>
                            <div className="col col-xs-12 col-sm-6">
                                <h3 dangerouslySetInnerHTML={{__html: pageOptions.teksty_obiekt_zarzadzany_przez}}/>
                                <p>
                                    {pageData.zarzadca_nazwa}<br/>
                                    <span dangerouslySetInnerHTML={{__html: pageData.zarzadca_adres}}/>
                                </p>
                                <a href={`tel:${pageData.zarzadca_telefon}`} title="Tel">
                                    {pageData.zarzadca_telefon}
                                </a><br/>
                                <a href={pageData.zarzadca_www} title="www">
                                    {pageData.zarzadca_www}
                                </a>
                            </div>
                        </div>

                    </div>
            </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    newsletterVisible: state.page.newsletterVisible
});

const mapDispatchToProps = dispatch => ({
    setNewsletterVisibility: value => dispatch( setNewsletterVisibility(value))
});


export default connect(mapStateToProps,mapDispatchToProps)(FindUsSection);