import React from 'react';

const Wifi = ({mainColor}) => {
    return (
        <svg version="1.1"
             id="i_wifi"
             xmlns="http://www.w3.org/2000/svg"
             width="143px"
             height="129px"
             viewBox="0 0 143 129" >
            <g>
                <path fill={mainColor} d="M131.889,45.975C115.785,29.869,94.336,21,71.5,21c-22.837,0-44.282,8.867-60.389,24.975
		c-0.869,0.869-0.869,2.274,0,3.144c0.869,0.869,2.274,0.869,3.145,0C29.521,33.851,49.851,25.444,71.5,25.444
		c21.648,0,41.979,8.407,57.245,23.673c0.434,0.433,1.002,0.651,1.571,0.651s1.139-0.218,1.572-0.651
		C132.758,48.249,132.758,46.842,131.889,45.975"/>
                <path fill={mainColor} d="M24.18,59.042c-0.869,0.87-0.869,2.274,0,3.144c0.435,0.434,1.003,0.651,1.572,0.651
		s1.138-0.217,1.572-0.651c24.356-24.359,63.99-24.359,88.35,0c0.869,0.869,2.274,0.869,3.144,0c0.869-0.87,0.869-2.274,0-3.144
		C92.729,32.953,50.273,32.949,24.18,59.042"/>
                <path fill={mainColor} d="M71.5,57.948c-12.951,0-25.113,5.031-34.249,14.166c-0.869,0.869-0.869,2.274,0,3.144
		c0.87,0.869,2.274,0.869,3.144,0c8.295-8.293,19.341-12.862,31.105-12.862c11.764,0,22.81,4.569,31.104,12.862
		c0.435,0.434,1.004,0.651,1.573,0.651s1.139-0.218,1.572-0.651c0.869-0.869,0.869-2.274,0-3.144
		C96.615,62.979,84.451,57.948,71.5,57.948"/>
                <path fill={mainColor} d="M71.5,78.865c-8.031,0-14.565,6.534-14.565,14.567S63.469,108,71.5,108c8.033,0,14.567-6.534,14.567-14.567
		S79.533,78.865,71.5,78.865 M71.5,103.551c-5.581,0-10.119-4.54-10.119-10.12c0-5.581,4.54-10.121,10.119-10.121
		c5.578,0,10.121,4.54,10.121,10.121C81.621,99.011,77.081,103.551,71.5,103.551"/>
            </g>
        </svg>
    );
};

export default Wifi;