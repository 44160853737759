import React from 'react';

const Ograniczaj = ({mainColor}) => {
    return (
        <svg
            version="1.1" xmlns="http://www.w3.org/2000/svg"
            id="svg_ograniczaj"
            x="0px" y="0px"
            width="82.87px" height="80.2px" viewBox="853.36 649.5 82.87 80.2">
            <path fill={mainColor} d="M902.92,689.68c-0.31-0.32-0.73-0.5-1.18-0.51l0,0c-0.92,0-1.67,0.74-1.67,1.66c0,0,0,0.01,0,0.01
	c0,0.44,0.18,0.87,0.51,1.17l16.97,17c0.31,0.32,0.73,0.5,1.18,0.51h0.12c0.48-0.04,0.92-0.28,1.21-0.66
	c10.53-13.95,7.76-33.79-6.18-44.32c-4.72-3.56-10.34-5.73-16.23-6.26c-0.44-0.04-0.88,0.1-1.22,0.38c-0.71,0.59-0.8,1.64-0.21,2.35
	c0.28,0.34,0.69,0.55,1.13,0.59c15.57,1.4,27.06,15.16,25.67,30.73c-0.42,4.62-1.96,9.07-4.5,12.96L902.92,689.68z"/>
            <path fill={mainColor} d="M893.34,660.97c0.29-0.34,0.42-0.78,0.39-1.22l0,0c-0.08-0.92-0.89-1.6-1.81-1.52c-5.89,0.53-11.51,2.7-16.22,6.26
	c-0.74,0.55-0.88,1.6-0.33,2.33c0.05,0.06,0.1,0.12,0.15,0.17l21.25,21.24c0.67,0.63,1.73,0.59,2.36-0.08c0.6-0.64,0.6-1.63,0-2.28
	l-19.85-19.83c3.88-2.54,8.32-4.08,12.93-4.5C892.65,661.52,893.06,661.31,893.34,660.97z"/>
            <path fill={mainColor} d="M870.65,670.12L870.65,670.12c-0.48,0.03-0.92,0.27-1.21,0.66c-4.13,5.47-6.35,12.14-6.34,18.99
	c0.01,17.5,14.19,31.68,31.69,31.67c6.85,0,13.51-2.22,18.99-6.33c0.38-0.29,0.63-0.73,0.66-1.22c0.03-0.49-0.15-0.96-0.51-1.29
	l-42-42C871.59,670.26,871.12,670.08,870.65,670.12z M910.18,713.52c-13.13,8.51-30.67,4.77-39.18-8.36
	c-6.08-9.37-6.08-21.43-0.01-30.8L910.18,713.52z"/>
            <path fill={mainColor} d="M923.12,661.44c-15.65-15.63-41.01-15.63-56.66,0c-15.64,15.64-15.63,40.99,0,56.63c6.82,6.82,15.87,10.95,25.49,11.63h0.11
	c0.87,0,1.6-0.68,1.66-1.55c0.06-0.91-0.61-1.7-1.52-1.78c-20.25-1.43-35.5-19-34.07-39.25c1.43-20.25,19-35.5,39.25-34.07
	c20.25,1.43,35.5,19,34.07,39.25c-1.29,18.26-15.81,32.78-34.07,34.07c-0.92,0.07-1.6,0.87-1.53,1.79c0.07,0.86,0.78,1.53,1.64,1.54
	h0.12c22.06-1.55,38.69-20.68,37.14-42.74C934.08,677.33,929.95,668.27,923.12,661.44z"/>
        </svg>
    );
};

export default Ograniczaj;