import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";
import { Redirect } from 'react-router';

//actions
import { setPost } from "../../../../actions/pageActions";

//tools
import Truncate from 'react-truncate';
import classnames from "classnames";

//components
import Modal from "../../../Modal";

//config
import { paths } from "../../../../config/paths";

//svg
import PlanVectors from "../../../../svg/plan/PlanVectors";
import WhatsApp from "../../../../svg/Whatsapp";
import Email from "../../../../svg/Email";
import Pins from "../../../../svg/plan/Pins";
import FoodIcon from "../../../../svg/plan/legend/FoodIcon";
import ToiletIcon from "../../../../svg/plan/legend/ToiletIcon";
import AtmIcon from "../../../../svg/plan/legend/AtmIcon";
import ToddlerIcon from "../../../../svg/plan/legend/ToddlerIcon";
import DrugstoreIcon from "../../../../svg/plan/legend/DrugstoreIcon";
import OfficesIcon from "../../../../svg/plan/legend/OfficesIcon";
import RescueIcon from "../../../../svg/plan/legend/RescueIcon";
import WifiIcon from "../../../../svg/plan/legend/WifiIcon";


const Icons = {
    FoodIcon,
    ToiletIcon,
    AtmIcon,
    ToddlerIcon,
    DrugstoreIcon,
    OfficesIcon,
    RescueIcon,
    WifiIcon,
};




class Plan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveredItem: null,
            redirect: false,
            redirectLink: null,
            showPins: true
        };
        this.handlePostSelection = this.handlePostSelection.bind(this);
    }

    handlePostSelection = value =>{
        this.props.setSelectedPost(value);
        this.setState(
            {
                redirect: true ,
                redirectLink: value
            });
        if (this.props.selectedPost) {
            this.setState( { showPins: false });
        } else {
            this.setState( { showPins: true });
        }
    };

    render() {
        const {
            pageOptions,
            shopsData,
            selectedPost,
            legend
        } = this.props;

        

        return (
            <div className="row plan-container center-xs">
                { this.state.redirect &&
                    <Redirect push to={paths.sklepy + '/' + this.state.redirectLink}/>
                }
                <div className="col col-xs-12">
                    <div className={classnames(
                        "stage",{
                            "block-active": selectedPost
                        }
                    )}>
                        <div className={classnames(
                            "pins-wrapper",{
                                "disabled" : selectedPost
                            }
                        )}>
                            <Pins/>
                        </div>

                        <PlanVectors shopsData={shopsData} />

                        <img
                            className="svg-size-provider"
                            src={pageOptions.plan_centrum_transparent}
                            width={1820}
                            height={1473}
                            aria-hidden={true}
                        />
                        
                        <ul className="legend">
                            {
                                legend &&
                                legend.map(
                                    (item,index) => {
                                        const Icon = Icons[item.icon];
                                        return (
                                                <li key={index}>
                                                    <i><Icon mainColor="#000000"/></i>
                                                    <span>{item.label}</span>
                                                </li>
                                            )

                                    }
                                )
                            }
                        </ul>

                        {
                            shopsData
                                .map( (item,index) => {
                                        return (
                                            <Fragment key={index}>
                                                { selectedPost === item.acf.url && <Modal modalData={item.acf} /> }
                                            </Fragment>
                                        )
                                    }
                                )
                        }

                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions,
    selectedPost: state.page.selectedPost,
    hoveredPost: state.page.hoveredPost
});

const mapDispatchToProps = dispatch => ({
    setSelectedPost: value => dispatch( setPost(value))
});


export default connect(mapStateToProps,mapDispatchToProps)(Plan);