import React from 'react';

const PhotoCamera = ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_photo_camera"
             xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="75px" height="62.988px"
             viewBox="218.5 224.506 75 62.988" >
            <g>
                <path fill={mainColor} d="M286.908,236.225h-12.7l-1.638-8.186c-0.409-2.047-2.222-3.533-4.31-3.533h-24.522c-2.087,0-3.9,1.486-4.309,3.533
                    l-1.637,8.186h-12.701c-3.635,0-6.592,2.957-6.592,6.592v38.086c0,3.635,2.957,6.592,6.592,6.592h61.816
                    c3.635,0,6.592-2.957,6.592-6.592v-38.086C293.5,239.182,290.543,236.225,286.908,236.225z M243.739,228.9h24.522l1.465,7.324
                    h-27.452L243.739,228.9z M289.105,280.902c0,1.212-0.985,2.197-2.197,2.197h-61.816c-1.211,0-2.197-0.985-2.197-2.197v-38.086
                    c0-1.211,0.986-2.197,2.197-2.197h61.816c1.212,0,2.197,0.986,2.197,2.197V280.902z"/>
                <path fill={mainColor} d="M256,245.6c-8.966,0-16.26,7.294-16.26,16.26s7.294,16.26,16.26,16.26s16.26-7.294,16.26-16.26S264.966,245.6,256,245.6z
                     M256,273.725c-6.542,0-11.865-5.323-11.865-11.865c0-6.542,5.323-11.865,11.865-11.865c6.543,0,11.865,5.323,11.865,11.865
                    C267.865,268.401,262.543,273.725,256,273.725z"/>
                <path fill={mainColor} d="M237.25,245.6h-7.031c-1.213,0-2.197,0.984-2.197,2.197s0.984,2.197,2.197,2.197h7.031c1.213,0,2.197-0.984,2.197-2.197
                    S238.463,245.6,237.25,245.6z"/>
            </g>
        </svg>
    );
};

export default PhotoCamera;