import React from 'react';

const Smiley = ({mainColor}) => {
    return (
        <svg version="1.1" width="82.87px" height="80.2px"  id="svg_smiley" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.87 80.2" >
            <g id="smiley-group">
                <path fill="#000000" d="M0,25.89,20.13,16.2,0,6.5V.24L27.86,13.93v4.48L0,32.09Z"/>
                <path fill={mainColor} d="M55,18.17V13.69L82.87,0V6.26L62.68,16l20.19,9.69v6.2Z"/>
                <path fill={mainColor} d="M42.78,80.2A33.63,33.63,0,0,1,9.18,46.6h5.43a28.17,28.17,0,0,0,56.33,0h5.44A33.63,33.63,0,0,1,42.78,80.2Z"/>
            </g>
        </svg>
    );
};

export default Smiley;