import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import lightbox from "./LightboxReducer";
import page from "./PageReducer";
import player from "./PlayerReducer";
import popup from "./PopupReducer";

export default (history) => combineReducers({
    router: connectRouter(history),
    lightbox,
    page,
    player,
    popup
});