import React from 'react';

const Recycling = ({mainColor}) => {
    return (
        <svg
            version="1.1"
            id="i_recycling"
            xmlns="http://www.w3.org/2000/svg"
            width="50px" height="50px"
            viewBox="0 0 50 50">
            <g>                
                <path display="none" fill={mainColor} d="M47.709,18.23c-5.886-5.886-13.723-9.116-22.059-9.116
                    c-8.347,0-16.173,3.23-22.059,9.116c-0.314,0.325-0.314,0.835,0,1.149c0.314,0.325,0.824,0.325,1.149,0
                    c5.572-5.571,12.997-8.65,20.91-8.65c7.902,0,15.327,3.079,20.91,8.65c0.151,0.163,0.368,0.239,0.574,0.239
                    s0.412-0.076,0.574-0.239C48.024,19.065,48.024,18.545,47.709,18.23"/>
                <path display="none" fill={mainColor} d="M8.362,23.011c-0.314,0.314-0.314,0.824,0,1.138
                    c0.163,0.163,0.368,0.238,0.574,0.238s0.412-0.075,0.575-0.238c8.899-8.888,23.371-8.888,32.27,0c0.325,0.325,0.834,0.325,1.148,0
                    c0.325-0.314,0.325-0.824,0-1.138C33.401,13.472,17.89,13.472,8.362,23.011"/>
                <path display="none" fill={mainColor} d="M25.651,22.61c-4.737,0-9.181,1.832-12.509,5.171
                    c-0.325,0.314-0.325,0.834,0,1.148c0.314,0.314,0.824,0.314,1.149,0c3.024-3.024,7.057-4.693,11.36-4.693
                    c4.293,0,8.325,1.669,11.36,4.693c0.162,0.163,0.368,0.238,0.574,0.238s0.412-0.075,0.574-0.238c0.314-0.314,0.314-0.834,0-1.148
                    C34.822,24.442,30.377,22.61,25.651,22.61"/>
                <path display="none" fill={mainColor} d="M25.651,30.251c-2.938,0-5.322,2.385-5.322,5.322
                    c0,2.927,2.385,5.312,5.322,5.312c2.927,0,5.322-2.385,5.322-5.312C30.973,32.636,28.578,30.251,25.651,30.251 M25.651,39.259
                    c-2.038,0-3.696-1.658-3.696-3.696c0-2.037,1.659-3.696,3.696-3.696c2.038,0,3.696,1.659,3.696,3.696
                    C29.347,37.601,27.689,39.259,25.651,39.259"/>
                <path display="none" fill={mainColor} d="M25.651,42.328c-4.628,0-8.975-1.811-12.249-5.073
                    c-3.273-3.273-5.073-7.631-5.073-12.26c0-4.618,1.799-8.975,5.073-12.249c3.274-3.263,7.621-5.073,12.249-5.073
                    c4.195,0,8.249,1.528,11.414,4.292c0.206,0.184,0.228,0.509,0.044,0.715c-0.185,0.217-0.51,0.238-0.716,0.054
                    c-2.97-2.612-6.786-4.043-10.742-4.043c-4.357,0-8.455,1.702-11.533,4.78c-3.079,3.078-4.78,7.176-4.78,11.522
                    c0,4.357,1.702,8.455,4.78,11.533c3.078,3.079,7.176,4.78,11.533,4.78s8.444-1.701,11.522-4.78c3.09-3.078,4.78-7.176,4.78-11.533
                    c0-3.989-1.452-7.826-4.098-10.807c-0.184-0.217-0.173-0.531,0.044-0.715c0.206-0.185,0.531-0.174,0.715,0.043
                    c2.808,3.166,4.358,7.241,4.358,11.479c0,4.629-1.8,8.986-5.073,12.26C34.626,40.517,30.28,42.328,25.651,42.328"/>
                <path display="none" fill={mainColor} d="M25.651,36.68c-1.647,0-2.992-1.334-2.992-2.992c0,0-0.119-1.041,0.509-1.041
                    c0.618,0,0.499,1.041,0.499,1.041c0,1.095,0.889,1.983,1.983,1.983c1.084,0,1.973-0.889,1.973-1.983v-4.986
                    c0-0.954,0.77-1.724,1.724-1.724h4.997c1.084,0,1.973-0.889,1.973-1.973c0-1.095-0.889-1.983-1.973-1.983h-4.997
                    c-0.954,0-1.724-0.77-1.724-1.724v-4.986c0-1.095-0.889-1.984-1.973-1.984c-1.095,0-1.983,0.889-1.983,1.984v4.986
                    c0,0.954-0.77,1.724-1.724,1.724h-4.986c-1.095,0-1.984,0.889-1.984,1.983c0,1.084,0.889,1.973,1.984,1.973h4.986
                    c0.954,0,1.724,0.77,1.724,1.724v2.406c0,0.282-0.228,0.51-0.499,0.51c-0.282,0-0.509-0.228-0.509-0.51v-2.406
                    c0-0.39-0.325-0.715-0.715-0.715h-4.986c-1.647,0-2.992-1.334-2.992-2.981c0-1.658,1.344-2.992,2.992-2.992h4.986
                    c0.39,0,0.715-0.325,0.715-0.715v-4.986c0-1.659,1.344-2.992,2.992-2.992s2.992,1.333,2.992,2.992v4.986
                    c0,0.39,0.313,0.715,0.704,0.715h4.997c1.647,0,2.992,1.333,2.992,2.992c0,1.647-1.345,2.981-2.992,2.981h-4.997
                    c-0.391,0-0.704,0.325-0.704,0.715v4.986C28.643,35.346,27.298,36.68,25.651,36.68"/>
                <path display="none" fill={mainColor} d="M28.805,41.72c-0.39-0.151-0.823,0.032-0.976,0.423
                    c-0.13,0.325-0.455,0.531-0.813,0.531h-2.732c-0.358,0-0.694-0.206-0.813-0.531c-0.163-0.391-0.596-0.574-0.976-0.423
                    c-0.39,0.162-0.575,0.596-0.423,0.976c0.369,0.899,1.225,1.485,2.211,1.485h2.732c0.976,0,1.843-0.586,2.2-1.485
                    C29.369,42.316,29.185,41.882,28.805,41.72"/>
                <path display="none" fill={mainColor} d="M37.596,37.861c-0.195-1.04-0.846-1.951-1.767-2.492
                    c0.162-0.131,0.325-0.282,0.466-0.456c0.705-0.823,1.019-1.907,0.856-2.98c-0.26-1.756-0.997-3.382-2.146-4.727
                    c-0.856-1.008-1.907-1.81-3.089-2.385c1.441-1.539,2.33-3.599,2.33-5.875c0-3.924-2.645-7.241-6.254-8.271
                    c0.487-0.585,0.759-1.312,0.759-2.092c0-1.518-1.236-2.764-2.765-2.764l0,0c-0.618,0-1.192,0.206-1.615,0.563
                    c-0.477,0.401-0.737,0.965-0.737,1.593c0,0.412,0.336,0.748,0.748,0.748c0.412,0,0.748-0.336,0.748-0.748
                    c0-0.184,0.065-0.325,0.206-0.444c0.163-0.141,0.391-0.206,0.65-0.206c0.705,0,1.269,0.564,1.269,1.258
                    c0,0.477-0.185,0.921-0.521,1.247c-0.336,0.336-0.78,0.52-1.246,0.52l0,0c-0.022,0-0.044,0-0.055,0
                    c-4.639,0.119-8.379,3.935-8.379,8.596c0,2.276,0.878,4.336,2.319,5.875c-1.17,0.575-2.233,1.377-3.089,2.385
                    c-1.138,1.345-1.886,2.971-2.146,4.727c-0.163,1.073,0.152,2.157,0.867,2.98c0.141,0.174,0.292,0.325,0.466,0.456
                    c-0.921,0.541-1.572,1.452-1.778,2.492c-0.206,1.052,0.054,2.146,0.715,2.992l1.604,2.049c0.65,0.823,1.637,1.269,2.634,1.269
                    c0.575,0,1.138-0.142,1.659-0.445c1.431-0.823,2.038-2.59,1.42-4.118l-0.976-2.418c-0.065-0.162-0.141-0.314-0.228-0.466h5.495
                    c0.412,0,0.748-0.336,0.748-0.748s-0.336-0.748-0.748-0.748h-6.916v-1.582c0-0.206,0.163-0.369,0.369-0.369h12.347
                    c0.206,0,0.368,0.163,0.368,0.369v1.68c-0.737,0.412-1.322,1.063-1.647,1.864l-0.976,2.418c-0.618,1.528,0,3.295,1.431,4.118
                    c0.51,0.304,1.084,0.445,1.647,0.445c0.998,0,1.984-0.445,2.635-1.269l1.604-2.049C37.542,40.007,37.802,38.913,37.596,37.861
                     M18.551,18.946c0-3.913,3.187-7.1,7.1-7.1s7.1,3.187,7.1,7.1c0,3.914-3.187,7.101-7.1,7.101S18.551,22.859,18.551,18.946
                     M19.364,37.752l0.976,2.407c0.336,0.845,0,1.82-0.78,2.276c-0.792,0.455-1.799,0.26-2.363-0.456l-1.604-2.06
                    c-0.39-0.498-0.542-1.138-0.423-1.767s0.509-1.171,1.063-1.485c0.553-0.325,1.214-0.39,1.81-0.173
                    C18.648,36.702,19.125,37.157,19.364,37.752 M31.83,30.285c-0.412,0-0.748,0.336-0.748,0.748v0.747H20.22v-0.747
                    c0-0.412-0.336-0.748-0.748-0.748c-0.412,0-0.748,0.336-0.748,0.748v0.899c-0.661,0.293-1.116,0.954-1.116,1.713v1.062
                    c-0.563-0.054-1.095-0.325-1.474-0.77c-0.423-0.487-0.607-1.138-0.51-1.788c0.423-2.851,2.331-5.203,4.997-6.233
                    c1.409,1.03,3.155,1.626,5.03,1.626s3.609-0.596,5.029-1.626c2.667,1.03,4.563,3.383,4.997,6.233
                    c0.098,0.65-0.098,1.301-0.52,1.788c-0.369,0.444-0.9,0.716-1.464,0.77v-1.062c0-0.759-0.466-1.42-1.116-1.702v-0.91
                    C32.578,30.621,32.242,30.285,31.83,30.285 M35.7,39.92l-1.604,2.06c-0.553,0.705-1.572,0.911-2.353,0.456
                    c-0.791-0.456-1.127-1.432-0.791-2.276l0.976-2.407c0.238-0.596,0.727-1.051,1.322-1.257c0.238-0.087,0.477-0.119,0.716-0.119
                    c0.39,0,0.77,0.098,1.105,0.292c0.553,0.314,0.932,0.856,1.063,1.485C36.252,38.772,36.089,39.422,35.7,39.92"/>
                <path display="none" fill={mainColor} d="M29.532,35.444c-0.142-0.141-0.336-0.217-0.531-0.217s-0.391,0.076-0.531,0.217
                    s-0.217,0.336-0.217,0.531s0.076,0.391,0.217,0.531s0.336,0.217,0.531,0.217s0.39-0.076,0.531-0.217
                    c0.13-0.141,0.217-0.336,0.217-0.531S29.662,35.584,29.532,35.444"/>
                <path display="none" fill={mainColor} d="M27.71,21.504c-0.314-0.26-0.791-0.206-1.052,0.119
                    c-0.249,0.303-0.617,0.477-1.008,0.477c-0.401,0-0.77-0.174-1.019-0.477c-0.26-0.325-0.726-0.379-1.051-0.119
                    c-0.314,0.26-0.369,0.737-0.108,1.062c0.531,0.65,1.333,1.03,2.179,1.03c0.846,0,1.637-0.379,2.168-1.03
                    C28.079,22.241,28.036,21.764,27.71,21.504"/>
                <path display="none" fill={mainColor} d="M23.754,18.415c-0.683-0.672-1.789-0.672-2.46,0
                    c-0.293,0.292-0.293,0.77,0,1.062c0.292,0.293,0.759,0.293,1.051,0c0.098-0.098,0.26-0.098,0.347,0
                    c0.151,0.152,0.347,0.217,0.531,0.217c0.195,0,0.391-0.065,0.531-0.217C24.047,19.185,24.047,18.708,23.754,18.415"/>
                <path display="none" fill={mainColor} d="M30.008,18.415c-0.683-0.672-1.788-0.672-2.472,0
                    c-0.292,0.292-0.292,0.77,0,1.062c0.152,0.152,0.348,0.217,0.531,0.217c0.195,0,0.391-0.065,0.531-0.217
                    c0.098-0.098,0.25-0.098,0.347,0c0.293,0.293,0.77,0.293,1.063,0C30.301,19.185,30.301,18.708,30.008,18.415"/>
                <path display="none" fill={mainColor} d="M44.827,13.027c-0.369,0-0.661,0.293-0.661,0.662v23.793h-3.6
                    c-4.249,0-8.487,0.487-12.639,1.441c-0.531,0.119-1.084-0.098-1.388-0.553c2.363-1.441,5.073-2.211,7.849-2.211h7.793
                    c0.358,0,0.661-0.293,0.661-0.661V30.88c0-0.368-0.303-0.661-0.661-0.661c-0.368,0-0.661,0.293-0.661,0.661v3.957h-7.132
                    c-2.83,0-5.615,0.736-8.076,2.124V18.979c0-0.369-0.304-0.661-0.661-0.661c-0.369,0-0.661,0.292-0.661,0.661v17.983
                    c-2.46-1.388-5.247-2.124-8.076-2.124H9.782V11.044H19.96c2.775,0,5.03,2.244,5.03,5.019c0,0.369,0.292,0.662,0.661,0.662
                    c0.357,0,0.661-0.293,0.661-0.662c0-2.775,2.244-5.019,5.019-5.019H41.52v17.181c0,0.368,0.293,0.661,0.661,0.661
                    c0.358,0,0.661-0.293,0.661-0.661V10.383c0-0.369-0.303-0.661-0.661-0.661H31.331c-2.406,0-4.606,1.366-5.68,3.522
                    c-1.073-2.157-3.273-3.522-5.691-3.522H9.121c-0.369,0-0.662,0.292-0.662,0.661v25.116c0,0.368,0.293,0.661,0.662,0.661h7.793
                    c2.764,0,5.485,0.77,7.848,2.211c-0.314,0.455-0.856,0.672-1.398,0.553c-4.141-0.954-8.379-1.441-12.639-1.441H7.137V13.689
                    c0-0.369-0.292-0.662-0.661-0.662s-0.661,0.293-0.661,0.662v24.454c0,0.368,0.292,0.661,0.661,0.661h4.249
                    c4.152,0,8.292,0.478,12.347,1.409c0.943,0.217,1.94-0.098,2.58-0.835c0.64,0.737,1.626,1.052,2.58,0.835
                    c4.043-0.932,8.184-1.398,12.335-1.409h4.261c0.357,0,0.661-0.293,0.661-0.661V13.689C45.488,13.32,45.184,13.027,44.827,13.027"/>
                <path display="none" fill={mainColor} d="M23.483,15.412c-1.041-0.693-2.266-1.062-3.523-1.062h-7.534
                    c-0.368,0-0.661,0.292-0.661,0.661c0,0.358,0.293,0.661,0.661,0.661h7.534c0.998-0.011,1.962,0.293,2.786,0.835
                    c0.303,0.206,0.715,0.13,0.921-0.174C23.873,16.03,23.787,15.618,23.483,15.412L23.483,15.412z"/>
                <path display="none" fill={mainColor} d="M38.875,14.35h-7.545c-1.246-0.011-2.471,0.369-3.522,1.062
                    c-0.304,0.206-0.38,0.618-0.185,0.921c0.206,0.304,0.618,0.379,0.922,0.174c0.823-0.542,1.799-0.846,2.785-0.835h7.545
                    c0.357,0,0.661-0.303,0.661-0.661C39.537,14.643,39.233,14.35,38.875,14.35"/>
                <path display="none" fill={mainColor} d="M23.483,18.718c-1.041-0.693-2.266-1.062-3.523-1.062h-7.534
                    c-0.368,0-0.661,0.292-0.661,0.661c0,0.357,0.293,0.661,0.661,0.661h7.534c0.998-0.011,1.962,0.292,2.786,0.834
                    c0.303,0.206,0.715,0.13,0.921-0.173C23.873,19.336,23.787,18.924,23.483,18.718L23.483,18.718z"/>
                <path display="none" fill={mainColor} d="M38.875,17.656h-7.545c-1.246-0.011-2.471,0.369-3.522,1.062
                    c-0.304,0.206-0.38,0.607-0.185,0.921c0.206,0.304,0.618,0.379,0.922,0.173c0.823-0.542,1.799-0.845,2.785-0.834h7.545
                    c0.357,0,0.661-0.304,0.661-0.661C39.537,17.949,39.233,17.656,38.875,17.656"/>
                <path display="none" fill={mainColor} d="M23.483,22.024c-1.041-0.694-2.266-1.062-3.523-1.062h-7.534
                    c-0.368,0-0.661,0.292-0.661,0.661c0,0.357,0.293,0.661,0.661,0.661h7.534c0.998-0.011,1.962,0.292,2.786,0.834
                    c0.303,0.206,0.715,0.13,0.921-0.173C23.873,22.631,23.787,22.23,23.483,22.024L23.483,22.024z"/>
                <path display="none" fill={mainColor} d="M38.875,20.962h-7.545c-1.246-0.011-2.471,0.368-3.522,1.062
                    c-0.304,0.206-0.38,0.607-0.185,0.921c0.206,0.304,0.618,0.379,0.922,0.173c0.823-0.542,1.799-0.845,2.785-0.834h7.545
                    c0.357,0,0.661-0.304,0.661-0.661C39.537,21.255,39.233,20.962,38.875,20.962"/>
                <path display="none" fill={mainColor} d="M23.483,25.331c-1.041-0.693-2.266-1.063-3.523-1.063h-7.534
                    c-0.368,0-0.661,0.293-0.661,0.661c0,0.358,0.293,0.65,0.661,0.65h7.534c0.998,0,1.962,0.304,2.786,0.846
                    c0.303,0.206,0.715,0.13,0.921-0.185C23.873,25.938,23.787,25.537,23.483,25.331L23.483,25.331z"/>
                <path display="none" fill={mainColor} d="M38.875,24.268h-7.545c-1.246-0.011-2.471,0.369-3.522,1.063
                    c-0.304,0.206-0.38,0.607-0.185,0.91c0.206,0.314,0.618,0.391,0.922,0.185c0.823-0.542,1.799-0.846,2.785-0.846h7.545
                    c0.357,0,0.661-0.292,0.661-0.65C39.537,24.561,39.233,24.268,38.875,24.268"/>
                <path display="none" fill={mainColor} d="M23.483,28.636c-1.041-0.693-2.266-1.072-3.523-1.062h-7.534
                    c-0.368,0-0.661,0.292-0.661,0.65c0,0.368,0.293,0.661,0.661,0.661h7.534c0.998,0,1.962,0.304,2.786,0.846
                    c0.303,0.205,0.715,0.13,0.921-0.185C23.873,29.244,23.787,28.842,23.483,28.636L23.483,28.636z"/>
                <path display="none" fill={mainColor} d="M38.875,27.575h-7.545c-1.246-0.011-2.471,0.368-3.522,1.062
                    c-0.304,0.206-0.38,0.607-0.185,0.911c0.206,0.314,0.618,0.39,0.922,0.185c0.823-0.542,1.799-0.846,2.785-0.846h7.545
                    c0.357,0,0.661-0.293,0.661-0.661C39.537,27.867,39.233,27.575,38.875,27.575"/>
                <path display="none" fill={mainColor} d="M19.96,30.88h-7.534c-0.368,0-0.661,0.293-0.661,0.65
                    c0,0.369,0.293,0.661,0.661,0.661h7.534c0.998,0,1.962,0.293,2.786,0.846c0.303,0.206,0.715,0.13,0.921-0.185
                    c0.206-0.303,0.119-0.704-0.185-0.91C22.442,31.25,21.217,30.87,19.96,30.88"/>
                <path display="none" fill={mainColor} d="M38.875,30.88h-7.545c-1.246-0.011-2.471,0.369-3.522,1.063
                    c-0.304,0.206-0.38,0.607-0.185,0.91c0.206,0.314,0.618,0.391,0.922,0.185c0.823-0.542,1.799-0.846,2.785-0.846h7.545
                    c0.357,0,0.661-0.292,0.661-0.661C39.537,31.173,39.233,30.88,38.875,30.88"/>
                <path display="none" fill={mainColor} d="M25.651,6.036c-1.453,0-2.829,1.03-3.859,2.894
                    c-0.932,1.669-1.474,3.815-1.474,5.864c0,2.103,1.095,4.011,2.851,4.986c0.607,0.336,0.965,0.965,0.933,1.626l-0.141,3.154
                    c-0.011,0.304,0.217,0.574,0.531,0.585c0.303,0.011,0.563-0.228,0.585-0.531l0.141-3.154c0.054-1.095-0.52-2.114-1.507-2.656
                    c-1.398-0.78-2.276-2.32-2.276-4.011c0-1.864,0.488-3.805,1.333-5.322c0.813-1.474,1.864-2.32,2.883-2.32
                    c1.008,0,2.06,0.846,2.884,2.32c0.845,1.518,1.322,3.458,1.322,5.322c0,1.691-0.867,3.23-2.276,4.011
                    c-0.976,0.542-1.55,1.561-1.507,2.656l0.933,19.966c0.021,0.38-0.12,0.737-0.38,1.009c-0.249,0.271-0.607,0.411-0.976,0.411
                    c-0.379,0-0.726-0.141-0.986-0.411c-0.26-0.271-0.39-0.629-0.379-1.009l0.683-14.59c0.011-0.304-0.228-0.563-0.531-0.574
                    c-0.304-0.022-0.564,0.217-0.585,0.531L23.18,41.374c-0.033,0.683,0.206,1.333,0.683,1.832c0.466,0.487,1.106,0.759,1.789,0.759
                    s1.312-0.271,1.789-0.759c0.466-0.499,0.715-1.149,0.683-1.832L27.19,21.407c-0.032-0.661,0.325-1.29,0.933-1.626
                    c1.756-0.976,2.851-2.883,2.851-4.986c0-2.049-0.531-4.195-1.463-5.864C28.469,7.066,27.103,6.036,25.651,6.036"/>
                <path display="none" fill={mainColor} d="M28.079,19.802c1.713-0.932,2.754-2.786,2.646-4.726l-0.401-7.664
                    c-0.043-0.77-0.672-1.376-1.452-1.376c-0.401,0-0.77,0.151-1.041,0.434c-0.281,0.282-0.423,0.661-0.423,1.051l0.152,6.634
                    c0.011,0.098-0.044,0.173-0.076,0.195c-0.032,0.032-0.098,0.086-0.195,0.086c-0.151,0-0.281-0.119-0.281-0.271l-0.065-6.818
                    c-0.011-0.727-0.607-1.312-1.333-1.312c-0.716,0-1.312,0.585-1.323,1.312l-0.065,6.818c0,0.152-0.13,0.271-0.282,0.271
                    c-0.098,0-0.163-0.054-0.195-0.086c-0.033-0.022-0.087-0.098-0.076-0.195l0.141-6.667c0.011-0.39-0.13-0.748-0.401-1.03
                    c-0.271-0.271-0.629-0.422-1.019-0.422c-0.748,0-1.376,0.585-1.42,1.344l-0.401,7.696c-0.097,1.962,0.911,3.783,2.645,4.726
                    c0.575,0.314,0.921,0.954,0.889,1.626l-0.542,11.815c-0.022,0.304,0.217,0.563,0.531,0.585c0.304,0.011,0.564-0.228,0.575-0.531
                    l0.553-11.815c0.054-1.095-0.531-2.135-1.474-2.656c-1.344-0.737-2.135-2.157-2.06-3.696l0.401-7.696
                    c0.011-0.163,0.141-0.282,0.304-0.282c0.119,0,0.195,0.054,0.228,0.087c0.033,0.032,0.087,0.108,0.087,0.228l-0.152,6.667
                    c-0.011,0.369,0.13,0.726,0.39,0.997c0.271,0.271,0.618,0.423,0.998,0.423c0.759,0,1.387-0.618,1.398-1.376l0.065-6.818
                    c0-0.119,0.098-0.206,0.206-0.206c0.119,0,0.217,0.087,0.217,0.206l0.064,6.818c0.011,0.759,0.629,1.376,1.398,1.376
                    c0.369,0,0.727-0.152,0.986-0.423c0.271-0.271,0.412-0.628,0.401-0.997L28.524,7.5c0-0.098,0.032-0.185,0.097-0.25
                    c0.065-0.065,0.152-0.098,0.25-0.098c0.184,0,0.336,0.141,0.347,0.325l0.401,7.653c0.075,1.54-0.716,2.959-2.07,3.696
                    c-0.943,0.521-1.518,1.561-1.464,2.656l0.922,19.945c0.021,0.38-0.12,0.737-0.38,1.009c-0.271,0.281-0.618,0.422-1.008,0.411
                    c-0.358,0-0.694-0.151-0.943-0.423c-0.26-0.281-0.401-0.661-0.379-1.04l0.271-5.854c0.011-0.314-0.228-0.574-0.531-0.585
                    c-0.314-0.011-0.574,0.228-0.585,0.53l-0.271,5.865c-0.033,0.672,0.217,1.354,0.683,1.842c0.456,0.499,1.073,0.771,1.735,0.781
                    c0.021,0,0.032,0,0.054,0c0.661,0,1.312-0.271,1.767-0.748c0.478-0.488,0.737-1.16,0.705-1.843L27.19,21.428
                    C27.158,20.756,27.515,20.117,28.079,19.802"/>
                <path display="none" fill={mainColor} d="M23.071,43.682c-3.718,0-7.285-1.583-9.789-4.347
                    c-0.206-0.228-0.184-0.574,0.033-0.78c0.228-0.206,0.574-0.185,0.78,0.043c2.298,2.526,5.572,3.989,8.976,3.989
                    c1.94,0,3.87-0.477,5.583-1.366c1.647-0.855,3.11-2.103,4.217-3.609c0.173-0.249,0.249-0.553,0.205-0.856
                    c-0.054-0.304-0.217-0.574-0.466-0.759c-0.238-0.184-0.553-0.26-0.856-0.206c-0.304,0.044-0.563,0.206-0.748,0.456
                    c-1.843,2.525-4.813,4.032-7.934,4.032c-5.409,0-9.81-4.412-9.81-9.821c0-1.875,0.531-3.696,1.528-5.268
                    c0.976-1.529,2.352-2.764,3.989-3.556c0.412-0.206,0.683-0.672,0.639-1.138s-0.369-0.867-0.813-1.008
                    c-0.282-0.086-0.585-0.054-0.845,0.065c-2.005,0.986-3.708,2.504-4.911,4.39c-1.247,1.951-1.897,4.195-1.897,6.515
                    c0,2.298,0.64,4.531,1.875,6.472c0.152,0.249,0.076,0.596-0.173,0.759c-0.26,0.162-0.596,0.087-0.759-0.174
                    c-1.333-2.113-2.038-4.553-2.038-7.057c0-2.525,0.705-4.976,2.06-7.1c1.312-2.06,3.165-3.718,5.366-4.792
                    c0.542-0.26,1.149-0.292,1.712-0.097c0.575,0.195,1.03,0.596,1.29,1.138c0.271,0.542,0.304,1.149,0.108,1.723
                    c-0.195,0.564-0.596,1.019-1.138,1.29c-1.452,0.705-2.677,1.8-3.544,3.154c-0.889,1.398-1.355,3.014-1.355,4.683
                    c0,4.813,3.913,8.727,8.715,8.727c2.775,0,5.409-1.345,7.045-3.588c0.357-0.478,0.878-0.803,1.475-0.89
                    c0.596-0.097,1.182,0.055,1.669,0.401c0.488,0.357,0.803,0.878,0.899,1.475c0.087,0.596-0.054,1.192-0.411,1.669
                    c-1.203,1.647-2.786,3.014-4.597,3.945C27.288,43.162,25.185,43.682,23.071,43.682"/>
                <path display="none" fill={mainColor} d="M25.423,13.331c-1.93,0-3.501-1.572-3.501-3.512
                    c0-1.929,1.572-3.501,3.501-3.501s3.501,1.572,3.501,3.501C28.924,11.759,27.353,13.331,25.423,13.331 M25.423,7.413
                    c-1.322,0-2.406,1.084-2.406,2.406c0,1.333,1.084,2.407,2.406,2.407s2.406-1.073,2.406-2.407
                    C27.83,8.497,26.746,7.413,25.423,7.413"/>
                <path display="none" fill={mainColor} d="M39.038,33.038c-0.217,0-0.423-0.141-0.509-0.357l-1.106-2.96
                    c-0.325-0.878-1.17-1.474-2.103-1.474h-7.11c-0.304,0-0.553-0.238-0.553-0.542v-5.192c0-0.303,0.249-0.553,0.553-0.553h6.645
                    c0.64,0,1.159-0.521,1.159-1.149c0-0.64-0.52-1.16-1.159-1.16h-6.645c-0.304,0-0.553-0.249-0.553-0.553v-1.116
                    c0-1.236-0.998-2.244-2.233-2.244c-1.236,0-2.233,1.008-2.233,2.244V23.9c0,0.304-0.25,0.553-0.553,0.553
                    c-0.304,0-0.553-0.249-0.553-0.553v-5.918c0-1.843,1.496-3.339,3.339-3.339s3.339,1.496,3.339,3.339v0.574h6.092
                    c1.246,0,2.255,1.008,2.255,2.255c0,1.236-1.009,2.244-2.255,2.244h-6.092v4.098h6.558c1.398,0,2.656,0.878,3.133,2.189
                    l1.105,2.959c0.108,0.282-0.043,0.597-0.325,0.705C39.168,33.027,39.103,33.038,39.038,33.038"/>
                <path display="none" fill={mainColor} d="M39.19,40.018c-0.325,0-0.65-0.075-0.954-0.206
                    c-0.542-0.26-0.954-0.704-1.16-1.268l-2.037-5.409c-0.314-0.889-1.16-1.475-2.104-1.475h-7.512c-1.843,0-3.339-1.495-3.339-3.338
                    V26.1c0-0.304,0.25-0.553,0.553-0.553c0.303,0,0.553,0.249,0.553,0.553v2.223c0,1.235,0.997,2.243,2.233,2.243h7.512
                    c1.398,0,2.656,0.878,3.133,2.189l2.038,5.398c0.217,0.607,0.878,0.911,1.484,0.694c0.282-0.108,0.521-0.314,0.65-0.597
                    c0.131-0.282,0.142-0.596,0.033-0.889l-0.976-2.623c-0.108-0.282,0.032-0.597,0.325-0.705c0.281-0.108,0.596,0.044,0.704,0.325
                    l0.976,2.623c0.206,0.575,0.185,1.182-0.064,1.724c-0.261,0.553-0.705,0.965-1.269,1.171C39.71,39.975,39.45,40.018,39.19,40.018"
                />
                <path display="none" fill={mainColor} d="M40.089,7.673H11.212c-1.615,0-2.883,1.279-2.883,2.894v28.866
                    c0,1.615,1.268,2.895,2.883,2.895h13.279h6.926h8.672c1.615,0,2.884-1.279,2.884-2.895V10.567
                    C42.973,8.952,41.705,7.673,40.089,7.673 M25.651,41.167V29.624h-4.618v-4.629h4.618v-4.032c0-3.295,2.536-6.07,5.767-6.352h5.203
                    v4.618h-4.617c-0.987,0-1.734,0.748-1.734,1.734v4.032h5.777v4.629h-5.777v11.544H25.651z M41.813,39.433
                    c0,0.986-0.748,1.734-1.724,1.734h-8.672V30.772h5.777v-6.927h-5.777v-2.883c0-0.347,0.238-0.585,0.586-0.585h5.767V13.45h-6.406
                    c-3.87,0.347-6.873,3.642-6.873,7.512v2.883h-4.618v6.927h4.618v10.396H11.212c-0.976,0-1.734-0.748-1.734-1.734V10.567
                    c0-0.986,0.759-1.734,1.734-1.734h28.877c0.976,0,1.724,0.748,1.724,1.734V39.433z"/>
                <path display="none" fill={mainColor} d="M40.079,12.876h-4.032c0.542-0.662,0.867-1.518,0.867-2.45
                    c0-1.485-0.824-2.818-2.157-3.48c-1.333-0.661-2.895-0.52-4.087,0.379L27.71,9.559c-0.563-0.445-1.279-0.716-2.06-0.716
                    s-1.496,0.271-2.06,0.716l-2.97-2.233c-1.181-0.899-2.753-1.041-4.076-0.379c-1.333,0.662-2.157,1.995-2.157,3.48
                    c0,0.932,0.325,1.788,0.867,2.45h-4.033c-1.355,0-2.45,1.105-2.45,2.46v4.325c0,1.301,1.019,2.374,2.298,2.45v18.33
                    c0,1.67,1.366,3.024,3.035,3.024h23.088c1.67,0,3.024-1.354,3.024-3.024v-5.127c0-0.401-0.325-0.727-0.726-0.727
                    c-0.391,0-0.716,0.325-0.716,0.727v5.127c0,0.867-0.716,1.583-1.583,1.583h-8.227V22.111h9.81v6.709
                    c0,0.401,0.325,0.727,0.716,0.727c0.4,0,0.726-0.325,0.726-0.727v-6.709c1.29-0.076,2.31-1.149,2.31-2.45v-4.325
                    C42.529,13.981,41.422,12.876,40.079,12.876 M31.547,8.475c0.748-0.575,1.713-0.662,2.559-0.239
                    c0.856,0.423,1.366,1.247,1.366,2.19c0,1.354-1.106,2.45-2.45,2.45h-4.054V12.16c0-0.531-0.131-1.029-0.348-1.474L31.547,8.475z
                     M23.776,14.317h3.75v6.352h-3.75V14.317z M25.651,10.285c1.03,0,1.875,0.834,1.875,1.875v0.716h-3.75V12.16
                    C23.776,11.12,24.61,10.285,25.651,10.285 M15.83,10.426c0-0.943,0.51-1.767,1.355-2.19c0.846-0.423,1.81-0.336,2.569,0.239
                    l2.927,2.211c-0.228,0.445-0.347,0.943-0.347,1.474v0.716H18.28C16.925,12.876,15.83,11.781,15.83,10.426 M10.215,19.662v-4.325
                    c0-0.563,0.444-1.019,1.008-1.019h11.111v6.352H11.798h-0.575C10.66,20.669,10.215,20.214,10.215,19.662 M12.524,40.441v-18.33
                    h9.81v19.913h-8.228C13.229,42.024,12.524,41.308,12.524,40.441 M23.776,22.111h3.75v19.913h-3.75V22.111z M41.086,19.662
                    c0,0.553-0.455,1.008-1.008,1.008h-0.585H28.968v-6.352h11.11c0.553,0,1.008,0.456,1.008,1.019V19.662z"/>
                <path display="none" fill={mainColor} d="M39.494,31.346c-0.185,0-0.369,0.076-0.51,0.217
                    c-0.13,0.131-0.206,0.314-0.206,0.51c0,0.185,0.076,0.369,0.206,0.51c0.141,0.13,0.325,0.206,0.51,0.206
                    c0.194,0,0.379-0.076,0.509-0.206c0.142-0.141,0.217-0.325,0.217-0.51c0-0.195-0.075-0.379-0.217-0.51
                    C39.873,31.422,39.688,31.346,39.494,31.346"/>
                <path display="none" fill={mainColor} d="M38.03,12.626C34.724,9.32,30.323,7.5,25.651,7.5
                    c-3.555,0-6.98,1.062-9.907,3.067c-0.314,0.217-0.391,0.64-0.174,0.954c0.206,0.303,0.64,0.39,0.943,0.173
                    c2.699-1.854,5.854-2.829,9.138-2.829c8.899,0,16.13,7.241,16.13,16.14c0,8.889-7.23,16.13-16.13,16.13s-16.14-7.241-16.14-16.13
                    c0-3.284,0.976-6.439,2.829-9.138c0.217-0.314,0.141-0.737-0.173-0.954c-0.314-0.206-0.737-0.13-0.954,0.185
                    c-2.005,2.916-3.067,6.341-3.067,9.907c0,4.672,1.821,9.063,5.127,12.368S20.968,42.5,25.651,42.5
                    c4.672,0,9.073-1.821,12.379-5.127c3.307-3.306,5.127-7.696,5.127-12.368C43.157,20.323,41.336,15.933,38.03,12.626"/>
                <path display="none" fill={mainColor} d="M13.749,13.786c0.184,0,0.357-0.065,0.488-0.195
                    c0.271-0.271,0.271-0.705,0-0.965c-0.271-0.271-0.705-0.271-0.965,0l0,0c-0.271,0.26-0.271,0.694,0,0.965
                    C13.402,13.721,13.576,13.786,13.749,13.786"/>
                <path display="none" fill={mainColor} d="M20.155,29.743c-0.098-0.369-0.477-0.586-0.835-0.488
                    c-0.368,0.098-0.585,0.478-0.488,0.835c0.824,3.078,3.631,5.235,6.818,5.235c3.187,0,5.983-2.157,6.808-5.235
                    c0.098-0.357-0.119-0.737-0.478-0.835c-0.368-0.098-0.736,0.119-0.834,0.488c-0.673,2.482-2.927,4.205-5.496,4.205
                    C23.082,33.948,20.816,32.225,20.155,29.743"/>
                <path display="none" fill={mainColor} d="M29.379,24.735c0.206-0.792,0.922-1.345,1.734-1.345
                    c0.824,0,1.54,0.553,1.745,1.345c0.087,0.303,0.358,0.498,0.662,0.498c0.054,0,0.119,0,0.173-0.021
                    c0.369-0.098,0.586-0.466,0.488-0.835c-0.369-1.387-1.626-2.352-3.068-2.352c-1.431,0-2.688,0.965-3.057,2.352
                    c-0.098,0.369,0.119,0.737,0.488,0.835C28.903,25.309,29.282,25.092,29.379,24.735"/>
                <path display="none" fill={mainColor} d="M20.177,22.024c-1.431,0-2.688,0.965-3.057,2.352
                    c-0.108,0.369,0.108,0.737,0.477,0.835s0.737-0.119,0.834-0.477c0.217-0.792,0.932-1.345,1.745-1.345s1.528,0.553,1.745,1.345
                    c0.076,0.303,0.358,0.498,0.662,0.498c0.054,0,0.108,0,0.173-0.021c0.369-0.098,0.585-0.466,0.488-0.835
                    C22.865,22.989,21.608,22.024,20.177,22.024"/>
                <path display="none" fill={mainColor} d="M39.537,25.97c0,0.434-0.347,0.78-0.78,0.78c-0.444,0-0.792-0.347-0.792-0.78
                    c0-0.444,0.348-0.791,0.792-0.791C39.19,25.179,39.537,25.526,39.537,25.97"/>
                <path display="none" fill={mainColor} d="M13.521,14.924c0,0.434-0.358,0.792-0.792,0.792s-0.791-0.358-0.791-0.792
                    s0.357-0.791,0.791-0.791S13.521,14.491,13.521,14.924"/>
                <path display="none" fill={mainColor} d="M45.575,20.919c-1.496-0.152-2.971-0.228-4.401-0.228
                    c-3.815,0-8.878,0.553-11.371,3.198c-2.124,2.243-1.951,5.788-0.228,8.444c-1.182,1.268-2.243,2.558-3.122,3.805v-2.743
                    c0-2.579-0.53-5.105-1.582-7.512c1.626-1.713,2.666-3.957,2.786-6.135c0.151-2.656-0.705-4.802-2.559-6.396
                    C19.32,8.367,5.771,10.794,5.641,10.827c-0.271,0.054-0.499,0.25-0.596,0.509c-0.098,0.26-0.043,0.553,0.119,0.77l1.756,2.233
                    c2.038,2.623,2.731,4.943,3.35,6.98c0.802,2.721,1.507,5.063,5.019,6.536c2.515,1.063,5.745,1.009,8.303-0.856
                    c0.824,2.07,1.247,4.206,1.247,6.396v5.594c0,0.368,0.26,0.693,0.618,0.78c0.065,0.021,0.13,0.021,0.195,0.021
                    c0.293,0,0.574-0.162,0.716-0.434c0.91-1.745,2.396-3.772,4.228-5.745c1.506,1.583,3.176,2.244,5.289,2.244
                    c3.61,0,5.897-2.634,6.612-6.049c0.65-2.211,1.398-4.715,3.621-7.587h0.011c0.184-0.228,0.228-0.542,0.108-0.813
                    S45.867,20.94,45.575,20.919 M15.917,26.372c-2.808-1.182-3.328-2.905-4.108-5.507c-0.618-2.07-1.387-4.65-3.621-7.523
                    l-0.889-1.138C10.725,11.77,19.906,11,24.047,14.567c1.474,1.268,2.125,2.938,2.005,5.095c-0.098,1.583-0.824,3.295-1.951,4.65
                    c-1.886-3.469-4.846-6.493-8.444-8.618c-0.379-0.228-0.878-0.108-1.095,0.282c-0.228,0.379-0.108,0.878,0.282,1.105
                    c3.479,2.06,6.331,5.03,8.054,8.412C20.795,27.184,18.085,27.282,15.917,26.372 M40.935,29.384
                    c-0.011,0.022-0.011,0.044-0.021,0.065c-0.293,1.441-1.377,4.791-5.03,4.791c-1.712,0-2.98-0.51-4.173-1.788
                    c1.658-1.648,3.393-3.09,4.932-4.098c0.38-0.238,0.488-0.737,0.238-1.116c-0.238-0.369-0.736-0.478-1.116-0.239
                    c-1.582,1.03-3.339,2.482-5.029,4.142c-1.116-1.951-1.29-4.521,0.238-6.146c2.103-2.222,6.699-2.688,10.2-2.688
                    c0.922,0,1.864,0.033,2.829,0.098C42.214,25.048,41.52,27.422,40.935,29.384"/>
                <path display="none" fill={mainColor} d="M34.941,34.523H7.625V15.478h4.423c0.314,0,0.563-0.25,0.563-0.564
                    c0-0.303-0.25-0.563-0.563-0.563H7.061c-0.304,0-0.553,0.26-0.553,0.563v20.173c0,0.304,0.25,0.563,0.553,0.563h4.889l2.092,4.51
                    c0.13,0.271,0.466,0.4,0.748,0.271l2.049-0.954l6.211,4.434c0.25,0.185,0.607,0.119,0.781-0.13l5.81-8.13h5.301
                    c0.303,0,0.563-0.26,0.563-0.563C35.504,34.772,35.244,34.523,34.941,34.523 M13.185,35.65h9.257l-7.62,3.523L13.185,35.65z
                     M23.245,42.674l-5.236-3.74l7.1-3.284h3.144L23.245,42.674z"/>
                <path display="none" fill={mainColor} d="M44.23,14.35h-4.889L37.25,9.841C37.13,9.57,36.784,9.439,36.501,9.57
                    l-2.049,0.954l-6.2-4.433c-0.249-0.185-0.607-0.12-0.791,0.13l-5.799,8.13h-7.36c-0.314,0-0.563,0.26-0.563,0.563
                    c0,0.314,0.249,0.564,0.563,0.564h7.642l0,0l0,0h17.04l0,0c0,0,0,0,0.011,0h4.672v19.045h-6.482c-0.304,0-0.563,0.249-0.563,0.563
                    c0,0.304,0.26,0.563,0.563,0.563h7.046c0.314,0,0.563-0.26,0.563-0.563V14.914C44.793,14.61,44.544,14.35,44.23,14.35
                     M38.106,14.35H28.86l7.609-3.523L38.106,14.35z M28.057,7.326l5.225,3.74l-7.089,3.285h-3.154L28.057,7.326z"/>
                <path display="none" fill={mainColor} d="M25.651,30.371c2.948,0,5.354-2.406,5.354-5.365s-2.406-5.355-5.354-5.355
                    c-2.959,0-5.366,2.396-5.366,5.355S22.692,30.371,25.651,30.371 M25.651,20.767c2.331,0,4.238,1.908,4.238,4.238
                    c0,2.342-1.907,4.238-4.238,4.238c-2.341,0-4.238-1.896-4.238-4.238C21.413,22.675,23.31,20.767,25.651,20.767"/>
                <path display="none" fill={mainColor} d="M20.892,32.897h17.105c0.314,0,0.563-0.261,0.563-0.563
                    c0-1.421,1.431-2.808,2.884-2.808c0.303,0,0.563-0.25,0.563-0.553v-7.946c0-0.304-0.261-0.553-0.563-0.553
                    c-1.453,0-2.884-1.388-2.884-2.808c0-0.304-0.249-0.563-0.563-0.563H12.99c-0.314,0-0.564,0.26-0.564,0.563
                    c0,1.507-1.203,2.818-2.569,2.818c-0.314,0-0.563,0.249-0.563,0.563v7.902c0,0.314,0.249,0.564,0.563,0.564
                    c1.366,0,2.569,1.312,2.569,2.818c0,0.303,0.25,0.563,0.564,0.563h5.658c0.304,0,0.563-0.261,0.563-0.563
                    c0-0.314-0.26-0.564-0.563-0.564H13.51c-0.119-0.791-0.477-1.55-1.019-2.157c-0.575-0.639-1.301-1.04-2.082-1.17v-6.883
                    c0.781-0.13,1.507-0.531,2.082-1.171c0.542-0.607,0.899-1.366,1.019-2.157h23.978c0.282,1.615,1.745,3.046,3.393,3.317v6.905
                    c-1.647,0.271-3.11,1.701-3.393,3.316H20.892c-0.314,0-0.563,0.25-0.563,0.564C20.329,32.636,20.578,32.897,20.892,32.897"/>
                <path display="none" fill={mainColor} d="M39.602,18.87c-0.065-0.033-0.142-0.054-0.217-0.065
                    c1.582-1.431,2.46-3.479,2.406-5.604c0-3.762-2.504-6.829-5.583-6.829c-3.089,0-5.593,3.067-5.593,6.829
                    c-0.044,2.125,0.823,4.173,2.406,5.604c-0.076,0.011-0.141,0.032-0.217,0.065c-2.525,0.423-4.444,2.515-4.65,5.073
                    c-1.496-1.073-3.512-1.073-5.008,0c-0.206-2.558-2.135-4.65-4.661-5.073c-0.065-0.033-0.13-0.054-0.206-0.065
                    c1.572-1.431,2.45-3.479,2.396-5.604c0-3.762-2.504-6.829-5.582-6.829c-3.079,0-5.593,3.067-5.593,6.829
                    c-0.043,2.125,0.834,4.173,2.406,5.604c-0.076,0.011-0.141,0.032-0.206,0.065c-2.699,0.444-4.672,2.775-4.683,5.506v6.829
                    c0.011,6.861,5.561,12.411,12.422,12.423h3.729c0.976,0,1.897-0.467,2.482-1.258c0.574,0.791,1.496,1.258,2.482,1.258h3.729
                    c6.851-0.012,12.412-5.562,12.412-12.423v-6.829C44.274,21.645,42.29,19.314,39.602,18.87 M38.604,20.03
                    c-0.281,1.095-1.268,1.865-2.396,1.865c-1.138,0-2.124-0.77-2.396-1.865H38.604z M31.862,13.201c0-3.079,1.951-5.583,4.347-5.583
                    s4.347,2.504,4.347,5.583c0,3.078-1.951,5.593-4.347,5.593S31.862,16.279,31.862,13.201 M25.38,24.41
                    c0.325,0.434,0.249,1.051-0.174,1.397c-0.163,0.152-0.228,0.38-0.163,0.597c0.054,0.217,0.228,0.391,0.433,0.444
                    c0.229,0.055,0.456-0.011,0.607-0.162c0.585-0.531,0.846-1.323,0.683-2.093c1.485,0.563,2.31,2.157,1.919,3.686
                    c-0.391,1.539-1.875,2.537-3.447,2.32c-1.571-0.206-2.731-1.572-2.699-3.154C22.583,25.862,23.797,24.55,25.38,24.41
                     M27.515,31.834c1.02,0,1.854,0.835,1.864,1.854v3.631c-3.49-0.683-5.875-3.393-6.948-4.899c0.347-0.368,0.834-0.585,1.355-0.585
                    H27.515z M25.651,38.685c-0.585-0.791-1.507-1.269-2.482-1.269h-1.247v-3.609c1.29,1.669,2.949,3.014,4.834,3.945
                    C26.323,37.969,25.944,38.284,25.651,38.685 M17.489,20.03c-0.282,1.095-1.269,1.865-2.396,1.865c-1.127,0-2.114-0.77-2.396-1.865
                    H17.489z M10.747,13.201c0-3.079,1.951-5.583,4.347-5.583c2.396,0,4.347,2.504,4.347,5.583c0,3.078-1.951,5.593-4.347,5.593
                    C12.697,18.794,10.747,16.279,10.747,13.201 M8.264,31.206v-6.829c0-1.951,1.301-3.664,3.187-4.184
                    c0.368,1.713,1.886,2.949,3.642,2.949c1.756,0,3.273-1.236,3.642-2.949c1.875,0.521,3.187,2.233,3.187,4.184v0.878
                    c-1.084,1.811-0.715,4.13,0.878,5.507c-0.672,0.228-1.258,0.683-1.637,1.279c-0.021,0.032-0.032,0.064-0.054,0.098
                    c-0.282,0.477-0.422,1.008-0.434,1.55v3.729H19.44c-3.425,0-6.211-2.774-6.211-6.211v-5.582c0-0.347-0.282-0.629-0.618-0.629
                    c-0.347,0-0.629,0.282-0.629,0.629v5.582c0.011,4.119,3.339,7.447,7.458,7.458v3.718C13.272,42.381,8.264,37.384,8.264,31.206
                     M23.168,42.381h-2.493v-3.718h2.493c1.019,0,1.854,0.835,1.854,1.864C25.022,41.558,24.188,42.381,23.168,42.381 M28.751,42.381
                    h-0.618c-1.03,0-1.864-0.823-1.864-1.854c0-1.029,0.834-1.864,1.864-1.864h2.482v3.718H28.751z M31.862,42.381v-3.718
                    c4.108-0.011,7.447-3.339,7.447-7.458v-5.582c0-0.347-0.282-0.629-0.618-0.629c-0.347,0-0.629,0.282-0.629,0.629v5.582
                    c0,3.437-2.774,6.211-6.2,6.211h-1.246v-3.729c0-1.322-0.856-2.504-2.125-2.927c1.604-1.377,1.973-3.696,0.889-5.507v-0.878
                    c0-1.951,1.301-3.664,3.188-4.184c0.368,1.713,1.886,2.949,3.642,2.949s3.273-1.236,3.643-2.949
                    c1.875,0.521,3.176,2.233,3.187,4.184v6.829C43.027,37.384,38.03,42.381,31.862,42.381"/>
                <path display="none" fill={mainColor} d="M30.138,22.111c0-1.94-1.582-3.523-3.533-3.523h-4.802
                    c-0.357,0-0.64,0.292-0.64,0.64v5.777c0,0.347,0.282,0.64,0.64,0.64h4.802C28.556,25.645,30.138,24.062,30.138,22.111
                     M22.442,19.867h4.163c1.246,0,2.244,1.008,2.244,2.244c0,1.247-0.998,2.244-2.244,2.244h-4.163V19.867z"/>
                <path display="none" fill={mainColor} d="M33.987,22.111c0-4.054-3.317-7.371-7.382-7.371h-8.65
                    c-0.347,0-0.639,0.292-0.639,0.64v19.241c0,0.347,0.292,0.639,0.639,0.639h3.848c0.358,0,0.64-0.292,0.64-0.639v-5.139h4.163
                    C30.669,29.482,33.987,26.176,33.987,22.111 M21.803,28.203c-0.357,0-0.64,0.293-0.64,0.64v5.128h-2.569V16.03h8.011
                    c3.36,0,6.092,2.731,6.092,6.081c0,3.36-2.731,6.091-6.092,6.091H21.803z"/>
                <path display="none" fill={mainColor} d="M25.651,40.126c0.347,0,0.64,0.282,0.64,0.64c0,0.358-0.293,0.64-0.64,0.64
                    c-0.357,0-0.64-0.281-0.64-0.64C25.011,40.409,25.293,40.126,25.651,40.126"/>
                <path display="none" fill={mainColor} d="M39.494,8.594H11.798c-1.409,0-2.558,1.149-2.558,2.558v27.696
                    c0,1.409,1.149,2.558,2.558,2.558h10.97c0.347,0,0.64-0.281,0.64-0.64c0-0.357-0.293-0.64-0.64-0.64h-10.97
                    c-0.705,0-1.279-0.574-1.279-1.278V11.152c0-0.705,0.575-1.279,1.279-1.279h27.696c0.704,0,1.278,0.575,1.278,1.279v27.696
                    c0,0.704-0.574,1.278-1.278,1.278H28.535c-0.358,0-0.64,0.282-0.64,0.64c0,0.358,0.281,0.64,0.64,0.64h10.959
                    c1.409,0,2.568-1.148,2.568-2.558V11.152C42.062,9.743,40.903,8.594,39.494,8.594"/>
                <path fill={mainColor} d="M19.819,32.539h-7.046l2.764-5.474l2.569,2.092c0.499,0.391,1.214-0.064,1.041-0.683
                    l-2.59-9.073c-0.087-0.314-0.391-0.521-0.716-0.466l-9.094,1.301c-0.607,0.076-0.759,0.878-0.239,1.203l2.71,1.626l-2.2,3.968
                    c-0.542,0.954-0.802,2.016-0.802,3.1c0,0.813,0.152,1.626,0.455,2.374l1.897,5.203c0.759,2.688,2.017,3.924,4.13,3.924h7.133
                    c0.357,0,0.64-0.292,0.64-0.65v-7.794C20.458,32.821,20.177,32.539,19.819,32.539 M7.863,32.019
                    c-0.238-0.597-0.357-1.236-0.357-1.897c0-0.867,0.217-1.713,0.628-2.482l2.515-4.531c0.173-0.292,0.076-0.694-0.239-0.867
                    l-1.68-0.997l6.71-0.954l1.875,6.558l-1.604-1.301c-0.325-0.271-0.802-0.162-0.986,0.206L9.5,36.138L7.863,32.019z M19.169,40.311
                    h-6.482c-1.387,0-2.525-1.073-2.59-2.438l2.038-4.033h7.035V40.311z"/>
                <path fill={mainColor} d="M37.564,10.079l-2.461,1.984l-2.97-3.458c-0.705-0.824-1.615-1.463-2.623-1.832
                    c-0.771-0.282-1.583-0.412-2.385-0.401h-5.778c-2.84-0.033-4.13,0.509-4.856,2.493l-2.482,6.677
                    c-0.12,0.336,0.043,0.705,0.379,0.834l7.295,2.71c0.325,0.119,0.694-0.043,0.834-0.379l2.439-6.602l4.184,4.488l-2.861,1.68
                    c-0.542,0.325-0.336,1.16,0.293,1.203l9.408,0.715c0.325,0.033,0.629-0.184,0.683-0.509l1.93-8.964
                    C38.735,10.123,38.041,9.7,37.564,10.079 M21.521,17.646l-6.07-2.244l2.244-6.07c0.477-1.301,1.886-1.983,3.187-1.583l3.079,3.306
                    L21.521,17.646z M35.504,18.87l-6.797-0.52l1.778-1.063c0.357-0.217,0.434-0.705,0.141-1.008l-7.924-8.498l4.444-0.098
                    c0.64-0.011,1.29,0.098,1.896,0.314c0.813,0.303,1.539,0.802,2.104,1.463l3.371,3.935c0.228,0.26,0.617,0.304,0.899,0.087
                    l1.518-1.236L35.504,18.87z"/>
                <path fill={mainColor} d="M45.033,29.645c-0.012-0.011-0.012-0.021-0.022-0.033c0-0.011-4.553-7.143-4.553-7.143
                    c-0.194-0.303-0.606-0.401-0.899-0.195l-5.843,3.892c-0.314,0.217-0.379,0.64-0.141,0.942l4.347,5.431h-6.417v-2.59
                    c0-0.597-0.737-0.879-1.139-0.434l-5.842,6.471c0,0,0,0.011-0.021,0.011c-0.011,0.011-0.021,0.033-0.033,0.044l-0.011,0.011
                    c-0.011,0.021-0.021,0.043-0.032,0.054c-0.011,0.011-0.011,0.011-0.011,0.021c-0.011,0.012-0.011,0.022-0.011,0.033
                    c-0.021,0.011-0.021,0.032-0.021,0.043s-0.011,0.011-0.011,0.032c-0.011,0.012-0.011,0.022-0.011,0.033s-0.011,0.011-0.011,0.021
                    c0,0.021-0.011,0.032-0.011,0.043s0,0.011-0.011,0.022v0.032v0.043v0.033v0.032v0.032v0.044c0,0.011,0,0.011,0.011,0.021
                    c0,0.011,0.011,0.021,0.011,0.032s0.011,0.011,0.011,0.021c0,0.021,0.011,0.033,0.011,0.044s0.011,0.011,0.011,0.032
                    c0.021,0.011,0.021,0.021,0.021,0.032c0.011,0.011,0.011,0.033,0.011,0.044c0.011,0.011,0.011,0.011,0.011,0.021
                    c0.011,0.011,0.011,0.032,0.032,0.043l0.011,0.011c0.011,0.011,0.022,0.044,0.033,0.055c0,0,0,0.011,0.021,0.011l5.832,6.482
                    c0.391,0.434,1.128,0.151,1.128-0.434v-2.591h4.78c1.452,0,2.84-0.542,3.892-1.55c1.701-1.626,3.197-3.838,4.292-6.526
                    c0.021-0.054,0.249-0.585,0.293-0.704c0.108-0.238,0.195-0.488,0.26-0.737c0.044-0.185,0.065-0.368,0.076-0.563
                    c0.021-0.151,0.021-0.26,0.021-0.368C45.097,29.862,45.076,29.764,45.033,29.645 M39.254,37.818C38.453,38.587,37.379,39,36.263,39
                    h-5.431c-0.358,0-0.65,0.293-0.65,0.65v1.55l-4.314-4.802l4.314-4.791v1.561c0,0.358,0.292,0.64,0.65,0.64h10.362
                    c0.391,0,0.77-0.043,1.117-0.151C41.466,35.346,40.415,36.723,39.254,37.818 M43.287,31.477c-0.011,0.011-0.011,0.011-0.011,0.021
                    c-0.466,0.618-1.225,1.029-2.06,1.029H39.58l-4.553-5.68l4.694-3.133l4.064,6.396C43.753,30.631,43.58,31.086,43.287,31.477"/>
            </g>
        </svg>
    );
};

export default Recycling;