import React from 'react';

const Odpuszczaj = ({mainColor}) => {
    return (
        <svg
            version="1.1" xmlns="http://www.w3.org/2000/svg"
            id="svg_odpuszczaj"
            x="0px" y="0px"
            width="82.87px" height="80.2px" viewBox="853.36 646 82.87 80.2">
            <path fill={mainColor} d="M875.32,669.14c-0.9-0.89-2.35-0.89-3.24,0l-15.34,15.33c-0.43,0.43-0.67,1-0.68,1.61c0,0.61,0.24,1.19,0.67,1.62
	l15.35,15.36c0.9,0.9,2.35,0.9,3.24,0s0.9-2.35,0-3.24l-11.42-11.43h44.96c1.26,0,2.29-1.03,2.29-2.29s-1.03-2.29-2.29-2.29H863.9
	l11.42-11.42C876.21,671.49,876.21,670.04,875.32,669.14z"/>
            <path fill={mainColor} d="M924.45,646.1h-59.32c-5.71,0.01-10.34,4.63-10.35,10.35v7.83c-0.03,1.25,0.93,2.3,2.17,2.39c1.27,0.07,2.35-0.91,2.41-2.17
	c0-0.04,0-0.08,0-0.12v-7.93c0-3.18,2.58-5.75,5.76-5.76h59.32c3.18,0,5.75,2.58,5.76,5.76v9.83c-0.07,1.27,0.91,2.35,2.17,2.42
	c1.27,0.07,2.35-0.91,2.41-2.17c0-0.08,0-0.16,0-0.24v-9.83C934.79,650.73,930.16,646.1,924.45,646.1z"/>
            <path fill={mainColor} d="M932.51,677.5c-1.27,0-2.29,1.03-2.3,2.3v35.96c0,3.18-2.58,5.75-5.76,5.76h-59.32c-3.18,0-5.75-2.58-5.76-5.76v-7.83
	c0.03-1.25-0.93-2.29-2.17-2.39c-0.63-0.04-1.25,0.2-1.7,0.63c-0.46,0.43-0.72,1.03-0.72,1.66v7.93
	c0.01,5.71,4.63,10.34,10.35,10.35h59.32c5.71-0.01,10.34-4.63,10.35-10.35V679.8C934.8,678.53,933.77,677.5,932.51,677.5z"/>
        </svg>
    );
};

export default Odpuszczaj;