import React, {Component} from 'react';
import {connect} from 'react-redux';

//actions
import { toggleLightbox, selectedGallery } from "../../../../../actions/lightboxActions";
import {togglePlayer,selectedVideo} from "../../../../../actions/playerActions";

//components
import PageHeader from "../../../../PageHeader";


class Participants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemHovered:null
        };
        this.handleLightbox = this.handleLightbox.bind(this);
        this.handleVideoPlayer = this.handleVideoPlayer.bind(this);
    }

    handleLightbox = (
        tytul,
        opis,
        miniatura,
        miniatura_xl,
        galeria
    ) => {
        this.props.openLightbox();
        this.props.selectedGallery({
            title:tytul,
            text:opis,
            imgList: galeria ? galeria : [miniatura_xl]
        });
        window.scrollTo(0,0)
    };

    handleVideoPlayer = video => {
        this.props.openPlayer();
        this.props.selectedVideo(video);
    };

    render() {
        const {
            uczestnicy,
            uczestnicyData,
            slogan,
            greenGradient,
            green
        } = this.props;
        return (
            <div className="row participants">

                <div className="col col-xs-12 txt">
                    <PageHeader
                        title={uczestnicyData.tytul}
                        text={uczestnicyData.opis} />
                </div>

                <div className="col col-xs-11 list">
                    <div className="row">
                        {
                            uczestnicy &&
                            uczestnicy.map(
                                (item,index) =>
                                    <div
                                        key={index}
                                        className="col col-xs-12 col-sm-6 col-md-4 item"
                                        onClick={
                                            ()=> {
                                                switch(item.rodzaj_wpisu){
                                                    case 'is_video':
                                                        return this.handleVideoPlayer({ vimeoId:item.vimeo_id} );
                                                    default:
                                                        return this.handleLightbox(
                                                            item.tytul,
                                                            item.opis,
                                                            item.miniatura,
                                                            item.miniatura,
                                                            item.galeria.length>0 ? item.galeria.map((item)=>item.zdjecie) : null
                                                        );
                                                }
                                            }
                                        }
                                        onMouseOver={()=>this.setState({itemHovered:index})}
                                        style={index === this.state.itemHovered ? greenGradient : {}}
                                    >
                                        <div className="row">
                                            <div className="col col-xs-4 rotate arrow-container">
                                                <div
                                                    style={index === this.state.itemHovered ? {borderColor:`transparent transparent transparent ${green}`} : {}}
                                                    className="arrow"/>
                                                <p
                                                    style={index === this.state.itemHovered ? {color:'#f6b938'} : {}}
                                                    dangerouslySetInnerHTML={{__html:item.tytul}}
                                                />
                                            </div>
                                            <div className="col col-xs-8 miniatura">
                                                <img src={item.miniatura}/>
                                            </div>
                                        </div>
                                    </div>
                            )
                        }
                    </div>
                </div>
                <div className="col col-xs-1 slogan">
                    <img src={slogan} />
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch( togglePlayer() ),
    selectedVideo: video => dispatch( selectedVideo(video) ),
    openLightbox: () => dispatch( toggleLightbox() ),
    selectedGallery: gallery => dispatch( selectedGallery(gallery) ),
});


export default connect(null,mapDispatchToProps)(Participants);