import React from 'react';

const ScrollerLabel = ({mainColor}) => {

    return (
        <svg version="1.1" id="scroller_label" xmlns="http://www.w3.org/2000/svg" width="18.375px" height="18px" viewBox="0 0 18.375 18" >
            <circle fill={mainColor} cx="9.375" cy="9.125" r="9"/>
        </svg>
    );
};

export default ScrollerLabel;