import React,{Fragment,Component} from 'react';
import {connect} from "react-redux";

//components
import Player from "../../Player";
import Tiles from "../../Tiles";
import ExternalButton from "../../ExternalButton";
import GalleryVideos from "../../GalleryVideos";
import Gallery from "../../Gallery";
import Back from "../../Back";

import Cover from "./Sections/Cover";
import Info from "./Sections/Info";
import Harmonogram from "./Sections/Harmonogram";
import Liczby from "./Sections/Liczby";
import Mapy from "./Sections/Mapy";
import Partners from "./Sections/Partners";
import Catalogue from "./Sections/Catalogue";

//svg
import VideoCamera from "../../../svg/VideoCamera";
import PhotoCamera from "../../../svg/PhotoCamera";

//tools
import classnames from "classnames";
import Countdown from 'react-countdown';
import {paths} from "../../../config/paths";

class Events extends Component  {

    render() {
        const {
            pageData,
            pageOptions,
            statistics,
            playerOpened
        } = this.props;

        const {
            tytul_listy,
            lista_obraz,
        } = pageData;

        const seasonGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_naglowki}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`};

        const seasonTxtColor = pageOptions.kolor_sezonowy_tekst;

        const eventGradient = {
            background: `linear-gradient( 
                29deg, 
                #f7b35c 53%, 
                #f49d52 85% 
            )`};

        const eventGradientTransp = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_event_transp}deg, 
                ${pageOptions.kolor_1_event_transp} 53%, 
                ${pageOptions.kolor_2_event_transp} 85% 
            )`};

        const greenGradient = {
            background: `linear-gradient( 
                45deg, 
                ${pageOptions.kolor_1_news} 53%, 
                ${pageOptions.kolor_2_news} 85% 
            )`};
        const navyGradient = {
            background: `linear-gradient( 
                45deg, 
                 #38516b 53%, 
                #2c4259 85% 
            )`};

        const pinkGradientSolid = {
            background: `linear-gradient( 
                32deg, 
                rgb(255,203,211) 53%, 
                rgb(245,177,187) 85%
            )`};

        const orangeGradientSolid = {
            background: `linear-gradient( 
                32deg, 
                #f3933f 53%, 
                #f3933f 85%
            )`};



        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
                // Render a completed state
                return <div className={
                    completed ? "btm finished" : "btm"
                }>
                        <span dangerouslySetInnerHTML={{__html:pageData.komunikat_zakonczenia}} />
                    </div>;
            } else {
                // Render a countdown
                return <Fragment>
                    {
                        pageData.etykieta_odliczania &&
                        <div className="top" dangerouslySetInnerHTML={{__html:pageData.etykieta_odliczania}} />
                    }
                    <div className="btm">
                        <div
                            className="counter-display"
                            style={{
                                width: `${pageData.pokaz_szerokosc}px`,
                                height: `${pageData.pokaz_wysokosc}px`,
                                backgroundImage: pageData.pokaz_tlo_odliczania ? `url(${pageData.pokaz_tlo_odliczania})` : "none",
                                backgroundSize:"100%",
                                backgroundPosition: "0 0"
                            }}
                        >
                            <p>
                                <span>{days}</span> dni
                                { pageData.pokaz_odliczanie_format.includes("godz") && <Fragment><span>{hours}</span> godz</Fragment> }
                                { pageData.pokaz_odliczanie_format.includes("min") && <Fragment><span>{minutes}</span> min</Fragment> }
                                { pageData.pokaz_odliczanie_format.includes("sek") && <Fragment><span>{seconds}</span> sek</Fragment> }
                            </p>
                        </div>;
                    </div>
                </Fragment>
            }
        };

        return (
            <Fragment>
                { playerOpened && <Player /> }
                {
                    pageData.pokaz_przycisk_wstecz &&
                    <Back
                        bg={pageOptions.zielen}
                        link={ paths.home + pageData.przycisk_url}
                    />
                }

                <article className={classnames(
                    "page event content",{
                        "news" : pageData.wyglad === "strona_newsa"
                    }
                )}>

                        <div className="boxed">

                            <h2 title={pageData.tytul}>
                                <Cover pageData={pageData}/>
                            </h2>

                            {
                                !pageData.tylko_banner &&
                                <Fragment>
                                    {
                                        pageData.zasady &&
                                        <Liczby
                                            liczby={pageData.zasady}
                                            colors={{
                                                csrGradient: eventGradient,
                                                green: pageOptions.zielen,
                                                greenGradient: greenGradient
                                            }}
                                            statistics={ statistics ? statistics: {} }
                                        />
                                    }

                                    {
                                        pageData.opis &&
                                        <Info
                                            gradient={{
                                                color1: pageData.info_kolor_tla_1,
                                                color2: pageData.info_kolor_tla_2
                                            }}
                                            tytul={pageData.tytul_opisu}
                                            opis={pageData.opis}
                                            hashtagi={pageData.hashtagi_opisu}
                                            rowerzysta={pageData.rowerzysta}
                                            theme={pageData.wyglad}
                                            flat={!pageData.zasady}
                                        />
                                    }

                                    {
                                        pageData.pokaz_odliczanie &&
                                        <div className="row center-xs section-countdown">
                                            <div className="col col-xs-12">

                                                    <Countdown
                                                        date={pageData.data}
                                                        renderer={renderer}
                                                    />


                                            </div>
                                        </div>
                                    }

                                    {
                                        pageData.zaloguj_pokaz &&
                                        <ExternalButton
                                            url={pageData.zaloguj_url_linka}
                                            txt={pageData.zaloguj_tekst_linka}
                                            isModal={pageData.zaloguj_modal}
                                            gradient={greenGradient}
                                            color="#FFFFFF"
                                            modalContent={pageData.zaloguj_modal_tekst}
                                        />
                                    }

                                    {
                                        pageData.opis_2 &&
                                        <Info
                                            gradient={{
                                                color1: pageData.info_kolor_tla_1_2,
                                                color2: pageData.info_kolor_tla_2_2
                                            }}
                                            tytul={pageData.tytul_opisu_2}
                                            opis={pageData.opis_2}
                                            rowerzysta={pageData.rowerzysta_2}
                                            theme={pageData.wyglad}
                                            flat={!pageData.zasady}
                                        />
                                    }


                                    {
                                        pageData.custom_img_1 &&
                                        <div className="row section-custom-image ">
                                            <div className="col col-xs-12">
                                                <img
                                                    src={pageData.custom_img_1}
                                                    alt={pageData.custom_img_1_title}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {
                                        pageData.mapy_tytul &&
                                        pageData.mapy_lista_pozycja === "pos1" &&
                                        <Mapy
                                            lista={pageData.mapy_lista_tras}
                                            tytul={pageData.mapy_tytul}
                                            gradient={navyGradient}
                                            color="#FFFFFF"
                                        />
                                    }


                                    <ExternalButton
                                        txt={pageData.sprawdz_info_tekst_linka}
                                        isModal={pageData.sprawdz_info_modal}
                                        url={pageData.sprawdz_info_url_linka}
                                        gradient={greenGradient}
                                        color="#FFFFFF"
                                        modalContent={pageData.sprawdz_info_modal_tekst}
                                    />

                                    {
                                        pageData.pitstopy_pozycja === "pos1" &&
                                        <Tiles
                                            tytul={pageData.tytul_sekcji_pitstopy}
                                            lista={pageData.lista_pitstopow}
                                            gradient={greenGradient}
                                            iconColor="#FFFFFF"
                                        />
                                    }

                                    {
                                        pageData.mapy_tytul &&
                                        pageData.mapy_lista_pozycja === "pos2" &&
                                        <Mapy
                                            lista={pageData.mapy_lista_tras}
                                            tytul={pageData.mapy_tytul}
                                            gradient={navyGradient}
                                            color="#FFFFFF"
                                        />
                                    }

                                    {
                                        pageData.pitstopy_pozycja === "pos2" &&
                                        <Tiles
                                            tytul={pageData.tytul_sekcji_pitstopy}
                                            lista={pageData.lista_pitstopow}
                                            gradient={greenGradient}
                                            iconColor="#FFFFFF"
                                        />
                                    }

                                    {
                                        pageData.sprawdz_info_konkurs_pozycja === "pos1" &&
                                        <ExternalButton
                                            txt={pageData.sprawdz_info_konkurs_tekst_linka}
                                            linkType={pageData.typ_linka}
                                            vimeoId={pageData.sprawdz_info_konkurs_vimeoid}
                                            url={pageData.sprawdz_info_konkurs_url_linka}
                                            isModal={pageData.sprawdz_info_konkurs_modal}
                                            modalContent={pageData.sprawdz_info_konkurs_modal_tekst}
                                            gradient={orangeGradientSolid}
                                            color="#000000"
                                            featObraz={pageData.sprawdz_info_konkurs_obraz}
                                            featTytul={pageData.sprawdz_info_konkurs_obraz_tytul}
                                            odleglosc={pageData.sprawdz_info_konkurs_odleglosc}
                                        />
                                    }


                                    {
                                        pageData.filmy_wyzwania &&
                                        <div style={pinkGradientSolid}>
                                            <h3 className="tiles-section-title gallery-photo-title">
                                                <figure>
                                                    <VideoCamera mainColor={"#000000"}/>
                                                </figure>
                                                <figcaption>
                                                    <span dangerouslySetInnerHTML={{__html: pageData.video_gallery_tytul_wyzwania}} />
                                                </figcaption>
                                            </h3>
                                            <GalleryVideos
                                                videos={pageData.filmy_wyzwania}
                                                playIcon={pageOptions.play_icon}
                                            />
                                        </div>
                                    }

                                    {
                                        pageData.sprawdz_info_konkurs_pozycja === "pos2" &&
                                        <ExternalButton
                                            txt={pageData.sprawdz_info_konkurs_tekst_linka}
                                            linkType={pageData.typ_linka}
                                            vimeoId={pageData.sprawdz_info_konkurs_vimeoid}
                                            url={pageData.sprawdz_info_konkurs_url_linka}
                                            isModal={pageData.sprawdz_info_konkurs_modal}
                                            modalContent={pageData.sprawdz_info_konkurs_modal_tekst}
                                            gradient={greenGradient}
                                            color="#FFFFFF"
                                            featObraz={pageData.sprawdz_info_konkurs_obraz}
                                            featTytul={pageData.sprawdz_info_konkurs_obraz_tytul}
                                            odleglosc={pageData.sprawdz_info_konkurs_odleglosc}
                                        />
                                    }

                                    {
                                        pageData.opis_3 &&
                                        <Info
                                            gradient={{
                                                color1: pageData.info_kolor_tla_1_3,
                                                color2: pageData.info_kolor_tla_2_3
                                            }}
                                            tytul={pageData.tytul_opisu_3}
                                            opis={pageData.opis_3}
                                            rowerzysta={pageData.rowerzysta_3}
                                            theme={pageData.wyglad}
                                            flat={!pageData.zasady}
                                        />
                                    }

                                    <Harmonogram
                                        tytul={pageData.harmonogram_tytul}
                                        wykres={pageData.wykres}
                                        animacja={pageData.animacja}
                                    />

                                    {
                                        pageData.filmy &&
                                        <div style={seasonGradient}>
                                            <h3 className="tiles-section-title gallery-photo-title">
                                                <figure>
                                                    <VideoCamera mainColor={seasonTxtColor}/>
                                                </figure>
                                                <figcaption>
                                                    {pageData.video_gallery_tytul}
                                                </figcaption>
                                            </h3>
                                            <GalleryVideos
                                                videos={pageData.filmy}
                                                playIcon={pageOptions.play_icon}
                                            />
                                        </div>
                                    }

                                    {
                                        pageData.galeria_zdjec &&
                                        <>
                                            <h3 className="tiles-section-title gallery-photo-title">
                                                <figure>
                                                    <PhotoCamera mainColor="#000000"/>
                                                </figure>
                                                <figcaption>
                                                    {pageData.photo_gallery_tytul}
                                                </figcaption>
                                            </h3>
                                            <Gallery
                                                pageData={pageData}
                                                pageOptions={pageOptions}
                                                initialAmount={6}
                                            />
                                        </>
                                    }

                                    {
                                        pageData.strony &&
                                        <>
                                            <Catalogue
                                                full={pageData.gazetka_pelna_szerokosc}
                                                tytul={pageData.gazetka_tytul}
                                                strony={pageData.strony}
                                            />
                                        </>
                                    }

                                    <Partners
                                        csrGradient={eventGradient}
                                        partnersData={{
                                            tytulListy: tytul_listy,
                                            img: lista_obraz
                                        } }/>

                                </Fragment>
                            }

                        </div>


                </article>

            </Fragment>
        );
    }

}
const mapStateToProps = (state) => ({
    playerOpened:state.player.playerOpened
});


export default connect(mapStateToProps,null)(Events);