import { TOGGLE_PLAYER } from "../actions/playerActions";
import { SET_SELECTED_VIDEO } from "../actions/playerActions";

const defaultState = {
    playerOpened: false,
    selectedVideo: {}
};

const player = (state = defaultState, action = undefined) => {
    const {type} = action;

    function togglePlayer (state) {
        const newState = Object.assign({}, state);
        newState.playerOpened = !newState.playerOpened;
        return newState.playerOpened;
    }

    switch (type) {
        case TOGGLE_PLAYER: {
            return {
                ...state,
                playerOpened: togglePlayer(state)
            }
        }
        case SET_SELECTED_VIDEO: {
            return {
                ...state,
                selectedVideo: action.video
            }
        }
        default:
            return state;
    }
};

export default player;