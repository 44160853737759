export const TOGGLE_PLAYER = 'portfolioTogglePlayer';
export const SET_SELECTED_VIDEO = 'portfolioSelectedVideo';

export const togglePlayer = () => ({
    type: TOGGLE_PLAYER
});

export const selectedVideo = video => ({
    type: SET_SELECTED_VIDEO,
    video
});