import React, {Component} from "react";
import { connect } from "react-redux";

//actions

import {
    toggleHomeMenu,
    setHoveredMenuItemIndex
} from "../../../actions/pageActions";

//tools
import classnames from "classnames";

//svg components
import MenuClose from "../../../svg/MenuClose";
import MenuOpen from "../../../svg/MenuOpen";


class MenuTop extends Component {

    constructor(props) {
        super(props);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseClick(value){
        this.props.setHoveredMenuItem(
            value === this.props.focusedMenuItem ? null : this.props.focusedMenuItem
        )
    }

    handleMouseEnter(value){
        this.props.setHoveredMenuItem(value)
    }

    handleMouseLeave(){
        this.props.setHoveredMenuItem(null)
    }

    render() {
        const {menuOpened} = this.props;
        return (
            <div className={
                classnames(
                    "menus-container",
                    {
                      "expanded" : menuOpened
                    }
                )
            } >
                {!menuOpened && (
                    <div className="mobile mobile-menu-btn open"
                         onClick={this.props.toggleMenu}>
                        <MenuOpen mainColor={"#000000"}/>
                    </div>
                )}
                {menuOpened && (
                    <div className="mobile mobile-menu-btn close"
                         onClick={this.props.toggleMenu}>
                        <MenuClose mainColor={"#000000"}/>
                    </div>
                )}
                <nav className="menu-top">
                    <ul>
                        {this.props.menu
                            .filter( item => { return item.post_parent === 0 && item.menu_item_parent === "0" })
                            .map(item =>
                                <li
                                    key={item.ID}
                                    data-id={item.object_id}
                                    className={ (item.ID === this.props.focusedMenuItem) ? 'active':'' }
                                    onMouseEnter={ () => this.handleMouseEnter(item.ID)  }
                                    onClick={ () => this.handleMouseEnter(item.ID)  }>

                                    {( item.type === 'custom' && item.menu_item_parent === "0" ) ?
                                        <span>{item.title}</span>
                                        :
                                        <a href={item.url} target={"_top"}>{item.title}</a>

                                    }
                                    <div>
                                        <ul onMouseLeave={ this.handleMouseLeave }>
                                            {this.props.menu
                                                .filter( childItem => { return childItem.menu_item_parent === item.object_id || childItem.menu_item_parent === item.db_id.toString(); })
                                                .map(childItem =>
                                                    <li key={childItem.ID} data-parent-id={item.object_id} data-child-id={childItem.post_parent} >
                                                        <a href={childItem.url} target={"_top"}>{childItem.title}</a>
                                                    </li>
                                                )}
                                        </ul>
                                    </div>
                                </li>
                            )}
                    </ul>
                </nav>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    menuOpened: state.page.menuOpened,
    focusedMenuItem: state.page.focusedMenuItem
});

const mapDispatchToProps = dispatch => ({
    toggleMenu: () => dispatch( toggleHomeMenu() ),
    setHoveredMenuItem: value => dispatch( setHoveredMenuItemIndex (value))
});


export default connect(mapStateToProps,mapDispatchToProps)(MenuTop);