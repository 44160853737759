export const SET_POPUP = 'setPopup';
export const SET_REDIRECTION = 'setRedirectionIfNotPopup';

export const setPopup = data => ({
    type: SET_POPUP,
    data
});

export const setRedirection = value => ({
    type: SET_REDIRECTION,
    value
});
