import React, {Component} from 'react';

import {config} from "../../config";

class Pins extends Component {
    render() {
        return (
        <svg 
            version="1.1" 
            id="pins" 
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            width="1820px" height="1553.44px" 
            viewBox="0 -40 1820 1553.44"  >
            <g>

            <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/299F00F.png"}  transform="matrix(1 0 0 1 673.939 556.6592)">
            </image>
        <g>
            <path fill="#FFFFFF" d="M730.43,587.16c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
			c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53S730.43,575.26,730.43,587.16z"/>
        </g>
        </g>
        <g>
            <g>
                <g>

                    <image overflow="visible" opacity="0.75" width="65" height="81"  xlinkHref={config.domain + "plan-shades/299F00D.png"}  transform="matrix(1 0 0 1 794.8599 940.2871)">
                    </image>
                    <g>
                        <path fill="#FFFFFF" d="M851.92,970.45c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
					c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C842.28,948.91,851.92,958.55,851.92,970.45z"/>
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path d="M826.2,960.15c-0.89,0-1.75,0.68-2.44,1.9c-0.66,1.19-1.04,2.71-1.04,4.18c0,1.39,0.72,2.65,1.87,3.29
				c0.65,0.36,1.03,1.03,1,1.75l-0.19,4.08l0,0l-0.51,11.06c-0.02,0.36,0.11,0.71,0.36,0.97c0.25,0.26,0.59,0.41,0.95,0.41
				s0.7-0.14,0.95-0.41c0.25-0.26,0.38-0.61,0.36-0.97l-0.7-15.14c-0.03-0.72,0.35-1.39,1-1.75c1.16-0.64,1.87-1.9,1.87-3.29
				c0-1.46-0.38-2.99-1.04-4.18C827.95,960.82,827.09,960.15,826.2,960.15z"/>
                    <path d="M839.12,966.47l-0.3-5.81c-0.01-0.29-0.25-0.51-0.54-0.51c-0.15,0-0.28,0.05-0.39,0.16c-0.1,0.11-0.16,0.24-0.15,0.39
				l0.11,5.03c0,0.21-0.07,0.41-0.22,0.56c-0.15,0.15-0.34,0.23-0.55,0.23c-0.42,0-0.77-0.34-0.77-0.77l-0.05-5.17
				c0-0.24-0.2-0.44-0.44-0.44c-0.24,0-0.44,0.2-0.44,0.44l-0.05,5.17c0,0.42-0.35,0.76-0.77,0.76c-0.21,0-0.41-0.08-0.55-0.23
				c-0.15-0.15-0.22-0.35-0.22-0.56l0.11-5.05c0-0.14-0.05-0.27-0.15-0.37s-0.23-0.15-0.37-0.15c-0.27,0-0.5,0.21-0.52,0.49
				l-0.3,5.83c-0.07,1.27,0.59,2.45,1.71,3.06c0.62,0.34,1,1.03,0.97,1.75l-0.5,10.65l0,0l-0.21,4.44
				c-0.02,0.37,0.12,0.73,0.37,0.99c0.24,0.26,0.57,0.41,0.92,0.41h0.02c0.35,0,0.7-0.14,0.94-0.4c0.25-0.26,0.39-0.62,0.37-0.98
				l-0.7-15.13c-0.03-0.72,0.35-1.41,0.97-1.75C838.51,968.93,839.19,967.73,839.12,966.47z"/>
                </g>
            </g>
        </g>
        <g>
        <g>
        <g>

        <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/299F013.png"}  transform="matrix(1 0 0 1 829.8599 961.2871)">
            </image>
        <g>
            <path fill="#FFFFFF" d="M886.53,991.18c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
					c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C876.89,969.65,886.53,979.29,886.53,991.18z"/>
        </g>
        </g>
    </g>
    <g>
    <g>
    <g>
    <path d="M865,997.97c-1.34,0-2.43,1.09-2.43,2.42c0,1.34,1.09,2.42,2.43,2.42c1.34,0,2.43-1.09,2.43-2.42
    C867.43,999.05,866.34,997.97,865,997.97z"/>
    </g>
    </g>
    <g>
    <g>
    <path d="M870.93,994.67c-1.58-1.58-3.69-2.45-5.93-2.45c-2.23,0-4.33,0.87-5.91,2.44c-0.64,0.64-0.64,1.67,0,2.31
    c0.31,0.31,0.72,0.48,1.16,0.48c0.44,0,0.85-0.17,1.16-0.48c0.96-0.96,2.24-1.48,3.6-1.48c1.36,0,2.65,0.53,3.61,1.49
    c0.31,0.31,0.72,0.48,1.16,0.48s0.85-0.17,1.16-0.48C871.57,996.34,871.57,995.31,870.93,994.67z"/>
    </g>
    </g>
    <g>
    <g>
    <path d="M878.55,987.08c-3.62-3.61-8.43-5.59-13.55-5.59c-5.11,0-9.92,1.98-13.53,5.58c-0.64,0.63-0.64,1.67,0,2.31
    c0.31,0.31,0.72,0.48,1.16,0.48c0.44,0,0.85-0.17,1.16-0.48c3-2.98,6.98-4.63,11.22-4.63c4.24,0,8.23,1.65,11.23,4.64
    c0.31,0.31,0.72,0.48,1.16,0.48s0.85-0.17,1.16-0.48C879.19,988.75,879.19,987.71,878.55,987.08z"/>
    </g>
    </g>
    <g>
    <g>
    <path d="M874.64,990.97c-2.58-2.57-6-3.98-9.64-3.98c-3.64,0-7.05,1.41-9.63,3.97c-0.31,0.31-0.48,0.72-0.48,1.15
    c0,0.44,0.17,0.85,0.48,1.15s0.72,0.48,1.16,0.48s0.85-0.17,1.16-0.48c1.95-1.95,4.55-3.02,7.31-3.02
    c2.77,0,5.37,1.07,7.32,3.03c0.31,0.31,0.72,0.48,1.16,0.48s0.85-0.17,1.16-0.48c0.31-0.31,0.48-0.72,0.48-1.15
    S874.95,991.28,874.64,990.97z"/>
    </g>
    </g>
    </g>
    </g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/299F015.png"}  transform="matrix(1 0 0 1 642.1021 573.248)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M698.59,603.75c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.65,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C688.95,582.21,698.59,591.85,698.59,603.75z"/>
    </g>
    </g>
    <g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/299F017.png"}  transform="matrix(1 0 0 1 608.9067 590.3799)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M665.4,620.88c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C655.75,599.34,665.4,608.98,665.4,620.88z"/>
    </g>
    </g>
    </g>
    <g>
    <g>
    <path d="M677.72,603.57c0,0.52,0,0.97,0,1.48c0.2,0,3.11,0,4.39,0c0.82,0,1.47,0.32,1.97,0.96c1.82,2.36,3.59,4.64,5.41,7
    c0.6,0.78,0.51,1.36-0.18,1.91c-0.67,0.53-1.26,0.44-1.83-0.23c-1.42-1.69-2.84-3.37-4.25-5.07c-0.28-0.34-0.58-0.48-1.02-0.47
    c-2.59,0.02-5.17,0.01-7.76,0.01c-1.47,0-2.32-0.86-2.32-2.34c0-2.94,0-5.87,0-8.81c0-1.52,0.84-2.36,2.35-2.36
    c0.32,0,0.63,0,0.95,0c1.33,0.01,2.29,0.8,2.25,2.36c0.02,0.94,0,2.98,0,3.22c0.21,0,3.23,0,4.59,0c0.75,0,1.01,0.55,1.03,1.1
    c0,0.9-0.29,1.24-1.03,1.24C680.93,603.57,677.92,603.57,677.72,603.57z"/>
    <path d="M674.88,594.98c-1.38-0.01-2.51-1.16-2.49-2.54c0.02-1.38,1.18-2.51,2.55-2.49c1.39,0.02,2.5,1.16,2.48,2.55
    C677.41,593.89,676.27,594.99,674.88,594.98z"/>
    </g>
    <path d="M684.75,612.04l-1.9-2.27l-0.05,0.57c-0.33,3.6-3.32,6.32-6.94,6.32c0,0,0,0,0,0c-0.36,0-0.73-0.03-1.09-0.08
    c-2.97-0.45-5.35-2.84-5.8-5.81c-0.39-2.61,0.67-5.17,2.78-6.71v-2.66l-0.13,0.06c-3.12,1.61-5.04,4.8-5.01,8.31
    c0.05,5.05,4.2,9.16,9.25,9.16l0.08,0c3.78-0.03,7.13-2.32,8.52-5.84c0.02-0.06,0.05-0.12,0.07-0.18l0.04-0.11l0,0
    c0.07-0.2,0.13-0.43,0.17-0.66L684.75,612.04z"/>
    </g>
    <g>
    <rect x="642.65" y="609.83" width="2.42" height="26.3"/>
    <g>
    <path d="M652.74,609.79c1.21,0.01,2.15,0.97,2.14,2.2c-0.01,1.2-0.99,2.17-2.19,2.16c-1.19-0.01-2.17-0.99-2.17-2.19
    C650.52,610.72,651.48,609.78,652.74,609.79z"/>
    <path d="M657.85,617.22c-0.04-1.47-1.28-2.54-2.76-2.55c-0.8,0-1.6-0.01-2.39-0.01l0,0c0,0,0,0,0,0s0,0,0,0l0,0
    c-0.8,0-1.6,0-2.39,0.01c-1.48,0.01-2.72,1.08-2.76,2.55c-0.07,2.33-0.03,4.67-0.02,7.01c0,0.54,0.42,0.92,0.93,0.93
    c0.5,0.01,0.91-0.37,0.97-0.9c0.02-0.15,0.01-0.31,0.01-0.47c0-1.7,0-3.39,0-5.09c0-0.19,0-0.37,0-0.55
    c0.56-0.11,0.43,0.27,0.43,0.5c0.01,5.23,0,10.45,0,15.68c0,0.17-0.01,0.35,0.01,0.52c0.06,0.79,0.6,1.33,1.33,1.32
    c0.72-0.01,1.24-0.57,1.26-1.37c0-0.16,0-0.31,0-0.47c0-2.89,0-5.78,0-8.67c0-0.24-0.01-0.47,0.24-0.48
    c0.25,0.01,0.24,0.24,0.24,0.48c0,2.89,0,5.78,0,8.67c0,0.16,0,0.31,0,0.47c0.03,0.8,0.54,1.36,1.26,1.37
    c0.72,0.01,1.27-0.52,1.33-1.32c0.01-0.17,0.01-0.35,0.01-0.52c0-5.23,0-10.45,0-15.68c0-0.23-0.13-0.61,0.43-0.5
    c0,0.18,0,0.37,0,0.55c0,1.7,0,3.39,0,5.09c0,0.16-0.01,0.31,0.01,0.47c0.06,0.53,0.47,0.9,0.97,0.9
    c0.51-0.01,0.92-0.38,0.93-0.93C657.88,621.89,657.92,619.55,657.85,617.22z"/>
    </g>
    <g>
    <g>
    <path d="M632.58,611.99c-0.01-1.23,0.92-2.19,2.13-2.2c1.25-0.01,2.23,0.94,2.23,2.17c0,1.19-0.98,2.18-2.17,2.19
    C633.58,614.16,632.59,613.19,632.58,611.99z"/>
    </g>
    <path d="M640.76,623.01c-0.29-1-1.51-5.17-1.85-6.26c-0.4-1.3-1.47-2.08-2.83-2.08c-0.45,0-0.91,0-1.36,0c-0.45,0-0.91,0-1.36,0
    c-1.36,0-2.43,0.78-2.83,2.08c-0.33,1.09-1.56,5.27-1.85,6.26c-0.12,0.42-0.05,0.8,0.34,1.05c0.34,0.22,0.72,0.21,1.01-0.07
    c0.17-0.17,0.29-0.43,0.36-0.67c0.53-1.75,1.04-3.51,1.56-5.27c0.08-0.29,0.16-0.55,0.59-0.33c-0.96,3.27-2.91,9.9-2.91,9.9h2.81
    c0,0,0,4.94,0,7.06c0,0.24,0,0.49,0.05,0.72c0.09,0.45,0.51,0.77,0.98,0.77c0.47,0,0.89-0.3,1-0.75c0.04-0.2,0.05-0.41,0.05-0.62
    c0-2.25,0-4.5,0-6.75c0-0.23,0.01-0.44,0.2-0.45c0.2,0.01,0.2,0.22,0.2,0.45c0,2.25,0,4.5,0,6.75c0,0.21,0,0.42,0.05,0.62
    c0.1,0.45,0.52,0.75,1,0.75c0.47,0,0.89-0.32,0.98-0.77c0.05-0.24,0.05-0.48,0.05-0.72c0-2.13,0-7.06,0-7.06h2.81
    c0,0-1.94-6.63-2.9-9.9c0.43-0.21,0.5,0.05,0.59,0.33c0.51,1.76,1.03,3.51,1.56,5.27c0.07,0.24,0.19,0.5,0.36,0.67
    c0.29,0.28,0.66,0.29,1.01,0.07C640.81,623.81,640.88,623.43,640.76,623.01z"/>
    </g>
    </g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/299F014.png"}  transform="matrix(1 0 0 1 607.4009 882.1797)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M663.89,912.68c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.65,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C654.25,891.14,663.89,900.79,663.89,912.68z"/>
    </g>
    </g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/299F01C.png"}  transform="matrix(1 0 0 1 574.2056 899.3105)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M630.69,929.81c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C621.05,908.28,630.69,917.92,630.69,929.81z"/>
    </g>
    </g>
    <g>
    <g>
    <path d="M643.02,912.5c0,0.52,0,0.97,0,1.48c0.2,0,3.11,0,4.39,0c0.82,0,1.47,0.32,1.97,0.96c1.83,2.36,3.59,4.64,5.42,7
    c0.6,0.78,0.51,1.36-0.18,1.91c-0.67,0.53-1.26,0.44-1.83-0.23c-1.42-1.69-2.84-3.37-4.25-5.07c-0.28-0.34-0.58-0.47-1.02-0.47
    c-2.59,0.02-5.17,0.01-7.76,0.01c-1.47,0-2.32-0.86-2.32-2.34c0-2.94,0-5.87,0-8.81c0-1.52,0.84-2.36,2.35-2.36
    c0.32,0,0.63,0,0.95,0c1.33,0.01,2.29,0.8,2.25,2.36c0.02,0.94,0,2.98,0,3.22c0.21,0,3.23,0,4.59,0c0.75,0,1.01,0.55,1.03,1.1
    c0,0.9-0.29,1.24-1.03,1.24C646.23,912.5,643.22,912.5,643.02,912.5z"/>
    <path d="M640.18,903.91c-1.38-0.01-2.51-1.16-2.49-2.54s1.18-2.51,2.55-2.49c1.39,0.03,2.5,1.16,2.48,2.55
    C642.71,902.82,641.57,903.92,640.18,903.91z"/>
    </g>
    <path d="M650.05,920.97l-1.9-2.27l-0.05,0.57c-0.33,3.6-3.32,6.32-6.94,6.32h0c-0.36,0-0.73-0.03-1.09-0.08
    c-2.97-0.45-5.35-2.84-5.8-5.81c-0.39-2.61,0.67-5.17,2.78-6.71v-2.66l-0.13,0.06c-3.12,1.61-5.04,4.8-5.01,8.31
    c0.05,5.05,4.2,9.16,9.25,9.16h0.08c3.78-0.03,7.13-2.32,8.52-5.84c0.02-0.06,0.05-0.12,0.07-0.18l0.04-0.11l0,0
    c0.07-0.2,0.13-0.43,0.17-0.66L650.05,920.97z"/>
    </g>
    <g>
    <rect x="607.95" y="918.77" width="2.42" height="26.3"/>
    <g>
    <path d="M618.04,918.72c1.21,0.01,2.15,0.97,2.14,2.2c-0.01,1.2-0.99,2.17-2.19,2.16c-1.19-0.01-2.17-0.99-2.17-2.19
    C615.81,919.65,616.78,918.71,618.04,918.72z"/>
    <path d="M623.15,926.15c-0.04-1.47-1.28-2.54-2.76-2.55c-0.8,0-1.6-0.01-2.39-0.01l0,0c0,0,0,0,0,0s0,0,0,0l0,0
    c-0.8,0-1.6,0-2.39,0.01c-1.48,0.01-2.72,1.08-2.76,2.55c-0.07,2.33-0.03,4.67-0.02,7.01c0,0.54,0.42,0.92,0.93,0.93
    c0.5,0.01,0.91-0.37,0.97-0.9c0.02-0.15,0.01-0.31,0.01-0.46c0-1.7,0-3.39,0-5.09c0-0.18,0-0.37,0-0.55
    c0.56-0.11,0.43,0.27,0.43,0.5c0.01,5.23,0,10.46,0,15.68c0,0.17-0.01,0.35,0.01,0.52c0.06,0.79,0.6,1.33,1.33,1.32
    c0.72-0.01,1.24-0.57,1.26-1.37c0-0.16,0-0.31,0-0.47c0-2.89,0-5.78,0-8.67c0-0.24-0.01-0.47,0.24-0.48
    c0.25,0.01,0.24,0.24,0.24,0.48c0,2.89,0,5.78,0,8.67c0,0.16,0,0.31,0,0.47c0.03,0.79,0.54,1.35,1.26,1.37
    c0.72,0.01,1.27-0.52,1.33-1.32c0.01-0.17,0.01-0.35,0.01-0.52c0-5.23,0-10.46,0-15.68c0-0.23-0.13-0.61,0.43-0.5
    c0,0.18,0,0.37,0,0.55c0,1.7,0,3.39,0,5.09c0,0.15-0.01,0.31,0.01,0.46c0.06,0.53,0.47,0.9,0.97,0.9
    c0.51-0.01,0.92-0.38,0.93-0.93C623.18,930.82,623.22,928.48,623.15,926.15z"/>
    </g>
    <g>
    <g>
    <path d="M597.88,920.92c-0.01-1.23,0.92-2.19,2.13-2.2c1.25-0.01,2.23,0.94,2.23,2.17c0,1.19-0.98,2.18-2.17,2.19
    C598.88,923.09,597.89,922.12,597.88,920.92z"/>
    </g>
    <path d="M606.06,931.95c-0.29-0.99-1.51-5.17-1.85-6.26c-0.4-1.3-1.47-2.07-2.83-2.08c-0.45,0-0.91,0-1.36,0
    c-0.45,0-0.91,0-1.36,0c-1.36,0-2.43,0.78-2.83,2.08c-0.33,1.09-1.56,5.27-1.85,6.26c-0.12,0.42-0.05,0.8,0.34,1.05
    c0.34,0.22,0.72,0.21,1-0.07c0.17-0.17,0.29-0.43,0.36-0.67c0.53-1.75,1.04-3.51,1.56-5.27c0.08-0.29,0.16-0.55,0.59-0.33
    c-0.96,3.27-2.91,9.89-2.91,9.89h2.81c0,0,0,4.94,0,7.07c0,0.24,0,0.49,0.05,0.72c0.09,0.46,0.51,0.77,0.98,0.77
    c0.47,0.01,0.89-0.29,1-0.75c0.05-0.2,0.05-0.41,0.05-0.62c0-2.25,0-4.5,0-6.75c0-0.22,0.01-0.44,0.2-0.45
    c0.2,0.01,0.2,0.22,0.2,0.45c0,2.25,0,4.5,0,6.75c0,0.21,0,0.42,0.05,0.62c0.1,0.45,0.52,0.75,1,0.75c0.47,0,0.89-0.32,0.98-0.77
    c0.05-0.23,0.05-0.48,0.05-0.72c0-2.13,0-7.07,0-7.07h2.81c0,0-1.94-6.62-2.91-9.89c0.43-0.21,0.51,0.05,0.59,0.33
    c0.51,1.76,1.03,3.51,1.56,5.27c0.07,0.24,0.19,0.5,0.36,0.67c0.29,0.28,0.66,0.29,1,0.07
    C606.11,932.74,606.18,932.37,606.06,931.95z"/>
    </g>
    </g>
    <g>
    <g>
    <g>
    <path d="M710.29,588.25c-0.62-0.4-1.19-0.76-1.75-1.12c-0.41-0.26-0.48-0.52-0.25-0.94c0.11-0.2,0.25-0.39,0.41-0.56
    c0.34-0.38,0.63-0.41,1.06-0.12c0.87,0.6,1.75,1.19,2.62,1.79c0.72,0.5,0.79,0.94,0.3,1.64c-0.12,0.18-0.24,0.36-0.41,0.61
    c0.69,0,1.32,0.04,1.94-0.02c0.15-0.01,0.36-0.32,0.39-0.53c0.07-0.35,0.01-0.71,0.02-1.07c0.02-0.46,0.31-0.69,0.87-0.69
    c0.56,0,0.87,0.22,0.88,0.68c0.01,1.46,0.02,2.92,0,4.39c-0.01,0.62-0.41,1.03-1.04,1.04c-1.43,0.02-2.86,0.04-4.29-0.01
    c-1.19-0.04-1.85-0.88-1.93-2.08c0-0.7,0.01-0.45,0-0.7c0.07-0.82,0.27-1.24,0.71-1.79C709.97,588.63,710.1,588.45,710.29,588.25
    z"/>
    <path d="M718.73,593.12c-1.07,0-1.9-0.82-1.89-1.88c0-1.06,0.83-1.89,1.89-1.89c1.03,0,1.86,0.83,1.88,1.86
    C720.61,592.28,719.78,593.12,718.73,593.12z"/>
    </g>
    <g>
    <path d="M709.71,575.51c0.03-1.38,1.15-2.49,2.51-2.47c1.37,0.02,2.48,1.16,2.47,2.52c-0.02,1.37-1.17,2.49-2.52,2.47
    C710.79,578,709.69,576.87,709.71,575.51z"/>
    <g>
    <path d="M700.66,600.54v-1.29c0,0.44,0,0.84,0,1.19C700.66,600.47,700.66,600.5,700.66,600.54z"/>
    <path d="M713.61,584.39c-0.84-0.59-1.67-1.2-2.53-1.75c-0.57-0.36-0.83-0.82-0.86-1.49c-0.05-1.04-0.09-2.09-0.3-3.1
    c-0.28-1.36-1.26-1.93-2.62-1.65c-0.32,0.06-0.63,0.19-0.91,0.35c-1.74,0.99-3.49,1.98-5.2,3.02c-1.09,0.66-1.82,1.68-2.17,2.89
    c-0.61,2.15-1.14,4.33-1.69,6.5c-0.09,0.34-0.23,1.08-0.23,1.08h3.56c0,0,0,0,0.04,10.21c0,0.57,0.3,1.34,1.37,1.34l0,0
    c0,0,0,0,0,0c0,0,0,0,0,0l0,0c1.07,0,1.36-0.77,1.36-1.34c0.04-10.21,0.04-10.21,0.04-10.21h0.02c0-0.01,0-0.01,0-0.01h3.54
    c0,0-1.94-6.96-1.79-7.29c0.15-0.35,0.72-0.52,1.12-0.75c0.59-0.33,1.2-0.63,1.89-0.99c0,0.48,0.02,0.83,0,1.18
    c-0.03,0.54,0.19,0.91,0.64,1.21c1.2,0.81,2.37,1.66,3.56,2.48c0.53,0.37,0.86,0.28,1.3-0.36
    C714.14,585.1,714.11,584.75,713.61,584.39z"/>
    </g>
    </g>
    <path d="M721.11,595.68h-12.74c-0.38,0-0.69-0.31-0.69-0.69v-0.28c0-0.38,0.31-0.69,0.69-0.69h12.74c0.38,0,0.69,0.31,0.69,0.69
    v0.28C721.8,595.37,721.49,595.68,721.11,595.68z"/>
    </g>
    </g>
    <g>
    <g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/299F01D.png"}  transform="matrix(1 0 0 1 268.8599 1032.2871)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M325.62,1062.02c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C315.98,1040.49,325.62,1050.13,325.62,1062.02z"/>
    </g>
    </g>
    </g>
    <g>
    <g>
    <path d="M292.86,1076.65c-0.84,0-1.52-0.68-1.52-1.52v-5.98c0-0.84,0.68-1.52,1.52-1.52s1.52,0.68,1.52,1.52v3.5
    c0,0.52,0.43,0.95,0.95,0.95h17.84c0.52,0,0.95-0.43,0.95-0.95v-17.84c0-0.52-0.42-0.95-0.95-0.95h-17.84
    c-0.52,0-0.95,0.42-0.95,0.95v3.51c0,0.84-0.68,1.52-1.52,1.52s-1.52-0.68-1.52-1.52v-5.98c0-0.84,0.68-1.52,1.52-1.52h22.79
    c0.84,0,1.52,0.68,1.52,1.52v22.79c0,0.84-0.68,1.52-1.52,1.52H292.86z"/>
    <path fill="#FFFFFF" d="M315.64,1050.86c0.81,0,1.47,0.66,1.47,1.47v22.79c0,0.81-0.66,1.47-1.47,1.47h-22.79
    c-0.81,0-1.47-0.66-1.47-1.47v-5.98c0-0.81,0.66-1.47,1.47-1.47s1.47,0.66,1.47,1.47v3.5c0,0.55,0.45,1,1,1h17.84
    c0.55,0,1-0.45,1-1v-17.84c0-0.55-0.45-1-1-1h-17.84c-0.55,0-1,0.45-1,1v3.51c0,0.81-0.66,1.47-1.47,1.47s-1.47-0.66-1.47-1.47
    v-5.98c0-0.81,0.66-1.47,1.47-1.47H315.64 M315.64,1050.76h-22.79c-0.87,0-1.57,0.71-1.57,1.57v5.98c0,0.87,0.7,1.57,1.57,1.57
    s1.57-0.7,1.57-1.57v-3.51c0-0.5,0.4-0.9,0.9-0.9h17.84c0.5,0,0.9,0.4,0.9,0.9v17.84c0,0.5-0.4,0.9-0.9,0.9h-17.84
    c-0.5,0-0.9-0.4-0.9-0.9v-3.5c0-0.87-0.7-1.57-1.57-1.57c-0.87,0-1.57,0.71-1.57,1.57v5.98c0,0.87,0.7,1.57,1.57,1.57h22.79
    c0.87,0,1.57-0.71,1.57-1.57v-22.79C317.22,1051.47,316.51,1050.76,315.64,1050.76L315.64,1050.76z"/>
    </g>
    <g>
    <path d="M302.02,1070.34c-0.36,0-0.7-0.14-0.97-0.38l-0.17-0.16c-0.28-0.26-0.44-0.62-0.44-1s0.14-0.74,0.41-1.02l2.44-2.44
    l-8.94-0.08c-0.78,0-1.41-0.63-1.41-1.41v-0.23c0-0.78,0.63-1.41,1.41-1.41h8.9l-2.42-2.54c-0.27-0.27-0.41-0.62-0.41-1
    c0-0.38,0.15-0.73,0.42-1l0.16-0.16c0.27-0.26,0.62-0.41,0.99-0.41c0.38,0,0.73,0.15,1,0.41l5.22,5.22
    c0.55,0.55,0.55,1.44,0,1.99l-5.19,5.21C302.75,1070.19,302.39,1070.34,302.02,1070.34z"/>
    <path fill="#FFFFFF" d="M301.99,1057.15c0.36,0,0.7,0.14,0.96,0.4l5.22,5.22c0.53,0.53,0.53,1.39,0,1.92l-5.19,5.21
    c-0.26,0.26-0.6,0.4-0.96,0.4c-0.35,0-0.68-0.13-0.93-0.37l-0.17-0.16c-0.27-0.25-0.42-0.6-0.43-0.97c0-0.37,0.14-0.72,0.4-0.98
    l2.44-2.44l0.17-0.17h-0.24h-8.9c-0.75,0-1.36-0.61-1.36-1.36v-0.23c0-0.75,0.61-1.36,1.36-1.36h8.9h0.24l-0.17-0.17l-2.46-2.46
    c-0.26-0.26-0.4-0.6-0.4-0.96c0-0.36,0.14-0.7,0.4-0.96l0.16-0.16C301.29,1057.29,301.63,1057.15,301.99,1057.15 M301.99,1057.05
    c-0.37,0-0.74,0.14-1.03,0.42l-0.16,0.16c-0.57,0.57-0.57,1.49,0,2.06l2.46,2.46h-8.9c-0.81,0-1.46,0.65-1.46,1.46v0.23
    c0,0.8,0.65,1.46,1.46,1.46h8.9l-2.44,2.44c-0.58,0.58-0.57,1.53,0.03,2.09l0.17,0.16c0.28,0.27,0.64,0.4,1,0.4
    c0.38,0,0.75-0.14,1.03-0.43l5.19-5.21c0.57-0.57,0.57-1.49,0-2.06l-5.22-5.22C302.74,1057.19,302.36,1057.05,301.99,1057.05
    L301.99,1057.05z"/>
    </g>
    </g>
    </g>
    <g>
    <g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/299F01F.png"}  transform="matrix(1 0 0 1 687.8599 1093.2871)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M744.76,1123.82c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C735.12,1102.28,744.76,1111.92,744.76,1123.82z"/>
    </g>
    </g>
    </g>
    <g>
    <g>
    <g>
    <path d="M712.13,1138.51c-0.84,0-1.52-0.68-1.52-1.52v-5.98c0-0.84,0.68-1.52,1.52-1.52c0.84,0,1.52,0.68,1.52,1.52v3.51
    c0,0.52,0.42,0.95,0.95,0.95h17.84c0.52,0,0.95-0.43,0.95-0.95v-17.84c0-0.52-0.43-0.95-0.95-0.95h-17.84
    c-0.52,0-0.95,0.43-0.95,0.95v3.51c0,0.84-0.68,1.52-1.52,1.52s-1.52-0.68-1.52-1.52v-5.98c0-0.84,0.68-1.52,1.52-1.52h22.79
    c0.84,0,1.52,0.68,1.52,1.52v22.79c0,0.84-0.68,1.52-1.52,1.52H712.13z"/>
    <path fill="#FFFFFF" d="M734.92,1112.73c0.81,0,1.47,0.66,1.47,1.47v22.79c0,0.81-0.66,1.47-1.47,1.47h-22.79
    c-0.81,0-1.47-0.66-1.47-1.47v-5.98c0-0.81,0.66-1.47,1.47-1.47c0.81,0,1.47,0.66,1.47,1.47v3.51c0,0.55,0.45,1,1,1h17.84
    c0.55,0,1-0.45,1-1v-17.84c0-0.55-0.45-1-1-1h-17.84c-0.55,0-1,0.45-1,1v3.51c0,0.81-0.66,1.47-1.47,1.47s-1.47-0.66-1.47-1.47
    v-5.98c0-0.81,0.66-1.47,1.47-1.47H734.92 M734.92,1112.63h-22.79c-0.87,0-1.57,0.71-1.57,1.57v5.98c0,0.87,0.7,1.57,1.57,1.57
    c0.87,0,1.57-0.7,1.57-1.57v-3.51c0-0.5,0.4-0.9,0.9-0.9h17.84c0.5,0,0.9,0.4,0.9,0.9v17.84c0,0.5-0.4,0.9-0.9,0.9h-17.84
    c-0.5,0-0.9-0.4-0.9-0.9v-3.51c0-0.87-0.71-1.57-1.57-1.57c-0.87,0-1.57,0.71-1.57,1.57v5.98c0,0.87,0.7,1.57,1.57,1.57h22.79
    c0.87,0,1.57-0.7,1.57-1.57v-22.79C736.49,1113.34,735.79,1112.63,734.92,1112.63L734.92,1112.63z"/>
    </g>
    <g>
    <path d="M721.29,1132.2c-0.36,0-0.7-0.14-0.97-0.38l-0.17-0.16c-0.28-0.26-0.44-0.62-0.44-1c0-0.38,0.14-0.74,0.41-1.02
    l2.44-2.44l-8.94-0.08c-0.78,0-1.41-0.63-1.41-1.41v-0.23c0-0.78,0.63-1.41,1.41-1.41h8.9l-2.42-2.54
    c-0.27-0.27-0.41-0.62-0.41-1s0.15-0.73,0.42-1l0.16-0.16c0.27-0.26,0.62-0.41,0.99-0.41c0.38,0,0.73,0.15,1,0.41l5.22,5.21
    c0.55,0.55,0.55,1.44,0,1.99l-5.19,5.21C722.02,1132.05,721.67,1132.2,721.29,1132.2z"/>
    <path fill="#FFFFFF" d="M721.27,1119.01c0.36,0,0.7,0.14,0.96,0.4l5.22,5.22c0.53,0.53,0.53,1.39,0,1.92l-5.19,5.21
    c-0.26,0.26-0.6,0.4-0.96,0.4c-0.35,0-0.68-0.13-0.93-0.37l-0.17-0.16c-0.27-0.25-0.42-0.6-0.43-0.97c0-0.37,0.14-0.72,0.4-0.98
    l2.44-2.44l0.17-0.17h-0.24h-8.9c-0.75,0-1.36-0.61-1.36-1.36v-0.23c0-0.75,0.61-1.36,1.36-1.36h8.9h0.24l-0.17-0.17l-2.46-2.46
    c-0.26-0.26-0.4-0.6-0.4-0.96c0-0.36,0.14-0.71,0.4-0.96l0.16-0.16C720.57,1119.15,720.91,1119.01,721.27,1119.01
    M721.27,1118.91c-0.37,0-0.74,0.14-1.03,0.42l-0.16,0.16c-0.57,0.57-0.57,1.5,0,2.07l2.46,2.46h-8.9
    c-0.81,0-1.46,0.65-1.46,1.46v0.23c0,0.8,0.65,1.46,1.46,1.46h8.9l-2.44,2.44c-0.58,0.58-0.57,1.53,0.03,2.09l0.17,0.16
    c0.28,0.26,0.64,0.4,1,0.4c0.37,0,0.75-0.14,1.03-0.43l5.19-5.21c0.57-0.57,0.57-1.49,0-2.06l-5.22-5.21
    C722.01,1119.05,721.64,1118.91,721.27,1118.91L721.27,1118.91z"/>
    </g>
    </g>
    </g>
    </g>
    <g>
    <g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="80"  xlinkHref={config.domain + "plan-shades/603FB23C.png"}  transform="matrix(1 0 0 1 1074.8594 873.2871)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M1131.82,902.93c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.65,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C1122.18,881.4,1131.82,891.04,1131.82,902.93z"/>
    </g>
    </g>
    </g>
    <g>
    <g>
    <g>
    <path d="M1098.9,917.54c-0.84,0-1.52-0.68-1.52-1.52v-5.98c0-0.84,0.68-1.52,1.52-1.52s1.52,0.68,1.52,1.52v3.51
    c0,0.52,0.43,0.95,0.95,0.95h17.84c0.52,0,0.95-0.43,0.95-0.95V895.7c0-0.52-0.43-0.95-0.95-0.95h-17.84
    c-0.52,0-0.95,0.43-0.95,0.95v3.51c0,0.84-0.68,1.52-1.52,1.52s-1.52-0.68-1.52-1.52v-5.98c0-0.84,0.68-1.52,1.52-1.52h22.79
    c0.84,0,1.52,0.68,1.52,1.52v22.79c0,0.84-0.68,1.52-1.52,1.52H1098.9z"/>
    <path fill="#FFFFFF" d="M1121.69,891.76c0.81,0,1.47,0.66,1.47,1.47v22.79c0,0.81-0.66,1.47-1.47,1.47h-22.79
    c-0.81,0-1.47-0.66-1.47-1.47v-5.98c0-0.81,0.66-1.47,1.47-1.47c0.81,0,1.47,0.66,1.47,1.47v3.51c0,0.55,0.45,1,1,1h17.84
    c0.55,0,1-0.45,1-1V895.7c0-0.55-0.45-1-1-1h-17.84c-0.55,0-1,0.45-1,1v3.51c0,0.81-0.66,1.47-1.47,1.47s-1.47-0.66-1.47-1.47
    v-5.98c0-0.81,0.66-1.47,1.47-1.47H1121.69 M1121.69,891.66h-22.79c-0.87,0-1.57,0.71-1.57,1.57v5.98
    c0,0.87,0.71,1.57,1.57,1.57c0.87,0,1.57-0.7,1.57-1.57v-3.51c0-0.5,0.4-0.9,0.9-0.9h17.84c0.5,0,0.9,0.4,0.9,0.9v17.84
    c0,0.5-0.4,0.9-0.9,0.9h-17.84c-0.5,0-0.9-0.4-0.9-0.9v-3.51c0-0.87-0.71-1.57-1.57-1.57c-0.87,0-1.57,0.71-1.57,1.57v5.98
    c0,0.87,0.71,1.57,1.57,1.57h22.79c0.87,0,1.57-0.7,1.57-1.57v-22.79C1123.26,892.36,1122.55,891.66,1121.69,891.66
    L1121.69,891.66z"/>
    </g>
    <g>
    <path d="M1108.06,911.23c-0.36,0-0.7-0.14-0.97-0.38l-0.17-0.16c-0.28-0.26-0.44-0.62-0.44-1c-0.01-0.38,0.14-0.74,0.41-1.02
    l2.44-2.44l-8.94-0.08c-0.78,0-1.41-0.63-1.41-1.41v-0.23c0-0.78,0.63-1.41,1.41-1.41h8.9l-2.43-2.54
    c-0.27-0.27-0.41-0.62-0.41-1s0.15-0.73,0.42-1l0.16-0.16c0.27-0.26,0.62-0.41,0.99-0.41c0.38,0,0.73,0.15,1,0.41l5.22,5.21
    c0.55,0.55,0.55,1.44,0,1.99l-5.19,5.21C1108.79,911.08,1108.44,911.23,1108.06,911.23z"/>
    <path fill="#FFFFFF" d="M1108.03,898.04c0.36,0,0.71,0.14,0.96,0.4l5.22,5.21c0.53,0.53,0.53,1.39,0,1.92l-5.19,5.21
    c-0.26,0.26-0.6,0.4-0.96,0.4c-0.35,0-0.68-0.13-0.93-0.37l-0.17-0.16c-0.27-0.25-0.42-0.6-0.43-0.97c0-0.37,0.14-0.72,0.4-0.98
    l2.44-2.44l0.17-0.17h-0.24h-8.9c-0.75,0-1.36-0.61-1.36-1.36v-0.23c0-0.75,0.61-1.36,1.36-1.36h8.9h0.24l-0.17-0.17l-2.46-2.46
    c-0.26-0.26-0.4-0.6-0.4-0.96c0-0.36,0.14-0.71,0.4-0.96l0.16-0.16C1107.33,898.18,1107.67,898.04,1108.03,898.04
    M1108.03,897.94c-0.37,0-0.74,0.14-1.03,0.42l-0.16,0.16c-0.57,0.57-0.57,1.49,0,2.07l2.46,2.46h-8.9
    c-0.8,0-1.46,0.65-1.46,1.46v0.23c0,0.8,0.65,1.46,1.46,1.46h8.9l-2.44,2.44c-0.58,0.58-0.57,1.53,0.03,2.09l0.17,0.16
    c0.28,0.26,0.64,0.4,1,0.4c0.37,0,0.75-0.14,1.03-0.43l5.19-5.21c0.57-0.57,0.57-1.49,0-2.06l-5.22-5.21
    C1108.78,898.08,1108.4,897.94,1108.03,897.94L1108.03,897.94z"/>
    </g>
    </g>
    </g>
    </g>
    <g>
    <g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/603FB23B.png"}  transform="matrix(1 0 0 1 976.8594 615.2876)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M1033.16,645.49c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.65,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C1023.52,623.96,1033.16,633.6,1033.16,645.49z"/>
    </g>
    </g>
    </g>
    <g>
    <g>
    <g>
    <path d="M1000.24,660.12c-0.84,0-1.52-0.68-1.52-1.52v-5.98c0-0.84,0.68-1.52,1.52-1.52s1.52,0.68,1.52,1.52v3.5
    c0,0.52,0.43,0.95,0.95,0.95h17.84c0.52,0,0.95-0.43,0.95-0.95v-17.84c0-0.52-0.43-0.95-0.95-0.95h-17.84
    c-0.52,0-0.95,0.43-0.95,0.95v3.5c0,0.84-0.68,1.52-1.52,1.52s-1.52-0.68-1.52-1.52v-5.98c0-0.84,0.68-1.52,1.52-1.52h22.79
    c0.84,0,1.52,0.68,1.52,1.52v22.79c0,0.84-0.68,1.52-1.52,1.52H1000.24z"/>
    <path fill="#FFFFFF" d="M1023.03,634.34c0.81,0,1.47,0.66,1.47,1.47v22.79c0,0.81-0.66,1.47-1.47,1.47h-22.79
    c-0.81,0-1.47-0.66-1.47-1.47v-5.98c0-0.81,0.66-1.47,1.47-1.47c0.81,0,1.47,0.66,1.47,1.47v3.5c0,0.55,0.45,1,1,1h17.84
    c0.55,0,1-0.45,1-1v-17.84c0-0.55-0.45-1-1-1h-17.84c-0.55,0-1,0.45-1,1v3.5c0,0.81-0.66,1.47-1.47,1.47
    c-0.81,0-1.47-0.66-1.47-1.47v-5.98c0-0.81,0.66-1.47,1.47-1.47H1023.03 M1023.03,634.24h-22.79c-0.87,0-1.57,0.7-1.57,1.57
    v5.98c0,0.87,0.71,1.57,1.57,1.57c0.87,0,1.57-0.7,1.57-1.57v-3.5c0-0.5,0.4-0.9,0.9-0.9h17.84c0.5,0,0.9,0.4,0.9,0.9v17.84
    c0,0.5-0.4,0.9-0.9,0.9h-17.84c-0.5,0-0.9-0.4-0.9-0.9v-3.5c0-0.87-0.71-1.57-1.57-1.57c-0.87,0-1.57,0.7-1.57,1.57v5.98
    c0,0.87,0.71,1.57,1.57,1.57h22.79c0.87,0,1.57-0.7,1.57-1.57v-22.79C1024.6,634.94,1023.89,634.24,1023.03,634.24
    L1023.03,634.24z"/>
    </g>
    <g>
    <path d="M1009.4,653.81c-0.36,0-0.7-0.14-0.97-0.38l-0.17-0.16c-0.28-0.26-0.44-0.62-0.44-1s0.14-0.74,0.41-1.02l2.44-2.44
    l-8.94-0.08c-0.78,0-1.41-0.63-1.41-1.41v-0.23c0-0.78,0.63-1.41,1.41-1.41h8.9l-2.43-2.54c-0.27-0.27-0.41-0.62-0.41-1
    c0-0.38,0.15-0.73,0.42-1l0.16-0.16c0.27-0.26,0.62-0.41,0.99-0.41s0.73,0.15,0.99,0.41l5.22,5.22c0.55,0.55,0.55,1.44,0,1.99
    l-5.19,5.21C1010.13,653.66,1009.78,653.81,1009.4,653.81z"/>
    <path fill="#FFFFFF" d="M1009.37,640.62c0.36,0,0.7,0.14,0.96,0.4l5.22,5.22c0.53,0.53,0.53,1.39,0,1.92l-5.19,5.21
    c-0.26,0.26-0.6,0.4-0.96,0.4c-0.35,0-0.68-0.13-0.93-0.37l-0.17-0.16c-0.27-0.25-0.42-0.6-0.43-0.97c0-0.37,0.14-0.72,0.4-0.98
    l2.44-2.44l0.17-0.17h-0.24h-8.9c-0.75,0-1.36-0.61-1.36-1.36v-0.23c0-0.75,0.61-1.36,1.36-1.36h8.9h0.24l-0.17-0.17l-2.46-2.46
    c-0.26-0.26-0.4-0.6-0.4-0.96c0-0.36,0.14-0.71,0.4-0.96l0.16-0.16C1008.67,640.76,1009.01,640.62,1009.37,640.62
    M1009.37,640.52c-0.37,0-0.74,0.14-1.03,0.42l-0.16,0.16c-0.57,0.57-0.57,1.5,0,2.07l2.46,2.46h-8.9
    c-0.81,0-1.46,0.65-1.46,1.46v0.23c0,0.8,0.65,1.46,1.46,1.46h8.9l-2.44,2.44c-0.58,0.58-0.57,1.53,0.03,2.09l0.17,0.16
    c0.28,0.27,0.64,0.4,1,0.4c0.37,0,0.75-0.14,1.03-0.43l5.19-5.21c0.57-0.57,0.57-1.49,0-2.06l-5.22-5.22
    C1010.12,640.66,1009.75,640.52,1009.37,640.52L1009.37,640.52z"/>
    </g>
    </g>
    </g>
    </g>
    <g>
    <g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/603FB234.png"}  transform="matrix(1 0 0 1 1205.8594 476.2876)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M1262.77,506.58c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53S1262.77,494.69,1262.77,506.58z"/>
    </g>
    </g>
    </g>
    <g>
    <path d="M1252.82,505.44h-9.15v-9.15c0-1.01-0.82-1.83-1.83-1.83h-1.22c-1.01,0-1.83,0.82-1.83,1.83v9.15h-9.14
    c-1.01,0-1.83,0.82-1.83,1.83v1.22c0,1.01,0.82,1.83,1.83,1.83h9.14v9.15c0,1.01,0.82,1.83,1.83,1.83h1.22
    c1.01,0,1.83-0.82,1.83-1.83v-9.15h9.15c1.01,0,1.83-0.82,1.83-1.83v-1.22C1254.65,506.25,1253.83,505.44,1252.82,505.44z"/>
    </g>
    </g>
    <g>
    <g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/603FB237.png"}  transform="matrix(1 0 0 1 673.4565 489.2446)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M729.69,519.07c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53S729.69,507.18,729.69,519.07z"/>
    </g>
    </g>
    </g>
    <g>
    <path d="M710.55,524.68c0.03-0.01,0.05-0.01,0.08-0.01c0.09,0,0.18,0.06,0.21,0.15c0,0,0.03,0.07,0.09,0.21
    c0.06,0.14,0.16,0.35,0.31,0.61c0.29,0.52,1.43,2.06,1.43,2.06s3.59,3.56,7.15,4.94l-0.42-0.55c-1.18-1.64-0.8-2.91-1.91-6.06
    c-1.7-4.77-8.01-3.39-8.47-3.74c0,0-2.71,8.17,4.37,9.47c2.13,0.39,3.62,0.51,4.51,0.54c-5.87-2.92-7.48-7.3-7.49-7.33
    C710.37,524.85,710.43,524.72,710.55,524.68 M698.18,526.04c-1.12,3.15-0.74,4.42-1.91,6.06l-0.42,0.55
    c3.55-1.38,5.79-3.33,7.15-4.94c0.69-0.81,1.15-1.54,1.43-2.06c0.14-0.26,0.24-0.47,0.31-0.61c0.06-0.14,0.09-0.21,0.09-0.21
    c0.03-0.09,0.12-0.15,0.21-0.15c0.03,0,0.05,0,0.08,0.01c0.12,0.04,0.18,0.17,0.14,0.29c-0.01,0.03-1.62,4.41-7.49,7.33
    c0.9-0.03,2.38-0.15,4.51-0.54c7.09-1.3,4.37-9.47,4.37-9.47C706.19,522.65,699.88,521.26,698.18,526.04 M717.52,517.13
    c1.12-3.15,0.74-4.42,1.91-6.06l0.42-0.55c-3.55,1.38-5.79,3.33-7.15,4.94c-0.69,0.81-1.15,1.54-1.43,2.06
    c-0.14,0.26-0.24,0.47-0.31,0.61c-0.06,0.14-0.09,0.21-0.09,0.21c-0.03,0.09-0.12,0.15-0.22,0.15c-0.03,0-0.05,0-0.08-0.01
    c-0.12-0.04-0.18-0.17-0.14-0.29c0.01-0.03,1.62-4.41,7.49-7.33c-0.9,0.03-2.38,0.15-4.51,0.54c-7.08,1.3-4.37,9.46-4.37,9.46
    C709.51,520.52,715.82,521.9,717.52,517.13 M702.31,511.4c-2.13-0.39-3.62-0.51-4.51-0.54c5.87,2.92,7.48,7.3,7.49,7.33
    c0.04,0.12-0.02,0.25-0.14,0.29c-0.03,0.01-0.05,0.01-0.08,0.01c-0.09,0-0.18-0.06-0.21-0.15c0,0-0.03-0.07-0.09-0.21
    c-0.06-0.14-0.16-0.35-0.31-0.61c-0.29-0.52-0.75-1.25-1.43-2.06c-1.36-1.61-3.59-3.56-7.15-4.94l0.42,0.55
    c1.18,1.64,0.79,2.91,1.91,6.06c1.7,4.77,8.01,3.39,8.47,3.74C706.68,520.87,709.39,512.7,702.31,511.4"/>
    </g>
    </g>
    <g>
    <g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="80"  xlinkHref={config.domain + "plan-shades/603FB235.png"}  transform="matrix(1 0 0 1 742.5767 489.3706)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M798.77,518.98c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53S798.77,507.09,798.77,518.98z"/>
    </g>
    </g>
    </g>
    <g>
    <g>
    <path d="M776.32,517.48c0.7-1.19,1.6-2.07,2.77-2.65c3.26-1.62,7.15-0.06,8.57,3.38c0.94,2.28,0.61,4.49-0.39,6.66
    c-1.03,2.22-2.51,4.14-4.31,5.76c-1.96,1.77-4.09,3.35-6.14,5.03c-0.35,0.29-0.64,0.3-1.04,0.02c-3.1-2.17-6.02-4.53-8.38-7.51
    c-1.46-1.85-2.64-3.86-2.95-6.25c-0.55-4.23,2.71-8.1,6.91-7.71c1.82,0.17,3.25,1.05,4.38,2.47
    C775.93,516.91,776.09,517.16,776.32,517.48z M774.6,525.21c0.04-0.37,0.05-0.57,0.09-0.75c0.37-1.66,0.75-3.32,1.11-4.99
    c0.08-0.36,0.28-0.79,0.16-1.08c-0.44-1.11-1.23-1.95-2.33-2.62c-0.44,4.38-0.87,8.65-1.32,13.07c1.55-1.1,2.96-2.12,4.38-3.13
    c0.04,0.04,0.09,0.08,0.13,0.12c-0.27,1.93-0.54,3.85-0.81,5.84c-0.47-0.14-0.84-0.25-1.31-0.39c0.55,1.29,1.05,2.47,1.58,3.72
    c0.92-1.03,1.75-1.95,2.63-2.94c-0.47-0.06-0.85-0.1-1.28-0.15c0.78-3.52,1.55-6.97,2.34-10.55
    C778.16,522.65,776.46,523.87,774.6,525.21z"/>
    <path d="M787.59,512.64c-0.81,0-1.53-0.02-2.25,0.01c-0.39,0.01-0.53-0.15-0.52-0.51c0.01-0.59,0.01-1.18,0-1.76
    c-0.01-0.37,0.13-0.52,0.52-0.51c0.72,0.02,1.44,0.01,2.25,0.01c0-0.76,0.02-1.48-0.01-2.2c-0.02-0.43,0.13-0.6,0.57-0.59
    c0.57,0.02,1.14,0.02,1.71,0c0.42-0.02,0.54,0.17,0.53,0.57c-0.02,0.57,0.02,1.14-0.01,1.71c-0.03,0.43,0.15,0.54,0.55,0.52
    c0.57-0.03,1.14,0.01,1.71-0.02c0.4-0.02,0.57,0.11,0.56,0.53c-0.03,0.57-0.02,1.14,0,1.71c0.01,0.4-0.13,0.57-0.54,0.55
    c-0.59-0.02-1.18,0.02-1.76-0.01c-0.4-0.02-0.51,0.14-0.5,0.51c0.02,0.61-0.01,1.22,0.01,1.82c0.01,0.34-0.13,0.46-0.44,0.45
    c-0.61-0.01-1.22-0.01-1.82,0c-0.36,0.01-0.53-0.12-0.52-0.52C787.61,514.19,787.59,513.47,787.59,512.64z"/>
    </g>
    </g>
    </g>
    <g>
    <g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/603FB22B.png"}  transform="matrix(1 0 0 1 676.8599 871.2871)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M733.61,901.42c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C723.97,879.88,733.61,889.53,733.61,901.42z"/>
    </g>
    </g>
    </g>
    <g>
    <polygon points="721.4,895.93 720.21,893.36 714.65,895.93 		"/>
    <path d="M712.31,906.79c1.71,0,3.09-1.39,3.09-3.09c0-1.71-1.39-3.09-3.09-3.09c-1.7,0-3.09,1.39-3.09,3.09
    C709.22,905.41,710.61,906.79,712.31,906.79z"/>
    <polygon points="717.89,893.53 714.07,890.81 710.41,895.93 712.71,895.93 		"/>
    <polygon points="702.91,911.43 704.1,914.01 709.65,911.43 		"/>
    <polygon points="706.42,913.83 710.24,916.56 713.9,911.43 711.6,911.43 		"/>
    <path d="M722.04,896.75C722.04,896.75,722.04,896.75,722.04,896.75h-12.43h0l0,0h-5.58h-1.64h-3.23v13.89h19.92h1.64h4.73v-13.89
    H722.04C722.05,896.75,722.04,896.75,722.04,896.75z M723.83,900.39c0.23,0,0.41,0.18,0.41,0.41v5.79c0,0.23-0.18,0.41-0.41,0.41
    c-1.06,0-2.1,1.01-2.1,2.04c0,0.23-0.18,0.41-0.41,0.41h-12.48c-0.23,0-0.41-0.18-0.41-0.41s0.18-0.41,0.41-0.41h12.11
    c0.21-1.18,1.27-2.22,2.47-2.42v-5.04c-1.2-0.2-2.27-1.24-2.47-2.42h-17.49c-0.08,0.58-0.35,1.13-0.74,1.57
    c-0.42,0.46-0.95,0.76-1.51,0.86v5.02c0.57,0.1,1.09,0.39,1.51,0.86c0.4,0.44,0.66,1,0.74,1.57h3.75c0.23,0,0.41,0.18,0.41,0.41
    s-0.18,0.41-0.41,0.41h-4.13c-0.23,0-0.41-0.18-0.41-0.41c0-1.1-0.88-2.05-1.88-2.05c-0.23,0-0.41-0.18-0.41-0.41v-5.77
    c0-0.23,0.18-0.41,0.41-0.41c1,0,1.88-0.96,1.88-2.05c0-0.23,0.18-0.41,0.41-0.41h18.25c0.23,0,0.41,0.18,0.41,0.41
    C721.73,899.38,722.78,900.39,723.83,900.39z M708.4,903.7c0-2.16,1.75-3.91,3.91-3.91s3.91,1.76,3.91,3.91
    c0,2.15-1.75,3.91-3.91,3.91S708.4,905.86,708.4,903.7z"/>
    </g>
    </g>
    <g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/603FB22C.png"}  transform="matrix(1 0 0 1 538.8599 867.2871)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M595.27,897.28c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.66,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53S595.27,885.39,595.27,897.28z"/>
    </g>
    </g>
    </g>
    <g>
    <polygon points="582.62,891.26 581.43,888.69 575.88,891.26 	"/>
    <path d="M573.54,902.13c1.71,0,3.09-1.39,3.09-3.09c0-1.71-1.39-3.09-3.09-3.09c-1.7,0-3.09,1.39-3.09,3.09
    C570.45,900.74,571.83,902.13,573.54,902.13z"/>
    <polygon points="579.11,888.86 575.29,886.14 571.63,891.26 573.93,891.26 	"/>
    <polygon points="564.13,906.76 565.32,909.34 570.88,906.76 	"/>
    <polygon points="567.65,909.16 571.47,911.89 575.12,906.76 572.82,906.76 	"/>
    <path d="M583.27,892.08C583.27,892.08,583.27,892.08,583.27,892.08h-12.43h0l0,0h-5.58h-1.64h-3.23v13.89h19.92h1.64h4.73v-13.89
    H583.27C583.27,892.08,583.27,892.08,583.27,892.08z M585.06,895.72c0.23,0,0.41,0.18,0.41,0.41v5.79c0,0.23-0.18,0.41-0.41,0.41
    c-1.06,0-2.1,1.01-2.1,2.04c0,0.23-0.18,0.41-0.41,0.41h-12.48c-0.23,0-0.41-0.18-0.41-0.41s0.18-0.41,0.41-0.41h12.11
    c0.21-1.18,1.27-2.22,2.47-2.42v-5.04c-1.2-0.2-2.27-1.24-2.47-2.42h-17.49c-0.08,0.57-0.35,1.13-0.74,1.57
    c-0.42,0.47-0.95,0.76-1.51,0.86v5.02c0.57,0.1,1.09,0.39,1.51,0.86c0.4,0.44,0.66,1,0.74,1.57h3.75c0.23,0,0.41,0.18,0.41,0.41
    s-0.18,0.41-0.41,0.41h-4.13c-0.23,0-0.41-0.18-0.41-0.41c0-1.09-0.88-2.05-1.88-2.05c-0.23,0-0.41-0.18-0.41-0.41v-5.77
    c0-0.23,0.18-0.41,0.41-0.41c1,0,1.88-0.96,1.88-2.05c0-0.23,0.18-0.41,0.41-0.41h18.25c0.23,0,0.41,0.18,0.41,0.41
    C582.96,894.71,584,895.72,585.06,895.72z M569.63,899.04c0-2.16,1.75-3.91,3.91-3.91s3.91,1.75,3.91,3.91s-1.75,3.91-3.91,3.91
    S569.63,901.19,569.63,899.04z"/>
    </g>
    <g>

    <image overflow="visible" opacity="0.75" width="64" height="81"  xlinkHref={config.domain + "plan-shades/603FB22F.png"}  transform="matrix(1 0 0 1 818.0776 703.2993)">
    </image>
    <g>
    <path fill="#FFFFFF" d="M874.49,733.29c0,10.86-9.84,27.69-18.96,37.27c-1.37,1.44-3.65,1.43-4.97-0.06
    c-8.48-9.59-19.13-26.37-19.13-37.21c0-11.89,9.64-21.53,21.53-21.53C864.85,711.76,874.49,721.4,874.49,733.29z"/>
    </g>
    </g>
    <g>
    <polygon points="861.84,727.28 860.65,724.7 855.1,727.28 	"/>
    <path d="M852.75,738.14c1.7,0,3.09-1.39,3.09-3.09s-1.39-3.09-3.09-3.09c-1.71,0-3.09,1.39-3.09,3.09S851.05,738.14,852.75,738.14z
    "/>
    <polygon points="858.33,724.88 854.51,722.15 850.85,727.28 853.15,727.28 	"/>
    <polygon points="843.35,742.77 844.54,745.35 850.1,742.77 	"/>
    <polygon points="846.86,745.18 850.68,747.9 854.34,742.77 852.04,742.77 	"/>
    <path d="M862.48,728.1C862.48,728.1,862.48,728.1,862.48,728.1h-12.43l0,0l0,0h-5.58h-1.64h-3.23v13.89h19.92h1.64h4.73V728.1
    H862.48C862.48,728.1,862.48,728.1,862.48,728.1z M864.28,731.74c0.23,0,0.41,0.18,0.41,0.41v5.79c0,0.23-0.18,0.41-0.41,0.41
    c-1.06,0-2.1,1.01-2.1,2.04c0,0.23-0.18,0.41-0.41,0.41h-12.48c-0.23,0-0.41-0.18-0.41-0.41c0-0.23,0.18-0.41,0.41-0.41h12.11
    c0.21-1.18,1.27-2.22,2.47-2.42v-5.04c-1.2-0.2-2.27-1.24-2.47-2.42H843.9c-0.09,0.58-0.35,1.13-0.74,1.57
    c-0.42,0.46-0.95,0.76-1.51,0.86v5.02c0.57,0.1,1.09,0.39,1.51,0.86c0.4,0.44,0.66,1,0.74,1.57h3.75c0.23,0,0.41,0.18,0.41,0.41
    c0,0.23-0.18,0.41-0.41,0.41h-4.13c-0.23,0-0.41-0.18-0.41-0.41c0-1.09-0.88-2.05-1.88-2.05c-0.23,0-0.41-0.18-0.41-0.41v-5.77
    c0-0.23,0.18-0.41,0.41-0.41c1,0,1.88-0.96,1.88-2.05c0-0.23,0.18-0.41,0.41-0.41h18.25c0.23,0,0.41,0.18,0.41,0.41
    C862.18,730.72,863.22,731.74,864.28,731.74z M848.84,735.05c0-2.16,1.75-3.91,3.91-3.91s3.91,1.75,3.91,3.91s-1.75,3.91-3.91,3.91
    S848.84,737.2,848.84,735.05z"/>
    </g>
    </svg>

    );
    }
}

export default Pins;