import React, { Component } from 'react';
import { connect } from "react-redux";

//actions
import { setPost } from "../../actions/pageActions";
import { setPopup, setRedirection } from "../../actions/popupActions";

//components
import Popup from "../Popup";

//tools
import classnames from "classnames";
import MediaQuery from "react-responsive";
import { Redirect } from "react-router";
import Moment from "react-moment";
import moment from "moment";


class SquarePosts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itemHovered:null
        };
        this.handlePostSelection = this.handlePostSelection.bind(this);
    }

    handlePostSelection = ( popup, url, title, txt, date, cover, seasonGradient, bg, cat
    ) => {
        this.props.setSelectedPost(url,cat);
        this.props.setPopup( { popup, url, title, txt, date, cover, seasonGradient, bg } );
        this.props.setRedirection( popup ? false : url);
    };
    render() {

        const {
            pageData,
            pageDataPosts,
            pageOptions,
            seasonGradient,
            bg,
            miniatura,
            popupData,
            redirect,
            redirectLink,
            cat
        } = this.props;

        const pageTitle = pageData.tytul;
        return (
            <>
                { redirect && <Redirect to={redirectLink}/> }
                <div className="row" >
                    {
                        pageDataPosts
                            .filter(item => !item.acf.archive)
                            .slice(0,pageData.qty)
                            .filter(item => {
                                const today = new Date();
                                const todaysDate = moment(today).format("YYYY/MM/DD");
                                const endDate = moment(item.acf.data_zakonczenia).format("YYYY/MM/DD/");
                                const startDate = moment(item.acf.data_publikacji).format("YYYY/MM/DD/");
                                return !endDate || todaysDate <= endDate
                            })
                            .map( (item,index) => {

                                const textColor = index === this.state.itemHovered ? pageOptions.kolor_sezonowy_tekst : "#000000";
                                return(
                                    <div
                                        key={index}
                                        data-startdate={item.acf.data_publikacji}
                                        data-enddate={item.acf.data_zakonczenia}
                                        className={classnames(
                                            "col",
                                            "col-xs-12 col-md-6",{
                                                "odd":  index % 2 === 0,
                                                "active":index === this.state.itemHovered
                                            } )}
                                        onMouseOver={() => this.setState({itemHovered:index})}
                                        style={index === this.state.itemHovered ? seasonGradient : {}}

                                    >
                                        <div className="row center-xs middle-xs"
                                             onClick={
                                                 () => this.handlePostSelection (
                                                     item.acf.popup,
                                                     item.acf.url,
                                                     item.acf.tytul,
                                                     item.acf.tekst,
                                                     item.acf.data_publikacji,
                                                     item.acf.miniatura_news,
                                                     seasonGradient,
                                                     bg,
                                                     cat
                                                 )
                                             }>
                                            <div key={index}
                                                 className="col col-xs-12 col-md-7 " >
                                                <MediaQuery minWidth={992}>
                                                    <div
                                                        style={seasonGradient}
                                                        className="mask"
                                                        onClick={
                                                            () => this.handlePostSelection (
                                                                item.acf.popup,
                                                                item.acf.url,
                                                                item.acf.tytul,
                                                                item.acf.tekst,
                                                                item.acf.data_publikacji,
                                                                item.acf.miniatura_news,
                                                                seasonGradient,
                                                                bg,
                                                                cat
                                                            )
                                                        }
                                                    />
                                                </MediaQuery>
                                                <MediaQuery maxWidth={991}>
                                                    <figcaption className="mobile-title">
                                                        <p dangerouslySetInnerHTML={{__html:item.acf.podtytul}}/>
                                                    </figcaption>
                                                </MediaQuery>
                                                <figure
                                                    style={{
                                                        backgroundColor: '#2c6d62',
                                                        backgroundImage:`url(${item.acf[miniatura]})`
                                                    }}
                                                >
                                                    <img src={pageOptions.wydarzenia_news_transparent} alt={item.acf.tytul} />
                                                </figure>

                                            </div>
                                            <div className="col  col-xs-12 col-md-5" >
                                                <figcaption>
                                                    <MediaQuery minWidth={992}>
                                                        <nav>
                                                            <p
                                                                style={{color: textColor}}
                                                                dangerouslySetInnerHTML={{__html:item.acf.tytul}} />
                                                            {
                                                                pageData.tytul &&
                                                                pageTitle.includes('promo') &&
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html:item.acf.podtytul}}
                                                                    className="shop-name"
                                                                    style={{color: textColor}}
                                                                />
                                                            }

                                                            <span style={{color: textColor}}>+ {pageOptions.teksty_wiecej}</span>
                                                        </nav>
                                                    </MediaQuery>
                                                    <MediaQuery maxWidth={991}>
                                                        {
                                                            (popupData.url && item.acf.url === popupData.url)
                                                                ?
                                                                <>
                                                                    <h3 dangerouslySetInnerHTML={{__html:item.acf.tytul}}/>
                                                                    <span dangerouslySetInnerHTML={{__html: item.acf.tekst}} />
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </MediaQuery>
                                                </figcaption>
                                            </div>
                                        </div>
                                        <MediaQuery minWidth={992}>
                                            {
                                                (popupData.url && item.acf.url === popupData.url)
                                                    ?
                                                    <Popup data={ popupData }/>
                                                    :
                                                    <></>
                                            }
                                        </MediaQuery>
                                    </div>
                                )
                            }

                            )
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    popupData: state.popup.popupData,
    redirect: state.popup.redirect,
    redirectLink: state.popup.redirectLink
});

const mapDispatchToProps = dispatch => ({
    setPopup: data => dispatch( setPopup(data)),
    setRedirection: value => dispatch( setRedirection(value) ),
    setSelectedPost: (url,cat) => dispatch( setPost(url,cat))
});


export default connect(mapStateToProps,mapDispatchToProps)(SquarePosts);