import React from 'react';

const ToddlerIcon= ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_search" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="31.16px" height="31.45px"
             viewBox="0 0 31.16 31.45">
            <g>
                <g>
                    <path fill={mainColor} d="M17.21,15.35c-0.62-0.4-1.19-0.75-1.75-1.12c-0.41-0.26-0.48-0.52-0.25-0.94c0.11-0.2,0.25-0.39,0.41-0.57
					c0.34-0.38,0.63-0.41,1.06-0.12c0.87,0.6,1.75,1.19,2.62,1.79c0.72,0.5,0.79,0.94,0.3,1.64c-0.12,0.18-0.24,0.36-0.41,0.61
					c0.69,0,1.32,0.04,1.94-0.02c0.15-0.01,0.36-0.32,0.39-0.53c0.07-0.35,0.01-0.71,0.02-1.07c0.02-0.46,0.31-0.69,0.87-0.69
					c0.56,0,0.87,0.22,0.88,0.68c0.01,1.46,0.02,2.92,0,4.39c-0.01,0.62-0.41,1.03-1.04,1.04c-1.43,0.02-2.86,0.04-4.29-0.01
					c-1.19-0.04-1.85-0.88-1.93-2.08c0-0.7,0.01-0.45,0-0.7c0.07-0.82,0.27-1.24,0.71-1.79C16.9,15.73,17.03,15.55,17.21,15.35z"/>
                    <path fill={mainColor} d="M25.65,20.22c-1.07,0-1.9-0.82-1.89-1.88c0-1.06,0.83-1.89,1.89-1.89c1.03,0,1.86,0.83,1.88,1.86
					C27.53,19.38,26.71,20.22,25.65,20.22z"/>
                </g>
                <g>
                    <path fill={mainColor} d="M16.64,2.61c0.03-1.38,1.15-2.49,2.51-2.47c1.37,0.02,2.48,1.16,2.47,2.52c-0.02,1.37-1.17,2.49-2.52,2.47
					C17.72,5.1,16.61,3.97,16.64,2.61z"/>
                    <g>
                        <path fill={mainColor} d="M7.58,27.64v-1.29c0,0.44,0,0.84,0,1.19C7.58,27.57,7.58,27.6,7.58,27.64z"/>
                        <path fill={mainColor} d="M20.53,11.49c-0.84-0.59-1.67-1.2-2.53-1.75c-0.57-0.36-0.82-0.82-0.86-1.49c-0.05-1.04-0.09-2.09-0.3-3.1
						c-0.28-1.36-1.26-1.93-2.62-1.65c-0.31,0.06-0.63,0.19-0.91,0.35c-1.74,0.99-3.49,1.98-5.2,3.02C7.02,7.52,6.29,8.54,5.95,9.75
						c-0.61,2.15-1.14,4.33-1.69,6.5c-0.09,0.34-0.23,1.08-0.23,1.08h3.56c0,0,0,0,0.04,10.21c0,0.57,0.3,1.34,1.36,1.34l0,0
						c0,0,0,0,0,0c0,0,0,0,0,0l0,0c1.07,0,1.36-0.77,1.36-1.34c0.04-10.21,0.04-10.21,0.04-10.21h0.02c0-0.01,0-0.01,0-0.01h3.54
						c0,0-1.94-6.96-1.79-7.29c0.15-0.35,0.72-0.52,1.12-0.75c0.59-0.33,1.2-0.63,1.89-0.99c0,0.48,0.02,0.83,0,1.18
						c-0.03,0.54,0.19,0.91,0.63,1.21c1.2,0.81,2.37,1.66,3.56,2.48c0.53,0.37,0.86,0.28,1.3-0.36
						C21.07,12.2,21.04,11.85,20.53,11.49z"/>
                    </g>
                </g>
                <path fill={mainColor} d="M28.03,22.78H15.3c-0.38,0-0.69-0.31-0.69-0.69v-0.28c0-0.38,0.31-0.69,0.69-0.69h12.74c0.38,0,0.69,0.31,0.69,0.69v0.28
				C28.72,22.47,28.42,22.78,28.03,22.78z"/>
            </g>
        </svg>
    );
};

export default ToddlerIcon;