import {
    SET_SELECTED_GALLERY,
    TOGGLE_LIGHTBOX
} from "../actions/lightboxActions";

const defaultState = {
    lightboxOpened: false,
    selectedGallery: {}
};

const lightbox = (state = defaultState, action = undefined) => {
    const {type} = action;

    function toggleLightbox (state) {
        const newState = Object.assign({}, state);
        newState.lightboxOpened = !newState.lightboxOpened;
        return newState.lightboxOpened;
    }

    switch (type) {
        case TOGGLE_LIGHTBOX: {
            return {
                ...state,
                lightboxOpened: toggleLightbox(state)
            }
        }
        case SET_SELECTED_GALLERY: {
            return {
                ...state,
                selectedGallery: action.gallery
            }
        }
        default:
            return state;
    }
};

export default lightbox;