import React from 'react';

const CounterTime = ({mainColor}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" width="107.75px" height="82.814px"
             viewBox="840.545 646.025 107.75 82.814">
            <g id="Layer_2">
                <g id="Capa_1">
                    <path fill={mainColor} d="M894.77,688.882L894.77,688.882c-2.503,0.009-4.698,1.674-5.381,4.083h-2.955v3.062h2.955
                        c0.83,2.973,3.912,4.709,6.883,3.879c1.882-0.525,3.353-1.996,3.879-3.879h13.843v-3.062h-13.843
                        C899.469,690.556,897.273,688.891,894.77,688.882z M897.322,694.497c0,1.409-1.143,2.552-2.552,2.552
                        c-1.409,0-2.552-1.143-2.552-2.552c0-1.41,1.143-2.553,2.552-2.553C896.18,691.944,897.322,693.086,897.322,694.497
                        L897.322,694.497z"/>
                    <path fill={mainColor} d="M920.813,672.339c-5.232-6.145-12.468-10.244-20.429-11.572v-5.57h1.191c0.846,0,1.531-0.686,1.531-1.531v-5.444
                        c0-0.846-0.686-1.531-1.531-1.531h-13.61c-0.845,0-1.531,0.686-1.531,1.531v5.444c0,0.845,0.686,1.531,1.531,1.531h1.191v5.565
                        c-5.141,0.854-10.015,2.888-14.239,5.941l-4.083-4.083c-0.598-0.598-1.567-0.598-2.166,0c0,0,0,0,0,0l-5.784,5.784
                        c-0.594,0.597-0.594,1.562,0,2.159l4.093,4.083c-4.192,5.767-6.435,12.721-6.401,19.851c0,18.854,15.336,34.194,34.195,34.194l0,0
                        c18.885-0.001,34.194-15.313,34.192-34.197C928.962,686.375,926.073,678.522,920.813,672.339z M900.044,649.753v2.382h-10.548
                        v-2.382H900.044z M894.77,725.629c-17.185,0.009-31.123-13.915-31.132-31.1c-0.003-6.916,2.297-13.635,6.538-19.098l0.02-0.024
                        c1.583-2.061,3.428-3.906,5.488-5.488l0.066-0.06l0,0c5.438-4.227,12.133-6.514,19.02-6.497c17.167,0,31.132,13.966,31.132,31.133
                        C925.902,711.664,911.938,725.629,894.77,725.629z M897.322,655.197v5.209c-0.951-0.072-1.768-0.105-2.552-0.105
                        c-0.818,0-1.655,0.032-2.552,0.097v-5.201H897.322z M866.138,669.488l3.624-3.625l2.722,2.722
                        c-1.296,1.109-2.504,2.317-3.614,3.613L866.138,669.488z"/>
                    <path fill={mainColor} d="M915.092,674.176c-5.379-5.408-12.694-8.443-20.322-8.431l0,0c-15.854,0-28.751,12.897-28.751,28.751
                        c0,15.854,12.897,28.751,28.751,28.751s28.751-12.897,28.751-28.751C923.533,686.87,920.498,679.555,915.092,674.176z
                         M920.381,696.027c-0.322,5.411-2.348,10.581-5.784,14.772l-2.506-2.508l-2.163,2.166l2.585,2.582
                        c-4.392,4.208-10.14,6.714-16.212,7.067v-4.005h-3.062v4.005c-6.071-0.354-11.817-2.859-16.208-7.067l2.584-2.582l-2.166-2.169
                        l-2.506,2.507c-3.436-4.19-5.46-9.358-5.784-14.769h2.644v-3.062h-2.642c0.345-5.735,2.608-11.188,6.426-15.481l1.871,1.871
                        l2.166-2.167l-1.872-1.859c4.293-3.824,9.748-6.093,15.486-6.442v2.644h3.062v-2.644c5.738,0.344,11.193,2.608,15.49,6.427
                        l-1.863,1.863l2.166,2.167l1.859-1.872c3.82,4.297,6.084,9.754,6.428,15.494h-2.644v3.062H920.381z"/>
                </g>
            </g>
        </svg>
    );
};

export default CounterTime;