import React from 'react';

const Box = ({mainColor}) => {
    return (
        <svg version="1.1" id="i_box"
             xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="512px" height="512px"
             viewBox="0 0 512 512" >
            <g>
                <g>
                    <path fill={mainColor} d="M491.7,113L259.3,0.7c-2.1-1-4.5-1-6.5,0L20.3,113c-2.6,1.3-4.2,3.9-4.2,6.8v272.5c0,2.9,1.6,5.5,4.2,6.8
			l232.5,112.2c1,0.5,2.1,0.7,3.3,0.7c1.1,0,2.2-0.2,3.3-0.7L491.7,399c2.6-1.3,4.2-3.9,4.2-6.8V119.7
			C496,116.8,494.3,114.2,491.7,113z M256,15.8l215.2,103.9l-62.4,30.1c-0.4-0.3-0.8-0.6-1.3-0.8L193.8,45.9L256,15.8z M176.9,54.3
			l214.9,103.7l-44,21.2L132.9,75.6L176.9,54.3z M396.8,172.3v78.5l-41.1,19.8v-78.5L396.8,172.3z M481,387.6l-217.5,105V236.7
			l51.9-25c3.7-1.8,5.3-6.3,3.5-10c-1.8-3.7-6.3-5.3-10-3.5L256,223.6l-20.8-10c-3.7-1.8-8.2-0.2-10,3.5s-0.2,8.2,3.5,10l19.8,9.6
			v255.9L31,387.6V131.7l165.6,79.9c1.1,0.5,2.2,0.7,3.3,0.7c2.8,0,5.5-1.6,6.8-4.2c1.8-3.7,0.2-8.2-3.5-10L40.8,119.7l74.5-36
			l225.3,108.8c0,0,0.1,0.1,0.1,0.1v90c0,2.6,1.3,5,3.5,6.4c1.2,0.8,2.6,1.1,4,1.1c1.1,0,2.2-0.2,3.3-0.7l56.1-27.1
			c2.6-1.3,4.2-3.9,4.2-6.8v-90.5l69.2-33.4L481,387.6L481,387.6z"/>
                </g>
            </g>
            <g>
                <g>
                    <path fill={mainColor} d="M92.9,358.5L58.8,342c-3.7-1.8-8.2-0.2-10,3.5s-0.2,8.2,3.5,10L86.4,372c1.1,0.5,2.2,0.7,3.3,0.7
			c2.8,0,5.5-1.6,6.8-4.2C98.2,364.8,96.7,360.3,92.9,358.5z"/>
                </g>
            </g>
            <g>
                <g>
                    <path fill={mainColor} d="M124.3,338l-65.5-31.6c-3.7-1.8-8.2-0.2-10,3.5c-1.8,3.7-0.2,8.2,3.5,10l65.5,31.6c1.1,0.5,2.2,0.7,3.3,0.7
			c2.8,0,5.5-1.6,6.8-4.2C129.6,344.3,128.1,339.8,124.3,338z"/>
                </g>
            </g>
        </svg>
    );
};

export default Box;