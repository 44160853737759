import React, { Component } from 'react';
import {connect} from "react-redux";

//actions
import {setPost} from "../../../actions/pageActions"

// components
import Page from '../../Page';
import List from '../../List';
import SearchSection from '../../SearchSection';
import Plan from './Plan';

//config
import { config }  from "../../../config";

//data
import { columns } from './dataLists';

class ViewPlanCentrum extends Component {

    componentDidMount() {
        const {
            match,
            setSelectedPost
        } = this.props;

        match && setSelectedPost(match.params.shopId)


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.searchFocused) {
            window.scrollTo(0,120);
        }
    }

    render() {
        const {
            pageData,
            pageDataPosts,
            match
        } = this.props;

        const inactiveColors = {
            top : pageData.inactive_top,
            front : pageData.inactive_front,
            side : pageData.inactive_side
        };
        return (
            <Page pageIndex={config.pageIndex.planCentrum}
                  restName="sklepy" >
                <article className="page plan-centrum content">
                    <div className="boxed">

                            <SearchSection dataSet={pageDataPosts}
                                           pageData={pageData}
                                           inputColSize={5}
                                           labelColSize={2}
                                           offset={5}
                            />

                            <div className="row subtitle-container center-xs start-md">
                                <div className={'col col-xs-12'}>
                                    <h2>{pageData.podtytul}</h2>
                                </div>
                            </div>

                            <Plan
                                mask={pageData.maska}
                                legend={pageData.legend}
                                shopsData={pageDataPosts}
                                inactiveColors={inactiveColors}
                            />

                            <div className="row shops-row-container center-xs start-md">
                                <div className={'col col-xs-12 col-sm-6  col-md-3'}>
                                    <div className={'row'}>
                                        { columns.col1.map( (item,index) =>
                                            <List data={pageDataPosts}
                                                  group={'sklepy'}
                                                  category={item.cat}
                                                  categoryId={'kategoria_sklepu'}
                                                  grid={'col col-xs-12'}
                                                  key={item.cat+index}
                                                  theme={item.theme}
                                            />)}
                                    </div>
                                </div>
                                <div className={'col col-xs-12 col-sm-6  col-md-3'}>
                                    <div className={'row'}>
                                        { columns.col2.map( (item,index) =>
                                            <List data={pageDataPosts}
                                                  group={'sklepy'}
                                                  category={item.cat}
                                                  categoryId={'kategoria_sklepu'}
                                                  grid={'col col-xs-12'}
                                                  key={item.cat+index}
                                                  theme={item.theme}
                                            />)}
                                    </div>
                                </div>
                                <div className={'col col-xs-12 col-sm-6  col-md-3'}>
                                    <div className={'row'}>
                                        { columns.col3.map( (item,index) =>
                                            <List data={pageDataPosts}
                                                  group={'sklepy'}
                                                  category={item.cat}
                                                  categoryId={'kategoria_sklepu'}
                                                  grid={'col col-xs-12'}
                                                  key={item.cat+index}
                                                  theme={item.theme}
                                            />)}
                                    </div>
                                </div>
                                <div className={'col col-xs-12 col-sm-6 col-md-3'}>
                                    <div className={'row'}>
                                        { columns.col4.map( (item,index) =>
                                            <List data={pageDataPosts}
                                                  group={'uslugi'}
                                                  category={item.cat}
                                                  categoryId={'katogoria_uslug'}
                                                  grid={'col col-xs-12'}
                                                  key={item.cat+index}
                                                  theme={item.theme}
                                            />)}
                                    </div>
                                </div>
                            </div>

                    </div>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions,
    pageDataPosts: state.page.pageDataPosts,
    searchFocused: state.page.searchFocused
});

const mapDispatchToProps = dispatch => ({
    setSelectedPost: value => dispatch( setPost(value))
});


export default connect(mapStateToProps,mapDispatchToProps)(ViewPlanCentrum);