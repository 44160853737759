import React from 'react';

const AtmIcon= ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_search" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="31.16px" height="31.45px"
             viewBox="0 0 31.16 31.45">
             <g>
                <polygon fill={mainColor} points="24.82,8.1 23.62,5.52 18.07,8.1 		"/>
                <path fill={mainColor} d="M15.73,18.96c1.7,0,3.09-1.39,3.09-3.09c0-1.7-1.39-3.09-3.09-3.09s-3.09,1.39-3.09,3.09S14.03,18.96,15.73,18.96z"/>
                <polygon fill={mainColor} points="21.3,5.7 17.48,2.97 13.83,8.1 16.12,8.1 		"/>
                <polygon fill={mainColor} points="6.32,23.6 7.52,26.17 13.07,23.6 		"/>
                <polygon fill={mainColor} points="9.84,26 13.66,28.72 17.32,23.6 15.02,23.6 		"/>
                <path fill={mainColor} d="M25.46,8.92C25.46,8.92,25.46,8.92,25.46,8.92H13.03h0c0,0,0,0,0,0H7.45H5.81H2.58v13.89h19.92h1.64h4.73V8.92H25.46
                C25.46,8.92,25.46,8.92,25.46,8.92z M27.25,12.56c0.23,0,0.41,0.18,0.41,0.41v5.79c0,0.23-0.18,0.41-0.41,0.41
                c-1.06,0-2.1,1.01-2.1,2.04c0,0.23-0.18,0.41-0.41,0.41H12.26c-0.23,0-0.41-0.18-0.41-0.41c0-0.23,0.18-0.41,0.41-0.41h12.11
                c0.21-1.18,1.27-2.22,2.47-2.42v-5.04c-1.2-0.2-2.27-1.24-2.47-2.42H6.87c-0.08,0.57-0.35,1.13-0.74,1.57
                c-0.42,0.46-0.95,0.76-1.51,0.86v5.02c0.57,0.1,1.09,0.39,1.51,0.86c0.4,0.44,0.66,1,0.74,1.57h3.75c0.23,0,0.41,0.18,0.41,0.41
                s-0.18,0.41-0.41,0.41H6.49c-0.23,0-0.41-0.18-0.41-0.41c0-1.09-0.88-2.05-1.88-2.05c-0.23,0-0.41-0.18-0.41-0.41v-5.77
                c0-0.23,0.18-0.41,0.41-0.41c1,0,1.88-0.96,1.88-2.05c0-0.23,0.18-0.41,0.41-0.41h18.25c0.23,0,0.41,0.18,0.41,0.41
                C25.15,11.55,26.19,12.56,27.25,12.56z M11.82,15.87c0-2.16,1.75-3.91,3.91-3.91c2.16,0,3.91,1.75,3.91,3.91
                c0,2.16-1.75,3.91-3.91,3.91C13.57,19.78,11.82,18.02,11.82,15.87z"/>
            </g>
        </svg>
    );
};

export default AtmIcon;