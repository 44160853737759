import React from 'react';

const Doceniaj = ({mainColor}) => {
    return (
        <svg
            version="1.1" xmlns="http://www.w3.org/2000/svg"
            id="svg_doceniaj"
            x="0px" y="0px"
            width="82.87px" height="80.2px" viewBox="853.36 648.47 82.87 80.2">
            <path fill={mainColor} d="M929.08,659.33c-9.47-9.45-24.77-9.52-34.34-0.17c-9.69-9.37-25.14-9.12-34.51,0.57c-9.22,9.53-9.15,24.68,0.17,34.12
			l32.83,32.82c0.84,0.83,2.19,0.83,3.03,0l13.51-13.51c0.84-0.84,0.84-2.19,0-3.03s-2.19-0.84-3.03,0l0,0l-12,11.99l-31.31-31.3
			c-7.81-7.9-7.74-20.64,0.17-28.45c7.84-7.75,20.45-7.75,28.29,0l1.34,1.34c0.84,0.84,2.2,0.84,3.03,0l1.34-1.34
			c7.81-7.9,20.55-7.98,28.46-0.17c7.9,7.81,7.98,20.55,0.17,28.45c-0.06,0.06-0.11,0.11-0.17,0.17l-9.87,9.87
			c-0.8,0.87-0.75,2.23,0.12,3.03c0.82,0.76,2.1,0.76,2.92,0l9.87-9.87c9.53-9.52,9.54-24.97,0.01-34.5
			C929.09,659.34,929.08,659.33,929.08,659.33L929.08,659.33z"/>
            <path fill={mainColor} d="M915.18,706.12c-0.45-1.09-1.7-1.62-2.79-1.17c-0.61,0.25-1.08,0.78-1.25,1.42c-0.24,0.85,0.08,1.76,0.8,2.27
			c0.37,0.28,0.82,0.43,1.29,0.44c0.4,0,0.79-0.12,1.12-0.33C915.21,708.18,915.56,707.09,915.18,706.12L915.18,706.12z"/>
        </svg>
    );
};

export default Doceniaj;