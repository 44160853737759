export const columns =
    {
        col1: [
            {
                cat: 'moda'
            }
        ],
        col2: [
            {
                cat: 'bizuteria'
            },
            {
                cat: 'obuwie'
            },
            {
                cat: 'spozywcze'
            }
        ],
        col3: [
            {
                cat: 'zdrowie'
            },
            {
                cat: 'wnetrza'
            },
            {
                cat: 'dzieci'
            }
        ],
        col4: [
            {
                cat: 'sport'
            },
            {
                cat: 'hobby'
            },
            {
                cat: 'rtv'
            }
        ],
        col1_1: [
            {
                cat: 'uslugi'
            },
            {
                cat: 'gastronomia'
            }
        ]
    };