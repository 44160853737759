import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";

// components-
import Page from '../../Page';
import PageHeader from "../../PageHeader";

//tools
import classnames from 'classnames';
import MediaQuery from 'react-responsive';

//config
import { config }  from "../../../config";

//svg
import Bike from "../../../svg/udogodnienia/Bike";
import Book from "../../../svg/udogodnienia/Book";
import Box from "../../../svg/udogodnienia/Box";
import Disabled from "../../../svg/udogodnienia/Disabled";
import Facebook from "../../../svg/udogodnienia/Facebook";
import Family from "../../../svg/udogodnienia/Family";
import Food from "../../../svg/udogodnienia/Food";
import Gift from "../../../svg/udogodnienia/Gift";
import Money from "../../../svg/udogodnienia/Money";
import Parking from "../../../svg/udogodnienia/Parking";
import Pharmacy from "../../../svg/udogodnienia/Pharmacy";
import Plant from "../../../svg/udogodnienia/Plant";
import Recycling from "../../../svg/udogodnienia/Recycling";
import Smiley from "../../../svg/udogodnienia/Smiley";
import Toddler from "../../../svg/udogodnienia/Toddler";
import Wifi from "../../../svg/udogodnienia/Wifi";

const Icons = {
    Bike,
    Book,
    Box,
    Disabled,
    Facebook,
    Family,
    Food,
    Gift,
    Money,
    Parking,
    Pharmacy,
    Plant,
    Recycling,
    Smiley,
    Toddler,
    Wifi
};



class ViewUdogodnienia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeFeature: null,
            showModal:null
        };
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleMouseClick = this.handleMouseClick.bind(this);
    }

    handleMouseEnter(index){
        this.setState({
            activeFeature:index
        })
    }

    handleMouseLeave(){
        this.setState({
            activeFeature:null,
            showModal:null
        })
    }
    handleMouseClick(){
        this.setState({
            showModal:true
        })
    }

    render() {
        const {
            pageData,
            pageDataFeaturesList,
            pageOptions } = this.props;

        const ftrGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_news}deg, 
                ${pageOptions.kolor_1_news} 53%, 
                ${pageOptions.kolor_2_news} 85% 
            )`,
            backgroundSize: '41.667% 100%',
            backgroundPositionY:'top',
            backgroundRepeat: 'no-repeat'
        };

        const ftrGradientActive = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_news}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`
        };

        const seasonTxtColor = {
            color: pageOptions.kolor_sezonowy_tekst
        };

        return (
            <Page pageIndex={config.pageIndex.udogodnienia}>
                <article className="page udogodnienia content">

                    <PageHeader
                        title={pageData.tytul}
                        subtitle={pageData.podtytul}
                        text={pageData.tekst_naglowka}
                        seasonGradient={ftrGradientActive}
                        seasonBlockWidth="33.333%"
                        seasonBlockHeight="331px"
                    />

                    <div className="row features">
                        {
                            pageDataFeaturesList.map((item,index) => {
                                const Icon = Icons[item.ikona];
                                return (
                                        <div
                                            className={classnames(
                                                "feature",
                                                "col",
                                                "col-xs-12",
                                                "col-sm-6",
                                                "col-md-4",{
                                                    "active":index===this.state.activeFeature
                                                }
                                            )}
                                            onMouseEnter={ () => this.handleMouseEnter(index)  }
                                            onMouseLeave={ () => this.handleMouseLeave()  }
                                            onClick={ () => this.handleMouseClick()  }
                                            key={index}>

                                            <div
                                                className="row middle-xs center-xs"
                                                style={ftrGradient}
                                            >
                                                <div className="col col-xs-5 icon middle-xs" >
                                                    {
                                                        Icon
                                                            ? <Icon
                                                                mainColor={ index===this.state.activeFeature ? pageOptions.kolor_1_naglowki : '#FFFFFF'  } />
                                                            : <Fragment /> }
                                                </div>
                                                <div className="col col-xs-7 title"  style={ this.state.activeFeature === index ? seasonTxtColor : {}} >
                                                    { index===this.state.activeFeature &&
                                                        <div className="mask" style={ftrGradientActive} />
                                                    }
                                                    <MediaQuery minWidth={769}>
                                                        <h3
                                                            style={(index===this.state.activeFeature && this.state.showModal) ? {visibility:'hidden'}:{}}
                                                            dangerouslySetInnerHTML={{__html: item.tytul}}
                                                        />
                                                    </MediaQuery>
                                                    <MediaQuery maxWidth={768}>
                                                        <h3 dangerouslySetInnerHTML={{__html: item.tytul}}/>
                                                    </MediaQuery>

                                                </div>
                                            </div>

                                            {
                                                (index===this.state.activeFeature && this.state.showModal) &&
                                                <div className={classnames(
                                                    "modal",{
                                                        "third" : index % 3
                                                    }
                                                )}>
                                                    <nav onClick={ () => this.handleMouseLeave() }>x</nav>
                                                    <figure style={ftrGradientActive}/>
                                                    <h3 dangerouslySetInnerHTML={{__html: item.tytul}}/>
                                                    <p dangerouslySetInnerHTML={{__html: item.opis}}/>
                                                </div>
                                            }



                                        </div>
                                    )

                            })
                        }
                    </div>


                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageDataFeaturesList: state.page.pageDataFeaturesList,
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(ViewUdogodnienia);