import React from 'react';

const WifiIcon= ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_search" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="31.16px" height="31.45px"
             viewBox="0 0 31.16 31.45">
            <g>
                <g>
                    <g>
                        <path fill={mainColor} d="M15.82,20.9c-1.34,0-2.43,1.09-2.43,2.42c0,1.34,1.09,2.42,2.43,2.42s2.43-1.09,2.43-2.42
					C18.25,21.99,17.16,20.9,15.82,20.9z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path fill={mainColor} d="M21.75,17.6c-1.58-1.58-3.69-2.45-5.93-2.45c-2.23,0-4.33,0.87-5.91,2.44c-0.64,0.64-0.64,1.67,0,2.31
					c0.31,0.31,0.72,0.48,1.16,0.48c0.44,0,0.85-0.17,1.16-0.48c0.96-0.96,2.24-1.48,3.6-1.48c1.36,0,2.65,0.53,3.61,1.49
					c0.31,0.31,0.72,0.48,1.16,0.48s0.85-0.17,1.16-0.48C22.39,19.27,22.39,18.24,21.75,17.6z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path fill={mainColor} d="M29.37,10.01c-3.62-3.61-8.43-5.59-13.55-5.59C10.71,4.42,5.9,6.4,2.29,10c-0.64,0.64-0.64,1.67,0,2.31
					c0.31,0.31,0.72,0.48,1.16,0.48c0.44,0,0.85-0.17,1.16-0.48c3-2.98,6.98-4.63,11.22-4.63c4.24,0,8.23,1.65,11.23,4.64
					c0.31,0.31,0.72,0.48,1.16,0.48s0.85-0.17,1.16-0.48C30,11.68,30.01,10.65,29.37,10.01z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path fill={mainColor} d="M25.46,13.9c-2.57-2.57-6-3.98-9.64-3.98c-3.64,0-7.05,1.41-9.63,3.97c-0.31,0.31-0.48,0.72-0.48,1.15
					c0,0.44,0.17,0.85,0.48,1.15c0.31,0.31,0.72,0.48,1.16,0.48c0.44,0,0.85-0.17,1.16-0.48c1.95-1.94,4.55-3.02,7.31-3.02
					c2.77,0,5.37,1.08,7.32,3.03c0.31,0.31,0.72,0.48,1.16,0.48s0.85-0.17,1.16-0.48c0.31-0.31,0.48-0.72,0.48-1.15
					C25.94,14.62,25.77,14.21,25.46,13.9z"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default WifiIcon;