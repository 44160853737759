import React, {Component} from 'react';
import classnames from "classnames";

//svg
import Smiley from "../../../../../svg/Smiley";
import CounterCalories from "../../../../../svg/rajd/CounterCalories";
import CounterDistance from "../../../../../svg/rajd/CounterDistance";
import CounterMembers from "../../../../../svg/rajd/CounterMembers";
import CounterMoney from "../../../../../svg/rajd/CounterMoney";
import CounterTime from "../../../../../svg/rajd/CounterTime";

const Icons = {
    Smiley,
    CounterCalories,
    CounterDistance,
    CounterMembers,
    CounterMoney,
    CounterTime
};

class Liczby extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itemHovered:null
        };
    }

    render() {
        const {
           liczby,
            colors,
            statistics
        } = this.props;

        const gradient = {
            background: `linear-gradient( 
                29deg, 
                #f7b35c 53%, 
                #f49d52 85% 
            )`};

        return (
            <div className="row rules center-xs">
                {
                    liczby &&
                    liczby
                        .filter(item=>!item.wylacz)
                        .map(
                        (item,index) => {
                            const Icon = Icons[item.ikona];
                            const number = parseInt(statistics[item.worksmile_key]);
                            const mnoznik = parseInt(item.podtytul);

                            return (
                                <div
                                    className={classnames(
                                        "col col-xs-12 col-sm-6 col-md-4",{
                                            "first" : item.is_first,
                                            "hovered": index === this.state.itemHovered
                                        }
                                    )}
                                    key={index}
                                    onMouseOver={() => this.setState({itemHovered:index>0 ? index : null})}
                                    style={ index === this.state.itemHovered ? colors.greenGradient : {}}

                                >
                                    <div className="row row-rule-item middle-xs center-xs">
                                        <div className="col col-xs-7 txt">
                                            {
                                                item.is_first ?
                                                    <h3 dangerouslySetInnerHTML={{__html: item.tytul }} />
                                                    :
                                                    <>
                                                        <h4
                                                            dangerouslySetInnerHTML={{__html: item.tytul }}
                                                            style={ index === this.state.itemHovered ? { color:'#f6b938' } : {}} />
                                                        <p
                                                            className="no"
                                                            style={ index === this.state.itemHovered ? { color:'#f6b938' } : {}} >
                                                            { ( statistics[item.worksmile_key] > 0 && item.podtytul ) ? number * mnoznik : "" }
                                                            { ( statistics[item.worksmile_key] > 0 && !item.podtytul) ?  number : "" }
                                                            { ( statistics[item.worksmile_key] === 0 ) && "0"}
                                                            { !statistics[item.worksmile_key] && statistics[item.worksmile_key] !== 0 && item.liczba }
                                                        </p>
                                                        <p
                                                            dangerouslySetInnerHTML={{__html: item.jednostka }}
                                                            className="unit"
                                                            style={ index === this.state.itemHovered ? { color:'#f6b938' } : {}} />
                                                    </>
                                            }
                                        </div>
                                        <div
                                            className="col col-xs-5 center-xs middle-xs icon"
                                            style={gradient}>
                                            <Icon mainColor={ index === this.state.itemHovered ? colors.green : "#FFFFFF"} />
                                        </div>
                                    </div>


                                </div>
                            )
                        }
                    )
                }

            </div>
        );
    }
}

export default Liczby;