import React from 'react';
import Arrow from "../../svg/lightbox/Arrow";

const Back = props => {
    const {
        bg,
        link
    } = props;
    return (
        <a href={link} title="wróć">
            <nav
                className="back-badge"
                style={{backgroundColor:bg}}
            >
                <Arrow mainColor="#FFFFFF"/>
            </nav>
        </a>
    );
};
export default Back;