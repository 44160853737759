import React, { Component } from 'react';
import {connect} from "react-redux";
import axios from "axios";

//components
import Page from '../../../Page';
import Events from '../../Events';

//config
import { config }  from "../../../../config";

class Rajd2022 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statistics:{}
        }
    }

    componentDidMount(props) {
        axios
            .get('https://rajdturawa.worksmile.com/challenge/json-details/rajd-turawa')
            .then(res => {
                this.setState({
                    statistics: res.data
                })
            })
            .catch(error => console.log(error));
    }

    render() {
        const {
            pageData,
            pageOptions
        } = this.props;

        return (
            <Page pageIndex={config.pageIndex.eventRajd2022}>
                <Events
                    pageOptions={pageOptions}
                    pageData={pageData}
                    statistics={this.state.statistics}
                />
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions:state.page.pageOptions
});


export default connect(mapStateToProps,null)(Rajd2022);