import React, {Component} from 'react';
import {connect} from "react-redux";

//actions
import {togglePlayer,selectedVideo} from "../../../../../actions/playerActions";

class Cover extends Component {
    constructor(props) {
        super(props);
        this.handleVideoPlayer = this.handleVideoPlayer.bind(this);
    }
    handleVideoPlayer = video => {
        this.props.openPlayer();
        this.props.selectedVideo(video);
    };
    render() {

        const {pageData} = this.props;

        const {
            link_to_filmu,
            vimeo_id,
            banner,
            tytul,
            slogan
        } = pageData;
        return (
            <div className="row cover-row-container">
                <div className={"col col-xs-12"}>
                    <h2>
                        {
                            link_to_filmu ?
                                <button onClick={ ()=>this.handleVideoPlayer({ vimeoId:vimeo_id} ) }>
                                    <img src={banner} alt={tytul}/>
                                </button> :
                                <img src={banner} alt={tytul}/>
                        }

                    </h2>
                    {
                        slogan &&
                        <figcaption>
                            <img src={slogan} alt={tytul}/>
                        </figcaption>
                    }


                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch( togglePlayer() ),
    selectedVideo: video => dispatch( selectedVideo(video) ),
});


export default connect(null,mapDispatchToProps)(Cover);