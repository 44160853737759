import React from 'react';

const Arrow = ({mainColor}) => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             width="18px"
             height="56px"
             viewBox="0 0 37 113">
            <path fill={mainColor} d="M34.952,113c-0.702,0-1.388-0.358-1.767-1.003L0,55.951L33.193,0.989c0.584-0.963,1.841-1.278,2.812-0.699
                s1.285,1.831,0.703,2.796L4.768,55.971l31.948,53.956c0.574,0.972,0.249,2.222-0.725,2.791C35.663,112.908,35.307,113,34.952,113z"
            />
        </svg>
    );
};

export default Arrow;