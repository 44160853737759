import React from 'react';

//tools
import classnames from 'classnames';
import MediaQuery from "react-responsive/src/Component";

//svg
import Facebook from "../../svg/social/Facebook";
import Instagram from "../../svg/social/Instagram";
import Vimeo from "../../svg/social/Vimeo";

const Icons = {
    Facebook,
    Instagram,
    Vimeo
};

const Footer = props => {

    const { pageOptions } = props;

    const greenGradient = {
        background: `linear-gradient( 
                ${pageOptions.rotacja_news}deg, 
                ${pageOptions.kolor_1_news} 53%, 
                ${pageOptions.kolor_2_news} 85% 
            )`
    };



    return (
        <div className="footer">
            <div
                style={greenGradient}
                className="slogan">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className={classnames({
                            'fade-in': props.inView
                        })}>
                            #KażdyDzieńDobry
                        </p>
                    </div>
                </div>
            </div>
            <div
                style={{
                    color: pageOptions.footer_txt,
                    backgroundColor:pageOptions.footer_bg
                }}
                className="info">
                <div className="row top-xs">
                    <div className="col col-xs-12 col-sm-8 center-xs start-sm">
                            <div className="ccol">
                                <nav>
                                    <a
                                        href={pageOptions.polityka_prywatnosci_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="">
                                        {pageOptions.polityka_prywatnosci}
                                    </a>
                                </nav>
                                <MediaQuery minWidth={769}>
                                    <section>
                                        <p dangerouslySetInnerHTML={
                                            { __html : pageOptions.dane_kontaktowe_1 }
                                        } />
                                    </section>
                                </MediaQuery>
                            </div>
                            {/*<div className="ccol">
                                <nav>
                                    <a
                                        href={pageOptions.rodo_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="">
                                        {pageOptions.rodo}
                                    </a>
                                </nav>
                            </div>*/}
                            <div className="ccol">
                                <nav>
                                    <a href={pageOptions.kontakt_link} title="">{pageOptions.kontakt}</a>
                                </nav>
                                <MediaQuery minWidth={769}>
                                    <section>
                                        <p dangerouslySetInnerHTML={
                                            { __html : pageOptions.dane_kontaktowe_2 }
                                        } />
                                    </section>
                                </MediaQuery>
                            </div>
                            {/*<div className="ccol">
                                <nav>
                                    <a
                                        href={pageOptions.regulamin_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="">
                                        {pageOptions.regulamin}
                                    </a>
                                </nav>
                            </div>*/}
                            <div className="ccol">
                                <MediaQuery maxWidth={768}>
                                    <section>
                                        <p dangerouslySetInnerHTML={
                                            { __html : pageOptions.dane_kontaktowe_1 }
                                        } />
                                    </section>
                                </MediaQuery>
                            </div>
                            <div className="ccol">
                                <MediaQuery maxWidth={768}>
                                    <section>
                                        <p dangerouslySetInnerHTML={
                                            { __html : pageOptions.dane_kontaktowe_2 }
                                        } />
                                    </section>
                                </MediaQuery>
                            </div>
                    </div>
                    <div className="col col-xs-12 col-sm-4 center-xs end-sm">
                        <ul className="social-media">
                            {
                                pageOptions.social_media &&
                                pageOptions.social_media.map(
                                    (item,index) => {
                                        const Icon = Icons[item.ikona];
                                        return <li key={index}>
                                            <a
                                                href={item.link}
                                                title={item.nazwa}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {
                                                    Icon
                                                        ? <Icon
                                                            mainColor="#FFFFFF" />
                                                        : <></> }
                                            </a>
                                        </li>
                                    }

                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;