import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../Page';
import Gallery from "../../Gallery";

//tools
import MediaQuery from "react-responsive/src/Component";

//config
import { config }  from "../../../config";

class ViewONas extends Component {
    render() {
        const {
            pageData,
            pageOptions
        } = this.props;

        const seasonGradient = {
            background: `linear-gradient( 
                            ${pageOptions.rotacja_naglowki}deg, 
                            ${pageOptions.kolor_1_naglowki} 53%, 
                            ${pageOptions.kolor_2_opsiy} 85% 
                        )`
        };

        const seasonGradientOpacity = {
            background: `linear-gradient( 
                            ${pageOptions.rotacja_opacity}deg, 
                            ${pageOptions.kolor_1_opacity} 53%, 
                            ${pageOptions.kolor_2_opacity} 85% 
                        )`
        };

        const footerGradient = {
            background: `linear-gradient( 
                            ${pageOptions.rotacja_news}deg, 
                            ${pageOptions.kolor_1_news} 53%, 
                            ${pageOptions.kolor_2_news} 85% 
                        )`
        };

        return (
            <Page pageIndex={config.pageIndex.oNas}>
                <article className="page o-nas content">
                    <div className="boxed">

                        <div className="row cover-row-container">
                            <div className={"col col-xs-12"}>
                                <h2><img src={pageData.okladka} alt={pageData.tytul}/></h2>
                            </div>
                        </div>

                        <div className="row content-row-container center-xs start-sm">
                            <div className={"col col-xs-12 col-md-offset-1 col-md-11"}>
                                <div className="row">
                                    <div className={"col col-md-8"}>
                                        <h3 dangerouslySetInnerHTML={{__html: pageData.naglowek}}  />
                                        <p dangerouslySetInnerHTML={{__html: pageData.opis}} />
                                    </div>
                                    <MediaQuery minWidth={992}>
                                        <div className={"col col-md-4"}>
                                            <aside style={ seasonGradientOpacity }>
                                                <div className="full-color" style={ seasonGradient }/>
                                            </aside>
                                        </div>
                                    </MediaQuery>
                                </div>
                            </div>
                        </div>

                        <div className="row gallery-row-container standalone">
                            <div className="bg" style={ footerGradient }/>
                            <Gallery
                                pageData={pageData}
                                pageOptions={pageOptions}
                                classNames={"col col-xs-12 col-md-offset-1 col-md-11 "}
                            />
                        </div>
                    </div>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(ViewONas);