import React from 'react';

const OfficesIcon= ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_search" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="31.16px" height="31.45px"
             viewBox="0 0 31.16 31.45">
             <g>
                 <path fill={mainColor} d="M18.21,18.97c0.03-0.01,0.05-0.01,0.08-0.01c0.09,0,0.18,0.06,0.21,0.15c0,0,0.03,0.07,0.09,0.21
			c0.06,0.14,0.16,0.35,0.31,0.61c0.29,0.52,1.43,2.06,1.43,2.06s3.59,3.56,7.15,4.94l-0.42-0.55c-1.18-1.64-0.79-2.91-1.91-6.06
			c-1.7-4.77-8.01-3.39-8.47-3.74c0,0-2.71,8.16,4.37,9.47c2.13,0.39,3.62,0.51,4.51,0.54c-5.87-2.92-7.48-7.3-7.49-7.33
			C18.03,19.15,18.09,19.02,18.21,18.97 M5.84,20.33c-1.12,3.15-0.74,4.42-1.91,6.06l-0.42,0.55c3.55-1.38,5.79-3.33,7.15-4.94
			c0.69-0.81,1.15-1.54,1.43-2.06c0.14-0.26,0.24-0.47,0.31-0.61c0.06-0.14,0.09-0.21,0.09-0.21c0.03-0.09,0.12-0.15,0.22-0.15
			c0.03,0,0.05,0,0.08,0.01c0.12,0.04,0.18,0.17,0.14,0.29c-0.01,0.03-1.62,4.41-7.49,7.33c0.9-0.03,2.38-0.14,4.51-0.54
			c7.09-1.3,4.37-9.47,4.37-9.47C13.85,16.94,7.54,15.56,5.84,20.33 M25.18,11.43C26.3,8.28,25.92,7,27.09,5.37l0.42-0.55
			c-3.55,1.38-5.79,3.33-7.15,4.94c-0.69,0.81-1.14,1.54-1.43,2.06c-0.14,0.26-0.24,0.47-0.31,0.61c-0.06,0.14-0.09,0.21-0.09,0.21
			c-0.03,0.09-0.12,0.15-0.21,0.15c-0.03,0-0.05,0-0.08-0.01c-0.12-0.04-0.18-0.17-0.14-0.29c0.01-0.03,1.62-4.41,7.49-7.33
			c-0.9,0.03-2.38,0.15-4.51,0.54c-7.09,1.3-4.37,9.46-4.37,9.46C17.17,14.81,23.48,16.2,25.18,11.43 M9.96,5.7
			C7.83,5.31,6.35,5.19,5.45,5.16c5.87,2.92,7.48,7.3,7.49,7.33c0.04,0.12-0.02,0.25-0.14,0.29c-0.03,0.01-0.05,0.01-0.08,0.01
			c-0.09,0-0.18-0.06-0.22-0.15c0,0-0.03-0.07-0.09-0.21c-0.06-0.14-0.16-0.35-0.31-0.61c-0.29-0.52-0.75-1.25-1.43-2.06
			C9.33,8.15,7.09,6.2,3.53,4.82l0.42,0.55c1.18,1.64,0.8,2.91,1.91,6.06c1.7,4.78,8.01,3.39,8.47,3.74
			C14.34,15.16,17.05,7,9.96,5.7"/>
            </g>
        </svg>
    );
};

export default OfficesIcon;