import React from 'react';

const Partners = ({partnersData,csrGradient}) => {
    return (
        <div className="row partners">
            <div
                className="col col-xs-12 col-sm-6 list"
                style={csrGradient}
            >
                <p>{ partnersData.tytulListy}</p>
                <img src={partnersData.img} alt="Lista partnerów" />
            </div>
        </div>
    );
};

export default Partners;