import React, {Component} from 'react';
import classnames from "classnames";

class Disclaimer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hidden : false
        };
        this.handleAccept = this.handleAccept.bind(this);
    }
    handleAccept = e => {
        e.preventDefault();
        this.setState({
            hidden : true
        });
        localStorage.setItem("disclaimer", "true")
    };

    render() {
        const {txt,link,btn,readMore} = this.props;
        return (
            <div className={classnames(
                "disclaimer",{
                    "hidden" : this.state.hidden
                }
            )}>
                <p>
                    {txt}
                    <a href={link} >{readMore}</a>
                    <a
                        href={btn}
                        className="accept"
                        onClick={ e => this.handleAccept(e) } >
                        {btn}
                    </a>
                </p>
            </div>
        );
    }
}

export default Disclaimer;

