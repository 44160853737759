import React, { Component } from 'react';
import {connect} from "react-redux";

// components
import Page from '../../Page';
import Player from '../../Player';

//sections
import LinksSection from "./LinksSection";
import SliderSection from "./SliderSection";
import SearchSection from "../../SearchSection";
import HoursSection from "./HoursSection";
import FindUsSection from "./FindUsSection";

//config
import { config }  from "../../../config";

class Home extends Component {
    
    render() {

        const {
            pageData,
            pageOptions,
            pageDataPosts,
            pageDataSlidesList,
            pageDataLinksList,
            pageDataHoursList,
            pageDataSocialList,
            playerOpened
        } = this.props;

        return (
            <Page pageIndex={config.pageIndex.home}
                  restName="sklepy">

                

                { playerOpened && <Player /> }

                <article className="page home content">
                    <div className="boxed">

                        {/* <SliderSection pageDataSlidesList={pageDataSlidesList} /> */}


                        
                        
                        {pageDataSlidesList ? <SliderSection pageDataSlidesList={pageDataSlidesList} /> : <p>Loading...</p>}

                        <SearchSection dataSet={pageDataPosts}
                                       pageData={pageData}
                                       inputColSize={6}
                                       labelColSize={6}
                                       offset={0}
                        />

                        <LinksSection
                            pageData={pageData}
                            pageOptions={pageOptions}
                            pageDataLinksList={pageDataLinksList}/>

                       <HoursSection pageData={pageData}
                                     pageOptions={pageOptions}
                                     pageDataHoursList={pageDataHoursList}/>


                        <FindUsSection pageData={pageData}
                                       pageOptions={pageOptions}
                                       pageDataSocialList={pageDataSocialList}/>

                    </div>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions,
    pageDataPosts: state.page.pageDataPosts,
    pageDataSlidesList: state.page.pageDataSlidesList,
    pageDataLinksList: state.page.pageDataLinksList,
    pageDataHoursList: state.page.pageDataHoursList,
    pageDataSocialList: state.page.pageDataSocialList,
    playerOpened: state.player.playerOpened
});


export default connect(mapStateToProps,null)(Home);

