import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../Page';


//tools
import MediaQuery from "react-responsive/src/Component";

//config
import { config }  from "../../../config";

class ViewKarta extends Component {
    render() {
        const {
            pageData,
            pageOptions
        } = this.props;

        //console.log(pageData);

        const seasonGradient = {
            background: `linear-gradient( 
                            ${pageOptions.rotacja_naglowki}deg, 
                            ${pageOptions.kolor_1_naglowki} 53%, 
                            ${pageOptions.kolor_2_opsiy} 55% 
                        )`
        };

        const seasonGradientOpacity = {
            background: `linear-gradient( 
                            ${pageOptions.rotacja_opacity}deg, 
                            ${pageOptions.kolor_1_opacity} 53%, 
                            ${pageOptions.kolor_2_opacity} 85% 
                        )`,
            marginTop: 0
        };


        return (
            <Page pageIndex={config.pageIndex.karta}>
                <article className="page o-nas content">
                    <div className="boxed">

                        <div className="row cover-row-container">
                            <img 
                                src={pageData.banner} 
                                alt="Kartą Podarunkową TURAWA PARK" title="Kartą Podarunkową TURAWA PARK" 
                                style={{maxWidth: "100%", height: "auto"}}    
                            />
                        </div>

                        <div className="row content-row-container center-xs start-sm">
                            <div className={"col col-xs-12 col-md-offset-1 col-md-11"}>
                                <div className="row">
                                    <div className={"col col-md-8"}>
                                        <p dangerouslySetInnerHTML={{__html: pageData.opis}} />
                                    </div>

                                    <MediaQuery minWidth={992}>
                                        <div className={"col col-md-4"}>
                                            <aside style={ seasonGradientOpacity }>
                                                <div className="full-color" style={ seasonGradient }/>
                                            </aside>
                                        </div>
                                    </MediaQuery>

                                </div>
                            </div>
                        </div>

                    </div>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(ViewKarta);