import React from 'react';
import classnames from "classnames";

const TableFooter = ({legenda}) => {
    return (
        <footer>
            <div className="row">
                {
                    legenda.map((item,index) =>
                        <div className={classnames(
                            "col",
                            "col-xs-12",{
                                "col-md-6": item.symbol,
                                "col-md-12": !item.symbol
                            }
                        )} key={index}>

                            {
                                item.symbol ?
                                    <div className="row">
                                        <div className="col col-xs-2"><strong>{item.symbol}</strong></div>
                                        <div className="col col-xs-10">{item.opis}</div>
                                    </div> :
                                    <div className="row">
                                        <div className="col col-xs-12"><strong>{item.opis}</strong></div>
                                    </div>
                            }
                        </div>
                    )
                }
            </div>
            
        </footer>
    );
};

export default TableFooter;