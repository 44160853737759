import React, {Component} from 'react';
import {connect} from "react-redux";

//components
import Search from "../Search";

//tools
import classnames from "classnames";
import MediaQuery from 'react-responsive';

class SearchSection extends Component {
    render() {

        const {
            pageOptions,
            inputColSize,
            labelColSize,
            offset
        } = this.props;

        const bumpZindex= {
            zIndex: 12
        };
        return (
            <>
                <MediaQuery maxWidth={991}>
                    <div className="row search-row-container mobile-label-container">
                        <div className={classnames(
                            "col",
                            "col-xs-12",
                            "col-md-" + labelColSize,
                            "label"
                        )}>
                            <label htmlFor="content-search" style={{fontSize:'15px'}}>
                                {pageOptions.teksty_znajdz_sklep}
                            </label>
                        </div>
                    </div>
                </MediaQuery>
                <div className="row search-row-container"
                     style={this.props.searchFocused ? bumpZindex : {}} >
                    <div className={classnames(
                        "col",
                        "col-xs-12",
                        "col-md-" + inputColSize,
                        "col-xs-offset-" + offset,
                        "input"
                    )}>
                        <Search searchMinWidth="112px"
                                searchMaxWidth="90%"
                                height={73}
                                align="right"
                                resultsAlignment="left"
                                iconWidth={26}
                                iconHeight={26}
                                placeholderTxt={pageOptions.teksty_wpisz_sklep}
                                fontSize={15}
                                id="content-search"
                        />
                    </div>
                    <MediaQuery minWidth={992}>
                        <div className={classnames(
                            "col",
                            "col-xs-12",
                            "col-md-" + labelColSize,
                            "label"
                        )}>
                            <label htmlFor="content-search" style={{fontSize:'15px'}}>
                                {pageOptions.teksty_znajdz_sklep}
                            </label>
                        </div>
                    </MediaQuery>
                </div>
            </>

        );
    }
}

const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions,
    searchFocused: state.page.searchFocused
});


export default connect(mapStateToProps,null)(SearchSection);