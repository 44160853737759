import React from 'react';

const Parking = ({mainColor}) => {
    return (
        <svg version="1.1"
             id="i_parking"
             xmlns="http://www.w3.org/2000/svg"
             width="143px"
             height="129px"
             viewBox="0 0 143 129" >
            <g>
                <path display="none" fill={mainColor} d="M131.889,45.975C115.785,29.869,94.336,21,71.5,21c-22.837,0-44.282,8.867-60.389,24.975
		c-0.869,0.869-0.869,2.274,0,3.144c0.869,0.869,2.274,0.869,3.145,0C29.521,33.851,49.851,25.444,71.5,25.444
		c21.648,0,41.979,8.407,57.245,23.673c0.434,0.433,1.002,0.651,1.571,0.651s1.139-0.218,1.572-0.651
		C132.758,48.249,132.758,46.842,131.889,45.975"/>
                <path display="none" fill={mainColor} d="M24.18,59.042c-0.869,0.87-0.869,2.274,0,3.144c0.435,0.434,1.003,0.651,1.572,0.651
		s1.138-0.217,1.572-0.651c24.356-24.359,63.99-24.359,88.35,0c0.869,0.869,2.274,0.869,3.144,0c0.869-0.87,0.869-2.274,0-3.144
		C92.729,32.953,50.273,32.949,24.18,59.042"/>
                <path display="none" fill={mainColor} d="M71.5,57.948c-12.951,0-25.113,5.031-34.249,14.166c-0.869,0.869-0.869,2.274,0,3.144
		c0.87,0.869,2.274,0.869,3.144,0c8.295-8.293,19.341-12.862,31.105-12.862c11.764,0,22.81,4.569,31.104,12.862
		c0.435,0.434,1.004,0.651,1.573,0.651s1.139-0.218,1.572-0.651c0.869-0.869,0.869-2.274,0-3.144
		C96.615,62.979,84.451,57.948,71.5,57.948"/>
                <path display="none" fill={mainColor} d="M71.5,78.865c-8.031,0-14.565,6.534-14.565,14.567S63.469,108,71.5,108
		c8.033,0,14.567-6.534,14.567-14.567S79.533,78.865,71.5,78.865 M71.5,103.551c-5.581,0-10.119-4.54-10.119-10.12
		c0-5.581,4.54-10.121,10.119-10.121c5.578,0,10.121,4.54,10.121,10.121C81.621,99.011,77.081,103.551,71.5,103.551"/>
                <path display="none" fill={mainColor} d="M71.5,111.923c-12.667,0-24.576-4.933-33.533-13.89S24.077,77.167,24.077,64.5
		s4.933-24.576,13.89-33.533s20.866-13.89,33.533-13.89c11.506,0,22.604,4.173,31.246,11.749c0.577,0.506,0.635,1.383,0.129,1.96
		c-0.506,0.577-1.383,0.635-1.96,0.129C92.778,23.783,82.333,19.856,71.5,19.856c-11.925,0-23.137,4.643-31.568,13.076
		C31.499,41.364,26.854,52.575,26.854,64.5c0,11.925,4.645,23.137,13.077,31.569c8.432,8.432,19.644,13.075,31.568,13.075
		s23.137-4.644,31.568-13.075c8.433-8.433,13.076-19.645,13.076-31.569c0-10.926-3.988-21.441-11.229-29.607
		c-0.51-0.574-0.457-1.452,0.118-1.961c0.573-0.509,1.452-0.456,1.961,0.118c7.693,8.675,11.928,19.844,11.928,31.45
		c0,12.667-4.933,24.576-13.89,33.533S84.167,111.923,71.5,111.923"/>
                <path display="none" fill={mainColor} d="M71.5,96.488c-4.517,0-8.189-3.674-8.189-8.189c0,0-0.313-2.863,1.39-2.863
		c1.701,0,1.389,2.863,1.389,2.863c0,2.983,2.428,5.411,5.411,5.411s5.41-2.428,5.41-5.411V74.632c0-2.604,2.118-4.722,4.722-4.722
		h13.667c2.983,0,5.411-2.427,5.411-5.41c0-2.984-2.428-5.411-5.411-5.411H81.632c-2.604,0-4.722-2.118-4.722-4.722V40.7
		c0-2.983-2.427-5.41-5.41-5.41s-5.411,2.427-5.411,5.41v13.667c0,2.604-2.117,4.722-4.721,4.722H47.701
		c-2.983,0-5.41,2.427-5.41,5.411c0,2.982,2.427,5.41,5.41,5.41h13.667c2.604,0,4.721,2.118,4.721,4.722v6.583
		c0,0.767-0.621,1.39-1.389,1.39s-1.39-0.623-1.39-1.39v-6.583c0-1.071-0.872-1.942-1.942-1.942H47.701
		c-4.516,0-8.189-3.674-8.189-8.189c0-4.516,3.674-8.189,8.189-8.189h13.667c1.07,0,1.942-0.872,1.942-1.944V40.7
		c0-4.515,3.673-8.189,8.189-8.189c4.516,0,8.189,3.674,8.189,8.189v13.667c0,1.072,0.871,1.944,1.942,1.944h13.667
		c4.516,0,8.189,3.673,8.189,8.189c0,4.515-3.674,8.189-8.189,8.189H81.632c-1.071,0-1.942,0.872-1.942,1.942v13.667
		C79.689,92.814,76.016,96.488,71.5,96.488"/>
                <path display="none" fill={mainColor} d="M80.134,110.289c-1.051-0.421-2.245,0.09-2.666,1.141c-0.353,0.88-1.245,1.469-2.222,1.469
		h-7.492c-0.977,0-1.869-0.589-2.223-1.469c-0.421-1.051-1.614-1.561-2.666-1.141c-1.051,0.422-1.562,1.615-1.14,2.667
		c0.985,2.457,3.351,4.044,6.028,4.044h7.492c2.678,0,5.044-1.587,6.028-4.044C81.695,111.904,81.186,110.711,80.134,110.289"/>
                <path display="none" fill={mainColor} d="M104.219,99.725c-0.558-2.872-2.327-5.358-4.856-6.826c0.46-0.376,0.892-0.792,1.285-1.249
		c1.942-2.257,2.803-5.239,2.359-8.184c-0.72-4.783-2.755-9.252-5.887-12.925c-2.345-2.749-5.228-4.965-8.452-6.521
		c3.947-4.211,6.368-9.869,6.368-16.083c0-10.753-7.249-19.845-17.116-22.646c1.344-1.605,2.077-3.61,2.077-5.728
		c0-4.17-3.393-7.562-7.562-7.562h-0.001c-1.684,0-3.26,0.547-4.438,1.541c-1.303,1.098-2.021,2.641-2.021,4.345
		c0,1.132,0.918,2.051,2.051,2.051c1.132,0,2.051-0.919,2.051-2.051c0-0.488,0.189-0.895,0.563-1.209
		c0.439-0.372,1.078-0.576,1.796-0.576c1.907,0,3.46,1.552,3.46,3.46c0,1.293-0.503,2.508-1.417,3.421
		c-0.914,0.914-2.128,1.417-3.42,1.417h-0.001c-0.05,0-0.1,0.004-0.148,0.008c-12.706,0.315-22.946,10.748-22.946,23.529
		c0,6.214,2.423,11.871,6.369,16.083c-3.225,1.557-6.106,3.771-8.452,6.521c-3.132,3.672-5.168,8.142-5.888,12.925
		c-0.443,2.944,0.417,5.927,2.36,8.184c0.393,0.457,0.825,0.873,1.285,1.248c-2.53,1.469-4.3,3.955-4.856,6.827
		c-0.558,2.879,0.158,5.855,1.966,8.165l4.392,5.612c1.774,2.268,4.47,3.486,7.203,3.486c1.553,0,3.117-0.393,4.532-1.21
		c3.909-2.257,5.584-7.116,3.897-11.302l-2.665-6.609c-0.178-0.441-0.389-0.865-0.623-1.271h15.042c1.133,0,2.051-0.919,2.051-2.051
		c0-1.133-0.918-2.051-2.051-2.051H53.584v-4.323c0-0.558,0.454-1.01,1.011-1.01h33.811c0.557,0,1.01,0.452,1.01,1.01v4.606
		c-2.035,1.115-3.642,2.904-4.522,5.09l-2.664,6.609c-1.688,4.186-0.013,9.045,3.897,11.302c1.415,0.817,2.979,1.21,4.531,1.21
		c2.733,0,5.43-1.22,7.204-3.486l4.392-5.612C104.061,105.58,104.776,102.604,104.219,99.725 M52.064,47.937
		c0-10.717,8.719-19.435,19.437-19.435c10.715,0,19.434,8.718,19.434,19.435c0,10.717-8.718,19.435-19.434,19.435
		C60.783,67.371,52.064,58.653,52.064,47.937 M54.304,99.4l2.663,6.61c0.929,2.302,0.008,4.975-2.143,6.216
		s-4.926,0.703-6.455-1.252l-4.392-5.612c-1.076-1.374-1.501-3.144-1.169-4.856s1.389-3.196,2.899-4.068
		c1.512-0.872,3.324-1.046,4.974-0.478S53.65,97.783,54.304,99.4 M88.419,78.958c-1.133,0-2.051,0.918-2.051,2.05v2.052H56.646
		v-2.052c0-1.132-0.918-2.05-2.051-2.05c-1.134,0-2.052,0.918-2.052,2.05v2.482c-1.8,0.792-3.06,2.592-3.06,4.681v2.896
		c-1.553-0.155-2.994-0.897-4.023-2.093c-1.162-1.351-1.677-3.136-1.412-4.897c1.175-7.804,6.377-14.252,13.683-17.063
		c3.875,2.804,8.632,4.46,13.771,4.46c5.137,0,9.894-1.656,13.769-4.46c7.305,2.812,12.508,9.26,13.682,17.063
		c0.265,1.762-0.249,3.547-1.412,4.897c-1.028,1.195-2.47,1.938-4.022,2.093v-2.896c0-2.084-1.254-3.88-3.047-4.674v-2.489
		C90.47,79.876,89.552,78.958,88.419,78.958 M99.022,105.362l-4.392,5.612c-1.53,1.954-4.305,2.493-6.455,1.252
		c-2.149-1.242-3.071-3.914-2.143-6.217l2.664-6.609c0.652-1.618,1.973-2.872,3.622-3.441c0.64-0.22,1.302-0.328,1.962-0.328
		c1.046,0,2.085,0.272,3.012,0.807c1.51,0.872,2.566,2.355,2.899,4.067C100.524,102.218,100.098,103.988,99.022,105.362"/>
                <path display="none" fill={mainColor} d="M82.121,93.094c-0.382-0.381-0.911-0.601-1.45-0.601s-1.068,0.219-1.45,0.601
		c-0.381,0.382-0.601,0.911-0.601,1.45c0,0.541,0.22,1.068,0.601,1.449c0.382,0.384,0.911,0.602,1.45,0.602s1.068-0.218,1.45-0.602
		c0.381-0.381,0.601-0.91,0.601-1.449S82.502,93.476,82.121,93.094"/>
                <path display="none" fill={mainColor} d="M77.154,54.939c-0.88-0.713-2.172-0.578-2.885,0.302c-0.682,0.841-1.691,1.323-2.77,1.323
		c-1.079,0-2.089-0.482-2.77-1.323c-0.714-0.88-2.005-1.014-2.885-0.302c-0.88,0.713-1.015,2.005-0.302,2.885
		c1.464,1.806,3.634,2.842,5.956,2.842c2.321,0,4.492-1.037,5.956-2.842C78.169,56.944,78.034,55.652,77.154,54.939"/>
                <path display="none" fill={mainColor} d="M66.317,46.486c-1.859-1.86-4.887-1.86-6.748,0c-0.801,0.801-0.801,2.099,0,2.9
		c0.802,0.801,2.101,0.801,2.9,0c0.262-0.26,0.686-0.26,0.948,0c0.399,0.401,0.925,0.601,1.449,0.601c0.525,0,1.051-0.2,1.45-0.601
		C67.119,48.586,67.119,47.287,66.317,46.486"/>
                <path display="none" fill={mainColor} d="M83.43,46.486c-1.86-1.86-4.888-1.86-6.748,0c-0.801,0.801-0.801,2.099,0,2.9
		c0.4,0.401,0.926,0.601,1.45,0.601c0.525,0,1.05-0.2,1.45-0.601c0.262-0.26,0.687-0.261,0.948,0c0.801,0.801,2.099,0.801,2.899,0
		C84.23,48.585,84.23,47.287,83.43,46.486"/>
                <path display="none" fill={mainColor} d="M123.993,31.71c-0.999,0-1.81,0.81-1.81,1.81v65.164h-9.829
		c-11.648,0.001-23.26,1.324-34.609,3.942c-1.458,0.333-2.97-0.267-3.802-1.51c6.463-3.956,13.893-6.051,21.47-6.053h21.34
		c1,0,1.811-0.812,1.811-1.812V80.584c0-1-0.811-1.811-1.811-1.811s-1.81,0.811-1.81,1.811v10.86h-19.53
		c-7.746-0.008-15.362,1.996-22.103,5.814V48.001c0-1-0.812-1.81-1.811-1.81c-1,0-1.81,0.81-1.81,1.81v49.255
		c-6.741-3.818-14.357-5.821-22.104-5.813H28.057V26.279h27.876c7.6,0.005,13.758,6.167,13.758,13.766c0,1,0.81,1.811,1.81,1.811
		c0.999,0,1.811-0.811,1.811-1.811c0-7.599,6.157-13.761,13.757-13.766h27.876v47.063c0,1,0.81,1.811,1.81,1.811
		s1.811-0.811,1.811-1.811V24.469c0-0.999-0.811-1.81-1.811-1.81H87.067c-6.6,0.005-12.629,3.743-15.567,9.652
		c-2.939-5.909-8.968-9.647-15.567-9.652H26.247c-1,0-1.811,0.811-1.811,1.81v68.784c0,1,0.811,1.812,1.811,1.812h21.339
		c7.579,0.002,15.01,2.097,21.474,6.053c-0.835,1.239-2.344,1.839-3.801,1.51c-11.352-2.619-22.964-3.941-34.614-3.942h-9.828
		V33.521c0-1-0.811-1.81-1.811-1.81c-0.999,0-1.81,0.81-1.81,1.81v66.975c0,0.999,0.811,1.81,1.81,1.81h11.639
		c11.375,0.002,22.713,1.294,33.796,3.851c2.597,0.6,5.312-0.283,7.06-2.296c1.747,2.013,4.463,2.896,7.06,2.296
		c11.083-2.557,22.421-3.848,33.795-3.851h11.639c1,0,1.812-0.811,1.812-1.81V33.521C125.805,32.521,124.993,31.71,123.993,31.71"/>
                <path display="none" fill={mainColor} d="M65.568,38.25c-2.854-1.905-6.206-2.92-9.635-2.919H35.297c-1,0-1.81,0.81-1.81,1.81
		c0,0.999,0.81,1.81,1.81,1.81h20.637c2.718-0.003,5.375,0.801,7.635,2.311c0.832,0.555,1.956,0.331,2.51-0.501
		c0.555-0.832,0.331-1.956-0.501-2.511H65.568z"/>
                <path display="none" fill={mainColor} d="M107.702,35.331H87.067c-3.434-0.003-6.79,1.013-9.645,2.919
		c-0.832,0.556-1.057,1.68-0.502,2.511c0.556,0.832,1.679,1.056,2.51,0.502c2.262-1.51,4.918-2.314,7.637-2.312h20.635
		c1,0,1.811-0.81,1.811-1.81C109.513,36.142,108.702,35.331,107.702,35.331"/>
                <path display="none" fill={mainColor} d="M65.568,47.301c-2.854-1.905-6.206-2.921-9.635-2.919H35.297c-1,0-1.81,0.81-1.81,1.81
		c0,0.999,0.81,1.81,1.81,1.81h20.637c2.718-0.004,5.375,0.801,7.635,2.311c0.832,0.555,1.956,0.33,2.51-0.501
		c0.555-0.832,0.331-1.956-0.501-2.511H65.568z"/>
                <path display="none" fill={mainColor} d="M107.702,44.381H87.067c-3.434-0.003-6.79,1.013-9.645,2.919
		c-0.832,0.555-1.057,1.679-0.502,2.511c0.556,0.832,1.679,1.056,2.51,0.502c2.262-1.51,4.918-2.314,7.637-2.312h20.635
		c1,0,1.811-0.81,1.811-1.81S108.702,44.381,107.702,44.381"/>
                <path display="none" fill={mainColor} d="M65.568,56.352c-2.854-1.905-6.206-2.921-9.635-2.92H35.297c-1,0-1.81,0.811-1.81,1.81
		c0,1,0.81,1.81,1.81,1.81h20.637c2.718-0.003,5.375,0.802,7.635,2.312c0.832,0.555,1.956,0.33,2.51-0.501
		c0.555-0.832,0.331-1.956-0.501-2.511H65.568z"/>
                <path display="none" fill={mainColor} d="M107.702,53.432H87.067c-3.434-0.002-6.79,1.014-9.645,2.92
		c-0.832,0.555-1.057,1.679-0.502,2.511c0.556,0.831,1.679,1.056,2.51,0.501c2.262-1.51,4.918-2.314,7.637-2.312h20.635
		c1,0,1.811-0.81,1.811-1.81C109.513,54.243,108.702,53.432,107.702,53.432"/>
                <path display="none" fill={mainColor} d="M65.568,65.402c-2.854-1.905-6.206-2.921-9.635-2.92H35.297c-1,0-1.81,0.811-1.81,1.81
		c0,1,0.81,1.81,1.81,1.81h20.637c2.718-0.003,5.375,0.802,7.635,2.312c0.832,0.555,1.956,0.33,2.51-0.501
		c0.555-0.832,0.331-1.956-0.501-2.511H65.568z"/>
                <path display="none" fill={mainColor} d="M107.702,62.482H87.067c-3.434-0.003-6.79,1.013-9.645,2.92
		c-0.832,0.555-1.057,1.679-0.502,2.511c0.556,0.831,1.679,1.056,2.51,0.501c2.262-1.51,4.918-2.314,7.637-2.312h20.635
		c1,0,1.811-0.811,1.811-1.81C109.513,63.293,108.702,62.482,107.702,62.482"/>
                <path display="none" fill={mainColor} d="M65.568,74.452c-2.854-1.904-6.206-2.92-9.635-2.919H35.297c-1,0-1.81,0.811-1.81,1.81
		c0,1,0.81,1.811,1.81,1.811h20.637c2.718-0.003,5.375,0.802,7.635,2.312c0.832,0.555,1.956,0.33,2.51-0.502
		c0.555-0.831,0.331-1.955-0.501-2.511H65.568z"/>
                <path display="none" fill={mainColor} d="M107.702,71.533H87.067c-3.434-0.003-6.79,1.013-9.645,2.919
		c-0.832,0.556-1.057,1.68-0.502,2.511c0.556,0.832,1.679,1.057,2.51,0.502c2.262-1.51,4.918-2.314,7.637-2.312h20.635
		c1,0,1.811-0.811,1.811-1.811C109.513,72.344,108.702,71.533,107.702,71.533"/>
                <path display="none" fill={mainColor} d="M55.933,80.584H35.297c-0.999,0-1.81,0.81-1.81,1.81s0.811,1.811,1.81,1.811h20.636
		c2.718-0.004,5.375,0.801,7.635,2.312c0.832,0.555,1.956,0.33,2.512-0.502c0.554-0.832,0.33-1.956-0.502-2.511
		C62.723,81.597,59.366,80.581,55.933,80.584"/>
                <path display="none" fill={mainColor} d="M107.702,80.584H87.067c-3.434-0.003-6.79,1.013-9.645,2.919
		c-0.832,0.555-1.057,1.679-0.502,2.511c0.556,0.832,1.679,1.057,2.51,0.502c2.262-1.51,4.918-2.314,7.637-2.312h20.635
		c1,0,1.811-0.811,1.811-1.811S108.702,80.584,107.702,80.584"/>
                <path display="none" fill={mainColor} d="M71.5,12.577c-3.976,0-7.728,2.812-10.567,7.918c-2.553,4.591-4.017,10.446-4.017,16.063
		c0,5.748,2.993,10.979,7.81,13.653c1.657,0.92,2.633,2.631,2.548,4.466l-0.4,8.625c-0.039,0.841,0.61,1.555,1.453,1.594
		c0.842,0.034,1.555-0.611,1.594-1.452l0.4-8.626c0.141-3.001-1.437-5.788-4.113-7.274c-3.85-2.137-6.24-6.346-6.24-10.986
		c0-5.114,1.324-10.428,3.633-14.58c2.244-4.036,5.124-6.351,7.9-6.351s5.656,2.315,7.9,6.351c2.309,4.152,3.633,9.466,3.633,14.58
		c0,4.64-2.391,8.849-6.239,10.986c-2.678,1.486-4.254,4.273-4.115,7.274l2.54,54.659c0.048,1.026-0.316,2.001-1.025,2.744
		c-0.71,0.743-1.666,1.152-2.693,1.152c-1.028,0-1.984-0.409-2.693-1.152s-1.074-1.718-1.026-2.744l1.856-39.938
		c0.039-0.842-0.612-1.556-1.453-1.595c-0.839-0.033-1.556,0.611-1.595,1.452l-1.856,39.938c-0.087,1.867,0.577,3.64,1.867,4.992
		c1.291,1.352,3.031,2.097,4.9,2.097s3.609-0.745,4.899-2.097c1.291-1.352,1.954-3.125,1.867-4.992l-2.54-54.658
		c-0.086-1.835,0.891-3.546,2.548-4.466c4.817-2.674,7.81-7.905,7.81-13.653c0-5.617-1.464-11.472-4.017-16.063
		C79.228,15.389,75.475,12.577,71.5,12.577"/>
                <path display="none" fill={mainColor} d="M78.168,50.274c4.672-2.554,7.51-7.635,7.232-12.946l-1.097-20.969
		c-0.11-2.121-1.861-3.783-3.985-3.783c-1.084,0-2.098,0.427-2.854,1.201c-0.758,0.775-1.161,1.798-1.137,2.881l0.411,18.148
		c0.006,0.28-0.132,0.465-0.216,0.551c-0.084,0.085-0.268,0.229-0.546,0.229c-0.417,0-0.758-0.339-0.762-0.755l-0.188-18.666
		c-0.021-1.979-1.646-3.589-3.625-3.589c-1.979,0-3.606,1.61-3.627,3.589l-0.188,18.666c-0.003,0.416-0.345,0.755-0.761,0.755
		c-0.28,0-0.463-0.144-0.546-0.229c-0.084-0.086-0.224-0.271-0.217-0.55l0.413-18.252c0.024-1.056-0.37-2.053-1.107-2.808
		c-0.738-0.755-1.727-1.171-2.782-1.171c-2.07,0-3.777,1.62-3.885,3.687l-1.102,21.065c-0.282,5.387,2.488,10.351,7.229,12.953
		c1.568,0.861,2.526,2.608,2.441,4.452l-1.503,32.336c-0.039,0.842,0.611,1.556,1.453,1.595c0.845,0.035,1.555-0.611,1.594-1.453
		l1.503-32.335c0.141-2.998-1.438-5.851-4.021-7.269c-3.706-2.033-5.871-5.911-5.651-10.119l1.103-21.064
		c0.022-0.446,0.391-0.796,0.838-0.796c0.308,0,0.509,0.158,0.601,0.252c0.092,0.095,0.246,0.299,0.239,0.606l-0.413,18.252
		c-0.023,1.035,0.362,2.012,1.085,2.752c0.723,0.74,1.692,1.148,2.727,1.148c2.082,0,3.792-1.694,3.813-3.775l0.188-18.666
		c0.003-0.314,0.261-0.569,0.575-0.569c0.313,0,0.57,0.255,0.574,0.569l0.188,18.666c0.021,2.081,1.73,3.775,3.812,3.775
		c1.035,0,2.003-0.408,2.727-1.148c0.724-0.74,1.109-1.717,1.086-2.752l-0.411-18.148c-0.006-0.255,0.09-0.496,0.268-0.679
		c0.179-0.182,0.419-0.283,0.674-0.283c0.501,0,0.913,0.392,0.939,0.892l1.096,20.968c0.221,4.211-1.944,8.085-5.648,10.11
		c-2.584,1.412-4.163,4.262-4.024,7.26l2.539,54.619c0.048,1.039-0.325,2.022-1.05,2.769c-0.726,0.746-1.692,1.152-2.741,1.127
		c-0.986-0.019-1.907-0.433-2.595-1.168c-0.714-0.761-1.097-1.797-1.047-2.84l0.745-16.038c0.038-0.842-0.612-1.556-1.453-1.595
		c-0.85-0.038-1.556,0.611-1.596,1.452l-0.745,16.039c-0.086,1.861,0.596,3.709,1.868,5.067c1.257,1.342,2.949,2.099,4.767,2.132
		c0.041,0.001,0.082,0.001,0.124,0.001c1.822,0,3.588-0.743,4.859-2.051c1.301-1.338,1.997-3.174,1.911-5.037l-2.539-54.62
		C75.644,52.875,76.601,51.131,78.168,50.274"/>
                <path display="none" fill={mainColor} d="M64.451,115.638c-10.178,0-19.948-4.338-26.809-11.9c-0.557-0.614-0.511-1.563,0.103-2.12
		c0.614-0.558,1.564-0.512,2.121,0.103c6.292,6.938,15.252,10.916,24.585,10.916c5.309,0,10.589-1.285,15.271-3.715
		c4.528-2.351,8.517-5.773,11.535-9.9c0.498-0.681,0.701-1.515,0.572-2.349c-0.129-0.833-0.575-1.567-1.257-2.065
		c-0.681-0.498-1.516-0.702-2.349-0.572c-0.834,0.13-1.567,0.576-2.064,1.257c-5.052,6.905-13.166,11.027-21.709,11.027
		c-14.818,0-26.875-12.057-26.875-26.875c0-5.133,1.45-10.121,4.195-14.427c2.674-4.194,6.446-7.563,10.907-9.741
		c1.157-0.565,1.871-1.814,1.763-3.1c-0.105-1.275-1.001-2.382-2.221-2.757c-0.765-0.235-1.597-0.169-2.313,0.181
		c-5.507,2.688-10.163,6.845-13.462,12.021c-3.393,5.322-5.186,11.484-5.186,17.823c0,6.286,1.772,12.409,5.128,17.706
		c0.442,0.701,0.234,1.628-0.466,2.071c-0.7,0.444-1.627,0.236-2.071-0.465c-3.659-5.778-5.594-12.457-5.594-19.313
		c0-6.911,1.956-13.632,5.656-19.436c3.599-5.643,8.673-10.175,14.678-13.106c1.478-0.722,3.149-0.826,4.706-0.29
		c1.556,0.535,2.811,1.644,3.531,3.123c0.723,1.479,0.825,3.15,0.29,4.707c-0.535,1.556-1.644,2.81-3.123,3.532
		c-3.963,1.935-7.314,4.929-9.691,8.657c-2.438,3.822-3.726,8.253-3.726,12.813c0,13.163,10.709,23.873,23.873,23.873
		c7.589,0,14.798-3.663,19.285-9.798c0.972-1.328,2.402-2.198,4.028-2.45c1.625-0.253,3.253,0.144,4.581,1.115
		s2.199,2.402,2.451,4.028s-0.144,3.253-1.116,4.581c-3.29,4.498-7.639,8.23-12.575,10.793
		C75.998,114.236,70.239,115.638,64.451,115.638"/>
                <path display="none" fill={mainColor} d="M70.884,32.544c-5.289,0-9.591-4.303-9.591-9.591c0-5.289,4.302-9.591,9.591-9.591
		c5.288,0,9.591,4.302,9.591,9.591C80.475,28.241,76.172,32.544,70.884,32.544 M70.884,16.364c-3.633,0-6.589,2.956-6.589,6.589
		c0,3.633,2.956,6.589,6.589,6.589s6.589-2.956,6.589-6.589C77.473,19.32,74.517,16.364,70.884,16.364"/>
                <path display="none" fill={mainColor} d="M108.168,86.507c-0.608,0-1.181-0.373-1.406-0.977l-3.023-8.106
		c-0.883-2.411-3.195-4.025-5.755-4.025H78.512c-0.829,0-1.501-0.672-1.501-1.501V57.686c0-0.829,0.672-1.501,1.501-1.501h18.192
		c1.746,0,3.167-1.421,3.167-3.168c0-1.746-1.421-3.167-3.167-3.167H78.512c-0.829,0-1.501-0.672-1.501-1.501v-3.076
		c0-3.379-2.749-6.127-6.127-6.127c-3.379,0-6.127,2.748-6.127,6.127v16.224c0,0.829-0.672,1.501-1.501,1.501
		s-1.502-0.672-1.502-1.501V45.273c0-5.034,4.096-9.13,9.13-9.13s9.129,4.096,9.129,9.13v1.574h16.691
		c3.401,0,6.169,2.768,6.169,6.17c0,3.402-2.768,6.17-6.169,6.17H80.013v11.208h17.971c3.813,0.001,7.258,2.406,8.57,5.986
		l3.021,8.099c0.29,0.777-0.104,1.642-0.882,1.932C108.52,86.477,108.343,86.507,108.168,86.507"/>
                <path display="none" fill={mainColor} d="M108.566,105.615c-0.884,0-1.765-0.192-2.591-0.575c-1.495-0.693-2.631-1.927-3.198-3.474
		l-5.56-14.803c-0.885-2.414-3.196-4.028-5.756-4.028H70.884c-5.033,0-9.13-4.096-9.13-9.13v-6.104c0-0.829,0.672-1.501,1.501-1.501
		c0.83,0,1.502,0.672,1.502,1.501v6.104c0,3.379,2.748,6.127,6.127,6.127h20.578c3.813,0,7.258,2.406,8.571,5.986l5.56,14.803
		c0.604,1.651,2.429,2.496,4.068,1.895c0.794-0.291,1.428-0.874,1.783-1.642s0.392-1.628,0.1-2.422l-2.678-7.181
		c-0.29-0.776,0.105-1.642,0.882-1.931c0.777-0.29,1.642,0.104,1.931,0.882l2.682,7.188c0.57,1.556,0.501,3.231-0.192,4.727
		c-0.692,1.495-1.927,2.631-3.474,3.198C110.001,105.489,109.283,105.615,108.566,105.615"/>
                <path display="none" fill={mainColor} d="M111.02,17.078H31.98c-4.426,0-7.904,3.477-7.904,7.903v79.039
		c0,4.426,3.479,7.903,7.904,7.903h36.358h18.969h23.712c4.426,0,7.903-3.478,7.903-7.903V24.98
		C118.923,20.555,115.445,17.078,111.02,17.078 M71.5,108.762V77.146H58.854V64.5H71.5V53.435c0-9.01,6.955-16.598,15.808-17.388
		h14.227v12.646H88.889c-2.688,0-4.742,2.055-4.742,4.742V64.5h15.808v12.647H84.146v31.615H71.5z M115.762,104.02
		c0,2.687-2.055,4.742-4.742,4.742H87.308V80.308h15.808V61.338H87.308v-7.904c0-0.949,0.632-1.581,1.581-1.581h15.808V32.884
		H87.149c-10.591,0.948-18.811,9.959-18.811,20.55v7.904H55.692v18.969h12.646v28.454H31.98c-2.687,0-4.742-2.055-4.742-4.742V24.98
		c0-2.687,2.056-4.742,4.742-4.742h79.039c2.688,0,4.742,2.055,4.742,4.742V104.02z"/>
                <path display="none" fill={mainColor} d="M110.997,31.32H99.953c1.488-1.833,2.383-4.167,2.383-6.707c0-4.07-2.263-7.725-5.906-9.54
		c-3.644-1.814-7.924-1.417-11.172,1.036l-8.112,6.126c-1.552-1.234-3.514-1.975-5.646-1.975c-2.133,0-4.094,0.741-5.646,1.975
		l-8.113-6.126c-3.248-2.453-7.527-2.85-11.171-1.036c-3.643,1.815-5.907,5.47-5.907,9.54c0,2.54,0.896,4.874,2.384,6.707H32.003
		c-3.702,0-6.715,3.011-6.715,6.714v11.849c0,3.57,2.801,6.497,6.319,6.702v50.172c0,4.575,3.721,8.296,8.295,8.296h63.195
		c4.573,0,8.295-3.721,8.295-8.296V92.737c0-1.09-0.885-1.975-1.976-1.975s-1.975,0.885-1.975,1.975v14.021
		c0,2.397-1.949,4.346-4.345,4.346H80.584V56.597h26.858v18.367c0,1.091,0.884,1.975,1.975,1.975s1.976-0.884,1.976-1.975V56.585
		c3.519-0.205,6.319-3.133,6.319-6.702V38.034C117.712,34.332,114.698,31.32,110.997,31.32 M87.638,19.261
		c2.075-1.567,4.704-1.811,7.031-0.651c2.328,1.158,3.718,3.403,3.718,6.003c0,3.698-3.009,6.707-6.707,6.707H80.584v-1.976
		c0-1.45-0.344-2.821-0.95-4.039L87.638,19.261z M66.365,35.27h10.271v17.378H66.365V35.27z M71.5,24.21
		c2.831,0,5.135,2.303,5.135,5.134v1.976h-10.27v-1.976C66.365,26.513,68.669,24.21,71.5,24.21 M44.613,24.613
		c0-2.601,1.391-4.845,3.717-6.003c2.327-1.16,4.956-0.916,7.031,0.651l8.004,6.044c-0.605,1.218-0.95,2.589-0.95,4.039v1.975H51.32
		C47.621,31.32,44.613,28.312,44.613,24.613 M29.238,49.883V38.034c0-1.525,1.24-2.765,2.765-2.765h30.412v17.379H33.582h-1.579
		C30.479,52.648,29.238,51.407,29.238,49.883 M35.558,106.758V56.597h26.857v54.506H39.902
		C37.507,111.104,35.558,109.155,35.558,106.758 M66.365,56.597h10.271v54.506H66.365V56.597z M113.762,49.883
		c0,1.524-1.24,2.765-2.765,2.765h-1.58H80.584V35.27h30.413c1.524,0,2.765,1.24,2.765,2.765V49.883z"/>
                <path display="none" fill={mainColor} d="M109.417,81.876c-0.52,0-1.028,0.211-1.396,0.579c-0.368,0.367-0.579,0.876-0.579,1.396
		s0.211,1.029,0.579,1.396c0.367,0.367,0.876,0.579,1.396,0.579s1.029-0.212,1.396-0.579s0.579-0.877,0.579-1.396
		s-0.212-1.028-0.579-1.396C110.446,82.087,109.937,81.876,109.417,81.876"/>
                <path display="none" fill={mainColor} d="M105.387,30.613C96.335,21.563,84.301,16.577,71.5,16.577
		c-9.739,0-19.117,2.908-27.119,8.409c-0.853,0.586-1.067,1.752-0.482,2.603c0.586,0.853,1.752,1.068,2.604,0.482
		c7.373-5.07,16.018-7.75,24.997-7.75c24.36,0,44.179,19.819,44.179,44.179c0,24.36-19.818,44.178-44.179,44.178
		S27.32,88.86,27.32,64.5c0-8.981,2.68-17.624,7.75-24.997c0.586-0.852,0.37-2.017-0.481-2.603c-0.852-0.585-2.018-0.37-2.604,0.482
		c-5.501,7.999-8.409,17.377-8.409,27.118c0,12.801,4.985,24.834,14.037,33.886c9.051,9.052,21.086,14.036,33.887,14.036
		s24.835-4.984,33.887-14.036s14.036-21.085,14.036-33.886S114.438,39.664,105.387,30.613"/>
                <path display="none" fill={mainColor} d="M38.936,33.809c0.479,0,0.959-0.183,1.325-0.548c0.731-0.731,0.731-1.916,0-2.647
		c-0.731-0.732-1.917-0.732-2.648,0h-0.001c-0.73,0.732-0.729,1.916,0,2.648C37.978,33.626,38.457,33.809,38.936,33.809"/>
                <path display="none" fill={mainColor} d="M56.46,77.471c-0.268-0.999-1.294-1.591-2.292-1.323c-1,0.268-1.592,1.293-1.325,2.292
		c2.26,8.429,9.931,14.315,18.657,14.315c8.726,0,16.397-5.886,18.656-14.315c0.268-0.999-0.325-2.024-1.324-2.292
		s-2.024,0.324-2.292,1.323c-1.821,6.795-8.006,11.54-15.04,11.54C64.465,89.011,58.281,84.266,56.46,77.471"/>
                <path display="none" fill={mainColor} d="M81.708,63.764c0.578-2.154,2.538-3.659,4.769-3.659c2.229,0,4.19,1.505,4.768,3.659
		c0.224,0.836,0.98,1.388,1.808,1.388c0.16,0,0.323-0.021,0.485-0.064c0.999-0.267,1.591-1.294,1.323-2.292
		c-1.015-3.788-4.462-6.434-8.384-6.434s-7.369,2.646-8.385,6.434c-0.268,0.998,0.325,2.025,1.324,2.292
		C80.415,65.354,81.441,64.762,81.708,63.764"/>
                <path display="none" fill={mainColor} d="M56.523,56.36c-3.922,0-7.369,2.646-8.385,6.435c-0.267,0.998,0.325,2.024,1.323,2.292
		c1,0.268,2.026-0.326,2.293-1.324c0.578-2.154,2.539-3.659,4.769-3.659s4.189,1.505,4.769,3.659
		c0.224,0.836,0.979,1.388,1.807,1.388c0.159,0,0.322-0.021,0.485-0.064c0.999-0.268,1.591-1.293,1.323-2.292
		C63.894,59.006,60.444,56.36,56.523,56.36"/>
                <path display="none" fill={mainColor} d="M109.533,67.142c0,1.192-0.966,2.159-2.159,2.159c-1.192,0-2.158-0.967-2.158-2.159
		s0.966-2.159,2.158-2.159C108.567,64.982,109.533,65.949,109.533,67.142"/>
                <path display="none" fill={mainColor} d="M38.287,36.917c0,1.191-0.966,2.159-2.159,2.159c-1.192,0-2.158-0.968-2.158-2.159
		c0-1.192,0.966-2.159,2.158-2.159C37.321,34.757,38.287,35.725,38.287,36.917"/>
                <path display="none" fill={mainColor} d="M126.047,53.314c-4.077-0.399-8.133-0.602-12.054-0.602
		c-10.434,0-24.303,1.516-31.124,8.741c-5.813,6.157-5.333,15.865-0.613,23.113c-3.253,3.475-6.153,7.018-8.547,10.427v-7.512
		c0-7.056-1.462-13.971-4.347-20.578c4.472-4.666,7.3-10.825,7.641-16.766c0.417-7.272-1.937-13.172-6.995-17.533
		c-15.825-13.646-52.923-6.992-53.287-6.916c-0.749,0.156-1.363,0.687-1.625,1.406c-0.264,0.718-0.135,1.52,0.338,2.121l4.786,6.087
		c5.591,7.208,7.488,13.544,9.164,19.134c2.226,7.43,4.148,13.847,13.765,17.898c6.89,2.901,15.709,2.758,22.713-2.355
		c2.276,5.649,3.43,11.523,3.43,17.503v15.31c0,1.019,0.696,1.904,1.686,2.146c0.175,0.042,0.351,0.063,0.524,0.063
		c0.81,0,1.574-0.447,1.959-1.192c2.486-4.79,6.556-10.315,11.567-15.732c4.133,4.338,8.713,6.139,14.483,6.139
		c9.896,0,16.141-7.203,18.101-16.552c1.796-6.054,3.848-12.911,9.937-20.76l0.008-0.01c0.505-0.633,0.624-1.491,0.31-2.238
		C127.55,53.908,126.853,53.394,126.047,53.314 M44.863,68.264c-7.698-3.243-9.11-7.958-11.248-15.096
		c-1.698-5.665-3.81-12.715-9.915-20.586l-2.44-3.104c9.398-1.204,34.521-3.308,45.863,6.472c4.02,3.464,5.809,8.023,5.47,13.934
		c-0.248,4.345-2.235,9.011-5.326,12.734c-5.163-9.486-13.268-17.79-23.106-23.607c-1.05-0.621-2.403-0.274-3.024,0.777
		c-0.62,1.05-0.273,2.404,0.776,3.025C51.46,48.458,59.24,56.597,63.98,65.857C58.202,70.481,50.792,70.761,44.863,68.264
		 M113.351,76.496c-0.018,0.061-0.033,0.12-0.046,0.183c-0.811,3.944-3.777,13.117-13.791,13.117c-4.69,0-8.146-1.388-11.42-4.903
		c4.532-4.514,9.279-8.454,13.518-11.2c1.023-0.664,1.316-2.031,0.652-3.056c-0.663-1.022-2.03-1.315-3.055-0.651
		c-4.342,2.814-9.164,6.783-13.771,11.319c-3.084-5.327-3.548-12.38,0.644-16.819c5.74-6.079,18.348-7.356,27.911-7.356
		c2.536,0,5.13,0.089,7.752,0.263C116.864,64.644,114.938,71.141,113.351,76.496"/>
                <path display="none" fill={mainColor} d="M96.936,90.568H22.164V38.432h12.11c0.85,0,1.538-0.688,1.538-1.537
		s-0.688-1.538-1.538-1.538H20.626c-0.849,0-1.537,0.689-1.537,1.538v55.21c0,0.85,0.688,1.538,1.537,1.538h13.375l5.729,12.353
		c0.353,0.76,1.281,1.101,2.042,0.748l5.608-2.602l17.004,12.132c0.683,0.487,1.659,0.323,2.146-0.358l15.892-22.273h14.516
		c0.849,0,1.537-0.688,1.537-1.537C98.474,91.257,97.785,90.568,96.936,90.568 M37.391,93.644h25.32l-20.839,9.664L37.391,93.644z
		 M64.918,112.879l-14.329-10.225l19.433-9.011h8.621L64.918,112.879z"/>
                <path display="none" fill={mainColor} d="M122.373,35.356h-13.374l-5.729-12.353c-0.351-0.758-1.28-1.1-2.042-0.748l-5.607,2.601
		L78.616,12.726c-0.683-0.487-1.659-0.323-2.145,0.358L60.58,35.356H40.425c-0.849,0-1.537,0.689-1.537,1.538
		s0.688,1.538,1.537,1.538h20.944h0.001c0.001,0,0.001,0,0.002,0h46.641c0.002,0,0.004,0,0.006,0c0.001,0,0.005,0,0.007,0h12.81
		v52.136h-17.749c-0.849,0-1.537,0.688-1.537,1.537c0,0.85,0.688,1.538,1.537,1.538h19.287c0.85,0,1.538-0.688,1.538-1.538V36.895
		C123.911,36.045,123.223,35.356,122.373,35.356 M105.609,35.356h-25.32l20.839-9.664L105.609,35.356z M78.082,16.122l14.329,10.224
		l-19.433,9.011h-8.622L78.082,16.122z"/>
                <path display="none" fill={mainColor} d="M71.5,79.194c8.092,0,14.676-6.583,14.676-14.675S79.592,49.844,71.5,49.844
		s-14.675,6.583-14.675,14.675S63.408,79.194,71.5,79.194 M71.5,52.919c6.396,0,11.601,5.204,11.601,11.6s-5.204,11.6-11.601,11.6
		s-11.601-5.204-11.601-11.6S65.104,52.919,71.5,52.919"/>
                <path display="none" fill={mainColor} d="M58.482,86.107h46.841c0.85,0,1.538-0.688,1.538-1.537c0-3.871,3.903-7.671,7.88-7.671
		c0.85,0,1.538-0.688,1.538-1.537V53.636c0-0.849-0.688-1.537-1.538-1.537c-3.977,0-7.88-3.8-7.88-7.671
		c0-0.849-0.688-1.537-1.538-1.537H36.84c-0.85,0-1.537,0.688-1.537,1.537c0,4.108-3.292,7.712-7.044,7.712
		c-0.85,0-1.538,0.688-1.538,1.537V75.32c0,0.849,0.688,1.538,1.538,1.538c3.752,0,7.044,3.603,7.044,7.711
		c0,0.85,0.688,1.538,1.537,1.538h15.493c0.849,0,1.537-0.688,1.537-1.538c0-0.849-0.688-1.537-1.537-1.537H38.264
		c-0.32-2.158-1.298-4.247-2.79-5.9c-1.576-1.745-3.552-2.856-5.678-3.219V55.085c2.126-0.363,4.102-1.474,5.678-3.219
		c1.492-1.654,2.47-3.743,2.79-5.901h65.657c0.776,4.42,4.774,8.334,9.283,9.08v18.907c-4.509,0.746-8.507,4.66-9.283,9.079H58.482
		c-0.849,0-1.538,0.688-1.538,1.538S57.634,86.107,58.482,86.107"/>
                <path display="none" fill={mainColor} d="M109.692,47.722c-0.178-0.094-0.372-0.154-0.571-0.178c4.31-3.921,6.71-9.518,6.579-15.343
		c0-10.311-6.863-18.701-15.3-18.701c-8.438,0-15.301,8.39-15.301,18.701c-0.131,5.825,2.27,11.422,6.579,15.344
		c-0.2,0.024-0.393,0.084-0.571,0.177c-6.929,1.152-12.183,6.871-12.747,13.872c-4.105-2.926-9.615-2.926-13.722,0
		c-0.563-7.001-5.819-12.72-12.746-13.872c-0.179-0.093-0.372-0.153-0.573-0.177c4.312-3.922,6.711-9.519,6.58-15.344
		c0-10.311-6.862-18.701-15.3-18.701c-8.437,0-15.3,8.39-15.3,18.701c-0.131,5.825,2.269,11.422,6.579,15.344
		c-0.199,0.024-0.394,0.084-0.571,0.177C25.929,48.945,20.513,55.321,20.5,62.8v18.7c0.021,18.77,15.23,33.979,34,34h10.2
		c2.686-0.007,5.209-1.289,6.8-3.453c1.591,2.164,4.114,3.446,6.8,3.453h10.2c18.77-0.021,33.979-15.23,34-34V62.8
		C122.486,55.321,117.072,48.943,109.692,47.722 M106.959,50.9c-0.763,3-3.464,5.099-6.559,5.099c-3.096,0-5.797-2.099-6.56-5.099
		H106.959z M88.5,32.201c0-8.437,5.339-15.301,11.9-15.301c6.561,0,11.899,6.864,11.899,15.301c0,8.435-5.339,15.3-11.899,15.3
		C93.839,47.5,88.5,40.636,88.5,32.201 M70.754,62.875c0.893,1.193,0.692,2.875-0.456,3.824c-0.442,0.427-0.619,1.059-0.464,1.653
		c0.156,0.595,0.62,1.058,1.215,1.214c0.594,0.155,1.227-0.021,1.653-0.463c1.584-1.441,2.294-3.607,1.872-5.705
		c4.057,1.542,6.311,5.884,5.237,10.089c-1.071,4.206-5.128,6.939-9.429,6.352c-4.3-0.586-7.476-4.307-7.381-8.646
		C63.096,66.853,66.432,63.274,70.754,62.875 M76.601,83.2c2.814,0.003,5.096,2.285,5.1,5.1v9.913
		c-9.543-1.859-16.061-9.281-19.012-13.39c0.958-1.032,2.303-1.621,3.711-1.623H76.601z M71.5,101.952
		c-1.591-2.164-4.114-3.445-6.8-3.452h-3.4v-9.885c3.542,4.552,8.067,8.245,13.237,10.803
		C73.34,100.013,72.299,100.881,71.5,101.952 M49.159,50.9C48.396,53.9,45.694,56,42.6,56S36.804,53.9,36.042,50.9H49.159z
		 M30.7,32.201c0-8.437,5.339-15.301,11.899-15.301c6.562,0,11.9,6.864,11.9,15.301c0,8.435-5.339,15.3-11.9,15.3
		C36.039,47.5,30.7,40.636,30.7,32.201 M23.899,81.5V62.8c0.01-5.346,3.579-10.03,8.73-11.457c1.008,4.7,5.163,8.057,9.97,8.057
		c4.808,0,8.962-3.357,9.969-8.057c5.152,1.427,8.723,6.111,8.731,11.457v2.403c-2.98,4.939-1.961,11.31,2.412,15.074
		c-1.855,0.618-3.44,1.858-4.487,3.511c-0.057,0.084-0.104,0.172-0.145,0.266c-0.765,1.285-1.171,2.751-1.181,4.246v10.2H54.5
		c-9.384-0.011-16.989-7.616-17-17V66.2c0-0.939-0.762-1.701-1.7-1.701c-0.939,0-1.7,0.761-1.7,1.701v15.3
		c0.012,11.261,9.139,20.388,20.4,20.4V112.1C37.607,112.081,23.919,98.393,23.899,81.5 M64.7,112.1h-6.801V101.9H64.7
		c2.816,0,5.1,2.283,5.1,5.1S67.517,112.1,64.7,112.1 M80,112.1h-1.7c-2.816,0-5.1-2.283-5.1-5.1s2.283-5.1,5.1-5.1h6.8V112.1H80z
		 M88.5,112.1V101.9c11.261-0.013,20.388-9.14,20.4-20.4V66.2c0-0.939-0.762-1.701-1.7-1.701s-1.7,0.761-1.7,1.701v15.3
		c-0.011,9.384-7.616,16.989-17,17h-3.4V88.3c-0.013-3.646-2.353-6.875-5.813-8.022c4.374-3.765,5.394-10.135,2.413-15.074V62.8
		c0.009-5.346,3.578-10.03,8.73-11.457c1.008,4.7,5.162,8.057,9.97,8.057c4.807,0,8.961-3.357,9.969-8.057
		c5.152,1.427,8.722,6.111,8.73,11.457v18.7C119.081,98.393,105.393,112.081,88.5,112.1"/>
                <path fill={mainColor} d="M83.783,56.604c0-5.322-4.329-9.651-9.651-9.651H60.971c-0.969,0-1.755,0.785-1.755,1.754V64.5
		c0,0.968,0.786,1.754,1.755,1.754h13.161C79.454,66.255,83.783,61.925,83.783,56.604 M62.726,50.461h11.406
		c3.387,0,6.143,2.755,6.143,6.142c0,3.386-2.756,6.142-6.143,6.142H62.726V50.461z"/>
                <path fill={mainColor} d="M94.313,56.604c0-11.127-9.053-20.181-20.181-20.181H50.442c-0.97,0-1.756,0.787-1.756,1.755v52.645
		c0,0.969,0.786,1.755,1.756,1.755h10.528c0.97,0,1.755-0.786,1.755-1.755V76.783h11.406C85.26,76.783,94.313,67.73,94.313,56.604
		 M60.971,73.274c-0.969,0-1.755,0.785-1.755,1.755v14.038h-7.019V39.933h21.935c9.192,0,16.671,7.479,16.671,16.671
		c0,9.191-7.479,16.671-16.671,16.671H60.971z"/>
                <path fill={mainColor} d="M71.5,105.913c0.969,0,1.755,0.786,1.755,1.755c0,0.97-0.786,1.755-1.755,1.755
		c-0.97,0-1.755-0.785-1.755-1.755C69.745,106.699,70.53,105.913,71.5,105.913"/>
                <path fill={mainColor} d="M109.404,19.577H33.596c-3.871,0-7.019,3.148-7.019,7.019v75.808c0,3.87,3.147,7.019,7.019,7.019h30.008
		c0.969,0,1.755-0.785,1.755-1.755c0-0.969-0.786-1.755-1.755-1.755H33.596c-1.935,0-3.51-1.574-3.51-3.509V26.596
		c0-1.935,1.575-3.51,3.51-3.51h75.809c1.935,0,3.509,1.575,3.509,3.51v75.808c0,1.935-1.574,3.509-3.509,3.509H79.396
		c-0.969,0-1.755,0.786-1.755,1.755c0,0.97,0.786,1.755,1.755,1.755h30.008c3.87,0,7.019-3.148,7.019-7.019V26.596
		C116.423,22.725,113.274,19.577,109.404,19.577"/>
            </g>
        </svg>
    );
};

export default Parking;