import React,{ useState, useCallback, Fragment} from 'react';
import { Redirect } from 'react-router';

//config
import {config} from "../../config";
//svg
import MenuClose from "../../svg/MenuClose";
import PitstopSmile from "../../svg/rajd/PitstopSmile";
import PitstopFresh from "../../svg/rajd/PitstopFresh";
import PitstopHealth from "../../svg/rajd/PitstopHealth";
import PitstopBikeService from "../../svg/rajd/PitstopBikeService";
import PitstopPhotobooth from "../../svg/rajd/PitstopPhotobooth";
import PitstopPrize from "../../svg/rajd/PitstopPrize";

const Icons = {
    PitstopSmile,
    PitstopFresh,
    PitstopHealth,
    PitstopBikeService,
    PitstopPhotobooth,
    PitstopPrize
};

const Tiles = (
    {
        tytul,
        lista,
        gradient,
        color,
        iconColor
    }) => {

    const [link,setLink] = useState(null);
    const [clicked,setClicked] = useState(null);
    const [modal,setModal] = useState(false);

    const handleClick = useCallback(
        (url,i,modal) => {
            if (!modal) {
                setLink(url);
                setClicked(i);
                setModal(false);
            } else {
                setModal(true);
                setLink(null);
                setClicked(i);
            }
        },[]
    );


    return (
        <Fragment>
            {
                lista &&
                <div className="section-tiles">
                    <h3 dangerouslySetInnerHTML={{__html: tytul}}/>
                    <div className="row tile-row">
                        {
                            lista &&
                            lista.map((item,index)=> {
                                    const Icon = Icons[item.nazwa_ikony];
                                    return (
                                        <Fragment key={index}>

                                            {
                                                !modal &&
                                                link &&
                                                (clicked === index) &&
                                                <Redirect to={`${config.domainRelPath}${link}`}/>
                                            }
                                            <div
                                                className="col col-xs-12 col-md-4 tile-container"
                                                key={index}
                                            >
                                                { modal && (clicked === index) &&
                                                    <div className="link-modal" >
                                                        <nav
                                                            className="link-modal-close"
                                                            onClick={()=>setModal(false)}
                                                        >
                                                            <MenuClose mainColor="#000000"/>
                                                        </nav>
                                                        <div className="square" style={gradient}/>
                                                        <div
                                                            className="link-modal-content"
                                                            dangerouslySetInnerHTML={{__html: item.tresc_modala}}
                                                        />
                                                    </div>
                                                }

                                                <div className="row tile middle-xs center-xs">
                                                    <div className="col col-xs-6 title">
                                                        <h4
                                                            style={{ color: color ? color : "#000000"}}
                                                            dangerouslySetInnerHTML={{__html: item.tytul}}
                                                            onClick={ ()=>handleClick( item.url, index, item.modal ) } />
                                                    </div>
                                                    <div
                                                        className="col col-xs-6 icon"
                                                        onClick={ ()=>handleClick( item.url, index, item.modal ) }
                                                    >
                                                        <div className="mask" style={gradient}/>
                                                        <figure>
                                                            <img
                                                                src={item.ikona_svg}
                                                                width={216}
                                                                height={247}
                                                                alt={`Ikona: ${item.tytul}`}
                                                            />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
            }

        </Fragment>


    );
};

export default Tiles;