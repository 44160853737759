import React, {Component} from 'react';
import {connect} from "react-redux";
import axios from "axios";

//actions
import {
    toggleOverlay,
    setPageData,
    setPostsData,
    setPageOptions } from "../../actions/pageActions";

// components
import Header from '../Header';
import Footer from '../Footer';
import Disclaimer from "../Disclaimer";

//tools
import {InView} from "react-intersection-observer";

//config
import { config }  from "../../config";

class Page extends Component {

    constructor(props) {
        super(props);
        this.handleOverlay = this.handleOverlay.bind(this);
        this.state = {
            disclaimer: localStorage.getItem("disclaimer")
        }
    }
    handleOverlay() {
        this.props.showOverlay(false);
    } 
    
    componentDidMount(props) {
        /** Newsletter **/
        const jqueryScript = document.createElement("script");  
        const extScript = document.createElement("script"); 
        jqueryScript.src = "https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js";
        extScript.src = "https://platform-stage.coniq.com/bundles/footfallsignup/js/coniqSignupBuilder.js?v730";
        jqueryScript.async = true;
        extScript.async = true;
        document.body.appendChild(jqueryScript);
        document.body.appendChild(extScript);
        /** Newsletter **/


        const {
            pageIndex,
            restName,
            postUrl } = this.props;


        this.setState({
            disclaimer: localStorage.getItem("disclaimer")
        });

        if(!postUrl){
            axios
                .get(`${config.domainRelPath}/wp-json/acf/v3/pages/?per_page=60`)
                .then(res => {
                    this.props.dispatchPageData(res.data[pageIndex].acf);
                    this.handleOverlay();
                    // console.log("PAGE INDEX - ", pageIndex, res.data)
                })
                .catch(error => console.log(error));
        }

        

        if (restName) {
            axios
                .get(`${config.domainRelPath}/wp-json/acf/v3/${restName}/?per_page=100`)
                .then(res => {
                    if (postUrl) {
                        this.props.dispatchPageData(
                            res.data.find( item => item.acf.url === postUrl).acf
                        );
                        this.handleOverlay();
                    } else {
                        this.props.dispatchShopsData(res.data);
                    }
                    

                })
                .catch(error => console.log(error));
        }

        axios
            .get(config.domainRelPath+'/wp-json/acf/v3/options/acf-options-turawa')
            .then( res => { this.props.dispatchPageDataOptions( res.data.acf ) } )
            .catch(error => console.log(error));

        window.scrollTo(0,0);
    }
      

    render() {
        
        const {pageOptions} = this.props;

        

        return (
            <>
                <Header/>
                {this.props.children}
                <InView>
                    {({ inView, ref }) => (
                        <div ref={ref}>
                            <Footer
                                pageOptions={pageOptions}
                                inView={inView}/>
                        </div>
                    )}
                </InView>
                {
                    !this.state.disclaimer &&
                    <Disclaimer
                        txt={pageOptions.tekst_disclaimera}
                        link={pageOptions.link_disclaimera}
                        btn={pageOptions.teksty_akceptuj}
                        readMore={pageOptions.teksty_czytaj_dalej}
                    />
                }


            </>
        );
    }
}
const mapStateToProps = state => ({
    pageOptions: state.page.pageOptions
});

const mapDispatchToProps = dispatch => ({
    showOverlay: value => dispatch(toggleOverlay(value)),
    dispatchPageData: data => dispatch( setPageData(data) ),
    dispatchShopsData: data => dispatch( setPostsData(data)),
    dispatchPageDataOptions: data => dispatch( setPageOptions(data) )
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);

