import React, {Component} from 'react';
import {connect} from "react-redux";

//actions
import { setPost } from "../../../actions/pageActions";

// components
import Page from '../../Page';
import PageHeader from "../../PageHeader";
import SquarePosts from "../../SquarePosts";

//config
import { config }  from "../../../config";

class Promocje extends Component {

    constructor(props) {
        super(props);
        this.state = { itemHovered:null };
    }

    render() {
        const {
            pageData,
            pageOptions,
            pageDataPosts
        } = this.props;

        const seasonGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_naglowki}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`
        };

        const bg = pageOptions.lightbox_opis_bg;

        return (
            <Page pageIndex={config.pageIndex.promocje}
                  restName="promocje">

                <article className="page wydarzenia promocje content">

                    <PageHeader
                        title={pageData.tytul}
                        subtitle={pageData.podtytul}
                        seasonGradient={seasonGradient}
                        seasonBlockWidth="35.4%"
                        seasonBlockHeight="241px"
                    />

                    <SquarePosts
                        pageData={pageData}
                        pageDataPosts={pageDataPosts}
                        pageOptions={pageOptions}
                        seasonGradient={seasonGradient}
                        bg={bg}
                        miniatura="miniatura_promocja"
                        cat="promo"
                    />
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    popupData: state.page.popupData,
    pageOptions: state.page.pageOptions,
    pageDataPosts: state.page.pageDataPosts
});

const mapDispatchToProps = dispatch => ({
    setSelectedPost: value => dispatch( setPost(value))
});


export default connect(mapStateToProps,mapDispatchToProps)(Promocje);