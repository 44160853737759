import React, {Component} from 'react';
import {connect} from 'react-redux';

//actions
import {togglePlayer,selectedVideo} from "../../../../actions/playerActions";

//tools
import Slider from "react-slick";
import {Link} from "react-router-dom";

//svg
import BackIcon from "../../../../svg/BackIcon";

//config
import {config} from "../../../../config";

class SliderSection extends Component {

    constructor(props) {
        super(props);
        this.handleVideoPlayer = this.handleVideoPlayer.bind(this);
    }

    

    handleVideoPlayer = video => {
        this.props.openPlayer();
        this.props.selectedVideo(video);
    };

    render() {
        const { pageDataSlidesList } = this.props;

        

        const sliderSettings = {
            autoplay:true,
            autoplaySpeed: 5000,
            cssEase:'ease-in-out',
            dots: true,
            arrows: false,
            infinite: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide:0,
            nextArrow: <div className="arrow next"><BackIcon mainColor="#000000" /></div>,
            prevArrow: <div className="arrow prev"><BackIcon mainColor="#000000" /></div>
        };

        return (
            <div className="slider-container">

                {/*<button
                    onClick={ ()=>this.handleVideoPlayer({ vimeoId:'89746368'} ) }>
                    PLAY
                </button>*/}
                

                <Slider {...sliderSettings}>   
                    {pageDataSlidesList
                        .map((item,index) =>
                            <div key={index}>
                                { item.typ_slajdu === 'link'  &&
                                    <Link to={config.domainRelPath + item.link}>
                                        <img src={item.banner} alt={item.tytul} className="banner"/>
                                    </Link>

                                }
                                { item.typ_slajdu === 'video' &&
                                    <button
                                        className="slide-video"
                                        onClick={ ()=>this.handleVideoPlayer({ vimeoId:item.vimeo_id} ) }>
                                        <img src={item.banner} alt={item.tytul} className="banner"/>
                                    </button>

                                }
                                { item.typ_slajdu === 'static' &&
                                    <img src={item.banner} alt={item.tytul} className="banner no-link"/>
                                }
                                { item.typ_slajdu === 'link_external' &&
                                    <a href={item.link_external_url} title={`Link: ${item.tytul}`} target="_blank" rel="noopener nofollow">
                                        <img src={item.banner} alt={item.tytul} className="banner no-link"/>
                                    </a>
                                }
                            </div>
                        )
                    }
                </Slider>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch( togglePlayer() ),
    selectedVideo: video => dispatch( selectedVideo(video) ),
});


export default connect(null,mapDispatchToProps)(SliderSection);