import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';

// components
import Page from '../../../Page';
import PageHeader from "../../../PageHeader";
import SquarePosts from "../../../SquarePosts";

//config
import { config }  from "../../../../config";
import { paths }  from "../../../../config/paths";

class Wydarzenia extends Component {
    constructor(props) {
        super(props);
        this.state = { itemHovered:null };
    }

    render() {
        const {
            pageData,
            pageOptions,
            pageDataPosts
        } = this.props;

        const seasonGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_naglowki}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`
        };

        const bg = pageOptions.lightbox_opis_bg;

        return (
            <Page pageIndex={config.pageIndex.aktualnosci.wydarzenia}
                  restName="wydarzenia">

                <article className="page wydarzenia content">

                    <PageHeader
                        title={pageData.tytul}
                        subtitle={pageData.podtytul}
                        seasonGradient={seasonGradient}
                        seasonBlockWidth="35.4%"
                        seasonBlockHeight="222px"
                    />

                    <SquarePosts
                        pageData={pageData}
                        pageDataPosts={pageDataPosts}
                        pageOptions={pageOptions}
                        seasonGradient={seasonGradient}
                        bg={bg}
                        miniatura="miniatura_news"
                        cat="news"
                    />

                    <nav className="to-archive">
                        <Link to={paths.archiwa}>archiwum</Link>
                    </nav>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    popupData: state.page.popupData,
    pageOptions: state.page.pageOptions,
    pageDataPosts: state.page.pageDataPosts
});

export default connect(mapStateToProps,null)(Wydarzenia);