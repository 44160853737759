import React from 'react';

const FoodIcon= ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_search" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="31.16px" height="31.45px"
             viewBox="0 0 31.16 31.45">
            <g>
                <path fill={mainColor} d="M11.63,3.82c-0.89,0-1.75,0.68-2.44,1.9C8.54,6.91,8.16,8.43,8.16,9.9c0,1.39,0.72,2.65,1.87,3.29
				c0.65,0.36,1.03,1.03,0.99,1.75l-0.19,4.08l0,0l-0.51,11.06c-0.02,0.36,0.11,0.71,0.36,0.97c0.25,0.26,0.59,0.41,0.95,0.41
				c0.36,0,0.7-0.14,0.95-0.41c0.25-0.26,0.38-0.61,0.36-0.97l-0.7-15.14c-0.03-0.72,0.35-1.4,0.99-1.75
				c1.16-0.64,1.87-1.9,1.87-3.29c0-1.46-0.38-2.98-1.04-4.18C13.39,4.49,12.52,3.82,11.63,3.82z"/>
                <path fill={mainColor} d="M24.55,10.14l-0.3-5.81C24.23,4.04,24,3.82,23.71,3.82c-0.15,0-0.28,0.06-0.39,0.16c-0.1,0.11-0.16,0.24-0.16,0.39
				l0.11,5.03c0,0.21-0.07,0.41-0.22,0.56s-0.34,0.23-0.55,0.23c-0.42,0-0.77-0.34-0.77-0.77l-0.05-5.17c0-0.24-0.2-0.44-0.44-0.44
				s-0.44,0.2-0.44,0.44l-0.05,5.17c0,0.42-0.35,0.76-0.77,0.76c-0.21,0-0.41-0.08-0.55-0.23c-0.15-0.15-0.23-0.35-0.22-0.56
				l0.11-5.05c0-0.14-0.05-0.27-0.15-0.37c-0.1-0.1-0.23-0.16-0.37-0.16c-0.27,0-0.5,0.21-0.52,0.49l-0.3,5.83
				c-0.07,1.27,0.59,2.45,1.71,3.07c0.62,0.34,1,1.03,0.97,1.75l-0.5,10.65l0,0l-0.21,4.44c-0.02,0.36,0.12,0.73,0.37,0.99
				c0.24,0.26,0.57,0.41,0.92,0.41h0.02c0.35,0,0.7-0.15,0.94-0.4c0.25-0.26,0.39-0.62,0.37-0.98l-0.7-15.13
				c-0.03-0.72,0.35-1.41,0.97-1.75C23.95,12.6,24.62,11.39,24.55,10.14z"/>
            </g>
        </svg>
    );
};

export default FoodIcon;