import React, {Component} from 'react';

//tools
import { TweenLite } from "gsap/TweenLite";
import { TimelineLite } from "gsap";
import classnames from "classnames";

class LinksSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: null
        };
        this.handleItemEnter = this.handleItemEnter.bind(this);
        this.handleItemLeave = this.handleItemLeave.bind(this);
    }

    handleItemEnter = (event,index) =>{
        this.setState({
            active:index
        });
        const hoveredElFigure = event.currentTarget.firstElementChild;
        //animate
        const zoomOut = new TimelineLite({
            paused:true,
            yoyo:false,
            repeat:false
        });﻿﻿
        TweenLite.set(hoveredElFigure, {
            backgroundSize: '100%',
            backgroundPosition:'center 0px'
        });﻿﻿
        zoomOut
            .to(hoveredElFigure, .28, {
                ease:"none",
                backgroundSize: '110%',
                backgroundPosition:'center -20px'

            })
            .play();﻿
    };
    handleItemLeave = event =>{
        this.setState({
            active:null
        });
        const hoveredElFigure = event.currentTarget.firstElementChild;
        //animate
        const zoomIn = new TimelineLite({
            paused:true,
            yoyo:false,
            repeat:false
        });﻿﻿
        TweenLite.set(hoveredElFigure, {
            backgroundSize: '110%',
            backgroundPosition:'center -20px'
        });﻿﻿
        zoomIn
            .to(hoveredElFigure, .1, {
                ease:"none",
                backgroundSize: '100%',
                backgroundPosition: 'center 0px'


            })
            .play();﻿
    };

    render() {

        const {
            pageData,
            pageOptions,
            pageDataLinksList } = this.props;

        const seasonGradient = {
            background: `linear-gradient( 
                            ${pageOptions.rotacja_naglowki}deg, 
                            ${pageOptions.kolor_1_naglowki} 53%, 
                            ${pageOptions.kolor_2_naglowki} 85% 
                        )`
        };
        const seasonTxtColor = {
            color: pageOptions.kolor_sezonowy_tekst
        };
        return (
            <div className="row links-row-container">
                {pageDataLinksList
                    .map((item,index) =>
                        <div className={classnames(
                            "col col-xs-12 col-md-6",{
                                "active" : this.state.active === index
                            })
                        }
                             style={ index===this.state.active ? seasonGradient : {} }
                             key={index}
                             onMouseEnter={event=>this.handleItemEnter(event,index)}
                             onMouseLeave={event=>this.handleItemLeave(event)}
                             >
                            <figure aria-hidden={true}
                                    style={{
                                        backgroundImage:`url(${item.obraz})`
                                    }}>
                                <a href={item.link}>
                                    <div
                                        className="fold"
                                        style={ seasonGradient }
                                    />

                                    <img src={pageData.tlo_bezbarwne_dla_kafli}
                                         alt=""/>
                                </a>
                            </figure>
                            <figcaption >
                                <a href={item.link} style={this.state.active === index ? seasonTxtColor : {}}>
                                    <h2 dangerouslySetInnerHTML={{__html: item.tytul}}/>
                                    <p>{pageOptions.teksty_zobacz_co_nowego_label}</p>
                                    <span>
                                                        + <span>{pageOptions.teksty_wiecej}</span>
                                                    </span>
                                </a>
                            </figcaption>

                        </div>
                    )
                }

            </div>
        );
    }
}

export default LinksSection;