import React, {Component} from 'react';
import {connect} from 'react-redux';

//actions
import { toggleLightbox} from "../../actions/lightboxActions";

//components
import Arrow from "../../svg/lightbox/Arrow";

//tools
import Slider from "react-slick";
import MediaQuery from "react-responsive/src/Component";
import classnames from "classnames";

class LightboxGallery extends Component {

    componentDidMount() {
        window.scrollTo(0,0)
    }

    render() {

        const {
            data,
            pageOptions,
            closeLightbox
        } = this.props;

        const sliderSettings = {
            autoplay:false,
            fade: true,
            dots: false,
            arrows: true,
            infinite: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide:0,
            nextArrow: <div className="arrow next"><Arrow mainColor="#FFFFFF" /></div>,
            prevArrow: <div className="arrow prev"><Arrow mainColor="#FFFFFF" /></div>
        };

        const pre = "lightbox-gallery";

        return (
            <div
                className={ pre + "-overlay" }
                style={{backgroundColor: pageOptions.player_bg}}
            >
                <div className={ pre + "-container" }>
                    <MediaQuery maxWidth={768}>
                        <figcaption>
                            <nav onClick={ () => closeLightbox() }>X</nav>
                            <article
                                style={{backgroundColor: pageOptions.lightbox_opis_bg}}
                                className={classnames(
                                    {"title-only" :!data.text}
                                )}
                            >
                                <h3>{data.title}</h3>
                                {
                                    data.text &&
                                    <p dangerouslySetInnerHTML={{__html:data.text}}/>
                                }

                            </article>
                        </figcaption>
                    </MediaQuery>
                    <figure className={pre + "-slider"}>
                        <MediaQuery minWidth={769}>
                            <figcaption>
                                <nav onClick={ () => closeLightbox() }>X</nav>
                                <article
                                    style={{backgroundColor: pageOptions.lightbox_opis_bg}}
                                    className={classnames(
                                        {"title-only" :!data.text}
                                    )} >
                                    <h3>{data.title}</h3>
                                    { data.text && <p dangerouslySetInnerHTML={{__html:data.text}}/> }
                                </article>
                            </figcaption>
                        </MediaQuery>

                        <Slider {...sliderSettings}>
                            {data.imgList
                                .map((item,index) =>
                                    <img
                                        key={index}
                                        src={item}
                                        alt="" />
                                )
                            }
                        </Slider>
                    </figure>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: state.lightbox.selectedGallery,
    pageOptions: state.page.pageOptions
});

const mapDispatchToProps = dispatch => ({
    closeLightbox: () => dispatch( toggleLightbox() )
});


export default connect(mapStateToProps,mapDispatchToProps)(LightboxGallery);