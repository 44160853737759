import React from 'react';

const DrugstoreIcon= ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_search" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="31.16px" height="31.45px" viewBox="0 0 31.16 31.45">
             <g>
                 <path fill={mainColor} d="M27.41,12.97h-9.15V3.82c0-1.01-0.82-1.83-1.83-1.83h-1.22c-1.01,0-1.83,0.82-1.83,1.83v9.15H4.24
			c-1.01,0-1.83,0.82-1.83,1.83v1.22c0,1.01,0.82,1.83,1.83,1.83h9.15v9.15c0,1.01,0.82,1.83,1.83,1.83h1.22
			c1.01,0,1.83-0.82,1.83-1.83v-9.15h9.15c1.01,0,1.83-0.82,1.83-1.83V14.8C29.23,13.79,28.42,12.97,27.41,12.97z"/>
            </g>
        </svg>
    );
};

export default DrugstoreIcon;