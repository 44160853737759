import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../Page';
import List from '../../List';

//config
import { config }  from "../../../config";

//data
import { columns } from './dataLists';

class ViewSklepyIUslugi extends Component {
    render() {

        const {
            pageData,
            pageDataPosts,
            pageOptions
        } = this.props;

        const headerGradient = {
            background: `linear-gradient( 
                            ${pageOptions.rotacja_naglowki}deg, 
                            ${pageOptions.kolor_1_naglowki} 46%, 
                            ${pageOptions.kolor_2_naglowki} 55% 
                        )`
        };

        const seasonTxtColor = {
            color: pageOptions.kolor_sezonowy_tekst
        };

        return (
            <Page pageIndex={config.pageIndex.sklepy}
                  restName="sklepy">
                <article className="page sklepy-i-uslugi content">
                    <div className="boxed">
                        <div className="row cover-row-container">
                            <div className={"col col-xs-12"}>
                                <h2><img src={pageData.obraz} alt={pageData.nazwa}/></h2>
                            </div>
                        </div>


                        <div className="row hd-row-container"
                            style={headerGradient}>
                            <div className={"col col-xs-12"}>
                                <h2 style={seasonTxtColor}>{ pageOptions.teksty_sklepy }</h2>
                            </div>
                        </div>

                        <div className="row shops-row-container center-xs start-md">
                            <div className={'col col-xs-12 col-sm-6  col-md-3'}>
                                <div className={'row'}>
                                    {
                                        columns.col1.map( (item,index) =>
                                            <List data={pageDataPosts}
                                                  group={'sklepy'}
                                                  category={item.cat}
                                                  categoryId={'kategoria_sklepu'}
                                                  grid={'col col-xs-12'}
                                                  key={item.cat+index}
                                            />)
                                    }
                                </div>
                            </div>
                            <div className={'col col-xs-12 col-sm-6  col-md-3'}>
                                <div className={'row'}>
                                    {
                                        columns.col2.map( (item,index) =>
                                            <List data={pageDataPosts}
                                                  group={'sklepy'}
                                                  category={item.cat}
                                                  categoryId={'kategoria_sklepu'}
                                                  grid={'col col-xs-12'}
                                                  key={item.cat+index}
                                                  theme={item.theme}
                                            />)
                                    }
                                </div>
                            </div>
                            <div className={'col col-xs-12 col-sm-6  col-md-3'}>
                                <div className={'row'}>
                                    {
                                        columns.col3.map( (item,index) =>
                                            <List data={pageDataPosts}
                                                  group={'sklepy'}
                                                  category={item.cat}
                                                  categoryId={'kategoria_sklepu'}
                                                  grid={'col col-xs-12'}
                                                  key={item.cat+index}
                                            />)}
                                </div>
                            </div>
                            <div className={'col col-xs-12 col-sm-6  col-md-3'}>
                                <div className={'row'}>
                                    {
                                        columns.col4.map( (item,index) =>
                                        <List data={pageDataPosts}
                                              group={'sklepy'}
                                              category={item.cat}
                                              categoryId={'kategoria_sklepu'}
                                              grid={'col col-xs-12'}
                                              key={item.cat+index}
                                        />)}
                                </div>
                            </div>

                        </div>

                        <div className="row hd-row-container"
                             style={headerGradient}>
                            <div className={"col col-xs-12"}>
                                <h2 style={seasonTxtColor}>{ pageOptions.teksty_uslugi }</h2>
                            </div>
                        </div>


                        <div className="row services-row-container center-xs start-md">
                            <div className={'col col-xs-12'}>
                                <div className={'row'}>
                                    {
                                        columns.col1_1.map( (item,index) =>
                                            <List data={pageDataPosts}
                                                  group={'uslugi'}
                                                  category={item.cat}
                                                  categoryId={'katogoria_uslug'}
                                                  grid={'col col-xs-12 col-md-6'}
                                                  key={item.cat+index}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageDataPosts: state.page.pageDataPosts,
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(ViewSklepyIUslugi);