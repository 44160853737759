import React, {Component} from 'react';

//components
import PageHeader from "../../../../PageHeader";


class Articles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemHovered:null
        };
        this.handleFileOpen = this.handleFileOpen.bind(this);
    }

    handleFileOpen = file => {
        window.open(file, '_blank', 'fullscreen=no')
    };

    render() {
        const {
            artykuly,
            artykulyData,
            slogan,
            greenGradient,
            green
        } = this.props;
        return (
            <div className="row participants articles">

                <div className="col col-xs-12 txt">
                    <PageHeader
                        title={artykulyData.tytul}
                        text={artykulyData.podtytul} />
                </div>

                <div className="col col-xs-11 list">
                    <div className="row">
                        {
                            artykuly &&
                            artykuly.map(
                                (item,index) =>
                                    <div
                                        key={index}
                                        className="col col-xs-12 col-sm-6 col-md-4 item"
                                        onClick={ ()=> this.handleFileOpen(item.pdf) }
                                        onMouseOver={()=>this.setState({itemHovered:index})}
                                        style={index === this.state.itemHovered ? greenGradient : {}}
                                    >
                                        <div className="row">
                                            <div className="col col-xs-4 rotate arrow-container">
                                                <div
                                                    style={index === this.state.itemHovered ? {borderColor:`transparent transparent transparent ${green}`} : {}}
                                                    className="arrow"/>
                                                <p
                                                    style={index === this.state.itemHovered ? {color:'#f6b938'} : {}}
                                                    dangerouslySetInnerHTML={{__html:item.tytul}}
                                                />
                                            </div>
                                            <div className="col col-xs-8 miniatura">
                                                <img src={item.miniatura}/>
                                            </div>
                                        </div>
                                    </div>
                            )
                        }
                    </div>
                </div>
                <div className="col col-xs-1 slogan">
                    <img src={slogan} />
                </div>
            </div>
        );
    }
}



export default Articles;