export const TOGGLE_HOME_MENU = 'menuHomeToggleState';
export const TOGGLE_OVERLAY = 'routesToggleOverlay';
export const SET_SEARCH_FOCUS = 'setSearchFocusState';
export const SET_PAGE_DATA = 'setPageDataFromAcf';
export const SET_POSTS_DATA = 'setPostsDataFromPostCustomType';
export const SET_PAGE_OPTIONS = 'setPageOptionsFromAcf';
export const SET_HOVERED_MENU_ITEM = 'setHoveredMenuItemIndex';
export const SET_POST = 'setPost';
export const SET_HOVERED_POST = 'setHoveredPost';
export const SET_POPUP = 'setPopup';
export const SET_FILTER_YEAR = 'setFilterYear';
export const SELECT_POSTS_PAGE = 'setPaginatorSelectedPage';
export const SET_NEWSLETTER_VISIBILITY = 'setNewsletterVisibility';
export const SET_MC_NEWSLETTER_VISIBILITY = 'setMailChimpNewsletterVisibility';
export const SET_CONTACTFORM_VISIBILITY = 'setContactFormVisibility';

export const toggleHomeMenu = () => ({
    type: TOGGLE_HOME_MENU
});

export const toggleOverlay = value => ({
    type: TOGGLE_OVERLAY,
    value
});

export const setSearchFocus = value => ({
    type: SET_SEARCH_FOCUS,
    value
});

export const setPageData = data => ({
    type: SET_PAGE_DATA,
    data
});

export const setPostsData = ( data,value ) => ({
    type: SET_POSTS_DATA,
    data,
    value
});

export const setPageOptions = data => ({
    type: SET_PAGE_OPTIONS,
    data
});

export const setHoveredMenuItemIndex = value => ({
    type: SET_HOVERED_MENU_ITEM,
    value
});

export const setPost = (url,cat) => ({
    type: SET_POST,
    url,cat
});

export const setHoveredPost = url => ({
    type: SET_HOVERED_POST,
    url
});

export const setPopup = data => ({
    type: SET_POPUP,
    data
});
export const setFilterYear = value => ({
    type: SET_FILTER_YEAR,
    value
});

export const setSelectedPage = value => ({
    type: SELECT_POSTS_PAGE,
    value
});

export const setNewsletterVisibility = value => ({
    type: SET_NEWSLETTER_VISIBILITY,
    value
});

export const setMailChimpNewsletterVisibility = value => ({
    type: SET_MC_NEWSLETTER_VISIBILITY,
    value
});

export const setContactFormVisibility = value => ({
    type: SET_CONTACTFORM_VISIBILITY,
    value
});
