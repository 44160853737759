import React, {Component} from 'react';
import {connect} from "react-redux";

//actions
import {togglePlayer,selectedVideo} from "../../actions/playerActions";

class GalleryVideos extends Component  {
    constructor(props) {
        super(props);
        this.handleVideoPlayer = this.handleVideoPlayer.bind(this);
    }
    handleVideoPlayer = video => {
        this.props.openPlayer();
        this.props.selectedVideo(video);
    };

    render(){
        const {
            videos,
            playIcon
        } = this.props;

        return (
            <div className="row video-gallery-row-container center-xs">
                { videos &&
                    videos.map(
                        (item,index) =>
                        <div
                            key={index}
                            className="col col-xs-12 col-sm-6 col-md-4"
                            onClick={ ()=>this.handleVideoPlayer({vimeoId: item.vimeo_id}) }
                        >
                            <div
                                className="icon"
                                style={{
                                    backgroundImage: `url(${playIcon})`
                                }}
                            />
                            <span>
                                <img
                                    src={item.okladka}
                                    alt={item.tytul}
                                    width={450}
                                    height={300}
                                />
                            </span>
                        </div>
                    )
                }
            </div>
        );
    }



}
const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch( togglePlayer() ),
    selectedVideo: video => dispatch( selectedVideo(video) ),
});


export default connect(null,mapDispatchToProps)(GalleryVideos);