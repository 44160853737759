import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../../Page';
// import PageHeader from "../../../PageHeader";

//config
import { config }  from "../../../../config";



class ViewLojalnoscLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
    
        var Headers1 = new Headers();
        Headers1.append("x-api-version", "2.0");
        Headers1.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "client_id": "129_2k6ix5hqg884880sk480swgooc880okoksws4kkskokc0ck8so",
            "client_secret": "6an5es08l1oo80co80080ok0w0cs0s8ckso84c4gcoc8k0c4c0",
            "grant_type": "password",
            "username": this.state.username,
            "password": this.state.password
        });
    
        // console.log(raw);
    
        var requestOptions = {
          method: 'POST',
          headers: Headers1,
          body: raw,
          redirect: 'follow'
        };  
        
        try {
          // GET TOKEN
          const response = await fetch('https://poweredby.coniq.com/oauth/v2/token', requestOptions);
          const result = await response.json();
          //this.setState({ userInfo: result });
          if (result && result.access_token) {
            //console.log(result);

            const Headers2 = new Headers();
            Headers2.append("x-api-version", "3.0");
            Headers2.append("Authorization", `Bearer ${result.access_token}`);
            Headers2.append("Content-Type", "application/json");

            requestOptions = {
                method: 'GET',
                headers: Headers2,
                redirect: 'follow'
            };

            const response = await fetch('https://api.coniq.com/app/customer/program/Klub_Turawa', requestOptions);
           
            const userinfo = await response.json();
            this.setState({ userInfo: userinfo });
            //console.log(userinfo);
            //this.setState(userinfo);

            const response2 = await fetch('https://api.coniq.com/app/program/Klub_Turawa/offers', requestOptions);
           
            const actionsinfo = await response2.json();
            this.setState({ actionsInfo: actionsinfo });
            //console.log(actionsinfo);

            
          } else if (result && result.error) {
            // console.log(result);
            this.setState({ userInfo: result });
          }
        } catch (error) {
          console.log('error', error);
        }
    };

    render() {
        const {
            pageData
        } = this.props;

        const { userInfo } = this.state;
        const { actionsInfo } = this.state;
        
        // console.log(userInfo);
        // console.log(actionsInfo);
        var userInfoCode = '';
        var loginError = '';
        if (userInfo && userInfo.error) {
            loginError = userInfo.error_description;
        } else if (userInfo) {
            // userInfoCode += `<li><b>barcode_number:</b> ${userInfo.barcode_number}</li>`;
            // userInfoCode += `<li><b>barcode_image_url:</b> ${userInfo.barcode_image_url}</li>`;
            // userInfoCode += `<lh><b>barcode_images:</b></lh>`;
            // userInfoCode += `<li><b>f1D:</b> ${userInfo.barcode_images.f1D}</li>`;
            // userInfoCode += `<li><b>fDM:</b> ${userInfo.barcode_images.fDM}</li>`;
            // userInfoCode += `<li><b>dfDM:</b> ${userInfo.barcode_images.dfDM}</li>`;
            // userInfoCode += `<li><b>bDM:</b> ${userInfo.barcode_images.bDM}</li>`;
            // userInfoCode += `<li><b>dbDM:</b> ${userInfo.barcode_images.dbDM}</li>`;
            // userInfoCode += `<li><b>title:</b> ${userInfo.title}</li>`;
            userInfoCode += `<h3 style="margin-bottom: 15px">Imię:  ${userInfo.first_name}</h3>`;
            userInfoCode += `<h3 style="margin-bottom: 15px">Nazwisko:  ${userInfo.last_name}</h3>`;
            // userInfoCode += `<lh><b>tier:</b></lh>`;
            // userInfoCode += `<li><b>label:</b> ${userInfo.tier.label}</li>`;
            // userInfoCode += `<li><b>expiry_date:</b> ${userInfo.tier.expiry_date}</li>`;
            // userInfoCode += `<li><b>assigned_on:</b> ${userInfo.tier.assigned_on}</li>`;
            // userInfoCode += `<li><b>spent_since_join:</b> ${userInfo.tier.spent_since_join}</li>`;
            // userInfoCode += `<li><b>earned_since_join:</b> ${userInfo.tier.earned_since_join}</li>`;
            userInfoCode += `<h3 style="margin-bottom: 15px">Saldo:  ${userInfo.balance}</h3>`;
            userInfoCode += `<h3 style="margin-bottom: 15px">Suma zebranych punktów:  ${userInfo.lifetime_balance}</h3>`;
            //userInfoCode += `<h3 style="margin-bottom: 15px">Suma punktów zrealizowanych:  ${userInfo.lifetime_spend}</h3>`;
            userInfoCode += `<h3 style="margin-bottom: 15px">Data rozpoczęcia subskrybcji:  ${userInfo.subscription_start_date}</h3>`;
        }
 

        return (
            <Page pageIndex={config.pageIndex.lojalnosclogin}>
            
                <article className="page o-nas content">

                    <div className="boxed">

                        {pageData.banner ? (
                            <div className="row cover-row-container">
                                <img src={pageData.banner} 
                                    alt="program lojalnościowy" 
                                    title="program lojalnościowy" 
                                    style={{maxWidth: "100%", height: "auto"}} 
                                />
                            </div>
                        ) : (
                            <div></div>
                        )}


                        <div className="row content-row-container center-xs start-sm">
                            <div className={"col col-xs-12 col-md-offset-1 col-md-11"}>
                                <div className="row">

                                    {/* <MediaQuery minWidth={992}>
                                        <div className={"col col-md-4"}>
                                            <aside style={ seasonGradientOpacity }>
                                                <div className="full-color" style={ seasonGradient }/>
                                            </aside>
                                        </div>
                                    </MediaQuery> */}

                                    <div className={"col col-md-8"}>
                                        <p dangerouslySetInnerHTML={{__html: pageData.opis}} />
                                    </div>

                                    {userInfoCode ? (
                                        
                                    <>
                                        <div className={"col col-md-8"}>
                                            {<div dangerouslySetInnerHTML={{__html: userInfoCode}} />}
                                        </div> 

                                        <div className={"col col-md-8"}>
                                            &nbsp;
                                        </div> 

                                        <div className={"col col-md-8"}>

                                            {actionsInfo && actionsInfo.map((offer) => (
                                                !offer.tags ? 
                                                    <div key={offer.id}>
                                                    <h3 style={{marginBottom: '15px'}}>{offer.title}</h3>
                                                    <img src={offer.photo_medium_url} alt={offer.title} style={{maxWidth: "100%", height: "auto"}}/>
                                                    <p dangerouslySetInnerHTML={{__html: offer.description}}></p>  
                                                    <br />                    
                                                    </div>
                                                : null
                                            ))}

                                            {actionsInfo && actionsInfo.map((offer) => (
                                                offer.tags && offer.tags[0] !== 'events' ? 
                                                    <div key={offer.id}>
                                                    <h3 style={{marginBottom: '15px'}}>{offer.title}</h3>
                                                    <img src={offer.photo_medium_url} alt={offer.title} style={{maxWidth: "100%", height: "auto"}}/>
                                                    <p dangerouslySetInnerHTML={{__html: offer.description}}></p>  
                                                    <br />                    
                                                    </div>
                                                : null
                                            ))}

                                            {actionsInfo && actionsInfo.map((offer) => (
                                                offer.tags && offer.tags[0] === 'events' ? 
                                                    <div key={offer.id}>
                                                    <h3 style={{marginBottom: '15px'}}>{offer.title}</h3>
                                                    <img src={offer.photo_medium_url} alt={offer.title} style={{maxWidth: "100%", height: "auto"}}/>
                                                    <p dangerouslySetInnerHTML={{__html: offer.description}}></p>  
                                                    <br />                    
                                                    </div>
                                                : null
                                            ))}
                                        </div>
                                    </>


                                    ) : (
                                        <>
                                        <div className={"col col-md-8"}>
                                            {<h2 dangerouslySetInnerHTML={{__html: loginError}} />}
                                            <form onSubmit={this.handleFormSubmit} className="kartaFrom">
                                                <div className="col text-input col-xs-12">
                                                    <label htmlFor="first_name">E-mail *</label>
                                                    <div>
                                                    <input 
                                                        id="first_name"
                                                        type="email" 
                                                        value={this.state.username} 
                                                        onChange={(event) => this.setState({ username: event.target.value })}
                                                        placeholder="E-mail" 
                                                        required="required" 
                                                    />
                                                    </div>
                                                    <figcaption></figcaption>
                                                </div>

                                                <div className="col text-input col-xs-12">
                                                    <label htmlFor="first_name">Hasło *</label>
                                                    <div>
                                                    <input 
                                                        id="first_name"
                                                        type="password" 
                                                        value={this.state.password} 
                                                        onChange={(event) => this.setState({ password: event.target.value })}
                                                        placeholder="Hasło" 
                                                        required="required" 
                                                    />
                                                    </div>
                                                    <figcaption></figcaption>
                                                </div>

                                                <br />
                                                <div className="col submit col-xs-4 ">
                                                    <button type="submit" className="btn form-btn">ZALOGUJ SIĘ</button>
                                                </div>
                                            </form>
                                        </div>
                                        </>
                                    )}

                                    <div className={"col col-md-8"}>
                                        &nbsp;
                                    </div>

                                    <div className={"col col-md-8"}>
                                        <p dangerouslySetInnerHTML={{__html: pageData.tekst_po_formularzu}} />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </article>
            </Page>
        );
    }


}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(ViewLojalnoscLogin);