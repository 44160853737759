import React from 'react';
import classnames from "classnames";
import {connect} from "react-redux";

const HoursLabels = ({pageOptions}) => {

    const hourFullLabels = [
        {name: pageOptions.teksty_od_poniedzialku_do_czwartku},
        {name: pageOptions.teksty_piatek},
        {name: pageOptions.teksty_sobota},
        {name: pageOptions.teksty_niedziela},
        {name: pageOptions.teksty_niedziela_wolna}
    ];

    return (
        <div className={classnames(
            "col col-xs-12",
            "col-sm-6",
            "col-md-2",
            "labels"
        )}>
            <h3><img src={pageOptions.zaslepka_godziny_otwarcia} alt="" /></h3>
            <ul>
                {
                    hourFullLabels.map((item,index)=>
                        <li key={index}>
                            {item.name}
                        </li>
                    )
                }
            </ul>
        </div>
    );
};

const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions
});

export default connect(mapStateToProps,null)(HoursLabels);