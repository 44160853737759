import React, {Component} from 'react';
import {connect} from "react-redux";
import { Redirect } from 'react-router';

//actions
import {
    setPost,
    setFilterYear,
    setSelectedPage
} from "../../../../actions/pageActions";

import { setPopup, setRedirection } from "../../../../actions/popupActions";

// components
import Page from '../../../Page';
import PageHeader from "../../../PageHeader";
import Popup from "../../../Popup";

//tools
import MediaQuery from 'react-responsive';
import classnames from 'classnames';

//config
import { config }  from "../../../../config";



class Archiwum extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hoveredIndex:null,
            hoveredBg: {
                background:'#FFFFFF'
            }
        };
        this.handlePostSelection = this.handlePostSelection.bind(this);
        this.handlePostHover = this.handlePostHover.bind(this);
        this.handleYearSelection = this.handleYearSelection.bind(this);
    }

    handlePostHover = ( lightboxBg, i ) => {
        this.setState({
            hoveredBg: lightboxBg,
            hoveredIndex: i
        })
    };

    handleYearSelection = date => {
        this.props.setFilterYear(date);
        this.props.setNavPage(1);
        this.setState({
            hoveredBg: {
                background:'#FFFFFF'
            },
            hoveredIndex: null
        })
    };

    handlePostSelection = ( popup, url, title, txt, date, cover, seasonGradient, bg
    ) => {
        this.props.setSelectedPost(url,'archive');
        this.props.setPopup( { popup, url, title, txt, date, cover, seasonGradient, bg } );
        this.props.setRedirection( popup ? false : url);
    };

    render() {
        const {
            pageData,
            pageOptions,
            pageDataPosts,
            bg,
            popupData,
            redirect,
            redirectLink
        } = this.props;

        const seasonGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_naglowki}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`
        };
        const seasonTxtColor = {
            color: pageOptions.kolor_sezonowy_tekst
        };

        const distinct = (value,index,self) => {
            return self.indexOf(value) === index;
        };
        const years = pageDataPosts.map(item => item.acf.data_publikacji.split('/')[2] );
        const distinctYears = years.filter(distinct);

        const calculatedByYear = pageDataPosts
            .filter(item => item.acf.archive)
            .filter( item => item.acf.data_publikacji.includes(this.props.paginationYear) )
            .map( item => item );

        const calculatedByPage = calculatedByYear
            .filter( ( item,index ) => {
                if (this.props.paginationCurrentPage>1) {
                    const min = parseInt( (this.props.paginationCurrentPage * this.props.paginationPagesPerPage) - this.props.paginationPagesPerPage );
                    const max =  parseInt(this.props.paginationCurrentPage*this.props.paginationPagesPerPage );
                    return (index+1) > min && (index+1) <= max;
                } else {
                    return (index+1) >= this.props.paginationCurrentPage && (index+1) <= this.props.paginationPagesPerPage
                }

            } )
            .map( item=>item );

        const calculatedNavList = calculatedByYear
            .filter( (item,index) => {
                return (index % this.props.paginationPagesPerPage) === 0  }
                )
            .map( (item,index) => index + 1 );

        return (

            <Page pageIndex={config.pageIndex.aktualnosci.archiwum}
                  restName="wydarzenia">

                { redirect && <Redirect to={redirectLink}/> }

                <article className="page archiwum content">

                    <PageHeader
                        title={pageData.tytul}
                        subtitle={pageData.podtytul}
                        text={pageData.tekst_naglowka}
                    />

                    <ul className="filter date">
                        {
                            distinctYears.map((item, index) => {
                                       return <li
                                           key={index}
                                           className={classnames(
                                               {'active': item === this.props.paginationYear}
                                           )}
                                           onClick={ () => this.handleYearSelection(item) }
                                       >
                                           {item}
                                       </li>
                                    }
                                )
                        }
                    </ul>

                    <div className="archive-list-container">
                        {
                            calculatedByPage
                                .filter( item => item.acf.data_publikacji.includes(this.props.paginationYear) )
                                .filter(item => item.acf.archive)
                                .map( (item,index) =>
                                    <div
                                        className="row center-xs middle-xs"
                                        key={index}
                                        style={this.state.hoveredIndex === index ? this.state.hoveredBg : {}}
                                        onMouseOver={ () => this.handlePostHover(seasonGradient,index) }
                                    >
                                        <div className="col
                                                    col-xs-12
                                                    col-md-6 " >
                                            <MediaQuery minWidth={992}>
                                                {
                                                    (popupData.url && item.acf.url === popupData.url)
                                                        ?
                                                        <Popup data={ popupData }/>
                                                        :
                                                        <></>
                                                }
                                            </MediaQuery>

                                            <figure
                                                style={{
                                                    backgroundImage:`url(${item.acf.okladka})`
                                                }}
                                                onClick={
                                                    () => this.handlePostSelection (
                                                        item.acf.popup,
                                                        item.acf.url,
                                                        item.acf.tytul,
                                                        item.acf.tekst,
                                                        item.acf.data_publikacji,
                                                        item.acf.miniatura_news,
                                                        seasonGradient,
                                                        bg
                                                    )
                                                }
                                            >
                                                <img src={pageOptions.archiwum_transparent} alt={item.acf.tytul} />
                                            </figure>
                                            <MediaQuery maxWidth={991}>
                                                {
                                                    (popupData.url && item.acf.url === popupData.url)
                                                        ?
                                                        <figcaption>
                                                            <h3>
                                                                <strong>{item.acf.tytul} </strong>

                                                            </h3>
                                                            <span dangerouslySetInnerHTML={{__html: item.acf.tekst}} />
                                                        </figcaption>
                                                        :
                                                        <></>
                                                }
                                            </MediaQuery>
                                        </div>
                                        <MediaQuery minWidth={992}>
                                            <div className="col col-xs-12 col-md-6" >
                                                <figcaption
                                                    style={ this.state.hoveredIndex===index ? seasonTxtColor : {} }
                                                    onClick={
                                                        () => this.handlePostSelection (
                                                            item.acf.popup,
                                                            item.acf.url,
                                                            item.acf.tytul,
                                                            item.acf.tekst,
                                                            item.acf.data_publikacji,
                                                            item.acf.miniatura_news,
                                                            seasonGradient,
                                                            bg
                                                        )
                                                    }
                                                >
                                                    <h3 dangerouslySetInnerHTML={{__html:item.acf.tytul }} />
                                                    { item.acf.podtytul && <h4 dangerouslySetInnerHTML={{__html:item.acf.podtytul }} /> }
                                                    <nav>
                                                    <span >+ {pageOptions.teksty_wiecej}</span>
                                                    </nav>
                                                </figcaption>
                                            </div>
                                        </MediaQuery>
                                    </div>
                                )
                        }
                    </div>

                    {
                        calculatedNavList.length>1 &&
                            <div className="row center-xs">
                                <div className="col col-xs-12">
                                    <ul className="pagination">
                                        <li
                                            className={classnames(
                                                "nav prev",{
                                                    "disabled": this.props.paginationCurrentPage === 1
                                                }
                                            )}
                                            onClick={ () => {
                                                this.props.paginationCurrentPage === 1 ?
                                                    this.props.setNavPage(1) :
                                                    this.props.setNavPage(this.props.paginationCurrentPage - 1)
                                            } }
                                        >&lt;</li>

                                        {
                                            calculatedNavList
                                                .map(
                                                    (item,index) =>
                                                        <li key={index}
                                                            className={classnames({
                                                                "active": (index+1) === this.props.paginationCurrentPage
                                                            })}
                                                            onClick={() => this.props.setNavPage(index + 1)}>
                                                            {index + 1}
                                                        </li> )
                                        }

                                        <li
                                            className={classnames(
                                                "nav next",{
                                                    "disabled": this.props.paginationCurrentPage === calculatedNavList.length
                                                }
                                            )}
                                            onClick={
                                                () => {
                                                    this.props.paginationCurrentPage === calculatedNavList.length ?
                                                        this.props.setNavPage(calculatedNavList.length) :
                                                        this.props.setNavPage(this.props.paginationCurrentPage + 1)
                                                }
                                            }
                                        >&gt;</li>
                                    </ul>
                                </div>

                            </div>
                    }
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions,
    pageDataPosts: state.page.pageDataPosts,
    paginationYear: state.page.paginationYear,
    paginationTotalPosts: state.page.paginationTotalPosts,
    paginationPagesPerPage: state.page.paginationPagesPerPage,
    paginationCurrentPage: state.page.paginationCurrentPage,
    popupData: state.popup.popupData,
    redirect: state.popup.redirect,
    redirectLink: state.popup.redirectLink
});

const mapDispatchToProps = dispatch => ({
    setSelectedPost: (url,cat) => dispatch( setPost( url,cat )),
    setFilterYear: value => dispatch ( setFilterYear(value) ),
    setNavPage: value =>dispatch( setSelectedPage(value) ),
    setPopup: data => dispatch( setPopup(data)),
    setRedirection: value => dispatch( setRedirection(value) )
});


export default connect(mapStateToProps,mapDispatchToProps)(Archiwum);