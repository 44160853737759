import React, { Component } from 'react';
import {connect} from "react-redux";

//actions
import {setPost} from "../../../../actions/pageActions";

//components
import Page from '../../../Page';
import Events from '../../Events';



//config
import { config }  from "../../../../config";

class Wosp2021 extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            pageData,
            pageOptions
        } = this.props;


        return (
            <Page pageIndex={config.pageIndex.eventWosp2021}>
                <Events pageOptions={pageOptions} pageData={pageData}/>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions:state.page.pageOptions,
    playerOpened:state.player.playerOpened
});
const mapDispatchToProps = dispatch => ({
    setSelectedPost: value => dispatch( setPost(value))
});


export default connect(mapStateToProps,mapDispatchToProps)(Wosp2021);