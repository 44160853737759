import React, {Component} from 'react';
import {connect} from "react-redux";
import { Redirect } from 'react-router';

// components
import Page from '../../../Page';
import Back from "../../../Back";

//contexts
import ResponsivenessContext from '../../../../contexts/Responsiveness/ResponsivenessContext';

//svg
//import WhatsApp from "../../../../svg/Whatsapp";
//import Email from "../../../../svg/Email";

//tools

//config
import { config }  from "../../../../config";
import { paths }  from "../../../../config/paths";

class Shop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            redirectionUrl: null
        }
        this.handleMapRedirection = this.handleMapRedirection.bind(this);
    }

    handleMapRedirection = post => {
        this.setState({
            redirectionUrl: post,
            redirect:true
        })
    }

    render() {
        const {
            pageData,
            pageOptions,
            selectedPost,
            match
        } = this.props;

        const seasonGradientTransp = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_opacity}deg, 
                ${pageOptions.kolor_1_opacity} 53%, 
                ${pageOptions.kolor_2_opacity} 85% 
            )`};

        const seasonTextColor = pageOptions.kolor_sezonowy_tekst;

        return (
            <ResponsivenessContext.Consumer>
                {
                    isMobile => (
                        <Page pageIndex={config.pageIndex.sklepy}
                              restName="sklepy"
                              postUrl={ match ? match.params.shopId : selectedPost }
                        >
                            { this.state.redirect && <Redirect to={`${paths.planCentrum}/${this.state.redirectionUrl}`}/> }
                            <Back
                                bg={pageOptions.zielen}
                                link={paths.planCentrum} />
                            <article className="page shop content">
                                { !isMobile &&
                                <div className="row cover-row-container">
                                    <div className={"col col-xs-12"}>
                                        <h1>
                                            <figure>
                                                <img src={pageData.cover} alt={`Cover: ${pageData.nazwa}`}/>
                                                {
                                                    pageData.cover_logo &&
                                                    <figcaption style={seasonGradientTransp}>
                                                        <img src={pageData.cover_logo} alt={`Logo: ${pageData.nazwa}`}/>
                                                    </figcaption>
                                                }
                                            </figure>
                                        </h1>
                                    </div>
                                </div>
                                }

                                <div className="row info-row-container center-xs start-md">

                                    {!isMobile &&
                                    <div className={'col col-xs-12 col-md-3'}>
                                        <figure>
                                            <img src={pageData.obraz_boczny} alt="" aria-hidden={true}/>
                                        </figure>
                                    </div>
                                    }

                                    <div className={'col col-xs-12 col-md-9'}>
                                        <h2>{pageData.nazwa}</h2>
                                        <div className={"text"}
                                             style={{
                                                 color: seasonTextColor,
                                                 background: `linear-gradient( 
                                                    ${pageOptions.rotacja_naglowki}deg, 
                                                    ${pageOptions.kolor_1_naglowki} 46%, 
                                                    ${pageOptions.kolor_2_naglowki} 55% 
                                                  )`,
                                                 marginLeft: isMobile ? 0 : -90
                                             }}>
                                            <p dangerouslySetInnerHTML={{ __html: pageData.opis }} />
                                            <footer>
                                                { pageData.metody_kontaktu &&
                                                    <div className="row bottom-xs">
                                                        <div className={'col col-xs-11 col-xs-offset-1'}>
                                                            <h3>{pageOptions.teksty_dane_kontaktowe}</h3>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    pageData.metody_kontaktu &&
                                                    pageData.metody_kontaktu.map(
                                                        (item,index)=> {
                                                            const phoneNoForLabel = pageData.tel;
                                                            const contactMethod = () => {
                                                                switch(item.typ_pola) {
                                                                    case "contact_www":
                                                                        return <a
                                                                            href={item.url}
                                                                            title={`Link: ${item.etykieta}`}
                                                                            target="_blank" rel="noopener nofollow"
                                                                            style={{color:seasonTextColor}}
                                                                        >
                                                                            <p>{item.etykieta}</p>
                                                                        </a>;
                                                                    case "contact_email":
                                                                        return <a
                                                                            href={`mailto:${item.etykieta}`}
                                                                            title={`Email: ${item.etykieta}`}
                                                                            target="_blank" rel="noopener nofollow"
                                                                            style={{color:seasonTextColor}}
                                                                        >
                                                                            <p>{item.etykieta}</p>
                                                                        </a>;
                                                                    case "contact_tel":
                                                                        return <a
                                                                            href={`tel:${item.etykieta.replace(/[() ]/g,"")}`}
                                                                            title={`Phone: ${item.etykieta}`}
                                                                            target="_blank"
                                                                            rel="noopener nofollow"
                                                                            style={{color:seasonTextColor}}
                                                                        >
                                                                            <p>{phoneNoForLabel}</p>
                                                                        </a>;
                                                                    default:
                                                                        return <p>{item.etykieta}</p>

                                                                }
                                                            };



                                                            return(
                                                                <div className="row middle-xs list-item" key={index}>
                                                                    <div className={'col col-xs-1 '}>
                                                                        <img src={item.ikona} width={21} height={21} alt={`Ikona:${item.etykieta}`}/>
                                                                    </div>
                                                                    <div className={'col col-xs-11'}>
                                                                        {contactMethod()}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    )
                                                }
                                            </footer>
                                            {
                                                pageData.opis_extra &&
                                                <p
                                                    className="text-extra"
                                                    dangerouslySetInnerHTML={{ __html: pageData.opis_extra }} />
                                            }

                                        </div>
                                    </div>
                                    <div className={"map"}
                                         onClick={() => this.handleMapRedirection(pageData.url)}
                                         style={
                                             {
                                                 left: isMobile ? 0 : 16,
                                                 visibility: pageData.mapka ? "visible" : "hidden"
                                             }}>
                                            <img src={pageData.mapka} width={350} height={240} alt={`Mapa: ${pageData.nazwa}`} />
                                    </div>
                                </div>
                            </article>
                        </Page>
                    )
                }

            </ResponsivenessContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions,
    selectedPost: state.page.selectedPost
});


export default connect(mapStateToProps,null)(Shop);