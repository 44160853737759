import React from 'react'
import { Route, Switch } from 'react-router';
import Home from '../components/Views/Home';
import ViewPlanCentrum from '../components/Views/ViewPlanCentrum';
import ViewLokalizacja from '../components/Views/ViewLokalizacja';
import ViewUdogodnienia from '../components/Views/ViewUdogodnienia';
import ViewONas from '../components/Views/ViewONas';
import ViewGodziny from '../components/Views/ViewGodziny';
import ViewCsr from '../components/Views/ViewCsr';
import Rule from '../components/Views/ViewCsr/Rule';
import ViewKontakt from '../components/Views/ViewKontakt';
import ViewPromocje from '../components/Views/ViewPromocje';
import Promocja from '../components/Views/ViewPromocje/Promocja';
import ViewFotoblog from '../components/Views/ViewFotoblog';
import ViewSklepyIUslugi from '../components/Views/ViewSklepyIUslugi';
import Shop from '../components/Views/ViewSklepyIUslugi/Shop';
import News from '../components/Views/Aktualnosci/News';
import Wydarzenia from '../components/Views/Aktualnosci/Wydarzenia';
import Archiwa from '../components/Views/Aktualnosci/Archiwa';
import Konkursy from '../components/Views/Aktualnosci/Konkursy';
import ViewKarta from '../components/Views/ViewKarta';
import ViewLojalnosc from '../components/Views/ViewLojalnosc';
import ViewLojalnoscLogin from '../components/Views/ViewLojalnosc/Login';

//events
import Rajd from '../components/Views/Events/Rajd';
import Rajd2021 from '../components/Views/Events/Rajd2021';
import Rajd2022 from '../components/Views/Events/Rajd2022';
import Wosp2021 from '../components/Views/Events/Wosp2021';
import Walentynki2021 from '../components/Views/Events/Walentynki2021';
import KonkursSwiateczny2020 from '../components/Views/Events/KonkursSwiateczny2020';
import MagicznaGwiazdka2020 from '../components/Views/Events/MagicznaGwiazdka2020';
import JesienneRadosci from '../components/Views/Events/JesienneRadosci';
import PowrotDoSzkoly from '../components/Views/Events/PowrotDoSzkoly';
import PowrotDoSzkolyKonkurs from '../components/Views/Events/PowrotDoSzkolyKonkurs';
import DzienKobiet2021 from "../components/Views/Events/DzienKobiet2021";
import KonkursWielkanoc2021 from "../components/Views/Events/KonkursWielkanoc2021";
import KonkursPrzedszkolaki2021 from "../components/Views/Events/KonkursPrzedszkolaki2021";
import KonkursDzienMatki2021 from "../components/Views/Events/KonkursDzienMatki2021";
import DziesiecLatRazem from "../components/Views/Events/DziesiecLatRazem";
import BialaSobota from "../components/Views/Events/BialaSobota";
import Mikolajki2021 from "../components/Views/Events/Mikolajki2021";
import Swieta2021 from "../components/Views/Events/Swieta2021";
import DzienBabciIDziadka2021 from "../components/Views/Events/DzienBabciIDziadka2021";

import { paths } from '../config/paths';

const routes = (
    <Switch>
        <Route exact path={paths.lojalnosclogin} component={ViewLojalnoscLogin} />
        <Route exact path={paths.lojalnosc} component={ViewLojalnosc} />
        <Route exact path={paths.karta} component={ViewKarta} />
        <Route exact path={paths.home} component={Home} />
        <Route exact path={paths.oNas} component={ViewONas} />
        <Route exact path={paths.godziny} component={ViewGodziny} />
        <Route exact path={paths.lokalizacja} component={ViewLokalizacja} />
        <Route exact path={paths.udogodnienia} component={ViewUdogodnienia} />
        <Route exact path={paths.planCentrum} component={ViewPlanCentrum} />
        <Route exact path={paths.sklepy} component={ViewSklepyIUslugi} />
        <Route exact path={paths.promocje} component={ViewPromocje} />
        <Route exact path={paths.fotoblog} component={ViewFotoblog} />
        <Route exact path={paths.wydarzenia} component={Wydarzenia} />
        <Route exact path={paths.archiwa} component={Archiwa} />
        <Route exact path={paths.konkursy} component={Konkursy} />
        <Route exact path={paths.csr} component={ViewCsr} />
        <Route exact path={paths.kontakt} component={ViewKontakt} />
        <Route path={paths.sklep} component={Shop} />
        <Route path={paths.news} component={News} />
        <Route path={paths.archiwum} component={News} />
        <Route path={paths.promocja} component={Promocja} />
        <Route path={paths.csrZasady} component={Rule} />
        <Route path={paths.planCentrumSklep} component={ViewPlanCentrum} />
        {/*event pages*/}
        <Route exact path={paths.eventRajd} component={Rajd} />
        <Route exact path={paths.eventRajd2021} component={Rajd2021} />
        <Route exact path={paths.eventRajd2022} component={Rajd2022} />
        <Route exact path={paths.eventBts} component={PowrotDoSzkoly} />
        <Route exact path={paths.eventBtsKonkurs} component={PowrotDoSzkolyKonkurs} />
        <Route exact path={paths.eventJesienneRadosci} component={JesienneRadosci} />
        <Route exact path={paths.eventMagicznaGwiazdka2020} component={MagicznaGwiazdka2020} />
        <Route exact path={paths.eventKonkursSwiateczny2020} component={KonkursSwiateczny2020} />
        <Route exact path={paths.eventWosp2021} component={Wosp2021} />
        <Route exact path={paths.eventWalentynki2021} component={Walentynki2021} />
        <Route exact path={paths.eventDzienKobiet2021} component={DzienKobiet2021} />
        <Route exact path={paths.eventWielkanoc2021} component={KonkursWielkanoc2021} />
        <Route exact path={paths.eventPrzedszkolaki2021} component={KonkursPrzedszkolaki2021} />
        <Route exact path={paths.eventDzienMatki2021} component={KonkursDzienMatki2021} />
        <Route exact path={paths.event10LatRazem} component={DziesiecLatRazem} />
        <Route exact path={paths.eventBialaSobota} component={BialaSobota} />
        <Route exact path={paths.eventMikolajki2021} component={Mikolajki2021} />
        <Route exact path={paths.eventSwieta2021} component={Swieta2021} />
        <Route exact path={paths.evenDzienBabci2022} component={DzienBabciIDziadka2021} />
    </Switch>
);

export default routes;