import React,{Component} from 'react';

//tools
import Lightbox from "react-lightbox-component";

class Gallery extends Component  {

    constructor(props) {
        super(props);
        this.state = {
            amount: 999,
            expanded: false
        };
        this.handleOpenGallery = this.handleOpenGallery.bind(this);
        this.handleCloseGallery = this.handleCloseGallery.bind(this);
    }

    handleOpenGallery = () => {
       this.setState({
           amount:999,
           expanded: true
       })
    };
    handleCloseGallery = val => {
        this.setState({
            amount:val,
            expanded: false
        })
    };
    componentDidMount = () => {
        const { initialAmount } = this.props;

        if (initialAmount) {
            this.setState({
                amount: initialAmount
            })
        }

    };


    render(){

        const {
            pageData,
            pageOptions,
            classNames,
            initialAmount
        } = this.props;

        return (
            <div className="row gallery-row-container center-xs">
                <div className={classNames ? classNames : "col col-xs-12"}>
                    {pageData.galeria_zdjec &&
                    <Lightbox
                        className="row"
                        images={
                            pageData.galeria_zdjec
                                ?
                                pageData.galeria_zdjec
                                    .slice(0, this.state.amount)
                                    .map(
                                        item => {
                                            return {
                                                src: item.url,
                                                title: item.title,
                                                description: item.description
                                            }
                                        }
                                    )
                                :
                                []
                        }
                        showImageModifiers={false}
                        thumbnailWidth='400px'
                        thumbnailHeight='270px'
                        renderImageFunc={(idx, image, toggleLightbox, width, height) => {
                            return (
                                <div
                                    key={idx}
                                    className={"col col-xs-12 col-sm-6 col-md-4 fota-wrapper"}
                                >
                                    <div
                                        className="fota"
                                        style={{
                                            backgroundImage: `url(${image.src})`
                                        }}
                                    >
                                        <img
                                            src={pageOptions.zaslepka_miniatury}
                                            alt={image.title}
                                            style={{width: width, height: height}}
                                            onClick={toggleLightbox.bind(null, idx)}
                                        />
                                    </div>
                                    <div>
                                        <h1>{image.title}</h1>
                                        <p>{image.description}</p>
                                    </div>
                                </div>
                            )
                        }}
                    />
                    }

                    {
                        (initialAmount && (this.state.amount === initialAmount)) &&
                        <button className="expand collpased" onClick={() => this.handleOpenGallery()}>
                            więcej
                        </button>
                    }
                    {
                        ( initialAmount && ( this.state.amount > initialAmount ) ) &&
                        <button className="expand expanded" onClick={() => this.handleCloseGallery(initialAmount)}>
                            mniej
                        </button>

                    }

                </div>

            </div>
        );
    }



};

export default Gallery;