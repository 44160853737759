import React from 'react';
import TableHeader from "./TableHeader";
import TableContent from "./TableContent";
import TableFooter from "./TableFooter";

const Table = (
    {
        data,
        hours,
        legenda,
        pageOptions
    }) => {
    return (
        <div className="col col-xs-12 col-md-6">
            <TableHeader
                linia={data.nr_linii}
                kolor={data.kolor}
                kierunekLabel={pageOptions._teksty_kierunek}
                kierunek={data.kierunek}
                przystanekLabel={pageOptions.teksty_przystanek}
                przystanek={data.przystanek} />
            <TableContent
                kolor={data.kolor}
                hours={hours}
                pageOptions={pageOptions}
            />
            <TableFooter
                legenda={legenda}
            />

        </div>
    );
};

export default Table;