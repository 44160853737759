import React from 'react';

const Pause = ({mainColor}) => {
    return (
        <svg
            version="1.1"
            id="player_pause"
            xmlns="http://www.w3.org/2000/svg"
            width="21.718px"
            height="36px"
            viewBox="0 -23.837 21.718 36">

            <rect fill={mainColor} x="12.705" y="-23.837" width="9.014" height="36"/>
            <rect fill={mainColor} x="0" y="-23.837" width="9.014" height="36"/>
        </svg>
    );
};
export default Pause;