import React from 'react';
import { InView } from 'react-intersection-observer';

//tools
import classnames from "classnames";

const Harmonogram = (
    {
        tytul,
        animacja,
        wykres
     }) => {
    return (
        <>
            {
                tytul &&
                <InView>
                    {({ inView, ref }) => (
                        <div className="section-harmonogram" ref={ref}>
                            <h3 dangerouslySetInnerHTML={{__html: tytul}}/>
                            {
                                animacja &&
                                <div className="row bike center-xs">
                                    <div className="col col-xs-12 col-md-10">
                                        <figure className={classnames({
                                            "long-ride": inView
                                        })}>
                                            <img
                                                src={animacja}
                                                alt=""
                                                className={classnames({
                                                    "ride-in": inView
                                                })}
                                            />
                                        </figure>

                                    </div>
                                </div>
                            }

                            <div className="row wykres center-xs">
                                <div className="col col-xs-12 col-md-10">
                                    <img
                                        src={wykres}
                                        alt={`Pic: ${tytul}`}
                                    />
                                </div>
                            </div>
                        </div>
                        )}
                </InView>
            }

        </>
    );
};

export default Harmonogram;