import React from 'react';

const RescueIcon= ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_search" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="31.16px" height="31.45px"
             viewBox="0 0 31.16 31.45">
             <g>
                 <path fill={mainColor} d="M14.31,12.76c0.7-1.19,1.6-2.07,2.77-2.65c3.26-1.62,7.15-0.06,8.57,3.38c0.94,2.28,0.61,4.49-0.39,6.66
				c-1.03,2.22-2.51,4.14-4.31,5.76c-1.96,1.77-4.09,3.35-6.14,5.03c-0.35,0.29-0.64,0.3-1.03,0.02c-3.1-2.17-6.02-4.53-8.38-7.51
				c-1.46-1.85-2.64-3.86-2.95-6.25c-0.55-4.23,2.71-8.1,6.91-7.71c1.82,0.17,3.25,1.05,4.38,2.46
				C13.92,12.18,14.08,12.43,14.31,12.76z M12.58,20.48c0.05-0.37,0.05-0.57,0.09-0.75c0.37-1.66,0.75-3.32,1.11-4.99
				c0.08-0.36,0.28-0.79,0.16-1.08c-0.44-1.11-1.23-1.95-2.34-2.62c-0.44,4.38-0.87,8.65-1.32,13.07c1.55-1.1,2.96-2.12,4.38-3.13
				c0.04,0.04,0.09,0.08,0.13,0.11c-0.27,1.93-0.54,3.85-0.81,5.84c-0.47-0.14-0.84-0.25-1.31-0.39c0.55,1.29,1.05,2.47,1.58,3.72
				c0.92-1.03,1.75-1.95,2.63-2.94c-0.47-0.06-0.85-0.1-1.28-0.15c0.78-3.52,1.55-6.97,2.34-10.55
				C16.15,17.92,14.45,19.15,12.58,20.48z"/>
                 <path fill={mainColor} d="M25.58,7.92c-0.81,0-1.54-0.02-2.25,0.01c-0.39,0.01-0.53-0.15-0.52-0.51c0.01-0.59,0.01-1.18,0-1.76
				c-0.01-0.37,0.13-0.52,0.52-0.51c0.72,0.02,1.44,0.01,2.25,0.01c0-0.76,0.02-1.48-0.01-2.19c-0.02-0.43,0.13-0.6,0.57-0.59
				c0.57,0.02,1.14,0.02,1.71,0c0.42-0.02,0.54,0.17,0.53,0.57c-0.02,0.57,0.02,1.14-0.01,1.71c-0.02,0.43,0.15,0.54,0.55,0.53
				c0.57-0.03,1.14,0.01,1.71-0.02c0.4-0.02,0.57,0.11,0.56,0.53c-0.02,0.57-0.02,1.14,0,1.71c0.01,0.4-0.13,0.57-0.54,0.55
				c-0.59-0.02-1.18,0.02-1.76-0.01c-0.4-0.02-0.51,0.14-0.49,0.51c0.02,0.61-0.01,1.22,0.01,1.82c0.01,0.34-0.13,0.46-0.45,0.45
				c-0.61-0.01-1.21-0.01-1.82,0c-0.36,0.01-0.53-0.12-0.52-0.52C25.6,9.47,25.58,8.74,25.58,7.92z"/>
            </g>
        </svg>
    );
};

export default RescueIcon;