import React from 'react';

const Book = ({mainColor}) => {
    return (
        <svg version="1.1"
             id="i_book"
             xmlns="http://www.w3.org/2000/svg"
             width="143px"
             height="129px"
             viewBox="0 0 143 129" >
            <g>
                <path display="none" fill={mainColor} d="M131.889,45.975C115.785,29.869,94.336,21,71.5,21c-22.837,0-44.282,8.867-60.389,24.975
		c-0.869,0.869-0.869,2.274,0,3.144c0.869,0.869,2.274,0.869,3.145,0C29.521,33.851,49.851,25.444,71.5,25.444
		c21.648,0,41.979,8.407,57.245,23.673c0.434,0.433,1.002,0.651,1.571,0.651s1.139-0.218,1.572-0.651
		C132.758,48.249,132.758,46.842,131.889,45.975"/>
                <path display="none" fill={mainColor} d="M24.18,59.042c-0.869,0.87-0.869,2.274,0,3.144c0.435,0.434,1.003,0.651,1.572,0.651
		s1.138-0.217,1.572-0.651c24.356-24.359,63.99-24.359,88.35,0c0.869,0.869,2.274,0.869,3.144,0c0.869-0.87,0.869-2.274,0-3.144
		C92.729,32.953,50.273,32.949,24.18,59.042"/>
                <path display="none" fill={mainColor} d="M71.5,57.948c-12.951,0-25.113,5.031-34.249,14.166c-0.869,0.869-0.869,2.274,0,3.144
		c0.87,0.869,2.274,0.869,3.144,0c8.295-8.293,19.341-12.862,31.105-12.862c11.764,0,22.81,4.569,31.104,12.862
		c0.435,0.434,1.004,0.651,1.573,0.651s1.139-0.218,1.572-0.651c0.869-0.869,0.869-2.274,0-3.144
		C96.615,62.979,84.451,57.948,71.5,57.948"/>
                <path display="none" fill={mainColor} d="M71.5,78.865c-8.031,0-14.565,6.534-14.565,14.567S63.469,108,71.5,108
		c8.033,0,14.567-6.534,14.567-14.567S79.533,78.865,71.5,78.865 M71.5,103.551c-5.581,0-10.119-4.54-10.119-10.12
		c0-5.581,4.54-10.121,10.119-10.121c5.578,0,10.121,4.54,10.121,10.121C81.621,99.011,77.081,103.551,71.5,103.551"/>
                <path display="none" fill={mainColor} d="M71.5,111.923c-12.667,0-24.576-4.933-33.533-13.89S24.077,77.167,24.077,64.5
		s4.933-24.576,13.89-33.533s20.866-13.89,33.533-13.89c11.506,0,22.604,4.173,31.246,11.749c0.577,0.506,0.635,1.383,0.129,1.96
		c-0.506,0.577-1.383,0.635-1.96,0.129C92.778,23.783,82.333,19.856,71.5,19.856c-11.925,0-23.137,4.643-31.568,13.076
		C31.499,41.364,26.854,52.575,26.854,64.5c0,11.925,4.645,23.137,13.077,31.569c8.432,8.432,19.644,13.075,31.568,13.075
		s23.137-4.644,31.568-13.075c8.433-8.433,13.076-19.645,13.076-31.569c0-10.926-3.988-21.441-11.229-29.607
		c-0.51-0.574-0.457-1.452,0.118-1.961c0.573-0.509,1.452-0.456,1.961,0.118c7.693,8.675,11.928,19.844,11.928,31.45
		c0,12.667-4.933,24.576-13.89,33.533S84.167,111.923,71.5,111.923"/>
                <path display="none" fill={mainColor} d="M71.5,96.488c-4.517,0-8.189-3.674-8.189-8.189c0,0-0.313-2.863,1.39-2.863
		c1.701,0,1.389,2.863,1.389,2.863c0,2.983,2.428,5.411,5.411,5.411s5.41-2.428,5.41-5.411V74.632c0-2.604,2.118-4.722,4.722-4.722
		h13.667c2.983,0,5.411-2.427,5.411-5.41c0-2.984-2.428-5.411-5.411-5.411H81.632c-2.604,0-4.722-2.118-4.722-4.722V40.7
		c0-2.983-2.427-5.41-5.41-5.41s-5.411,2.427-5.411,5.41v13.667c0,2.604-2.117,4.722-4.721,4.722H47.701
		c-2.983,0-5.41,2.427-5.41,5.411c0,2.982,2.427,5.41,5.41,5.41h13.667c2.604,0,4.721,2.118,4.721,4.722v6.583
		c0,0.767-0.621,1.39-1.389,1.39s-1.39-0.623-1.39-1.39v-6.583c0-1.071-0.872-1.942-1.942-1.942H47.701
		c-4.516,0-8.189-3.674-8.189-8.189c0-4.516,3.674-8.189,8.189-8.189h13.667c1.07,0,1.942-0.872,1.942-1.944V40.7
		c0-4.515,3.673-8.189,8.189-8.189c4.516,0,8.189,3.674,8.189,8.189v13.667c0,1.072,0.871,1.944,1.942,1.944h13.667
		c4.516,0,8.189,3.673,8.189,8.189c0,4.515-3.674,8.189-8.189,8.189H81.632c-1.071,0-1.942,0.872-1.942,1.942v13.667
		C79.689,92.814,76.016,96.488,71.5,96.488"/>
                <path display="none" fill={mainColor} d="M80.134,110.289c-1.051-0.421-2.245,0.09-2.666,1.141c-0.353,0.88-1.245,1.469-2.222,1.469
		h-7.492c-0.977,0-1.869-0.589-2.223-1.469c-0.421-1.051-1.614-1.561-2.666-1.141c-1.051,0.422-1.562,1.615-1.14,2.667
		c0.985,2.457,3.351,4.044,6.028,4.044h7.492c2.678,0,5.044-1.587,6.028-4.044C81.695,111.904,81.186,110.711,80.134,110.289"/>
                <path display="none" fill={mainColor} d="M104.219,99.725c-0.558-2.872-2.327-5.358-4.856-6.826c0.46-0.376,0.892-0.792,1.285-1.249
		c1.942-2.257,2.803-5.239,2.359-8.184c-0.72-4.783-2.755-9.252-5.887-12.925c-2.345-2.749-5.228-4.965-8.452-6.521
		c3.947-4.211,6.368-9.869,6.368-16.083c0-10.753-7.249-19.845-17.116-22.646c1.344-1.605,2.077-3.61,2.077-5.728
		c0-4.17-3.393-7.562-7.562-7.562h-0.001c-1.684,0-3.26,0.547-4.438,1.541c-1.303,1.098-2.021,2.641-2.021,4.345
		c0,1.132,0.918,2.051,2.051,2.051c1.132,0,2.051-0.919,2.051-2.051c0-0.488,0.189-0.895,0.563-1.209
		c0.439-0.372,1.078-0.576,1.796-0.576c1.907,0,3.46,1.552,3.46,3.46c0,1.293-0.503,2.508-1.417,3.421
		c-0.914,0.914-2.128,1.417-3.42,1.417h-0.001c-0.05,0-0.1,0.004-0.148,0.008c-12.706,0.315-22.946,10.748-22.946,23.529
		c0,6.214,2.423,11.871,6.369,16.083c-3.225,1.557-6.106,3.771-8.452,6.521c-3.132,3.672-5.168,8.142-5.888,12.925
		c-0.443,2.944,0.417,5.927,2.36,8.184c0.393,0.457,0.825,0.873,1.285,1.248c-2.53,1.469-4.3,3.955-4.856,6.827
		c-0.558,2.879,0.158,5.855,1.966,8.165l4.392,5.612c1.774,2.268,4.47,3.486,7.203,3.486c1.553,0,3.117-0.393,4.532-1.21
		c3.909-2.257,5.584-7.116,3.897-11.302l-2.665-6.609c-0.178-0.441-0.389-0.865-0.623-1.271h15.042c1.133,0,2.051-0.919,2.051-2.051
		c0-1.133-0.918-2.051-2.051-2.051H53.584v-4.323c0-0.558,0.454-1.01,1.011-1.01h33.811c0.557,0,1.01,0.452,1.01,1.01v4.606
		c-2.035,1.115-3.642,2.904-4.522,5.09l-2.664,6.609c-1.688,4.186-0.013,9.045,3.897,11.302c1.415,0.817,2.979,1.21,4.531,1.21
		c2.733,0,5.43-1.22,7.204-3.486l4.392-5.612C104.061,105.58,104.776,102.604,104.219,99.725 M52.064,47.937
		c0-10.717,8.719-19.435,19.437-19.435c10.715,0,19.434,8.718,19.434,19.435c0,10.717-8.718,19.435-19.434,19.435
		C60.783,67.371,52.064,58.653,52.064,47.937 M54.304,99.4l2.663,6.61c0.929,2.302,0.008,4.975-2.143,6.216
		s-4.926,0.703-6.455-1.252l-4.392-5.612c-1.076-1.374-1.501-3.144-1.169-4.856s1.389-3.196,2.899-4.068
		c1.512-0.872,3.324-1.046,4.974-0.478S53.65,97.783,54.304,99.4 M88.419,78.958c-1.133,0-2.051,0.918-2.051,2.05v2.052H56.646
		v-2.052c0-1.132-0.918-2.05-2.051-2.05c-1.134,0-2.052,0.918-2.052,2.05v2.482c-1.8,0.792-3.06,2.592-3.06,4.681v2.896
		c-1.553-0.155-2.994-0.897-4.023-2.093c-1.162-1.351-1.677-3.136-1.412-4.897c1.175-7.804,6.377-14.252,13.683-17.063
		c3.875,2.804,8.632,4.46,13.771,4.46c5.137,0,9.894-1.656,13.769-4.46c7.305,2.812,12.508,9.26,13.682,17.063
		c0.265,1.762-0.249,3.547-1.412,4.897c-1.028,1.195-2.47,1.938-4.022,2.093v-2.896c0-2.084-1.254-3.88-3.047-4.674v-2.489
		C90.47,79.876,89.552,78.958,88.419,78.958 M99.022,105.362l-4.392,5.612c-1.53,1.954-4.305,2.493-6.455,1.252
		c-2.149-1.242-3.071-3.914-2.143-6.217l2.664-6.609c0.652-1.618,1.973-2.872,3.622-3.441c0.64-0.22,1.302-0.328,1.962-0.328
		c1.046,0,2.085,0.272,3.012,0.807c1.51,0.872,2.566,2.355,2.899,4.067C100.524,102.218,100.098,103.988,99.022,105.362"/>
                <path display="none" fill={mainColor} d="M82.121,93.094c-0.382-0.381-0.911-0.601-1.45-0.601s-1.068,0.219-1.45,0.601
		c-0.381,0.382-0.601,0.911-0.601,1.45c0,0.541,0.22,1.068,0.601,1.449c0.382,0.384,0.911,0.602,1.45,0.602s1.068-0.218,1.45-0.602
		c0.381-0.381,0.601-0.91,0.601-1.449S82.502,93.476,82.121,93.094"/>
                <path display="none" fill={mainColor} d="M77.154,54.939c-0.88-0.713-2.172-0.578-2.885,0.302c-0.682,0.841-1.691,1.323-2.77,1.323
		c-1.079,0-2.089-0.482-2.77-1.323c-0.714-0.88-2.005-1.014-2.885-0.302c-0.88,0.713-1.015,2.005-0.302,2.885
		c1.464,1.806,3.634,2.842,5.956,2.842c2.321,0,4.492-1.037,5.956-2.842C78.169,56.944,78.034,55.652,77.154,54.939"/>
                <path display="none" fill={mainColor} d="M66.317,46.486c-1.859-1.86-4.887-1.86-6.748,0c-0.801,0.801-0.801,2.099,0,2.9
		c0.802,0.801,2.101,0.801,2.9,0c0.262-0.26,0.686-0.26,0.948,0c0.399,0.401,0.925,0.601,1.449,0.601c0.525,0,1.051-0.2,1.45-0.601
		C67.119,48.586,67.119,47.287,66.317,46.486"/>
                <path display="none" fill={mainColor} d="M83.43,46.486c-1.86-1.86-4.888-1.86-6.748,0c-0.801,0.801-0.801,2.099,0,2.9
		c0.4,0.401,0.926,0.601,1.45,0.601c0.525,0,1.05-0.2,1.45-0.601c0.262-0.26,0.687-0.261,0.948,0c0.801,0.801,2.099,0.801,2.899,0
		C84.23,48.585,84.23,47.287,83.43,46.486"/>
                <path fill={mainColor} d="M123.993,31.71c-0.999,0-1.81,0.81-1.81,1.81v65.164h-9.829c-11.648,0.001-23.26,1.324-34.609,3.942
		c-1.458,0.333-2.97-0.267-3.802-1.51c6.463-3.956,13.893-6.051,21.47-6.053h21.34c1,0,1.811-0.812,1.811-1.812V80.584
		c0-1-0.811-1.811-1.811-1.811s-1.81,0.811-1.81,1.811v10.86h-19.53c-7.746-0.008-15.362,1.996-22.103,5.814V48.001
		c0-1-0.812-1.81-1.811-1.81c-1,0-1.81,0.81-1.81,1.81v49.255c-6.741-3.818-14.357-5.821-22.104-5.813H28.057V26.279h27.876
		c7.6,0.005,13.758,6.167,13.758,13.766c0,1,0.81,1.811,1.81,1.811c0.999,0,1.811-0.811,1.811-1.811
		c0-7.599,6.157-13.761,13.757-13.766h27.876v47.063c0,1,0.81,1.811,1.81,1.811s1.811-0.811,1.811-1.811V24.469
		c0-0.999-0.811-1.81-1.811-1.81H87.067c-6.6,0.005-12.629,3.743-15.567,9.652c-2.939-5.909-8.968-9.647-15.567-9.652H26.247
		c-1,0-1.811,0.811-1.811,1.81v68.784c0,1,0.811,1.812,1.811,1.812h21.339c7.579,0.002,15.01,2.097,21.474,6.053
		c-0.835,1.239-2.344,1.839-3.801,1.51c-11.352-2.619-22.964-3.941-34.614-3.942h-9.828V33.521c0-1-0.811-1.81-1.811-1.81
		c-0.999,0-1.81,0.81-1.81,1.81v66.975c0,0.999,0.811,1.81,1.81,1.81h11.639c11.375,0.002,22.713,1.294,33.796,3.851
		c2.597,0.6,5.312-0.283,7.06-2.296c1.747,2.013,4.463,2.896,7.06,2.296c11.083-2.557,22.421-3.848,33.795-3.851h11.639
		c1,0,1.812-0.811,1.812-1.81V33.521C125.805,32.521,124.993,31.71,123.993,31.71"/>
                <path fill={mainColor} d="M65.568,38.25c-2.854-1.905-6.206-2.92-9.635-2.919H35.297c-1,0-1.81,0.81-1.81,1.81
		c0,0.999,0.81,1.81,1.81,1.81h20.637c2.718-0.003,5.375,0.801,7.635,2.311c0.832,0.555,1.956,0.331,2.51-0.501
		c0.555-0.832,0.331-1.956-0.501-2.511H65.568z"/>
                <path fill={mainColor} d="M107.702,35.331H87.067c-3.434-0.003-6.79,1.013-9.645,2.919c-0.832,0.556-1.057,1.68-0.502,2.511
		c0.556,0.832,1.679,1.056,2.51,0.502c2.262-1.51,4.918-2.314,7.637-2.312h20.635c1,0,1.811-0.81,1.811-1.81
		C109.513,36.142,108.702,35.331,107.702,35.331"/>
                <path fill={mainColor} d="M65.568,47.301c-2.854-1.905-6.206-2.921-9.635-2.919H35.297c-1,0-1.81,0.81-1.81,1.81
		c0,0.999,0.81,1.81,1.81,1.81h20.637c2.718-0.004,5.375,0.801,7.635,2.311c0.832,0.555,1.956,0.33,2.51-0.501
		c0.555-0.832,0.331-1.956-0.501-2.511H65.568z"/>
                <path fill={mainColor} d="M107.702,44.381H87.067c-3.434-0.003-6.79,1.013-9.645,2.919c-0.832,0.555-1.057,1.679-0.502,2.511
		c0.556,0.832,1.679,1.056,2.51,0.502c2.262-1.51,4.918-2.314,7.637-2.312h20.635c1,0,1.811-0.81,1.811-1.81
		S108.702,44.381,107.702,44.381"/>
                <path fill={mainColor} d="M65.568,56.352c-2.854-1.905-6.206-2.921-9.635-2.92H35.297c-1,0-1.81,0.811-1.81,1.81
		c0,1,0.81,1.81,1.81,1.81h20.637c2.718-0.003,5.375,0.802,7.635,2.312c0.832,0.555,1.956,0.33,2.51-0.501
		c0.555-0.832,0.331-1.956-0.501-2.511H65.568z"/>
                <path fill={mainColor} d="M107.702,53.432H87.067c-3.434-0.002-6.79,1.014-9.645,2.92c-0.832,0.555-1.057,1.679-0.502,2.511
		c0.556,0.831,1.679,1.056,2.51,0.501c2.262-1.51,4.918-2.314,7.637-2.312h20.635c1,0,1.811-0.81,1.811-1.81
		C109.513,54.243,108.702,53.432,107.702,53.432"/>
                <path fill={mainColor} d="M65.568,65.402c-2.854-1.905-6.206-2.921-9.635-2.92H35.297c-1,0-1.81,0.811-1.81,1.81
		c0,1,0.81,1.81,1.81,1.81h20.637c2.718-0.003,5.375,0.802,7.635,2.312c0.832,0.555,1.956,0.33,2.51-0.501
		c0.555-0.832,0.331-1.956-0.501-2.511H65.568z"/>
                <path fill={mainColor} d="M107.702,62.482H87.067c-3.434-0.003-6.79,1.013-9.645,2.92c-0.832,0.555-1.057,1.679-0.502,2.511
		c0.556,0.831,1.679,1.056,2.51,0.501c2.262-1.51,4.918-2.314,7.637-2.312h20.635c1,0,1.811-0.811,1.811-1.81
		C109.513,63.293,108.702,62.482,107.702,62.482"/>
                <path fill={mainColor} d="M65.568,74.452c-2.854-1.904-6.206-2.92-9.635-2.919H35.297c-1,0-1.81,0.811-1.81,1.81
		c0,1,0.81,1.811,1.81,1.811h20.637c2.718-0.003,5.375,0.802,7.635,2.312c0.832,0.555,1.956,0.33,2.51-0.502
		c0.555-0.831,0.331-1.955-0.501-2.511H65.568z"/>
                <path fill={mainColor} d="M107.702,71.533H87.067c-3.434-0.003-6.79,1.013-9.645,2.919c-0.832,0.556-1.057,1.68-0.502,2.511
		c0.556,0.832,1.679,1.057,2.51,0.502c2.262-1.51,4.918-2.314,7.637-2.312h20.635c1,0,1.811-0.811,1.811-1.811
		C109.513,72.344,108.702,71.533,107.702,71.533"/>
                <path fill={mainColor} d="M55.933,80.584H35.297c-0.999,0-1.81,0.81-1.81,1.81s0.811,1.811,1.81,1.811h20.636
		c2.718-0.004,5.375,0.801,7.635,2.312c0.832,0.555,1.956,0.33,2.512-0.502c0.554-0.832,0.33-1.956-0.502-2.511
		C62.723,81.597,59.366,80.581,55.933,80.584"/>
                <path fill={mainColor} d="M107.702,80.584H87.067c-3.434-0.003-6.79,1.013-9.645,2.919c-0.832,0.555-1.057,1.679-0.502,2.511
		c0.556,0.832,1.679,1.057,2.51,0.502c2.262-1.51,4.918-2.314,7.637-2.312h20.635c1,0,1.811-0.811,1.811-1.811
		S108.702,80.584,107.702,80.584"/>
            </g>
        </svg>
    );
};

export default Book;