export const config = {
    // domain: 'http://localhost/turawa/',
    // domainRelPath: '/turawa',
    // domainRelPathExt: '/turawa/',

    

    domain: 'https://turawa-park.com/',
    domainRelPath: '',
    domainRelPathExt: '/',

    //domain: 'http://localhost:8000/',
    // domainRelPath: '',
    // domainRelPathExt: '/',

    pageIndex: {
        lojalnosclogin: 0,
        lojalnosc: 1,
        karta: 2,
        eventRajd2022: 3,
        evenDzienBabci2022: 4,
        eventSwieta2021: 5,
        eventMikolajki2021: 6,
        eventBialaSobota: 7,
        event10LatRazem: 8,
        eventRajd2021: 9,
        eventDzienMatki2021: 10,
        eventPrzedszkolaki2021: 11,
        eventWielkanoc2021: 12,
        eventDzienKobiet2021: 13,
        eventWalentynki2021: 14,
        eventWosp2021: 15,
        eventKonkursSwiateczny2020: 16,
        eventMagicznaGwiazdka2020: 17,
        eventJesienneRadosci: 18,
        eventBtsKonkurs: 19,
        eventBts: 20,
        events: 21,
        eventRajd: 22,
        csr: 23,
        fotoblog: 24,
        aktualnosci: { archiwum: 25, konkursy: 26, wydarzenia: 27 },
        aktualnosciParent: 28,
        promocje: 29,
        uslugi: 30,
        sklepy: 31,
        planCentrum: 32,
        udogodnienia: 33,
        lokalizacja: 34,
        godziny: 35,
        oNas: 36,
        turawaParent: 37,
        kontakt: 38,
        home: 39,
    }
};