import React, {Component} from 'react';

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      telefon_code: 48,
      telefon_number: '',
      city: '',
      postcode: '',
      country: '',
      birthday: '',
      sex: '',
      marketing: false,
      privacy: '',
      userInfo: null,
    };

    this.handleMarketing = this.handleMarketing.bind(this);
    this.handlePrivacy = this.handlePrivacy.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleMarketing() {
    this.setState(prevState => ({
      marketing: !prevState.marketing,
    }));
  }

  handlePrivacy() {
    this.setState(prevState => ({
      privacy: !prevState.privacy,
    }));
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("x-api-version", "2.0");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ 
      "fields": {
        "first_name": this.state.firstname,
        "last_name": this.state.lastname,
        "email": this.state.email,
        "phone": 
          {
            "country_code": this.state.telefon_code,
            "number": this.state.telefon_number
          },
        "city": this.state.city,
        "postcode": this.state.postcode,
        "country": this.state.country,
        "date_of_birth": this.state.birthday,
        "gender": this.state.sex,
        "marketing_agreement": this.state.marketing,
        "privacy_agreement": this.state.privacy
        }
    });

    //console.log(raw);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };  
    
    try {
      const response = await fetch('https://poweredby.coniq.com/signup/0eff3sw.json', requestOptions);
      const result = await response.json();
      this.setState({ userInfo: result });
      if (result.message == 'Dzi\u0119kuj\u0119') {
        const tokenUrl = "https://poweredby.coniq.com/oauth/v2/token";
        const userUrl = "https://api.coniq.com/app/customer/credentials/set-password";
        const clientId = "129_2k6ix5hqg884880sk480swgooc880okoksws4kkskokc0ck8so";
        const clientSecret = "6an5es08l1oo80co80080ok0w0cs0s8ckso84c4gcoc8k0c4c0";

        const tokenHeaders = new Headers();
        tokenHeaders.append("x-api-version", "2.0");
        tokenHeaders.append("Content-Type", "application/json");
    
        const tokenData = {
            "client_id": clientId,
            "client_secret": clientSecret,
            "grant_type": "client_credentials"
        };
    
        const tokenOptions = {
            method: 'POST',
            headers: tokenHeaders,
            body: JSON.stringify(tokenData),
            redirect: 'follow'
        };

        try {
          // GET TOKEN
          const tokenResponse = await fetch(tokenUrl, tokenOptions);
          const tokenResult = await tokenResponse.json();
          //console.log(tokenResult);

          // SET PASSWORD
          const userHeaders = new Headers();
          userHeaders.append("x-api-version", "3.0");
          userHeaders.append("Authorization", `Bearer ${tokenResult.access_token}`);
          userHeaders.append("Content-Type", "application/json");
          //console.log("Authorization", `Bearer ${tokenResult.access_token}`);
          
          var data = `{\n\t\"username\" : \"${this.state.email}\",\n    \"redirect_url\" : \"https://turawa-park.com/klub_turawa_login\"\n}`;

          var userOptions = {
            method: 'POST',
            headers: userHeaders,
            body: data,
            redirect: 'follow'
          };

          const userResponse = await fetch(userUrl, userOptions);
          const userResult = await userResponse.json();
          //console.log(userResponse);
          //console.log(userResult);
        } catch (error) {
            console.error(error);
        }
      }
      //console.log(result);
    } catch (error) {
      console.log('error', error);
    }
  };


  render() {
    const { userInfo } = this.state;

    if (userInfo && userInfo.message === 'Dzi\u0119kuj\u0119') {
      return (
        <div>
          <h1>Sprawdź swoją skrzynkę mailową</h1>
        </div>
      );
    }

    return (
      
      <div className="row text">
        <form onSubmit={this.handleFormSubmit} className="kartaFrom">
          <div className="col text-input col-xs-12">
            <label htmlFor="first_name">Imię *</label>
            <div>
              <input 
                id="first_name"
                type="text" 
                value={this.state.firstname} 
                onChange={(event) => this.setState({ firstname: event.target.value })}
                placeholder="Imię" 
                required="required" 
              />
            </div>
            <figcaption></figcaption>
          </div>

          <div className="col text-input col-xs-12">
            <label htmlFor="last_name">Nazwisko *</label>
            <div>
              <input 
                id="last_name"
                type="text" 
                value={this.state.lastname} 
                onChange={(event) => this.setState({ lastname: event.target.value })}
                placeholder="Nazwisko" 
                required="required" 
              />
            </div>
            <figcaption></figcaption>
          </div>

          <div className="col text-input col-xs-12">
            <label htmlFor="email">Adres e-mail *</label>
            <div>
              <input 
                id="email" 
                type="email" 
                value={this.state.email}
                onChange={(event) => this.setState({ email: event.target.value })}
                placeholder="Email" 
                required="required"
              />
            </div>
            <figcaption></figcaption>
          </div>

          <div className="col text-input col-xs-12">
            <label htmlFor="telefoncode">Numer telefonu *</label>
            <div>
              <input 
                id="telefoncode" 
                type="number" 
                value={this.state.telefon_code}
                onChange={(event) => this.setState({ telefon_code: event.target.value })}
                style={{ width:"15%" }}
                placeholder="Kod"
              />

              <input 
                type="number" 
                value={this.state.telefon_number}
                onChange={(event) => this.setState({ telefon_number: event.target.value })} 
                style={{ width:"84%" }}
                placeholder="Telefon"  
                required="required"              
              />
            </div>
            <figcaption></figcaption>
          </div>

          <div className="col text-input col-xs-12">
            <label htmlFor="city">Miasto</label>
            <div>
              <input 
                id="city"
                type="text" 
                value={this.state.city}
                onChange={(event) => this.setState({ city: event.target.value })}
                placeholder="Miasto" 
              />
            </div>
            <figcaption></figcaption>
          </div>

          <div className="col text-input col-xs-12">
            <label htmlFor="postcode">Kod pocztowy</label>
            <div>
              <input 
                id="postcode"
                type="text" 
                value={this.state.postcode} 
                onChange={(event) => this.setState({ postcode: event.target.value })} 
                placeholder="Kod pocztowy" 
              />
            </div>
            <figcaption></figcaption>
          </div>
          <br />
          <div className="col text-input col-xs-12">
            <label htmlFor="country">Państwo</label>
            <div>  
              <select
                className="SignupField"
                id="country"
                value={this.state.country}
                onChange={(event) => this.setState({ country: event.target.value })}
              >
                <option value="PL">Polska</option>
                <option value="GB">Wielka Brytania</option>
                <option value="ES">Hiszpania</option>
                <option value="DE">Niemcy</option>
                <option value="FR">Francja</option>
                <option value="PT">Portugalia</option>
                <option value="CZ">Czechy</option>
                <option value="SE">Szwecja</option>
                <option value="NL">Holandia</option>
                <option value="CH">Szwajcaria</option>
                <option disabled="disabled">-------------------</option>
                <option value="AF">Afganistan</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algieria</option>
                <option value="AD">Andora</option>
                <option value="AO">Angola</option>
                <option value="AI">Anguilla</option>
                <option value="AQ">Antarktyda</option>
                <option value="AG">Antigua i Barbuda</option>
                <option value="SA">Arabia Saudyjska</option>
                <option value="AR">Argentyna</option>
                <option value="AM">Armenia</option>
                <option value="AW">Aruba</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbejdżan</option>
                <option value="BS">Bahamy</option>
                <option value="BH">Bahrajn</option>
                <option value="BD">Bangladesz</option>
                <option value="BB">Barbados</option>
                <option value="BE">Belgia</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BM">Bermudy</option>
                <option value="BT">Bhutan</option>
                <option value="BY">Białoruś</option>
                <option value="BO">Boliwia</option>
                <option value="BA">Bośnia i Hercegowina</option>
                <option value="BW">Botswana</option>
                <option value="BR">Brazylia</option>
                <option value="BN">Brunei</option>
                <option value="IO">Brytyjskie Terytorium Oceanu Indyjskiego</option>
                <option value="VG">Brytyjskie Wyspy Dziewicze</option>
                <option value="BG">Bułgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="EA">Ceuta i Melilla</option>
                <option value="CL">Chile</option>
                <option value="CN">Chiny</option>
                <option value="HR">Chorwacja</option>
                <option value="CI">Côte d’Ivoire</option>
                <option value="CW">Curaçao</option>
                <option value="CY">Cypr</option>
                <option value="TD">Czad</option>
                <option value="ME">Czarnogóra</option>
                <option value="UM">Dalekie Wyspy Mniejsze Stanów Zjednoczonych</option>
                <option value="DK">Dania</option>
                <option value="CD">Demokratyczna Republika Konga</option>
                <option value="DG">Diego Garcia</option>
                <option value="DM">Dominika</option>
                <option value="DO">Dominikana</option>
                <option value="DJ">Dżibuti</option>
                <option value="EG">Egipt</option>
                <option value="EC">Ekwador</option>
                <option value="ER">Erytrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Etiopia</option>
                <option value="FK">Falklandy</option>
                <option value="FJ">Fidżi</option>
                <option value="PH">Filipiny</option>
                <option value="FI">Finlandia</option>
                <option value="TF">Francuskie Terytoria Południowe i Antarktyczne</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GS">Georgia Południowa i Sandwich Południowy</option>
                <option value="GH">Ghana</option>
                <option value="GI">Gibraltar</option>
                <option value="GR">Grecja</option>
                <option value="GD">Grenada</option>
                <option value="GL">Grenlandia</option>
                <option value="GE">Gruzja</option>
                <option value="GU">Guam</option>
                <option value="GG">Guernsey</option>
                <option value="GY">Gujana</option>
                <option value="GF">Gujana Francuska</option>
                <option value="GP">Gwadelupa</option>
                <option value="GT">Gwatemala</option>
                <option value="GN">Gwinea</option>
                <option value="GW">Gwinea Bissau</option>
                <option value="GQ">Gwinea Równikowa</option>
                <option value="HT">Haiti</option>
                <option value="HN">Honduras</option>
                <option value="IN">Indie</option>
                <option value="ID">Indonezja</option>
                <option value="IQ">Irak</option>
                <option value="IR">Iran</option>
                <option value="IE">Irlandia</option>
                <option value="IS">Islandia</option>
                <option value="IL">Izrael</option>
                <option value="JM">Jamajka</option>
                <option value="JP">Japonia</option>
                <option value="YE">Jemen</option>
                <option value="JE">Jersey</option>
                <option value="JO">Jordania</option>
                <option value="KY">Kajmany</option>
                <option value="KH">Kambodża</option>
                <option value="CM">Kamerun</option>
                <option value="CA">Kanada</option>
                <option value="QA">Katar</option>
                <option value="KZ">Kazachstan</option>
                <option value="KE">Kenia</option>
                <option value="KG">Kirgistan</option>
                <option value="KI">Kiribati</option>
                <option value="CO">Kolumbia</option>
                <option value="KM">Komory</option>
                <option value="CG">Kongo</option>
                <option value="KR">Korea Południowa</option>
                <option value="KP">Korea Północna</option>
                <option value="XK">Kosowo</option>
                <option value="CR">Kostaryka</option>
                <option value="CU">Kuba</option>
                <option value="KW">Kuwejt</option>
                <option value="LA">Laos</option>
                <option value="LS">Lesotho</option>
                <option value="LB">Liban</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libia</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Litwa</option>
                <option value="LU">Luksemburg</option>
                <option value="LV">Łotwa</option>
                <option value="MK">Macedonia</option>
                <option value="MG">Madagaskar</option>
                <option value="YT">Majotta</option>
                <option value="MW">Malawi</option>
                <option value="MV">Malediwy</option>
                <option value="MY">Malezja</option>
                <option value="ML">Mali</option>
                <option value="MT">Malta</option>
                <option value="MP">Mariany Północne</option>
                <option value="MA">Maroko</option>
                <option value="MQ">Martynika</option>
                <option value="MR">Mauretania</option>
                <option value="MU">Mauritius</option>
                <option value="MX">Meksyk</option>
                <option value="FM">Mikronezja</option>
                <option value="MM">Mjanma (Birma)</option>
                <option value="MD">Mołdawia</option>
                <option value="MC">Monako</option>
                <option value="MN">Mongolia</option>
                <option value="MS">Montserrat</option>
                <option value="MZ">Mozambik</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="BQ">Niderlandy Karaibskie</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="NI">Nikaragua</option>
                <option value="NU">Niue</option>
                <option value="NF">Norfolk</option>
                <option value="NO">Norwegia</option>
                <option value="NC">Nowa Kaledonia</option>
                <option value="NZ">Nowa Zelandia</option>
                <option value="OM">Oman</option>
                <option value="UN">Organizacja Narodów Zjednoczonych</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua-Nowa Gwinea</option>
                <option value="PY">Paragwaj</option>
                <option value="PE">Peru</option>
                <option value="PN">Pitcairn</option>
                <option value="PF">Polinezja Francuska</option>
                <option value="PR">Portoryko</option>
                <option value="ZA">Republika Południowej Afryki</option>
                <option value="CF">Republika Środkowoafrykańska</option>
                <option value="CV">Republika Zielonego Przylądka</option>
                <option value="RE">Reunion</option>
                <option value="RU">Rosja</option>
                <option value="RO">Rumunia</option>
                <option value="RW">Rwanda</option>
                <option value="EH">Sahara Zachodnia</option>
                <option value="KN">Saint Kitts i Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="VC">Saint Vincent i Grenadyny</option>
                <option value="BL">Saint-Barthélemy</option>
                <option value="MF">Saint-Martin</option>
                <option value="PM">Saint-Pierre i Miquelon</option>
                <option value="SV">Salwador</option>
                <option value="WS">Samoa</option>
                <option value="AS">Samoa Amerykańskie</option>
                <option value="SM">San Marino</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="SC">Seszele</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapur</option>
                <option value="SX">Sint Maarten</option>
                <option value="SK">Słowacja</option>
                <option value="SI">Słowenia</option>
                <option value="SO">Somalia</option>
                <option value="HK">SRA Hongkong (Chiny)</option>
                <option value="MO">SRA Makau (Chiny)</option>
                <option value="LK">Sri Lanka</option>
                <option value="US">Stany Zjednoczone</option>
                <option value="EZ">strefa euro</option>
                <option value="SZ">Suazi</option>
                <option value="SD">Sudan</option>
                <option value="SS">Sudan Południowy</option>
                <option value="SR">Surinam</option>
                <option value="SJ">Svalbard i Jan Mayen</option>
                <option value="SY">Syria</option>
                <option value="TJ">Tadżykistan</option>
                <option value="TH">Tajlandia</option>
                <option value="TW">Tajwan</option>
                <option value="TZ">Tanzania</option>
                <option value="PS">Terytoria Palestyńskie</option>
                <option value="TL">Timor Wschodni</option>
                <option value="TG">Togo</option>
                <option value="TK">Tokelau</option>
                <option value="TO">Tonga</option>
                <option value="TA">Tristan da Cunha</option>
                <option value="TT">Trynidad i Tobago</option>
                <option value="TN">Tunezja</option>
                <option value="TR">Turcja</option>
                <option value="TM">Turkmenistan</option>
                <option value="TC">Turks i Caicos</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraina</option>
                <option value="UY">Urugwaj</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VU">Vanuatu</option>
                <option value="WF">Wallis i Futuna</option>
                <option value="VA">Watykan</option>
                <option value="VE">Wenezuela</option>
                <option value="HU">Węgry</option>
                <option value="VN">Wietnam</option>
                <option value="IT">Włochy</option>
                <option value="CX">Wyspa Bożego Narodzenia</option>
                <option value="IM">Wyspa Man</option>
                <option value="SH">Wyspa Świętej Heleny</option>
                <option value="AC">Wyspa Wniebowstąpienia</option>
                <option value="AX">Wyspy Alandzkie</option>
                <option value="CK">Wyspy Cooka</option>
                <option value="VI">Wyspy Dziewicze Stanów Zjednoczonych</option>
                <option value="IC">Wyspy Kanaryjskie</option>
                <option value="CC">Wyspy Kokosowe</option>
                <option value="MH">Wyspy Marshalla</option>
                <option value="FO">Wyspy Owcze</option>
                <option value="SB">Wyspy Salomona</option>
                <option value="ST">Wyspy Świętego Tomasza i Książęca</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
                <option value="AE">Zjednoczone Emiraty Arabskie</option>
              </select>
            </div>
          </div>
          <br />
          <div className="col text-input col-xs-12">
            <label htmlFor="birthday">Data urodzenia *</label>
            <div>
              <input 
                id="birthday"
                type="date" 
                value={this.state.birthday} 
                onChange={(event) => this.setState({ birthday: event.target.value })} 
                placeholder="Data urodzenia" 
              />
            </div>
            <figcaption></figcaption>
          </div>
          <br />
          <div className="col text-input col-xs-12">
            <label htmlFor="sex">Płeć</label>
            <div>  
              <select
                className="SignupField"
                id="sex"
                value={this.state.sex}
                onChange={(event) => this.setState({ sex: event.target.value })}
              >
                <option value="F">Kobieta</option>
                <option value="M">Mężczyzna</option>
              </select>
            </div>
          </div>
          <br />
          <div className="col text-input col-xs-12">
            <div>
              <input
                className="SignupCheckbox"
                name="marketing" 
                id="marketing"
                type="checkbox" 
                value={this.state.marketing} 
                onChange={this.handleMarketing} 
              />
              <label  className="SignupCheckboxLabel" htmlFor="marketing">Wyrażam zgodę na otrzymywanie informacji marketingowych</label>
            </div>
            <figcaption></figcaption>
          </div>
          <br />
          <div className="col text-input col-xs-12">
            <div>
              <input 
                name="privacy"
                id="privacy"
                className="SignupCheckbox"
                type="checkbox" 
                value={this.state.privacy} 
                onChange={this.handlePrivacy} 
                required="required"
              />
              <label className="SignupCheckboxLabel" htmlFor="privacy" dangerouslySetInnerHTML={{__html: "Rozumiem i akceptuję: <a target='_blank' href='https://poweredby.coniq.com/policy/390/PL.html'>Ogólne warunki programu Klub Turawa</a> i <a target='_blank' href='https://poweredby.coniq.com/policy/392/PL.html'>Polityka Prywatności</a> która określa, w jaki sposób będą wykorzystywane moje dane osobowe.*"}}></label>
            </div>
            <figcaption></figcaption>
          </div>
          <br />
          <div className="col submit col-xs-4 ">
            <button type="submit" className="btn form-btn">ZALOGUJ SIĘ</button>
          </div>

        </form>

      </div>
      
    );
  }
}

export default SignupForm;
