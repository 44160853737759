import React, {Component} from 'react';
import {connect} from "react-redux";

//actions
import {setPost} from "../../actions/pageActions";

//tools
import classnames from "classnames";
import Truncate from "react-truncate";

//svg
//import WhatsApp from "../../svg/Whatsapp";
//import Email from "../../svg/Email";
import {Redirect} from "react-router";
import {paths} from "../../config/paths";

class Modal extends Component {

    constructor(props) {
        super(props);
        this.state={
            redirect: false,
            redirectLink: null,
        };

        this.handlePostSelection = this.handlePostSelection.bind(this);

    }

    handlePostSelection = url => {
        console.log(url)
        this.setState({
                redirect: true,
                redirectLink: url
            });
        console.log(this.state)
    };

    render() {

        const {
            modalData,
            pageOptions,
            setSelectedPost
        } = this.props;


        return (
            <div
                className="modal"
                style={{ backgroundColor: pageOptions.lightbox_opis_bg }}
            >
                { this.state.redirect &&
                    <Redirect push to={ [ paths.sklepy, this.state.redirectLink ].join("/") }/>
                }
                <nav className="close" onClick={ () => setSelectedPost(null) }>x</nav>
                <article>
                    <div className="row">
                        <div className={classnames(
                            "col",{
                                "col-xs-5": modalData.tel || modalData.email,
                                "col-xs-12": !modalData.tel && !modalData.email
                            }
                        )}>
                            <h3>{modalData.nazwa}</h3>
                        </div>
                        <div className={classnames(
                            "col col-xs-6 phone",{
                                "reduced" :  modalData.tel && modalData.email
                            }
                        )}>
                            {
                                modalData.metody_kontaktu &&
                                modalData.metody_kontaktu
                                    .filter(item => item.in_popup)
                                    .map(
                                    (item,index) =>
                                        <section key={index}>
                                            <div className="row">
                                                <div className="col col-xs-2 icon">
                                                    <img src={item.ikona} width={25} height={25} alt={`Ikona:${item.etykieta}`}/>
                                                </div>
                                                <div className="col col-xs-7 info">
                                                    {
                                                        item.url ?
                                                            <a href={item.url} title={`Link: ${item.etykieta}`}
                                                               target="_blank" rel="noopener nofollow">
                                                                <span>{item.etykieta}</span>
                                                            </a> :
                                                            <span>{item.etykieta}</span>

                                                    }
                                                </div>
                                            </div>
                                        </section>
                                )
                            }

                        </div>
                        <div className="col col-xs-12 txt">
                            <Truncate
                                lines={2}
                                ellipsis={(
                                    <span>...</span>
                                )}
                            >
                                <span dangerouslySetInnerHTML={{__html:modalData.zajawka}}/>

                            </Truncate>
                        </div>
                    </div>
                </article>
                <nav className="go-to">
                    <button
                        className="btn form-btn revert"
                        title={`Link: ${ modalData.nazwa}`}
                        onMouseEnter={ () => setSelectedPost( modalData.url ) }
                        onClick={() => this.handlePostSelection(modalData.url)}>
                        {pageOptions.teksty_zobacz_wiecej}
                    </button>
                </nav>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions
});

const mapDispatchToProps = dispatch => ({
    setSelectedPost: value => dispatch( setPost(value))
});


export default connect(mapStateToProps,mapDispatchToProps)(Modal);