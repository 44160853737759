import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../Page';
import Mapa from './Mapa';
import Table from "./Table";
import PageHeader from "../../PageHeader";

//config
import { config }  from "../../../config";

class ViewLokalizacja extends Component {
    render() {
        const {
            pageData,
            pageDataBusesList,
            pageOptions } = this.props;

        return (
            <Page pageIndex={config.pageIndex.lokalizacja}>
                <article className="page lokalizacja content">
                    <div className="boxed">
                        <PageHeader
                            title={pageData.tytul}
                            subtitle={pageData.podtytul}
                            text={pageData.tekst_naglowka}
                        />

                        <Mapa img={pageData.mapa} link={pageData.link_mapy} />

                        <PageHeader
                            id="dojazd"
                            title={pageData.tytul_dojazd}
                            subtitle={pageData.podtytul_dojazd}
                            text={pageData.tekst_naglowka_dojazd}
                        />

                        <div className="row rozklady-jazdy">
                            {
                                pageDataBusesList.map((item, index) =>
                                    <Table
                                        data={item}
                                        hours={item.godziny}
                                        legenda={item.legenda}
                                        pageOptions={pageOptions}
                                        key={index} />
                                )
                            }
                        </div>

                    </div>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageDataBusesList: state.page.pageDataBusesList,
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(ViewLokalizacja);