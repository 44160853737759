import React from 'react';

const TableHeader = (
    {
        linia,
        kolor,
        kierunekLabel,
        przystanekLabel,
        kierunek,
        przystanek
    }
    ) => {
    return (
        <header style={{ backgroundColor: `rgb(${kolor})` }}>
            <div className="row middle-xs">
                <div className="col col-xs-8 kierunki">
                    {kierunekLabel} <strong>{kierunek}</strong><br/>
                    {przystanekLabel} <strong>{przystanek}</strong>
                </div>
                <div className="col col-xs-4 end-xs">
                    <figure>{linia}</figure>
                </div>
            </div>
        </header>
    );
};

export default TableHeader;