import React, { Component, Fragment } from 'react';
import {connect} from "react-redux";
import { Redirect } from 'react-router';

//actions
import { setPost } from "../../actions/pageActions";

//components
import Modal from "../Modal";

//tools
import classnames from "classnames";
import * as _ from "lodash";

import { paths } from "../../config/paths";

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            redirectLink: null,
            hoveredItemUrl: null
        };
        this.handlePostSelection = this.handlePostSelection.bind(this);
    }

    handlePostSelection = value =>{
        this.props.setSelectedPost(value);
        this.setState(
            {
                redirect: true ,
                redirectLink: value
            });
    };

    render() {

        const {
            data,
            group,
            category,
            categoryId,
            grid,
            theme
        } = this.props;

        return (

            <div className={classnames(
                "list",
                grid,
                { "themed" : theme }
            )}>
                { this.state.redirect &&
                    <Redirect push to={paths.sklepy + '/' + this.state.redirectLink}/>
                }
                <h3 style={{ backgroundColor: theme  }}>
                            <span>
                                {data
                                    .filter( item => { return item.acf.grupa === group })
                                    .filter( (item) =>  {
                                        return item.acf[categoryId].value === category
                                    })
                                    .filter( (item,index) => { return index === 0 } )
                                    .map( (item,index) => <Fragment key={index}> { item.acf[categoryId].label }</Fragment>)}
                            </span>
                </h3>

                <ul>
                    {_.sortBy(data, e => e.acf.nazwa)
                        .filter( item => { return item.acf.grupa === group })
                        .filter( item => { return item.acf[categoryId].value === category })
                        .map( (item,index) =>
                            <li
                                key={index}
                                onMouseEnter={ () => this.setState({hoveredItemUrl:item.acf.url}) }
                                onMouseLeave={ () => this.setState({hoveredItemUrl: null}) }
                                onClick={() => this.handlePostSelection(item.acf.url)}
                                className={classnames(
                                    `item-${item.acf.url}`,
                                    `cat-${item.acf.grupa}`,{
                                        "placeholder" : item.acf.placeholder
                                    }
                                )}
                            >
                                <button title={`Link: ${ item.acf.nazwa}`} > { item.acf.nazwa } </button>
                                {
                                    theme &&
                                    this.state.hoveredItemUrl === item.acf.url
                                    && <Modal modalData={item.acf}/>
                                }

                            </li>
                        )}
                </ul>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setSelectedPost: value => dispatch( setPost(value))
});


export default connect(null,mapDispatchToProps)(List);
