/*
dataid: 'inactive',

    top:
fill: '#cccccc',
    side:
fill: '#888888',
    front:
fill: '#bbbbbb',
*/

export const layers =
    {
        row1: [
            // 58
            {
                className: "row1col15",
                dataid: 58,
                fill: "#063356",
                pointsIn: "1740.968,360.075 1750.45,365.55 1819.819,325.24 1820,175.31 1820,175 1809.839,169.134     1741,209.721   ",
                pointsOut: "1740.968,320.075 1750.45,325.55 1819.819,285.24 1820,135.31 1820,135 1809.839,129.134     1741,169.721   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col15",
                dataid: 58,
                fill: "#002B4B",
                pointsIn: "1750.63,215.31 1750.45,365.55 1447.35,190.55 1447.53,40.31 1460.353,32.828 1762,208.721   ",
                pointsOut: "1750.63,175.31 1750.45,325.55 1447.35,150.55 1447.53,0.31 1460.353,-7.172 1762,168.721   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col15",
                dataid: 58,
                posX: "79.56%",
                fill: "#09375A",
                pointsIn: "1516.89,0 1448,40 1750.888,215.732 1820,175 1820,175   ",
                pointsOut: "1516.89,-40 1448,0 1750.888,175.732 1820,135 1820,135   ",
                side: "top",
                cat: "inactive",
            },

            // 57
            {
                className: "row1col13row1col2",
                dataid: 57,
                fill: "#C17622",
                pointsIn: "1742.979,220.15 1742.79,370.69 1673.42,411 1673.61,260.46 1666.88,256.573 1737.979,217.26       ",
                pointsOut: "1742.979,180.15 1742.79,330.69 1673.42,371 1673.61,220.46 1666.88,216.573 1737.979,177.26       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col13row1col2",
                dataid: 57,
                fill: "#935912",
                pointsIn: "1673.61,260.46 1673.42,411 1370.41,236 1370.6,85.46   ",
                pointsOut: "1673.61,220.46 1673.42,371 1370.41,196 1370.6,45.46   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col13row1col2",
                dataid: 57,
                posX: "71.10%",
                fill: "#D38027",
                pointsIn: "1742.979,220.15 1673.61,260.46 1511.47,166.85 1370.51,85.46 1439.88,45.15 1446.06,48.72     1737.979,217.26   ",
                pointsOut: "1742.979,180.15 1673.61,220.46 1511.47,126.85 1370.51,45.46 1439.88,5.15 1446.06,8.72     1737.979,177.26   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col13row1col1",
                dataid: 57,
                fill: "#C17622",
                pointsIn: "1512,167.471 1511.97,317.93 1434.35,364.47 1425.173,360.075 1425.173,207.604 1504,162.221       ",
                pointsOut: "1512,127.471 1511.97,277.93 1434.35,324.47 1425.173,320.075 1425.173,167.604 1504,122.221       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col13row1col1",
                dataid: 57,
                fill: "#935912",
                pointsIn: "1434.44,212.92 1434.38,363.39 1293.42,282 1293.47,131.53 1300.406,127.39 1441.63,208.629   ",
                pointsOut: "1434.44,172.92 1434.38,323.39 1293.42,242 1293.47,91.53 1300.406,87.39 1441.63,168.629   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col13row1col1",
                dataid: 57,
                posX: "71.10%",
                fill: "#D38027",
                pointsIn: "1513,166.39 1434.38,212.92 1293.41,131.53 1372.03,85   ",
                pointsOut: "1513,126.39 1434.38,172.92 1293.41,91.53 1372.03,45   ",
                side: "top",
                cat: "inactive",
            },

            // 56
            {
                className: "row1col14",
                dataid: 56,
                fill: "#bbbbbb",
                pointsIn: "1666.88,264.79 1666.88,415.11 1597.2,455.36 1589.639,450.95 1589.639,300.555 1659.38,260.46       ",
                pointsOut: "1666.88,224.79 1666.88,375.11 1597.2,415.36 1589.639,410.95 1589.639,260.555 1659.38,220.46       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col14",
                dataid: 56,
                fill: "#888888",
                pointsIn: "1443.41,366.57 1597.2,455.36 1597.601,305.13 1607.347,299.384 1451.851,211.585 1443.54,216.14       ",
                pointsOut: "1443.41,326.57 1597.2,415.36 1597.601,265.13 1607.347,259.384 1451.851,171.585 1443.54,176.14       ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col14",
                dataid: 56,
                posX: "79.29%",
                fill: "#cccccc",
                pointsIn: "1666.88,264.79 1597.51,305.1 1443.72,216.31 1513.09,176   ",
                pointsOut: "1666.88,224.79 1597.51,265.1 1443.72,176.31 1513.09,136   ",
                side: "top",
                cat: "inactive",
            },

            // 55
            {
                className: "row1col12",
                dataid: 55,
                fill: "#C17622",
                pointsIn: "1588.41,309.75 1588.229,460.37 1519.41,501 1508.781,494.829 1508.781,344.145 1578.579,304.085       ",
                pointsOut: "1588.41,269.75 1588.229,420.37 1519.41,461 1508.781,454.829 1508.781,304.145 1578.579,264.085       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col12",
                dataid: 55,
                fill: "#935912",
                pointsIn: "1519.58,350.38 1519.47,501 1216.36,326 1216.479,175.38 1223.889,171.074 1528.039,345.387   ",
                pointsOut: "1519.58,310.38 1519.47,461 1216.36,286 1216.479,135.38 1223.889,131.074 1528.039,305.387   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col12",
                dataid: 55,
                posX: "66.81%",
                fill: "#D38027",
                pointsIn: "1588.41,309.75 1519.58,350.38 1216.479,175.38 1285.57,135.23   ",
                pointsOut: "1588.41,269.75 1519.58,310.38 1216.479,135.38 1285.57,95.23   ",
                side: "top",
                cat: "inactive",
            },

            // 54
            {
                className: "row1col10row1col2",
                dataid: 54,
                fill: "#646794",
                pointsIn: "1510.99,355.99 1510.85,505.59 1441.479,545.91 1432.29,540.705 1432.29,390.917     1502.162,350.894   ",
                pointsOut: "1510.99,315.99 1510.85,465.59 1441.479,505.91 1432.29,500.705 1432.29,350.917     1502.162,310.894   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col10row1col2",
                dataid: 54,
                fill: "#4B4D73",
                pointsIn: "1441.63,396.31 1441.479,545.91 1138.41,370.9 1138.55,221.3 1148.128,215.732 1450.823,390.965       ",
                pointsOut: "1441.63,356.31 1441.479,505.91 1138.41,330.9 1138.55,181.3 1148.128,175.732 1450.823,350.965       ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col10row1col2",
                dataid: 54,
                posX: "58.41%",
                fill: "#6C70A1",
                pointsIn: "1510.99,355.99 1441.63,396.31 1252.03,285.59 1242.67,291.03 1131.399,225.38 1207.89,181   ",
                pointsOut: "1510.99,315.99 1441.63,356.31 1252.03,245.59 1242.67,251.03 1131.399,185.38 1207.89,141   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col10row1col1",
                dataid: 54,
                fill: "#646794",
                pointsIn: "1252.03,285.59 1252.06,435.2 1174.82,480 1166.653,475.285 1166.653,325.622 1244.769,281.415       ",
                pointsOut: "1252.03,245.59 1252.06,395.2 1174.82,440 1166.653,435.285 1166.653,285.622 1244.769,241.415       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col10row1col1",
                dataid: 54,
                fill: "#4B4D73",
                pointsIn: "1174.93,330.4 1174.82,480 1062.41,415.1 1062.44,265.45 1073.02,259.303 1185.62,324.188   ",
                pointsOut: "1174.93,290.4 1174.82,440 1062.41,375.1 1062.44,225.45 1073.02,219.303 1185.62,284.188   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col10row1col1",
                dataid: 54,
                posX: "58.41%",
                fill: "#6C70A1",
                pointsIn: "1139.03,220.63 1252.03,285.59 1174.93,330.4 1062.44,265.45 1139.54,220.65   ",
                pointsOut: "1139.03,180.63 1252.03,245.59 1174.93,290.4 1062.44,225.45 1139.54,180.65   ",
                side: "top",
                cat: "inactive",
            },

            // 53
            {
                className: "row1col11",
                dataid: 53,
                fill: "#bbbbbb",
                pointsIn: "1433.979,399.65 1433.95,549.65 1364.42,590 1354.502,583.93 1354.502,434.133 1425.173,394.566       ",
                pointsOut: "1433.979,359.65 1433.95,509.65 1364.42,550 1354.502,543.93 1354.502,394.133 1425.173,354.566       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col11",
                dataid: 53,
                fill: "#888888",
                pointsIn: "1183.41,485.36 1364.51,589.92 1364.63,439.91 1373.567,434.764 1193.168,329.798 1183.54,335.33       ",
                pointsOut: "1183.41,445.36 1364.51,549.92 1364.63,399.91 1373.567,394.764 1193.168,289.798 1183.54,295.33       ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col11",
                dataid: 53,
                posX: "65.05%",
                fill: "#cccccc",
                pointsIn: "1433.979,399.65 1364.61,439.97 1183.51,335.41 1252.88,295.1   ",
                pointsOut: "1433.979,359.65 1364.61,399.97 1183.51,295.41 1252.88,255.1   ",
                side: "top",
                cat: "inactive",
            },

            // 52
            {
                className: "row1col9row1col2",
                dataid: 52,
                fill: "#8B8020",
                pointsIn: "1286.6,485.72 1286.42,636 983.41,460.96 983.6,310.68   ",
                pointsOut: "1286.6,445.72 1286.42,596 983.41,420.96 983.6,270.68   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col9row1col2",
                dataid: 52,
                fill: "#B9A72F",
                pointsIn: "1355.97,445.41 1355.79,595.69 1286.42,636 1286.6,485.72   ",
                pointsOut: "1355.97,405.41 1355.79,555.69 1286.42,596 1286.6,445.72   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col9row1col2",
                dataid: 52,
                posX: "49.95%",
                fill: "#CBB633",
                pointsIn: "1355.97,445.41 1286.6,485.72 1095.99,375.68 983.5,310.73 1052.87,270.42   ",
                pointsOut: "1355.97,405.41 1286.6,445.72 1095.99,335.68 983.5,270.729 1052.87,230.42   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col9row1col1",
                dataid: 52,
                posX: "49.95%",
                fill: "#CBB633",
                pointsIn: "1097.41,374.95 1097.41,374.95 1020.3,419.76 907.81,354.81 984.92,310   ",
                pointsOut: "1097.41,334.95 1097.41,334.95 1020.3,379.76 907.81,314.81 984.92,270   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col9row1col1",
                dataid: 52,
                fill: "#B9A72F",
                pointsIn: "1097.41,374.95 1097.38,525.09 1020.27,569.9 1020.3,419.76   ",
                pointsOut: "1097.41,334.95 1097.38,485.09 1020.271,529.9 1020.3,379.76   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col9row1col1",
                dataid: 52,
                fill: "#8B8020",
                pointsIn: "1020.3,419.76 1020.3,569.9 907.81,504.95 907.81,354.81   ",
                pointsOut: "1020.3,379.76 1020.3,529.9 907.81,464.95 907.81,314.81   ",
                side: "side",
                cat: "inactive",
            },

            // 51
            {
                className: "row1col10",
                dataid: 51,
                posX: "56.59%",
                fill: "#C5222C",
                pointsIn: "1279.11,488.56 1209.74,528.87 1028.64,424.31 1098.01,384   ",
                pointsOut: "1279.11,448.56 1209.74,488.87 1028.64,384.31 1098.01,344   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col10",
                dataid: 51,
                fill: "#B51D27",
                pointsIn: "1279.11,488.56 1278.88,638.98 1209.51,679.3 1209.74,528.87   ",
                pointsOut: "1279.11,448.56 1278.88,598.979 1209.51,639.3 1209.74,488.87   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col10",
                dataid: 51,
                fill: "#8A0E16",
                pointsIn: "1209.74,528.87 1209.51,679.3 1028.41,574.74 1028.64,424.31   ",
                pointsOut: "1209.74,488.87 1209.51,639.3 1028.41,534.74 1028.64,384.31   ",
                side: "side",
                cat: "inactive",
            },

            // 50
            {
                className: "row1col8",
                dataid: 50,
                fill: "#063356",
                pointsIn: "1203.07,533.97 1202.89,683.8 1133.521,724.11 1126.098,719.458 1126.098,569.9 1196.663,530.271       ",
                pointsOut: "1203.07,493.97 1202.89,643.8 1133.521,684.11 1126.098,679.458 1126.098,529.9 1196.663,490.271       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col8",
                dataid: 50,
                fill: "#002B4B",
                pointsIn: "1133.7,574.29 1133.521,724.11 830.41,549.11 830.6,399.29 838.9,394.466 1143.372,568.668   ",
                pointsOut: "1133.7,534.29 1133.521,684.11 830.41,509.11 830.6,359.29 838.9,354.466 1143.372,528.668   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col8",
                dataid: 50,
                posX: "45.55%",
                fill: "#09375A",
                pointsIn: "1203.07,533.97 1133.7,574.29 830.6,399.29 898.78,359.67 899.93,359   ",
                pointsOut: "1203.07,493.97 1133.7,534.29 830.6,359.29 898.78,319.67 899.93,319   ",
                side: "top",
                cat: "inactive",
            },

            // 49
            {
                className: "row1col7",
                dataid: 49,
                fill: "#7F0F57",
                pointsIn: "1126.88,577.68 1126.69,727.8 1057.32,768.111 1047.609,759.71 1047.609,612.277 1119.075,573.19       ",
                pointsOut: "1126.88,537.68 1126.69,687.8 1057.32,728.111 1047.609,719.71 1047.609,572.277 1119.075,533.19       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col7",
                dataid: 49,
                fill: "#690047",
                pointsIn: "1057.521,617.99 1057.521,768.06 754.41,593.07 754.41,443 764.271,437.27 1066.559,612.746   ",
                pointsOut: "1057.521,577.99 1057.521,728.06 754.41,553.07 754.41,403 764.271,397.27 1066.559,572.746   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col7",
                dataid: 49,
                posX: "41.43%",
                fill: "#86135E",
                pointsIn: "1126.88,577.68 1057.521,618 754.41,443 822.45,403.46 823.229,403   ",
                pointsOut: "1126.88,537.68 1057.521,578 754.41,403 822.45,363.46 823.229,363   ",
                side: "top",
                cat: "inactive",
            },
            { 	className: "row1col6divider", 
                    dataid: "divider",
                    fill: "#FFFFFF", 
                    pointsIn: "691,498 691,630 990,809 1059,768 1060,641 752,465  ", 
                    cat: "inactive",
            },

            // 27
            {
                className: "row1col3row1col2",
                dataid: 27,
                fill: "#888888",
                pointsIn: "868.56,638.87 868.55,790 644,660.02 644,508.9   ",
                pointsOut: "868.56,598.87 868.55,750 644,620.021 644,468.9   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col3row1col2",
                dataid: 27,
                fill: "#bbbbbb",
                pointsIn: "911.04,614.18 911.03,765.18 868.55,789.87 868.56,638.87   ",
                pointsOut: "911.04,574.18 911.03,725.179 868.55,749.871 868.56,598.87   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col3row1col2",
                dataid: 27,
                posX: "45.55%",
                fill: "#cccccc",
                pointsIn: "764.27,578.71 764.27,578.71 764.27,578.71 630.59,656.4 510,586.78 616.79,524.72 619.93,522.89     643.68,509.09   ",
                pointsOut: "764.271,538.71 764.271,538.71 764.271,538.71 630.59,616.4 510,546.78 616.79,484.72     619.93,482.89 643.68,469.09   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col3row1col1",
                dataid: 27,
                posX: "10%",
                fill: "#cccccc",
                pointsIn: "686.48,484.54 911.04,614.18 868.56,638.87 764.59,578.84 764.59,578.84 644,509.22   ",
                pointsOut: "686.479,444.54 911.04,574.18 868.56,598.87 764.59,538.84 764.59,538.84 644,469.22   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col3row1col1",
                dataid: 27,
                fill: "#bbbbbb",
                pointsIn: "764.27,578.71 764.26,729.32 630.59,807 630.59,656.4   ",
                pointsOut: "764.271,538.71 764.26,689.32 630.59,767 630.59,616.4   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col3row1col1",
                dataid: 27,
                fill: "#888888",
                pointsIn: "630.59,656.39 630.59,807 510,737.38 510,586.78   ",
                pointsOut: "630.59,616.39 630.59,767 510,697.38 510,546.78   ",
                side: "side",
                cat: "inactive",
            },

            // 48
            {
                className: "row1col6",
                dataid: 48,
                posX: "48.24%",
                fill: "#09375A",
                pointsIn: "988.71,659.67 947.37,683.7 878.18,643.76 919.53,619.73   ",
                pointsOut: "988.71,619.67 947.37,643.7 878.18,603.76 919.53,579.729   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col6",
                dataid: 48,
                fill: "#8C8020",
                pointsIn: "947.37,683.7 947.18,834 878,794.06 878.18,643.76   ",
                pointsOut: "947.37,643.7 947.18,794 878,754.06 878.18,603.76   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col6",
                dataid: 48,
                fill: "#BAA82F",
                pointsIn: "988.71,659.67 988.65,809.96 947.31,833.99 947.37,683.7   ",
                pointsOut: "988.71,619.67 988.65,769.96 947.31,793.99 947.37,643.7   ",
                side: "front",
                cat: "inactive",
            },

            // 46
            {
                className: "row1col4row1col2",
                dataid: 46,
                posX: "36.48%",
                fill: "#7071A7",
                pointsIn: "811.13,670.92 859.91,642.57 764.2,587.31 715.42,615.66   ",
                pointsOut: "811.13,630.92 859.91,602.57 764.2,547.31 715.42,575.66   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col4row1col2",
                dataid: 46,
                fill: "#67689B",
                pointsIn: "859.91,642.57 860.03,792.33 811.25,820.68 811.13,670.92   ",
                pointsOut: "859.91,602.57 860.03,752.33 811.25,780.679 811.13,630.92   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col4row1col2",
                dataid: 46,
                fill: "#4E4E77",
                pointsIn: "715.42,615.66 715.25,765.94 811.25,820.68 811.25,670.68   ",
                pointsOut: "715.42,575.66 715.25,725.941 811.25,780.679 811.25,630.68   ",
                side: "side",
                cat: "inactive",
            },

            // 47
            {
                className: "row1col5",
                dataid: 47,
                posX: "45.55%",
                fill: "#d41a31",
                pointsIn: "939.66,687.94 898.32,711.97 829.13,672.02 870.47,648   ",
                pointsOut: "939.66,647.94 898.32,671.97 829.13,632.021 870.47,608   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col5",
                dataid: 47,
                fill: "#b5192e",
                pointsIn: "939.66,687.94 939.52,841.65 898.18,865.67 898.32,711.97   ",
                pointsOut: "939.66,647.94 939.521,801.65 898.18,825.669 898.32,671.97   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col5",
                dataid: 47,
                fill: "#970b14",
                pointsIn: "898.32,711.97 898.18,865.79 829,825.85 829.13,672.02   ",
                pointsOut: "898.32,671.97 898.18,825.791 829,785.849 829.13,632.021   ",
                side: "side",
                cat: "inactive",
            },

            // 46
            {
                className: "row1col4row1col1",
                dataid: 46,
                fill: "#67689B",
                pointsIn: "838.9,746.47 838.89,897 891,866.75 891,716.2   ",
                pointsOut: "838.9,706.47 838.89,857 891,826.75 891,676.201   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col4row1col1",
                dataid: 46,
                fill: "#4E4E77",
                pointsIn: "664.03,645.67 664.04,796.11 838.89,897 838.9,746.47   ",
                pointsOut: "664.03,605.67 664.04,756.111 838.89,857 838.9,706.47   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col4row1col1",
                dataid: 46,
                posX: "36.48%",
                fill: "#7071A7",
                pointsIn: "891,716.201 716.26,615.31 708.8,619.65 707.15,620.61 664.03,645.67 838.9,746.47   ",
                pointsOut: "891,676.201 716.26,575.31 708.8,579.65 707.15,580.61 664.03,605.67 838.9,706.47   ",
                side: "top",
                cat: "inactive",
            },
            { 	className: "row1col3divider", 
                    dataid: "devider",
                    fill: "#FFFFFF", 
                    pointsIn: "628,806 804.79,916.99 838.89,897 840,768 664,667 628,687  ", 
                    cat: "inactive",
            },

            // 45
            {
                className: "row1col2",
                dataid: 45,
                posX: "8.02%",
                fill: "#3978AD",
                pointsIn: "804.91,766.63 449.24,973.32 146.02,798.4 501.8,591.63   ",
                pointsOut: "804.91,726.63 449.24,933.32 146.02,758.4 501.8,551.63   ",
                cat: "inactive",
            },
            {
                className: "row1col2",
                dataid: 45,
                fill: "#3978AD",
                pointsIn: "804.91,766.63 805,788 442,1000 442,978 146,807 146.02,798.4 501.8,591.63   ",
                pointsOut: "804.91,726.63 805,748 442,960 442,938 146,767 146.02,758.4 501.8,551.63   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row1col2",
                dataid: 45,
                fill: "#346E9F",
                pointsIn: "804.91,766.63 804.79,916.99 449.12,1123.46 449.24,973.32   ",
                pointsOut: "804.91,726.63 804.79,876.99 449.12,1083.46 449.24,933.32   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col2",
                dataid: 45,
                fill: "#24537C",
                pointsIn: "449.34,973.25 449.16,1124 146.06,949 146,798.72   ",
                pointsOut: "449.34,933.25 449.16,1084 146.06,909 146,758.72   ",
                side: "side",
                cat: "inactive",
            },

            // 44
            {
                className: "row1col1",
                dataid: 44,
                fill: "#063356",
                pointsIn: "441.64,978 441.521,1128.56 303.1,1209 292.111,1202.655 292.111,1052.027 431.13,971.933   ",
                pointsOut: "441.64,938 441.521,1088.56 303.1,1169 292.111,1162.655 292.111,1012.027 431.13,931.933   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row1col1",
                dataid: 44,
                fill: "#002B4B",
                pointsIn: "0,1034 303.1,1209 303.22,1058.441 319.363,1049.06 15.686,874.396 0.12,883.441   ",
                pointsOut: "0,994 303.1,1169 303.22,1018.441 319.363,1009.06 15.686,834.396 0.12,843.441   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row1col1",
                dataid: 44,
                posX: "0",
                fill: "#09375A",
                pointsIn: "137.54,803.58 0.12,883.441 303.22,1058.441 441.64,978 138.53,803   ",
                pointsOut: "137.54,763.58 0.12,843.441 303.22,1018.441 441.64,938 138.53,763   ",
                side: "top",
                cat: "inactive",
            },
            { 	className: "row1clip", 
                    dataid: "inactive",
                    fill: "#FFFFFF", 
                    pointsIn: "1844,1393.44 302,1393.44 -14,1393.44 -2,911 302,1082 1820,195  ", 
                    cat: "inactive",
            },

            // 41
            {
                className: "row2col4item4",
                dataid: 41,
                fill: "#bbbbbb",
                pointsIn: "1098.74,721.15 1098.74,868.41 1049.55,897 1049.17,896.781 1045.41,895.669 1045.41,747.351     1095.81,719.458   ",
                pointsOut: "1098.74,681.15 1098.74,828.41 1049.55,857 1049.17,856.781 1045.41,855.669 1045.41,707.351     1095.81,679.458   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col4item4",
                dataid: 41,
                fill: "#888888",
                pointsIn: "1049.55,743.47 1049.55,897 982.14,858.089 982.14,710.83 983.4,710.1   ",
                pointsOut: "1049.55,703.47 1049.55,857 982.14,818.089 982.14,670.83 983.4,670.1   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item4",
                dataid: 41,
                posX: "0",
                fill: "#cccccc",
                pointsIn: "1098.74,721.15 1049.55,749.74 982.14,710.83 1031.34,682.229   ",
                pointsOut: "1098.74,681.15 1049.55,709.74 982.14,670.83 1031.34,642.229   ",
                side: "top",
                cat: "inactive",
            },

            // 40
            {
                className: "row2col4item3_1",
                dataid: 40,
                fill: "#002B4B",
                pointsIn: "1014.5,878.191 972.17,902.72 972.17,757.89 1014.5,735.01   ",
                pointsOut: "1014.5,838.191 972.17,862.72 972.17,717.89 1014.5,695.01   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col4item3_1",
                dataid: 40,
                fill: "#002B4B",
                pointsIn: "935.479,737.94 935.479,877.22 972.89,902.72 972.89,757.5   ",
                pointsOut: "935.479,697.94 935.479,837.22 972.89,862.72 972.89,717.5   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item3_1",
                dataid: 40,
                posX: "0",
                fill: "#09375A",
                pointsIn: "972.84,759.57 1015.42,734.83 978.01,713.229 935.479,737.94 972.89,759.541   ",
                pointsOut: "972.84,719.57 1015.42,694.83 978.01,673.229 935.479,697.94 972.89,719.541   ",
                side: "top",
                cat: "inactive",
            },

            // 39
            {
                className: "row2col4item2",
                dataid: 39,
                fill: "#7F0F57",
                pointsIn: "965.21,759.72 965.21,906.951 943.56,919.531 939.66,916.387 940.11,770.299 962.351,758.069       ",
                pointsOut: "965.21,719.72 965.21,866.951 943.56,879.531 939.66,876.387 940.11,730.299 962.351,718.069       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col4item2",
                dataid: 39,
                fill: "#690047",
                pointsIn: "943.56,772.291 943.56,919.531 909.86,900.07 909.86,752.83 910.44,752.49 944.09,771.979   ",
                pointsOut: "943.56,732.291 943.56,879.531 909.86,860.07 909.86,712.83 910.44,712.49 944.09,731.979   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item2",
                dataid: 39,
                posX: "0",
                fill: "#86135E",
                pointsIn: "965.21,759.72 943.56,772.291 909.86,752.83 931.5,740.26   ",
                pointsOut: "965.21,719.72 943.56,732.291 909.86,712.83 931.5,700.26   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col4item1",
                dataid: 38,
                fill: "#4F2C7C",
                pointsIn: "940.11,774.55 940.11,921.451 913.59,936.861 910.368,935.001 910.368,788.1 936.949,772.725       ",
                pointsOut: "940.11,734.55 940.11,881.451 913.59,896.861 910.368,895.001 910.368,748.1 936.949,732.725       ",
                side: "front",
                cat: "inactive",
            },
            
            // 38
            {
                className: "row2col4item1",
                dataid: 38,
                fill: "#3B195F",
                pointsIn: "913.59,789.96 913.59,936.861 879.89,917.4 879.89,770.509 880.63,770.08 914.521,789.419   ",
                pointsOut: "913.59,749.96 913.59,896.861 879.89,877.4 879.89,730.509 880.63,730.08 914.521,749.419   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item1",
                dataid: 38,
                posX: "0",
                fill: "#563285",
                pointsIn: "940.11,774.55 913.59,789.96 879.89,770.509 906.41,755.09   ",
                pointsOut: "940.11,734.55 913.59,749.96 879.89,730.509 906.41,715.09   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col4item3_2",
                dataid: 40,
                fill: "#002B4B",
                pointsIn: "942.934,955.25 946.81,956.849 1045.41,899.55 1045.41,752.14 1041.253,749.74 942.934,807.213       ",
                pointsOut: "942.934,915.25 946.81,916.849 1045.41,859.55 1045.41,712.14 1041.253,709.74 942.934,767.213       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col4item3_2",
                dataid: 40,
                fill: "#002B4B",
                pointsIn: "916.81,792.13 916.81,939.531 946.81,956.849 946.81,809.451 952.436,806.182 921.984,789.123       ",
                pointsOut: "916.81,752.13 916.81,899.531 946.81,916.849 946.81,769.451 952.436,766.182 921.984,749.123       ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item3_2",
                dataid: 40,
                posX: "0",
                fill: "#09375A",
                pointsIn: "1015.42,734.83 972.84,759.57 972.89,759.541 937.65,780.021 916.81,792.13 946.81,809.451     1045.41,752.14   ",
                pointsOut: "1015.42,694.83 972.84,719.57 972.89,719.541 937.65,740.021 916.81,752.13 946.81,769.451     1045.41,712.14   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col4item6_1",
                dataid: 40,
                fill: "#063356",
                pointsIn: "1137.62,887.91 1066.2,929.72 1065.5,783.72 1137.62,743.47   ",
                pointsOut: "1137.62,847.91 1066.2,889.72 1065.5,743.72 1137.62,703.47   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col4item6_1",
                dataid: 40,
                fill: "#002B4B",
                pointsIn: "1066.26,784.179 1073.25,780.26 1037.239,761.772 1031.65,765.021 1031.65,911.72 1066.2,929.72       ",
                pointsOut: "1066.26,744.179 1073.25,740.26 1037.239,721.772 1031.65,725.021 1031.65,871.72 1066.2,889.72       ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item6_1",
                dataid: 40,
                posX: "0",
                fill: "#09375A",
                pointsIn: "1141.88,745.929 1103.07,723.521 1031.65,765.021 1072.075,786.971   ",
                pointsOut: "1141.88,705.929 1103.07,683.521 1031.65,725.021 1072.075,746.971   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col4item5",
                dataid: 40,
                fill: "#063356",
                pointsIn: "1059.42,784.9 1059.42,933 981.9,978.041 977,974.971 977.422,827.356 1053.199,781.308   ",
                pointsOut: "1059.42,744.9 1059.42,893 981.9,938.041 977,934.971 977.422,787.356 1053.199,741.308   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col4item5",
                dataid: 40,
                fill: "#002B4B",
                pointsIn: "981.9,829.941 981.9,978.041 951.03,960.22 951.03,812.121 957.71,808.239 987.224,826.849   ",
                pointsOut: "981.9,789.941 981.9,938.041 951.03,920.22 951.03,772.121 957.71,768.239 987.224,786.849   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item5",
                dataid: 40,
                posX: "0",
                fill: "#09375A",
                pointsIn: "1059.42,784.9 981.9,829.941 951.03,812.121 1028.55,767.07   ",
                pointsOut: "1059.42,744.9 981.9,789.941 951.03,772.121 1028.55,727.07   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col4item6_2",
                dataid: 40,
                fill: "#063356",
                pointsIn: "1012.083,994.971 1013.25,996.4 1166.68,908.839 1166.68,760.25 1165.75,759.71 1013.08,846.009       ",
                pointsOut: "1012.083,954.971 1013.25,956.4 1166.68,868.839 1166.68,720.25 1165.75,719.71 1013.08,806.009       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col4item6_2",
                dataid: 40,
                fill: "#002B4B",
                pointsIn: "985.68,831.89 985.68,980.479 1014.63,997.191 1014.63,848.599 1019.539,845.746 990.65,829.001       ",
                pointsOut: "985.68,791.89 985.68,940.479 1014.63,957.191 1014.63,808.599 1019.539,805.746 990.65,789.001       ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item6_2",
                dataid: 40,
                posX: "0",
                fill: "#09375A",
                pointsIn: "1166.68,760.25 1137.62,743.47 1066.26,785 1066.26,785 1062.34,787.349 1031.54,805.24     1031.54,805.24 985.68,831.89 1014.63,848.599   ",
                pointsOut: "1166.68,720.25 1137.62,703.47 1066.26,745 1066.26,745 1062.34,747.349 1031.54,765.24     1031.54,765.24 985.68,791.89 1014.63,808.599   ",
                side: "top",
                cat: "inactive",
            },

            // 42
            {
                className: "row2col4item7 ",
                dataid: 42,
                fill: "#063356",
                pointsIn: "1262.72,815.701 1262.72,966.281 1166.68,1021.849 1165.25,866.971 1258.644,813.348   ",
                pointsOut: "1262.72,775.701 1262.72,926.281 1166.68,981.849 1165.25,826.971 1258.644,773.348   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col4item7 ",
                dataid: 42,
                fill: "#002B4B",
                pointsIn: "1171.752,868.564 1167.99,870.75 1167.99,1021.33 1118.97,993.81 1115.219,845.383     1112.125,843.229 1118.97,838.846   ",
                pointsOut: "1171.752,828.564 1167.99,830.75 1167.99,981.33 1118.97,953.81 1115.219,805.383     1112.125,803.229 1118.97,798.846   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item7 ",
                dataid: 42,
                fill: "#063356",
                pointsIn: "1118.97,843.229 1118.97,993.81 1061,1026.521 1058.5,870.346 1114.125,839.971   ",
                pointsOut: "1118.97,803.229 1118.97,953.81 1061,986.521 1058.5,830.346 1114.125,799.971   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col4item7 ",
                dataid: 42,
                fill: "#002B4B",
                pointsIn: "1066.224,873.512 1061.99,875.941 1061.99,1026.521 1019.271,1001.861 1019.271,851.281     1023.098,849.057   ",
                pointsOut: "1066.224,833.512 1061.99,835.941 1061.99,986.521 1019.271,961.861 1019.271,811.281     1023.098,809.057   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item7 ",
                dataid: 42,
                fill: "#09375A",
                pointsIn: "1262.72,815.701 1167.99,870.75 1118.97,843.229 1061.99,875.941 1019.271,851.281     1076.14,818.229 1171.31,762.919   ",
                pointsOut: "1262.72,775.701 1167.99,830.75 1118.97,803.229 1061.99,835.941 1019.271,811.281     1076.14,778.229 1171.31,722.919   ",
                side: "top",
                cat: "inactive",
            },

            // 43
            {
                className: "row2col4item8",
                dataid: 43,
                fill: "#008F94",
                pointsIn: "1164.01,873.06 1163.89,1024.72 1110.5,1055.339 1107.625,897.721 1160.231,870.878   ",
                pointsOut: "1164.01,833.06 1163.89,984.72 1110.5,1015.339 1107.625,857.721 1160.231,830.878   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col4item8",
                dataid: 43,
                fill: "#006F73",
                pointsIn: "1111.31,903.691 1111.19,1055.339 1067.39,1030.05 1067.51,878.4 1072.075,875.941     1116.955,900.41   ",
                pointsOut: "1111.31,863.691 1111.19,1015.339 1067.39,990.05 1067.51,838.4 1072.075,835.941     1116.955,860.41   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col4item8",
                dataid: 43,
                posX: "0",
                fill: "#009BA1",
                pointsIn: "1164.01,873.06 1111.31,903.691 1067.51,878.4 1120.21,847.771   ",
                pointsOut: "1164.01,833.06 1111.31,863.691 1067.51,838.4 1120.21,807.771   ",
                side: "top",
                cat: "inactive",
            },

            { 	className: "row2_3_divider", 
                    dataid: "inactive",
                    fill: "#FFFFFF", 
                    pointsIn: "878.66,789.99 878.979,840.691 1147.85,997.56 1147.53,946.849   ", 
                    cat: "inactive",
            },

            // 30
            {
                className: "row2col3item5_2",
                dataid: 30,
                fill: "#B51F27",
                pointsIn: "881.86,1021.771 813.91,978.5 813.91,808.929 881.86,848.16   ",
                pointsOut: "881.86,981.771 813.91,938.5 813.91,768.929 881.86,808.16   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col3item5_2",
                dataid: 30,
                fill: "#B51F27",
                pointsIn: "920.65,825.611 920.65,999.97 881.86,1021.771 878.66,1019.732 878,845.932 917.529,823.81   ",
                pointsOut: "920.65,785.611 920.65,959.97 881.86,981.771 878.66,979.732 878,805.932 917.529,783.81   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col3item5_2",
                dataid: 30,
                fill: "#C5232C",
                pointsIn: "920.65,825.611 852.7,786.39 813.91,808.929 881.86,848.16   ",
                pointsOut: "920.65,785.611 852.7,746.39 813.91,768.929 881.86,808.16   ",
                side: "top",
                cat: "inactive",
            },

            {
                className: "row2col3item6",
                dataid: 31,
                fill: "#bbbbbb",
                pointsIn: "942.11,838.259 942.11,989.72 906.84,1010.21 904.998,1008.767 904.998,857.686 940.427,837.287       ",
                pointsOut: "942.11,798.259 942.11,949.72 906.84,970.21 904.998,968.767 904.998,817.686 940.427,797.287       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col3item6",
                dataid: 31,
                fill: "#888888",
                pointsIn: "906.84,858.75 906.84,1010.21 889.86,1000.41 889.86,848.941 891.493,847.992 908.389,857.85       ",
                pointsOut: "906.84,818.75 906.84,970.21 889.86,960.41 889.86,808.941 891.493,807.992 908.389,817.85   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col3item6",
                dataid: 31,
                posX: "0",
                fill: "#cccccc",
                pointsIn: "942.11,838.259 906.84,858.75 889.86,848.941 925.12,828.451   ",
                pointsOut: "942.11,798.259 906.84,818.75 889.86,808.941 925.12,788.451   ",
                side: "top",
                cat: "inactive",
            },

            // 30
            {
                className: "row2col3item5_1",
                dataid: 30,
                fill: "#B51F27",
                pointsIn: "878.3,1024.861 903.9,1009.979 903.9,860.88 878.3,875.759   ",
                pointsOut: "878.3,984.861 903.9,969.979 903.9,820.88 878.3,835.759   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col3item5_1",
                dataid: 30,
                fill: "#8A1016",
                pointsIn: "788.31,823.81 788.31,972.9 878.3,1024.861 878.3,875.759   ",
                pointsOut: "788.31,783.81 788.31,932.9 878.3,984.861 878.3,835.759   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col3item5_1",
                dataid: 30,
                posX: "0",
                fill: "#C5232C",
                pointsIn: "889.537,843.698 824.489,802.782 813.91,808.929 788.31,823.81 878.3,875.759 903.9,860.88     881.86,848.16   ",
                pointsOut: "889.537,803.698 824.489,762.782 813.91,768.929 788.31,783.81 878.3,835.759 903.9,820.88     881.86,808.16   ",
                side: "top",
                cat: "inactive",
            },

            // 29
            {
                className: "row2col3item4",
                dataid: 29,
                posX: "0",
                fill: "#0C9C54",
                pointsIn: "873.84,877.89 830.22,903.24 740.54,851.46 784.16,826.111   ",
                pointsOut: "873.84,837.89 830.22,863.24 740.54,811.46 784.16,786.11   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col3item4",
                dataid: 29,
                fill: "#006F39",
                pointsIn: "830.22,902.55 830.22,1051.38 740.54,999.599 740.54,851.46   ",
                pointsOut: "830.22,862.55 830.22,1011.38 740.54,959.6 740.54,811.46   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col3item4",
                dataid: 29,
                fill: "#04904D",
                pointsIn: "873.84,877.89 873.84,1026.031 829,1051.339 829,901.849   ",
                pointsOut: "873.84,837.89 873.84,986.03 829,1011.34 829,861.85   ",
                side: "front",
                cat: "inactive",
            },

            // 28
            {
                className: "row2col3item1",
                dataid: 28,
                posX: "0",
                fill: "#1C3265",
                pointsIn: "759.66,866.951 703.78,899.429 679.95,885.669 735.83,853.201    ",
                pointsOut: "759.66,826.95 703.78,859.43 679.95,845.67 735.83,813.2    ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col3item1",
                dataid: 28,
                fill: "#0F214E",
                pointsIn: "703.78,899.429 703.72,1047.66 679.9,1033.91 679.95,885.669 680.54,885.33 704.34,899.099         ",
                pointsOut: "703.78,859.43 703.72,1007.66 679.9,993.91 679.95,845.67 680.54,845.33 704.34,859.1    ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col3item1",
                dataid: 28,
                fill: "#192D5F",
                pointsIn: "759.66,866.951 759.6,1015.191 703,1047.66 703.32,899.16 703.32,899.16 759.2,866.691    ",
                pointsOut: "759.66,826.95 759.6,975.19 703,1007.66 703.32,859.16 703.32,859.16 759.2,826.69    ",
                side: "front",
                cat: "inactive",
            },

            // 37
            {
                className: "row2col3item2",
                dataid: 37,
                posX: "0",
                fill: "#009BA1",
                pointsOut: "785.28,842.22 729.4,874.69 708.38,862.55 764.26,830.08   ",
                pointsIn: "785.28,882.22 729.4,914.691 708.38,902.55 764.26,870.08   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col3item2",
                dataid: 37,
                fill: "#006F73",
                pointsOut: "729.4,874 729.29,1022.84 708.27,1010.71 708.38,862.55   ",
                pointsIn: "729.4,914 729.29,1062.839 708.271,1050.71 708.38,902.55   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col3item2",
                dataid: 37,
                fill: "#008F94",
                pointsOut: "785.28,842.22 785.17,990.37 727.83,1023.55 728.67,873.35   ",
                pointsIn: "785.28,882.22 785.17,1030.371 727.83,1063.55 728.67,913.349   ",
                side: "front",
                cat: "inactive",
            },

            // 36
            {
                className: "row2col3item3",
                dataid: 36,
                posX: "0",
                fill: "#583614",
                pointsIn: "826.07,905.771 770.19,938.24 733.46,917.031 789.34,884.56   ",
                pointsOut: "826.07,865.77 770.19,898.24 733.46,877.03 789.34,844.56   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col3item3",
                dataid: 36,
                fill: "#462C15",
                pointsIn: "770.19,937.64 770.19,1085.75 733.46,1064.55 733.46,917.031   ",
                pointsOut: "770.19,897.64 770.19,1045.75 733.46,1024.55 733.46,877.03   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col3item3",
                dataid: 36,
                fill: "#4F3117",
                pointsIn: "826.07,905.771 826.07,1053.281 768.67,1085.75 769.5,936.72   ",
                pointsOut: "826.07,865.77 826.07,1013.28 768.67,1045.75 769.5,896.72   ",
                side: "front",
                cat: "inactive",
            },
            
            // 32
            {
                className: "row2col2item10",
                dataid: 32,
                fill: "#3B195F",
                pointsIn: "955.39,886.85 955.39,1035.88 911.07,1010.21 911.07,861.18 914.52,859.17 957.71,885.42   ",
                pointsOut: "955.39,846.85 955.39,995.88 911.07,970.21 911.07,821.18 914.52,819.17 957.71,845.42   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item10",
                dataid: 32,
                fill: "#4F2C7C",
                pointsIn: "990.65,866.28 990.65,1015.31 955.39,1035.8 950.4,1032.99 948.92,887.89 944.09,876.97     980.01,860.14   ",
                pointsOut: "990.65,826.28 990.65,975.31 955.39,995.8 950.4,992.99 948.92,847.89 944.09,836.97     980.01,820.14   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item10",
                dataid: 32,
                posX: "0",
                fill: "#563285",
                pointsIn: "990.65,866.28 955.39,886.77 911.07,861.18 946.33,840.69   ",
                pointsOut: "990.65,826.28 955.39,846.77 911.07,821.18 946.33,800.69   ",
                side: "top",
                cat: "inactive",
            },

            // 33
            {
                className: "row2col2item9",
                dataid: 33,
                fill: "#0F214E",
                pointsIn: "899.98,918.97 899.98,1068.22 855.66,1042.63 855.66,893.38   ",
                pointsOut: "899.98,878.97 899.98,1028.22 855.66,1002.63 855.66,853.38   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item9",
                dataid: 33,
                fill: "#192D5F",
                pointsIn: "951.53,889.02 951.53,1038.26 899.98,1068.22 894.5,1065.78 895.75,921.08 897.21,917.37     946.59,886.17   ",
                pointsOut: "951.53,849.02 951.53,998.26 899.98,1028.22 894.5,1025.78 895.75,881.08 897.21,877.37     946.59,846.17   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item9",
                dataid: 33,
                posX: "0",
                fill: "#1C3265",
                pointsIn: "951.53,889.02 899.98,918.97 855.66,893.38 907.21,863.43   ",
                pointsOut: "951.53,849.02 899.98,878.97 855.66,853.38 907.21,823.43   ",
                side: "top",
                cat: "inactive",
            },

            // 43
            {
                className: "row2col2item8",
                dataid: 34,
                fill: "#002B4B",
                pointsIn: "846.11,947.93 845.85,1098.69 801.52,1073.1 801.53,924.84   ",
                pointsOut: "846.11,907.93 845.85,1058.69 801.52,1033.1 801.53,884.84   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item8",
                dataid: 34,
                fill: "#063356",
                pointsIn: "896.05,921.25 896.05,1069.52 844.25,1098.69 844.25,947.93   ",
                pointsOut: "896.05,881.25 896.05,1029.52 844.25,1058.69 844.25,907.93   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item8",
                dataid: 34,
                posX: "0",
                fill: "#09375A",
                pointsIn: "896.05,921.25 845.85,950.43 801.53,924.84 851.73,895.67   ",
                pointsOut: "896.05,881.25 845.85,910.43 801.53,884.84 851.73,855.67   ",
                side: "top",
                cat: "inactive",
            },

            // 35
            {
                className: "row2col3item7",
                dataid: 35,
                fill: "#832A61",
                pointsIn: "810.71,961.63 810.71,1109.72 774.34,1088.72 774.34,940.64   ",
                pointsOut: "810.71,921.63 810.71,1069.72 774.34,1048.72 774.34,900.64   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col3item7",
                dataid: 35,
                fill: "#9B3674",
                pointsIn:
                    "M818.47,1108.99c0.25-0.01,0.49-0.03,0.73-0.04c1.25-0.05,2.52-0.07,3.81-0.07c0.28,0,0.57,0.01,0.85,0.01    c1.3,0.01,2.61,0.04,3.95,0.1l0.44-0.26V960.66l-0.44,0.25c-0.8-0.04-1.61-0.07-2.4-0.09c-0.52-0.01-1.03-0.01-1.55-0.02    c-0.28,0-0.57-0.01-0.85-0.01c-1.14,0-2.27,0.01-3.38,0.05c-0.14,0.01-0.29,0.02-0.43,0.02c-0.25,0.01-0.49,0.03-0.73,0.04    c-1.11,0.05-2.2,0.12-3.27,0.22c-0.21,0.02-0.43,0.03-0.64,0.05c-0.11,0.01-0.23,0.02-0.34,0.03c-1.19,0.12-2.36,0.26-3.51,0.43    l-0.5-0.28v148.08l0.5,0.28c1.25-0.19,2.54-0.34,3.84-0.46c0.21-0.02,0.43-0.03,0.64-0.05    C816.27,1109.11,817.36,1109.04,818.47,1108.99z",
                pointsOut:
                    "M818.47,1068.99c0.25-0.01,0.49-0.03,0.73-0.04c1.25-0.05,2.52-0.07,3.81-0.07c0.28,0,0.57,0.01,0.85,0.01    c1.3,0.01,2.61,0.04,3.95,0.1l0.44-0.26V920.66l-0.44,0.25c-0.8-0.04-1.61-0.07-2.4-0.09c-0.52-0.01-1.03-0.01-1.55-0.02    c-0.28,0-0.57-0.01-0.85-0.01c-1.14,0-2.27,0.01-3.38,0.05c-0.14,0.01-0.29,0.02-0.43,0.02c-0.25,0.01-0.49,0.03-0.73,0.04    c-1.11,0.05-2.2,0.12-3.27,0.22c-0.21,0.02-0.43,0.03-0.64,0.05c-0.11,0.01-0.23,0.02-0.34,0.03c-1.19,0.12-2.36,0.26-3.51,0.43    l-0.5-0.28v148.08l0.5,0.28c1.25-0.19,2.54-0.34,3.84-0.46c0.21-0.02,0.43-0.03,0.64-0.05    C816.27,1069.11,817.36,1069.04,818.47,1068.99z",
                side: "side",
                isPath: !0,
                cat: "inactive",
            },
            {
                className: "row2col3item7",
                dataid: 35,
                fill: "#AA3E80",
                pointsIn: "841.69,952.85 841.69,1100.93 827.81,1108.99 827.81,960.91   ",
                pointsOut: "841.69,912.85 841.69,1060.93 827.81,1068.99 827.81,920.91   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col3item7",
                dataid: 35,
                posX: "0",
                fill: "#B9448A",
                pointsIn: "M841.69,952.85l-13.88,8.07c-6.08-0.29-11.85-0.06-17.1,0.72l-36.37-21l23.03-13.38L841.69,952.85z",
                pointsOut: "M841.69,912.85l-13.88,8.07c-6.08-0.29-11.85-0.06-17.1,0.72l-36.37-21l23.03-13.38L841.69,912.85z",
                side: "top",
                isPath: !0,
                cat: "inactive",
            },
            
            { 
                className: "row2_2_divider2", 
                dataid: "devider",
                fill: "#FFFFFF", 
                pointsIn: "997.15,886.17 997.11,951.42 814.89,1053.85 815.01,993.87  ", 
                cat: "inactive",
            },

            // 26 !!! Test
            {
                className: "row2col2item14",
                dataid: 26,
                fill: "#bbbbbb",
                pointsIn: "1081.28,919.73 1081.28,1069.429 997,1117.97 997,966.55 1080.34,919.179   ",
                pointsOut: "1081.28,879.73 1081.28,1029.429 997,1077.97 997,926.55 1080.34,879.179   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item14",
                dataid: 26,
                fill: "#a7a7a7",
                pointsIn: "998.9,967.599 997.75,968.271 997.75,1117.97 934.1,1081.22 934.1,931.531 935.16,930.91     998.81,966.66   ",
                pointsOut: "998.9,927.599 997.75,928.271 997.75,1077.97 934.1,1041.22 934.1,891.531 935.16,890.91     998.81,926.66   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item14",
                dataid: 26,
                posX: "0",
                fill: "#cccccc",
                pointsIn: "1081.28,919.73 997.75,968.271 934.1,931.531 1017.63,882.99   ",
                pointsOut: "1081.28,879.73 997.75,928.271 934.1,891.531 1017.63,842.99   ",
                side: "top",
                cat: "inactive",
            },

            // 25
            {
                className: "row2col2item13",
                dataid: 25,
                fill: "#063356",
                pointsIn: "993.51,970.74 993.51,1120.531 955.93,1142.371 955.93,992.58   ",
                pointsOut: "993.51,930.74 993.51,1080.531 955.93,1102.371 952.404,1100.335 952.404,950.544     988.647,927.932   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item13",
                dataid: 25,
                fill: "#002B4B",
                pointsIn: "955.93,992.58 955.93,1142.371 892.28,1105.63 892.28,955.83 893.271,955.25 956.75,992.099   ",
                pointsOut: "955.93,952.58 955.93,1102.371 892.28,1065.63 892.28,915.83 893.271,915.25 956.75,952.099   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item13",
                dataid: 25,
                posX: "0",
                fill: "#09375A",
                pointsIn: "993.51,970.74 955.93,992.58 892.28,955.83 929.86,933.99   ",
                pointsOut: "993.51,930.74 955.93,952.58 892.28,915.83 929.86,893.99   ",
                side: "top",
                cat: "inactive",
            },

            // 24
            {
                className: "row2col2item11",
                dataid: 24,
                fill: "#842A62",
                pointsIn:
                    "M890.45,980.8c-0.15-0.09-0.29-0.18-0.44-0.26c-1.439-0.83-2.899-1.631-4.38-2.41    c-0.2-0.109-0.4-0.211-0.6-0.32c-1.41-0.74-2.83-1.449-4.26-2.141c-0.261-0.129-0.521-0.25-0.78-0.369    c-1.431-0.68-2.86-1.34-4.311-1.971c-0.239-0.1-0.47-0.199-0.71-0.309c-1.5-0.65-3.01-1.271-4.52-1.871    c-0.03-0.01-0.051-0.02-0.08-0.029c-0.88-0.35-1.771-0.691-2.66-1.021l0,0l0,0v151.771c0.91,0.34,1.82,0.689,2.73,1.049    c1.52,0.602,3.02,1.23,4.52,1.871c0.24,0.1,0.48,0.199,0.71,0.311c1.45,0.629,2.88,1.289,4.31,1.969    c0.261,0.121,0.521,0.25,0.78,0.371c1.431,0.689,2.851,1.398,4.261,2.139c0.199,0.1,0.399,0.211,0.59,0.32    c1.47,0.779,2.939,1.58,4.38,2.41c0.149,0.08,0.29,0.17,0.439,0.26l0,0l0,0L890.45,980.8z",
                pointsOut:
                    "M890.45,940.8c-0.15-0.09-0.29-0.18-0.44-0.26c-1.439-0.83-2.899-1.631-4.38-2.41    c-0.2-0.109-0.4-0.211-0.6-0.32c-1.41-0.74-2.83-1.449-4.26-2.141c-0.261-0.129-0.521-0.25-0.78-0.369    c-1.431-0.68-2.86-1.34-4.311-1.971c-0.239-0.1-0.47-0.199-0.71-0.309c-1.5-0.65-3.01-1.271-4.52-1.871    c-0.03-0.01-0.051-0.02-0.08-0.029c-0.88-0.35-1.771-0.691-2.66-1.021l0,0l0,0v151.771c0.91,0.34,1.82,0.689,2.73,1.049    c1.52,0.602,3.02,1.23,4.52,1.871c0.24,0.1,0.48,0.199,0.71,0.311c1.45,0.629,2.88,1.289,4.31,1.969    c0.261,0.121,0.521,0.25,0.78,0.371c1.431,0.689,2.851,1.398,4.261,2.139c0.199,0.1,0.399,0.211,0.59,0.32    c1.47,0.779,2.939,1.58,4.38,2.41c0.149,0.08,0.29,0.17,0.439,0.26l0,0l0,0L890.45,940.8z",
                side: "side",
                isPath: !0,
                cat: "inactive",
            },
            {
                className: "row2col2item11",
                dataid: 24,
                fill: "#AA3E80",
                pointsIn: "908.74,970.169 908.74,1121.941 890.45,1132.57 888.23,1131.317 888.23,979.535 906.479,968.864       ",
                pointsOut: "908.74,930.169 908.74,1081.941 890.45,1092.57 888.23,1091.317 888.23,939.535 906.479,928.864       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item11",
                dataid: 24,
                fill: "#B9448A",
                pointsIn: "M908.74,970.17l-18.29,10.63c-0.15-0.09-0.29-0.18-0.44-0.26c-7.08-4.09-14.61-7.58-22.29-10.44    l20.38-11.85L908.74,970.17z",
                pointsOut: "M908.74,930.169L890.45,940.8c-0.15-0.09-0.29-0.18-0.44-0.26c-7.08-4.09-14.609-7.58-22.29-10.441    l20.381-11.85L908.74,930.169z",
                side: "top",
                isPath: !0,
                cat: "inactive",
            },

            // 23
            {
                className: "row2col2item12",
                dataid: 23,
                fill: "#842A62",
                pointsIn:
                    "M912.19,997.32c-1.19-1.23-2.461-2.449-3.811-3.66c-1.35-1.219-2.79-2.43-4.3-3.629    c-3.03-2.4-6.38-4.76-10.04-7.051l0,0l0,0v151.381c14.63,9.17,24.32,19.439,28.28,28.93V1011.91    C920.35,1007.16,916.93,1002.22,912.19,997.32z",
                pointsOut:
                    "M912.19,957.32c-1.19-1.23-2.461-2.449-3.811-3.66c-1.35-1.219-2.79-2.43-4.3-3.629    c-3.03-2.4-6.38-4.76-10.04-7.051l0,0l0,0v151.381c14.63,9.17,24.32,19.439,28.28,28.93V971.91    C920.35,967.16,916.93,962.22,912.19,957.32z",
                side: "side",
                isPath: !0,
                cat: "inactive",
            },
            {
                className: "row2col2item12",
                dataid: 23,
                fill: "#AA3E80",
                pointsIn: "951.58,994.91 951.58,1146.281 922.32,1163.281 922.32,1011.91   ",
                pointsOut: "951.58,954.91 951.58,1106.281 922.32,1123.281 922.32,971.91   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item12",
                dataid: 23,
                fill: "#B9448A",
                pointsIn: "M951.58,994.91l-29.25,17c-3.95-9.49-13.65-19.76-28.28-28.93l18.38-10.68L951.58,994.91z",
                pointsOut: "M951.58,954.91l-29.25,17c-3.95-9.49-13.65-19.76-28.28-28.93l18.38-10.68L951.58,954.91z",
                side: "top",
                isPath: !0,
                cat: "inactive",
            },

            { 	className: "row2_2_divider", 
                    dataid: "inactive",
                    fill: "#FFFFFF", 
                    pointsIn: "M663.01,904.77l0.32,50.71l241.82,141.04l17.17-60.88   C912,1012.13,841,972.3,810.71,990.92L663.01,904.77z",
                    isPath: !0, 
                    cat: "inactive",
            },

            // 16
            {
                className: "row2col2item7",
                dataid: 16,
                fill: "#006F39",
                pointsIn: "649.5,920.32 649.5,1069.52 633.64,1060.36 633.64,911.16 633.83,911.05 649.72,920.19   ",
                pointsOut: "649.5,880.32 649.5,1029.52 633.64,1020.36 633.64,871.16 633.83,871.05 649.73,880.19   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item7",
                dataid: 16,
                fill: "#04904D",
                pointsIn: "673.26,906.51 673.26,1055.7 649.5,1069.52 649.5,920.32 649.15,920.12 672.97,906.34   ",
                pointsOut: "673.26,866.51 673.26,1015.7 649.5,1029.52 649.5,880.32 649.15,880.12 672.97,866.34   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item7",
                dataid: 16,
                posX: "0",
                fill: "#0C9C54",
                pointsIn: "673.26,906.51 649.5,920.32 633.64,911.16 657.4,897.35   ",
                pointsOut: "673.26,866.51 649.5,880.32 633.64,871.16 657.4,857.35   ",
                side: "top",
                cat: "inactive",
            },

            // 17
            {
                className: "row2col2item6",
                dataid: 17,
                posX: "0",
                fill: "#009BA1",
                pointsIn: "691.78,917.2 668.01,931.01 652.16,921.86 675.93,908.04   ",
                pointsOut: "691.78,877.2 668.01,891.01 652.16,881.86 675.93,868.04   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col2item6",
                dataid: 17,
                fill: "#006F73",
                pointsIn: "668.01,931.01 668.01,1078.07 652.16,1068.92 652.16,921.86 652.46,921.69 668.28,930.85   ",
                pointsOut: "668.01,891.01 668.01,1038.07 652.16,1028.92 652.16,881.86 652.46,881.69 668.28,890.85   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item6",
                dataid: 17,
                fill: "#008F94",
                pointsIn: "691.78,917.2 691.78,1064.26 668.01,1078.07 668.01,931.01 667.61,930.78 691.42,916.99   ",
                pointsOut: "691.78,877.2 691.78,1024.26 668.01,1038.07 668.01,891.01 667.61,890.78 691.42,876.99   ",
                side: "front",
                cat: "inactive",
            },

            // 15
            {
                className: "row2col2item5",
                dataid: 15,
                posX: "0",
                fill: "#cccccc",
                pointsIn: "663.88,933.2 646.92,943.05 612.55,923.2 629.5,913.35   ",
                pointsOut: "663.88,893.2 646.92,903.05 612.55,883.2 629.5,873.35   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col2item5",
                dataid: 15,
                fill: "#888888",
                pointsIn: "646.92,943.05 646.92,1090.92 612.55,1071.08 612.55,923.2 612.89,923 647.37,942.79   ",
                pointsOut: "646.92,903.05 646.92,1050.92 612.55,1031.08 612.55,883.2 612.89,883 647.37,902.79   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item5",
                dataid: 15,
                fill: "#bbbbbb",
                pointsIn: "663.88,933.2 663.88,1081.07 646.92,1090.92 646.92,943.05   ",
                pointsOut: "663.88,893.2 663.88,1041.07 646.92,1050.92 646.92,903.05   ",
                side: "front",
                cat: "inactive",
            },

            // 14
            {
                className: "row2col2item4",
                dataid: 14,
                posX: "0",
                fill: "#B9448A",
                pointsIn: "642.79,945.67 625.84,955.52 591.46,935.67 608.42,925.82   ",
                pointsOut: "642.79,905.67 625.84,915.52 591.46,895.67 608.42,885.82   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col2item4",
                dataid: 14,
                fill: "#832A61",
                pointsIn: "625.84,955.52 625.84,1103.42 591.46,1083.58 591.46,935.67 592.15,935.27 626.32,955.24   ",
                pointsOut: "625.84,915.52 625.84,1063.42 591.46,1043.58 591.46,895.67 592.15,895.27 626.32,915.24   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item4",
                dataid: 14,
                fill: "#AA3E80",
                pointsIn: "642.79,945.67 642.79,1093.57 625.84,1103.42 625.84,955.52   ",
                pointsOut: "642.79,905.67 642.79,1053.57 625.84,1063.42 625.84,915.52   ",
                side: "front",
                cat: "inactive",
            },

            // 13
            {
                className: "row2col2item3",
                dataid: 13,
                posX: "0",
                fill: "#D38027",
                pointsOut: "621.71,917.92 604.75,927.77 570.38,907.93 587.33,898.07   ",
                pointsIn: "621.71,957.92 604.75,967.77 570.38,947.93 587.33,938.07   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col2item3",
                dataid: 13,
                fill: "#935912",
                pointsOut: "604.75,927.77 604.75,1075.22 570.38,1055.38 570.38,907.93 571.07,907.53 605.45,927.36   ",
                pointsIn: "604.75,967.77 604.75,1115.22 570.38,1095.38 570.38,947.93 571.07,947.53 605.45,967.36   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item3",
                dataid: 13,
                fill: "#C17622",
                pointsOut: "621.71,917.92 621.71,1065.37 604.75,1075.22 604.75,927.77   ",
                pointsIn: "621.71,957.92 621.71,1105.37 604.75,1115.22 604.75,967.77   ",
                side: "front",
                cat: "inactive",
            },

            // 12
            {
                className: "row2col2item2",
                dataid: 12,
                posX: "0",
                fill: "#cccccc",
                pointsIn: "600.62,970.18 583.67,980.03 549.29,960.18 566.24,950.33   ",
                pointsOut: "600.62,930.18 583.67,940.03 549.29,920.18 566.24,910.33   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col2item2",
                dataid: 12,
                fill: "#888888",
                pointsIn: "583.67,980.03 583.67,1127.22 549.29,1107.37 549.29,960.18 549.93,959.8 584.06,979.8   ",
                pointsOut: "583.67,940.03 583.67,1087.22 549.29,1067.37 549.29,920.18 549.93,919.8 584.06,939.8   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item2",
                dataid: 12,
                fill: "#bbbbbb",
                pointsIn: "600.62,970.18 600.62,1117.37 583.67,1127.22 583.67,980.03   ",
                pointsOut: "600.62,930.18 600.62,1077.37 583.67,1087.22 583.67,940.03   ",
                side: "front",
                cat: "inactive",
            },

            // 18
            {
                className: "row2col2item11",
                dataid: 18,
                posX: "0",
                fill: "#B9448A",
                pointsIn: "M787.35,973.26c-2.1,3.75-2.65,8-1.77,12.54l-19.61,11.4L708.46,964l-3.64,2.12l-22.98,13.35l-23.5-13.57    l-2.89-1.67l57.93-33.67L787.35,973.26z",
                pointsOut: "M787.35,933.26c-2.1,3.75-2.65,8-1.77,12.54l-19.61,11.4L708.46,924l-3.65,2.12l-22.98,13.35l-23.5-13.57    l-2.89-1.67l57.93-33.67L787.35,933.26z",
                side: "top",
                isPath: !0,
                cat: "inactive",
            },
            {
                className: "row2col2item11",
                dataid: 18,
                fill: "#832A61",
                pointsIn: "765.97,997.19 765.97,1146.72 708.47,1113.52 708.47,963.99 708.27,962.6 767.49,996.32   ",
                pointsOut: "765.97,957.19 765.97,1106.72 708.47,1073.52 708.47,923.99 708.27,922.6 767.48,956.32   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item11",
                dataid: 18,
                fill: "#AA3E80",
                pointsIn: "785.59,985.8 785.59,1135.32 765.97,1146.72 765.97,997.19 764.31,996.24 785.38,984.58   ",
                pointsOut: "785.59,945.8 785.59,1095.32 765.97,1106.72 765.97,957.19 764.31,956.24 785.38,944.58   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item11",
                dataid: 18,
                posX: "0",
                fill: "#8A2E67",
                pointsIn:
                    "M785.31,1129.11c0.05-0.44,0.12-0.88,0.21-1.31c0.02-0.11,0.05-0.22,0.07-0.33    c0.06-0.29,0.13-0.59,0.21-0.88c0.04-0.14,0.08-0.28,0.12-0.42c0.08-0.27,0.17-0.53,0.26-0.8c0.05-0.13,0.1-0.27,0.15-0.41    c0.1-0.25,0.21-0.5,0.32-0.75c0.05-0.12,0.1-0.24,0.16-0.36c0.17-0.36,0.35-0.72,0.55-1.08V973.26c-0.2,0.36-0.38,0.71-0.55,1.08    c-0.06,0.12-0.1,0.24-0.16,0.36c-0.11,0.25-0.22,0.5-0.32,0.75c-0.02,0.05-0.05,0.1-0.06,0.15c-0.03,0.08-0.05,0.17-0.08,0.25    c-0.09,0.26-0.18,0.53-0.26,0.8c-0.04,0.14-0.08,0.28-0.12,0.42c-0.08,0.29-0.15,0.58-0.21,0.88c-0.02,0.09-0.05,0.17-0.06,0.26    c-0.01,0.02-0.01,0.05-0.01,0.08c-0.08,0.43-0.15,0.87-0.21,1.31c-0.01,0.1-0.02,0.21-0.03,0.31c-0.03,0.35-0.06,0.71-0.07,1.06    c0,0.07-0.01,0.14-0.02,0.22c0,0.17-0.01,0.33-0.01,0.5v149.53c0-0.24,0.01-0.48,0.02-0.72c0.02-0.35,0.04-0.71,0.07-1.06    C785.29,1129.32,785.29,1129.22,785.31,1129.11z",
                pointsOut:
                    "M785.31,1089.11c0.05-0.44,0.12-0.88,0.21-1.31c0.02-0.11,0.05-0.22,0.07-0.33    c0.06-0.29,0.13-0.59,0.21-0.88c0.04-0.14,0.08-0.28,0.12-0.42c0.08-0.27,0.17-0.53,0.26-0.8c0.05-0.13,0.1-0.27,0.15-0.41    c0.1-0.25,0.21-0.5,0.32-0.75c0.05-0.12,0.1-0.24,0.16-0.36c0.17-0.36,0.35-0.72,0.55-1.08V933.26c-0.2,0.36-0.38,0.71-0.55,1.08    c-0.06,0.12-0.1,0.24-0.16,0.36c-0.11,0.25-0.22,0.5-0.32,0.75c-0.02,0.05-0.05,0.1-0.06,0.15c-0.03,0.08-0.05,0.17-0.08,0.25    c-0.09,0.26-0.18,0.53-0.26,0.8c-0.04,0.14-0.08,0.28-0.12,0.42c-0.08,0.29-0.15,0.58-0.21,0.88c-0.02,0.09-0.05,0.17-0.06,0.26    c-0.01,0.02-0.01,0.05-0.01,0.08c-0.08,0.43-0.15,0.87-0.21,1.31c-0.01,0.1-0.02,0.21-0.03,0.31c-0.03,0.35-0.06,0.71-0.07,1.06    c0,0.07-0.01,0.14-0.02,0.22c0,0.17-0.01,0.33-0.01,0.5v149.53c0-0.24,0.01-0.48,0.02-0.72c0.02-0.35,0.04-0.71,0.07-1.06    C785.29,1089.32,785.29,1089.22,785.31,1089.11z",
                side: "front",
                isPath: !0,
                cat: "inactive",
            },
            {
                className: "row2col2item11",
                dataid: 18,
                fill: "#832A61",
                pointsIn: "681.85,979.46 681.85,1128.99 655.46,1113.75 655.46,964.22 657.08,963.29 683.3,978.62   ",
                pointsOut: "739.96,972.13 739.96,1121.55 685.67,1090.21 685.66,940.78   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item11",
                dataid: 18,
                fill: "#832A61",
                pointsIn: "681.85,979.46 681.85,1128.99 655.46,1113.75 655.46,964.22 657.08,963.29 683.3,978.62   ",
                pointsOut: "681.85,939.46 681.85,1088.99 655.46,1073.75 655.46,924.22 657.08,923.29 683.3,938.62   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item11",
                dataid: 18,
                fill: "#AA3E80",
                pointsIn: "708.47,963.99 708.47,1113.52 681.85,1128.99 681.85,979.46 680.39,978.63 708.27,962.6   ",
                pointsOut: "708.47,923.99 708.47,1073.52 681.85,1088.99 681.85,939.46 680.39,938.63 708.27,922.6   ",
                side: "front",
                cat: "inactive",
            },

            // 19
            {
                className: "row2col2item10",
                dataid: 19,
                posX: "0",
                fill: "#cccccc",
                pointsIn: "762.76,998.88 739.96,1012.13 685.66,980.78 708.46,967.53   ",
                pointsOut: "762.76,958.88 739.96,972.13 685.66,940.78 708.46,927.53   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col2item10",
                dataid: 19,
                fill: "#888888",
                pointsIn: "739.96,1012.13 739.96,1161.55 685.67,1130.21 685.66,980.78   ",
                pointsOut: "739.96,972.13 739.96,1121.55 685.67,1090.21 685.66,940.78   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item10",
                dataid: 19,
                fill: "#bbbbbb",
                pointsIn: "762.76,998.88 762.76,1148.31 739.96,1161.55 739.96,1012.13   ",
                pointsOut: "762.76,958.88 762.76,1108.31 739.96,1121.55 739.96,972.13   ",
                side: "front",
                cat: "inactive",
            },

            // 20
            {
                className: "row2col2item9",
                dataid: 20,
                posX: "0",
                fill: "#09375A",
                pointsIn: "735.05,1014.98 689.78,1041.29 588.2,982.64 633.47,956.34   ",
                pointsOut: "735.05,974.98 689.78,1001.29 588.2,942.64 633.47,916.34   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col2item9",
                dataid: 20,
                fill: "#002B4B",
                pointsIn: "689.78,1041.05 689.78,1189.72 588.2,1131.07 588.2,982.64 589.95,981.62 691.32,1040.5   ",
                pointsOut: "689.78,1001.05 689.78,1149.72 588.2,1091.07 588.2,942.64 589.95,941.62 691.32,1000.5   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item9",
                dataid: 20,
                fill: "#063356",
                pointsIn: "735.05,1014.98 735.05,1163.41 688.25,1189.72 689.63,1040.74   ",
                pointsOut: "735.05,974.98 735.05,1123.41 688.25,1149.72 689.63,1000.74   ",
                side: "front",
                cat: "inactive",
            },

            // 11
            {
                className: "row2col2item1",
                dataid: 11,
                posX: "0",
                fill: "#C0222B",
                pointsIn: "579.76,981.62 550.98,998.35 516.61,978.5 545.39,961.77   ",
                pointsOut: "579.76,941.62 550.98,958.35 516.61,938.5 545.39,921.77   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col2item1",
                dataid: 11,
                fill: "#8A0F16",
                pointsIn: "550.98,998.35 550.98,1146.72 516.61,1126.87 516.61,978.5 517.1,978.22 551.5,998.05   ",
                pointsOut: "550.98,958.35 550.98,1106.72 516.61,1086.87 516.61,938.5 517.1,938.22 551.5,958.05   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item1",
                dataid: 11,
                fill: "#B11E26",
                pointsIn: "579.76,981.62 579.76,1130 550.98,1146.72 550.25,997.19   ",
                pointsOut: "579.76,941.62 579.76,1090 550.98,1106.72 550.25,957.19   ",
                side: "front",
                cat: "inactive",
            },

            // 10
            {
                className: "row2col2item8",
                dataid: 10,
                posX: "0",
                fill: "#09375A",
                pointsIn: "685.44,1043.81 633.34,1074.09 492.86,992.98 511.47,982.16 550.38,1004.62 583.86,985.17   ",
                pointsOut: "685.44,1003.81 633.34,1034.09 492.86,952.98 511.47,942.16 550.38,964.62 583.86,945.17   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col2item8",
                dataid: 10,
                fill: "#002B4B",
                pointsIn: "633.34,1073.35 633.34,1224.22 492.86,1143.11 492.86,992.98 493.54,992.58 635.12,1072.41   ",
                pointsOut: "633.34,1033.35 633.34,1184.22 492.86,1103.11 492.86,952.98 493.54,952.58 635.12,1032.41   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item8",
                dataid: 10,
                fill: "#09375A",
                pointsIn: "685.44,1043.81 685.44,1193.94 632.33,1224.22 632.76,1073.75   ",
                pointsOut: "685.44,1003.81 685.44,1153.94 632.33,1184.22 632.76,1033.75   ",
                side: "front",
                cat: "inactive",
            },

            { 	className: "row2_1_divider_22", 
                    dataid: "inactive",
                    fill: "#FFFFFF", 
                    pointsIn: "809.966,995.255 809.926,1060.505 627.705,1162.935    627.825,1102.957  ", 
                    cat: "inactive",
            },

            // 21
            {
                className: "row2col2item13_1",
                dataid: 21,
                fill: "#4F3117",
                pointsIn: "680.39,1251.55 820.25,1170.941 819.65,1021.599 817.8,1020.509 678.35,1101.38 678.35,1250.697       ",
                pointsOut: "680.39,1211.55 820.25,1130.941 819.65,981.599 817.8,980.509 678.35,1061.38 678.35,1210.697       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item13_1",
                dataid: 21,
                fill: "#462C15",
                pointsIn: "658.51,1089.929 659.25,1239.72 681.33,1252.41 680.39,1102.56 684.732,1100.034 663.01,1087.315       ",
                pointsOut: "658.51,1049.929 659.25,1199.72 681.33,1212.41 680.39,1062.56 684.732,1060.034 663.01,1047.315       ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item13_1",
                dataid: 21,
                posX: "0",
                fill: "#583614",
                pointsIn: "M685.44,1105.477l98.575-57.816l-4.625-2.67l40.25-23.389c-7.67-4.472-14.119-9.25-19.27-14.111    l-141.86,82.44L685.44,1105.477z",
                pointsOut: "M685.44,1065.477l98.575-57.816l-4.625-2.67l40.25-23.389c-7.67-4.472-14.119-9.25-19.27-14.111    l-141.86,82.44L685.44,1065.477z",
                side: "top",
                isPath: !0,
                cat: "inactive",
            },

            // 22
            {
                className: "row2col2item14",
                dataid: 22,
                posX: "0",
                fill: "#B9448A",
                pointsIn: "M874.44,1040.5l-45.471,26.42l-39.16-22.609l34.57-20.09C840.26,1032.66,858.04,1038.25,874.44,1040.5z",
                pointsOut: "M874.44,1000.5l-45.47,26.42l-39.16-22.61l34.57-20.09C840.26,992.66,858.04,998.25,874.44,1000.5z",
                side: "top",
                isPath: !0,
                cat: "inactive",
            },
            {
                className: "row2col2item14",
                dataid: 22,
                fill: "#832A61",
                pointsIn: "828.97,1066.919 828.97,1216.24 789.81,1193.63 789.81,1044.31   ",
                pointsOut: "828.97,1026.92 828.97,1176.24 789.81,1153.63 789.81,1004.31   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item14",
                dataid: 22,
                fill: "#AA3E80",
                pointsIn: "874.44,1040.5 874.44,1189.82 827.75,1216.24 828.97,1066.919 827.229,1065.919 872.12,1040.16       ",
                pointsOut: "874.44,1000.5 874.44,1149.82 827.75,1176.24 828.97,1026.92 827.23,1025.92 872.12,1000.16   ",
                side: "front",
                cat: "inactive",
            },

            // 21
            {
                className: "row2col2item13_2",
                dataid: 21,
                fill: "#4F3117",
                pointsIn: "719.821,1273.889 723.67,1277.07 823.09,1219.521 823.15,1070.25 819.229,1067.986     719.821,1125.332   ",
                pointsOut: "719.821,1233.889 723.67,1237.07 823.09,1179.521 823.15,1030.25 819.229,1027.986     719.821,1085.332   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col2item13_2",
                dataid: 21,
                fill: "#462C15",
                pointsIn: "680.39,1102.56 680.45,1251.9 724.05,1277.07 724.11,1127.81 728.649,1125.172 684.732,1100.034       ",
                pointsOut: "680.39,1062.56 680.45,1211.9 724.05,1237.07 724.11,1087.81 728.649,1085.172 684.732,1060.034       ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col2item13_2",
                dataid: 21,
                posX: "0",
                fill: "#583614",
                pointsIn: "779.39,1044.99 680.39,1102.56 680.39,1102.56 724.11,1127.81 823.15,1070.25   ",
                pointsOut: "779.39,1004.99 680.39,1062.56 680.39,1062.56 724.11,1087.81 823.15,1030.25   ",
                side: "top",
                cat: "inactive",
            },

            { 	className: "row2_1_divider", 
                    dataid: "inactive",
                    fill: "#FFFFFF", 
                    pointsIn: "463.01,1003.96 463.34,1054.67 732.21,1211.53 731.89,1160.82  ", 
                    cat: "inactive",
            },

            // 5
            {
                className: "row2col1item5",
                dataid: 5,
                posX: "0",
                fill: "#B9448A",
                pointsIn: "497.23,1026.6 473.46,1040.41 443.45,1023.09 467.22,1009.27   ",
                pointsOut: "497.23,986.6 473.46,1000.41 443.45,983.09 467.22,969.27   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col1item5",
                dataid: 5,
                fill: "#832A61",
                pointsIn: "473.46,1040.41 473.46,1188.89 443.45,1171.56 443.45,1023.09   ",
                pointsOut: "473.46,1000.41 473.46,1148.89 443.45,1131.56 443.45,983.09   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col1item5",
                dataid: 5,
                fill: "#AA3E80",
                pointsIn: "497.23,1026.6 497.23,1175.08 473.46,1188.89 473.46,1040.41   ",
                pointsOut: "497.23,986.6 497.23,1135.08 473.46,1148.89 473.46,1000.41   ",
                side: "front",
                cat: "inactive",
            },

            // 4
            {
                className: "row2col1item4",
                dataid: 4,
                posX: "0",
                fill: "#009BA1",
                pointsIn: "469.33,1042.81 452.37,1052.67 422.36,1035.34 439.32,1025.49   ",
                pointsOut: "469.33,1002.81 452.37,1012.67 422.36,995.34 439.32,985.49   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col1item4",
                dataid: 4,
                fill: "#006F73",
                pointsIn: "452.37,1052.67 452.37,1201.22 422.36,1183.9 422.36,1035.34   ",
                pointsOut: "452.37,1012.67 452.37,1161.22 422.36,1143.9 422.36,995.34   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col1item4",
                dataid: 4,
                fill: "#008F94",
                pointsIn: "469.33,1042.81 469.33,1191.37 452.37,1201.22 452.37,1052.67   ",
                pointsOut: "469.33,1002.81 469.33,1151.37 452.37,1161.22 452.37,1012.67   ",
                side: "front",
                cat: "inactive",
            },

            // 3
            {
                className: "row2col1item3",
                dataid: 3,
                posX: "0",
                fill: "#009BA1",
                pointsIn: "448.09,1055.16 431.13,1065.01 401.13,1047.68 418.08,1037.83   ",
                pointsOut: "448.09,1015.16 431.13,1025.01 401.13,1007.68 418.08,997.83   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col1item3",
                dataid: 3,
                fill: "#006F73",
                pointsIn: "431.13,1065.01 431.13,1213.55 401.13,1196.23 401.13,1047.68   ",
                pointsOut: "431.13,1025.01 431.13,1173.55 401.13,1156.23 401.13,1007.68   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col1item3",
                dataid: 3,
                fill: "#008F94",
                pointsIn: "448.09,1055.16 448.09,1203.7 431.13,1213.55 431.13,1065.01   ",
                pointsOut: "448.09,1015.16 448.09,1163.7 431.13,1173.55 431.13,1025.01   ",
                side: "front",
                cat: "inactive",
            },

            // 2
            {
                className: "row2col1item2",
                dataid: 2,
                posX: "0",
                fill: "#009BA1",
                pointsIn: "427.15,1067.2 410.2,1077.05 380.19,1059.73 397.14,1049.88   ",
                pointsOut: "427.15,1027.2 410.2,1037.05 380.19,1019.73 397.14,1009.88   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col1item2",
                dataid: 2,
                fill: "#006F73",
                pointsIn: "410.2,1077.05 410.2,1226.32 380.19,1209 380.19,1059.73   ",
                pointsOut: "410.2,1037.05 410.2,1186.32 380.19,1169 380.19,1019.73   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col1item2",
                dataid: 2,
                fill: "#008F94",
                pointsIn: "427.15,1067.2 427.15,1216.47 410.2,1226.32 410.2,1077.05   ",
                pointsOut: "427.15,1027.2 427.15,1176.47 410.2,1186.32 410.2,1037.05   ",
                side: "front",
                cat: "inactive",
            },

            // 1
            {
                className: "row2col1item1",
                dataid: 1,
                posX: "0",
                fill: "#cccccc",
                pointsIn: "404.38,1079.22 373.07,1097.36 344,1080.53 375.3,1062.4   ",
                pointsOut: "404.38,1039.22 373.07,1057.36 344,1040.53 375.3,1022.4   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col1item1",
                dataid: 1,
                fill: "#888888",
                pointsIn: "373.3,1096.22 373.3,1247.68 344,1230.78 344,1080.33   ",
                pointsOut: "373.3,1056.22 373.3,1207.68 344,1190.78 344,1040.33   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col1item1",
                dataid: 1,
                fill: "#bbbbbb",
                pointsIn: "404.38,1079.22 404.37,1229.67 372.93,1247.81 372.37,1095.97   ",
                pointsOut: "404.38,1039.22 404.37,1189.67 372.93,1207.81 372.37,1055.97   ",
                side: "front",
                cat: "inactive",
            },

            // 6
            {
                className: "row2col1item7",
                dataid: 6,
                posX: "0",
                fill: "#cccccc",
                pointsIn: "521.09,1040.88 518.96,1042.11 468.38,1071.44 447.46,1059.36 500.17,1028.73   ",
                pointsOut: "521.09,1000.88 518.96,1002.11 468.38,1031.44 447.46,1019.36 500.17,988.73   ",
                side: "top",
                cat: "inactive",
            },
            {
                className: "row2col1item7",
                dataid: 6,
                fill: "#888888",
                pointsIn: "468.42,1070.22 467.8,1219.52 446.88,1207.44 446.88,1059.36   ",
                pointsOut: "468.42,1030.22 467.8,1179.52 446.88,1167.44 446.88,1019.36   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col1item7",
                dataid: 6,
                fill: "#bbbbbb",
                pointsIn: "521.09,1040.81 521.09,1188.89 468.38,1219.52 467.88,1070.47 520.21,1040.36   ",
                pointsOut: "521.09,1000.81 521.09,1148.89 468.38,1179.52 467.88,1030.47 520.2,1000.36   ",
                side: "front",
                cat: "inactive",
            },

            // 7
            {
                className: "row2col1item6",
                dataid: 7,
                fill: "#063356",
                pointsIn: "575.38,1072.63 575.38,1222.531 452.8,1293.759 448.09,1291.04 448.504,1141.381     571.973,1070.663   ",
                pointsOut: "575.38,1032.63 575.38,1182.531 452.8,1253.759 448.09,1251.04 448.504,1101.381     571.973,1030.663   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col1item6",
                dataid: 7,
                fill: "#002B4B",
                pointsIn: "452.8,1143.861 452.8,1293.759 377.67,1250.39 377.67,1100.49 384.5,1096.521 460.407,1139.441       ",
                pointsOut: "452.8,1103.861 452.8,1253.759 377.67,1210.39 377.67,1060.49 384.5,1056.521 460.407,1099.441       ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col1item6",
                dataid: 7,
                posX: "0",
                fill: "#09375A",
                pointsIn: "575.38,1072.63 452.8,1143.861 377.67,1100.49 443.479,1062.24 468.21,1076.521 524.979,1043.531       ",
                pointsOut: "575.38,1032.63 452.8,1103.861 377.67,1060.49 443.479,1022.24 468.21,1036.521 524.979,1003.531       ",
                side: "top",
                cat: "inactive",
            },

            // 8
            {
                className: "row2col1item8",
                dataid: 8,
                fill: "#4F3117",
                pointsIn: "641.21,1110.64 641.21,1260.55 518.23,1331.56 518.63,1181.87 518.23,1181.64 640.56,1110.27       ",
                pointsOut: "641.21,1070.64 641.21,1220.55 518.23,1291.56 518.63,1141.87 518.23,1141.64 640.56,1070.27       ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col1item8",
                dataid: 8,
                fill: "#462C15",
                pointsIn: "518.63,1181.87 518.63,1331.79 457.02,1296.22 457.02,1146.3   ",
                pointsOut: "518.63,1141.87 518.63,1291.79 457.02,1256.22 457.02,1106.3   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col1item8",
                dataid: 8,
                posX: "0",
                fill: "#583614",
                pointsIn: "641.21,1110.64 518.63,1181.87 457.02,1146.3 579.6,1075.07   ",
                pointsOut: "641.21,1070.64 518.63,1141.87 457.02,1106.3 579.6,1035.07   ",
                side: "top",
                cat: "inactive",
            },

            // 9
            {
                className: "row2col1item10",
                dataid: 9,
                fill: "#B51F27",
                pointsIn: "697.74,1143.27 695.98,1292.01 572.5,1363.25 575.16,1214.51 574.56,1214.16 696.6,1142.61   ",
                pointsOut: "697.74,1103.27 695.98,1252.01 572.5,1323.25 575.16,1174.51 574.56,1174.16 696.6,1102.61   ",
                side: "front",
                cat: "inactive",
            },
            {
                className: "row2col1item10",
                dataid: 9,
                fill: "#8A1016",
                pointsIn: "575.16,1214.51 573.4,1363.25 521.09,1333.05 522.85,1184.31   ",
                pointsOut: "575.16,1174.51 573.4,1323.25 521.09,1293.05 522.85,1144.31   ",
                side: "side",
                cat: "inactive",
            },
            {
                className: "row2col1item10",
                dataid: 9,
                posX: "0",
                fill: "#C5232C",
                pointsIn: "697.74,1143.27 575.16,1214.51 522.85,1184.31 645.44,1113.08   ",
                pointsOut: "697.74,1103.27 575.16,1174.51 522.85,1144.31 645.44,1073.08   ",
                side: "top",
                cat: "inactive",
            },
            
            {
                className: "row2clip",
                dataid: "inactive",
                fill: "#FFFFFF",
                pointsIn: "1281.62,826.85 1281.62,956.34 573.5,1373.22 341.5,1235.22 341.5,1101.55     574.66,1237.67   ",
                pointsOut: "1281.62,826.85 1281.62,956.34 573.5,1373.22 341.5,1235.22 341.5,1101.55     574.66,1237.67   ",
                cat: "inactive",
            },
        ],
    };

export const colors = {
    divider: { active: { top: "#ffffff", side: "#ffffff", front: "#ffffff" }, passive: { top: "#ffffff", side: "#ffffff", front: "#ffffff" } },
    inactive: { active: { top: "#cccccc", side: "#a7a7a7", front: "#bbbbbb" }, passive: { top: "#cccccc", side: "#a7a7a7", front: "#bbbbbb" } },
    moda: { active: { top: "#09375A", side: "#002B4B", front: "#063356" }, passive: { top: "#778590", side: "#727f88", front: "#75838c" } },
    bizuteria: { active: { top: "#563285", side: "#3B195F", front: "#4F2C7C" }, passive: { top: "#9b91aa", side: "#897d93", front: "#9a8ca6" } },
    obuwie: { active: { top: "#583614", side: "#462C15", front: "#4F3117" }, passive: { top: "#90867c", side: "#8a817a", front: "#8e837d" } },
    spozywcze: { active: { top: "#3978AD", side: "#24537C", front: "#346E9F" }, passive: { top: "#9aadbc", side: "#8896a3", front: "#95a6b6" } },
    zdrowie: { active: { top: "#C5232C", side: "#8A1016", front: "#B51F27" }, passive: { top: "#c49295", side: "#a78083", front: "#bc8f92" } },
    wnetrza: { active: { top: "#D38027", side: "#935912", front: "#C17622" }, passive: { top: "#ccb297", side: "#ccb297", front: "#c3ab93" } },
    dzieci: { active: { top: "#CBB633", side: "#8B8020", front: "#B9A72F" }, passive: { top: "#cac29b", side: "#aaa786", front: "#c1bb99" } },
    sport: { active: { top: "#6C70A1", side: "#4B4D73", front: "#646794" }, passive: { top: "#aeb0bf", side: "#989ba4", front: "#a8aab7" } },
    hobby: { active: { top: "#0C9C54", side: "#006F39", front: "#04904D" }, passive: { top: "#8bb29d", side: "#8bb29d", front: "#76a98a" } },
    rtv: { active: { top: "#86135E", side: "#690047", front: "#7F0F57" }, passive: { top: "#a58499", side: "#94778b", front: "#a18095" } },
    uslugi: { active: { top: "#009BA1", side: "#006F73", front: "#008F94" }, passive: { top: "#7eaeb0", side: "#79989b", front: "#7ea7a9" } },
    gastronomia: { active: { top: "#B9448A", side: "#832A61", front: "#AA3E80" }, passive: { top: "#c4a1b7", side: "#a88b9f", front: "#bb9cae" } },
};