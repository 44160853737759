import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../../Page';
import Gallery from '../../../Gallery';
import GalleryVideos from '../../../GalleryVideos';
import Back from "../../../Back";
import Player from '../../../Player';
import Catalogue from "../../Events/Sections/Catalogue";

//svg
import PhotoCamera from "../../../../svg/PhotoCamera";
import VideoCamera from "../../../../svg/VideoCamera";

//contexts
import ResponsivenessContext from '../../../../contexts/Responsiveness/ResponsivenessContext';

//config
import { config }  from "../../../../config";
import { paths }  from "../../../../config/paths";

class News extends Component {
    render() {
        const {
            pageData,
            pageOptions,
            selectedPost,
            selectedPostCategory,
            playerOpened,
            match
        } = this.props;

        const seasonGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_naglowki}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`
        };
        const seasonTxtColor = pageOptions.kolor_sezonowy_tekst;

        return (
            <ResponsivenessContext.Consumer>
                {
                    isMobile => (
                        <Page pageIndex={config.pageIndex.wydarzenia}
                              restName="wydarzenia"
                              postUrl={ match ? match.params.newsId : selectedPost }
                        >
                            { playerOpened && <Player /> }
                            <Back
                                bg={pageOptions.zielen}
                                link={selectedPostCategory === 'news' ? paths.wydarzenia : paths.archiwa}
                            />
                            <article className="page news content">
                                { !isMobile &&
                                <div className="row cover-row-container">
                                    <div className={"col col-xs-12"}>
                                        <figure>
                                            {
                                                pageData.okladka_full ?
                                                    <img src={pageData.okladka_full} alt={'News'}/>
                                                    :
                                                    <img src={pageOptions.zaslepka_news} alt={'News'}/>
                                            }

                                        </figure>
                                    </div>
                                </div>
                                }
                                <div className="row center-xs start-sm">
                                    <div className={"col col-xs-12 col-md-4"}>
                                        <div className="panel" style={seasonGradient}/>
                                    </div>
                                    <div className={'col col-xs-12 col-md-8 news-txt-content'}>
                                        <article>
                                            <h2>{pageData.tytul}</h2>
                                            <p dangerouslySetInnerHTML={{__html: pageData.tekst}} />
                                            {
                                                pageData.regulamin &&
                                                    <a
                                                        href={pageData.regulamin}
                                                        title={`Legal: ${pageData.tytul}`}
                                                        className="legal"
                                                        target="_blank"
                                                        rel="noopener nofollow"
                                                    >{pageOptions.regulamin}>></a>
                                            }
                                        </article>
                                    </div>
                                </div>

                                {
                                    pageData.posiada_galerie_filmow &&
                                    <div style={seasonGradient}>
                                        <h3 className="tiles-section-title" >
                                            <figure>
                                                <VideoCamera mainColor={seasonTxtColor}/>
                                            </figure>
                                            <figcaption style={{color:seasonTxtColor}}>
                                                {pageOptions.teksty_obejrzyj_video}
                                            </figcaption>
                                        </h3>
                                        <GalleryVideos
                                            videos={pageData.filmy}
                                            playIcon={pageOptions.play_icon}
                                        />
                                    </div>
                                }

                                {
                                    pageData.posiada_galerie &&
                                    <>
                                        <h3 className="tiles-section-title">
                                            <figure>
                                                <PhotoCamera mainColor="#000000"/>
                                            </figure>
                                            <figcaption>
                                                {pageOptions.teksty_obejrzyj_foto}
                                            </figcaption>
                                        </h3>
                                        <Gallery
                                            pageData={pageData}
                                            pageOptions={pageOptions}
                                            initialAmount={6}
                                        />
                                    </>
                                }

                                {
                                    pageData.strony &&
                                    <>
                                        <Catalogue
                                            full={pageData.gazetka_pelna_szerokosc}
                                            tytul={pageData.gazetka_tytul}
                                            strony={pageData.strony}
                                        />
                                    </>
                                }

                            </article>

                        </Page>
                    )
                }

            </ResponsivenessContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions,
    selectedPost: state.page.selectedPost,
    selectedPostCategory: state.page.selectedPostCategory,
    playerOpened:state.player.playerOpened
});


export default connect(mapStateToProps,null)(News);