import React from 'react';

const Vimeo = ({mainColor}) => {
    return (
        <svg
            version="1.1"
            id="soc_vimeo"
            xmlns="http://www.w3.org/2000/svg"
            width="100px"
            height="100px"
            viewBox="0 0 100 100">
            <path fill={mainColor} d="M50,0C22.387,0,0,22.386,0,50s22.387,50,50,50s50-22.386,50-50S77.613,0,50,0z M78.278,36.84
				c-0.25,5.511-4.104,13.061-11.547,22.643C59.037,69.49,52.523,74.495,47.193,74.495c-3.297,0-6.089-3.047-8.372-9.144
				c-1.523-5.588-3.047-11.173-4.57-16.761c-1.692-6.094-3.509-9.145-5.453-9.145c-0.422,0-1.907,0.893-4.445,2.669l-2.662-3.433
				c2.792-2.453,5.549-4.911,8.26-7.371c3.728-3.219,6.524-4.914,8.391-5.086c4.405-0.423,7.118,2.592,8.136,9.039
				c1.1,6.96,1.86,11.289,2.288,12.981c1.269,5.774,2.668,8.658,4.191,8.658c1.187,0,2.969-1.875,5.341-5.616
				c2.37-3.746,3.64-6.598,3.812-8.551c0.338-3.233-0.934-4.853-3.812-4.853c-1.357,0-2.756,0.31-4.191,0.929
				c2.782-9.115,8.099-13.545,15.946-13.295C75.872,25.687,78.613,29.461,78.278,36.84z"/>
        </svg>
    );
};

export default Vimeo;