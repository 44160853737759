import React from 'react';

//tools
import Slider from "react-slick";
import classnames from "classnames";
import Arrow from "../../../../../svg/lightbox/Arrow";

const Catalogue = ({ strony, tytul, full }) => {
    const sliderSettings = {
        autoplay:false,
        cssEase:'ease-in-out',
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide:0,
        nextArrow: <div className="arrow next"><Arrow mainColor="#FFFFFF" /></div>,
        prevArrow: <div className="arrow prev"><Arrow mainColor="#FFFFFF" /></div>
    };

    return (
        <div
            className="section-catalogue"
            style={{ marginBottom: "-3px" }}
        >
            <div
                className="row slider-row-container top-xs"
                style={{margin:"0"}}
            >
                <div
                    className={classnames(
                    "col",{
                        "col-md-8 col-md-offset-4": !full,
                        "col-md-12": full
                    })}
                    style={ full ? {padding:"0"} : {paddingLeft:"58px"}}
                >
                    <h3
                        dangerouslySetInnerHTML={{__html: tytul}}
                        style={
                            full ?
                            {
                                fontSize: "21px",
                                lineHeight: "1.2",
                                textTransform: "uppercase",
                                margin: "59px 0 49px",
                                paddingLeft:"16px"
                            } :
                            {
                                fontSize: "21px",
                                lineHeight: "1.2",
                                textTransform: "uppercase",
                                margin: "59px 0 49px",
                                paddingLeft:"0"
                            }
                        }
                    />

                    <Slider {...sliderSettings}>
                        {
                            strony &&
                            strony.map((item,index) =>
                                <div key={index}>
                                    <img src={item.strona} alt="" className="page"/>
                                </div>
                            )
                        }
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Catalogue;