export const columns =
    {
        col1: [
            {
                cat: 'moda',
                theme: '#07365b'
            },
            {
                cat: 'bizuteria',
                theme: '#563286'
            }
        ],
        col2: [
            {
                cat: 'obuwie',
                theme: '#593613'
            },
            {
                cat: 'spozywcze',
                theme: '#3778af'
            },
            {
                cat: 'zdrowie',
                theme: '#c7212c'
            },
            {
                cat: 'wnetrza',
                theme: '#d68127'
            }
        ],
        col3: [
            {
                cat: 'dzieci',
                theme: '#f1db3c'
            },
            {
                cat: 'sport',
                theme: '#7a5994'
            },
            {
                cat: 'hobby',
                theme: '#1d9d55'
            },
            {
                cat: 'rtv',
                theme: '#87135d'
            }
        ],
        col4: [
            {
                cat: 'uslugi',
                theme: '#009ca2'
            },
            {
                cat: 'gastronomia',
                theme: '#ba438a'
            }
        ]
    };