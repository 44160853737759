import React from 'react';

const Logo = ({mainColor}) => {
    return (
        <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" width="173px" height="75.118px"
             viewBox="34.618 54.718 390.901 169.523">
            <path fill={mainColor} d="M120.303,137.227c0.157-0.059,0.307-0.084,0.46-0.084c0.546,0,1.054,0.336,1.254,0.878c0,0,0.151,0.417,0.524,1.247
            c0.373,0.841,0.956,2.057,1.793,3.577c1.668,3.041,8.339,12.028,8.339,12.028s20.947,20.737,41.646,28.797l-2.433-3.232
            c-6.859-9.539-4.64-16.969-11.154-35.298c-9.896-27.822-46.665-19.75-49.364-21.778c0,0-15.796,47.578,25.483,55.154
            c12.42,2.283,21.066,2.973,26.286,3.135c-34.201-17.037-43.574-42.529-43.634-42.721
            C119.255,138.24,119.613,137.475,120.303,137.227 M48.205,145.14c-6.518,18.329-4.295,25.759-11.154,35.298l-2.433,3.232
            c20.696-8.063,33.75-19.424,41.646-28.797c3.994-4.746,6.671-8.987,8.342-12.028c0.837-1.521,1.423-2.736,1.796-3.577
            c0.37-0.83,0.521-1.247,0.523-1.247c0.194-0.542,0.708-0.878,1.254-0.878c0.154,0,0.304,0.025,0.461,0.084
            c0.69,0.248,1.05,1.017,0.793,1.709c-0.056,0.188-9.433,25.684-43.63,42.722c5.223-0.163,13.865-0.847,26.286-3.135
            c41.283-7.577,25.483-55.154,25.483-55.154C94.873,125.393,58.101,117.318,48.205,145.14 M160.896,93.246
            c6.518-18.323,4.295-25.759,11.154-35.295l2.43-3.232c-20.7,8.066-33.747,19.42-41.64,28.797c-4,4.746-6.677,8.987-8.348,12.028
            c-0.837,1.517-1.42,2.737-1.793,3.57c-0.37,0.834-0.521,1.248-0.524,1.251c-0.197,0.542-0.708,0.878-1.254,0.878
            c-0.153,0-0.307-0.028-0.458-0.085c-0.696-0.254-1.053-1.016-0.796-1.708c0.057-0.188,9.433-25.681,43.631-42.718
            c-5.223,0.166-13.869,0.853-26.289,3.138c-41.28,7.571-25.483,55.142-25.483,55.142C114.227,112.992,150.996,121.064,160.896,93.246
            M72.246,59.875c-12.42-2.288-21.066-2.972-26.289-3.138c34.201,17.038,43.578,42.53,43.637,42.718
            c0.254,0.693-0.106,1.458-0.799,1.708c-0.15,0.057-0.304,0.084-0.458,0.084c-0.542,0-1.06-0.335-1.254-0.877
            c0,0-0.15-0.417-0.523-1.251s-0.956-2.056-1.793-3.57c-1.671-3.044-4.348-7.282-8.345-12.029c-7.894-9.376-20.944-20.73-41.64-28.79
            l2.433,3.226c6.859,9.54,4.633,16.972,11.15,35.295c9.903,27.822,46.668,19.746,49.364,21.771
            C97.729,115.017,113.525,67.446,72.246,59.875"/>
            <path fill={mainColor} d="M323.127,158.805c0.661-0.482,1.749-1.251,3.264-2.305c1.514-1.041,3.115-2.176,4.809-3.4
            c1.689-1.223,3.345-2.445,4.972-3.668c1.624-1.223,2.905-2.258,3.837-3.107c-0.223-0.445-0.756-0.98-1.605-1.633
            c-0.84-0.648-1.868-0.969-3.068-0.969c-1.289,0-2.342,0.188-3.139,0.567c-0.796,0.38-1.689,0.925-2.668,1.636
            c-1.247,0.891-2.555,1.85-3.937,2.869c-1.376,1.028-2.709,2-4.001,2.934c-1.291,0.938-2.457,1.793-3.504,2.571
            c-1.047,0.78-1.859,1.37-2.436,1.765v-27.289c-0.223-0.088-0.668-0.232-1.332-0.433c-0.668-0.198-1.405-0.304-2.201-0.304
            c-1.295,0-2.295,0.298-3.004,0.872c-0.717,0.577-1.072,1.464-1.072,2.667v45.644h7.609v-15.085c1.376,1.028,2.814,2.147,4.307,3.37
            c1.486,1.226,2.957,2.495,4.404,3.8c1.442,1.316,2.797,2.651,4.069,4.006c1.267,1.36,2.414,2.662,3.427,3.906h9.482
            c-2.138-2.941-4.762-6.045-7.875-9.308C330.353,164.642,326.904,161.604,323.127,158.805 M301.336,143.958
            c-1.758-0.778-4.037-1.169-6.837-1.169c-4.454,0-7.935,1.121-10.448,3.369c-2.514,2.244-3.768,5.418-3.768,9.512v21.552h7.604
            v-19.354c0-5.512,2.603-8.27,7.806-8.27c1.424,0,2.689,0.153,3.803,0.47c1.113,0.308,2.047,0.64,2.809,1.003
            c0.267-0.182,0.608-0.627,1.035-1.342c0.423-0.705,0.633-1.441,0.633-2.197C303.977,145.924,303.096,144.738,301.336,143.958
            M264.938,172.012c-0.4,0.094-1.2,0.217-2.4,0.373c-1.205,0.153-2.822,0.232-4.872,0.232c-1.825,0-3.342-0.123-4.571-0.364
            c-1.223-0.245-2.188-0.603-2.905-1.072c-0.708-0.468-1.207-1.034-1.499-1.699c-0.285-0.665-0.432-1.397-0.432-2.204
            c0-1.955,0.686-3.389,2.065-4.307c1.376-0.909,3.58-1.367,6.608-1.367c2.445,0,4.281,0.088,5.502,0.273
            c1.223,0.176,2.056,0.307,2.501,0.395v9.74H264.938z M267.539,145.989c-1.555-1.11-3.413-1.919-5.57-2.433
            c-2.16-0.512-4.545-0.768-7.172-0.768c-2.18,0-3.979,0.135-5.404,0.4c-1.424,0.267-2.559,0.633-3.408,1.101
            c-0.837,0.468-1.43,1.009-1.765,1.608c-0.333,0.596-0.499,1.21-0.499,1.834c0,0.891,0.235,1.646,0.699,2.266
            c0.467,0.625,0.831,1.047,1.101,1.27c0.489-0.404,1.501-0.893,3.038-1.47c1.535-0.579,3.57-0.865,6.104-0.865
            c1.514,0,2.896,0.11,4.141,0.329c1.242,0.229,2.313,0.631,3.201,1.201c0.891,0.582,1.592,1.379,2.104,2.4
            c0.511,1.029,0.765,2.314,0.765,3.872c-0.759-0.091-1.891-0.188-3.405-0.298c-1.514-0.113-3.112-0.17-4.799-0.17
            c-2.938,0-5.436,0.232-7.477,0.703c-2.044,0.467-3.705,1.169-4.972,2.106c-1.267,0.928-2.179,2.088-2.737,3.468
            c-0.558,1.379-0.836,2.98-0.836,4.805c0,1.377,0.234,2.725,0.705,4.031c0.467,1.311,1.335,2.471,2.602,3.471
            c1.267,1.004,2.997,1.806,5.204,2.401c2.197,0.599,5.034,0.903,8.505,0.903c1.742,0,3.404-0.045,5.006-0.133
            c1.605-0.094,3.047-0.188,4.339-0.301c1.294-0.115,2.413-0.219,3.366-0.332c0.957-0.113,1.658-0.21,2.107-0.298v-20.088
            c0-2.583-0.43-4.78-1.301-6.608C270.311,148.575,269.095,147.105,267.539,145.989 M228.708,149.563
            c-0.401,1.047-1.079,1.966-2.035,2.769c-0.956,0.8-2.257,1.423-3.903,1.868c-1.649,0.441-3.693,0.668-6.141,0.668
            c-2.226,0-4.047-0.101-5.467-0.301c-1.426-0.201-2.294-0.32-2.605-0.367v-16.282c0.357-0.088,1.179-0.176,2.47-0.267
            c1.292-0.088,3.003-0.135,5.138-0.135c2.671,0,4.859,0.248,6.577,0.736c1.706,0.489,3.053,1.129,4.035,1.935
            c0.975,0.8,1.646,1.724,2.003,2.771c0.354,1.041,0.533,2.126,0.533,3.232C229.307,147.394,229.11,148.516,228.708,149.563
            M232.245,134.983c-3.47-2.803-8.768-4.201-15.884-4.201c-3.47,0-6.138,0.075-8.01,0.232c-1.862,0.157-3.157,0.301-3.868,0.429
            c-1.022,0.226-1.89,0.646-2.602,1.273c-0.715,0.624-1.069,1.646-1.069,3.069v41.439h7.74v-16.285c0.135,0,0.492,0.041,1.069,0.135
            c0.58,0.088,1.27,0.162,2.069,0.232c0.799,0.063,1.677,0.131,2.636,0.197c0.956,0.065,1.9,0.097,2.834,0.097
            c3.693,0,6.831-0.386,9.411-1.166s4.668-1.858,6.267-3.231c1.604-1.38,2.771-3.02,3.505-4.913c0.733-1.888,1.1-3.921,1.1-6.101
            C237.448,141.525,235.715,137.785,232.245,134.983 M417.98,107.39c-0.398,0.087-1.201,0.207-2.401,0.367
            c-1.197,0.15-2.831,0.232-4.872,0.232c-1.824,0-3.348-0.123-4.566-0.367c-1.229-0.245-2.191-0.605-2.906-1.072
            c-0.712-0.467-1.213-1.035-1.502-1.702c-0.291-0.665-0.436-1.398-0.436-2.201c0-1.959,0.687-3.389,2.066-4.304
            c1.379-0.909,3.582-1.367,6.602-1.367c2.451,0,4.285,0.084,5.508,0.267c1.226,0.182,2.053,0.313,2.502,0.401v9.747H417.98z
            M424.222,85.769c-0.868-1.825-2.084-3.292-3.636-4.401c-1.555-1.116-3.42-1.928-5.577-2.439c-2.153-0.508-4.549-0.765-7.173-0.765
            c-2.176,0-3.978,0.132-5.404,0.401c-1.423,0.267-2.559,0.633-3.401,1.101c-0.843,0.467-1.436,1-1.771,1.599
            c-0.333,0.602-0.499,1.213-0.499,1.837c0,0.891,0.235,1.646,0.699,2.267c0.467,0.624,0.834,1.047,1.104,1.267
            c0.483-0.401,1.499-0.891,3.038-1.47c1.532-0.574,3.566-0.862,6.104-0.862c1.508,0,2.893,0.113,4.135,0.333
            c1.24,0.219,2.311,0.624,3.2,1.201c0.89,0.58,1.599,1.379,2.106,2.404c0.508,1.019,0.766,2.31,0.766,3.868
            c-0.756-0.087-1.891-0.191-3.402-0.301c-1.514-0.11-3.115-0.166-4.805-0.166c-2.938,0-5.427,0.232-7.471,0.699
            c-2.047,0.47-3.709,1.169-4.975,2.107c-1.268,0.934-2.18,2.091-2.734,3.47c-0.555,1.373-0.83,2.981-0.83,4.803
            c0,1.379,0.231,2.724,0.699,4.038c0.467,1.313,1.335,2.47,2.602,3.467c1.266,1.003,3,1.803,5.203,2.401
            c2.204,0.602,5.035,0.906,8.506,0.906c1.732,0,3.4-0.047,5.006-0.138c1.602-0.088,3.047-0.191,4.338-0.301
            c1.289-0.113,2.411-0.226,3.371-0.329c0.949-0.116,1.654-0.213,2.1-0.301V92.377C425.523,89.794,425.087,87.593,424.222,85.769
            M386.752,79.098c-1.333,0-2.324,0.319-2.967,0.965c-0.645,0.646-1.166,1.658-1.566,3.038c-1.022,3.646-2.148,7.295-3.37,10.938
            c-1.226,3.652-2.414,7.01-3.571,10.085c-0.535-1.514-1.121-3.248-1.771-5.207c-0.646-1.959-1.298-4.003-1.969-6.138
            c-0.665-2.141-1.333-4.304-2-6.508c-0.668-2.204-1.292-4.304-1.865-6.311c-0.183-0.088-0.63-0.25-1.339-0.499
            c-0.711-0.241-1.511-0.363-2.401-0.363c-1.42,0-2.458,0.301-3.104,0.903c-0.643,0.599-1.147,1.477-1.501,2.627
            c-0.492,1.737-1.029,3.574-1.605,5.508c-0.576,1.938-1.17,3.85-1.768,5.737c-0.605,1.896-1.18,3.733-1.74,5.508
            c-0.555,1.781-1.072,3.36-1.561,4.743c-0.537-1.562-1.151-3.329-1.834-5.308c-0.693-1.981-1.396-4.06-2.107-6.241
            c-0.711-2.179-1.4-4.367-2.068-6.574c-0.665-2.198-1.267-4.279-1.8-6.235c-0.179-0.088-0.548-0.223-1.104-0.401
            c-0.555-0.179-1.323-0.266-2.304-0.266c-1.295,0-2.245,0.351-2.865,1.065c-0.627,0.715-0.938,1.536-0.938,2.464
            c0,0.539,0.163,1.439,0.502,2.711c0.332,1.264,0.912,3.069,1.732,5.401c0.825,2.333,1.936,5.282,3.336,8.843
            c1.401,3.558,3.188,7.894,5.373,13.006h7.869c0.359-0.931,0.824-2.301,1.404-4.104c0.576-1.799,1.209-3.765,1.899-5.906
            c0.689-2.132,1.376-4.323,2.069-6.571c0.689-2.244,1.322-4.301,1.899-6.172c0.577,1.872,1.213,3.928,1.903,6.172
            c0.689,2.251,1.391,4.442,2.1,6.571c0.712,2.141,1.367,4.106,1.973,5.906c0.598,1.803,1.071,3.173,1.436,4.104h7.871
            c1.109-2.533,2.289-5.373,3.536-8.508c1.247-3.132,2.427-6.204,3.539-9.207c1.106-3.003,2.106-5.796,3-8.373
            c0.891-2.577,1.508-4.605,1.865-6.072c-0.087-0.094-0.511-0.335-1.267-0.736C388.934,79.295,387.952,79.098,386.752,79.098
            M308.885,102.65c0-1.959,0.688-3.389,2.066-4.304c1.379-0.909,3.576-1.367,6.602-1.367c2.451,0,4.285,0.085,5.508,0.267
            c1.227,0.182,2.061,0.313,2.505,0.401v9.746c-0.397,0.088-1.207,0.207-2.404,0.367c-1.204,0.15-2.827,0.232-4.872,0.232
            c-1.827,0-3.348-0.122-4.573-0.367c-1.223-0.245-2.188-0.605-2.899-1.072c-0.712-0.467-1.217-1.034-1.498-1.702
            C309.029,104.186,308.885,103.453,308.885,102.65 M318.293,113.531c1.733,0,3.4-0.047,5.003-0.138
            c1.603-0.088,3.05-0.191,4.339-0.301c1.288-0.113,2.41-0.226,3.369-0.329c0.957-0.116,1.658-0.213,2.107-0.301V92.377
            c0-2.58-0.439-4.78-1.305-6.605c-0.865-1.824-2.078-3.292-3.637-4.401c-1.561-1.116-3.42-1.928-5.573-2.439
            c-2.159-0.508-4.548-0.765-7.173-0.765c-2.178,0-3.986,0.131-5.404,0.401c-1.423,0.266-2.561,0.633-3.407,1.1
            c-0.847,0.467-1.433,1-1.769,1.599c-0.335,0.602-0.498,1.213-0.498,1.837c0,0.89,0.232,1.646,0.699,2.266
            c0.467,0.624,0.83,1.047,1.1,1.267c0.486-0.401,1.502-0.89,3.038-1.47c1.53-0.574,3.564-0.862,6.101-0.862
            c1.514,0,2.891,0.113,4.139,0.332c1.244,0.22,2.313,0.624,3.2,1.201c0.894,0.58,1.593,1.379,2.104,2.404
            c0.511,1.019,0.765,2.311,0.765,3.869c-0.756-0.088-1.887-0.191-3.401-0.301c-1.511-0.109-3.116-0.166-4.806-0.166
            c-2.934,0-5.43,0.232-7.474,0.699c-2.047,0.47-3.705,1.169-4.972,2.106c-1.267,0.934-2.179,2.091-2.736,3.47
            c-0.555,1.373-0.834,2.981-0.834,4.803c0,1.379,0.234,2.724,0.699,4.038c0.47,1.313,1.335,2.47,2.604,3.467
            c1.267,1.003,3,1.802,5.204,2.401C311.985,113.227,314.819,113.531,318.293,113.531 M283.461,93.239c0-5.511,2.603-8.27,7.807-8.27
            c1.423,0,2.692,0.153,3.805,0.467c1.11,0.307,2.041,0.646,2.797,1c0.273-0.179,0.615-0.624,1.041-1.335
            c0.42-0.705,0.63-1.448,0.63-2.201c0-1.602-0.874-2.793-2.634-3.57c-1.758-0.777-4.037-1.169-6.836-1.169
            c-4.452,0-7.938,1.125-10.445,3.37c-2.515,2.248-3.769,5.417-3.769,9.511v21.552h7.604V93.239z M251.768,113.531
            c2.402,0,4.6-0.326,6.603-0.972c2.003-0.646,3.728-1.589,5.173-2.837c1.441-1.245,2.558-2.765,3.332-4.567
            c0.781-1.799,1.172-3.84,1.172-6.106V79.832c-0.229-0.084-0.667-0.229-1.335-0.43c-0.667-0.2-1.429-0.301-2.27-0.301
            c-1.247,0-2.226,0.276-2.938,0.834s-1.065,1.458-1.065,2.699v14.752c0,3.019-0.712,5.326-2.139,6.903
            c-1.43,1.58-3.602,2.367-6.535,2.367c-2.935,0-5.117-0.787-6.539-2.367c-1.424-1.577-2.139-3.884-2.139-6.903V79.834
            c-0.223-0.084-0.668-0.229-1.336-0.429c-0.667-0.201-1.4-0.301-2.197-0.301c-1.291,0-2.295,0.276-3.003,0.834
            c-0.715,0.558-1.065,1.458-1.065,2.699v16.417c0,2.267,0.404,4.308,1.197,6.107c0.799,1.802,1.912,3.323,3.336,4.567
            c1.422,1.251,3.137,2.194,5.137,2.837C247.16,113.202,249.363,113.531,251.768,113.531 M210.862,112.594h7.94V74.092h16.819
            c0.176-0.223,0.389-0.68,0.633-1.367c0.242-0.693,0.367-1.458,0.367-2.304c0-2.401-1.314-3.605-3.938-3.605H194.58
            c-0.132,0.222-0.333,0.68-0.599,1.37c-0.266,0.684-0.398,1.458-0.398,2.301c0,2.401,1.313,3.602,3.938,3.602h13.345v38.505H210.862z
            "/>
            <g>
                <path fill={mainColor} d="M34.775,201.928c0-1.045,0.627-1.668,1.564-1.668c0.903,0,1.53,0.627,1.53,1.668v11.26l10.082-12.27
            c0.313-0.383,0.765-0.662,1.285-0.662c0.73,0,1.392,0.555,1.392,1.355c0,0.382-0.172,0.729-0.417,1.009l-6.047,7.159l6.918,12.029
            c0.21,0.348,0.313,0.661,0.313,0.972c0,0.8-0.589,1.461-1.46,1.461c-0.627,0-1.113-0.313-1.461-0.903l-6.361-11.156l-4.241,5.145
            v5.247c0,1.044-0.627,1.667-1.53,1.667c-0.938,0-1.564-0.627-1.564-1.667v-20.646H34.775z"/>
                <path fill={mainColor} d="M54.142,222.122l7.264-20.126c0.382-1.113,0.972-1.736,2.016-1.736s1.702,0.627,2.084,1.736l7.16,20.126
            c0.069,0.21,0.138,0.451,0.138,0.696c0,0.867-0.662,1.426-1.461,1.426c-0.696,0-1.251-0.383-1.496-1.113l-1.599-4.555h-9.699
            l-1.599,4.555c-0.245,0.73-0.799,1.113-1.496,1.113c-0.799,0-1.46-0.555-1.46-1.426C54.001,222.573,54.073,222.332,54.142,222.122z
            M67.246,215.727l-3.756-10.74h-0.104l-3.824,10.74H67.246z"/>
                <path fill={mainColor} d="M77.167,224.068c-1.009,0-1.633-0.592-1.633-1.461c0-0.661,0.244-1.182,0.902-2.188l11.054-17.066v-0.034h-9.975
            c-0.938,0-1.53-0.628-1.53-1.427c0-0.834,0.592-1.461,1.53-1.461h12.167c1.009,0,1.633,0.627,1.633,1.496
            c0,0.592-0.173,1.043-0.834,2.084l-11.123,17.135v0.035h10.426c0.938,0,1.53,0.627,1.53,1.426c0,0.834-0.589,1.461-1.53,1.461
            H77.167L77.167,224.068z M81.478,195.532c0-1.044,0.834-1.912,1.912-1.912c1.044,0,1.913,0.868,1.913,1.912
            c0,1.078-0.869,1.912-1.913,1.912C82.312,197.444,81.478,196.607,81.478,195.532z"/>
                <path fill={mainColor} d="M94.819,201.824c0-0.834,0.521-1.393,1.392-1.393h6.013c4.589,0,7.404,1.809,8.552,5.389
            c0.521,1.53,0.696,3.025,0.696,6.43c0,3.408-0.172,4.9-0.696,6.43c-1.147,3.58-3.962,5.389-8.552,5.389h-6.013
            c-0.869,0-1.392-0.555-1.392-1.392V201.824z M98.124,221.185h3.894c3.128,0,4.934-0.972,5.771-3.546
            c0.383-1.216,0.555-2.57,0.555-5.389c0-2.814-0.172-4.172-0.555-5.389c-0.833-2.574-2.643-3.545-5.771-3.545h-3.894
            c-0.138,0-0.207,0.068-0.207,0.209v17.449C97.914,221.115,97.982,221.185,98.124,221.185z"/>
                <path fill={mainColor} d="M122.967,214.477v8.1c0,1.044-0.627,1.668-1.53,1.668c-0.869,0-1.53-0.627-1.53-1.668v-8.1l-6.464-11.922
            c-0.138-0.279-0.244-0.627-0.244-0.903c0-0.765,0.624-1.392,1.46-1.392c0.627,0,1.044,0.313,1.354,0.902l5.423,10.082h0.104
            l5.354-10.082c0.313-0.592,0.73-0.902,1.354-0.902c0.833,0,1.426,0.627,1.426,1.392c0,0.278-0.104,0.627-0.245,0.903
            L122.967,214.477z"/>
                <path fill={mainColor} d="M145.513,201.824c0-0.834,0.521-1.393,1.392-1.393h6.013c4.589,0,7.404,1.809,8.552,5.389
            c0.521,1.53,0.696,3.025,0.696,6.43c0,3.408-0.172,4.9-0.696,6.43c-1.147,3.58-3.962,5.389-8.552,5.389h-6.013
            c-0.868,0-1.392-0.555-1.392-1.392L145.513,201.824L145.513,201.824z M148.814,221.185h3.894c3.128,0,4.937-0.972,5.771-3.546
            c0.383-1.216,0.555-2.57,0.555-5.389c0-2.814-0.172-4.172-0.555-5.389c-0.834-2.574-2.643-3.545-5.771-3.545h-3.894
            c-0.138,0-0.21,0.068-0.21,0.209v17.449C148.607,221.115,148.676,221.185,148.814,221.185z"/>
                <path fill={mainColor} d="M165.877,224.068c-1.009,0-1.633-0.592-1.633-1.461c0-0.661,0.245-1.182,0.903-2.188l11.053-17.066v-0.034h-9.975
            c-0.937,0-1.53-0.628-1.53-1.427c0-0.834,0.589-1.461,1.53-1.461h12.167c1.009,0,1.633,0.627,1.633,1.496
            c0,0.592-0.172,1.043-0.834,2.084l-11.122,17.135v0.035h10.426c0.938,0,1.53,0.627,1.53,1.426c0,0.834-0.589,1.461-1.53,1.461
            H165.877L165.877,224.068z"/>
                <path fill={mainColor} d="M183.529,201.928c0-1.045,0.624-1.668,1.564-1.668c0.903,0,1.53,0.627,1.53,1.668v20.646c0,1.044-0.627,1.668-1.53,1.668
            c-0.937,0-1.564-0.627-1.564-1.668V201.928z"/>
                <path fill={mainColor} d="M191.382,201.824c0-0.834,0.521-1.393,1.392-1.393h12.166c0.938,0,1.53,0.627,1.53,1.427c0,0.833-0.592,1.46-1.53,1.46
            h-10.254c-0.138,0-0.21,0.07-0.21,0.211v6.953c0,0.138,0.069,0.21,0.21,0.21h8.552c0.938,0,1.53,0.627,1.53,1.427
            c0,0.834-0.592,1.461-1.53,1.461h-8.552c-0.138,0-0.21,0.068-0.21,0.209v7.195c0,0.137,0.069,0.209,0.21,0.209h10.254
            c0.938,0,1.53,0.627,1.53,1.427c0,0.834-0.592,1.461-1.53,1.461h-12.166c-0.869,0-1.392-0.555-1.392-1.392V201.824z"/>
                <path fill={mainColor} d="M210.034,202.135c0-1.148,0.624-1.879,1.702-1.879c0.903,0,1.496,0.593,2.085,1.6l10.082,16.648h0.138V201.82
            c0-0.972,0.589-1.564,1.426-1.564c0.869,0,1.461,0.593,1.461,1.564v20.543c0,1.147-0.627,1.878-1.702,1.878
            c-0.903,0-1.53-0.593-2.119-1.599l-10.082-16.58h-0.104v16.614c0,0.972-0.592,1.564-1.426,1.564c-0.869,0-1.461-0.593-1.461-1.564
            V202.135z M219.871,193.134c0.245-0.278,0.521-0.451,0.938-0.451c0.662,0,1.182,0.485,1.182,1.147c0,0.348-0.138,0.661-0.348,0.902
            l-2.398,2.643c-0.313,0.348-0.627,0.521-1.044,0.521c-0.592,0-1.113-0.486-1.113-1.113c0-0.348,0.138-0.662,0.383-0.938
            L219.871,193.134z"/>
                <path fill={mainColor} d="M244.781,201.824c0-0.834,0.52-1.393,1.392-1.393h6.013c4.589,0,7.404,1.809,8.552,5.389
            c0.521,1.53,0.696,3.025,0.696,6.43c0,3.408-0.173,4.9-0.696,6.43c-1.147,3.58-3.963,5.389-8.552,5.389h-6.013
            c-0.868,0-1.392-0.555-1.392-1.392V201.824z M248.082,221.185h3.893c3.129,0,4.935-0.972,5.771-3.546
            c0.383-1.216,0.555-2.57,0.555-5.389c0-2.814-0.172-4.172-0.555-5.389c-0.834-2.574-2.643-3.545-5.771-3.545h-3.893
            c-0.139,0-0.211,0.068-0.211,0.209v17.449C247.875,221.115,247.943,221.185,248.082,221.185z"/>
                <path fill={mainColor} d="M265.515,218.855c-0.556-1.702-0.661-3.232-0.661-6.605c0-3.369,0.104-4.899,0.661-6.605
            c1.182-3.613,3.928-5.596,7.993-5.596c4.102,0,6.848,1.982,8.029,5.596c0.555,1.703,0.661,3.232,0.661,6.605
            s-0.104,4.9-0.661,6.605c-1.182,3.614-3.928,5.596-8.029,5.596C269.442,224.451,266.696,222.47,265.515,218.855z M278.515,217.915
            c0.382-1.147,0.556-2.536,0.556-5.665s-0.173-4.518-0.556-5.664c-0.696-2.191-2.261-3.649-5.007-3.649
            c-2.711,0-4.275,1.461-4.971,3.649c-0.383,1.146-0.556,2.535-0.556,5.664s0.173,4.518,0.556,5.665
            c0.695,2.19,2.26,3.649,4.971,3.649C276.258,221.566,277.822,220.105,278.515,217.915z"/>
                <path fill={mainColor} d="M286.295,201.824c0-0.834,0.521-1.393,1.393-1.393h7.336c4.727,0,7.125,2.434,7.125,6.188c0,2.746-1.251,4.448-3.197,5.179
            v0.068c1.701,0.452,3.614,2.364,3.614,5.459c0,4.447-2.85,6.742-7.646,6.742h-7.229c-0.868,0-1.392-0.52-1.392-1.392v-20.853
            H286.295z M294.951,210.651c2.607,0,4.135-1.495,4.135-3.683c0-2.261-1.53-3.722-4.135-3.722h-5.355
            c-0.137,0-0.209,0.069-0.209,0.21v6.987c0,0.138,0.068,0.21,0.209,0.21h5.355V210.651z M289.6,221.254h5.527
            c2.814,0,4.344-1.53,4.344-3.895c0-2.328-1.529-3.893-4.344-3.893H289.6c-0.139,0-0.21,0.068-0.21,0.21v7.37
            C289.39,221.185,289.459,221.254,289.6,221.254z"/>
                <path fill={mainColor} d="M306.367,222.573V201.82c0-0.834,0.521-1.392,1.393-1.392h7.404c4.59,0,7.474,2.677,7.474,6.812
            c0,3.164-1.668,5.459-4.483,6.361l4.242,8.342c0.138,0.279,0.21,0.52,0.21,0.834c0,0.765-0.556,1.461-1.495,1.461
            c-0.627,0-1.113-0.314-1.496-0.976l-4.658-9.21h-5.282c-0.138,0-0.21,0.068-0.21,0.21v8.308c0,1.043-0.627,1.668-1.529,1.668
            C306.994,224.244,306.367,223.617,306.367,222.573z M319.578,207.244c0-2.467-1.633-3.928-4.379-3.928h-5.527
            c-0.138,0-0.21,0.068-0.21,0.209v7.439c0,0.139,0.069,0.21,0.21,0.21h5.527C317.945,211.172,319.578,209.715,319.578,207.244z"/>
                <path fill={mainColor} d="M334.635,214.477v8.1c0,1.044-0.627,1.668-1.529,1.668c-0.869,0-1.53-0.627-1.53-1.668v-8.1l-6.464-11.922
            c-0.139-0.279-0.245-0.627-0.245-0.903c0-0.765,0.624-1.392,1.461-1.392c0.627,0,1.044,0.313,1.354,0.902l5.424,10.082h0.103
            l5.354-10.082c0.313-0.592,0.73-0.902,1.354-0.902c0.833,0,1.427,0.627,1.427,1.392c0,0.278-0.104,0.627-0.245,0.903
            L334.635,214.477z"/>
            </g>
        </svg>
    );
};

export default Logo;