import React, {Component} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../Page';
import PageHeader from "../../PageHeader";
import PhotoSessionGroup from "./PhotoSessionGroup";

//config
import { config }  from "../../../config";

class ViewFotoblog extends Component {
    render() {

        const {
            pageData,
            pageOptions
        } = this.props;

        const greenGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_news}deg, 
                ${pageOptions.kolor_1_news} 53%, 
                ${pageOptions.kolor_2_news} 85% 
            )`
        };

        const seasonGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_news}deg, 
                ${pageOptions.kolor_1_naglowki} 53%, 
                ${pageOptions.kolor_2_naglowki} 85% 
            )`
        };

        return (
            <Page pageIndex={config.pageIndex.fotoblog}>
                <article className="page fotoblog content">

                    <PageHeader
                        title={pageData.tytul}
                        subtitle={pageData.podtytul}
                        text={pageData.tekst_naglowka}
                        seasonGradient={seasonGradient}
                        seasonBlockWidth="33.333%"
                        seasonBlockHeight="266px"
                    />

                    <div className="row fotoblog-row-container">
                        {pageData.grupy_sesji_zdjeciowych
                            ?
                            pageData.grupy_sesji_zdjeciowych.map( (item,index) =>
                                <PhotoSessionGroup
                                    index={index}
                                    item={item}
                                    gradient={greenGradient}
                                    dummy={pageOptions.zaslepka_fotoblog_tytul}
                                />  )
                            : []
                        }
                    </div>
                </article>
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(ViewFotoblog);