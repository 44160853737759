import React from 'react';

const VideoCamera = ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_video_camera"
             xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="75px" height="65.918px"
             viewBox="218.5 223.041 75 65.918" >
            <g>
                <path fill={mainColor} d="M292.464,252.67c-0.644-0.401-1.449-0.441-2.131-0.106l-14.704,7.231v-2.33c0-3.435-2.161-6.373-5.194-7.529
                    c3.184-2.82,5.194-6.936,5.194-11.514c0-8.481-6.9-15.381-15.381-15.381c-5.588,0-10.49,2.997-13.184,7.466
                    c-2.693-4.47-7.595-7.466-13.184-7.466c-8.481,0-15.381,6.9-15.381,15.381c0,4.578,2.011,8.693,5.194,11.514
                    c-3.033,1.157-5.194,4.095-5.194,7.529v23.438c0,4.442,3.614,8.057,8.057,8.057h41.016c4.442,0,8.057-3.614,8.057-8.057v-2.33
                    l14.704,7.231c0.307,0.151,0.639,0.226,0.97,0.226c0.404,0,0.807-0.111,1.161-0.332c0.645-0.401,1.036-1.106,1.036-1.865v-29.297
                    C293.5,253.776,293.108,253.071,292.464,252.67z M260.248,227.436c6.058,0,10.986,4.929,10.986,10.986s-4.929,10.986-10.986,10.986
                    s-10.986-4.929-10.986-10.986S254.19,227.436,260.248,227.436z M249.496,249.408h-4.862c0.934-0.915,1.753-1.946,2.431-3.072
                    C247.743,247.462,248.562,248.494,249.496,249.408z M222.895,238.422c0-6.058,4.929-10.986,10.986-10.986
                    s10.986,4.929,10.986,10.986s-4.929,10.986-10.986,10.986S222.895,244.479,222.895,238.422z M271.234,280.902
                    c0,2.02-1.643,3.662-3.662,3.662h-41.016c-2.02,0-3.662-1.643-3.662-3.662v-23.438c0-2.02,1.643-3.662,3.662-3.662h41.016
                    c2.02,0,3.662,1.643,3.662,3.662v5.859v11.719V280.902z M289.105,280.303l-13.477-6.628v-8.982l13.477-6.628V280.303z"/>
                <path fill={mainColor} d="M233.881,245.014c3.635,0,6.592-2.957,6.592-6.592s-2.957-6.592-6.592-6.592s-6.592,2.957-6.592,6.592
                    S230.246,245.014,233.881,245.014z M233.881,236.225c1.211,0,2.197,0.986,2.197,2.197s-0.986,2.197-2.197,2.197
                    s-2.197-0.986-2.197-2.197S232.669,236.225,233.881,236.225z"/>
                <path fill={mainColor} d="M260.248,245.014c3.635,0,6.592-2.957,6.592-6.592s-2.957-6.592-6.592-6.592s-6.592,2.957-6.592,6.592
                    S256.613,245.014,260.248,245.014z M260.248,236.225c1.212,0,2.197,0.986,2.197,2.197s-0.985,2.197-2.197,2.197
                    s-2.197-0.986-2.197-2.197S259.036,236.225,260.248,236.225z"/>
                <path fill={mainColor} d="M264.643,258.197h-16.113c-1.213,0-2.197,0.983-2.197,2.197v13.184c0,1.214,0.984,2.197,2.197,2.197h16.113
                    c1.214,0,2.197-0.983,2.197-2.197v-13.184C266.84,259.181,265.856,258.197,264.643,258.197z M262.445,271.381h-11.719v-8.789
                    h11.719V271.381z"/>
                <path fill={mainColor} d="M239.74,275.775h-10.254c-1.213,0-2.197,0.983-2.197,2.197s0.984,2.197,2.197,2.197h10.254
                    c1.213,0,2.197-0.983,2.197-2.197S240.954,275.775,239.74,275.775z"/>
                <path fill={mainColor} d="M239.74,266.986h-10.254c-1.213,0-2.197,0.983-2.197,2.197s0.984,2.197,2.197,2.197h10.254
                    c1.213,0,2.197-0.983,2.197-2.197S240.954,266.986,239.74,266.986z"/>
            </g>
        </svg>
    );
};

export default VideoCamera;