import React from 'react';
import {connect} from 'react-redux';

//actions
import {setContactFormVisibility} from '../../../../actions/pageActions';

//components
import ContactForm from '../../../ContactForm';

//tools
import MediaQuery from "react-responsive";

const ChTurawa = props => {

    const {
        pageData,
        title,
        textColor,
        textSubmit,
        seasonGradient
    } = props;



    const phone = pageData.kontakt_telefon;

    return (
        <div
            className={"col col-xs-12 col-sm-6 col-md-4"}
            style={seasonGradient} >
            {
                props.contactFormVisible && <ContactForm/>
            }

            <section style={{color:textColor}}>
                <h3 dangerouslySetInnerHTML={{__html:title}}/>
                <p>
                    <strong>{pageData.kontakt_nazwa}</strong>
                    <span dangerouslySetInnerHTML={{__html: pageData.kontakt_adres}}/>
                </p>

                <p>
                    <MediaQuery minWidth={769}>
                        <span>
                            {pageData.kontakt_telefon}
                        </span>
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                        <a
                            href={`tel:${phone && phone.replace(/[() ]/g,'')}`}
                            title="Tel"
                            style={{color:textColor}}
                        >
                            {pageData.kontakt_telefon}
                        </a>
                    </MediaQuery>

                        {
                            pageData.kontakt_email &&
                            pageData.kontakt_email.map( (item,index) =>
                                <a
                                    href={`mailto:${item.email}`}
                                    title="Email"
                                    key={index}
                                    style={{color:textColor}}
                                >
                                    {item.email}
                                </a>
                            )
                        }

                </p>

                <button
                    className="btn form-btn capitalized"
                    onClick={ ()=>props.setContactFormVisibility(true) }>
                    {textSubmit}
                </button>

            </section>
        </div>
    );
};

const mapStateToProps = (state) => ({
    contactFormVisible: state.page.contactFormVisible
});

const mapDispatchToProps = dispatch => ({
    setContactFormVisibility: value => dispatch( setContactFormVisibility(value))
});


export default connect(mapStateToProps,mapDispatchToProps)(ChTurawa);