import React from 'react';
import {connect} from "react-redux";

//actions
import {setPopup} from "../../actions/pageActions";

const Popup = props => {

    const {
        data
    } = props;
    return (
        <div className="popup-overlay">
            <div
                className="popup-content"
                style={{backgroundColor:data.bg}}
            >
                <nav onClick={ () =>  props.setPopup({}) }>x</nav>
                <figure style={data.seasonGradient}/>
                <h3 dangerouslySetInnerHTML={{__html:data.title}}/>
                <span dangerouslySetInnerHTML={{__html: data.txt}} />
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    setPopup: data => dispatch( setPopup(data))
});


export default connect(null,mapDispatchToProps)(Popup);