import React, {Component} from 'react';

//components
import HoursLabels from "../../../HoursLabels";

class HoursSection extends Component {
    render() {

        const {
            pageData,
            pageOptions,
            pageDataHoursList } = this.props;

        const greenGradient = {
            background: `linear-gradient( 
                ${pageOptions.rotacja_news}deg, 
                ${pageOptions.kolor_1_news} 53%, 
                ${pageOptions.kolor_2_news} 85% 
            )`
        };

        return (
            <>
                <div className="row hours-row-title">
                    <div className="col col-xs-12 col-md-9 title">
                        <h2 style={greenGradient}>
                            {pageData.tytul_godziny_otwarcia}
                        </h2>
                    </div>
                </div>
                <div className="row hours-row-container center-xs start-md">
                    <HoursLabels />
                    {pageDataHoursList
                        .map((item,index) =>
                            <div className={"col col-xs-12 col-sm-6 col-md-2"}
                                 key={index}>
                                <h3>
                                    <img
                                        src={item.logo}
                                        alt={item.nazwa_sklepu}
                                        width={134}
                                        height={47} />
                                </h3>
                                <ul>
                                    {
                                        item.godziny.map((item,index)=>
                                            <li key={index}>
                                                <header>{item.label}</header>
                                                <span>{item.hours}
                                                    {item.note &&
                                                        <>
                                                            <br/><span className="small">{item.note}</span>
                                                        </>
                                                    }
                                                </span>

                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        )
                    }
                </div>
            </>
        );
    }
}

export default HoursSection;