import { SET_POPUP } from "../actions/popupActions";
import { SET_REDIRECTION } from "../actions/popupActions";

const defaultState = {
    popupData: {},
    redirect: false,
    redirectLink: null
};

const player = (state = defaultState, action = undefined) => {
    const {type} = action;

    switch (type) {
        case SET_POPUP: {
            return {
                ...state,
                popupData: action.data,
                redirectLink: action.data.url
            }
        }
        case SET_REDIRECTION: {
            return {
                ...state,
                redirectLink: action.value,
                redirect: !state.popupData.popup
            }
        }
        default:
            return state;
    }
};

export default player;